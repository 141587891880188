import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import StainManOverview from './pages/stainManOverview';
import { OrderServiceLines } from 'utils/interfaces';

interface Props {
}

const FineryStainManStation: React.FC<Props> = () => {
  return (
    <Routes>
      <Route path={`table/*`} element={<StainManOverview serviceLine={OrderServiceLines.FINERY} />} />
      <Route path="*" element={<Navigate to="table" />} />
    </Routes>
  );
};
export default FineryStainManStation;
