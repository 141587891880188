import { IItemReportDetail } from 'utils/interfaces/stainManStationInterfaces';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as ItemIcon } from 'assets/images/svgs/item-icon-1.svg';
import { ReactComponent as ItemJourneyIcon } from 'assets/images/svgs/item-journey.svg';
import SizeIcon from 'assets/images/pngs/size-tag.png';

import { Box, IconButton, Grid, Typography } from '@material-ui/core';
import { ItemDetails, useAnswerDelays, useItemJourney } from '../../../pendingItemsStation/context';
import QrDisplay from '../../../foldingStation/components/qrDisplay';
import { itemTypeIconMap } from 'utils/maps';
import { BrandDisplay } from 'components/brandDisplay';
import { ColorMapper } from 'components/colorMapper';
import { ImagePopup } from 'components/imagePopup';
import lodash from 'lodash';
import AnswerDelayComponent from 'modules/stations/pendingItemsStation/components/answerDelays/answerDelayComponent';
import { ItemTypes } from 'utils/interfaces';

export interface IDetailsCardProps {
  itemReportDetail: IItemReportDetail;
  isItemRejected: boolean;
  showFrontImage?: boolean;
  adjustHeight?: boolean;
  itemIcon?: JSX.Element;
  itemJourneyIcon?: JSX.Element;
  iconTitle?: string;
  size?: string;
  showColorNextLine?: boolean;
  border?: boolean;
  showDelayButton?: boolean;
  showJourneyButton?: boolean;
  showQRCode?: boolean;
  adjustFrontImageSize?: boolean;
}

const LeftCard: React.FC<IDetailsCardProps> = ({
  itemReportDetail,
  isItemRejected,
  showFrontImage = true,
  adjustHeight = false,
  itemIcon,
  itemJourneyIcon,
  iconTitle = 'Item Details',
  size,
  showColorNextLine = false,
  border = true,
  showDelayButton = true,
  showJourneyButton = true,
  showQRCode = true,
  adjustFrontImageSize = true,
}) => {
  const { toggle, updateItemId, updateOrderAlphaId } = useItemJourney();
  const { handleItemDelay, answerNoDelay } = useAnswerDelays();

  const toggleItemJourney = () => {
    updateOrderAlphaId(itemReportDetail.orderAlphaId);
    updateItemId(itemReportDetail.itemCode);
    toggle(true);
  };

  const orderItemDetails = useMemo<ItemDetails>(() => {
    return {
      itemId: itemReportDetail.itemCode,
      orderAlphaId: itemReportDetail.orderAlphaId,
      customerAlphaId: itemReportDetail.customerAlphaId,
      dropoffDate: itemReportDetail.dropoffDate,
      itemType: itemReportDetail.type as ItemTypes | undefined,
      orderId: itemReportDetail.orderId,
    };
  }, [itemReportDetail]);
  const delayDetails = useMemo(() => itemReportDetail.delayDetails, [itemReportDetail]);

  const [resetDelay, setResetDelay] = useState(false);
  const toggleResetDelay = (value: boolean) => setResetDelay(value);

  const handleDelayAnswer = (isDelayed: boolean) => {
    if (orderItemDetails) {
      if (isDelayed) {
        handleItemDelay?.({ ...orderItemDetails });
      } else {
        answerNoDelay?.({ ...orderItemDetails });
      }
      toggleResetDelay(false);
    }
  };

  return (
    <Wrapper adjustHeight={adjustHeight} border={border}>
      <Box
        {...(adjustHeight ? { paddingBottom: '1rem' } : { paddingY: '1rem' })}
        display="flex"
        flexWrap="nowrap"
        justifyContent="space-between"
        alignItems={'center'}
        flexDirection="row"
      >
        <Box display="flex">
          {!itemIcon ? <ItemIcon width="4.5rem" height="4.5rem" /> : itemIcon}
          <Header>{iconTitle}</Header>
        </Box>
        {showJourneyButton && (
          <Box>
            <IconButton onClick={toggleItemJourney}>{itemJourneyIcon ? itemJourneyIcon : <ItemJourneyIcon width="4rem" height="4rem" />}</IconButton>
          </Box>
        )}
      </Box>
      <DetailsWrapper display={'flex'} flexDirection={'column'}>
        <Box alignItems={'center'}>
          {showFrontImage && (
            <>
              <ImagesWrapper adjustFrontImageSize={adjustFrontImageSize}>
                <ImagePopup index={0.1} imageWidth="18rem" imageUrl={itemReportDetail.frontImage} />
              </ImagesWrapper>
              {lodash.get(itemReportDetail, 'brandDetails.Delicate', false) && (
                <ImagesWrapper paddingY={'1.3rem'} minHeight={'2rem'} adjustFrontImageSize={adjustFrontImageSize}>
                  <ImagePopup index={0.2} imageWidth="18rem" imageUrl={itemReportDetail.careLabelPhoto} />
                </ImagesWrapper>
              )}
            </>
          )}
          <ItemStylesWrapper container item alignItems="center" wrap="nowrap" justifyContent={'center'} adjustHeight={adjustHeight}>
            {showQRCode && <QrDisplay withMargin={false} qrCode={itemReportDetail.itemCode} />}
            {itemReportDetail.type && <ItemType src={itemTypeIconMap[itemReportDetail.type]} alt="item type" />}
            {itemReportDetail.brandDetails && (
              <BrandDisplay textFont="small" brand={itemReportDetail.brandDetails} logoHeight="3.5rem" logoWidth="3.5rem" />
            )}
            {!showColorNextLine && <ColorMapper colorsList={itemReportDetail.colorsList ?? []} withShadows textHidden dimension="3rem" />}
            {size && (
              <ShoeSize container item>
                <ShoeSizeImage src={SizeIcon} alt="item type" />
                <ShoeSizeText>{size}</ShoeSizeText>{' '}
              </ShoeSize>
            )}
          </ItemStylesWrapper>
          {showColorNextLine && <ColorMapper colorsList={itemReportDetail.colorsList ?? []} withShadows textHidden dimension="3rem" />}
        </Box>
        {showDelayButton && (
          <DelayWrapper paddingTop={adjustHeight ? 3 : 10}>
            {!isItemRejected && (
              <AnswerDelayComponent
                handleDelayAnswer={handleDelayAnswer}
                resetDelay={resetDelay}
                toggleResetDelay={toggleResetDelay}
                answerDelaysWidth={'40%'}
                twoButtonOption={false}
                delayDetails={delayDetails}
                delayButtonText="Add Delay"
              />
            )}
          </DelayWrapper>
        )}
      </DetailsWrapper>
    </Wrapper>
  );
};

export default LeftCard;

const DelayWrapper = styled(Box)`
  font-size: 2.5rem;

  svg {
    width: 2.5rem;
    height: 2.5rem;
  }
`;
const ItemStylesWrapper = styled(Grid)<{ adjustHeight: boolean }>`
  height: fit-content;
  ${(props) => (props.adjustHeight ? 'gap: 1rem; margin-top: 1rem;' : '')};
`;

const Wrapper = styled.div<{ adjustHeight: boolean; border: boolean }>`
  ${(props) => (!props.adjustHeight ? 'padding: 0px 1rem;' : 'padding: 0.5rem 1rem 1rem 1rem;')}
  ${(props) => (props.border ? 'border: 1px solid #7d7c97;' : '')}
  ${(props) => (!props.adjustHeight ? 'height: 100%;' : '')}
  overflow-y: auto;
`;

const ItemType = styled.img`
  height: 6rem;
`;

const ShoeSize = styled(Grid)``;

const ShoeSizeImage = styled.img`
  width: 2.5rem;
  height: 2.5rem;
`;

const ShoeSizeText = styled(Typography)`
  font-size: 1.5rem;
  letter-spacing: normal;
  text-align: center;
  color: #111135;
`;

export const Header = styled.p`
  font-family: Ubuntu, serif;
  font-size: 25px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #111135;
  padding-left: 0.75rem;
`;
const DetailsWrapper = styled(Box)`
  align-items: center;
  height: 85%;
  justify-content: space-around;
`;

const ImagesWrapper = styled(Box)<{ adjustFrontImageSize: boolean }>`
  img {
    ${(props) => (!props.adjustFrontImageSize ? ' max-width: 25rem;' : ' max-width: 20rem;')}
  }
`;
