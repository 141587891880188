import { FilterDefaultValue, FilterOptions } from 'components/filters';
import { ShoeSideEnum } from 'utils/interfaces';
import { IssuesActionStatus } from '../../enums/stainManEnums';
import moment from 'moment';

const shoeCommonFilters: FilterOptions[] = [
  {
    filterKey: 'clusterIdsListFilter',
    title: 'Cluster',
    type: 'autoComplete',
    isMultipleSelection: true,
    filterOptions: [],
    defaultValue: [],
    xsValue: 3,
  },

  {
    filterKey: 'itemTypesListFilter',
    title: 'Item Type',
    type: 'autoComplete',
    isMultipleSelection: true,
    filterOptions: [],
    defaultValue: [],
    xsValue: 3,
  },

  {
    filterKey: 'itemSideListFilter',
    title: 'Single/Pair',
    type: 'select',
    isMultipleSelection: true,
    defaultValue: [],
    filterOptions: [
      { label: 'Left', value: ShoeSideEnum.LEFT },
      { label: 'Right', value: ShoeSideEnum.RIGHT },
      { label: 'Pair', value: ShoeSideEnum.BOTH },
    ],
    xsValue: 2,
  },

  {
    filterKey: 'isStainedFilter',
    title: 'Item Reports',
    type: 'select',
    defaultValue: '',
    filterOptions: [
      { label: 'All', value: '' },
      { label: 'Stained', value: 'true' },
      { label: 'Damaged', value: 'false' },
    ],
    xsValue: 2,
  },

  {
    filterKey: 'actionStatusListFilter',
    title: 'Actioned',
    type: 'select',
    isMultipleSelection: true,
    defaultValue: [`${IssuesActionStatus.ACTION_ATTEMPTED}`, `${IssuesActionStatus.NOT_ACTIONED}`],
    filterOptions: [
      { label: 'No', value: `${IssuesActionStatus.NOT_ACTIONED}` },
      { label: 'Partially', value: `${IssuesActionStatus.ACTION_ATTEMPTED}` },
      { label: 'Yes', value: `${IssuesActionStatus.ACTIONED}` },
    ],
    xsValue: 2,
  },

  {
    filterKey: 'dateFilter',
    title: 'Dropoff Date',
    type: 'date',
    multiple: false,
    defaultValue: { start: new Date(), end: moment().add('2', 'days').toDate() },
    xsValue: 3,
  },
];

const shoeDefaultCommonValues = (() => {
  const obj: { [key in string]?: FilterDefaultValue } = {};
  shoeCommonFilters.forEach((filter) => {
    obj[filter.filterKey] = filter.defaultValue;
  });
  return obj;
})();

export { shoeCommonFilters, shoeDefaultCommonValues };
