export enum ItemTypes {
  sheila = 'sheila',
  shorts = 'shorts',
  socks = 'socks',
  tShirt = 'tShirt',
  jeans = 'jeans',
  pants = 'pants',
  duvet = 'duvet',
  tie = 'tie',
  gathra = 'gathra',
  jacket = 'jacket',
  undershirt = 'undershirt',
  handkerchief = 'handkerchief',
  bra = 'bra',
  skirt = 'skirt',
  suitJacket = 'suitJacket',
  blouse = 'blouse',
  pillow = 'pillow',
  abayascarf = 'abayascarf',
  vest = 'vest',
  bedsheets = 'bedsheets',
  towel = 'towel',
  bathrobe = 'bathrobe',
  scarf = 'scarf',
  shirt = 'shirt',
  suitPants = 'suitpants',
  belt = 'belt',
  legging = 'legging',
  jumpsuit = 'jumpsuit',
  tableCloth = 'tableCloth',
  carpet = 'carpet',
  manUnderwear = 'manUnderwear',
  pillowCase = 'pillowcase',
  kandura = 'kandura',
  longDress = 'longDress',
  winterJacket = 'winterJacket',
  winterOvercoat = 'winterOvercoat',
  pullover = 'pullover',
  sweater = 'sweater',
  coat = 'coat',
  womenUnderwear = 'womenUnderwear',
  abaya = 'abaya',
  overcoat = 'overcoat',
  stain = 'stain',
  damage = 'damage',
  rewash = 'rewash',
  jalabiya = 'jalabiya',
  delicateAbaya = 'delicateAbaya',
  glove = 'glove',
  faceMask = 'faceMask',
  repress = 'repress',
  spotter = 'spotter',
  cantClean = 'cantClean',
  customer = 'customer',
  itemRisk = 'itemRisk',
  damageAfterCleaning = 'damageAfterCleaning',
  itemAtDamageRisk = 'itemAtDamageRisk',
  addStain = 'addStain',
  addDamage = 'addDamage',
  smellsBad = 'smellsBad',
  stillWet = 'stillWet',
  shoePair = 'shoePair',
  shoeSingle = 'shoeSingle',
  shoeLeft = 'shoeLeft',
  shoeRight = 'shoeRight',
  sythenticsTextileSportsSneakers = 'sythenticsTextileSportsSneakers',
  suedeNubuckSportsSneakers = 'suedeNubuckSportsSneakers',
  leatherSportsSneakers = 'leatherSportsSneakers',
  mixSportsSneakers = 'mixSportsSneakers',
  sythenticsTextileDesignerSneakers = 'sythenticsTextileDesignerSneakers',
  suedeNubuckDesignerSneakers = 'suedeNubuckDesignerSneakers',
  leatherDesignerSneakers = 'leatherDesignerSneakers',
  mixDesignerSneakers = 'mixDesignerSneakers',
  sythenticsTextileFormal = 'sythenticsTextileFormal',
  suedeNubuckFormal = 'suedeNubuckFormal',
  leatherFormal = 'leatherFormal',
  mixFormal = 'mixFormal',
  sythenticsTextileAnkelBoot = 'sythenticsTextileAnkelBoot',
  suedeNubuckAnkelBoot = 'suedeNubuckAnkelBoot',
  leatherAnkelBoot = 'leatherAnkelBoot',
  mixAnkelBoot = 'mixAnkelBoot',
  sythenticsTextileHighBoot = 'sythenticsTextileHighBoot',
  suedeNubuckHighBoot = 'suedeNubuckHighBoot',
  leatherHighBoot = 'leatherHighBoot',
  mixHighBoot = 'mixHighBoot',
  sythenticsTextileTHighBoot = 'sythenticsTextileTHighBoot',
  suedeNubuckTHighBoot = 'suedeNubuckTHighBoot',
  leatherTHighBoot = 'leatherTHighBoot',
  mixTHighBoot = 'mixTHighBoot',
  sythenticsTextileFlipFlop = 'sythenticsTextileFlipFlop',
  suedeNubuckFlipFlop = 'suedeNubuckFlipFlop',
  leatherFlipFlop = 'leatherFlipFlop',
  flipFlopMix = 'flipFlopMix',
  sythenticsTextileSandal = 'sythenticsTextileSandal',
  suedeNubuckSandal = 'suedeNubuckSandal',
  leatherSandal = 'leatherSandal',
  sandalMix = 'sandalMix',
  sythenticsTextileEspardrilles = 'sythenticsTextileEspardrilles',
  suedeNubuckEspardrilles = 'suedeNubuckEspardrilles',
  leatherEspardrilles = 'leatherEspardrilles',
  mixEspardrilles = 'mixEspardrilles',
  childrensSportsSneakers = 'childrensSportsSneakers',
  childrensFormal = 'childrensFormal',
  accessories = 'accessories',
  apron = 'apron',
  smallStuffedToy = 'smallStuffedToy',
  mediumStuffedToy = 'mediumStuffedToy',
  largeStuffedToy = 'largeStuffedToy',
  shoeStain = 'shoeStain',
  shoeCantBeCleaned = 'shoeCantBeCleaned',
  leatherJacket = 'leatherJacket',
  bridalDress = 'bridalDress',
  formalDress = 'formalDress',
  giletJacket = 'giletJacket',
  casualDress = 'casualDress',
  eveningGown = 'eveningGown',
  waistCoat = 'waistCoat',
  tuxedoPants = 'tuxedoPants',
  tuxedoJacket = 'tuxedoJacket',
  shoes = 'shoes',
  bags = 'bags',
  sleevelesUndershirt = 'sleevelesUndershirt',
  pyjamaTop = 'pyjamaTop',
  winterPants = 'winterPants',
  winterCoat = 'winterCoat',
  swimmingSuit = 'swimmingSuit',
  veil = 'veil',
  shoeLaces = 'shoeLaces',
  designerFormalShoesSuede = 'designerFormalShoesSuede',
  dressEvening = 'dressEvening',
  dressComplex = 'dressComplex',
  dressBasic = 'dressBasic',
  sportSweaterHoodie = 'sportSweaterHoodie',
  sweaterHoodie = 'sweaterHoodie',
  thickSweaterHoodie = 'thickSweaterHoodie',
  shoeBox = 'shoeBox',
  shoeBag = 'shoeBag',
  shoeTree = 'shoeTree',
  suitCover = 'suitCover',
  laundryBag = 'laundryBag',
  hangers = 'hangers',
  designerEspardrilles = 'designerEspardrilles',
  designerSandalsFlipflops = 'designerSandalsFlipflops',
  bootsAnkle = 'bootsAnkle',
  designerFormalSythenticsTextile = 'designerFormalSythenticsTextile',
  designerFormalLeather = 'designerFormalLeather',
  designerFormalMix = 'designerFormalMix',
  reusableBag = 'reusableBag',
  sofaCover = 'sofaCover',
  cushionCover = 'cushionCover',
  cushion = 'cushion',
  mensFormalShoeLeather = 'mensFormalShoeLeather',
  mensFormalShoesMixedMaterial = 'mensFormalShoesMixedMaterial',
  mensDesignerFormalLeather = 'mensDesignerFormalLeather',
  mensDesignerFormalMixedMaterial = 'mensDesignerFormalMixedMaterial',
  womensFormalShoe = 'womensFormalShoe',
  womenDesignerFormal = 'womenDesignerFormal',
}

export enum TypeformTypes {
  DAMAGED = 'ioBGXp',
  PENDING = 'lxckPv',
}

export enum ActivityTypes {
  BAG = 'BAG',
  SHIRT = 'SHIRT',
  SHOE = 'SHOE',
}
