import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

import { FilterDefaultValue, isAutoComplete, isDate } from 'components/filters';
import moment from 'moment';

interface Filters {
  [x: string]: FilterDefaultValue | number | boolean;
}

export const SELECT_ALL = 'ALL';
const useQueryFilters = <T extends Partial<Filters>>(defaults: T, filters?: Partial<Filters>): T => {
  return useMemo<T>(() => {
    const filterObj: T = { ...defaults };

    for (const filterKey in filters) {
      const filter = filters[filterKey];

      if (isEmpty(filter)) continue;
      if (isAutoComplete(filter)) {
        const selectingAll = filter.some((v) => v.value === SELECT_ALL);
        if (!selectingAll) {
          Object.assign(filterObj, { [filterKey]: filter.map((f) => f.value) });
        }
        continue;
      }
      if (isDate(filter)) {
        Object.assign(filterObj, { [filterKey]: { start: moment(filter.start).startOf('D').toDate(), end: moment(filter.end).endOf('D').toDate() } });
        continue;
      }

      Object.assign(filterObj, { [filterKey]: filter });
    }
    return filterObj;
  }, [defaults, filters]);
};

export default useQueryFilters;
