/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { Typography, Box, Grid, Button, Tabs, Tab, List, ListItem } from '@material-ui/core';
import { ExtraItem, IGroup, IItemGroup, IOrderItem, OrderServiceLines } from 'utils/interfaces';
import { CustomDialog } from 'components/dialogs';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

import useAddGroup from 'modules/stations/components/itemGroups/mutations/useAddGroup';
import useRemoveGroup from 'modules/stations/components/itemGroups/mutations/useRemoveGroup';
import useAddExtraItem from 'modules/stations/components/itemGroups/mutations/useAddExtraItem';
import useRemoveExtraItems from 'modules/stations/components/itemGroups/mutations/useRemoveExtraItems';
import { LoaderComponent } from 'components/loaderComponent';
import useGetItemGroups from 'modules/stations/components/itemGroups/queries/useGetItemGroups';
import GroupRow from './groupRow';
import GroupRowExtraItems from './groupRowExtraItem';
import LeftPanelListItem from './leftPanelListItem';
import RightPanelNewGroup from './rightPanelNewGroup';
import RemoveConfirmationModal from './removeConfirmationModal';
import AddExtraItems from './addExtraItems';

interface Props {
  onClose: () => any;
  open: boolean;
  orderAlphaId: string;
  currentItem: IOrderItem;
  recommendedItems?: { itemCode: string }[];
  disableMarrying?: boolean;
  serviceLine?: OrderServiceLines;
}

export type GroupItem = {
  itemCode: string;
  frontImage: string;
  groupId?: string;
};

type ItemGroupType = {
  items: IOrderItem[];
  allItems: IOrderItem[];
  groups: IGroup[];
  recommendations: IOrderItem[][];
  groupIds: Record<string, string>;
};

const a11yProps = (index: number) => {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
};

const TabPanel = (props: { [x: string]: any; children: any; value: number; index: number }) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      style={{ width: '100%', height: '50rem', overflow: 'scroll' }}
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const MarryingModal: React.FC<Props> = observer(
  ({ onClose, open, orderAlphaId, currentItem, recommendedItems, disableMarrying = false, serviceLine = OrderServiceLines.LAUNDRY }) => {
    const { mutateAsync: addGroup } = useAddGroup();
    const { mutateAsync: removeGroup } = useRemoveGroup();
    const { mutateAsync: addExtraItem } = useAddExtraItem();
    const { mutateAsync: removeExtraItems } = useRemoveExtraItems();

    const [value, setValue] = React.useState(1);
    const [mergedGroupIds, setMergedGroupIds] = React.useState<string[]>([]);
    const [editGroupId, setEditGroupId] = React.useState<string>('');
    const [newGroupItemsList, setNewGroupItemsList] = React.useState<{ itemCode: string; frontImage: string }[]>([]);
    const [itemsAddedToGroup, setItemsAddedToGroup] = React.useState<string[]>([]);
    const [showRemoveConfirmation, setShowRemoveConfirmation] = React.useState<boolean>(false);
    const [groupItemToRemove, setGroupItemToRemove] = React.useState<GroupItem>();
    const [allItemsRemove, setAllItemsRemove] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>('');

    const { itemGroups, refetch: refetchItemGroups } = useGetItemGroups(
      orderAlphaId,
      itemsAddedToGroup,
      true,
      serviceLine,
      recommendedItems,
      !disableMarrying
    );

    const handleGroupItems = (itemCodes: string[]) => {
      setItemsAddedToGroup([...itemCodes]);
    };

    const handleChange = (event: any, newValue: number) => {
      setValue(newValue);
    };

    const handleReset = (event: any, newValue: number) => {
      setValue(newValue);
      setNewGroupItemsList([]);
      handleGroupItems([]);
      setEditGroupId('');
      setMergedGroupIds([]);
    };

    const handleClose = () => {
      onClose();
    };

    const addItemToGroup = ({ itemCode, frontImage, groupId }: GroupItem) => {
      if (newGroupItemsList.length === 3) {
        setErrorMessage('Max limit to marry items is 3.');
        setTimeout(() => {
          setErrorMessage('');
        }, 3000);
        return;
      }

      if (serviceLine === OrderServiceLines.SHOES) {
        const itemTypes: string[] = [];
        newGroupItemsList.forEach((newItem) => {
          const item = (itemGroups as IItemGroup).allItems.find((item) => item.itemCode === newItem.itemCode);
          if (item?.itemCode === currentItem.code) {
            itemTypes.push(currentItem.type!);
          }

          if (item && item.type) {
            itemTypes.push(item.type);
          }
        });

        const allowedItemTypes = ['LOOSE_ITEM', 'ACCESSORY', 'REUSABLE_BAG', 'BELTS', 'SHOE_LACES'];
        const hasDisallowedType = itemTypes.some((type) => !allowedItemTypes.includes(type));
        const item = (itemGroups as IItemGroup).allItems.find((item) => item.itemCode === itemCode);
        if (hasDisallowedType && item?.type && !allowedItemTypes.includes(item?.type)) {
          setErrorMessage('Cannot marry shoes together.');
          setTimeout(() => {
            setErrorMessage('');
          }, 3000);
          return;
        }
      }

      if (newGroupItemsList.findIndex((item) => item.itemCode === itemCode) !== -1) {
        return;
      }

      let newGroupItems;

      if (groupId && groupId !== editGroupId) {
        if (mergedGroupIds.findIndex((gId) => gId === groupId) === -1) {
          setMergedGroupIds([...mergedGroupIds, groupId]);
        }
        const allItemsOfGroup = itemGroups.items.filter((item) => item.groupId === groupId);
        newGroupItems = [
          ...newGroupItemsList,
          ...allItemsOfGroup.map((item) => ({
            itemCode: item.itemCode!,
            frontImage: item.frontImage!,
          })),
        ];
      } else {
        newGroupItems = [...newGroupItemsList, { itemCode, frontImage }];
      }

      setNewGroupItemsList(newGroupItems);
      handleGroupItems(newGroupItems.map((item) => item.itemCode));
    };

    React.useEffect(() => {
      if (!open) {
        handleReset({}, 1); // Reset all state values
        return;
      }

      if (!currentItem.code) {
        return;
      }

      const { groupIds } = itemGroups as ItemGroupType;
      if (groupIds && groupIds[currentItem.code]) {
        handelModifyGroup(groupIds[currentItem.code]);
        return;
      }

      addItemToGroup({
        itemCode: currentItem.code,
        frontImage:
          (serviceLine === OrderServiceLines.SHOES ? currentItem.itemImagesMap?.top : currentItem.frontImage || currentItem.itemImagesMap?.front) ||
          '',
        groupId: groupIds && groupIds[currentItem.code] ? groupIds[currentItem.code] : '',
      });
    }, [currentItem, open]);

    const handelRemoveItemFromGroup = (groupItem: GroupItem) => {
      const group = itemGroups.groups.find((group) => group.itemCodesList.includes(groupItem.itemCode));
      if (group && editGroupId) {
        setGroupItemToRemove(groupItem);
        setShowRemoveConfirmation(true);
        return;
      }
      handelContinueRemoveItem(groupItem);
    };

    const handelContinueRemoveItem = async (groupItem?: GroupItem) => {
      const gpItem = groupItem && groupItem.itemCode ? groupItem : groupItemToRemove;

      if (gpItem) {
        const group = itemGroups.groups.find((group) => group.itemCodesList.includes(gpItem.itemCode));

        const filteredGroupItemsList = newGroupItemsList.filter((item) =>
          group && !editGroupId ? !group.itemCodesList.includes(item.itemCode) : item.itemCode !== gpItem.itemCode
        );

        setNewGroupItemsList([...filteredGroupItemsList]);
        handleGroupItems(filteredGroupItemsList.map((item) => item.itemCode));
      }
      setShowRemoveConfirmation(false);
    };

    const handelAddGroup = async () => {
      const itemsToGroup = [...newGroupItemsList];

      await addGroup(
        {
          orderAlphaId,
          itemCodesList: itemsToGroup.map((item) => item.itemCode),
          ...(editGroupId && { groupId: editGroupId }),
          ...(mergedGroupIds.length && { mergedGroupIds }),
        },
        {
          onSuccess: () => {
            if (editGroupId) {
              setValue(0);
              handleReset({}, 0);
            }
            handleReset({}, 1);
            refetchItemGroups();
          },
        }
      );
    };

    const handelGroupItemsButton = async () => {
      await handelAddGroup();
      handleClose();
    };

    const handelRemoveGroup = async (groupId: string) => {
      await removeGroup(
        {
          orderAlphaId,
          groupId,
        },
        {
          onSuccess: () => {
            refetchItemGroups();
            if (editGroupId === groupId) {
              handleReset({}, 0);
            }
          },
        }
      );
    };

    const handleRemoveNewGroup = () => {
      setShowRemoveConfirmation(true);
      setAllItemsRemove(true);
    };

    const handleContinueRemoveNewGroup = () => {
      setNewGroupItemsList([]);
      handleGroupItems([]);
      setMergedGroupIds([]);
      setShowRemoveConfirmation(false);
      setAllItemsRemove(false);
    };

    const handleContinueRemove = (allItemsRemove: boolean) => {
      if (allItemsRemove) {
        handleContinueRemoveNewGroup();
        return;
      }
      handelContinueRemoveItem();
    };

    const handelModifyGroup = (groupId: string) => {
      setValue(1);
      setEditGroupId(groupId);
      const group = itemGroups.groups.find((group) => group.groupId === groupId);

      const filterGroupItems = (itemGroups as IItemGroup).allItems.filter((item) => group?.itemCodesList.includes(item.itemCode!));
      const newGroupItems = filterGroupItems.map((item) => ({ itemCode: item.itemCode!, frontImage: item.frontImage! }));
      setNewGroupItemsList([...newGroupItems]);
      handleGroupItems(newGroupItems.map((item) => item.itemCode));
    };

    const handleAddExtraItem = async (item: ExtraItem) => {
      setValue(0);
      await addExtraItem(
        {
          orderAlphaId,
          itemCode: currentItem.code,
          extraItemCode: item.code,
          type: item.type,
          image: item.frontImage,
          orderId: currentItem.orderId,
        },
        {
          onSuccess: () => {
            if (editGroupId) {
              setValue(0);
            }
            setNewGroupItemsList([]);
            handleGroupItems([]);
            setEditGroupId('');
            setMergedGroupIds([]);
            refetchItemGroups();
          },
        }
      );
    };

    const handelRemoveRemoveExtraItems = async (orderAlphaId: string, itemCode: string, extraItemCodesList: string[]) => {
      await removeExtraItems(
        {
          orderAlphaId,
          itemCode,
          extraItemCodesList,
        },
        {
          onSuccess: () => {
            refetchItemGroups();
          },
        }
      );
    };

    return (
      <CustomDialog open={open} onClose={handleClose} size="xl">
        <LoaderComponent />

        <Grid container direction="column" alignItems="baseline" style={{ padding: '1rem' }}>
          <Grid container direction="row" justifyContent="space-between" style={{ padding: '1rem' }}>
            <Grid xs={11}>
              <Tabs value={value} onChange={handleChange} aria-label="marrying item tabs" indicatorColor="primary" textColor="primary">
                <CustomTab label="All" {...a11yProps(0)} textColor="primary" />
                <CustomTab label="Group Items" {...a11yProps(1)} textColor="primary" />
                <CustomTab label="Extra Items" {...a11yProps(2)} textColor="primary" />
              </Tabs>
            </Grid>
            <Grid xs={'auto'}>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Grid>
            <StyledSeperator></StyledSeperator>
          </Grid>
          <TabPanel value={value} index={0}>
            {value === 0 && (
              <div>
                <Box style={{ background: '#ECEFF9' }}>
                  <Typography variant="h5" style={{ background: '#ECEFF9', padding: '2rem', marginLeft: '2rem' }}>
                    Group Items
                  </Typography>
                </Box>

                <Grid container xs={12} direction="row" justifyContent="space-between" alignItems="center">
                  <List style={{ width: '100%' }}>
                    {itemGroups.groups.map((group, index) => {
                      return (
                        <ListItem style={{ padding: '2px' }} key={`${index}-${group.groupId}-group`}>
                          {
                            <GroupRow
                              group={group}
                              index={index + 1}
                              itemGroups={itemGroups as IItemGroup}
                              handelModifyGroup={() => handelModifyGroup(group.groupId)}
                              handelRemoveGroup={() => handelRemoveGroup(group.groupId)}
                              currentItem={currentItem}
                            />
                          }
                        </ListItem>
                      );
                    })}
                    {itemGroups.groups.length === 0 && (
                      <NoItemsMessage variant="h5" align="center">
                        No groups added yet.
                      </NoItemsMessage>
                    )}
                  </List>
                </Grid>

                <Box style={{ background: '#ECEFF9' }}>
                  <Typography variant="h5" style={{ background: '#ECEFF9', padding: '2rem', marginLeft: '2rem', marginTop: '2rem' }}>
                    Extra Items
                  </Typography>
                </Box>

                <Grid container xs={12} direction="row" justifyContent="space-between" alignItems="center">
                  <List style={{ width: '100%' }}>
                    {(itemGroups as IItemGroup).allItems
                      .filter((item) => item.extraItemsList && item.extraItemsList.length)
                      .map((item, index) => (
                        <ListItem style={{ padding: '2px' }} key={`${index}-${item.code}-groupExtraItems`}>
                          {
                            <GroupRowExtraItems
                              extraItemsList={item.extraItemsList!}
                              item={item}
                              index={itemGroups.groups.length + index + 1}
                              itemGroups={itemGroups as IItemGroup}
                              handelRemoveGroup={handelRemoveRemoveExtraItems}
                              orderAlphaId={orderAlphaId}
                            />
                          }
                        </ListItem>
                      ))}
                    {!(itemGroups as IItemGroup).allItems.some((item) => item.extraItemsList && item.extraItemsList.length) && (
                      <NoItemsMessage variant="h5" align="center">
                        No extra items groups added yet.
                      </NoItemsMessage>
                    )}
                  </List>
                </Grid>
              </div>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {value === 1 && (
              <div>
                {errorMessage && <SyledErrorMessage variant="h6">{errorMessage}</SyledErrorMessage>}
                <FullWidthGrid container xs={12} direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="h5">Marry the items by clicking on the PLUS</Typography>

                  {editGroupId ? (
                    <ModalTypeTitle variant="h5">Editing Group</ModalTypeTitle>
                  ) : (
                    <ModalTypeTitle variant="h5">Adding New Group</ModalTypeTitle>
                  )}

                  <Button color="primary" size="large" variant="text" disabled={newGroupItemsList.length < 2} onClick={handelAddGroup}>
                    <AddIcon />
                    <span>{editGroupId ? 'Save Group' : 'Add Group'}</span>
                  </Button>
                </FullWidthGrid>
                <FullWidthGrid container xs={12} direction="row" justifyContent="space-between" alignItems="center">
                  <Grid xs={6} style={{ height: '35rem', overflow: 'scroll' }} justifyContent="space-between" alignItems="center">
                    <List>
                      {itemGroups.items.map((item: any, i: number) => {
                        const { code } = currentItem;
                        if (code === item.itemCode) {
                          item.type = currentItem.type;
                          item.brand = currentItem.brand;
                          item.colorsList = currentItem.colorsList;
                          item.frontImage = serviceLine === OrderServiceLines.SHOES ? currentItem.itemImagesMap?.top : currentItem.frontImage;
                        }

                        return (
                          <ListItem style={{ padding: '0px' }} key={`${i}-${item.itemCode}-item-list`}>
                            {
                              <LeftPanelListItem
                                type={item.type}
                                brand={item.brand}
                                colorsList={item.colorsList}
                                frontImage={item.frontImage}
                                itemCode={item.itemCode}
                                borderColor={item.borderColor}
                                groupId={item.groupId}
                                recommendationId={item.recommendationId}
                                addItemToGroup={addItemToGroup}
                              />
                            }
                          </ListItem>
                        );
                      })}

                      {itemGroups.items.length === 0 && (
                        <NoItemsMessage variant="h5" align="left">
                          No items remaining to be married.
                        </NoItemsMessage>
                      )}
                    </List>
                  </Grid>
                  <Grid xs={6} justifyContent="space-between" alignItems="flex-start" style={{ alignSelf: 'flex-start' }}>
                    <RightPanelNewGroup
                      groupItemsList={newGroupItemsList}
                      removeItemFromGroup={handelRemoveItemFromGroup}
                      handleRemoveNewGroup={handleRemoveNewGroup}
                      currentItem={currentItem}
                      serviceLine={serviceLine}
                    />
                  </Grid>
                </FullWidthGrid>
                <FullWidthGrid style={{ marginTop: '2rem' }} container xs={12} direction="row" justifyContent="center" alignItems="center">
                  <Button color="secondary" size="large" variant="outlined" onClick={handleClose}>
                    <span>Cancel</span>
                  </Button>
                  <GroupItemsButton
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={handelGroupItemsButton}
                    disabled={newGroupItemsList.length < 2}
                  >
                    <span>Group Items</span>
                  </GroupItemsButton>
                </FullWidthGrid>
              </div>
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            <AddExtraItems handleAddExtraItem={handleAddExtraItem} serviceLine={serviceLine} currentItemCode={currentItem.code} />
          </TabPanel>
        </Grid>
        <RemoveConfirmationModal
          open={showRemoveConfirmation}
          onClose={() => {
            setShowRemoveConfirmation(false);
          }}
          onContinue={handleContinueRemove}
          allItemsRemove={allItemsRemove}
        />
      </CustomDialog>
    );
  }
);

export default MarryingModal;

export const RoundButton = styled(Button)`
  border-radius: 50px;
  padding: 20px;
  margin-top: 6px;
`;

const GroupItemsButton = styled(Button)`
  margin-left: 3rem;
`;

const FullWidthGrid = styled(Grid)`
  width: 100%;
`;

const CustomTab = styled(Tab)`
  min-width: 14rem;
  font-size: 22px;
`;

const StyledSeperator = styled.span`
  background-color: #d5dae7;
  width: 100%;
  bottom: 0;
  height: 3px;
`;

const NoItemsMessage = styled(Typography)`
  margin: 7rem;
`;

const ModalTypeTitle = styled(Typography)`
  margin-left: 7rem;
`;

const SyledErrorMessage = styled(Typography)`
  color: red;
  margin-left: 40%;
`;
