import { useQuery } from 'react-query';

import { FilterDefaultValue } from 'components/filters';
import { StainManService } from 'services';
import { IOrderWithStainedItems } from 'utils/interfaces/stainManStationInterfaces';
import { OrderServiceLines } from 'utils/interfaces';
import { useQueryFilters } from 'hooks';

const ITEMS_PER_PAGE_LIMIT = 50;
type FiltersFromAPI = { label: string; value: string }[];

export interface fetchedFilters {
  itemTypesListFilter: FiltersFromAPI;
  clusterIdsListFilter: FiltersFromAPI;
  orderAlphaIdsListFilter: FiltersFromAPI;
  cleaningProgramsListFilter: FiltersFromAPI;
}

interface Filters {
  [x: string]: FilterDefaultValue | number;
}

export interface IOptionsToFilterBy extends Partial<Filters> {
  orderAlphaIdsListFilter?: FiltersFromAPI;
  clusterIdsListFilter?: FiltersFromAPI;
  cleaningProgramsListFilter?: FiltersFromAPI;
  itemTypesListFilter?: FiltersFromAPI;
  itemTypesCategoryFilter?: FiltersFromAPI;
  orderServiceLinesListFilter?: OrderServiceLines[];
  actionStatusListFilter?: string[];
  isVipFilter?: string;
  isDelicateFilter?: string;
  isStainedFilter?: string;
  dateFilter?: { start: Date; end: Date };
}

export interface IStainManFilters extends IOptionsToFilterBy {
  pageNumber?: number;
  itemsPerPage?: number;
}

export interface PaginationMeta {
  fullItemsCount: number;
  hasNextPage: boolean;
  itemsPerPage: number;
}

export interface QueryResponse {
  ordersItemsList: IOrderWithStainedItems[];
  baseFilters: fetchedFilters;
  paginationMeta: PaginationMeta;
}

const useGetOrdersWithStainedItemsList = (
  filters: IStainManFilters,
  pageNumber: number,
  orderServiceLine: OrderServiceLines = OrderServiceLines.LAUNDRY
) => {
  const queryFilters = useQueryFilters<IStainManFilters>({ pageNumber, orderServiceLinesListFilter: [orderServiceLine] }, filters);

  const { data, isSuccess, isError, isFetching } = useQuery<QueryResponse, Error>(
    ['ordersWithStainedItemsList', pageNumber, { ...queryFilters }],
    () =>
      StainManService.fetchStainedItems({
        ...queryFilters,
        itemsPerPage: ITEMS_PER_PAGE_LIMIT,
      }),
    {
      initialData: {
        ordersItemsList: [],
        paginationMeta: { fullItemsCount: 0, hasNextPage: false, itemsPerPage: ITEMS_PER_PAGE_LIMIT },
        baseFilters: {
          cleaningProgramsListFilter: [],
          clusterIdsListFilter: [],
          orderAlphaIdsListFilter: [],
          itemTypesListFilter: [],
        },
      },
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
  return {
    ordersItemsList: data ? data?.ordersItemsList : [],
    paginationMeta: data?.paginationMeta ?? {
      fullItemsCount: 0,
      hasNextPage: false,
      itemsPerPage: ITEMS_PER_PAGE_LIMIT,
    },
    baseFilters: data?.baseFilters ?? ({} as fetchedFilters),
    isSuccess,
    isError,
    isFetching,
  };
};
export default useGetOrdersWithStainedItemsList;
