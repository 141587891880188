import React from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import UserItemNotes from '../../../components/userItemNotes/UserItemNotes';
import Instructions from '../instructions';
import { IRequestCardProps } from './rightCard';

const ShoesRightCard: React.FC<IRequestCardProps> = ({
  itemReportDetail: { itemCode, orderAlphaId, orderId, customerNotes, customerAttachmentsList },
}) => {
  return (
    <Wrapper>
      <NotesWrapper>
        <UserItemNotes
          title="Please Add Photo of Shoe"
          onClose={() => {}}
          itemCode={itemCode}
          orderId={orderId}
          orderAlphaId={orderAlphaId}
          isDialog={false}
          imageSize="4rem"
        />
      </NotesWrapper>
      <SpecialInstructionsWrapper>
        <Instructions notes={customerNotes || ''} images={customerAttachmentsList || []} />
      </SpecialInstructionsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  overflow-y: auto;
`;

const NotesWrapper = styled(Box)`
  padding: 1rem;
  margin-bottom: 1rem;
`;
const SpecialInstructionsWrapper = styled(Box)`
  border-top: 1px solid #7d7c97;
  padding: 1rem;
`;
export default ShoesRightCard;
