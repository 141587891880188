import React, { FormEvent, useMemo, useState } from 'react';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { ItemTypes } from 'utils/enums/typesEnum';
import { DetailsList, ReportType } from 'utils/interfaces';
import { useGetSelectedItem } from 'modules/stations/components/SortingStepper/SortingStepper';

import lodashGet from 'lodash/get';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LabelSelector from 'modules/stations/sorterStation/components/labelSelector/LabelSelector';
import { ItemBanner } from 'modules/stations/components/itemBanner';
import useSaItems from 'modules/stations/finerySorterStation/hooks/useSaItems';
import useGetBag from 'modules/stations/shoeSorterStation/queries/useGetBag';
import {
  partsMapper,
  reasonMapper,
  reportDamagedTypes,
  reportLocation,
} from 'modules/stations/sorterStation/components/dialogs/stainDamageComponent/addStainDamageDetails';
import { TypeValueMapper } from 'utils/maps/typeValueMapper';

interface StateProps {
  type: ReportType;
  image: string;
}

interface Props {
  onSubmit: (formDetails: DetailsList, index?: string) => void;
}

const useStyles = makeStyles({
  root: {
    flex: '1',
  },
  imageContainer: {
    padding: '1rem',
    border: '1px solid #b6bcd1',
    borderRadius: '0.3rem',
  },
  formControl: {
    display: 'flex',
    marginBottom: '2rem',
    width: '20rem',
  },
});

const AddStainDamageDetails: React.FC<Props> = ({ onSubmit }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const locationState = useLocation().state as StateProps | null;
  const { index } = useParams<{ index: string }>();
  const { selectedItem: currentItem } = useGetSelectedItem();
  const { data: bag } = useGetBag();
  const { linkedItem } = useSaItems(currentItem, { bag });

  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [reportForm, setReportForm] = useState<DetailsList>({
    location: '',
    part: '',
    type: '',
    imageUrl: lodashGet(locationState, 'image', ''),
    reason: reasonMapper[lodashGet(locationState, 'type', '')] || '',
  });

  const [labelWidth, setLabelWidth] = React.useState(0);
  const parts = useMemo(() => partsMapper(currentItem.type), [currentItem.type]);

  const btnEnabled = useMemo(
    () => Boolean(reportForm.imageUrl && reportForm.location && (reportForm.part || parts.length === 0) && reportForm.type && reportForm.reason),
    [parts.length, reportForm.imageUrl, reportForm.location, reportForm.part, reportForm.reason, reportForm.type]
  );

  React.useEffect(() => {
    setLabelWidth(inputLabel.current!.offsetWidth);
  }, []);

  React.useEffect(() => {
    if (index && currentItem.reportDetailsList) {
      const intIndex = parseInt(index);
      setReportForm(currentItem.reportDetailsList[intIndex]);
    }
  }, [index, currentItem.reportDetailsList]);

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setReportForm((oldValues) => ({
      ...oldValues,
      [event.target.name as string]: event.target.value,
    }));
  };

  // add stain report to the current item list then route to overview
  const onSubmitStainDamage = (event: FormEvent) => {
    event.preventDefault();
    onSubmit(reportForm, index);
    navigate(`../overView`);
  };

  return (
    <Grid container classes={{ container: classes.root }} alignItems={linkedItem ? 'flex-start' : 'center'} justifyContent="center">
      {/* ItemBanner - Special Attention Item */}
      {linkedItem && <ItemBanner linkedItem={linkedItem} currentItem={currentItem} />}

      <Grid item xs={6} style={{ margin: '1rem 0' }}>
        <Grid container direction="row" justifyContent="center">
          <LabelSelector
            pathType="stroke"
            iconName={ItemTypes.damage}
            isActive={reportForm.reason === 'DAMAGED'}
            onToggleClick={() => setReportForm({ ...reportForm, reason: 'DAMAGED', type: '' })}
          />
          <Box margin="0 2rem" />
          <LabelSelector
            iconName={ItemTypes.stain}
            isActive={reportForm.reason === 'STAINED'}
            onToggleClick={() => setReportForm({ ...reportForm, reason: 'STAINED', type: '' })}
          />
        </Grid>
        <Grid container justifyContent="center">
          <Grid classes={{ item: classes.imageContainer }} item>
            <img src={reportForm.imageUrl} alt="" className="img-responsive" style={{ width: '30rem' }} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <Grid container direction="row" alignItems="stretch">
          <Grid item xs={5}>
            <form onSubmit={onSubmitStainDamage}>
              <FormControl variant="outlined" classes={{ root: classes.formControl }} required>
                <InputLabel ref={inputLabel} htmlFor="type-selector">
                  Type
                </InputLabel>
                <Select
                  disabled={!reportForm.reason}
                  variant="outlined"
                  labelWidth={labelWidth}
                  value={reportForm.type}
                  onChange={handleChange}
                  inputProps={{
                    name: 'type',
                    id: 'type-selector',
                  }}
                >
                  {reportForm.reason &&
                    reportDamagedTypes[reportForm.reason].map((name) => (
                      <MenuItem key={name} value={name}>
                        {TypeValueMapper[name]}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" classes={{ root: classes.formControl }} required={parts.length !== 0}>
                <InputLabel ref={inputLabel} htmlFor="part-selector">
                  Part
                </InputLabel>
                <Select
                  variant="outlined"
                  labelWidth={labelWidth}
                  value={reportForm.part}
                  onChange={handleChange}
                  inputProps={{
                    name: 'part',
                    id: 'part-selector',
                  }}
                  disabled={parts.length === 0}
                >
                  {parts.map((part) => (
                    <MenuItem key={part} value={part}>
                      {part}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" classes={{ root: classes.formControl }} required>
                <InputLabel ref={inputLabel} htmlFor="location-selector">
                  Location
                </InputLabel>
                <Select
                  variant="outlined"
                  labelWidth={labelWidth}
                  value={reportForm.location}
                  onChange={handleChange}
                  inputProps={{
                    name: 'location',
                    id: 'location-selector',
                  }}
                >
                  {reportLocation.map((location) => (
                    <MenuItem key={location} value={location}>
                      {location}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button variant="contained" size="large" color="primary" type="submit" disabled={!btnEnabled}>
                Next
              </Button>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddStainDamageDetails;
