import { useQuery } from 'react-query';
import { StationService } from '../../../../services';
import { BagError, QueryResponse } from '../../shoeSorterStation/queries/useGetBag';
import { Bag } from '../../../../utils/classes';
import { IBag } from '../../../../utils/interfaces';

export const useGetBagQuery = (bagCode: string) => {
  console.log(bagCode)
  const getBagQuery = useQuery<QueryResponse, BagError>(['GET_BAG'], () => StationService.getOrderBagByQrCode(bagCode, false), {
    refetchOnWindowFocus: false,
    retry: false,
  });
  console.log(getBagQuery)
  return {
    ...getBagQuery,
    data: getBagQuery.data || (new Bag() as IBag),
  };
}
