import { GridCellParams, GridColDef,  GridValueFormatterParams } from '@mui/x-data-grid';
import { formattedDate } from 'utils/helpers/table';
import { ReactComponent as UrgentIcon } from 'assets/images/svgs/urgent-order.svg';
import { Brand, ColorsList,  ShoeSideEnum } from 'utils/interfaces';
import { typeKeyObjHelper } from 'utils/maps/typeMapper';
import { ColorMapper } from 'components/colorMapper';
import React from 'react';
import { actionComponent, getCellClass, getReportedType } from '../common';
import ItemReportTrigger from '../../components/table/ItemReportTrigger';

const shoeColumns: GridColDef[] = [
  {
    field: 'dropoffDate',
    headerName: 'Drop off date',
    flex: 1.4,
    cellClassName: (params) => getCellClass('multi-line-cell'),
    renderCell: (params: GridCellParams) => formattedDate(params.value as string, params.row.dropoffTime as string),
  },
  {
    field: 'cluster',
    headerName: 'Cluster',
    flex: 1.2,
    cellClassName: () => getCellClass(),
  },
  {
    field: 'orderAlphaId',
    headerName: 'Order ID',

    flex: 1.3,
    cellClassName: () => getCellClass(),

    renderCell: (params: GridCellParams) => {
      return (
        <>
          {params.value} {params.row.isUrgent && <UrgentIcon width={'1.5rem'} height={'1.5rem'} className="urgent-icon" />}
        </>
      );
    },
  },
  {
    field: 'itemCode',
    headerName: 'Item Code',
    flex: 1.3,
    cellClassName: (params) => getCellClass(),
    renderCell: (params) => <ItemReportTrigger {...params} />,
  },
  {
    field: 'singlePair',
    headerName: 'Single/pair',
    flex: 1.35,

    cellClassName: () => getCellClass(),
    renderCell: (params: GridCellParams) => {
      switch (params.row.side) {
        case ShoeSideEnum.LEFT:
          return 'Left';
        case ShoeSideEnum.RIGHT:
          return 'Right';
        case ShoeSideEnum.BOTH:
          return 'Pair';
        default:
          return 'N/A';
      }
    },
  },
  {
    field: 'itemType',
    headerName: 'Item Type',
    flex: 1.3,
    cellClassName: (params) => getCellClass('multi-line-cell wrap-line-cell'),
    valueFormatter: (params: GridValueFormatterParams) => typeKeyObjHelper(params.value ? (params.value as string) : '').label,
  },
  {
    field: 'brand_color',
    headerName: 'Brand/Color',
    flex: 1.3,
    cellClassName: (params) => getCellClass('multi-line-cell'),

    renderCell: (params: GridCellParams) => {
      const colors: ColorsList[] = params.row.color as ColorsList[];
      const brand: Brand = params.row.brand as Brand;
      return (
        <span>
          {brand?.label}
          <br />
          {colors ? <ColorMapper boxColor="black" withoutLeftMargin colorsList={colors} dimension="1rem" textHidden /> : ''}
        </span>
      );
    },
  },
  {
    field: 'stainDamage',
    headerName: 'Stain/Damage',
    flex: 1.25,
    cellClassName: () => getCellClass(),
    valueFormatter: (params) => getReportedType(params.row.isStained, params.row.isDamaged),
  },
  {
    field: 'isVip',
    headerName: 'VIP',
    flex: 0.75,
    cellClassName: () => getCellClass(),
    valueFormatter: (params) => (params.row.vip ? 'Yes' : 'No'),
  },
  {
    field: 'stainsActionStatus',
    headerName: 'Actioned',
    flex: 2.1,
    cellClassName: () => getCellClass(),
    renderCell: (params: GridCellParams) => actionComponent(params.row),
  },
  {
    field: 'lastLocation',
    headerName: 'Last location',
    flex: 1.45,
    cellClassName: (params) => getCellClass(),
    valueFormatter: (params) => params.row.lastLocation,
  },
];

export { shoeColumns };
