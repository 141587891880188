/** Program Interface */
export interface IProgramType {
  digit: string;
  key: string;
  name: string;
}
/** Suggested Program's interface (Received form the API) */
export interface SuggestedPrograms {
  WC: string[];
  L: string[];
  DC: string[];
  HC: string[];
}

export interface SuggestedProgramResponse {
  category: { name: string };
  programList: SuggestedProgramObj[];
}

interface SuggestedProgramObj {
  name: string;
  key: string;
}

/** Suggested Program's interface with the Program's Objects  */
export interface PreSelectedPrograms {
  WC: IProgramType[];
  L: IProgramType[];
  DC: IProgramType[];
  HC: IProgramType[];
}

/** Declare types for the categories  */
export type suggestedProgramsKeys = 'L' | 'WC' | 'DC' | 'HC';
export type programsCategories = 'laundry' | 'wetCleaning' | 'dryCleaning' | 'handCleaning';

export const programCategories = ['laundry', 'wetCleaning', 'dryCleaning', 'handCleaning'];

interface IProgramCategories {
  [key: string]: { [key: string]: IProgramType };
}

/** Category Mapper to Notation */
export const categoryMapper: { [key in programsCategories]: suggestedProgramsKeys } = {
  laundry: 'L',
  wetCleaning: 'WC',
  dryCleaning: 'DC',
  handCleaning: 'HC',
};

export const programMapper: IProgramCategories = {
  laundry: {
    shirts_whites: {
      name: 'Shirts Whites',
      key: 'shirts_whites',
      digit: '3',
    },
    shirts_lights: {
      name: 'Shirts Lights',
      key: 'shirts_lights',
      digit: '4',
    },
    shirts_colors: {
      name: 'Shirts Colors',
      key: 'shirts_colors',
      digit: '5',
    },

    general_whites: {
      name: 'General Whites',
      key: 'general_whites',
      digit: '14',
    },
    general_colors: {
      name: 'General Colors',
      key: 'general_colors',
      digit: '14',
    },

    pillowcase_whites: {
      name: 'Pillowcase Whites',
      key: 'pillowcase_whites',
      digit: '9',
    },
    pillowcase_lights: {
      name: 'Pillowcase Lights',
      key: 'pillowcase_lights',
      digit: '9',
    },
    pillowcase_colors: {
      name: 'Pillowcase Colors',
      key: 'pillowcase_colors',
      digit: '9',
    },
    towels_whites: {
      name: 'Towels Whites',
      key: 'towels_whites',
      digit: '8',
    },
    towels_lights: {
      name: 'Towels Lights',
      key: 'towels_lights',
      digit: '8',
    },
    towels_colors: {
      name: 'Towels Colors',
      key: 'towels_colors',
      digit: '8',
    },
    bedsheets_whites: {
      name: 'Bedsheets Whites',
      key: 'bedsheets_whites',
      digit: '6',
    },
    bedsheets_lights: {
      name: 'Bedsheets Lights',
      key: 'bedsheets_lights',
      digit: '6',
    },
    bedsheets_colors: {
      name: 'Bedsheets Colors',
      key: 'bedsheets_colors',
      digit: '6',
    },
    blanket_lights: {
      name: 'Blanket Lights',
      key: 'blanket_lights',
      digit: '7',
    },
    blanket_dark: {
      name: 'Blanket Dark',
      key: 'blanket_dark',
      digit: '7',
    },
    starch_shirts: {
      name: 'Starch Shirts',
      key: 'starch_shirts',
      digit: '2',
    },
  },
  wetCleaning: {
    wet_cleaning_lights: {
      name: 'Wet Cleaning Lights',
      key: 'wet_cleaning_lights',
      digit: '15',
    },
    wet_cleaning_whites: {
      name: 'Wet Cleaning Whites',
      key: 'wet_cleaning_whites',
      digit: '15',
    },
    wet_cleaning_colors: {
      name: 'Wet Cleaning Colors',
      key: 'wet_cleaning_colors',
      digit: '15',
    },

    wet_cleaning_sensitive_lights: {
      name: 'Wet Cleaning Sensitive Lights',
      key: 'wet_cleaning_sensitive_lights',
      digit: '18',
    },
    wet_cleaning_sensitive_whites: {
      name: 'Wet Cleaning Sensitive Whites',
      key: 'wet_cleaning_sensitive_whites',
      digit: '18',
    },
    wet_cleaning_sensitive_colors: {
      name: 'Wet Cleaning Sensitive Colors',
      key: 'wet_cleaning_sensitive_colors',
      digit: '18',
    },

    wet_cleaning_silk_lights: {
      name: 'Wet Cleaning Silk Lights',
      key: 'wet_cleaning_silk_lights',
      digit: '18',
    },
    wet_cleaning_silk_colors: {
      name: 'Wet Cleaning Silk Colors',
      key: 'wet_cleaning_silk_colors',
      digit: '18',
    },
    wet_cleaning_silk_black: {
      name: 'Wet Cleaning Silk Black',
      key: 'wet_cleaning_silk_black',
      digit: '18',
    },
    wet_cleaning_silk_whites: {
      name: 'Wet Cleaning Silk Whites',
      key: 'wet_cleaning_silk_whites',
      digit: '18',
    },
    wet_cleaning_silk_dark_colors: {
      name: 'Wet Cleaning Silk Dark Colors',
      key: 'wet_cleaning_silk_dark_colors',
      digit: '18',
    },
    wet_cleaning_leather_whites: {
      name: 'Wet Cleaning Leather Whites',
      key: 'wet_cleaning_leather_whites',
      digit: '19',
    },
    wet_cleaning_leather_dark_colors: {
      name: 'Wet Cleaning Leather Dark Colors',
      key: 'wet_cleaning_leather_dark_colors',
      digit: '19',
    },
    wet_cleaning_leather_black: {
      name: 'Wet Cleaning Leather Black',
      key: 'wet_cleaning_leather_black',
      digit: '19',
    },
    wet_cleaning_leather_colors: {
      name: 'Wet Cleaning Leather Color',
      key: 'wet_cleaning_leather_colors',
      digit: '19',
    },
    wet_cleaning_leather_lights: {
      name: 'Wet Cleaning Leather Lights',
      key: 'wet_cleaning_leather_lights',
      digit: '19',
    },

    wet_cleaning_t_shirt_whites: {
      name: 'T-Shirt Whites',
      key: 'wet_cleaning_t_shirt_whites',
      digit: '17',
    },
    wet_cleaning_t_shirt_colors: {
      name: 'T-Shirt Colors',
      key: 'wet_cleaning_t_shirt_colors',
      digit: '17',
    },
    wet_cleaning_t_shirt_lights: {
      name: 'T-Shirt Lights',
      key: 'wet_cleaning_t_shirt_lights',
      digit: '17',
    },

    wet_cleaning_pants_whites: {
      name: 'Wet Cleaning Pants Whites',
      key: 'wet_cleaning_pants_whites',
      digit: '16',
    },
    wet_cleaning_pants_colors: {
      name: 'Wet Cleaning Pants Colors',
      key: 'wet_cleaning_pants_colors',
      digit: '16',
    },

    wet_cleaning_disinfect: {
      name: 'Wet Cleaning Disinfect',
      key: 'wet_cleaning_disinfect',
      digit: '18',
    },
    wet_cleaning_disinfect_colors: {
      name: 'Wet Cleaning Disinfect Colors',
      key: 'wet_cleaning_disinfect_colors',
      digit: '18',
    },
    wet_cleaning_disinfect_lights: {
      name: 'Wet Cleaning Disinfect Lights',
      key: 'wet_cleaning_disinfect_lights',
      digit: '18',
    },
    wet_cleaning_disinfect_whites: {
      name: 'Wet Cleaning Disinfect Whites',
      key: 'wet_cleaning_disinfect_whites',
      digit: '18',
    },

    wet_cleaning_sensitive_linens: {
      name: 'Wet Cleaning Sensitive Linens',
      key: 'wet_cleaning_sensitive_linens',
      digit: '16',
    },
  },
  dryCleaning: {
    dry_cleaning_lights: {
      name: 'Dry Cleaning Lights',
      key: 'dry_cleaning_lights',
      digit: '12',
    },
    dry_cleaning_whites: {
      name: 'Dry Cleaning Whites',
      key: 'dry_cleaning_whites',
      digit: '12',
    },
    dry_cleaning_dark_colors: {
      name: 'Dry Cleaning Dark Colors',
      key: 'dry_cleaning_dark_colors',
      digit: '13',
    },
    dry_cleaning_blacks: {
      name: 'Dry Cleaning Black',
      key: 'dry_cleaning_blacks',
      digit: '13',
    },
  },
  handCleaning: {
    hand_cleaning: {
      name: 'Hand Cleaning',
      key: 'hand_cleaning',
      digit: '20',
    },
  },
};

/** Mapper for selecting the active color in each category */
export const categoryColorMapper: { [key: string]: '#8B5C5E' | '#f3aa39' | '#19a784' | '#3e48a6' } = {
  laundry: '#19a784',
  wetCleaning: '#3e48a6',
  dryCleaning: '#f3aa39',
  handCleaning: '#8B5C5E',
};

/** PreSelection Response Helper Function */
const preSelectionHelper = (programMapperCat: string, responseCat: string, responseArray: string[]) => {
  if (responseArray.length <= 0) {
    return [];
  }
  return Object.keys(programMapper[programMapperCat])
    .map((key: string) => {
      const temp = responseArray
        .map((name: string) => {
          return key === name ? programMapper[programMapperCat][name] : undefined;
        })
        .filter((key: any) => key !== undefined);

      return { ...temp[0] };
    })
    .filter(obj => Object.keys(obj).length > 0);
};

const getCategoryArray = (responseObj: SuggestedProgramResponse[], category: string) => {
  return responseObj
    .map(cat => {
      if (cat.category.name === category) {
        return cat.programList.map(program => {
          return program.key;
        });
      }
      return '';
    })
    .filter(item => {
      return item;
    });
};

/** PreSelection Response Mapper */
export const parseSuggestedPrograms = (responseObj: SuggestedProgramResponse[]) => {
  const WC = getCategoryArray(responseObj, 'WC')[0] || [];
  const L = getCategoryArray(responseObj, 'L')[0] || [];
  const DC = getCategoryArray(responseObj, 'DC')[0] || [];
  const HC = getCategoryArray(responseObj, 'HC')[0] || [];

  return {
    WC: preSelectionHelper('wetCleaning', 'WC', WC),
    L: preSelectionHelper('laundry', 'L', L),
    DC: preSelectionHelper('dryCleaning', 'DC', DC),
    HC: preSelectionHelper('handCleaning', 'HC', HC),
  };
};

/** Retrieve the Category Color from object */
export const getCategoryColor = (programObj: IProgramType) => {
  let category: string = '';

  Object.keys(programMapper).map((key: string) => {
    return Object.keys(programMapper[key]).map((program: string) => {
      if (programMapper[key][program].name === programObj.name) {
        return (category = key);
      }
      return false;
    });
  });

  return categoryColorMapper[category];
};
