import React, { useState, useEffect } from 'react';
import { useStationStore } from 'stores';
import { observer } from 'mobx-react-lite';
/** Import Enums */
import { typeMapper, ITypeObj } from 'utils/maps/typeMapper';
import { getCategoryColor } from 'utils/maps/programMapper';
import { Brand, ColorsList, Program } from 'utils/interfaces/stationInterfaces';
import { BrandDisplay } from 'components/brandDisplay';
import { ColorMapper } from 'components/colorMapper';

import './style.scss';
/** Material UI */
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { itemTypeIconMap } from 'utils/maps';

const useContentStyle = makeStyles((theme) => ({
  contentContainer: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    color: theme.palette.text.secondary,
  },
}));

export type StepperKeys = 'type' | 'brand' | 'color' | 'programs' | 'photo' | 'stainDamage';

type IContentMapper = {
  [key in StepperKeys]: contentMapperKeys[key];
};

type contentMapperKeys = {
  type: (obj: ITypeObj) => JSX.Element;
  brand: (obj: Brand) => JSX.Element;
  color: (obj: ColorsList[]) => JSX.Element;
  programs: (obj: Program) => JSX.Element;
  photo: (obj: string) => JSX.Element;
  stainDamage: (obj: { [key: string]: boolean }) => JSX.Element;
};

export const contentMapper: IContentMapper = {
  type: (selectedObj: ITypeObj) => {
    if (Boolean(selectedObj)) {
      return <img src={itemTypeIconMap[selectedObj.value]} alt="" />;
    }
    return <></>;
  },
  brand: (selectedObj: Brand) => {
    if (Boolean(selectedObj)) {
      return <BrandDisplay brand={selectedObj} isHorizontal logoWidth="40px" logoHeight="40px" />;
    }
    return <></>;
  },
  color: (selectedObj: ColorsList[]) => {
    if (Boolean(selectedObj)) {
      return <ColorMapper colorsList={selectedObj} isVertical textHidden withShadows dimension="50px" />;
    }
    return <></>;
  },
  programs: (selectedObj: Program) => {
    if (Boolean(selectedObj)) {
      return (
        <Typography variant="body1" className="type-jsx" style={{ color: getCategoryColor(selectedObj) }}>
          {selectedObj.name}
        </Typography>
      );
    }
    return <></>;
  },
  photo: (selectedObj: string) => {
    if (Boolean(selectedObj)) {
      return <img src={selectedObj} width="120px" height="160px" alt="Washmen" style={{ objectFit: 'cover' }} />;
    }
    return <></>;
  },
  stainDamage: (selectedObj: { [key: string]: boolean }) => {
    if (Boolean(selectedObj)) {
      return (
        <Box display="flex" flexDirection="column">
          <Typography variant="body1" className="stain-flag">
            Damaged : {selectedObj.DAMAGED ? 'Yes' : 'No'}
          </Typography>
          <Typography variant="body1" className="stain-flag">
            Stained : {selectedObj.STAINED ? 'Yes' : 'No'}
          </Typography>
        </Box>
      );
    }
    return <></>;
  },
};

interface Props {
  itemProperty: StepperKeys;
}

// added these two new types due to the new TypeScript update
// the first is for the State, the second is for mapping all of them
type ProgramTypes = ITypeObj | Brand | ColorsList[] | Program | string | { [key: string]: boolean };
type FullProgramTypes = ITypeObj & Brand & ColorsList[] & Program & string & { [key: string]: boolean };

const ContentMapperComponent: React.FC<Props> = observer(({ itemProperty }) => {
  //Import Style
  const classes = useContentStyle();
  const { currentItem } = useStationStore();
  // Declare State
  const [selectedObj, setSelectedObj] = useState<ProgramTypes | null | undefined>();

  useEffect(() => {
    switch (itemProperty) {
      case 'type':
        if (currentItem && currentItem.type) {
          Object.keys(typeMapper).filter((category: string) => {
            return Object.keys(typeMapper[category]).filter((typeValue: string) => {
              return typeMapper[category][typeValue].value === currentItem.type ? setSelectedObj(typeMapper[category][typeValue]) : false;
            });
          });
          break;
        }
        setSelectedObj(undefined);
        break;
      case 'brand': {
        if (currentItem && currentItem.brand) {
          setSelectedObj({ ...currentItem.brand });
          break;
        }
        setSelectedObj(undefined);
        break;
      }
      case 'color': {
        if (currentItem && currentItem.colorsList) {
          setSelectedObj(currentItem.colorsList);
          break;
        }
        setSelectedObj(undefined);
        break;
      }
      case 'programs': {
        if (currentItem && currentItem.programs && currentItem.programs.program) {
          setSelectedObj({ ...currentItem.programs.program });
          break;
        }
        setSelectedObj(undefined);
        break;
      }
      case 'photo': {
        if (currentItem && currentItem.frontImage) {
          setSelectedObj(currentItem.frontImage);
          break;
        }
        setSelectedObj(undefined);
        break;
      }
      case 'stainDamage': {
        let reportDetail: { [key: string]: boolean } = {};
        if (currentItem && currentItem.reportDetailsList) {
          if (currentItem.reportDetailsList && currentItem.reportDetailsList.find((item) => item.reason === 'STAINED')) {
            reportDetail = { STAINED: true };
          }
          if (currentItem.reportDetailsList && currentItem.reportDetailsList.find((item) => item.reason === 'DAMAGED')) {
            reportDetail = { ...reportDetail, DAMAGED: true };
          }
          setSelectedObj(Object.keys(reportDetail).length > 0 ? reportDetail : null);
        }
        break;
      }
    }
  }, [itemProperty, currentItem]);

  return (
    <Grid item sm={2} md={2} lg={2}>
      <Box className={classes.contentContainer}>{contentMapper[itemProperty](selectedObj as FullProgramTypes)}</Box>
    </Grid>
  );
});

export default ContentMapperComponent;
