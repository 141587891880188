import React, { useState } from 'react';
import styled from 'styled-components';
import { styled as MUIStyled, Typography, Fab, Grid, Button, Card, CardContent, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import _ from 'lodash';

import { CustomDialog } from 'components/dialogs';
import { DelayReasonsMap } from 'utils/maps/delayReasonaMapper';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { ReactComponent as DelayDaysIcon } from 'assets/images/svgs/delay-days.svg';
import { ReactComponent as NoDelaySelected } from 'assets/images/svgs/no-delay.svg';

interface Props {
  open: boolean;
  incrementedNumber: number;
  isDecrementDisabled: boolean;
  isSubmitButtonDisabled: boolean;
  incrementCount: () => void;
  decrementCount: () => void;
  onSubmit: (reportedReason: string, type: 'Pending' | 'Damaged') => Promise<boolean>;
  onClose: (clearData?: boolean) => void;
}

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    padding: '4rem 0',
  },
  reasonTitle: {
    marginTop: '2rem',
    marginBottom: '3rem',
  },
  reason: {
    alignSelf: 'flex-start',
  },
  reasonContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: '2rem',
    overflowX: 'hidden',
    maxWidth: '100%',
    width: '100%',
    maxHeight: '27rem',
    boxSizing: 'border-box',
  },
  card: {
    flexBasis: '48%',
    margin: '10px',
    minWidth: '48rem',
    cursor: 'pointer',
    height: '3.4rem',
    border: '2px solid #B6BCD1',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: '#EEF0FF',
      borderColor: '#3e48a6',
    },
    boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '16px !important',
  },
  active: {
    backgroundColor: '#EEF0FF',
    borderColor: '#3e48a6',
  },
});

const ReasonCard = ({ title, onChange, activeReason }: { title: string; activeReason: string; onChange: (reason: string) => void }) => {
  const classes = useStyles();

  return (
    <Card className={`${classes.card} ${title === activeReason ? classes.active : ''}`} onClick={() => onChange(title)}>
      <CardContent className={classes.content}>
        <Typography variant="body1">{title}</Typography>
      </CardContent>
    </Card>
  );
};

const DelayDialog: React.FC<Props> = ({
  open,
  incrementedNumber,
  incrementCount,
  decrementCount,
  isDecrementDisabled,
  isSubmitButtonDisabled,
  onSubmit,
  onClose,
}) => {
  const classes = useStyles();

  const [activeReasonCategory, setActiveReasonCategory] = useState('');
  const [activeReason, setActiveReason] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const submitDayAndReason = () => {
    if (activeReason === '') return;
    setIsSubmitDisabled(true);
    onSubmit(activeReason, 'Pending').then((isSuccess) => isSuccess && onCloseModal());
  };
  const onCloseModal = (clearData?: boolean, event?: object, reason?: string) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
    setActiveReasonCategory('');
    setActiveReason('');
    onClose(clearData);
  };

  const handleTabChange = (_event: React.MouseEvent<HTMLElement>, value: string) => {
    if (value) {
      setActiveReasonCategory(value);
      setActiveReason('');
    }
  };

  const handleReasonChange = (reason: string) => {
    setActiveReason(reason);
    setIsSubmitDisabled(false);
  };

  return (
    <CustomDialog
      open={open}
      onClose={(event, reason) => onCloseModal(true, event, reason)}
      incrementedNumber={incrementedNumber}
      size="xl"
      disableAutoFocus={true}
      custom_size={{ width: '100%', height: '100%' }}
    >
      <Grid wrap="nowrap" direction="column" container alignItems="center" justifyContent={'center'} style={{ padding: '0 1rem' }}>
        <Grid container direction="row" item>
          <Grid item xs>
            <Grid container justifyContent="flex-start" alignItems="center">
              <DelayDaysIcon width="5rem" height="5rem" />
              <Typography variant="h5" paragraph>
                <b>How many extra days?</b>
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs>
            <Grid container justifyContent="flex-end" alignItems="center">
              <ActionFab
                color="primary"
                size="large"
                variant="circular"
                onClick={decrementCount}
                disabled={isDecrementDisabled || isSubmitButtonDisabled}
              >
                <RemoveIcon />
              </ActionFab>
              <CustomTypography variant="h3">{incrementedNumber}</CustomTypography>
              <ActionFab disabled={isSubmitButtonDisabled} color="primary" size="large" variant="circular" onClick={incrementCount}>
                <AddIcon />
              </ActionFab>
            </Grid>
          </Grid>
        </Grid>
        <Grid style={{ minHeight: '38rem' }} container item direction="column" alignItems="center">
          <Grid container item classes={{ container: classes.reasonTitle }}>
            <Typography variant="h5">
              <b>Select a delay reason</b>
            </Typography>
          </Grid>
          <Grid container justifyContent="center">
            <StyledToggleButtonGroup size="large" exclusive value={activeReasonCategory} onChange={handleTabChange}>
              {Object.keys(DelayReasonsMap).map((type: string, i) => {
                return (
                  <ToggleButton color="primary" value={type} style={{ borderRadius: '50px', width: '12rem', height: '4rem' }}>
                    {_.startCase(type)}
                  </ToggleButton>
                );
              })}
            </StyledToggleButtonGroup>
          </Grid>
          <Grid container {...(activeReasonCategory === '' && { justifyContent: 'center' })}>
            {activeReasonCategory && (
              <Box display={'flex'} className={classes.reasonContainer}>
                {DelayReasonsMap[activeReasonCategory].map((reason: string, i) => {
                  return <ReasonCard title={reason} onChange={handleReasonChange} activeReason={activeReason}></ReasonCard>;
                })}
              </Box>
            )}
            {activeReasonCategory === '' && <StyledNoDelayIcon />}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs justifyContent="center" alignItems="center">
        <Actionbutton color="primary" size="large" variant="outlined" onClick={() => onClose(true)}>
          Cancel
        </Actionbutton>
        <Actionbutton color="primary" size="large" variant="contained" onClick={submitDayAndReason} disabled={isSubmitDisabled}>
          submit
        </Actionbutton>
      </Grid>
    </CustomDialog>
  );
};

export default DelayDialog;

const Actionbutton = MUIStyled(Button)({
  padding: '1rem 3rem',
  margin: '2rem',
  width: '20%',
});

const ActionFab = MUIStyled(Fab)({
  margin: '0 2rem',
});

const CustomTypography = MUIStyled(Typography)({
  padding: '1rem 0',
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  .MuiToggleButtonGroup-groupedHorizontal:not(:last-child),
  .MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
    height: 4rem;
    width: 8rem;
    border: 3px solid #b6bcd1;
    border-radius: 5px;
  }
  .MuiToggleButton-root.Mui-selected + .MuiToggleButton-root.Mui-selected,
  .MuiToggleButton-root.Mui-selected {
    color: #3e48a6;
    background-color: #ffffff;
    border-color: #3e48a6;
    border: 3px solid #3e48a6;
  }
`;

const StyledNoDelayIcon = styled(NoDelaySelected)`
  width: 18rem;
  margin-top: 7rem;
`;
