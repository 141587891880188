import { useQuery } from 'react-query';

import { PendingService } from 'services';
import { IOrderWithItems, OrderServiceLines } from 'utils/interfaces';
import { FilterDefaultValue } from 'components/filters';
import { useQueryFilters } from 'hooks';

type FiltersFromAPI = { label: string; value: string }[];
export interface fetchedFilters {
  driversIdsListFilter: FiltersFromAPI;
  geofencesIdsListFilter: FiltersFromAPI;
  clustersIdsListFilter: FiltersFromAPI;
  itemTypesListFilter: FiltersFromAPI;
  orderIdsListFilter: FiltersFromAPI;
  orderStatusListFilter: FiltersFromAPI;
  isDelayedListFilter: FiltersFromAPI;
}

interface Filters {
  [x: string]: FilterDefaultValue | boolean | number;
}

export interface IOptionsToFilterBy extends Partial<Filters> {
  driversIdsListFilter?: FiltersFromAPI;
  clustersIdsListFilter?: FiltersFromAPI;
  geofencesIdsListFilter?: FiltersFromAPI;
  itemTypesListFilter?: FiltersFromAPI;
  orderIdsListFilter?: FiltersFromAPI;
  isDelayedListFilter?: string[];
  orderStatusListFilter?: string[];
  isHungFilter?: string;
  isUrgentFilter?: string;
  isPackagedFilter?: string;
  isProblematicFilter?: string;
  dateFilter?: { start: Date; end: Date };
  orderServiceLinesListFilter?: OrderServiceLines[];
}

export interface QueryFilters extends IOptionsToFilterBy {
  pageNumber?: number;
  isItemsLastActivityNotPackagedFilter?: boolean;
}

export interface PaginationMeta {
  fullItemsCount: number;
  hasNextPage: boolean;
}
export interface QueryResponse {
  ordersItemsList: IOrderWithItems[];
  baseFilters: fetchedFilters;
  paginationMeta: PaginationMeta;
}

/**
 * useGetOrdersWithItemsList
 * @description Takes filters and pageNumber and returns filtered data (OrdersWithItemsList)
 * @param {IOptionsToFilterBy} filters
 * @param {number}  pageNumber--OPTIONAL
 *
 * @returns {json}
 * { ordersItemsList,baseFilters,paginationMeta,isSuccess,isError,isFetching }
 *
 * @example useGetOrdersWithItemsList({orderIdsListFilter : [ { label: "AA001", value: "AA001" } ], isHungFilter : "true" }, 1)
 */
const useGetOrdersWithItemsList = (
  filters?: IOptionsToFilterBy,
  pageNumber?: number,
  orderServiceLine: OrderServiceLines = OrderServiceLines.LAUNDRY
) => {
  const queryFilters = useQueryFilters<QueryFilters>(
    {
      pageNumber,
      isItemsLastActivityNotPackagedFilter: filters?.isPackagedFilter === 'false',
      orderServiceLinesListFilter: [orderServiceLine],
    },
    filters
  );

  const { data, isSuccess, isError, isFetching } = useQuery<QueryResponse, Error>(
    ['ordersWithItemsList', pageNumber, { ...queryFilters }],
    () => PendingService.fetchPendingItems({ ...queryFilters }),
    {
      initialData: {
        ordersItemsList: [],
        baseFilters: {
          clustersIdsListFilter: [],
          driversIdsListFilter: [],
          geofencesIdsListFilter: [],
          isDelayedListFilter: [],
          itemTypesListFilter: [],
          orderIdsListFilter: [],
          orderStatusListFilter: [],
        },
        paginationMeta: { fullItemsCount: 0, hasNextPage: false },
      },

      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  return {
    ordersItemsList: data ? data.ordersItemsList : [],
    baseFilters: data?.baseFilters ?? ({} as fetchedFilters),
    paginationMeta: data?.paginationMeta ?? { fullItemsCount: 0, hasNextPage: false },
    isSuccess,
    isError,
    isFetching,
  };
};

export default useGetOrdersWithItemsList;
