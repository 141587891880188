import { BagTypes } from 'utils/enums/stationEnums';

import BlueBag from 'assets/images/pngs/bags/blue-bag-inactive.png';
import GreenBag from 'assets/images/pngs/bags/green-bag-inactive@3x.png';
import WhiteBag from 'assets/images/pngs/bags/white-bag-inactive@3x.png';
import PinkBag from 'assets/images/pngs/bags/pink-bag.png';
import OrangeBag from 'assets/images/pngs/bags/orange-bag-circle@3x.png';
import PurpleBag from 'assets/images/pngs/bags/purple-bag-inactive.png';

const BagTypeMap: { [key in BagTypes]: string } = {
  CP: GreenBag,
  WF: BlueBag,
  P: WhiteBag,
  HC: PinkBag,
  SC: OrangeBag,
  TF: PurpleBag,
};

export default BagTypeMap;
