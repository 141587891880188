import moment from 'moment';

/**
 *
 * FormatDropOffDate(date:string)
 *
 * @description Takes a date and formats it to return
 * ('Today' | 'Tomorrow' | 'actual date') +  Day / Month
 *
 * @param {string} date
 *
 * @returns {string} FormattedDropOffDate
 */
export const formatDropOffDate = (date: string) => {
  // Sanity Check
  if (!date || date === '') return 'Invalid Date';

  const REFERENCE = moment(date);
  const TODAY = moment();
  const TOMORROW = TODAY.clone().add(2, 'days').startOf('day');

  if (REFERENCE.isSame(moment(TODAY), 'day')) return `Today, ${REFERENCE.format('D MMM')}`;

  if (REFERENCE.isBefore(TOMORROW) && REFERENCE.isAfter(TODAY)) return `Tomorrow, ${REFERENCE.format('D MMM')}`;

  return `${REFERENCE.format('dddd, D MMM')}`;
};

/**
 *
 * dropOffDateColor(date:string)
 *
 * @description Takes a date and
 *  returns true if :  the date is today or Less,
 *  returns false if : the date is greater than today
 *
 * @param {string} date
 *
 * @returns {boolean} isTodayOrLess
 */
export const dropOffDateColor = (dropoffDate: string) => {
  const _today = new Date();
  const _dropOffDate = new Date(dropoffDate);

  return _today.getTime() > _dropOffDate.getTime() ? true : false;
};

/**
 *
 * inTheNextGivenDays(date:string, days:number)
 *
 * @description Takes a date and a number of days
 *  returns true if :  the date is in the next given number of days
 *
 * @param {string} date
 * @param {number} days
 *
 * @returns {boolean} inTheNextGivenDays
 */
export const inTheNextGivenDays = (date: string, days: number): boolean => {
  const reference = moment(date);
  const today = moment();
  const givenDays = today.clone().add(days, 'days').startOf('day');

  if (reference.isAfter(today) && reference.isAfter(givenDays)) return true;

  return false;
};

/**
 *
 * inThePastGivenDays(date:string, days:number)
 *
 * @description Takes a date and a number of days
 *  returns true if :  the date is in the past given number of days
 *
 * @param {string} date
 * @param {number} days
 *
 * @returns {boolean} inThePastGivenDays
 */
export const inThePastGivenDays = (date: string, days: number): boolean => {
  const reference = moment(date);
  const now = moment();
  const intervalLimitDate = now.clone().subtract(days, 'days').startOf('day');

  if (reference.isSameOrAfter(intervalLimitDate)) return true;

  return false;
};

/**
 *
 * formatCurrentDate(date:string)
 *
 * @description Takes a date and formats it to return
 * ('Today' | 'Tomorrow' | 'actual date')
 *
 * @param {string} date
 *
 * @returns {string} FormattedDropOffDate
 */
export const formatCurrentDate = (date: string, format: string | undefined) => {
  // Sanity Check
  if (!date || date === '') return 'Invalid Date';

  const REFERENCE = moment(date);
  const TODAY = moment();
  const TOMORROW = TODAY.clone().add(2, 'days').startOf('day');

  if (REFERENCE.isSame(moment(TODAY), 'day')) return `Today`;

  if (REFERENCE.isBefore(TOMORROW) && REFERENCE.isAfter(TODAY)) return `Tomorrow`;

  return `${REFERENCE.format(format ? format : 'dddd, D MMM')}`;
};

export const DROPOFF_TIME_FORMAT = 'hmmA';
