import { createContext, useContext } from 'react';
//TODO: Remove when migrating react-scripts and mobx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { observable, action, computed } from 'mobx';

import { stationStore } from './stationStore';

import { IGroup, IItemGroup, IOrderItem, OrderServiceLines } from 'utils/interfaces';
import { ScanType } from 'utils/enums/stationEnums';
import { StationService } from 'services';

type scanners = 'rfid' | 'qrCode' | 'barCode';

/**
 * @description packaging store to manage data
 */
export class PackagingStore {
  @observable itemsOfOrder: IOrderItem[] = [];
  @observable orderItems: IOrderItem[] = [];
  @observable indexedOrderItems: { [id: string]: IOrderItem } = {};
  @observable packageSerialId: string = '';
  @observable disabled: boolean = true;
  @observable renderedBannedItems: boolean = false;
  @observable initialPackageSerialId: string = '';
  @observable storedPrintResponse: string | undefined;
  @observable scanType: 'rfid' | 'qrCode' | 'barCode' | undefined = undefined;
  @observable selectedItem: IOrderItem | undefined = {} as IOrderItem;
  @observable groupedItems: IOrderItem[] = [];
  @observable showGroupItemsModal: boolean = false;

  get fetchedOrderItems() {
    return this.itemsOfOrder.length > 0;
  }

  get orderId() {
    if (this.orderItems.length > 0) {
      return this.orderItems[0].orderId;
    }
    return '';
  }

  @computed get itemsCodeList() {
    let itemsCodeList: string[] = [];
    this.orderItems.forEach((item: IOrderItem) => {
      if (item.isScannedOnPackaging) {
        itemsCodeList.push(item.code);
      }
    });
    return itemsCodeList;
  }

  /**
   * @description Store OrderItems
   */
  @action
  setOrderItems = (value: IOrderItem[]) => {
    this.orderItems = value;
  };

  @action
  setPackageId = (value: string) => {
    this.packageSerialId = value;
  };

  @action setStoredPrintResponse = (value: string | undefined) => {
    this.storedPrintResponse = value;
  };
  @action
  setInitialPackageSerialId = (value: string) => {
    this.initialPackageSerialId = value;
  };

  /**
   * @description Disable/Enable NEXT Button
   */
  @action
  setDisabled = (value: boolean) => {
    this.disabled = value;
  };
  @action.bound
  setSelectedItem(item: IOrderItem | undefined) {
    this.selectedItem = item ? { ...item } : undefined;
  }

  /**
   * Set the scanner type on the banned packaging station
   *
   * @param type - Scanner type
   */
  @action
  setScanType = (type: scanners) => {
    this.scanType = type;
  };

  /**
   * Indicates if the banned items have been rendered
   *
   * @param value - true/false
   */
  @action
  setRenderedBannedItems = (value: boolean) => {
    this.renderedBannedItems = value;
  };

  /**
   * Set the array of all items in an order
   *
   * @param allOrderItems - all items of an order
   */
  @action
  setItemsOfOrder = (allOrderItems: IOrderItem[]) => {
    this.itemsOfOrder = allOrderItems;
  };

  /**
   * Set the array of grouped items of an order
   *
   * @param value - grouped items of an order
   */
  @action
  setGroupedItems = (value: IOrderItem[]) => {
    this.groupedItems = value;
  };

  /**
   * Indicates if the group items modal have been rendered
   *
   * @param value - true/false
   */
  @action
  setShowGroupItemsModal = (value: boolean) => {
    this.showGroupItemsModal = value;
  };

  /**
   * Called when an item is scanned
   *
   * @param code
   * @param codeType
   */
  @action
  handleOnScanItem = async (code: string, codeType: ScanType) => {
    console.log(this.orderItems.filter((i) => i.isScannedOnPackaging));

    const getOrderItemFunctions: { [key in ScanType]?: () => Promise<IOrderItem> } = {
      rfid: async () => await StationService.getOrderItemByRfidCode(code),
      qrCode: async () => await StationService.getOrderItemByQrCode(code),
    };
    let item: IOrderItem = await getOrderItemFunctions[codeType]!();

    let itemsOfOrder: IOrderItem[];
    let fetchedItemsGroups: IItemGroup = {
      groups: [],
      items: [],
      allItems: [],
      recommendations: [],
    };
    if (this.fetchedOrderItems) {
      itemsOfOrder = this.itemsOfOrder;

      if (this.orderId && this.orderId !== item.orderId) {
        throw new Error("Item doesn't belong to the same orde");
      }
    } else {
      itemsOfOrder = await stationStore.fetchItemsByOrderAlphaId({ itemCode: code });
      const response = await StationService.fetchItemGroups(itemsOfOrder[0].orderAlphaId, false, OrderServiceLines.LAUNDRY);
      fetchedItemsGroups = response;

      // reset indexedOrderItems each time the order items are fetched
      this.indexedOrderItems = {};

      this.setItemsOfOrder(itemsOfOrder);
    }

    // if the banned items haven't been rendered yet and the scanned item is banned and is hung
    if (!this.renderedBannedItems && item.isBanned && item.isHung) {
      itemsOfOrder.forEach((i) => {
        if (i.isBanned && i.isHung && (!i.isPackaged || item.id === i.id)) {
          this.indexedOrderItems[i.id] = { ...i, isScannedOnPackaging: item.id === i.id };
        }
      });
      this.setRenderedBannedItems(true);
    } else {
      this.indexedOrderItems[item.id] = { ...item, isScannedOnPackaging: true };
    }

    let groupedItems: IOrderItem[] = [];
    if (fetchedItemsGroups.groups.length) {
      fetchedItemsGroups.groups.forEach((group: IGroup) => {
        if (group.itemCodesList.includes(item.code)) {
          group.itemCodesList.forEach((code) => {
            const foundItem = fetchedItemsGroups.items.find((i) => i.itemCode === code);
            if (foundItem) {
              if (!foundItem.frontImage && foundItem.itemImagesMap) {
                foundItem.frontImage = foundItem.itemImagesMap.front;
              }
              groupedItems.push(foundItem);
              this.indexedOrderItems[foundItem.id] = { ...foundItem, isScannedOnPackaging: foundItem.id === item.id };

              if (foundItem.extraItemsList) {
                groupedItems = [...groupedItems, ...(foundItem.extraItemsList as IOrderItem[])];
              }
            }
          });
        }
      });
    }

    if (groupedItems.length) {
      this.setGroupedItems(groupedItems);
      this.setShowGroupItemsModal(true);
    } else {
      this.setGroupedItems([]);
      this.setShowGroupItemsModal(false);
    }

    this.setOrderItems(Object.values(this.indexedOrderItems));
    this.setDisabled(false);
    this.setSelectedItem(item);
  };

  /**
   * Called when a scanned item is deleted
   *
   * @param orderItem - item
   */
  @action
  handleOnDeleteItem = (orderItem: IOrderItem) => {
    if (orderItem.isBanned && orderItem.isHung) {
      this.indexedOrderItems[orderItem.id] = { ...orderItem, isScannedOnPackaging: false };
    } else {
      delete this.indexedOrderItems[orderItem.id];
    }
    let items = Object.values(this.indexedOrderItems);
    this.setOrderItems(items);

    // if all the scanned items were deleted
    if (!this.itemsCodeList.length) {
      this.setDisabled(true);
      if (!items.length) {
        this.indexedOrderItems = {};
        this.setItemsOfOrder([]);
        this.setRenderedBannedItems(false);
      }
    }
  };
}

const packagingStore = new PackagingStore();
const PackagingStoreContext = createContext(packagingStore);

export const usePackagingStore = () => useContext(PackagingStoreContext);
