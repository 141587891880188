import AbayaIcon from 'assets/images/pngs/GarmentTypesIcon/abaya.png';
import BathrobeIcon from 'assets/images/pngs/GarmentTypesIcon/bathrobe.png';
import BedsheetsIcon from 'assets/images/pngs/GarmentTypesIcon/bedsheets.png';
import BeltIcon from 'assets/images/pngs/GarmentTypesIcon/belt.png';
import BlouseIcon from 'assets/images/pngs/GarmentTypesIcon/blouse.png';
import BraIcon from 'assets/images/pngs/GarmentTypesIcon/bra.png';
import CarpetIcon from 'assets/images/pngs/GarmentTypesIcon/carpet.png';
import CoatIcon from 'assets/images/pngs/GarmentTypesIcon/coat.png';
import DuvetIcon from 'assets/images/pngs/GarmentTypesIcon/duvet.png';
import GathraIcon from 'assets/images/pngs/GarmentTypesIcon/gathra.png';
import JacketIcon from 'assets/images/pngs/GarmentTypesIcon/jacket.png';
import JeansIcon from 'assets/images/pngs/GarmentTypesIcon/jeans.png';
import JumpsuitIcon from 'assets/images/pngs/GarmentTypesIcon/jumpsuit.png';
import KanduraIcon from 'assets/images/pngs/GarmentTypesIcon/kandura.png';
import LeggingIcon from 'assets/images/pngs/GarmentTypesIcon/legging.png';
import LongDressIcon from 'assets/images/pngs/GarmentTypesIcon/longdress.png';
import WinterJacketIcon from 'assets/images/pngs/GarmentTypesIcon/winter-jacket.png';
import WinterOvercoatIcon from 'assets/images/pngs/GarmentTypesIcon/winter-overcoat.png';
import OvercoatIcon from 'assets/images/pngs/GarmentTypesIcon/overcoat.png';
import PantsIcon from 'assets/images/pngs/GarmentTypesIcon/pants.png';
import SuitPantsIcon from 'assets/images/pngs/GarmentTypesIcon/suitpants.png';
import PullOverIcon from 'assets/images/pngs/GarmentTypesIcon/pullover.png';
import PillowCaseIcon from 'assets/images/pngs/GarmentTypesIcon/pillowcase.png';
import PillowIcon from 'assets/images/pngs/GarmentTypesIcon/pillow.png';
import ScarfIcon from 'assets/images/pngs/GarmentTypesIcon/skarf.png';
import ShirtIcon from 'assets/images/pngs/GarmentTypesIcon/shirt.png';
import ShortDressIcon from 'assets/images/pngs/GarmentTypesIcon/shortdress.png';
import ShortsIcon from 'assets/images/pngs/GarmentTypesIcon/short.png';
import SkirtIcon from 'assets/images/pngs/GarmentTypesIcon/skirt.png';
import SocksIcon from 'assets/images/pngs/GarmentTypesIcon/sock.png';
import SuitJacketIcon from 'assets/images/pngs/GarmentTypesIcon/suit-jacket.png';
import SweaterIcon from 'assets/images/pngs/GarmentTypesIcon/sweater.png';
import TshirtIcon from 'assets/images/pngs/GarmentTypesIcon/t-shirt.png';
import TableClothIcon from 'assets/images/pngs/GarmentTypesIcon/tablecloth.png';
import TableMatIcon from 'assets/images/pngs/GarmentTypesIcon/tableMat.png';
import TieIcon from 'assets/images/pngs/GarmentTypesIcon/tie.png';
import TowelIcon from 'assets/images/pngs/GarmentTypesIcon/towel.png';
import UndershirtIcon from 'assets/images/pngs/GarmentTypesIcon/undershirt.png';
import UnderwearIcon from 'assets/images/pngs/GarmentTypesIcon/underwear.png';
import VestIcon from 'assets/images/pngs/GarmentTypesIcon/vest.png';
import HandkerchiefIcon from 'assets/images/pngs/GarmentTypesIcon/handkerchief.png';
import ComforterCoverIcon from 'assets/images/pngs/GarmentTypesIcon/mattresscover.png';
import ComforterIcon from 'assets/images/pngs/GarmentTypesIcon/comforter.png';
import DoorMatIcon from 'assets/images/pngs/GarmentTypesIcon/doormat.png';
import BlanketSmallIcon from 'assets/images/pngs/GarmentTypesIcon/blanket.png';
import BlanketLargeIcon from 'assets/images/pngs/GarmentTypesIcon/blanket-large.png';
import BedCoverIcon from 'assets/images/pngs/GarmentTypesIcon/bedcover.png';
import SariIcon from 'assets/images/pngs/GarmentTypesIcon/saree.png';
import SherwaniIcon from 'assets/images/pngs/GarmentTypesIcon/sherwani.png';
import BathingSuitIcon from 'assets/images/pngs/GarmentTypesIcon/bathing-suit.png';
import PyjamaPantsIcon from 'assets/images/pngs/GarmentTypesIcon/pyjama-pant.png';
import CapIcon from 'assets/images/pngs/GarmentTypesIcon/cap.png';
import KurtaIcon from 'assets/images/pngs/GarmentTypesIcon/kurta.png';
import CurtainIcon from 'assets/images/pngs/GarmentTypesIcon/curtain.png';
import SofaCoverSmallIcon from 'assets/images/pngs/GarmentTypesIcon/sofacover.png';
import SofaCoverLargeIcon from 'assets/images/pngs/GarmentTypesIcon/sofacover-large.png';
import CushionSmallIcon from 'assets/images/pngs/GarmentTypesIcon/cushionsmall.png';
import CushionLargeIcon from 'assets/images/pngs/GarmentTypesIcon/cushionlarge.png';
import CushionCoverIcon from 'assets/images/pngs/GarmentTypesIcon/cushion-cover.png';
import BackpackIcon from 'assets/images/pngs/GarmentTypesIcon/backpack.png';
import CarpetWoolIcon from 'assets/images/pngs/GarmentTypesIcon/woolcarpet.png';
import DuffelBagIcon from 'assets/images/pngs/GarmentTypesIcon/duffelbag.png';
import SheilaIcon from 'assets/images/pngs/GarmentTypesIcon/sheila.png';
import AccessoryIcon from 'assets/images/pngs/GarmentTypesIcon/accessory.png';
import LooseItemIcon from 'assets/images/pngs/GarmentTypesIcon/looseitem.png';
import JalabiyaIcon from 'assets/images/pngs/GarmentTypesIcon/jalabiya.png';
import DelicateAbayaIcon from 'assets/images/pngs/GarmentTypesIcon/delicate-abaya.png';
import FaceMaskIcon from 'assets/images/pngs/GarmentTypesIcon/face-mask.png';
import GloveIcon from 'assets/images/pngs/GarmentTypesIcon/gloves.png';
import MixSportsSneakersIcon from 'assets/images/pngs/ShoeTypesIcon/mix/sports-sneakers.png';
import MixDesignerSneakersIcon from 'assets/images/pngs/ShoeTypesIcon/mix/designer-sneakers.png';
import MixFormalIcon from 'assets/images/pngs/ShoeTypesIcon/mix/formal.png';
import MixBootIcon from 'assets/images/pngs/ShoeTypesIcon/mix/ankle-boots.png';
import MixFlipFlopIcon from 'assets/images/pngs/ShoeTypesIcon/mix/flip-flops.png';
import MixSandalIcon from 'assets/images/pngs/ShoeTypesIcon/mix/sandals.png';
import MixEspardrillesIcon from 'assets/images/pngs/ShoeTypesIcon/mix/espardrilles.png';
import LeatherSportsSneakersIcon from 'assets/images/pngs/ShoeTypesIcon/leather/sports-sneakers.png';
import LeatherDesignerSneakersIcon from 'assets/images/pngs/ShoeTypesIcon/leather/designer-sneakers.png';
import LeatherFormalIcon from 'assets/images/pngs/ShoeTypesIcon/leather/formal.png';
import LeatherBootIcon from 'assets/images/pngs/ShoeTypesIcon/leather/ankle-boots.png';
import HighBootIcon from 'assets/images/pngs/ShoeTypesIcon/leather/high-boots.png';
import ThighHighBootIcon from 'assets/images/pngs/ShoeTypesIcon/leather/thigh-high-boots.png';
import LeatherFlipFlopIcon from 'assets/images/pngs/ShoeTypesIcon/leather/flip-flops.png';
import LeatherSandalIcon from 'assets/images/pngs/ShoeTypesIcon/leather/sandals.png';
import LeatherEspardrillesIcon from 'assets/images/pngs/ShoeTypesIcon/leather/espardrilles.png';
import SuedeNubuckSportsSneakersIcon from 'assets/images/pngs/ShoeTypesIcon/suedeNubuck/sports-sneakers.png';
import SuedeNubuckDesignerSneakersIcon from 'assets/images/pngs/ShoeTypesIcon/suedeNubuck/designer-sneakers.png';
import SuedeNubuckFormalIcon from 'assets/images/pngs/ShoeTypesIcon/suedeNubuck/formal.png';
import SuedeNubuckBootIcon from 'assets/images/pngs/ShoeTypesIcon/suedeNubuck/ankle-boots.png';
import SuedeNubuckFlipFlopIcon from 'assets/images/pngs/ShoeTypesIcon/suedeNubuck/flip-flops.png';
import SuedeNubuckSandalIcon from 'assets/images/pngs/ShoeTypesIcon/suedeNubuck/sandals.png';
import SuedeNubuckEspardrillesIcon from 'assets/images/pngs/ShoeTypesIcon/suedeNubuck/espardrilles.png';
import SythenticsTextileSportsSneakersIcon from 'assets/images/pngs/ShoeTypesIcon/sythenticsTextile/sports-sneakers.png';
import SythenticsTextileDesignerSneakersIcon from 'assets/images/pngs/ShoeTypesIcon/sythenticsTextile/designer-sneakers.png';
import SythenticsTextileFormalIcon from 'assets/images/pngs/ShoeTypesIcon/sythenticsTextile/formal.png';
import SythenticsTextileBootIcon from 'assets/images/pngs/ShoeTypesIcon/sythenticsTextile/ankle-boots.png';
import SythenticsTextileFlipFlopIcon from 'assets/images/pngs/ShoeTypesIcon/sythenticsTextile/flip-flops.png';
import SythenticsTextileSandalIcon from 'assets/images/pngs/ShoeTypesIcon/sythenticsTextile/sandals.png';
import SythenticsTextileEspardrillesIcon from 'assets/images/pngs/ShoeTypesIcon/sythenticsTextile/espardrilles.png';
import ChildrensSportsSneakersIcon from 'assets/images/pngs/ShoeTypesIcon/children-sneaker.png';
import ChildrensFormalIcon from 'assets/images/pngs/ShoeTypesIcon/children-formal.png';
import ClutchPurseIcon from 'assets/images/pngs/handBagIcons/clutch-purse.png';
import WalletsIcon from 'assets/images/pngs/handBagIcons/wallet.png';
import BagsStandardSizeIcon from 'assets/images/pngs/handBagIcons/bag-standard-size.png';
import BagsOversizedIcons from 'assets/images/pngs/handBagIcons/bag-oversized.png';
import MensBriefcaseIcon from 'assets/images/pngs/handBagIcons/mens-briefcase.png';
import ValiseSuitcaseIcon from 'assets/images/pngs/handBagIcons/suitcase.png';
import BackPackIcon from 'assets/images/pngs/handBagIcons/backpack.png';
import BeltsIcon from 'assets/images/pngs/handBagIcons/belt.png';
import ApronIcon from 'assets/images/pngs/GarmentTypesIcon/apron.png';
import StuffedToySmallIcon from 'assets/images/pngs/GarmentTypesIcon/stuffed-toy-small.png';
import StuffedToyMediumIcon from 'assets/images/pngs/GarmentTypesIcon/stuffed-toy-medium.png';
import StuffedToyLargeIcon from 'assets/images/pngs/GarmentTypesIcon/stuffed-toy-large.png';
import BridalDress from 'assets/images/pngs/GarmentTypesIcon/bridal-dress.png';
import LeatherJacket from 'assets/images/pngs/GarmentTypesIcon/leather-jacket.png';
import SleevelesUndershirt from 'assets/images/pngs/GarmentTypesIcon/sleeveles-undershirt.png';
import GiletJacket from 'assets/images/pngs/GarmentTypesIcon/gilet-jacket.png';
import CasualDress from 'assets/images/pngs/GarmentTypesIcon/casual-dress.png';
import FormalDress from 'assets/images/pngs/GarmentTypesIcon/formal-dress.png';
import EveningGown from 'assets/images/pngs/GarmentTypesIcon/evening-gown.png';
import WaistCoat from 'assets/images/pngs/GarmentTypesIcon/waist-coat.png';
import SwimmingCoat from 'assets/images/pngs/GarmentTypesIcon/swimming-suit.png';
import PyjamaTop from 'assets/images/pngs/GarmentTypesIcon/pyjama-top.png';
import WinterPants from 'assets/images/pngs/GarmentTypesIcon/winter-pants.png';
import WinterCoat from 'assets/images/pngs/GarmentTypesIcon/winter-coat.png';
import TuxedoPants from 'assets/images/pngs/GarmentTypesIcon/tuxedo-pants.png';
import TuxedoJacket from 'assets/images/pngs/GarmentTypesIcon/tuxedo-jacket.png';
import ShoesIcon from 'assets/images/pngs/GarmentTypesIcon/shoes.png';
import BagsIcon from 'assets/images/pngs/GarmentTypesIcon/bags.png';
import VeilIcon from 'assets/images/pngs/GarmentTypesIcon/veilIcon.png';
import ShoeLaceIcon from 'assets/images/pngs/GarmentTypesIcon/shoeLaceIcon.png';
import formalShoesIcon from 'assets/images/pngs/GarmentTypesIcon/formalShoesIcon.png';
import DressComplex from 'assets/images/pngs/GarmentTypesIcon/dressComplex.png';
import DressEvening from 'assets/images/pngs/GarmentTypesIcon/dressEvening.png';
import SportSweaterHoodie from 'assets/images/pngs/GarmentTypesIcon/sport-sweater-hoodie.png';
import SweaterHoodie from 'assets/images/pngs/GarmentTypesIcon/sweater-hoodie.png';
import ThickSweaterHoodie from 'assets/images/pngs/GarmentTypesIcon/thick-sweater-hoodie.png';
import DesignerFormalSythenticsTextile from 'assets/images/pngs/ShoeTypesIcon/designer-formal-sythentics-textile.png';
import DesignerFormalLeather from 'assets/images/pngs/ShoeTypesIcon/designer-formal-leather.png';
import DesignerFormalMix from 'assets/images/pngs/ShoeTypesIcon/designer-formal-mix.png';
import DesignerSandalsFlipFlops from 'assets/images/pngs/ShoeTypesIcon/designer-sandals-flipflops.png';
import BootsAnkle from 'assets/images/pngs/ShoeTypesIcon/boots-ankle.png';
import DesignerEspardrilles from 'assets/images/pngs/ShoeTypesIcon/designer-espardrilles.png';
import ReusableBag from 'assets/images/pngs/reusable-bag.png';
import ShoeBag from 'assets/images/pngs/extraItems/shoe-bag.png';
import ShoeBox from 'assets/images/pngs/extraItems/shoe-box.png';
import ShoeTree from 'assets/images/pngs/extraItems/shoe-tree.png';
import Hangers from 'assets/images/pngs/extraItems/hangers.png';
import LaundryBag from 'assets/images/pngs/extraItems/laundry-bag.png';
import SuitCover from 'assets/images/pngs/extraItems/suit-cover.png';
import FormalHeels from 'assets/images/pngs/ShoeTypesIcon/formal-heels.png';
import DesignerFormalHeels from 'assets/images/pngs/ShoeTypesIcon/designer-formal-heels.png';

const itemTypeIconMap: { [key: string]: string } = {
  ABAYA: AbayaIcon,
  BATHROBE: BathrobeIcon,
  BEDSHEET: BedsheetsIcon,
  BELT: BeltIcon,
  BLOUSE: BlouseIcon,
  BRA: BraIcon,
  CARPET: CarpetIcon,
  COAT: CoatIcon,
  DUVET: DuvetIcon,
  GATHRA: GathraIcon,
  JACKET: JacketIcon,
  JEANS: JeansIcon,
  JUMPSUIT: JumpsuitIcon,
  KANDURA: KanduraIcon,
  LEGGING: LeggingIcon,
  DRESS_LONG: LongDressIcon,
  WINTER_JACKET: WinterJacketIcon,
  WINTER_OVERCOAT: WinterOvercoatIcon,
  OVERCOAT: OvercoatIcon,
  PANTS: PantsIcon,
  PILLOW_CASE: PillowCaseIcon,
  PILLOW: PillowIcon,
  PULLOVER: PullOverIcon,
  SCARF: ScarfIcon,
  SHIRT: ShirtIcon,
  DRESS_BASIC: ShortDressIcon,
  SHEILA: SheilaIcon,
  SHORTS: ShortsIcon,
  SKIRT: SkirtIcon,
  SOCKS: SocksIcon,
  SUIT_JACKET: SuitJacketIcon,
  SWEATER: SweaterIcon,
  T_SHIRT: TshirtIcon,
  TABLE_CLOTH: TableClothIcon,
  TIE: TieIcon,
  TOWEL: TowelIcon,
  UNDERSHIRT: UndershirtIcon,
  UNDERWEAR: UnderwearIcon,
  VEST: VestIcon,
  HANDKERCHIEF: HandkerchiefIcon,
  COMFORTER: ComforterIcon,
  COMFORTER_COVER: ComforterCoverIcon,
  TABLE_MAT: TableMatIcon,
  DOORMAT: DoorMatIcon,
  BLANKET: BlanketSmallIcon,
  BLANKET_LARGE: BlanketLargeIcon,
  BEDCOVER: BedCoverIcon,
  SUIT_PANTS: SuitPantsIcon,
  SARI: SariIcon,
  SHERWANI: SherwaniIcon,
  BATHING_SUIT: BathingSuitIcon,
  PYJAMA_PANTS: PyjamaPantsIcon,
  CAP: CapIcon,
  KURTA_MENS: KurtaIcon,
  CURTAIN_SQM: CurtainIcon,
  SOFA_COVER_REGULAR: SofaCoverSmallIcon,
  SOFA_COVER_LARGE: SofaCoverLargeIcon,
  SOFA_COVER: SofaCoverLargeIcon,
  CUSHION_COVER_SMALL: CushionSmallIcon,
  CUSHION_COVER_LARGE: CushionLargeIcon,
  CUSHION: CushionSmallIcon,
  CUSHION_COVER: CushionCoverIcon,
  CARPET_REGULAR_PERSQM: CarpetIcon,
  CARPET_WOOL: CarpetWoolIcon,
  DUFFEL_BAG: DuffelBagIcon,
  BACKPACK: BackpackIcon,
  LOOSE_ITEM: LooseItemIcon,
  ACCESSORY: AccessoryIcon,
  JALABIYA: JalabiyaIcon,
  DELICATE_ABAYA: DelicateAbayaIcon,
  FACE_MASK: FaceMaskIcon,
  GLOVE: GloveIcon,
  SPORTS_SNEAKERS_SYTHENTICS_TEXTILE: SythenticsTextileSportsSneakersIcon,
  SPORTS_SNEAKERS_SUEDE_NUBUCK: SuedeNubuckSportsSneakersIcon,
  SPORTS_SNEAKERS_LEATHER: LeatherSportsSneakersIcon,
  SPORTS_SNEAKERS_MIX: MixSportsSneakersIcon,
  SPORTS_SNEAKERS_CHILDREN: ChildrensSportsSneakersIcon,
  DESIGNER_SNEAKERS_SYTHENTICS_TEXTILE: SythenticsTextileDesignerSneakersIcon,
  DESIGNER_SNEAKERS_SUEDE: SuedeNubuckDesignerSneakersIcon,
  DESIGNER_SNEAKERS_LEATHER: LeatherDesignerSneakersIcon,
  DESIGNER_SNEAKERS_MIX: MixDesignerSneakersIcon,
  FORMAL_SHOE_SYTHENTICS_TEXTILE: SythenticsTextileFormalIcon,
  FORMAL_SHOE_SUEDE: SuedeNubuckFormalIcon,
  FORMAL_SHOE_LEATHER: LeatherFormalIcon,
  FORMAL_SHOE_MIX: MixFormalIcon,
  FORMAL_SHOE_CHILDREN: ChildrensFormalIcon,
  BOOTS_SYTHENTICS_TEXTILE: SythenticsTextileBootIcon,
  BOOTS_SUEDE: SuedeNubuckBootIcon,
  BOOTS_LEATHER: LeatherBootIcon,
  BOOTS_MIX: MixBootIcon,
  BOOTS_HIGH_BOOTS: HighBootIcon,
  BOOTS_THIGH_HIGH_BOOTS: ThighHighBootIcon,
  FLIP_FLOP_SYTHENTICS_TEXTILE: SythenticsTextileFlipFlopIcon,
  FLIP_FLOP_SUEDE: SuedeNubuckFlipFlopIcon,
  FLIP_FLOP_LEATHER: LeatherFlipFlopIcon,
  FLIP_FLOP_MIX: MixFlipFlopIcon,
  SANDAL_SYTHENTICS_TEXTILE: SythenticsTextileSandalIcon,
  SANDAL_SUEDE: SuedeNubuckSandalIcon,
  SANDAL_LEATHER: LeatherSandalIcon,
  SANDAL_MIX: MixSandalIcon,
  ESPARDRILLES_SYTHENTICS_TEXTILE: SythenticsTextileEspardrillesIcon,
  ESPARDRILLES_SUEDE: SuedeNubuckEspardrillesIcon,
  ESPARDRILLES_LEATHER: LeatherEspardrillesIcon,
  ESPARDRILLES_MIX: MixEspardrillesIcon,
  CLUTCH_PURSE: ClutchPurseIcon,
  WALLETS: WalletsIcon,
  BAGS_STANDARD_SIZE: BagsStandardSizeIcon,
  BAGS_OVERSIZED: BagsOversizedIcons,
  MENS_BRIEFCASE: MensBriefcaseIcon,
  VALISE_SUITCASE: ValiseSuitcaseIcon,
  BACKPACKS: BackPackIcon,
  BELTS: BeltsIcon,
  APRON: ApronIcon,
  STUFFED_TOY_SMALL: StuffedToySmallIcon,
  STUFFED_TOY_MEDIUM: StuffedToyMediumIcon,
  STUFFED_TOY_LARGE: StuffedToyLargeIcon,
  BRIDAL_DRESS: BridalDress,
  LEATHER_JACKET: LeatherJacket,
  SLEEVELES_UNDERSHIRT: SleevelesUndershirt,
  GILET_JACKET: GiletJacket,
  CASUAL_DRESS: CasualDress,
  FORMAL_DRESS: FormalDress,
  EVENING_GOWN: EveningGown,
  WAIST_COAT: WaistCoat,
  SWIMMING_SUIT: SwimmingCoat,
  PYJAMA_TOP: PyjamaTop,
  WINTER_PANTS: WinterPants,
  WINTER_COAT: WinterCoat,
  TUXEDO_PANTS: TuxedoPants,
  TUXEDO_JACKET: TuxedoJacket,
  SHOES: ShoesIcon,
  BAGS: BagsIcon,
  VEIL: VeilIcon,
  DESIGNER_FORMAL_SHOES_SUEDE: formalShoesIcon,
  SHOE_LACES: ShoeLaceIcon,
  DRESS_EVENING: DressEvening,
  DRESS_COMPLEX_EVENING: DressComplex,
  DRESS_COMPLEX: DressComplex,
  SPORT_SWEATER_HOODIE: SportSweaterHoodie,
  SWEATER_HOODIE: SweaterHoodie,
  THICK_SWEATER_HOODIE: ThickSweaterHoodie,
  DESIGNER_FORMAL_SYTHENTICS_TEXTILE: DesignerFormalSythenticsTextile,
  DESIGNER_FORMAL_LEATHER: DesignerFormalLeather,
  DESIGNER_FORMAL_MIX: DesignerFormalMix,
  DESIGNER_SANDALS_FLIPFLOPS: DesignerSandalsFlipFlops,
  BOOTS_ANKLE: BootsAnkle,
  DESIGNER_ESPARDRILLES: DesignerEspardrilles,
  REUSABLE_BAG: ReusableBag,
  SHOE_BAG: ShoeBag,
  SHOE_BOX: ShoeBox,
  SHOE_TREE: ShoeTree,
  HANGERS: Hangers,
  LAUNDRY_TRANSPORT_BAG: LaundryBag,
  SUIT_COVER: SuitCover,
  MENS_FORMAL_SHOE_LEATHER: LeatherFormalIcon,
  MENS_FORMAL_SHOES_MIXED_MATERIAL: MixFormalIcon,
  MENS_DESIGNER_FORMAL_LEATHER: DesignerFormalLeather,
  MENS_DESIGNER_FORMAL_MIXED_MATERIAL: DesignerFormalMix,
  WOMENS_FORMAL_SHOE: FormalHeels,
  WOMEN_DESIGNER_FORMAL: DesignerFormalHeels,
};

export default itemTypeIconMap;
