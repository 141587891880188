import styled from 'styled-components';
/**
 *
 * @description Styles the Date in Red if the date is today or Less
 *
 * @description Components using this component :
 *
 *  Folding Station - ScannedItems.tsx
 *
 *  Packaging Station - AddItemWidget.tsx
 *
 *  Scanner Station - ScannedItem.tsx
 *
 *  Sorter Station - ScannedItems.tsx
 *
 *  Sorter Station - ItemOverView.tsx
 *
 *  Tagger Station - TaggerSidebar.tsx
 *
 *  Washer Station - ScannedItem.tsx
 *
 * @param {boolean} isDropOffRed
 *
 * @returns {StyledComponent} DropOffDateSpan
 *
 */
export const DropOffDateSpan = styled.span<{ isDropOffRed: boolean }>`
  color: ${(props) => (props.isDropOffRed ? '#cd3d3d' : '')};
`;
