import React from 'react';

import { Button, Box, makeStyles, createStyles } from '@material-ui/core';

import StainOrDamage from 'assets/images/pngs/stainordamage.png';
import { ConfirmDialog } from 'components/confirmDialog';

interface props {
  open: boolean;
  onClose: () => void;
  submit: (value: boolean) => void;
}
const useStyles = makeStyles(() =>
  createStyles({
    body: {
      padding: '2rem',
    },
  })
);
const StainOrDamageDialog: React.FC<props> = ({ open, onClose, submit }) => {
  const classes = useStyles();
  const handleSubmit = (value: boolean) => {
    onClose();
    submit(value);
  };
  return (
    <ConfirmDialog wrapperClass={classes.body} image={StainOrDamage} open={open} onClose={onClose} subTitle={'Is there a stain or damage?'}>
      <Box paddingRight="1rem">
        <Button variant="outlined" color="primary" size="large" onClick={() => handleSubmit(false)}>
          No
        </Button>
      </Box>
      <Box paddingLeft="1rem">
        <Button variant="contained" color="primary" size="large" onClick={() => handleSubmit(true)}>
          Yes
        </Button>
      </Box>
    </ConfirmDialog>
  );
};

export default StainOrDamageDialog;
