import React, { useMemo, useState } from 'react';
import { Grid, Button, Typography, Fab } from '@material-ui/core';
import styled from 'styled-components';

import { IItemReportDetail } from 'utils/interfaces/stainManStationInterfaces';
import moment from 'moment';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { CustomDialog } from 'components/dialogs';

import { ReactComponent as DelayDaysIcon } from 'assets/images/svgs/delay-days.svg';
import { DateFormats } from 'utils/enums/constants';

interface Props {
  onSubmitDeliveryDelay: (expectedDeliveryDelay: number) => void;
  open: boolean;
  item: IItemReportDetail;
}

const DeliveryDelay: React.FC<Props> = ({ onSubmitDeliveryDelay, open, item }) => {
  const [expectedDeliveryDelay, setExpectedDeliveryDelay] = useState(0);

  const handleSubmitDeliveryDelay = () => {
    onSubmitDeliveryDelay(expectedDeliveryDelay);
  };

  const [dropoffDate, today] = useMemo(
    () => [moment(item.dropoffDate, DateFormats.DROPOFF_DATE), moment().format(DateFormats.DROPOFF_DATE)],
    [item.dropoffDate]
  );
  return (
    <CustomDialog custom_size={{ height: 'auto', width: '80%' }} open={open} size={false}>
      <Wrapper container direction="column" alignItems="center">
        <TitleWrapper container item direction="row" justify="center" alignItems="center">
          <Typography variant="h4" display="initial" align="center">
            Original drop off date: {dropoffDate.isSame(today, 'D') ? 'Today' : dropoffDate.from(today)}
          </Typography>
        </TitleWrapper>

        <ActionsLayout wrap="nowrap" container alignItems="center" justifyContent={'center'}>
          <ActionsWrapper container direction="column" justifyContent="space-between" alignItems="center" item xs={4}>
            <Grid item xs>
              <Typography variant="h5" paragraph>
                <b>Suggested delay days?</b>
              </Typography>
            </Grid>
            <Grid item xs>
              <DelayDaysIcon width="10rem" height="10rem" />
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <ActionFab
                color="primary"
                size="large"
                variant="circular"
                onClick={() => setExpectedDeliveryDelay((prev) => --prev)}
                disabled={expectedDeliveryDelay <= 0}
              >
                <RemoveIcon />
              </ActionFab>
              <CustomTypography variant="h3">{expectedDeliveryDelay}</CustomTypography>
              <ActionFab color="primary" size="large" variant="circular" onClick={() => setExpectedDeliveryDelay((prev) => ++prev)}>
                <AddIcon />
              </ActionFab>
            </Grid>
          </ActionsWrapper>
        </ActionsLayout>
        <Grid container item xs justifyContent="center" alignItems="center">
          <Actionbutton color="primary" size="large" variant="contained" onClick={handleSubmitDeliveryDelay}>
            submit
          </Actionbutton>
        </Grid>
      </Wrapper>
    </CustomDialog>
  );
};

export default DeliveryDelay;

const ActionsLayout = styled(Grid)`
  height: 100%;
  width: 100%;
  padding: 4rem 0;
`;

const ActionsWrapper = styled(Grid)`
  align-self: flex-start;
`;
const Wrapper = styled(Grid)`
  padding: 1rem;
`;

const TitleWrapper = styled(Grid)`
  padding: 1rem;
  border-bottom: 1px solid #b6bcd1;
  position: relative;
`;

const Actionbutton = styled(Button)`
  padding: 1rem 3rem;
  margin-bottom: 2rem;
  width: 20%;
`;
const ActionFab = styled(Fab)`
  margin: 0 2rem;
`;

const CustomTypography = styled(Typography)`
  padding: 1rem 0;
`;
