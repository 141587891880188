import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { StationTypes } from "utils/enums/stationEnums";

const useVerifyStation = (stationName: StationTypes) => {
    const { pathname } = useLocation();
    return useMemo(() => pathname.includes(stationName), [pathname, stationName]);
}

export default useVerifyStation
