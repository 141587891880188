import useUrlState from '@ahooksjs/use-url-state';

export function useNotesModal(modalKey?: string) {
  const [, setImageNotes] = useUrlState({ imageNotes: false, descriptionNotes: false, noteModalKey: '' });

  const openNotesModal = () => {
    setImageNotes({ imageNotes: true, noteModalKey: modalKey });
  };

  const closeNotesModal = () => {
    setImageNotes({ imageNotes: false, descriptionNotes: false, noteModalKey: modalKey });
  };

  return {
    openNotesModal,
    closeNotesModal,
  };
}
