import React from 'react';
import styled from 'styled-components';
import { Box, Button, Typography } from '@material-ui/core';
import { CustomDialog } from 'components/dialogs';
import { ReactComponent as RejectedIcon } from 'assets/images/svgs/facility-rejected-icon.svg';

interface IProps {
  open: boolean;
  toggle: () => void;
  onPressCancel: () => void;
}

const RejectedInfoModal: React.FC<IProps> = ({ open, toggle, onPressCancel }) => {
  return (
    <CustomDialog open={open} size="md" dataCy="rejectedInfo.component">
      <InnerModal>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Box>
            <RejectedIcon />
          </Box>
          <Header> Item Rejected By Facility</Header>
          <Text>This item was rejected by the facility you can only view the issues.</Text>
        </Box>
        <Box display={'flex'} flex={'0.4'} justifyContent="center" alignItems={'center'} padding="3rem 0">
          <SubmitButton variant="outlined" color="primary" onClick={onPressCancel}>
            Cancel
          </SubmitButton>
          <SubmitButton variant="contained" color="primary" onClick={toggle}>
            Confirm
          </SubmitButton>
        </Box>
      </InnerModal>
    </CustomDialog>
  );
};
const InnerModal = styled(Box)`
  padding: 4rem;
`;
const Text = styled(Typography)`
  font-size: 2.5rem;
  font-weight: lighter;
  text-align: center;
`;
const Header = styled(Typography)`
  color: #d84141;
  font-size: 2.7rem;
  align-items: center;
`;
const SubmitButton = styled(Button)`
  height: 5.6rem;
  width: 18.6rem;
  padding: 1rem 3rem;
  font-size: 1.5rem;
  margin: 0 1rem;
`;
export default RejectedInfoModal;
