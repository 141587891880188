import React from 'react';
import { Box, ButtonBase, Typography, Popover, Theme } from '@material-ui/core';
import { WashmenIcon } from '../washmenIcon';
import { IBag, OrderServiceLines } from 'utils/interfaces/stationInterfaces';
import { BagTypes } from 'utils/enums/stationEnums';
import { makeStyles, createStyles } from '@material-ui/styles';
import BagTypeMap from 'utils/maps/bagTypeMap';
import { ValidateManagerDialog } from '../dialogs';
import useBagTypes from 'modules/stations/taggerStation/hooks/useBagTypes';

import chunck from 'lodash/chunk';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bagBtn: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      minWidth: '38px',
      minHeight: '38px',
      marginRight: '1rem',
      borderRadius: '3rem',
    },
    editBagPopover: {
      padding: '1.5rem 2rem',
      minWidth: '18rem',
    },
  })
);

interface Props {
  bag: IBag;
  disabled?: boolean;
  onTypeSelect: (type: BagTypes) => void;
  stationServiceLine: OrderServiceLines;
}

const BagTypeSelector: React.FC<Props> = ({ bag, onTypeSelect, disabled = false, stationServiceLine }) => {
  const classes = useStyles();
  const [anchorElBag, setAnchorElBag] = React.useState<HTMLButtonElement | null>(null);
  const isBagPopOverOpen = Boolean(anchorElBag);
  const bagPopoverId = isBagPopOverOpen ? 'bag-popover' : undefined;
  const [isManagerModalOpen, setIsManagerModalOpen] = React.useState(false);
  const [clickedType, setClickedType] = React.useState<BagTypes>(BagTypes.CLEAN_PRESS);

  const bagTypes = useBagTypes({ stationServiceLine, isManualPaymentFineryOrder: !!bag.order?.isManualPaymentFineryOrder, type: 'edit' });

  function selectBagTypeHandler(type: BagTypes) {
    setClickedType(type);
    setIsManagerModalOpen(true);
  }

  function handleManagerScan(validated: Boolean) {
    if (validated) {
      onTypeSelect(clickedType);
      setIsManagerModalOpen(false);
    }
  }

  function handleBagIconClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setAnchorElBag(event.currentTarget);
  }

  function handleBagPopoverClose() {
    setAnchorElBag(null);
  }

  return (
    <>
      {bag.bagCode && (
        <Box display="flex" alignItems="center">
          <Box borderLeft="1px solid white" margin="0 1rem" display="flex" alignSelf="stretch"></Box>
          <ButtonBase
            focusRipple
            aria-describedby={bagPopoverId}
            onClick={handleBagIconClick}
            className={classes.bagBtn}
            disabled={disabled}
            style={{ backgroundImage: `url(${BagTypeMap[bag.type]})` }}
          ></ButtonBase>
          <Typography>{`Bag #${bag.bagCode}`}</Typography>
          <Popover
            id={bagPopoverId}
            open={isBagPopOverOpen}
            anchorEl={anchorElBag}
            onClose={handleBagPopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box className={classes.editBagPopover} display="flex" flexDirection="column" alignItems="start" justifyContent="center">
              <Typography variant="h6">Edit bag type</Typography>
              <Box margin="1rem 0"></Box>
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" alignSelf="stretch">
                {chunck(bagTypes, 2).map((row) => (
                  <Box
                    key={row.map(({ bagType }) => bagType).toString()}
                    display="flex"
                    width="80%"
                    alignItems="center"
                    justifyContent="space-around"
                    paddingBottom="1rem"
                  >
                    {row.map(({ bagType, iconName }) => (
                      <WashmenIcon key={bagType} active={bag.type === bagType} name={iconName} onToggleClick={() => selectBagTypeHandler(bagType)} />
                    ))}
                  </Box>
                ))}
              </Box>
            </Box>
          </Popover>
        </Box>
      )}
      <ValidateManagerDialog
        open={isManagerModalOpen}
        onManagerScan={handleManagerScan}
        onClose={() => {
          setIsManagerModalOpen(false);
        }}
      />
    </>
  );
};

export default BagTypeSelector;
