import React from 'react';
import { Navigate, Route, Routes, } from 'react-router-dom';

import SelectMeasurementsDialog from './selectMeasurementsDialog';
import { ComponentProps } from 'modules/stations/components/SortingStepper/SortingStepper';

interface Props extends ComponentProps { }

const SelectBrandComponent: React.FC<Props> = ({ onSelect }) => {


  return (
    <Routes>
      <Route path={'/'} element={<SelectMeasurementsDialog onSelect={onSelect} />} />
      <Route path="*" element={<Navigate to={''} />} />
    </Routes>
  );
};

export default SelectBrandComponent;
