import React, { useRef, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Typography, Button, Grid, Theme, Box, CircularProgress, GridSize } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { WashmenIcon } from 'components/washmenIcon';
import { LoaderComponent } from 'components/loaderComponent';
import { Toaster } from 'components/toaster';

import CareLabelAnimation from 'assets/images/pngs/gif/carelabel-animation.gif';
import { useGetSelectedItem } from 'modules/stations/components/SortingStepper/SortingStepper';
import styled from 'styled-components';
import ImageUploader from './imageUploader';
import { useUploadImageToS3 } from './mutations';

interface Props {
  imageSrc?: string;
  title?: string;
  cancelBtn?: Boolean;
  cancelBtnText?: string;
  isCareLabel?: boolean;
  hideNextButton?: boolean;
  showSkipButton?: boolean;
  handleSkipButton?: () => void;
  onCancelButtonClick?: () => void;
  onPhotoTaken?: (imageBlob: string) => void;
  onConfirmImage?: (imageBlob: string) => void;
  onResetImage?: () => void;
  isReport?: boolean;
  showChildrenInTakePhoto?: boolean;
  enableUploadImage?: boolean;
  itemCode?: string;
  showTitleAtEnd?: boolean;
  styles?: {
    imgGridSize?: GridSize;
  };
}

const defaultProps = {
  title: 'Please take a front photo of garment',
  isCareLabel: false,
  onPhotoTaken: (image: string) => {},
  onConfirmImage: (image: string) => {},
  onResetImage: () => {},
  handleSkipButton: () => {},
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: '1',
    },
    photoBoxWrapper: {
      border: '1px solid #b6bcd1',
      padding: '1rem',
      width: 'auto',
    },
    cameraIcon: {
      marginRight: '2rem',
      '& path': {
        fill: 'white',
      },
      width: '2.5vw',
      height: 'auto',
    },
    careLabelAnimation: {
      maxHeight: '10rem',
      maxWidth: '6rem',
      margin: '0 2rem',
    },
  })
);

/**
 *
 * @description PhotoCatcher component that takes photos
 */
const PhotoCatcher: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  imageSrc = '',
  cancelBtn = false,
  cancelBtnText = 'cancel',
  onCancelButtonClick = () => {},
  isCareLabel = defaultProps.isCareLabel,
  onPhotoTaken = defaultProps.onPhotoTaken,
  onConfirmImage = defaultProps.onConfirmImage,
  onResetImage = defaultProps.onResetImage,
  handleSkipButton = defaultProps.handleSkipButton,
  title = defaultProps.title,
  hideNextButton = false,
  isReport = false,
  showSkipButton = false,
  showChildrenInTakePhoto = false,
  enableUploadImage = false,
  itemCode = '',
  showTitleAtEnd = false,
  styles = {},
}) => {
  const { selectedItem: currentItem } = useGetSelectedItem();
  const { mutateAsync: uploadImageToS3 } = useUploadImageToS3();

  const videoElementRef = useRef<HTMLVideoElement>(null);
  const canvasElementRef = useRef<HTMLCanvasElement>(null);
  const [imgValue, setImgValue] = useState(imageSrc);
  const [showImgUploadLoader, setShowImgUploadLoader] = useState<boolean>(false);
  const [loaded, setLoaded] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    if (imgValue) {
      return;
    }
    const video = videoElementRef.current!;
    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(function (stream) {
          videoElementRef.current!.srcObject = stream;
          // Activate take photo button on load
          setLoaded(false);
        })
        .catch(function (error) {
          console.log('Something went wrong!');
        });
    }
    return () => {
      video.srcObject = null;
    };
  }, [imgValue, showImgUploadLoader]);

  async function onTakeScreenShot() {
    setShowImgUploadLoader(true);
    canvasElementRef.current!.width = videoElementRef.current!.videoWidth;
    canvasElementRef.current!.height = videoElementRef.current!.videoHeight;
    canvasElementRef.current!.getContext('2d')!.drawImage(videoElementRef.current!, 0, 0);
    // Other browsers will fall back to image/png
    const image = canvasElementRef.current!.toDataURL('image/jpeg');
    await handleUploadImageToS3(image);
  }

  const retakeScreenShotHandler = () => {
    setImgValue('');
    onResetImage();
  };

  const handleConfirmImage = () => {
    if (showImgUploadLoader && imgValue.startsWith('https://s3')) {
      toast(<Toaster message="Wait for image to upload" type="error" />);
      return;
    }
    onConfirmImage(imgValue);
  };

  const handleUploadImageToS3 = async (image: string) => {
    setImgValue(image);
    await uploadImageToS3(
      { itemCode: currentItem?.code || itemCode, image: image.split(',')[1], isReport },
      {
        onSuccess: (data) => {
          setImgValue(data);
          onPhotoTaken(data);
          setShowImgUploadLoader(false);
        },
        onError: () => {
          setShowImgUploadLoader(false);
        },
      }
    );
  };

  return (
    <Grid classes={{ container: classes.root }} wrap="nowrap" container direction="column" justify="space-around" alignItems="center">
      <LoaderComponent />
      {((loaded && !imgValue) || showImgUploadLoader) && (
        <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%" className="loading-icon">
          <CircularProgress />
        </Box>
      )}
      {!imgValue ? (
        <>
          <Grid container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {isCareLabel && <img src={CareLabelAnimation} alt="careLabel" className={classes.careLabelAnimation} />}
            <Typography variant="h3">{title}</Typography>
            {isCareLabel && <img src={CareLabelAnimation} alt="careLabel" className={classes.careLabelAnimation} />}
          </Grid>
          <Grid item xs={4}>
            <Grid container item alignItems="center" justify="center">
              <video ref={videoElementRef} id="videoElement" autoPlay={true} style={{ width: '100%', maxHeight: '100%' }} />
              <canvas ref={canvasElementRef} style={{ display: 'none' }}></canvas>
            </Grid>
          </Grid>
          <Grid container alignItems="center" justify="center" style={{ gap: '1.5rem' }}>
            {cancelBtn && (
              <>
                <StyledButton size="large" variant="contained" color="secondary" onClick={() => onCancelButtonClick()}>
                  {cancelBtnText}
                </StyledButton>
                <Box margin="0 1rem" />
              </>
            )}

            {showSkipButton && (
              <StyledButton color="secondary" variant="outlined" size="large" onClick={handleSkipButton}>
                Skip
              </StyledButton>
            )}
            {enableUploadImage && <ImageUploader onImageUploaded={handleUploadImageToS3} />}

            <StyledButton
              size="large"
              variant="contained"
              color="primary"
              onClick={async () => {
                await onTakeScreenShot();
              }}
              disabled={loaded}
            >
              <WashmenIcon svg name="cameraIcon" iconClassName={classes.cameraIcon} isButton={false} />
              Take Photo
            </StyledButton>
            {showChildrenInTakePhoto && <>{children}</>}
          </Grid>
        </>
      ) : (
        <>
          {showTitleAtEnd && <Typography variant="h3">{title}</Typography>}
          <Grid container item classes={{ item: classes.photoBoxWrapper }} justify="center" direction="column" xs={styles.imgGridSize ?? 4}>
            <img className="img-responsive" src={imgValue} alt="" />
            <Button color="primary" size="large" variant="text" onClick={retakeScreenShotHandler}>
              Retake Photo
            </Button>
          </Grid>
          <Grid container item direction="row" alignItems="center" justify="center">
            {children}
            {!hideNextButton && (
              <StyledButton
                color="primary"
                variant="contained"
                size="large"
                onClick={handleConfirmImage}
                style={{ margin: '0 0' }}
                disabled={showImgUploadLoader}
              >
                next
              </StyledButton>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default PhotoCatcher;

export const StyledButton = styled(Button)`
  height: 6rem;
  width: 23rem;
`;
