import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { useStationStore } from 'stores';
import { FormattedMessage } from 'react-intl';

import { ITEM_TYPE_CATEGORIES, typeMapper, COMMON_ITEM_TYPES, ITypeObj } from 'utils/maps/typeMapper';
import LabelSelector from '../labelSelector/LabelSelector';
import { ItemTypes } from 'utils/enums/typesEnum';

/** Imports Material UI */
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import { Grid, Paper, InputBase, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { MainKeyboard } from 'components/mainKeyboard';
import { MainKeyboardRefProps } from 'components/mainKeyboard/mainKeyboard';
import { useNavigate } from 'react-router-dom';

const useTypeBoxStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: '32px',
    paddingTop: '20px',
  },
  labelTypo: {
    fontWeight: 300,
    paddingBottom: '20px',
  },
  paper: {
    padding: '10px 4px',
    display: 'flex',
    alignItems: 'stretch',
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  searchContainer: {
    padding: '0 1.5rem',
  },
}));

/**
 * @description gets the item category based on the type
 * @param itemType
 * @returns {string} item category
 */
export const getSelectedCategoryFromType = (itemType: string) => {
  let selectedCategory = '';
  Object.keys(typeMapper).map((category: string) => {
    return Object.keys(typeMapper[category]).map((type: any) => {
      if (itemType === typeMapper[category][type].value) {
        selectedCategory = category;
        return category;
      }
      return '';
    });
  });
  return selectedCategory;
};

interface Props {}

const allTypesObject = {
  ...typeMapper['top'],
  ...typeMapper['bottom'],
  ...typeMapper['undergarment'],
  ...typeMapper['formal'],
  ...typeMapper['linen'],
  ...typeMapper['ethnic'],
  ...typeMapper['outerwear'],
  ...typeMapper['other'],
};

const arrayToObject = (arr: any[], keyField: string) => Object.assign({}, ...arr.map((item) => ({ [item[keyField]]: item })));

const SelectTypeComponent: React.FC<Props> = observer(() => {
  // Import styles
  const classes = useTypeBoxStyles();

  const navigate = useNavigate();
  // Import the currentItem and it's setter
  let { currentItem, updateCurrentItemDetails } = useStationStore();
  const [filterInput, setFilterInput] = useState('');

  const keyboardRef = useRef<MainKeyboardRefProps>(null);

  // Declare States
  const [currentListing, setCurrentListing] = useState({ ...COMMON_ITEM_TYPES });
  const [currentCategory, setCurrentCategory] = useState(getSelectedCategoryFromType(currentItem.type || ''));

  useEffect(() => {
    setCurrentListing(typeMapper[currentCategory] || { ...COMMON_ITEM_TYPES });
  }, [currentCategory]);

  useEffect(() => {
    if (keyboardRef.current) keyboardRef.current.setInput(filterInput);
    if (filterInput) {
      // filter the array of items
      const result = Object.keys(allTypesObject)
        .filter((key) => allTypesObject[key].label.toLowerCase().includes(filterInput.toLowerCase()))
        .map((key) => allTypesObject[key]);
      //convert result to object
      const response = arrayToObject(result, 'iconName');
      setCurrentListing(response);
    } else {
      setCurrentListing(typeMapper[currentCategory] || { ...COMMON_ITEM_TYPES });
    }
  }, [filterInput, currentCategory]);

  const handleFilterInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFilterInput(value);
  };

  /**
   * Set the selected catgory
   * @param label - Name of the Catogory selected
   */
  const onCategoryClick = (label: string) => {
    setCurrentCategory(label);
  };

  /**
   * Save the selected value to selectedItem and the StationStore
   * @param typeObj - TypeObj selected
   */
  const onTypeSelection = (typeObj: ITypeObj) => {
    updateCurrentItemDetails({ ...currentItem, type: typeObj.value });
    if (currentItem.brand) {
      navigate(-1);
    } else {
      onGoToBrand();
    }
  };

  /**
   * Route to the Brands Modal View
   */
  function onGoToBrand() {
    navigate('../(item/brand)');
  }

  const handleKeyBoardInput = (value: string) => {
    setFilterInput(value);
  };
  return (
    <>
      <Box display="flex" flexDirection="column" className={classes.root}>
        <Grid container justify="flex-end" classes={{ container: classes.searchContainer }}>
          <Grid item xs={2}>
            <form onSubmit={(event) => event.preventDefault()}>
              <Paper className={classes.paper}>
                <InputBase
                  onFocus={() => {
                    if (keyboardRef.current) keyboardRef.current.toggleKeyBoard(true);
                  }}
                  className={classes.input}
                  placeholder="Search Type..."
                  inputProps={{ 'aria-label': 'search type' }}
                  value={filterInput}
                  onChange={handleFilterInput}
                />
                <IconButton className={classes.iconButton} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Paper>
            </form>
          </Grid>
        </Grid>
        <Typography variant="h6" className={classes.labelTypo}>
          <FormattedMessage id={'station.sorter.itemType.type.label'} defaultMessage={'station.sorter.itemType.categories.default'} />
        </Typography>
        <Box display="flex">
          {ITEM_TYPE_CATEGORIES.map((label, i) => {
            return (
              <LabelSelector
                key={`${i}-type-mapper`}
                onToggleClick={() => onCategoryClick(label)}
                isActive={currentCategory === label}
                width="25%"
              >{`station.sorter.itemType.categories.${label}`}</LabelSelector>
            );
          })}
        </Box>

        <Typography variant="h6" className={classes.labelTypo}>
          <FormattedMessage id={'station.sorter.itemType.garment.label'} defaultMessage={'station.sorter.itemType.categories.default'} />
        </Typography>
        <Box display="flex" flexWrap="wrap" alignItems="space-evenly">
          {Object.keys(currentListing).map((type: string, i) => {
            return (
              <LabelSelector
                key={i + '-garment-mapper'}
                iconName={type as ItemTypes}
                onToggleClick={() => onTypeSelection(currentListing[type])}
                isActive={currentItem.type ? currentItem.type === currentListing[type].value : false}
              />
            );
          })}
        </Box>
      </Box>
      <MainKeyboard onChange={handleKeyBoardInput} ref={keyboardRef} />
    </>
  );
});

export default SelectTypeComponent;
