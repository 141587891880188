import { useMutation } from 'react-query';
import { StationService } from 'services';

interface Options {
  rfid: string;
}
const useInformAndProceed = () => {
  return useMutation<any, Error, Options>(({ rfid }) => StationService.informAndProceed(rfid));
};
export default useInformAndProceed;
