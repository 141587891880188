import React from 'react';
import { Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';
import { useStationStore } from 'stores';
import PhotoCatcher from '../../photoCatcher';
import AddStainDamageComponent from './addStainDamageDetails';
import ReportOverView from './reportOverView';
import { DetailsList } from 'utils/interfaces';
import { observer } from 'mobx-react-lite';

interface Props {
  autoSave?: boolean;
  handleOnFinish?: () => void;
  skipPhoto?: boolean;
}

const StainDamageComponent: React.FC<Props> = observer(({ autoSave = false, handleOnFinish, skipPhoto = true }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { updateCurrentItemDetails, currentItem, linkedItem } = useStationStore();

  // go to add stain/damage details with info
  function handleConfirmImage(image: string) {
    navigate(`addDetails`, {
      state: { type: location.state, image: image },
    });
  }

  const handleAddStainSubmit = (form: DetailsList) => {
    if (!form.part) {
      delete form.part;
    }
    updateCurrentItemDetails({
      ...currentItem,
      reportDetailsList: [...(currentItem.reportDetailsList || []), form],
    });
  };

  const handleEditStain = (form: DetailsList, index: string = '') => {
    const intIndex = parseInt(index);
    updateCurrentItemDetails({
      ...currentItem,
      reportDetailsList: currentItem.reportDetailsList!.map((report, index) => {
        if (index === intIndex) {
          return form;
        }
        return report;
      }),
    });
  };
  const forcedStain = linkedItem && linkedItem.stainsArray && linkedItem.stainsArray.length > 0;
  const onFinish = () => {
    handleOnFinish && handleOnFinish();
    goOut();
  };

  const goOut = () => navigate('../');

  return (
    <Routes>
      <Route path={`overView`} element={<ReportOverView handleOnFinish={onFinish} autoSave={autoSave} />} />
      <Route path={`addDetails`} element={<AddStainDamageComponent onSubmit={handleAddStainSubmit} />} />
      <Route path={`editDetails/:index`} element={<AddStainDamageComponent onSubmit={handleEditStain} />} />
      <Route
        path={`/`}
        element={
          <PhotoCatcher
            title="Please take a photo of the stain/damage"
            onConfirmImage={handleConfirmImage}
            isReport={true}
            showSkipButton={skipPhoto && forcedStain}
            handleSkipButton={goOut}
          />
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
});

export default StainDamageComponent;
