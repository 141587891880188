import { AxiosRequestConfig } from 'axios';
import { QueryFilters, Response as GetOrderWithPackagesResponse, GetPackageWithActivitiesResponse } from 'modules/stations/dispatch/queries';
import { QueryResponse as GetOrderWithPackagesListResponse } from 'modules/stations/dispatch/queries';

import Axious from './api';
import { PackageStatuses } from 'utils/enums/stationEnums';
import { ServiceType } from 'utils/interfaces/stationInterfaces';

class DispatchService {
  public async getOrderWithPackages(params: { orderAlphaId?: string; itemCode?: string; serviceFilter: ServiceType }) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/dispatch-station/items/orders/packages`,
      params,
    };
    const response = await Axious.request<GetOrderWithPackagesResponse>(requestOptions);
    return response.data;
  }

  public async getPackageWithActivities(packageId: string = '') {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/packages/${packageId}/items/activities`,
    };

    const response = await Axious.request<GetPackageWithActivitiesResponse>(requestOptions);
    return response.data;
  }

  public async getOrdersWithPackagesList(filters?: QueryFilters) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/orders/packages/list`,
      params: filters,
    };
    const response = await Axious.request<GetOrderWithPackagesListResponse>(requestOptions);
    return response.data;
  }

  public async setPackageStatus(params: { packageId: string; statusCode: number }) {
    const requestOptions: AxiosRequestConfig = {
      method: 'PUT',
      url: `/packages/${params.packageId}/status/${params.statusCode}`,
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async setPackageStatusWithDriverValidation(params: { packageId: string; driverId: string; statusCode: PackageStatuses }) {
    const requestOptions: AxiosRequestConfig = {
      method: 'PUT',
      url: `/packages/${params.packageId}/status/${params.statusCode}/drivers/${params.driverId}`,
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async setDropoffBagPackagesStatus(params: { bagCode: string; statusCode: PackageStatuses }) {
    const requestOptions: AxiosRequestConfig = {
      method: 'PUT',
      url: `/dropoff-bags/${params.bagCode}/packages/status/${params.statusCode}`,
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async setDropoffBagPackagesStatusWithDriverValidation(params: { bagCode: string; driverId: string; statusCode: PackageStatuses }) {
    const requestOptions: AxiosRequestConfig = {
      method: 'PUT',
      url: `/dropoff-bags/${params.bagCode}/packages/status/${params.statusCode}/drivers/${params.driverId}`,
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }
}

const dispatchService = new DispatchService();

export default dispatchService;
