import React from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Grid, Typography, Theme } from '@material-ui/core';

import { SpecialAttentionImage } from 'components/specialAttentionImageGallery';
import { SaItem } from 'utils/interfaces';
import { formatArrayToString, capitalizeString } from 'utils/helpers/string';
import styled from 'styled-components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    padding: {
      padding: '1rem',
    },
    thumbnailContainer: {
      padding: '0.5rem',
    },
    thumbnailImage: {
      minWidth: '7rem',
      padding: ' 0px 10px',
    },
    infoTitle: {
      fontSize: '2rem',
      color: '#7d7c97',
    },
    infoBody: {
      fontWeight: 'normal',
      fontSize: '1.5rem',
    },
    smallInfoTitle: {
      fontSize: '1.3rem',
      color: '#7d7c97',
    },
    smallInfoBody: {
      fontWeight: 'normal',
      fontSize: '1rem',
    },
  })
);

interface Props {
  activeItem: SaItem;
  thumbnailItems?: SaItem[];
  withStatus?: boolean;
  hideStainReport?: boolean;
  selectItem: (item: SaItem) => void;
}

const SaDialogItemsDisplay: React.FC<Props> = ({ activeItem, thumbnailItems, selectItem, hideStainReport = false, withStatus = false }) => {
  const classes = useStyles();

  return (
    // Items Report
    <Grid container item justify="flex-start" alignItems="flex-start" className={classes.padding}>
      {/* Image Gallery Container */}
      <ImagesWrapper item xs={6}>
        {/* Main Image */}
        <Grid container item xs={12} className={`${classes.padding} hero-img-wrapper`}>
          <Typography variant="h4" className={classes.infoTitle}>
            Notes by item
          </Typography>
          <SpecialAttentionImage dialogClassName="sa-image" item={activeItem} withStatus={withStatus} isActiveItem />
        </Grid>

        {/* Images Thumbnail  */}
        {thumbnailItems && thumbnailItems.length > 0 && (
          <ThumbnailImgsWrapper container item xs={12} wrap="nowrap" className={classes.thumbnailContainer}>
            {thumbnailItems.map((item: SaItem, i: number) => (
              <Grid key={`thumbnailItems-${i}`} container item xs={4} justify="center" alignItems="center" className={classes.thumbnailImage}>
                <SpecialAttentionImage item={item} onClickHandler={selectItem} withStatus={withStatus} />
              </Grid>
            ))}
          </ThumbnailImgsWrapper>
        )}
      </ImagesWrapper>

      {/* Item Info Container */}
      <InfoContainer item={activeItem} hideStainReport={hideStainReport} />
    </Grid>
  );
};

export default SaDialogItemsDisplay;

export const InfoContainer: React.FC<{ item: SaItem; hideStainReport: boolean; hideBrand?: boolean; smallText?: boolean }> = ({
  item,
  hideStainReport,
  hideBrand = false,
  smallText = false,
}) => {
  const classes = useStyles({ smallText });

  // TODO: Refactor styles
  return (
    <Grid container item xs={6} className={classes.padding} justify="flex-start" alignItems="flex-start" spacing={2}>
      {/* Brand Section */}
      {!hideBrand && (
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Typography variant="body1" className={smallText ? classes.smallInfoTitle : classes.infoTitle}>
              Brand:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" className={smallText ? classes.smallInfoBody : classes.infoBody}>
              {item.brandName}
            </Typography>
          </Grid>
        </Grid>
      )}

      {/* Notes Section */}
      {!!item.comments && (
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Typography variant="body1" className={smallText ? classes.smallInfoTitle : classes.infoTitle}>
              Notes:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" className={smallText ? classes.smallInfoBody : classes.infoBody}>
              {capitalizeString(item.comments)}
            </Typography>
          </Grid>
        </Grid>
      )}

      {/* Stain Report Section */}
      {item.stainsArray && item.stainsArray.length > 0 && !hideStainReport && (
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Typography variant="body1" className={smallText ? classes.smallInfoTitle : classes.infoTitle}>
              Stain reported:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" className={smallText ? classes.smallInfoBody : classes.infoBody}>
              {formatArrayToString(item.stainsArray)}
            </Typography>
          </Grid>
        </Grid>
      )}

      {/* Cleaning Instructions Section */}
      {item.cleaningInstruction && item.cleaningInstruction !== '' && (
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Typography variant="body1" className={smallText ? classes.smallInfoTitle : classes.infoTitle}>
              Cleaning instructions:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" className={smallText ? classes.smallInfoBody : classes.infoBody}>
              {capitalizeString(item.cleaningInstruction)}
            </Typography>
          </Grid>
        </Grid>
      )}

      {item.tagsArray && item.tagsArray.length > 0 && (
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Typography variant="body1" className={smallText ? classes.smallInfoTitle : classes.infoTitle}>
              Others:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" className={smallText ? classes.smallInfoBody : classes.infoBody}>
              {formatArrayToString(item.tagsArray)}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const ImagesWrapper = styled(Grid)`
  .sa-image {
    max-height: 20rem;
    min-width: 24rem;
  }

  .hero-img-wrapper {
    gap: 1rem;
  }
`;

const ThumbnailImgsWrapper = styled(Grid)`
  overflow-x: auto;
  max-width: 25rem;
  padding: 1rem;
`;
