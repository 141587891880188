import { useQuery } from 'react-query';
import { StationService } from 'services';
import { IUserNote } from 'utils/interfaces';

export const QUERY_KEY = 'item-notes';

const useGetUserNotes = (itemCode: string, orderAlphaId: string) => {
  const userNotesQuery = useQuery<IUserNote[], Error>(
    [QUERY_KEY, itemCode, orderAlphaId],
    () => StationService.getUserNotes(itemCode, orderAlphaId),
    {
      refetchOnWindowFocus: false,
    }
  );

  return { ...userNotesQuery, userNotes: userNotesQuery.data || [] };
};

export default useGetUserNotes;
