import { FilterDefaultValue, FilterOptions } from 'components/filters';

const shoeModalFilters: FilterOptions[] = [
  {
    filterKey: 'orderAlphaIdsListFilter',
    title: 'Order ID',
    type: 'autoComplete',
    isMultipleSelection: true,
    creatable: true,
    rulePattern: /^([a-zA-Z]{2}|[a-zA-Z]{3})[0-9]{3}$/,
    filterOptions: [],
    defaultValue: [],
    dependents: [{ key: 'isPackagedFilter', setValue: '' }],
    xsValue: 5,
  },

  {
    filterKey: 'orderStatusListFilter',
    title: 'Order Status',
    type: 'select',
    isMultipleSelection: true,
    filterOptions: [
      { label: 'Complete', value: 'ORDER_COMPLETE' },
      { label: 'Pending', value: 'ORDER_PENDING' },
      { label: 'Dropoff Pending', value: 'ORDER_DROPOFF_PENDING' },
    ],
    defaultValue: ['ORDER_PENDING', 'ORDER_DROPOFF_PENDING'],
    xsValue: 5,
  },
];

const shoeDefaultModalValues = (() => {
  const obj: { [key in string]?: FilterDefaultValue } = {};
  shoeModalFilters.forEach((filter) => {
    obj[filter.filterKey] = filter.defaultValue;
  });
  return obj;
})();

export { shoeModalFilters, shoeDefaultModalValues };
