import React, { useMemo } from 'react';
import { ControllerRenderProps } from 'react-hook-form';

import { Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import styled from 'styled-components';
import { Cancel } from '@material-ui/icons';

interface Props {
  title: string;
  filterOptions: { label: string; value: string }[];
  filterKey: string;
  isMultipleSelection?: boolean;
  onChangeHandler?: (val: string | string[]) => void;
  field?: ControllerRenderProps;
  defaultValue: string[] | string;
  displayAllOptions?: boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const getMenuProps = (displayAllOptions?: boolean) => {
  return {
    PaperProps: {
      style: {
        maxHeight: displayAllOptions ? undefined : ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
};

const SelectBox: React.FC<Props> = ({
  defaultValue,
  filterKey,
  filterOptions,
  field,
  title,
  isMultipleSelection,
  onChangeHandler = () => {},
  displayAllOptions,
}) => {
  const renderValues = (selected: unknown) => {
    const selectedValues = filterOptions.filter((f) => (selected as string[]).includes(f.value));
    return isMultipleSelection
      ? selectedValues.map(({ value, label }) => (
          <DisplayChip
            key={value}
            label={label}
            color="primary"
            variant="outlined"
            clickable
            onDelete={() => {
              const newValues = selectedValues.filter((v) => v.value !== value).map((v) => v.value);
              field?.onChange(newValues);
              onChangeHandler(newValues);
            }}
            deleteIcon={<Cancel />}
            onMouseDown={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()}
          />
        ))
      : filterOptions.find((f) => f.value === selected)?.label;
  };

  const MenuProps = useMemo(() => getMenuProps(displayAllOptions), [displayAllOptions]);
  return (
    <StyledForm fullWidth key={filterKey}>
      <InputLabel id="select-label">{title}</InputLabel>
      <Select
        multiple={isMultipleSelection}
        labelId="select-label"
        id="select"
        label={title}
        MenuProps={MenuProps}
        {...field}
        variant="outlined"
        onChange={(ev) => {
          field?.onChange(ev);
          onChangeHandler(ev.target.value as string | string[]);
        }}
        autoWidth
        defaultValue={defaultValue}
        renderValue={renderValues}
      >
        <MenuItem disabled value="">
          <em>{title}</em>
        </MenuItem>
        {filterOptions.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            <Checkbox color="primary" checked={isMultipleSelection ? field?.value.includes(value) : field?.value === value} />
            <ListItemText primary={label} />
          </MenuItem>
        ))}
      </Select>
    </StyledForm>
  );
};

export default SelectBox;

const StyledForm = styled(FormControl)`
  background-color: white;
  border-radius: 2rem;

  .MuiOutlinedInput-root {
    border-radius: 2rem;
  }

  .MuiInputLabel-formControl.MuiInputLabel-shrink {
    transform: translate(15px, -4.5px) scale(0.75);
    z-index: 10;
  }

  .MuiSelect-select:focus {
    background-color: white;
    border-radius: 2rem;
  }
  .MuiSelect-select.MuiSelect-select {
    display: flex;
    flex-wrap: wrap;
  }

  .MuiInputLabel-formControl {
    transform: translate(10px, 21px) scale(1);
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #3f44ac;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #3f44ac;
  }
`;

const DisplayChip = styled(Chip)`
  margin: 0 0.5rem 0.5rem 0;
  .MuiChip-deleteIcon {
    width: 2rem;
    height: 2rem;
    margin: 0;
  }
`;
