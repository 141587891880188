import { useQuery } from 'react-query';
import axios from 'axios';

const usePingPdfLink = (pdfLink: string, enabled: boolean) => {
  const { isSuccess, isError, isFetching } = useQuery<unknown, Error>(['pingPdfLink', pdfLink], () => axios.head(pdfLink), {
    retry: 10,
    retryDelay: 2000,
    enabled: enabled,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
  return { isSuccess, isError, isFetching };
};
export default usePingPdfLink;
