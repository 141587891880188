import React from 'react';
import { Link, Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { Box, Breadcrumbs, Typography, Button } from '@material-ui/core';
import { toast, ToastContainer } from 'react-toastify';

import { useAuthStore, useUiState, useStationStore } from 'stores';
import { HeaderBar } from 'components/headerBar';
import { LoaderComponent } from 'components/loaderComponent';
import { BagCountDisplay } from 'components/bagCount';
import { Stations } from '../stations';
import { Login } from '../login';
import { Locales, StationTypes } from 'utils/enums/stationEnums';
import * as Sentry from '@sentry/react';

// i18n support config
import en from '../../i18n/en.json';
import ar from '../../i18n/ar.json';
import './root.scss';

const isProd = process.env.NODE_ENV === 'production';
// const isProd = true;
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const messages: any = {
  en,
  ar,
};

const breadcrumbNameMap: { [key: string]: string } = {
  '/login': 'Login',
  '/login/user': 'User',
  '/login/manager': 'Manager',
  '/login/manager/selectStation': 'Select Station',
  '/login/manager/selectStation/section': 'Station Section',
  '/login/manager/selectStation/type': 'Station Type',
  '/login/manager/selectStation/number': 'Station Number',

  '/station': 'Station',
  '/station/grouping': 'Grouping',
  '/station/spotter': 'Spotter',
  '/station/tailor': 'Tailor',
  '/station/shirt-pressing': 'Shirt Pressing',
  '/station/multi-pressing': 'Multi Pressing',
  '/station/pant-pressing': 'Pant Pressing',
  '/station/utility-pressing': 'Utility Pressing',
  '/station/multi-pressing/item': 'Item',
  '/station/collar-cuff-pressing': 'Collar & Cuff Pressing',
  '/station/pants-topper': 'Pants Topper',
  '/station/flat-ironer': 'Flat Ironer',
  '/station/folding': 'Folding',
  '/station/folding/order-search': 'Order Search',
  '/station/folding/scan': 'Scan',
  '/station/banned-packaging': 'Banned Packaging',
  '/station/scanner': 'Scanner',
  '/station/quality-control': 'Quality Control',
  '/station/hand-wash': 'Hand Wash',
  '/station/sorter': 'Sorter',
  '/station/sorter/bag': 'Bag',
  '/station/tagger': 'Tagger',
  '/station/tagger/bag': 'Bag',
  '/station/washer': 'Washer',
};

const ignoredBreadcrumbs = ['item', 'selectStation', 'type', 'section', 'number', 'bag'];

const excludeBreadcrumbStations = [
  'racking',
  'pending-items',
  'stain-man',
  StationTypes.CPDispatch,
  StationTypes.WFDispatch,
  StationTypes.ShoesDispatch,
  StationTypes.Receiving,
  StationTypes.FineryReceiving,
  StationTypes.ShoesReceiving,
  StationTypes.WfReceiving,
];
function isNumeric(value: string) {
  return /-?\d+$/.test(value);
}
const Root: React.FC = observer(() => {
  const { showBackBtn, showCustomerOrderId } = useUiState();
  const { userLocale, isLoggedIn } = useAuthStore();
  const { bag, stationType, stationId } = useStationStore();
  const isRtl = userLocale === Locales.Arabic ? true : false;
  const location = useLocation();
  const navigate = useNavigate();
  const pathnames = location.pathname.split('/').filter((x) => x);
  // add rtl in body for rtl languages
  if (isRtl) {
    document.body.style.direction = 'rtl';
  } else {
    document.body.style.direction = 'ltr';
  }
  const goBack = () => {
    navigate('../');
  };
  const addStationNumber = () => {
    if (pathnames[0] === 'station') {
      // If station Name doesnt have a number and instead a name
      if (!isNumeric(stationId)) {
        return ` / ${stationId.replaceAll('-', ' ').replaceAll('station', '').toUpperCase()}`;
      }
      //All other stations
      return ` ${stationId.substr(stationId.length - 1, stationId.length)}`;
    }
  };
  return (
    //@ts-ignore //MIGRATION: Migration to latest react-intl should fix this issue
    <IntlProvider locale={userLocale} key={userLocale} messages={messages[userLocale]}>
      <>
        <div className="app">
          <HeaderBar />
          <Box
            display="flex"
            margin={!excludeBreadcrumbStations.includes(stationType) ? `1.5rem 1.5rem 0 1.5rem` : `0rem 1.5rem 0 1.5rem`}
            justifyContent="space-between"
            alignItems="center"
            className="control-buttons"
          >
            {!excludeBreadcrumbStations.includes(stationType) && (
              <Breadcrumbs aria-label="breadcrumb">
                {pathnames.map((value, index) => {
                  const last = index === pathnames.length - 1 || index === 0 || ignoredBreadcrumbs.includes(value);
                  const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                  return last || isProd ? (
                    <Typography color="textPrimary" key={to}>
                      {breadcrumbNameMap[to] || value}
                      {index === 1 && addStationNumber()}
                    </Typography>
                  ) : (
                    <Link color="inherit" to={to} key={to}>
                      {breadcrumbNameMap[to] || value}
                      {index === 1 && addStationNumber()}
                    </Link>
                  );
                })}
              </Breadcrumbs>
            )}
            <Box width="500px">
              <BagCountDisplay />
            </Box>
            {showBackBtn && (
              <Button onClick={goBack} variant="outlined" size="small">
                go Back
              </Button>
            )}
            {showCustomerOrderId && (
              <Box display="flex" flexDirection="row" alignContent="center" justifyContent="center">
                <Box display="flex" fontSize="large" paddingX="1rem" alignItems="center">
                  CustomerALpha: {bag.customerAlphaId}
                </Box>
                <Box display="flex" alignItems="center" fontSize="large">
                  OrderAlpha: {bag.orderAlphaId}
                </Box>
              </Box>
            )}
          </Box>
          <div className="main">
            <LoaderComponent />
            <SentryRoutes>
              <Route path="/login/*" element={isLoggedIn ? <Navigate to="/station" /> : <Login />} />
              <Route path="/station/*" element={isLoggedIn ? <Stations /> : <Navigate to="/login" />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </SentryRoutes>
          </div>
        </div>
        <ToastContainer rtl={isRtl} position={toast.POSITION.TOP_CENTER} draggable={false} hideProgressBar={true} />
      </>
    </IntlProvider>
  );
});

export default Root;
