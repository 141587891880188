import React from 'react';
import { GridCellParams } from '@mui/x-data-grid';
import styled from 'styled-components';
import { ButtonBase } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

const ItemReportTrigger: React.FC<GridCellParams> = ({ row }) => {
  const navigate = useNavigate();

  const routeToModal = () => {
    navigate(`report/${row.itemCode}`, { state: { orderAlphaId: row.orderAlphaId } });
  };
  return <ColoredButton onClick={routeToModal}>{row.itemCode}</ColoredButton>;
};

export default ItemReportTrigger;

const ColoredButton = styled(ButtonBase)`
  color: #3e48a6;
`;
