import React, { useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';

import { ComponentProps, useGetSelectedItem } from 'modules/stations/components/SortingStepper/SortingStepper';
import CustomStepper, { PhotosStepperSchema } from '../../imagesCustomStepper/customStepper';
import { FineryAngleImages } from 'utils/interfaces';
import { FineryImages } from 'utils/enums/stationEnums';
import PhotosSelector from './photosSelector';

const config: {
  schema: PhotosStepperSchema[];
} = {
  schema: [
    {
      label: 'Care Label',
      type: FineryImages.careLabel,
    },
    {
      label: 'Front',
      type: FineryImages.front,
    },
    {
      label: 'Back',
      type: FineryImages.back,
    },
    {
      label: 'Inside Front',
      type: FineryImages.leftSide,
    },
    {
      label: 'Inside Back',
      type: FineryImages.rightSide,
    },
    {
      label: 'Extra',
      type: FineryImages.extra,
    },
  ],
};

interface Props extends ComponentProps {}

const findIntialStep = (images: FineryAngleImages) => {
  let initStep = FineryImages.careLabel;

  config.schema.some((stepSchema) => {
    if (!images[stepSchema.type]) {
      initStep = FineryImages[stepSchema.type];
      return true;
    } else {
      return false;
    }
  });

  return initStep;
};

/** Required in order */
const requiredSteps: (keyof FineryAngleImages)[] = [FineryImages.careLabel, FineryImages.front, FineryImages.back];
const optionalSteps: (keyof FineryAngleImages)[] = [FineryImages.leftSide, FineryImages.rightSide, FineryImages.extra];

const getDisabledSteps = (images: FineryAngleImages) => {
  const disabledSteps: (keyof FineryAngleImages)[] = [...optionalSteps];
  let disableRest = false;

  requiredSteps.forEach((step, index) => {
    if (disableRest) {
      disabledSteps.push(step);
    } else if (!images[step]) {
      disableRest = true;
    }
  });

  // If we are at the last step and we have all the required steps, enable everything else
  return disableRest ? disabledSteps : [];
};

const SelectPhotosComponent: React.FC<Props> = ({ onSelect }) => {
  const { selectedItem: currentItem } = useGetSelectedItem();
  const { itemImagesMap } = currentItem;

  const [images, setImages] = React.useState<FineryAngleImages>({
    careLabel: itemImagesMap?.careLabel || '',
    front: itemImagesMap?.front || '',
    back: itemImagesMap?.back || '',
    leftSide: itemImagesMap?.leftSide || '',
    rightSide: itemImagesMap?.rightSide || '',
    extra: itemImagesMap?.outside || '',
  });
  const disabledSteps = useMemo<(keyof FineryAngleImages)[]>(() => getDisabledSteps(images), [images]);
  const completedSteps = useMemo<(keyof FineryAngleImages)[]>(
    () => [...requiredSteps, ...optionalSteps].filter((imageKey) => images[imageKey]),
    [images]
  );
  const [activeStep, setActiveStep] = useState<FineryImages>(findIntialStep(images));

  const onStepClick = (step: FineryImages) => setActiveStep(step);

  // Save the current Image to the state
  const handleImageSizeState = (image: string) => {
    setImages({
      ...images,
      [activeStep]: image,
    });
  };

  const handleNextClick = () => {
    let localDisabledSteps = disabledSteps;

    // Check if not last stepper then jump to next stepper
    const currentActiveIndex = config.schema.findIndex((item) => item.type === activeStep);
    if (currentActiveIndex < config.schema.length - 1) {
      const nextStep = config.schema[currentActiveIndex + 1].type;
      if (!localDisabledSteps.includes(nextStep)) {
        setActiveStep(nextStep);
      }
    }
    // Check if last stepper then end flow
    if (currentActiveIndex === config.schema.length - 1) {
      handleOnSelect(true);
    }
  };

  const onSkip = (routeToStainDamage: boolean, routeTo?: 'stain' | 'damage') => {
    const skipNextStep = !routeToStainDamage;
    //Dont skip next step if we are routing to stainDamage
    handleOnSelect(skipNextStep, routeTo);
  };

  const handleOnSelect = (skipStep: boolean, routeTo?: 'stain' | 'damage') => {
    onSelect(
      {
        ...currentItem,
        itemImagesMap: images,
      },
      {
        skipStep: skipStep,
        urlState: routeTo,
        forceRoute: !skipStep,
      }
    );
  };
  return (
    <Grid container style={{ flex: 'auto', overflow: 'scroll' }}>
      <CustomStepper
        schema={config.schema}
        onStepClick={onStepClick}
        disabledSteps={disabledSteps as FineryImages[]}
        completedSteps={completedSteps}
        activeStep={activeStep}
      />

      <PhotosSelector
        onSkip={onSkip}
        showSkipButton={!requiredSteps.includes(activeStep)}
        key={activeStep}
        imageSrc={{ ...images }[activeStep] || ''}
        onPhotoTaken={handleImageSizeState}
        onNextClick={handleNextClick}
        activeStep={activeStep}
      />
    </Grid>
  );
};

export default SelectPhotosComponent;
