import { useMutation } from 'react-query';
import { StationService } from 'services';

interface Options {
  bagCode: string;
}
const useMarkBagAsSorted = () => {
  return useMutation<any, Error, Options>(({ bagCode }) => StationService.markBagAsSorted(bagCode));
};
export default useMarkBagAsSorted;
