import React, { BaseSyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import useGetItemTypeMeasurementsConfig from 'services/queries/useGetItemTypeMeasurementsConfig';
import styled from 'styled-components';
import { IItemReportDetail } from 'utils/interfaces/stainManStationInterfaces';
import { Controller, useForm } from 'react-hook-form';
import capitalize from 'lodash/capitalize';
import startCase from 'lodash/startCase';
import { MainKeyboardRefProps } from 'components/mainKeyboard/mainKeyboard';
import { ItemMeasurements, MeasurementConfig } from 'utils/interfaces';
import { createStyles, makeStyles } from '@material-ui/styles';
import { ReactComponent as MeasurementIcon } from 'assets/images/svgs/measurement.svg';
import useSetAfterMeasurements from '../../hooks/useSetAfterMeasurements';
import { ReactComponent as GreenCheckSVG } from 'assets/images/svgs/check_circle.svg';
import { ReactComponent as CancelSVG } from 'assets/images/svgs/cancel.svg';
import lodash from 'lodash';
import {
  getDefault,
  InputNameTypes,
  InputObj,
  InputState,
} from '../../../finerySorterStation/components/dialogs/selectMeasurementsComponent/selectMeasurementsDialog';
import { MainKeyboard } from '../../../../../components/mainKeyboard';

interface MeasurementSectionProps {
  itemReportDetail: IItemReportDetail
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '2rem',
    },
    paper: {
      padding: '10px 4px',
      display: 'flex',
      alignItems: 'stretch',
    },
    input: {},
    keyBoard: {
      width: '100%',
      position: 'absolute',
      bottom: '0',
      left: '0',
      right: '0',
      zIndex: 2000,
    },
    table: {},
  }),
);
type FormData = { [key in string]: string | number };

const MeasurementSection: React.FC<MeasurementSectionProps> = ({ itemReportDetail }) => {
  const { data: measurementsConfig } = useGetItemTypeMeasurementsConfig(itemReportDetail.type);

  const keyboardInputKeys = useMemo<InputObj>(() => {
    const filterKeys: InputObj = {};
    measurementsConfig?.measurements.forEach(
      (measurement) =>
        (filterKeys[measurement.key] = String(getDefault(measurementsConfig.keyedMeasurements[measurement.key], itemReportDetail.measurementsList) || '')),
    );
    return filterKeys;
  }, [itemReportDetail.measurementsList, measurementsConfig]);
  const classes = useStyles();
  const [inputsState, setInputsState] = useState<InputState<InputNameTypes>>({
    input: { default: '', ...keyboardInputKeys },
    inputName: '',
    keyboardOpen: false,
  });
  const { mutate: setAfterMeasurements } = useSetAfterMeasurements()
  const formMethods = useForm<FormData>();
  const keyboardRef = useRef<MainKeyboardRefProps>(null);

  useEffect(() => {
    // reset Input Value
    if (inputsState.inputName && keyboardRef.current) {
      const value = inputsState.input[inputsState.inputName];
      if (value) {
        keyboardRef.current.setInput(value);
      } else {
        keyboardRef.current.clearInput();
      }
    }
  }, [inputsState.input, inputsState.inputName]);


  const setActiveInput = (inputName: InputNameTypes) => {
    setInputsState((prev) => ({ ...prev, inputName, keyboardOpen: true }));
  };
  const updateKeyboardInputs = (value: string) => {
    setInputsState((prev) => ({ ...prev, input: { ...prev.input, [inputsState.inputName]: value } }));
  };

  const onFieldChange = (fieldOnChange: (...params: any[]) => void, type: MeasurementConfig['type']) => {
    return type === 'number'
      ? (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let output: number | '' = parseInt(event.target.value, 10);
        output = isNaN(output) ? '' : output;
        updateKeyboardInputs(String(output));
        fieldOnChange(output);
      }
      : (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        updateKeyboardInputs(event.target.value);
        fieldOnChange(event.target.value);
      };
  };
  const onToggleKeyboard = (value: boolean, measurementKey: string) => {
    value && setActiveInput(measurementKey);
    setInputsState((prev) => ({ ...prev, input: { ...prev.input, [prev.inputName]: prev.input[measurementKey] || '' }, keyboardOpen: value }));
    keyboardRef.current?.toggleKeyBoard(value);
  };

  function handleNextClick(data: FormData, event?: BaseSyntheticEvent) {
    event && event.preventDefault();
    onClearInput();
    const measurementsList: ItemMeasurements[] = Object.entries(data).map(([key, value]) => ({
      key,
      value,
      letter: measurementsConfig?.keyedMeasurements[key].letter || '',
      unit: measurementsConfig?.keyedMeasurements[key].unit,
    }));
    setAfterMeasurements({
      afterMeasurementsList: measurementsList,
      itemCode: itemReportDetail.itemCode,
      orderAlphaId: itemReportDetail.orderAlphaId,
      itemType: itemReportDetail.type,
    })
  }

  /**
   * Clears input and keyboard input
   */
  const onClearInput = () => {
    keyboardRef.current?.clearInput();
    handleInputChange('');
  };
  /**
   * @description
   * Updates keyboard input-name/target to the changed input name
   * @param value
   */
  const handleInputChange = (value: string) => {
    let output: number | string = parseInt(value, 10);
    output = isNaN(output) ? '' : output;
    if (measurementsConfig?.keyedMeasurements?.[inputsState.inputName]?.type !== 'number') {
      output = value;
    }

    formMethods.setValue(inputsState.inputName, output);
    formMethods.trigger(inputsState.inputName);
    updateKeyboardInputs(String(output));
  };
  return (
    <Box>
      <Box className={classes.keyBoard}>
        <MainKeyboard onChange={(input: string) => handleInputChange(input)} ref={keyboardRef} />
      </Box>
      <HeaderGrid
        container
        wrap="nowrap"
        justifyContent="space-between"
        alignItems={'center'}>
        <Grid container item>
          <StyledMeasurementIcon width="4.5rem" height="4.5rem" />
          <Header>Measurements</Header>
        </Grid>
        {lodash.isEmpty(itemReportDetail.afterMeasurementsList) &&
          <Box>
            <FormControl>
              <NextButton
                form="measurements-form"
                variant="contained" type="submit"
                fullWidth color="primary" disabled={!formMethods.formState.isValid}>Next</NextButton>
            </FormControl>
          </Box>}

      </HeaderGrid>

      <Grid container>
        {!lodash.isEmpty(itemReportDetail.afterMeasurementsList) ?
          <TableGrid container xs={8} item><MeasurementBeforeAfterTable itemReportDetail={itemReportDetail} /></TableGrid>
          :
          (<Grid container spacing={1}
                 alignItems="center"
                 item xs={8}
                 component="form"
                 onSubmit={formMethods.handleSubmit(handleNextClick)}
                 id="measurements-form"
                 style={{ padding: '1rem', gap: '4rem', maxHeight: '24rem', overflowY: 'auto' }}>
            {measurementsConfig?.measurements.map((measurement) => (
              <Grid item xs={4} key={measurement.key}>
                <Controller
                  name={measurement.key}
                  control={formMethods.control}
                  rules={{ required: measurement.required, minLength: 1, min: measurement.type === 'number' ? measurement.min : 1 }}
                  key={measurement.key}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={`${capitalize(measurement.letter)} - ${startCase(measurement.key)}`}
                      onFocus={() => onToggleKeyboard(true, measurement.key)}
                      variant="outlined"
                      fullWidth
                      className={classes.input}
                      placeholder={
                        measurement.type === 'number' ? `Add size in ${measurement?.unit || 'cm'}` : 'Please add some details for the measurements'
                      }
                      inputProps={{ 'aria-label': `Measurement input for ${measurement.key}` }}
                      type={measurement.type}
                      multiline={measurement.type === 'text'}
                      onChange={onFieldChange(field.onChange, measurement.type)}
                      key={measurement.key}
                      InputProps={{
                        endAdornment: measurement.unit && <InputAdornment position="end">{measurement.unit}</InputAdornment>,
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
            ))}
          </Grid>)
        }

        <Grid item xs={4}>
          <MeasurementImg src={measurementsConfig?.imageUrl} alt="measurement-img" />
        </Grid>
      </Grid>
    </Box>

  )
}
const MeasurementBeforeAfterTable: React.FC<{ itemReportDetail: IItemReportDetail }> = ({ itemReportDetail }) => {
  const classes = useStyles();
  const measurementsComparision = useMemo(() => {
    return itemReportDetail.measurementsList?.map(measurement => {
      const afterMeasurement = itemReportDetail.afterMeasurementsList?.find(afterMeasurement => afterMeasurement.letter === measurement.letter)
      return {
        label: `${capitalize(measurement.letter)} - ${startCase(measurement.key)}`,
        before: measurement.value,
        after: afterMeasurement ? afterMeasurement.value : 0,
        result: measurement.value === (afterMeasurement ? afterMeasurement.value : 0),
      }
    })
  }, [itemReportDetail])
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead style={{ background: 'rgba(238, 240, 255, 0.5)' }}>
          <TableRow>
            <TableCell>Measurements</TableCell>
            <TableCell align="center">Before</TableCell>
            <TableCell align="center">After</TableCell>
            <TableCell align="center">Result</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {measurementsComparision?.map((row) => (
            <TableRow key={row.label}>
              <TableCell component="th" scope="row">
                {row.label}
              </TableCell>
              <TableCell align="center">{row.before}</TableCell>
              <TableCell align="center">{row.after}</TableCell>
              <TableCell align="center">{row.result ? <GreenCheckSVG /> : <CancelSVG />}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>)
}
export default MeasurementSection
const MeasurementImg = styled.img`
  width: 100%;
  height: 16rem;
  margin-top: 1rem;
`;
const StyledMeasurementIcon = styled(MeasurementIcon)`
`;
const Header = styled.p`
  font-family: Ubuntu, serif;
  font-size: 25px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #111135;
  padding-left: 0.75rem;
`;
const NextButton = styled(Button)`
  border-radius: 20px;
  border-width: 2px;
  font-size: 18px;
  width: 12rem;
`;
const HeaderGrid = styled(Grid)`
  margin-top: 1rem;
`
const TableGrid = styled(Grid)`
  padding: 1rem;
`

