import { IItemReportDetail } from 'utils/interfaces/stainManStationInterfaces';
import { useValidateEntityServiceLine } from 'services/mutations';
import { useCallback, useEffect, useState } from 'react';
import useGetItemReportDetail, { initialReport, QUERY_KEY as ITEM_REPORT_QUERY_KEY } from '../queries/useGetItemReportDetail';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';

export interface QueryResponse {
  itemReportDetail: IItemReportDetail;
}

const useHandleGetItemReportDetail = (itemIdentifier: string = '', orderAlphaId?: string) => {
  const navigate = useNavigate();
  const { mutateAsync: validateEntityServiceLine } = useValidateEntityServiceLine();
  const [enabled, setEnabled] = useState(false);
  const queryClient = useQueryClient();

  const [isFirstScan, setIsFirstScan] = useState<boolean>(true);

  const validateOnScan = useCallback(
    async (entityId: string, onSuccess: (entityId: string) => void) => {
      await validateEntityServiceLine(
        { entityId },
        {
          onSuccess: () => onSuccess(entityId),
          onError: () => {
            setEnabled(false);
            navigate(-1);
          },
        }
      );
    },
    [navigate, validateEntityServiceLine]
  );

  useEffect(() => {
    if (!enabled && itemIdentifier) {
      validateOnScan(itemIdentifier, () => setEnabled(true));
    }
  }, [itemIdentifier, enabled, validateOnScan]);

  const { data, ...itemReportDetailQuery } = useGetItemReportDetail(itemIdentifier, isFirstScan, enabled, orderAlphaId);

  useEffect(() => {
    if (isFirstScan && itemReportDetailQuery.isFetchedAfterMount) {
      setIsFirstScan(false);
    }
  }, [isFirstScan, itemReportDetailQuery.isFetchedAfterMount]);

  const updateItemDetails = (updatedItem: IItemReportDetail) => {
    queryClient.setQueryData<QueryResponse | undefined>([ITEM_REPORT_QUERY_KEY, itemIdentifier, { orderAlphaId }], (old) =>
      old ? { itemReportDetail: { ...old.itemReportDetail, ...updatedItem } } : undefined
    );
  };

  return {
    itemReportDetail: data ? data.itemReportDetail : initialReport,
    ...itemReportDetailQuery,
    updateItemDetails,
  };
};
export default useHandleGetItemReportDetail;
