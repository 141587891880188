import { useEffect, useState } from 'react';

import { IStainManFilters, PaginationMeta } from './useGetOrdersWithStainedItemsList';
import usePingPdfLink from './usePingPdfLink';
import usePrintTableItems from './usePrintTableItems';

//TODO : TYPES Can be refactored to be generic, low priority for now
const useGetPdfLink = (selectedFilters: IStainManFilters, pageNumber: number, paginationMeta: PaginationMeta) => {
  const [printEnabled, setPrintEnabled] = useState(false);
  const { pdfLink, isSuccess, isFetching: isPrinting, isError } = usePrintTableItems(selectedFilters, pageNumber, printEnabled, paginationMeta);
  useEffect(() => {
    if ((isSuccess && printEnabled && pdfLink) || (!isPrinting && isError)) {
      setPrintEnabled(false);
    }
  }, [isSuccess, printEnabled, pdfLink, isPrinting, isError]);
  const handlePrintOrderPaper = () => {
    setPrintEnabled(true);
  };

  const { isFetching: isPinging } = usePingPdfLink(pdfLink, !!pdfLink);

  return { handlePrintOrderPaper, isPinging, pdfLink, isPrinting };
};

export default useGetPdfLink;
