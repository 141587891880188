import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import SelectBrandDialog from './selectBrandDialog';
import MissingBrandDialog from './missingBrandDialog';
import { ComponentProps, useGetSelectedItem } from 'modules/stations/components/SortingStepper/SortingStepper';

interface Props extends ComponentProps { }

const SelectBrandComponent: React.FC<Props> = ({ onSelect }) => {
  const navigate = useNavigate()
  const { selectedItem: currentItem } = useGetSelectedItem();

  const handleMissingBrandSelected = () => {
    navigate('missing-brand')
  }

  const handleReturnToBrands = () => {
    navigate('.')
  }

  // Route to missing brand if care label is present (User chose `missing brand` before and went back to select brand)
  useEffect(() => {
    if (currentItem.brand?.careLabel) handleMissingBrandSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Routes>
      <Route path={'/'} element={<SelectBrandDialog onSelect={onSelect} onMissingBrandSelected={handleMissingBrandSelected} />} />
      <Route path={'/missing-brand'} element={<MissingBrandDialog onSelect={onSelect} onGoBack={handleReturnToBrands} />} />
      <Route path="*" element={<Navigate to={''} />} />
    </Routes>
  );
};

export default SelectBrandComponent;
