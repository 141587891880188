import { createContext, useContext } from 'react';
//TODO: Remove when migrating react-scripts and mobx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { action, computed, observable, runInAction } from 'mobx';

import { StationService } from '../services';
import stationService from '../services/station.service';
import { BagTypes, StationTypes } from '../utils/enums/stationEnums';
import { IActiveItem, IBag, IBrandsResponse, IOrderItem, IOrdersListObj, OrderServiceLines, SaItem } from '../utils/interfaces/stationInterfaces';
import { Bag, OrderItem } from '../utils/classes';
import { inThePastGivenDays } from 'utils/helpers/date';
import { InterchangeableServiceLines, ServiceLinesForBagsMap } from 'utils/maps/serviceLinesForBagsMap';
import { ActivityTypes } from 'utils/enums/typesEnum';

export class StationStore {
  @observable stationId = '';
  @observable stationSection = '';
  @observable stationType: StationTypes;
  @observable item: IOrderItem = {} as IOrderItem;
  @observable bag = new Bag();
  @observable items: IOrderItem[] = [];
  @observable private _currentItem: IOrderItem = new OrderItem();
  @observable private _bagScanTimeStamp: Date | null = null;
  @observable latestBrandSelected: IBrandsResponse | undefined;
  @observable private _ordersList: IOrdersListObj[] = [] as IOrdersListObj[];
  // Redirect on RFID Page (Sorter)
  @observable _activeItem: IActiveItem = { itemCode: '', itemImage: '' } as IActiveItem;
  // Order Already Completed Dialog flag
  @observable orderAlreadyCompletedDialog: IOrderItem = {} as IOrderItem;

  // Special Attention Feature
  @observable specialAttentionItemsList: SaItem[] = [] as SaItem[];
  @observable stationServiceLine: OrderServiceLines = OrderServiceLines.LAUNDRY;

  focusInput$ = observable.box(true, { deep: false });

  @computed
  get sortedItems() {
    return this.items.slice().sort((a, b) => {
      if (a.isSorted === true && b.isSorted === false) {
        return 1;
      }
      if (b.isSorted === true && a.isSorted === false) {
        return -1;
      }
      if (!a.isSorted && b.isSorted) {
        return -1;
      }
      return 0;
    });
  }

  @computed
  get bagScanTimeStamp() {
    return this._bagScanTimeStamp;
  }

  set bagScanTimeStamp(value: Date | null) {
    localStorage.setItem('bagScanTimeStamp', String(value));
    this._bagScanTimeStamp = value;
  }

  @computed.struct
  get currentItem() {
    return this._currentItem;
  }

  @computed.struct
  get currentStation() {
    return this.stationType;
  }

  @computed.struct
  get currentSection() {
    return this.stationSection;
  }

  set currentItem(value: IOrderItem) {
    this._currentItem = value;
    const foundIndex = this.items.findIndex((item) => {
      return item.code === value.code;
    });
    this.editItemInList(value, foundIndex);
  }

  get activeItem() {
    return this._activeItem;
  }

  @computed
  get isFinisherStation() {
    return (
      this.stationType === StationTypes.ShirtPressing ||
      this.stationType === StationTypes.MultiPressing ||
      this.stationType === StationTypes.PantPressing ||
      this.stationType === StationTypes.UtilityPressing ||
      this.stationType === StationTypes.FineryPressing
    );
  }

  @action.bound
  setActiveItem(qrCode: string, frontImage?: string) {
    // Save the values to the store
    this._activeItem = {
      itemCode: qrCode,
      itemImage: frontImage ? frontImage : undefined,
    };

    // Persist the active item to save in the localStorage as well
    localStorage.setItem(
      'activeItem',
      JSON.stringify({
        itemCode: qrCode,
        itemImage: frontImage ? frontImage : undefined,
      })
    );
  }

  constructor() {
    const scanTime = localStorage.getItem('bagScanTimeStamp');
    this.stationId = localStorage.getItem('stationId') || '';
    this.stationSection = localStorage.getItem('stationSection') || '';
    this.stationServiceLine = (localStorage.getItem('stationServiceLine') as OrderServiceLines) || OrderServiceLines.LAUNDRY;
    this.stationType = localStorage.getItem('stationType') as StationTypes;

    const activeItem = localStorage.getItem('activeItem');
    this._activeItem = activeItem ? JSON.parse(activeItem) : ({ itemCode: '', itemImage: '' } as IActiveItem);

    const bag = localStorage.getItem('bag');
    const items = localStorage.getItem('items');

    // Retrieve and load special attention items from the order if any
    const specialAttentionItems = localStorage.getItem('special_attention_items') || '[]';
    this.setSpecialAttentionItemsList(JSON.parse(specialAttentionItems));

    if (Boolean(bag) && bag !== null) {
      this.bag = JSON.parse(bag);
    }
    if (Boolean(items) && items !== null) {
      this.items = JSON.parse(items);
    }
    if (scanTime) {
      this.bagScanTimeStamp = new Date(scanTime);
    }
  }

  @action.bound
  setLatestBrandSelected(brand: IBrandsResponse | undefined) {
    this.latestBrandSelected = brand;
  }

  @action.bound
  setCurrentItem(item: IOrderItem) {
    this.currentItem = { ...item };
  }

  @action.bound
  editItemInListWithItem(item: IOrderItem) {
    const foundIndex = this.items.findIndex((value) => {
      return value.code === item.code;
    });
    this.editItemInList(item, foundIndex);
  }

  @action.bound
  setOrderAlreadyCompletedDialog(item: IOrderItem) {
    this.orderAlreadyCompletedDialog = { ...item };
  }

  // update current item details and sets is sorted false
  @action.bound
  updateCurrentItemDetails(item: IOrderItem) {
    this.currentItem = {
      ...this.currentItem,
      ...item,
      isSorted: false,
    };
  }

  @action.bound
  addItemToItemsList(item: IOrderItem) {
    this.items.push(item);
    localStorage.setItem('items', JSON.stringify(this.items));
  }

  @action.bound
  editItemInList(item: IOrderItem, index: number) {
    this.items[index] = item;
    localStorage.setItem('items', JSON.stringify(this.items));
  }

  @action.bound
  setStationId(stationId: string) {
    this.stationId = stationId;
    localStorage.setItem('stationId', stationId);
  }

  @action.bound
  setStationType(stationType: StationTypes) {
    this.stationType = stationType;
    localStorage.setItem('stationType', stationType);
  }

  @action.bound
  setStationSection(stationSection: string) {
    this.stationSection = stationSection;
    localStorage.setItem('stationSection', stationSection);
  }

  @action.bound
  setItems(items: IOrderItem[]) {
    this.items = items;
  }

  @action
  clearUserStations() {
    this.stationId = '';
    this.stationType = StationTypes.Unselected;
    this.stationServiceLine = OrderServiceLines.LAUNDRY;
    localStorage.removeItem('stationId');
    localStorage.removeItem('stationType');
    localStorage.removeItem('stationSection');
    localStorage.removeItem('stationServiceLine');
  }

  @action.bound
  setOrderItem(item: IOrderItem) {
    this.item = item;
  }

  getOrderItemWithReports = async (rfidCode: string) => {
    const item = await this.fetchOrderItemByRfidCode(rfidCode);
    this.setSpecialAttentionItemsList(item.specialAttentionItemsArray || []);
    this.setOrderItem(item);
    this.setCurrentItem(item);
    return item;
  };

  getOrderItemByRfidCode = async (rfidCode: string) => {
    const item = await this.fetchOrderItemByRfidCode(rfidCode);

    if (item.isOrderCompleted) {
      this.setOrderAlreadyCompletedDialog(item);
      return;
    }

    this.setSpecialAttentionItemsList(item.specialAttentionItemsArray || []);
    this.setOrderItem(item);

    return item;
  };

  fetchAndSetOrderItemByRfidCode = async (rfidCode: string) => {
    const item = await this.fetchOrderItemByRfidCode(rfidCode);
    this.setSpecialAttentionItemsList(item.specialAttentionItemsArray || []);
    this.setOrderItem(item);

    return item;
  };

  getOrderBagByQrCode = async (qrCode: string) => {
    const bag = await StationService.getOrderBagByQrCode(qrCode);

    // set bag & items associated with the bag
    runInAction(() => {
      this.setBag(bag);
      this.setSpecialAttentionItemsList(bag.order.specialAttentionItemsArray || []);
      if (bag.orderItemList) {
        this.items = bag.orderItemList;
        localStorage.setItem('items', JSON.stringify(bag.orderItemList));
      }
    });

    return bag;
  };

  @action.bound
  setBag(bag: IBag) {
    this.bag = bag;
    localStorage.setItem('bag', JSON.stringify(bag));
  }

  @action.bound
  setStationServiceLine(serviceLine: OrderServiceLines) {
    this.stationServiceLine = serviceLine;
    localStorage.setItem('stationServiceLine', serviceLine);
  }

  fetchOrderItemByQrCode = async (qrCode: string) => {
    const response = await StationService.getOrderItemByQrCode(qrCode);
    return response;
  };
  // getOrderItemByQrCode

  fetchOrderItemByRfidCode = async (rfidCode: string) => {
    const response = await StationService.getOrderItemByRfidCode(rfidCode);
    return response;
  };

  fetchItemsByOrderAlphaId = async (params: any) => {
    const { orderAlphaId, itemCode } = params;
    const response = await StationService.fetchItemsByOrderAlphaId(orderAlphaId, itemCode);
    return response.orderSearchItemsList;
  };

  addNewToBag = async (itemCode: string, bagCode: string) => {
    const response = await StationService.addNewToBag(itemCode, bagCode);
    return response;
  };

  addExistingToBag = async (itemCode: string, bagCode: string) => {
    const response = await StationService.addExistingToBag(itemCode, bagCode);
    return response;
  };

  linkRfidToItem = async (itemCode: string, orderId: string, rfid: string) => {
    const response = await StationService.linkRfidToItem(rfid, itemCode, orderId);
    return response;
  };

  updateBagType = async (bagType: BagTypes, bagCode?: string) => {
    await StationService.updateBagType(bagCode ? bagCode : this.bag.bagCode, bagType);
    this.setBag({
      ...this.bag,
      type: bagType,
    });
  };

  isValidBagForOrder = (bagType: BagTypes) => {
    const bagServiceLine = Object.entries(ServiceLinesForBagsMap).find(([serviceLine, bagTypes]) =>
      bagTypes.includes(bagType)
    )?.[0] as OrderServiceLines;
    const isInterchangeable = InterchangeableServiceLines.some(
      (serviceLineTuple) => serviceLineTuple.includes(bagServiceLine) && serviceLineTuple.includes(this.bag.order.serviceLine)
    );

    return isInterchangeable || ServiceLinesForBagsMap[this.bag.order.serviceLine].includes(bagType);
  };

  /**
   * @description deletes a stain report from the stain array
   * @param index
   */
  deleteReportFromItem = (index: number) => {
    if (!this.currentItem.reportDetailsList) {
      return;
    }
    const newStainDetailsList = [...this.currentItem.reportDetailsList];
    newStainDetailsList.splice(index, 1);
    this.updateCurrentItemDetails({
      ...this.currentItem,
      reportDetailsList: newStainDetailsList,
    });
  };

  @action.bound
  clearData = () => {
    this.bag = new Bag();
    this.items = [];
    this.currentItem = new OrderItem();
    this.item = new OrderItem();
  };

  @action.bound
  updateItemRequest = async (item: IOrderItem, managerId = '') => {
    const itemData = await stationService.updateItem(item, managerId);
    runInAction(() => {
      this.setCurrentItem(itemData);
    });
  };

  @action.bound
  createNewItem = async (rfid: string, item?: IOrderItem) => {
    const newItem = await stationService.createNewItem(rfid, item);
    runInAction(() => {
      this.setCurrentItem(newItem);
    });
  };

  @action.bound
  markItemAsSorted = async () => {
    await stationService.markItemAsSorted(this.currentItem.rfid);
    runInAction(() => {
      this.setCurrentItem({
        ...this.currentItem,
        isSorted: true,
      });
    });
  };

  @action.bound
  setBagScanTimeStamp(value: Date) {
    this.bagScanTimeStamp = value;
  }

  // finish a bag and clear related data from store and local storage
  @action.bound
  handleFinishBag() {
    // TODO: clear LS for speaci attention ITems list
    this.setBag(new Bag());
    this.items = [];
    this.bagScanTimeStamp = null;
    localStorage.removeItem('items');
    localStorage.removeItem('bagScanTimeStamp');
    localStorage.removeItem('bag');
  }

  @action.bound
  async createBagAndAddToStore(orderPickupDate: string, orderId: string, bagCode: string, bagType: string) {
    let valid = inThePastGivenDays(orderPickupDate, 5);
    return new Promise<IBag>(async (resolve, reject) => {
      if (valid) {
        const bag = await stationService.createBag(orderId, bagCode, bagType); // call the create bag service api
        runInAction(() => {
          this.bag = bag;
          this.setSpecialAttentionItemsList(bag.order.specialAttentionItemsArray || []);
        });

        resolve(bag);
      }
      reject();
    });
  }

  @action.bound
  async removeItemFromBag(managerId: string) {
    await stationService.removeItemFromBag(this.bag.bagCode, this.currentItem.code, managerId);
    // remove item from list
    runInAction(() => {
      const foundIndex = this.items.findIndex((item) => item.code === this.currentItem.code);
      if (foundIndex !== -1) {
        this.items = this.items.filter((item) => !(item.code === this.currentItem.code));
        localStorage.setItem('items', JSON.stringify(this.items));
        this.setCurrentItem(this.items[0] || new OrderItem()); // set default current item after deletion
      }
    });
  }

  /** ==== Racking Station ==== */
  @computed.struct
  get ordersList() {
    return this._ordersList;
  }

  @action.bound
  setOrdersList(ordersList: IOrdersListObj[]) {
    this._ordersList = [...ordersList];
  }

  @action.bound
  setOrderToReady(item: IOrderItem) {
    const updatedArray = this._ordersList.map((order: IOrdersListObj) => {
      if (order.orderAlphaId === item.orderAlphaId) {
        return { ...order, isRacked: true };
      }
      return order;
    });
    this.setOrdersList(updatedArray);
  }

  @action.bound
  async fetchOrdersList() {
    const ordersListResponse = await stationService.fetchOrdersList();
    this.setOrdersList(ordersListResponse);
  }

  @action.bound
  async clearRacks(orderIdsList: string[]) {
    await stationService.clearRacks(orderIdsList);
  }

  /** ==== Special Attention Feature ==== */

  @computed
  get hasSpecialAttentionItems() {
    return this.specialAttentionItemsList.length > 0 ? true : false;
  }

  @computed
  get specialItemsInBag() {
    return this.specialAttentionItemsList.filter((item: SaItem) => {
      return item.bagCode && item.bagCode === this.bag.bagCode && !item.itemCode;
    });
  }

  @computed
  get linkedItem() {
    return (
      this.specialAttentionItemsList.find((item: SaItem) => {
        return item.bagCode && item.itemCode === this.currentItem.code;
      }) || undefined
    );
  }

  @computed
  get isAllSaItemsLinked() {
    if (this.specialAttentionItemsList.length > 0)
      return this.specialAttentionItemsList.filter((item: SaItem) => {
        return item.bagCode && item.bagCode === this.bag.bagCode && !item.itemCode;
      }).length > 0
        ? false
        : true;
    return true;
  }

  @computed
  get activityType() {
    let activityType;
    if (
      this.stationType === StationTypes.Tagger ||
      this.stationType === StationTypes.Sorter ||
      this.stationType === StationTypes.ShoesTagger ||
      this.stationType === StationTypes.ShoesSorter ||
      this.stationType === StationTypes.FinerySorter ||
      this.stationType === StationTypes.FineryTagger
    ) {
      activityType = ActivityTypes.BAG;
    } else if (
      this.stationType === StationTypes.ShirtPressing ||
      this.stationType === StationTypes.MultiPressing ||
      this.stationType === StationTypes.PantPressing ||
      this.stationType === StationTypes.UtilityPressing ||
      this.stationType === StationTypes.CollarAndCuffPressing ||
      this.stationType === StationTypes.PantsTopper ||
      this.stationType === StationTypes.FineryTailorPost ||
      this.stationType === StationTypes.FineryTailorPre ||
      this.stationType === StationTypes.FinerySpotter ||
      this.stationType === StationTypes.FineryCleaning ||
      this.stationType === StationTypes.FineryDrying ||
      this.stationType === StationTypes.FineryPressing
    ) {
      activityType = ActivityTypes.SHIRT;
    } else if (this.stationType === StationTypes.ShoesBeforeAfter) {
      activityType = ActivityTypes.SHOE;
    }
    return activityType;
  }

  @action.bound
  setSpecialAttentionItemsList(saList: SaItem[]) {
    // Sanity Clean
    this.specialAttentionItemsList = [];

    // Fill in the new Special Attention array
    this.specialAttentionItemsList = [...saList];

    // Persist Data in LS if it's on the sorter station / Tagger Station (Refresh ability)
    if (this.stationType === 'sorter' || this.stationType === 'tagger') {
      localStorage.setItem('special_attention_items', JSON.stringify(this.specialAttentionItemsList));
    }
  }

  @action.bound
  markAsInBag(saItemId: string) {
    const bagCode = this.bag.bagCode;

    const updatedArray = this.specialAttentionItemsList.filter((item: SaItem) => {
      if (item.id === saItemId) {
        delete item.notInBag;
        item.bagCode = bagCode;
      }
      return item;
    });

    this.setSpecialAttentionItemsList(updatedArray);
  }

  @action.bound
  markAsNotInBag(saItemId: string) {
    const updatedArray = this.specialAttentionItemsList.filter((item: SaItem) => {
      if (item.id === saItemId) {
        delete item.bagCode;
        item.notInBag = true;
      }
      return item;
    });

    this.setSpecialAttentionItemsList(updatedArray);
  }

  @action.bound
  linkSaItem(saItemId: string) {
    const updatedArray = this.specialAttentionItemsList.filter((item: SaItem) => {
      if (item.id === saItemId) item.itemCode = this.currentItem.code;
      return item;
    });
    this.setSpecialAttentionItemsList(updatedArray);
  }

  @action.bound
  unlinkSaItem(saItemId: string) {
    const updatedArray = this.specialAttentionItemsList.filter((item: SaItem) => {
      if (item.id === saItemId) delete item.itemCode;
      return item;
    });

    this.setSpecialAttentionItemsList(updatedArray);
  }

  @action.bound
  async linkSpecialAttentionItemToBag(saItemId: string) {
    // TODO: Check if Item already in bag return without api call
    const bagCode = this.bag.bagCode;

    await StationService.linkSpecialAttentionItemToBag(bagCode, saItemId);

    this.markAsInBag(saItemId);
  }

  @action.bound
  async unlinkSpecialAttentionItemFromBag(saItemId: string) {
    const itemIndex = this.specialAttentionItemsList.findIndex((item: SaItem) => item.id === saItemId);

    if (this.specialAttentionItemsList[itemIndex].bagCode) await StationService.unlinkSpecialAttentionItemFromBag(this.bag.bagCode, saItemId);

    this.markAsNotInBag(saItemId);
    return;
  }

  @action.bound
  async linkItemToSpecialAttentionItem(saItemId: string) {
    await StationService.linkItemToSpecialAttentionItem(this.currentItem.rfid, saItemId);
    this.linkSaItem(saItemId);
  }

  @action.bound
  async unlinkItemToSpecialAttentionItem() {
    if (this.linkedItem) {
      await StationService.unlinkItemToSpecialAttentionItem(this.currentItem.rfid, this.linkedItem!.id);
      this.unlinkSaItem(this.linkedItem!.id);
    }
  }
}

const stationStore = new StationStore();
export { stationStore };

const StationStoreContext = createContext(stationStore);
export const useStationStore = () => useContext(StationStoreContext);
