import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { useStationStore } from 'stores';
import { item, SelectStationItemList } from 'components/selectStationItemList';
import { StationTypes } from 'utils/enums/stationEnums';
import { OrderServiceLines } from 'utils/interfaces';
import useGetStationsList from './queries/useGetStationsList';

interface StationSelectType extends Record<string, string> {
  type: 'number' | 'type' | 'section';
}
const SelectStation: React.FC = () => {
  const { type } = useParams<StationSelectType>();
  const navigate = useNavigate();
  const { stationType, stationSection, setStationId, setStationType, setStationSection, setStationServiceLine } = useStationStore();
  let itemList: item[] | any = [];
  let title: JSX.Element = <></>;

  const { stationSections, stationNumbers } = useGetStationsList();

  const selectHandler = (value: string | StationTypes) => {
    switch (type) {
      case 'number': {
        setStationId(value);
        setStationServiceLine(Object.entries(OrderServiceLines).find(([key, val]) => value.includes(val))?.[1] || OrderServiceLines.LAUNDRY);
        break;
      }
      case 'section': {
        setStationSection(value);
        navigate(`/login/manager/selectStation/type`);
        break;
      }
      case 'type': {
        setStationType(value as StationTypes);

        navigate(`/login/manager/selectStation/number`);
      }
    }
  };

  switch (type) {
    case 'number': {
      if (stationNumbers && stationNumbers[stationType]) {
        itemList = stationNumbers[stationType];
        title = <FormattedMessage id="station.selectStation.number" defaultMessage="Select Station Number" />;
      } else {
        console.error('Error: stationNumbers is undefined. Failed to fetch.');
      }

      break;
    }
    case 'type': {
      if (stationSections && stationSections.length) {
        stationSections.map((section) => {
          if (section.value === stationSection) {
            itemList = section.items;
          }
          return null;
        });
        title = <FormattedMessage id="station.selectStation.type" defaultMessage="Select Station Type" />;
      } else {
        console.error('Error: stationSections is undefined. Failed to fetch.');
      }

      break;
    }
    case 'section': {
      itemList = stationSections;
      title = <FormattedMessage id="station.selectStation.section" defaultMessage="Select Station Section" />;

      break;
    }
  }

  return <SelectStationItemList items={itemList} title={title} onSelect={selectHandler} type={type} />;
};

export default SelectStation;
