import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { SaDialog } from 'modules/stations/components/saDialog';
import { linkActions } from 'modules/stations/components/saDialog/saDialog';
import { SaDialogTypes } from 'utils/enums/stationEnums';
import { IBag, IOrderItem } from 'utils/interfaces';
import { SAHandlerOptions } from 'modules/stations/finerySorterStation/hooks/useSaItems';

interface routeProps extends Record<string, string | undefined> {
  bagCode: string;
  linkStatus: 'linkItem' | 'unlinkItem' | 'itemOverview';
  qrCode: string;
  rfid: string;
}

interface Props {
  isOpen: boolean;
  onCloseHandler: (linkedItem?: boolean) => void;
  currentItem: IOrderItem;
  saDialogProps: Omit<SAHandlerOptions, 'isAllSaItemsLinked' | 'cleanUp'> & { bag: IBag };
}

const LinkSaItemsDialog: React.FC<Props> = ({ isOpen, onCloseHandler, saDialogProps, currentItem }) => {
  const params = useParams<routeProps>();
  const navigate = useNavigate();

  const onSuccessfulLink = (action: linkActions) => {
    // Redirect to type dialog - New Item
    if (!currentItem.type) return navigate(`../(item/type)`);
    // Redirect to report stain if recurrent item
    if (action === linkActions.redirectToStain) return navigate(`../(item/stainDamage)`);

    // Redirect the user to summary page if no stain and recurrent
    return onCloseHandler(action === linkActions.linkSuccessful);
  };

  switch (params.linkStatus) {
    case 'linkItem': {
      return <SaDialog isOpen={isOpen} onClose={onCloseHandler} type={SaDialogTypes.linkToItem} onItemLink={onSuccessfulLink} {...saDialogProps} />;
    }
    case 'unlinkItem': {
      return <SaDialog isOpen={isOpen} onClose={onCloseHandler} type={SaDialogTypes.unlinkItem} onItemLink={onSuccessfulLink} {...saDialogProps} />;
    }
    case 'itemOverview': {
      return <SaDialog isOpen={isOpen} onClose={onCloseHandler} type={SaDialogTypes.warning} onContinue={() => navigate('../')} {...saDialogProps} />;
    }
    default:
      return <></>;
  }
};

export default LinkSaItemsDialog;
