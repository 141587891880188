import React, { useState, useEffect, ChangeEvent, useRef, useMemo } from 'react';
import lodash from 'lodash';

import LabelSelector from '../../shoeSorterStation/components/labelSelector/LabelSelector';
import {
  SHOE_GENERAL_TYPE_MAPPER,
  FINERY_GENERAL_TYPE_MAPPER,
  LAUNDRY_EXCLUSIVE_TYPE_MAPPER,
  ITypeObj,
  ITypeCategories,
} from 'utils/maps/typeMapper';
import { ItemTypes } from 'utils/enums/typesEnum';

/** Imports Material UI */
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid, Paper, InputBase, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { MainKeyboard } from 'components/mainKeyboard';
import { MainKeyboardRefProps } from 'components/mainKeyboard/mainKeyboard';
import { ComponentProps, useGetSelectedItem } from 'modules/stations/components/SortingStepper/SortingStepper';

import { OrderServiceLines } from 'utils/interfaces';

type ConfigType = {
  labelPrefix: string;
  typesConfig: ITypeCategories;
  title: string;
};

const config: {
  [key in OrderServiceLines]: ConfigType;
} = {
  [OrderServiceLines.SHOES]: {
    labelPrefix: 'station.shoeSorter.itemType.categories.',
    typesConfig: SHOE_GENERAL_TYPE_MAPPER,
    title: 'Shoe Type',
  },

  [OrderServiceLines.FINERY]: {
    labelPrefix: 'station.sorter.itemType.categories.',
    typesConfig: FINERY_GENERAL_TYPE_MAPPER,
    title: 'Item type',
  },
  [OrderServiceLines.LAUNDRY]: {
    labelPrefix: 'station.sorter.itemType.categories.',
    typesConfig: LAUNDRY_EXCLUSIVE_TYPE_MAPPER,
    title: 'Item type',
  },
};

const useTypeBoxStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: '32px',
    paddingTop: '20px',
  },
  labelTypo: {
    fontWeight: 300,
    paddingBottom: '20px',
  },
  paper: {
    padding: '10px 4px',
    display: 'flex',
    alignItems: 'stretch',
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  searchContainer: {
    padding: '0 1.5rem',
  },
}));

/**
 * @description gets the item category based on the type
 * @param itemType
 * @returns {string} item category
 */
const getSelectedCategoryFromType = (itemType: string, typeMapper: ITypeCategories) => {
  let selectedCategory = '';
  Object.keys(typeMapper).map((category: string) => {
    return Object.keys(typeMapper[category]).map((type: any) => {
      if (itemType === typeMapper[category][type].value) {
        selectedCategory = category;
        return category;
      }
      return '';
    });
  });

  if (Object.keys(typeMapper).length === 1) selectedCategory = Object.keys(typeMapper)[0];
  return selectedCategory;
};

interface Props extends ComponentProps {
  serviceLine?: OrderServiceLines;
  customType?: ConfigType;
}

const arrayToObject = (arr: any[], keyField: string) => Object.assign({}, ...arr.map((item) => ({ [item[keyField]]: item })));

const SelectTypeComponent: React.FC<Props> = ({ onSelect, serviceLine = OrderServiceLines.SHOES, customType }) => {
  const { selectedItem: currentItem } = useGetSelectedItem();
  // Import styles
  const classes = useTypeBoxStyles();
  const [filterInput, setFilterInput] = useState('');

  const configType: ConfigType = useMemo(() => (customType ? customType : config[serviceLine]), [serviceLine, customType]);

  const typeMapper = useMemo(() => configType.typesConfig, [configType]);

  const allTypes = useMemo(() => {
    let allTypes: {
      [key: string]: ITypeObj;
    } = {};

    Object.keys(configType.typesConfig).forEach((type) => {
      allTypes = {
        ...allTypes,
        ...configType?.typesConfig[type],
      };
    });

    return allTypes;
  }, [configType]);

  const keyboardRef = useRef<MainKeyboardRefProps>(null);

  // Declare States
  const [currentListing, setCurrentListing] = useState<{ [key: string]: ITypeObj }>({});
  const [currentCategory, setCurrentCategory] = useState(
    getSelectedCategoryFromType(currentItem && currentItem.type ? currentItem.type : '', typeMapper)
  );

  useEffect(() => {
    setCurrentListing(typeMapper[currentCategory] || {});
  }, [currentCategory, typeMapper]);

  useEffect(() => {
    if (keyboardRef.current) keyboardRef.current.setInput(filterInput);
    if (filterInput) {
      // filter the array of items
      const result = Object.keys(allTypes)
        .filter((key) => allTypes[key].label.toLowerCase().includes(filterInput.toLowerCase()))
        .map((key) => allTypes[key]);
      //convert result to object
      const response = arrayToObject(result, 'iconName');
      setCurrentListing(response);
    } else {
      setCurrentListing(typeMapper[currentCategory] || {});
    }
  }, [filterInput, currentCategory, typeMapper, allTypes]);

  const handleFilterInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFilterInput(value);
  };

  /**
   * Set the selected catgory
   * @param label - Name of the Catogory selected
   */
  const onCategoryClick = (label: string) => {
    setCurrentCategory(label);
  };

  const handleKeyBoardInput = (value: string) => {
    setFilterInput(value);
  };
  return (
    <>
      <Box display="flex" flexDirection="column" style={{ width: '100%' }} className={classes.root}>
        <Grid container justifyContent="flex-end" classes={{ container: classes.searchContainer }}>
          <Grid item xs={2}>
            <form onSubmit={(event) => event.preventDefault()}>
              <Paper className={classes.paper}>
                <InputBase
                  onFocus={() => {
                    if (keyboardRef.current) keyboardRef.current.toggleKeyBoard(true);
                  }}
                  className={classes.input}
                  placeholder="Search Type..."
                  inputProps={{ 'aria-label': 'search type' }}
                  value={filterInput}
                  onChange={handleFilterInput}
                />
                <IconButton className={classes.iconButton} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Paper>
            </form>
          </Grid>
        </Grid>
        <Typography variant="h6" className={classes.labelTypo}>
          {configType.title}
        </Typography>
        <Box display="flex">
          {Object.keys(typeMapper).map((label, i) => {
            return (
              <LabelSelector
                key={`${i}-type-mapper`}
                onToggleClick={() => onCategoryClick(label)}
                isActive={currentCategory === label}
                width="25%"
              >{`${configType.labelPrefix}${label}`}</LabelSelector>
            );
          })}
        </Box>

        <Typography variant="h6" className={classes.labelTypo}>
          {lodash.startCase(currentCategory)}
        </Typography>
        <Grid container alignItems="flex-start">
          {Object.keys(currentListing).map((type: string, i) => {
            return (
              <LabelSelector
                key={i + '-garment-mapper'}
                iconName={type as ItemTypes}
                width="9rem"
                labelFontSize="1.25rem"
                onToggleClick={() => onSelect({ ...currentItem, type: currentListing[type].value })}
                isActive={currentItem && currentItem.type ? currentItem.type === currentListing[type].value : false}
              />
            );
          })}
        </Grid>
      </Box>
      <MainKeyboard onChange={handleKeyBoardInput} ref={keyboardRef} />
    </>
  );
};

export default SelectTypeComponent;
