import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { Root } from '../root';
import { ThemePrimary } from 'assets/theme';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={ThemePrimary}>
      <Router>
        <Root />
      </Router>
    </ThemeProvider>
  );
};

export default Sentry.withProfiler(App);
