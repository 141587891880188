import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { Grid, Theme, InputBase, Box, ButtonBase, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';

import { Brand, IBrandsResponse } from 'utils/interfaces';
import { MainKeyboard } from 'components/mainKeyboard';
import { MainKeyboardRefProps } from 'components/mainKeyboard/mainKeyboard';
import useGetBag from 'modules/stations/shoeSorterStation/queries/useGetBag';
import { ComponentProps, useGetSelectedItem } from 'modules/stations/components/SortingStepper/SortingStepper';

import NoLogoIcon from 'assets/images/pngs/noLogo.png';
import useGetBrands from './queries/useGetBrands';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2rem',
    },
    formContainer: {
      padding: '0rem 1rem',
    },
    paper: {
      padding: '10px 4px',
      display: 'flex',
      alignItems: 'stretch',
    },
    input: {
      marginLeft: 8,
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    brandsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    brandItemContainer: {
      display: 'flex',
      alignSelf: 'stretch',
      justifyContent: 'center',
    },
    brandButton: {
      padding: '1rem',
      border: '1px solid #b6bcd1',
      width: '100%',
      flexDirection: 'column',
    },
    keyBoard: {
      width: '100%',
      position: 'absolute',
      bottom: '0',
      left: '0',
      right: '0',
    },
    noLogoPadding: {
      paddingBottom: '0rem',
    },
    brandImageLogo: {
      maxHeight: '8rem',
      height: '2.5rem',
      width: 'auto',
      objectFit: 'contain',
    },
    btnContentContainer: {
      flex: '1',
    },
  })
);

const BrandItem: React.FC<{ brand: IBrandsResponse; onBrandSelect: (brand: IBrandsResponse) => void }> = ({ brand, onBrandSelect }) => {
  const classes = useStyles();
  const imgRef = useRef<HTMLImageElement>(null);
  return (
    <Grid container item className={classes.brandItemContainer} xs={2}>
      <ButtonBase focusRipple className={classes.brandButton} onClick={() => onBrandSelect(brand)}>
        {brand.logo && (brand.logo !== 'NO_LOGO' || brand.logo === undefined) ? (
          <Grid
            container
            item
            classes={{ item: classes.btnContentContainer }}
            alignItems="stretch"
            justifyContent="space-between"
            direction="column"
            xs={12}
          >
            <img className={`${classes.brandImageLogo} img-responsive`} src={`${process.env.REACT_APP_BRAND_API_HOST}/${brand.logo}`} alt="" />
            <Typography variant="body2">{brand.name}</Typography>
          </Grid>
        ) : (
          <Box>
            <img width="128px" src={NoLogoIcon} alt="" className={classes.brandImageLogo} onError={() => (imgRef.current!.src = NoLogoIcon)} />
            <Typography variant="body2">{brand.name}</Typography>
          </Box>
        )}
      </ButtonBase>
    </Grid>
  );
};

export const MISSING_BRAND_CONFIG: Brand & IBrandsResponse = {
  //Logo is the name of the file on S3, do not change it here
  logo: 'missing-brand.png',
  name: 'Missing Brand',
  active: false,
  sort_key: 10,
  luxury: false,
  Delicate: false,
  label: 'Missing Brand',
};

interface Props extends ComponentProps {
  onMissingBrandSelected: () => void;
}
const SelectBrandComponent: React.FC<Props> = ({ onSelect, onMissingBrandSelected }) => {
  // Import Style
  const classes = useStyles();
  const { selectedItem: currentItem } = useGetSelectedItem();
  const keyboardRef = useRef<MainKeyboardRefProps>(null);
  const { data: bag } = useGetBag();

  // Declare State
  const [input, setInput] = useState('');
  const [filteredBrands, setFilteredBrands] = useState<IBrandsResponse[]>([]);
  const [latestBrandSelected, setLatestBrandSelected] = useState<IBrandsResponse>();

  const customerId = process.env.NODE_ENV === 'development' ? '000dfd04-755c-4c1e-b378-62c0edb2dc81' : bag.customerId || '';
  const brands = useGetBrands(customerId, currentItem.type!, latestBrandSelected);

  // Refresh the filteredBrands Array
  useEffect(() => {
    if (input !== '') {
      // Filter the brands using the input
      const filtering = brands.allBrands.filter((brand) => brand.name.toLowerCase().includes(input.toLowerCase())).slice(0, 10);
      // Create the custom brand with the name to match the input
      const customBrand: IBrandsResponse = {
        logo: 'NO_LOGO',
        name: input.toLowerCase().trim().replace(' ', '_'),
        active: false,
        sort_key: 10,
        luxury: false,
        Delicate: false,
      };

      // Set the filteredBrands with the custom Brand
      setFilteredBrands([...filtering, customBrand]);
    }
  }, [input, brands.allBrands]);

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInput(value);
    if (keyboardRef.current) {
      keyboardRef.current.setInput(value);
    }
  };

  const handleBrandClick = (brand: IBrandsResponse) => {
    setLatestBrandSelected(brand);

    onSelect({
      ...currentItem,
      brand: {
        label: brand.name,
        logo: brand.logo || 'NO_LOGO',
        name: brand.name,
        //Disable Delicate Brand for Finery Sorter
        luxury: false,
        Delicate: false,
      },
    });
  };

  const handleMissingBrandClick = (brand: IBrandsResponse) => {
    setLatestBrandSelected(brand);
    onMissingBrandSelected();
  };

  const onChange = (input: string) => {
    setInput(input);
  };

  return (
    <Grid container className={classes.root} spacing={5}>
      <Box className={classes.keyBoard}>
        <MainKeyboard onChange={(input: string) => onChange(input)} ref={keyboardRef} />
      </Box>

      <Grid item xs={4} className={classes.formContainer}>
        <form onSubmit={(event) => event.preventDefault()}>
          <Paper className={classes.paper}>
            <InputBase
              onFocus={() => {
                if (keyboardRef.current) keyboardRef.current.toggleKeyBoard(true);
              }}
              className={classes.input}
              placeholder="Search Brands"
              inputProps={{ 'aria-label': 'search brands' }}
              value={input}
              onChange={onChangeInput}
              autoFocus
            />
            <IconButton className={classes.iconButton} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </form>
        <Box margin="1rem 0rem" />
      </Grid>
      <Grid item xs={8}>
        <Grid container className={classes.brandsContainer} spacing={1} direction="row" alignItems="center">
          {input
            ? filteredBrands?.map((brand) => <BrandItem onBrandSelect={handleBrandClick} key={brand.name} brand={brand} />)
            : brands?.topBrands?.map((brand) => <BrandItem onBrandSelect={handleBrandClick} key={brand.name} brand={brand} />)}

          <BrandItem onBrandSelect={handleMissingBrandClick} key={'default'} brand={{ ...MISSING_BRAND_CONFIG }} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SelectBrandComponent;
