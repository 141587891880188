import React from 'react';
import { Grid } from '@material-ui/core';

import SpecialAttentionImage from './specialAttentionImage';
import { SaItem } from 'utils/interfaces/stationInterfaces';
import styled from 'styled-components';

interface Props {
  viewOnly?: boolean;
  isTaggerStation?: boolean;
  onItemToggle: (item: SaItem) => void;
  specialAttentionItemsList: SaItem[];
}

const SpecialAttentionImageGallery: React.FC<Props> = ({ onItemToggle, isTaggerStation = false, viewOnly = false, specialAttentionItemsList }) => {
  return (
    <Wrapper
      container
      item
      spacing={isTaggerStation ? 3 : 1}
      xs={12}
      direction={isTaggerStation ? 'column' : 'row'}
      alignItems="center"
      justify="center"
    >
      {specialAttentionItemsList.map((saItem: SaItem, i: Number) => {
        return (
          <Grid item xs={isTaggerStation ? 8 : 6} key={`sa-item-${i}`}>
            {/* Render the Image with status and brand */}
            <SpecialAttentionImage item={saItem} onClickHandler={(item: SaItem) => onItemToggle(item)} withStatus={!viewOnly} isGalleryDisplay />
          </Grid>
        );
      })}
    </Wrapper>
  );
};
export default SpecialAttentionImageGallery;

const Wrapper = styled(Grid)`
  max-height: 14rem;
  overflow-y: auto;
  margin: 1rem;
`;
