import React, { useState, useMemo, useRef } from 'react';

import styled from 'styled-components';
import { GridRowData, GridColDef } from '@mui/x-data-grid';

import { CustomTable } from 'components/tables';
import StainedItemReport from '../../pages/stainedItemReport';
import { IOrderWithStainedItems } from 'utils/interfaces/stainManStationInterfaces';
import { PaginationMeta } from '../../hooks/useGetOrdersWithStainedItemsList';

import StainManTableFooter from './stainManTableFooter';
import { Navigate, Route, Routes } from 'react-router-dom';

type orderSeparatorColors = '#03d3bb' | '#3e48a6';
type orderCellColors = '#FFFFFF' | '#F5F5F8';
export type orderWithColor = { orderAlphaId: string; borderColor: orderSeparatorColors; cellBgColor: orderCellColors };

export interface TablePagination extends PaginationMeta {
  page: number;
  itemsPerPage: number;
}

interface TableProps {
  columns: GridColDef[];
  tableData: IOrderWithStainedItems[];
  onPageChange: (pageNumber: number) => void;
  paginationMeta: TablePagination;
  handlePrintOrderPaper: () => void;
}

const Table: React.FC<TableProps> = ({ columns, tableData, onPageChange, paginationMeta, handlePrintOrderPaper }) => {
  const [orderColoring, setOrderColoring] = useState<orderWithColor[]>([]);

  const data = useMemo(() => {
    let newData: GridRowData[] = [];
    const orderColors: orderWithColor[] = [];
    tableData.forEach((order, index) => {
      orderColors.push({
        orderAlphaId: order.orderAlphaId,
        borderColor: index % 2 !== 0 ? '#03d3bb' : '#3e48a6',
        cellBgColor: index % 2 !== 0 ? '#F5F5F8' : '#FFFFFF',
      });
      order.itemsList.forEach((item) => {
        newData = [
          ...newData,
          {
            id: `${item.code}-${order.orderAlphaId}`,
            cluster: order.cluster,
            reportDetailsList: item.reportDetailsList,
            orderId: order.orderId!,
            orderAlphaId: order.orderAlphaId,
            itemCode: item.code,
            itemType: item.type,
            itemTypeKey: item.type,
            lastEvent: item.lastEvent.activity,
            lastEventTimeStamp: item.lastEvent.createdAt,
            color: item.colorsList,
            createdAt: item.createdAt,
            brand: item.brand,
            csr: item.isLinked,
            vip: order.isVip,
            isPackaged: item.isPackaged,
            isUrgent: order.isUrgent,
            itemIssues: item.itemIssues,
            stainsActionStatus: item.stainsActionStatus,
            lastLocation: item.lastEvent.stationId ? item.lastEvent.stationId : '',
            activityStatus: item.lastEvent.activityStatus,
            program: item.program,
            dropoffDate: order.dropoffDate,
            dropoffTime: order.dropoffTime,
            frontImage: item.frontImage,
            activities: item.activities ?? [],
            isDamaged: item.itemIssues.some((itemIssue) => itemIssue.reason === 'DAMAGED'),
            isStained: item.itemIssues.some((itemIssue) => itemIssue.reason === 'STAINED'),
            isRejected: RegExp('REJECTED', 'i').test(item.approvalStatus || ''),
            side: item.side,
          },
        ];
      });
    });
    setOrderColoring(orderColors);
    return newData;
  }, [tableData]);

  const tableRef = useRef<HTMLDivElement>(null);

  return (
    <Routes>
      <Route path={`report/:itemCode/*`} element={<StainedItemReport />} />
      <Route
        path={'/'}
        element={
          <Wrapper>
            <CustomTable
              paginationMeta={paginationMeta}
              orderColors={orderColoring}
              FooterElement={StainManTableFooter}
              columns={columns}
              data={data}
              tableRef={tableRef}
              rowHeight={100}
              handleOnPrint={handlePrintOrderPaper}
              onPageChange={onPageChange}
            />
          </Wrapper>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default Table;

const Wrapper = styled.div`
  height: 90%;
`;
