import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Keyboard from 'react-simple-keyboard';
import classNames from 'classnames';

import styled from 'styled-components';
interface Props {
  onChange?: (input: string) => any;
  onChangeAll?: (inputObj: any) => any;
  inputName?: string;
  showKeyboard?: boolean;
  onCloseKeyboard?: () => void;
  withShift?: boolean;
  withNumpad?: boolean;
  customStyles?: { [style: string]: string | number };
  /** If we want to render multiple keyboards under the same Parent, use different Ids */
  keyboardId?: string;
}

export interface MainKeyboardRefProps {
  clearInput: () => any;
  setInput: (value: string) => any;
  toggleKeyBoard: (value: boolean) => any;
  keyboardOpen?: boolean;
}

const MainKeyboard = forwardRef<MainKeyboardRefProps, Props>(
  (
    {
      customStyles,
      onChange = () => {},
      inputName = '',
      onChangeAll = () => {},
      showKeyboard = false,
      onCloseKeyboard,
      withNumpad = true,
      withShift = false,
      keyboardId = '',
    },
    ref
  ) => {
    const [state, setState] = useState({ layoutName: 'default' });
    const [keyboardRef, setKeyboardRef] = useState<Keyboard>();
    const [keyboardOpen, setKeyboardOpen] = useState(showKeyboard);
    const onKeyPress = (button: string) => {
      if (button === '{shift}') {
        setState((prev) => {
          return { layoutName: prev.layoutName === 'default' ? 'shift' : 'default' };
        });
      }
    };
    const customFns = React.useMemo(() => (withShift ? { onKeyPress: (value: string) => onKeyPress(value) } : {}), [withShift]);
    // TODO: forward actual keyboard reference
    useImperativeHandle(ref, () => ({
      clearInput: () => {
        if (keyboardRef) keyboardRef.clearInput();
      },
      setInput: (value: string) => {
        if (keyboardRef) keyboardRef.setInput(value);
      },
      toggleKeyBoard: (value: boolean) => {
        setKeyboardOpen(value);
      },
      keyboardOpen: keyboardOpen,
    }));

    const closeKeyboard = () => {
      setKeyboardOpen(false);
      onCloseKeyboard && onCloseKeyboard();
    };

    const commonKeyboardOptions = {
      onChange: (input: string) => onChange(input),
      onChangeAll: (inputObj: any) => onChangeAll(inputObj),
      inputName: inputName,
      theme: 'simple-keyboard hg-theme-default hg-layout-default',
      // turned to false because of a bug when using input autocomplete (7aseb)
      physicalKeyboardHighlight: false,
      syncInstanceInputs: true,
      mergeDisplay: true,
      keyboardRef: (r: Keyboard) => setKeyboardRef(r),
      debug: false,
    };
    const keyboardOptions = {
      ...commonKeyboardOptions,
      /**
       * Layout by:
       * Sterling Butters (https://github.com/SterlingButters)
       */
      layout: {
        default: ["& / - ' . , ( ) {bksp}", 'Q W E R T Y U I O P', 'A S D F G H J K L', `Z X C V B N M${withShift ? ' {shift}' : ''}`, '{space}'],
        shift: ['1 2 3 4 5 6 7 8 9 0 {bksp}', 'q w e r t y u i o p', 'a s d f g h j k l', 'z x c v b n m {shift}', '{space}'],
      },
      display: {
        '{bksp}': 'Delete ⌫',
      },
    };

    const keyboardNumPadOptions = {
      ...commonKeyboardOptions,
      layout: {
        default: ['{numpad7} {numpad8} {numpad9}', '{numpad4} {numpad5} {numpad6}', '{numpad1} {numpad2} {numpad3}', '{numpad0} {numpaddecimal}'],
      },
    };

    const keyboardNumPadEndOptions = {
      ...commonKeyboardOptions,
      layout: {
        default: ['{numpadenter}'],
      },
    };

    return (
      <KeyboardContainer keyboardId={keyboardId} customStyles={customStyles} className={classNames('keyboardContainer', { hidden: !keyboardOpen })}>
        <button className="closeBtn" onClick={closeKeyboard}>
          Close Keyboard
        </button>
        <Keyboard {...customFns} baseClass={`simple-keyboard-main${keyboardId}`} layoutName={state.layoutName} {...keyboardOptions} />

        {withNumpad && (
          <div className="numPad">
            <Keyboard baseClass={`simple-keyboard-numpad${keyboardId}`} {...keyboardNumPadOptions} />
            <Keyboard baseClass={`simple-keyboard-numpadEnd${keyboardId}`} {...keyboardNumPadEndOptions} />
          </div>
        )}
      </KeyboardContainer>
    );
  }
);

export default MainKeyboard;
const KeyboardContainer = styled.div<{ keyboardId: string; customStyles?: { [style: string]: string | number } }>`
  ${({ customStyles }) => customStyles}
  display: flex;
  background: rgba(0, 0, 0, 0.15);
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  border-radius: 5px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  &.hidden {
    display: none;
  }

  button {
    background: #d84141;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    margin-top: 15px;
    position: absolute;
    top: -53px;
    right: 0;
  }
  input {
    width: 100%;
    height: 100px;
    padding: 20px;
    font-size: 20px;
    border: none;
    box-sizing: border-box;
  }

  .simple-keyboard.hg-theme-default {
    display: inline-block;
    width: 100%;
    padding: 5px !important;
    border-radius: none !important;
  }

  .simple-keyboard-main${({ keyboardId }) => keyboardId}.simple-keyboard {
    width: 75%;
    min-width: 640px;
    background: none;
  }

  .simple-keyboard-main${({ keyboardId }) => keyboardId}.simple-keyboard .hg-row:first-child {
    margin-bottom: 1rem;
  }

  .simple-keyboard-main${({ keyboardId }) => keyboardId}.simple-keyboard .hg-row:last-child {
    margin-bottom: 15px 15rem 0px;
    display: flex;
    justify-content: center;
  }

  .simple-keyboard-main${({ keyboardId }) => keyboardId}.simple-keyboard .hg-button.hg-functionBtn.hg-button-bksp {
    background-color: #f5edef;
    min-width: 18rem;
  }

  .simple-keyboard .hg-button.selectedButton {
    background: rgba(5, 25, 70, 0.53);
    color: white;
  }

  .simple-keyboard .hg-button.emptySpace {
    pointer-events: none;
    background: none;
    border: none;
    box-shadow: none;
  }

  .simple-keyboard .hg-button span {
    font-size: 1.8rem;
  }

  .simple-keyboard-main${({ keyboardId }) => keyboardId}.simple-keyboard .hg-button {
    width: 50px;
    height: 70px;
  }

  .numPad {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.1);
  }

  .simple-keyboard-numpad${({ keyboardId }) => keyboardId}.simple-keyboard {
    width: 100%;
    align-items: center;
    min-height: 20rem;
    background: transparent !important;
  }

  .simple-keyboard.hg-theme-default .hg-button {
    min-height: 4.2rem;
  }

  .simple-keyboard-numpad${({ keyboardId }) => keyboardId}.simple-keyboard .hg-button {
    width: 5rem;
    min-height: 5rem;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .simple-keyboard-main${({ keyboardId }) => keyboardId}.simple-keyboard .hg-row:last-child {
    margin: 1rem 15rem 0.5rem;
  }

  // OLD
  .simple-keyboard-numpadEnd${({ keyboardId }) => keyboardId}.simple-keyboard {
    width: 150px;
    height: 100%;
    margin: 0;
    padding: 5px 5px 5px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background: transparent !important;
  }

  .simple-keyboard-numpadEnd${({ keyboardId }) => keyboardId}.simple-keyboard .hg-button {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .simple-keyboard.hg-theme-default .hg-button.hg-button-numpadadd,
  .simple-keyboard.hg-theme-default .hg-button.hg-button-numpadenter {
    min-height: 20rem;
    background-color: #dcede6;
    min-width: 6rem;
  }

  .simple-keyboard.hg-theme-default .hg-button.hg-selectedButton {
    background: rgba(5, 25, 70, 0.53);
    color: white;
  }

  .hg-button.hg-functionBtn.hg-button-space {
    max-width: 100%;
  }
`;
