import React, { useRef, useState } from 'react';
import Scanner, { ImageIcon, QrCodeType, ScannerRefProps } from './scanner';
import WidgetBox from '../widgetBox/widgetBox';
import { ScanType } from 'utils/enums/stationEnums';
import { makeStyles, createStyles, Theme, Box } from '@material-ui/core';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import { useUiState } from 'stores';
import { observer } from 'mobx-react-lite';
interface Props {
  onScanComplete: (value: string, type: ScanType) => void;
  images?: ImageIcon;
  border?: boolean;
  padding?: string;
  title?: string;
  qrCodeType?: QrCodeType;
  validateInput?: boolean;
  isVisible?: boolean;
  autoFocus?: boolean;
  button?: JSX.Element;
  svgClass?: string;
  coloredSvgFlag?: boolean;
  classNames?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hidden: {
      width: 0,
      height: 0,
      padding: 0,
      margin: 0,
      border: '0 !important',
      flex: 0,
    },
    button: {
      padding: '1rem',
    },
  })
);

/**
 * @description If the module using this component uses AXIOS for API calls without RQ,
 *  you should use this component, else use the `ScannerRQWidget`
 *  which is optimized for RQ
 */
const ScannerWidget: React.FC<React.PropsWithChildren<Props>> = observer(
  ({
    onScanComplete,
    children,
    border = true,
    padding = '',
    images = {} as ImageIcon,
    title = '',
    qrCodeType,
    validateInput,
    isVisible = true,
    autoFocus = true,
    button,
    svgClass,
    coloredSvgFlag,
    classNames = '',
  }) => {
    const [active, setActive] = useState(false);
    const classes = useStyles();
    const scannerRef = useRef<ScannerRefProps>(null);

    const { isLoading } = useUiState();

    const handleScannerClick = () => {
      if (scannerRef.current) scannerRef.current.focusInput();
    };

    return (
      <WidgetBox padding={padding} border={border} classNames={`${isVisible ? '' : classes.hidden} ${classNames}`} onClick={handleScannerClick}>
        {active ? <CheckCircleOutline color="primary" /> : <ErrorOutline color="secondary" />}
        <Scanner
          isLoading={isLoading}
          title={title}
          imageIcon={images}
          onFormSubmit={onScanComplete}
          qrCodeType={qrCodeType}
          validateInput={validateInput}
          onBlur={() => setActive(false)}
          onFocus={() => setActive(true)}
          ref={scannerRef}
          isVisible={isVisible}
          autoFocus={autoFocus}
          svgClass={svgClass}
          coloredSvgFlag={coloredSvgFlag}
        />
        <Box display="flex" className={classes.button} justifyContent="center">
          {button}
          {children}
        </Box>
      </WidgetBox>
    );
  }
);

export default ScannerWidget;
