import React from 'react';
import { IActivity } from 'utils/interfaces';
import { Box, ButtonBase, Grid, Modal, Typography } from '@material-ui/core';
import { ItemJourney } from 'components/itemJourney';
import { ReactComponent as BlackCloseSVG } from 'assets/images/svgs/close-black.svg';
import { useItemJourney } from '../../../pendingItemsStation/context';
import styled from 'styled-components';
import { ReactComponent as SearchQR } from 'assets/images/svgs/search-qr.svg';
import { ReactComponent as StainInstructionIcon } from 'assets/images/svgs/stainAndDamage/instructions-stain.svg';
import { ReactComponent as DamageInstructionIcon } from 'assets/images/svgs/stainAndDamage/instructions-damage.svg';

interface IProps {
  open: boolean;
  activities: IActivity[];
  hasStain: boolean;
  hasDamage: boolean;
}

const ItemActivityJourneyOverview: React.FC<IProps> = ({ open, activities, hasStain, hasDamage }) => {
  const { toggle, itemId } = useItemJourney();
  return (
    <CustomModal open={open}>
      <FiltersModal>
        <InnerModal container xs={12} direction={'column'}>
          <Grid item container xs={12}>
            <JourneyHeader item container xs={11}>
              <Grid item>
                <Typography variant="h4">Item Journey</Typography>
              </Grid>
            </JourneyHeader>
            <Grid item xs={1}>
              <Box display="flex" justifyContent="flex-end">
                <CustomButton onClick={() => toggle(false)}>
                  <BlackCloseSVG width="3rem" height="3rem" />
                </CustomButton>
              </Box>
            </Grid>
          </Grid>
          <ItemJourneyWrapper xs={12} item>
            <ItemJourney activities={activities} />
          </ItemJourneyWrapper>
          <Grid container xs={12} item justifyContent="space-between">
            <InstructionGrid container item xs={6} spacing={2}>
              <Grid item>{hasStain && <StainInstructionIcon width="8.5rem" height="4rem" />}</Grid>
              <Grid item>{hasDamage && <DamageInstructionIcon width="9.5rem" height="4rem" />}</Grid>
            </InstructionGrid>
            <Grid item xs={2}>
              <Typography variant="h4">
                <SearchLink target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_LAUNDRY_WEB_URL}/item/item_qr_search?itemQr=${itemId}`}>
                  <SearchQR /> Search QR
                </SearchLink>
              </Typography>
            </Grid>
          </Grid>
        </InnerModal>
      </FiltersModal>
    </CustomModal>
  );
};

export default ItemActivityJourneyOverview;
const FiltersModal = styled(Box)`
  background-color: white;
  position: absolute;
  border-radius: 0.7rem;
  top: 50%;
  left: 50%;
  width: 80%;
  height: max-content;
  transform: translate(-50%, -50%);
`;
const InnerModal = styled(Grid)`
  padding: 2rem;
`;
const CustomButton = styled(ButtonBase)`
  top: 5px;
  right: 5px;
`;
const CustomModal = styled(Modal)`
  position: relative;
`;
const JourneyHeader = styled(Grid)`
  padding: 1rem 2rem;
`;
const ItemJourneyWrapper = styled(Grid)`
  overflow-x: auto;
  width: 100%;
  margin-bottom: 2rem;
`;
const SearchLink = styled.a`
  text-decoration: none;
  display: flex;
  color: #3e48a6;
`;
const InstructionGrid = styled(Grid)`
  padding-bottom: 0.5rem;
`;
