import React from 'react';
import { CustomDialog } from 'components/dialogs';
import alert from 'assets/images/pngs/alert.png';
import { Box, Grid, Theme, Button, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';

interface Props {
  open: boolean;
  title?: string;
  size?: 'sm' | 'md' | 'lg';
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imgHeight: {
      width: '100%',
    },
    textwidth: {
      maxWidth: '90%',
    },
    root: {
      padding: '1rem',
    },
  })
);

const NotificationDialog: React.FC<React.PropsWithChildren<Props>> = ({ onClose, open, children, title, size = 'sm' }) => {
  const CloseModal = () => {
    onClose();
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles();

  return (
    <>
      <CustomDialog open={open} onClose={CloseModal} size={size}>
        <Grid container alignItems="center" justify="center" direction="column" classes={{ container: classes.root }}>
          <Grid container item xs={12} alignItems="center" justify="center">
            <Grid item xs={2}>
              <Box padding="1rem">
                <img src={alert} className={classes.imgHeight} alt="warning" />
              </Box>
            </Grid>

            {title && (
              <Grid item xs={10}>
                <Typography variant="h3">{title}</Typography>
              </Grid>
            )}
          </Grid>
          {children}
          <Grid item xs={8}>
            <Button color="primary" variant="contained" size="large" onClick={CloseModal}>
              I understand
            </Button>
          </Grid>
        </Grid>
      </CustomDialog>
    </>
  );
};
export default NotificationDialog;
