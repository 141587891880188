import { createContext, useContext } from 'react';
//TODO: Remove when migrating react-scripts and mobx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { observable, action, computed } from 'mobx';

class UiState {
  @observable private _isLoading = false;
  @observable showBackBtn = false;
  @observable closeBtnRoute = '';
  @observable showCustomerOrderId = false;
  @observable showPreferenceModal = true;
  @observable isBackgroundLoading = false;

  @action.bound
  setIsLoading(value: boolean) {
    this._isLoading = value;
  }

  @action.bound
  setShowBackBtn(value: boolean) {
    this.showBackBtn = value;
  }

  @action.bound
  setCloseBtnRoute(value: string) {
    this.closeBtnRoute = value;
  }

  @action.bound
  setShowCustomerOrderId(value: boolean) {
    this.showCustomerOrderId = value;
  }

  @action.bound
  setShowPreferenceModal(value: boolean) {
    this.showPreferenceModal = value;
  }

  @action.bound
  setIsBackgroundLoading(value: boolean) {
    this.isBackgroundLoading = value;
  }

  @computed
  get isLoading() {
    return this._isLoading;
  }
}

export const uiState = new UiState();
const UiStateContext = createContext(uiState);

export const useUiState = () => useContext(UiStateContext);
