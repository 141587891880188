import React from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Grid, Box, Typography, Theme } from '@material-ui/core';

import { ReactComponent as CloseDialogIcon } from 'assets/images/svgs/dialog-close.svg';
import AlertIcon from 'assets/images/pngs/alert.png';

const useHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerTitle: {
      padding: '1rem',
      borderBottom: '1px solid #b6bcd1',
      marginBottom: '1rem',
    },
    titleIcon: {
      width: '2.5rem',
      paddingRight: '1rem',
    },
    closeIcon: {
      [theme.breakpoints.down('lg')]: {
        width: '20px',
      },
      [theme.breakpoints.up('xl')]: {
        width: '25px',
      },
    },
  })
);

interface SaDialogHeaderProps {
  onClose?: () => void;
}

const SaDialogHeader: React.FC<SaDialogHeaderProps> = ({ onClose }) => {
  const headerClasses = useHeaderStyles();

  return (
    <>
      {/* Dialog Header */}
      <Grid container item direction="row" justify="center" alignItems="center" classes={{ item: headerClasses.headerTitle }}>
        {/* Title and Icon */}
        <Grid container item xs={11} justify="center" alignItems="center">
          <Box>
            <img src={AlertIcon} alt="warning" className={headerClasses.titleIcon} />
          </Box>
          <Typography variant="h4" display="initial" align="center" style={{ color: '#ca3232' }}>
            Customer Special Request
          </Typography>
        </Grid>
        {/* On Close Icon */}
        {onClose && (
          <Grid container item xs={1} justify="center" alignItems="center" onClick={() => onClose()} data-cy="saDialog-closeIcon">
            <CloseDialogIcon className={headerClasses.closeIcon} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default SaDialogHeader;
