import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Button, Box, Theme, Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { IObservableValue } from 'mobx';
import styled from 'styled-components';

import ItemsList from './itemsList';
import { Toaster } from 'components/toaster';
import WidgetBox from 'components/widgetBox/widgetBox';
import { ConfirmDialog } from 'components/confirmDialog';
import { WashmenIcon } from 'components/washmenIcon';
import { formatDropOffDate, dropOffDateColor } from 'utils/helpers/date';
import { DropOffDateSpan } from 'modules/stations/components/modules.styled';
import { useMarkBagAsSorted } from 'modules/stations/shoeSorterStation/mutations';
import { SaDialog } from 'modules/stations/components/saDialog';
import useSaItems from '../hooks/useSaItems';
import { SaDialogTypes } from 'utils/enums/stationEnums';
import useGetBag from 'modules/stations/shoeSorterStation/queries/useGetBag';
import { IOrderItem, SaItem } from 'utils/interfaces';
import { SpecialAttentionImageGallery } from 'components/specialAttentionImageGallery';
import { OriginApps } from 'utils/maps/originApps';

import alert from 'assets/images/pngs/alert.png';
import vipBig from 'assets/images/svgs/vipBig.svg';
import { ReactComponent as CareemIcon } from 'assets/images/svgs/careem/careem-logo-no-bg.svg';
import { ReactComponent as FirstOrderIcon } from 'assets/images/svgs/first-order-logo.svg';
interface Props {
  handleBagClosed: () => void;
  focusInput$: IObservableValue<boolean>;
  currentItem: IOrderItem;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    widgetTitle: {
      padding: '.7rem 1rem',
      borderBottom: '2px solid #b6bcd1',
      flexDirection: 'column',
      display: 'flex',
    },
    orderInfoBox: {
      padding: '0.5rem',
      marginBottom: '10px',
    },
    itemsListBox: {},
    boxBorder: {
      border: '2px solid #b6bcd1',
      borderRadius: '5px',
    },
    isVip: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    vipLogo: {
      height: 'auto',
      width: '4vw',
    },
    dialogImageVip: {
      height: '5rem',
    },
    dialogImageFinish: {
      height: '30vh',
    },
    overwrite: {
      border: '0px !important',
    },
  })
);

const ScannedItems: React.FC<Props> = ({ handleBagClosed, focusInput$, currentItem }) => {
  const classes = useStyles();
  const { mutateAsync: markBagAsSorted } = useMarkBagAsSorted();

  const { items, data: bag, refetchBag } = useGetBag();

  const [toggleFinish, setToggleFinish] = useState(false);
  const [toggleVip, setToggleVip] = useState(true);
  const [callCustomerOpen, setCallCustomerOpen] = useState(false);
  const [isSpecialAttentionDialogOpen, setIsSpecialAttentionDialogOpen] = useState(false);
  const [activeSaItem, setActiveSaItem] = useState({} as SaItem);

  const {
    hasSpecialAttentionItems,
    linkItemToSpecialAttentionItem,
    linkSpecialAttentionItemToBag,
    linkedItem,
    specialAttentionItemsList,
    specialItemsInBag,
    unlinkItemToSpecialAttentionItem,
    unlinkSpecialAttentionItemFromBag,
  } = useSaItems(currentItem, { bag, refetchBag });
  const isFinishBagEnabled = items.findIndex((item) => !item.isSorted) === -1;

  const dropoffDate = useMemo(() => formatDropOffDate(bag.order.dropoffDate || ''), [bag]);
  const isDropOffRed = useMemo(() => dropOffDateColor(bag.order.dropoffDate || ''), [bag]);

  useEffect(() => {
    // Prompt the Special Attention Dialog if item found in bag
    const isAllItemsAssigned = specialAttentionItemsList.every((item: SaItem) => {
      return item.bagCode!! || item.itemCode!! || item.notInBag;
    });

    if ((hasSpecialAttentionItems && !isAllItemsAssigned) || bag.order.customerNotes) {
      setIsSpecialAttentionDialogOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onConfirm = async () => {
    //To block further requests from user
    if (toggleFinish) {
      setToggleFinish(false);

      // Handle an error on the mark bag as sorted API
      await markBagAsSorted(
        { bagCode: bag.bagCode },
        {
          onSuccess: () => {
            handleBagClosed();
          },
          onError: () => {
            toast(<Toaster message="Something went wrong!" type="error" />);
          },
        }
      );
    }
  };

  const onContinueVip = () => {
    setToggleVip(false);
    focusInput$.set(true);
  };

  const toggleFinishModal = () => {
    // Make sure that all the items in this bag has been linked before finishing the bag
    if (bag.order.preferences?.isCallCustomerBeforeProcessing && !toggleFinish) {
      setCallCustomerOpen(true);
    } else {
      setToggleFinish(!toggleFinish);
    }
  };
  const toggleVipModal = () => {
    setToggleVip(!toggleVip);
  };

  const onConfirmCallCustomer = () => {
    setCallCustomerOpen(false);
    setToggleFinish(!toggleFinish);
  };

  const onToggleItemHandler = (item: SaItem) => {
    setActiveSaItem(item);
    setIsSpecialAttentionDialogOpen(true);
  };
  const onSaModalClose = () => {
    const isAllItemsAssigned = specialAttentionItemsList.every((item: SaItem) => {
      return item.bagCode!! || item.itemCode!! || item.notInBag;
    });

    // If some items are not marked, return a toaster
    if (!isAllItemsAssigned) return toast(<Toaster message="Please check all the items" type="error" />);

    // Close the dialog
    setIsSpecialAttentionDialogOpen(false);
    // Clean active Item
    setActiveSaItem({} as SaItem);
  };

  return (
    <>
      <WidgetBox padding="0" classNames={classes.overwrite}>
        <Box display="flex" flexDirection="column" flex="1" justifyContent="space-between">
          {/* Order Info Box */}
          <Box display="flex" flexDirection="column" minHeight="0" className={`${classes.orderInfoBox} ${classes.boxBorder}`}>
            <Grid container item justify="center" alignItems="center" spacing={0}>
              {/* Order Alpha and Customer Alpha ID */}
              <Grid container item direction="row" spacing={1} xs={12}>
                {/* Order Detail */}
                <Grid container item xs={10} justify="center" direction="column">
                  <InfoBox paddingBottom=".5rem" display={'flex'} alignItems={'center'}>
                    Order ID. {bag.orderAlphaId} {bag.order.originApp === OriginApps.CAREEM && <CareemIcon />}
                  </InfoBox>
                  <InfoBox paddingBottom=".5rem" display={'flex'} alignItems={'center'}>
                    Customer ID. {bag.customerAlphaId} {bag.order.isFirstOrder && <FirstOrderIcon />}
                  </InfoBox>
                  <Box paddingBottom=".5rem">
                    Drop Off: <DropOffDateSpan isDropOffRed={isDropOffRed}>{dropoffDate}</DropOffDateSpan>
                  </Box>
                </Grid>

                {/* VIP Badge */}
                {bag.isCustomerVip && (
                  <Grid item xs={2} className={classes.isVip}>
                    <WashmenIcon name="vipIcon" iconClassName={classes.vipLogo} svg isButton={false} />
                  </Grid>
                )}
              </Grid>

              {/* Special Attention Item's images */}
              {hasSpecialAttentionItems && (
                <SpecialAttentionImageGallery onItemToggle={onToggleItemHandler} specialAttentionItemsList={specialAttentionItemsList} />
              )}
            </Grid>
          </Box>
          {/* Item List */}
          <Box display="flex" flex="1" flexDirection="column" minHeight="0" className={classes.boxBorder}>
            <ItemsList currentItem={currentItem} />
            <Box
              display="flex"
              padding="1rem"
              alignItems="flex-end"
              justifyContent="center"
              style={{ display: items.length === 0 ? 'none' : 'flex' }}
            >
              <Button color="secondary" variant="contained" size="large" onClick={toggleFinishModal} disabled={!isFinishBagEnabled}>
                Finish Bag
              </Button>
            </Box>
          </Box>
        </Box>
      </WidgetBox>

      {/* Special Attention Dialog */}
      <SaDialog
        isOpen={isSpecialAttentionDialogOpen}
        type={SaDialogTypes.linkToBag}
        openWithItem={activeSaItem}
        onClose={onSaModalClose}
        hasSpecialAttentionItems={hasSpecialAttentionItems}
        bag={bag}
        linkedItem={linkedItem}
        specialItemsInBag={specialItemsInBag}
        specialAttentionItemsList={specialAttentionItemsList}
        linkSpecialAttentionItemToBag={linkSpecialAttentionItemToBag}
        unlinkSpecialAttentionItemFromBag={unlinkSpecialAttentionItemFromBag}
        unlinkItemToSpecialAttentionItem={unlinkItemToSpecialAttentionItem}
        linkItemToSpecialAttentionItem={linkItemToSpecialAttentionItem}
      />

      {/* VIP Customer Modal */}
      {bag.isCustomerVip && (
        <ConfirmDialog
          title={bag.customerAlphaId}
          imageClass={classes.dialogImageVip}
          subTitle="Please take extra care"
          image={vipBig}
          open={toggleVip}
          onClose={toggleVipModal}
          modalSize="sm"
        >
          <Box padding="2rem">
            <Button variant="contained" color="primary" size="large" onClick={onContinueVip}>
              Continue
            </Button>
          </Box>
        </ConfirmDialog>
      )}
      <ConfirmDialog image={alert} open={callCustomerOpen} onClose={onConfirmCallCustomer} title="Do not process clothes without Customer Approval">
        <Box padding="1rem">
          <Button variant="contained" color="primary" size="large" onClick={onConfirmCallCustomer}>
            Confirm
          </Button>
        </Box>
      </ConfirmDialog>

      {/* Finish Bag Modal */}
      <ConfirmDialog
        onClose={toggleFinishModal}
        image={alert}
        imageClass={classes.dialogImageFinish}
        open={toggleFinish}
        title="Are you sure you want to finish bag?"
      >
        <Box padding="1rem">
          <Button onClick={toggleFinishModal} color="primary" variant="outlined" size="large">
            Cancel
          </Button>
        </Box>
        <Box padding="1rem">
          <Button variant="contained" color="primary" size="large" onClick={onConfirm}>
            Confirm
          </Button>
        </Box>
      </ConfirmDialog>
    </>
  );
};

export default ScannedItems;

const InfoBox = styled(Box)`
  gap: 0.5rem;
`;
