import { useMutation } from 'react-query';
import StationService from 'services/station.service';

interface CreateOptions {
    rfid: string;
    itemCode: string;
    orderId: string;
}

const useLinkRfidToItem = () => {
    return useMutation<void, Error, CreateOptions>(
        ({ rfid, itemCode, orderId }) => StationService.linkRfidToItem(rfid, itemCode, orderId),
    );
};

export default useLinkRfidToItem;
