import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { IStats, useActivityState } from 'stores/activityStore';
import { useStationStore } from 'stores';
import ActivityCountComponent from './activityCount';

import WhiteBagIconS1 from 'assets/images/pngs/whiteIcons/whitebag-50.png';
import WhiteBagIconS2 from 'assets/images/pngs/whiteIcons/whitebag-56.png';
import WhiteBagIconS3 from 'assets/images/pngs/whiteIcons/whitebag-68.png';
import WhiteShirtIconS1 from 'assets/images/pngs/whiteIcons/whitetshirt-50.png';
import WhiteShirtIconS2 from 'assets/images/pngs/whiteIcons/whitetshirt-56.png';
import WhiteShirtIconS3 from 'assets/images/pngs/whiteIcons/whitetshirt-68.png';
import ShoeIconS1 from 'assets/images/pngs/whiteIcons/shoe-50.png';
import ShoeIconS2 from 'assets/images/pngs/whiteIcons/shoe-56.png';
import ShoeIconS3 from 'assets/images/pngs/whiteIcons/shoe-68.png';
import { ActivityTypes } from 'utils/enums/typesEnum';

export type HeaderRenderIcon = {
  src: string;
  count: number;
  width: string;
  height?: string;
};

type ActivityMap = {
  [key in ActivityTypes]: { title: string; icons: HeaderRenderIcon[] };
};

const getIconMap = (activityStats: IStats) => {
  const activityMap: ActivityMap = {
    [ActivityTypes.SHIRT]: {
      title: 'Shirts:',
      icons: [
        { src: WhiteShirtIconS1, count: activityStats.twoHourAgo, width: '40px', height: '40px' },
        { src: WhiteShirtIconS2, count: activityStats.oneHourAgo, width: '45px', height: '45px' },
        { src: WhiteShirtIconS3, count: activityStats.thisHour, width: '50px', height: '50px' },
      ],
    },
    [ActivityTypes.SHOE]: {
      title: 'Shoes:',
      icons: [
        { src: ShoeIconS1, count: activityStats.twoHourAgo, width: '40px' },
        { src: ShoeIconS2, count: activityStats.oneHourAgo, width: '45px' },
        { src: ShoeIconS3, count: activityStats.thisHour, width: '50px' },
      ],
    },
    [ActivityTypes.BAG]: {
      title: 'Bags:',
      icons: [
        { src: WhiteBagIconS1, count: activityStats.twoHourAgo, width: '40px' },
        { src: WhiteBagIconS2, count: activityStats.oneHourAgo, width: '45px' },
        { src: WhiteBagIconS3, count: activityStats.thisHour, width: '50px' },
      ],
    },
  };

  return activityMap;
};

const UserActivityCount: React.FC = observer(() => {
  const { activityStats } = useActivityState();
  const { activityType } = useStationStore();

  const activityMapObject = useMemo(() => getIconMap(activityStats)[activityType || ActivityTypes.BAG], [activityStats, activityType]);

  return (
    <>
      {activityType && (
        <>
          <ActivityCountComponent renderIcons={activityMapObject.icons} activityType={activityType}>
            <WrapperDiv>
              <TitleDiv>Sorted {activityMapObject.title}</TitleDiv>
              <ListStyle>
                <ListItem>{activityStats.thisHour} - This Hour</ListItem>
                <ListItem>{activityStats.oneHourAgo} - One Hour</ListItem>
                <ListItem>{activityStats.twoHourAgo} - Two Hour</ListItem>
              </ListStyle>
            </WrapperDiv>
          </ActivityCountComponent>
        </>
      )}
    </>
  );
});
export default UserActivityCount;

const WrapperDiv = styled.div`
  display: flex;
  min-width: 150px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TitleDiv = styled.div`
  font-size: 1.2rem;
  margin: 0.5rem 0;
`;

const ListItem = styled.li`
  padding: 0.25rem;
`;

const ListStyle = styled.ul`
  padding-inline-start: 0;
`;
