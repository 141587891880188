import { IBag, Preferences, IOrder, OrderServiceLines } from '../interfaces/stationInterfaces';
import { BagTypes } from '../enums/stationEnums';
import { Order } from 'utils/interfaces';

export default class Bag implements IBag {
  addedItemCount: number = 0;
  bagCode: string = '';
  isScanned: boolean = false;
  isSorted: boolean = false;
  isTagged: boolean = false;
  orderAlphaId: string = '';
  orderId: string = '';
  reference: string = '';
  sortedItemCount: number = 0;
  type: BagTypes = BagTypes.CLEAN_PRESS;
  customerAlphaId: string = '';
  isCustomerVip?: boolean = false;
  customerId?: string;
  preferences?: Preferences;
  customerInstruction?: string;
  driverInstruction?: string;
  //@ts-ignore //FIXME ignoring this TS error here as we need to remove this class completely later
  order: IOrder = {
    city: {
      city: '',
    },
    pickupDriver: {
      name: '',
    },
    isUrgent: false,
    orderAlphaId: '',
    geofence: {
      clusterName: '',
      label: '',
    },
    sortedCPBags: 0,
    sortedPBags: 0,
    sortedHCBags: 0,
    totalPBags: 0,
    totalCPBags: 0,
    totalHCBags: 0,
    totalSCBags: 0,
    sortedSCBags: 0,
    totalTFBags: 0,
    sortedTFBags: 0,
    dropoffDate: '',
    serviceLine: OrderServiceLines.LAUNDRY,
    ordersGroup: { ordersMap: {} },
  };

  constructor(customerId?: string, orderId?: string, bagType?: BagTypes, order?: Partial<Order>) {
    this.bagCode = customerId && orderId ? `${customerId.toLowerCase()}${orderId.toLowerCase()}${Date.now()}` : '';
    this.orderAlphaId = orderId || '';
    this.customerAlphaId = customerId || '';
    this.type = bagType || BagTypes.CLEAN_PRESS;

    this.order = {
      ...this.order,
      serviceLine: order?.serviceLine || OrderServiceLines.LAUNDRY,
      ordersGroup: order?.ordersGroup?.ordersMap ? order?.ordersGroup : { ordersMap: {} },
      customerAttachmentsList: order?.customerAttachmentsList || [],
      dropoffDate: order?.dropoffDate || '',
      customerNotes: order?.customerNotes || '',
      preferences: order?.preferences || undefined,
    };
  }
}
