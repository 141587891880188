import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

interface Props {
  title?: string;
  padding?: string;
  border?: boolean;
  margin?: string;
  display?: string;
  classNames?: string;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => any;
  flex?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textTransform: 'capitalize',
      textAlign: 'center',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(8),
    },
  })
);

const WidgetBox: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  title,
  padding = '',
  border = true,
  margin = '',
  display = '',
  classNames = '',
  flex = '1',
  onClick = () => {},
}) => {
  const classes = useStyles();
  return (
    <Box
      data-testid={'widgetBox'}
      className={`widget ${border ? 'border' : ''} ${classNames}`}
      display={display ? display : 'flex'}
      flexDirection="column"
      flex={flex}
      padding={padding}
      margin={margin}
      onClick={(event) => onClick(event)}
    >
      {title && (
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
};

export default WidgetBox;
