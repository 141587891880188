import { useMutation } from 'react-query';
import stationService from 'services/station.service';

interface Options {
  itemCode: string;
  image: string;
  isReport: boolean;
}
const useUploadImageToS3 = () => {
  return useMutation<string, Error, Options>(({ itemCode, image, isReport }) => stationService.uploadImageToS3(itemCode, image, isReport));
};

export default useUploadImageToS3;
