import { useQueryClient, useMutation } from 'react-query';
import { PendingService } from 'services';
import { DelayOptions } from 'services/pending.service';
import { IOrderWithItems, IPendingItem } from 'utils/interfaces';
import { IOptionsToFilterBy, QueryResponse } from './hooks/useGetOrdersWithItemsList';

export function useItemDelayQuery(updateOnSuccess: boolean) {
  const queryClient = useQueryClient();
  const orderItemsListQuery = queryClient.getQueryCache().find<QueryResponse>(['ordersWithItemsList'], {
    exact: false,
    active: true,
  });
  //If query Filters contain OrderAlphaId, set certain mutation options
  const queryKey = orderItemsListQuery?.options.queryKey as [string, IOptionsToFilterBy];
  return useMutation<{ order: IOrderWithItems; item: IPendingItem; type: DelayOptions['type'] }, Error, DelayOptions>(
    (options) =>
      PendingService.markItemAsDelayAnswered({
        ...options,
        isItemsLastActivityNotPackagedFilter: updateOnSuccess && queryKey ? !queryKey[1].orderIdsListFilter?.length && !options.packageId : true,
      }),
    {
      onSuccess: (data, variables, context) => {
        const {
          item: { delayDetails },
        } = data;

        const { orderId, itemsCodesList } = variables;

        if (updateOnSuccess) {
          queryClient.setQueryData<Partial<QueryResponse>>(queryKey ?? '', (old) => {
            if (!old) return {};

            const updatedData =
              old.ordersItemsList?.map((order) => {
                if (order.orderId === orderId) {
                  return {
                    ...order,
                    itemsList: order.itemsList.map((item) => {
                      if (itemsCodesList.includes(item.code))
                        return {
                          ...item,
                          delayDetails: delayDetails,
                        };
                      return item;
                    }),
                  };
                }
                return order;
              }) || [];

            return {
              baseFilters: old.baseFilters,
              ordersItemsList: [...updatedData],
              paginationMeta: old.paginationMeta,
            };
          });
        }
      },
    }
  );
}
