import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Button, ButtonBase, Grid, makeStyles, StepConnector, StepContent, withStyles } from '@material-ui/core';
import classNames from 'classnames';

import CustomStepperIcon from './customStepperIcons';
import { FineryImages } from 'utils/enums/stationEnums';
import { ShoeAngleImages } from 'utils/interfaces';
import './style.scss';

import { ReactComponent as UploadIcon } from 'assets/images/svgs/stepperIcons/svg/upload-icon.svg';

/** Connector Styles */
const ConnectorStyle = withStyles({
  alternativeLabel: {
    top: 22,
  },
  line: {
    marginLeft: '2.3rem',
    height: '7rem',
  },
})(StepConnector);

const useStepperStyle = makeStyles((theme) => ({
  label: {
    fontWeight: 'lighter',
    fontSize: '2rem',
  },
  activeLabel: {
    fontSize: '2.5rem',
    fontWeight: 'normal',
  },
}));

export type PhotosStepperSchema = {
  label: string;
  type: FineryImages;
};

/** Define props interface */
interface Props {
  disabledSteps?: FineryImages[];
  completedSteps?: string[];
  schema: PhotosStepperSchema[];
  activeStep: FineryImages;
  onStepClick?: (name: FineryImages) => void;
  stepContent?: boolean;
  showAllStepContent?: boolean;
  stepContentImgs?: ShoeAngleImages;
  onstepContentUploadClick?: (name: FineryImages) => void;
}

/** Define Default Props */
const defaultProps = {
  initialStep: 0,
  onStepClick: (name: FineryImages) => console.log('No click Handler on Stepper Widget'),
  onstepContentUploadClick: (name: string) => console.log('No click Handler on step content upload click'),
};

const useStepIconStyles = makeStyles({
  root: {
    zIndex: 1,
    height: '4rem',
    width: '6rem',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const findActiveStep = (activeStep: FineryImages, schema: PhotosStepperSchema[]) => {
  return schema.findIndex((value) => value.type === activeStep);
};

const CustomStepper = ({
  onStepClick = defaultProps.onStepClick,
  disabledSteps = [],
  completedSteps = [],
  schema,
  activeStep,
  stepContent = false,
  showAllStepContent = false,
  stepContentImgs,
  onstepContentUploadClick = defaultProps.onstepContentUploadClick,
}: React.PropsWithChildren<Props>) => {
  const stepIconClasses = useStepIconStyles();
  const stepperStyleClasses = useStepperStyle();

  const handleStepClick = (name: FineryImages) => {
    onStepClick(name);
  };

  return (
    <Wrapper sx={{ maxWidth: 400, maxHeight: '100%' }}>
      <Stepper activeStep={findActiveStep(activeStep, schema)} orientation="vertical" connector={!stepContent ? <ConnectorStyle /> : undefined}>
        {schema.map((schema, index) => (
          //  declare schema type somehere fix type issue
          <Step key={schema.type} active={showAllStepContent || schema.type === activeStep} completed={completedSteps.includes(schema.type)}>
            <StepLabel
              style={{ gap: '1rem' }}
              StepIconComponent={(props) => {
                return (
                  //  declare schema type somehere fix type issue
                  <div className={stepIconClasses.root}>
                    <CustomStepperIcon
                      name={schema.type}
                      active={stepContent ? false : props.active}
                      disabled={disabledSteps.includes(schema.type)}
                      completed={props.completed}
                      error={props.error}
                      isDelicate={props.isDelicate}
                      onToggleClick={(name) => handleStepClick(name)}
                    />
                  </div>
                );
              }}
            >
              <div className={classNames(stepperStyleClasses.label, { [stepperStyleClasses.activeLabel]: activeStep === schema.type })}>
                <FormattedMessage id={`station.sorter.stepper.label.${schema.type}`} defaultMessage={schema.label} />
              </div>
            </StepLabel>
            {stepContent && (
              <StepContent style={{ marginLeft: '-10rem', marginTop: '2rem', borderLeft: '' }}>
                <Box sx={{ mb: 2 }}>
                  {stepContentImgs && stepContentImgs![schema.type] && (
                    <>
                      <img
                        className="img-responsive"
                        src={stepContentImgs && stepContentImgs[schema.type]}
                        alt=""
                        style={{ width: '25rem', height: '16rem' }}
                      />
                      <StyledButtonBase onClick={() => onstepContentUploadClick(schema.type)} focusRipple>
                        <IconPositioned />
                      </StyledButtonBase>
                    </>
                  )}
                  {(!stepContentImgs || !stepContentImgs![schema.type]) && (
                    <Grid item container justifyContent="center" style={{ gap: '1rem' }}>
                      <StyledButton size="medium" variant="outlined" color="primary" onClick={() => onstepContentUploadClick(schema.type)}>
                        Upload Photo
                      </StyledButton>
                      <StyledButton size="medium" variant="contained" color="primary" onClick={() => onstepContentUploadClick(schema.type)}>
                        Take Photo
                      </StyledButton>
                    </Grid>
                  )}
                </Box>
              </StepContent>
            )}
          </Step>
        ))}
      </Stepper>
    </Wrapper>
  );
};

export default CustomStepper;

const Wrapper = styled(Box)`
  overflow-y: auto;

  .MuiStepper-root.MuiStepper-vertical {
    margin: 2rem;
  }
`;

const StyledButtonBase = styled(ButtonBase)`
  display: 'flex';
  border-radius: '50%';
`;

const IconPositioned = styled(UploadIcon)`
  position: absolute;
  bottom: 11.5rem;
  right: 0.5rem;
`;

const StyledButton = styled(Button)`
  height: 4rem;
  width: 9.5rem;
`;
