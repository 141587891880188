import React from 'react';
import { DataGrid, GridColDef, GridRowData, GridPagination, GridSortModel } from '@mui/x-data-grid';
import styled from 'styled-components';
import { orderWithColor, TablePagination } from 'modules/stations/pendingItemsStation/components/table/table';

interface TableProps {
  data?: GridRowData[];
  columns?: GridColDef[];
  FooterElement?: React.FC<React.PropsWithChildren<{ totalNumberOfItems: number; handleOnPrint?: () => void }>>;
  orderColors?: orderWithColor[];
  onPageChange: (pageNumber: number) => void;
  paginationMeta: TablePagination;
  rowHeight?: number;
  tableRef?: React.RefObject<HTMLDivElement>;
  handleOnPrint?: () => void;
  CustomNoRowsOverlay?: React.FC;
}

const CustomTable: React.FC<TableProps> = ({
  data = [],
  columns = [],
  FooterElement,
  orderColors,
  onPageChange,
  paginationMeta,
  rowHeight = 65,
  tableRef,
  handleOnPrint,
  CustomNoRowsOverlay,
}) => {
  const [disableOrderColoring, setDisableOrderColoring] = React.useState(false);
  const newColumns = React.useMemo(
    () =>
      columns.map((column: GridColDef) => {
        column.headerClassName = column.headerClassName + ' headerClass';
        return column;
      }),
    [columns]
  );

  const CustomGridFooter: React.FC = () => {
    if (!FooterElement) return <></>;

    return (
      <FooterElement handleOnPrint={handleOnPrint} totalNumberOfItems={paginationMeta.fullItemsCount}>
        <GridPagination />
      </FooterElement>
    );
  };

  const handlePageChange = (num: number) => {
    onPageChange(num + 1);
  };
  return (
    <DataGridCustom
      rows={data}
      ref={tableRef}
      rowHeight={rowHeight}
      columns={newColumns}
      order_colors={disableOrderColoring ? [] : orderColors}
      pagination
      pageSize={paginationMeta.itemsPerPage}
      rowsPerPageOptions={[paginationMeta.itemsPerPage]}
      page={paginationMeta.page - 1}
      rowCount={paginationMeta.fullItemsCount}
      paginationMode="server"
      disableColumnMenu
      disableColumnSelector
      onPageChange={handlePageChange}
      onSortModelChange={(model: GridSortModel) => setDisableOrderColoring(!!model.length)}
      hideFooterSelectedRowCount
      components={{
        Footer: CustomGridFooter,
        NoRowsOverlay: CustomNoRowsOverlay,
      }}
    />
  );
};

export default CustomTable;

//TODO : remove !important and use classes when MUI fixes the issue with inline styling on this component's cells
const DataGridCustom = styled(DataGrid)<{
  order_colors?: orderWithColor[];
}>`
  .headerClass {
    font-size: 1rem;
    letter-spacing: 1px;
    font-weight: 400;
    background-color: #f5f7fc;
  }
  .MuiDataGrid-columnsContainer {
    background-color: #f5f7fc;
    border: 1px solid #b6bcd1;
  }

  .cellClass {
    font-size: 1rem;
    font-weight: 200;
    color: #111135;
  }
  .packaged-cell {
    color: #00b78a;
  }

  .warning-cell {
    color: #db0404;
  }

  .multi-line-cell {
    line-height: 1.4rem !important;
  }
  .word-break {
    text-wrap: pretty;
    word-wrap: break-word;
  }

  .wrap-line-cell {
    white-space: normal;
  }

  .MuiDataGrid-row {
    background-color: #ffffff;
  }

  ${({ order_colors }) =>
    order_colors?.map(
      (order) => `
.MuiDataGrid-row[data-id$='${order.orderAlphaId}'] {
    border-left: 0.35rem solid ${order.borderColor};
    background-color: ${order.cellBgColor};
  }`
    )}

  .package-not-ready {
    color: #f5a623;
  }

  .package-not-found {
    color: #f5a623;
  }

  .package-not-collected {
    color: #e51c23;
  }

  .package-collected {
    color: #19a784;
  }
`;
