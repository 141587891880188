import { AcceptedIconTypes } from "components/washmenIcon/washmenIcon"
import useVerifyStation from "modules/stations/sorterStation/hooks/useVerifyStation";
import { useMemo } from "react"
import { BagTypes, StationTypes } from "utils/enums/stationEnums"
import { OrderServiceLines } from "utils/interfaces"


interface BagType {

    iconName: AcceptedIconTypes;
    bagType: BagTypes

}


const IconNames: { [key in BagTypes]: AcceptedIconTypes } = {
    CP: "greenBagCircle",
    HC: "pinkBagCircle",
    P: "whiteBagCircle",
    SC: "orangeBagCircle",
    TF: "purpleBagCircle",
    WF: "blueBagCircle"
}

interface Options {
    stationServiceLine?: OrderServiceLines;
    isManualPaymentFineryOrder?: boolean;
    type?: 'edit' | 'create'
}
const useBagTypes = ({ isManualPaymentFineryOrder, stationServiceLine, type = 'create' }: Options): BagType[] => {

    const isWfTagger = useVerifyStation(StationTypes.WfTagger)
    return useMemo<BagType[]>(() =>
        isWfTagger ? [{
            bagType: BagTypes.CLEAN_PRESS,
            iconName: IconNames[BagTypes.CLEAN_PRESS]
        }] : determineBagTypes({ stationServiceLine, isManualPaymentFineryOrder, type }).map((bagType) => ({
            bagType,
            iconName: IconNames[bagType]
        }))
        , [stationServiceLine, isManualPaymentFineryOrder, type, isWfTagger])
}

const determineBagTypes = ({ stationServiceLine, isManualPaymentFineryOrder, type }: Options) => {
    const bagTypes = type === 'edit' ? Object.values(BagTypes) : Object.values(BagTypes).filter((bagType) => bagType !== BagTypes.WASH_FOLD)

    switch (stationServiceLine) {
        case OrderServiceLines.FINERY:
            return isManualPaymentFineryOrder ? [BagTypes.FINERY] : bagTypes

        case OrderServiceLines.LAUNDRY:
        case OrderServiceLines.SHOES:
        default:
            return bagTypes

    }
}


export default useBagTypes