import { GridColDef } from '@mui/x-data-grid';
import { FilterOptions, FilterValues } from 'components/filters';
import { OrderServiceLines } from 'utils/interfaces';
import { laundryColumns } from '../config/laundryConfig/laundryTableColumns';
import { laundryCommonFilters, laundryDefaultCommonValues } from '../config/laundryConfig/laundryCommonFilters';
import { laundryDefaultModalValues, laundryModalFilters } from '../config/laundryConfig/laundryModalFilters';
import { shoeColumns } from '../config/shoeConfig/shoeTableColumns';
import { shoeCommonFilters, shoeDefaultCommonValues } from '../config/shoeConfig/shoeCommonFilters';
import { shoeDefaultModalValues, shoeModalFilters } from '../config/shoeConfig/shoeModalFilters';
import { shoeServiceLineFilterValues } from '../config/shoeConfig/shoeServiceLineFilterValues';
import { laundryServiceLineFilterValues } from '../config/laundryConfig/laundryServiceLineFilterValues';
import { fineryServiceLineFilterValues } from '../config/fineryConfig/fineryServiceLineFilterValues';
import { fineryCommonFilters, fineryDefaultCommonValues } from '../config/fineryConfig/fineryCommonFilters';
import { fineryColumns } from '../config/fineryConfig/fineryTableColumns';
import { fineryDefaultModalValues, fineryModalFilters } from '../config/fineryConfig/fineryModalFilters';

interface IServiceLineConfig {
  columns: GridColDef[];
  modalFilters: FilterOptions[];
  commonFilters: FilterOptions[];
  defaultModalValues: FilterValues;
  defaultCommonValues: FilterValues;
  serviceLineFilterValues: FilterValues;
}

const config: { [k in OrderServiceLines]: IServiceLineConfig } = {
  [OrderServiceLines.LAUNDRY]: {
    columns: laundryColumns,
    commonFilters: laundryCommonFilters,
    defaultCommonValues: laundryDefaultCommonValues,
    modalFilters: laundryModalFilters,
    defaultModalValues: laundryDefaultModalValues,
    serviceLineFilterValues: laundryServiceLineFilterValues,
  },
  [OrderServiceLines.SHOES]: {
    columns: shoeColumns,
    commonFilters: shoeCommonFilters,
    defaultCommonValues: shoeDefaultCommonValues,
    modalFilters: shoeModalFilters,
    defaultModalValues: shoeDefaultModalValues,
    serviceLineFilterValues: shoeServiceLineFilterValues,
  },
  [OrderServiceLines.FINERY]: {
    columns: fineryColumns,
    commonFilters: fineryCommonFilters,
    defaultCommonValues: fineryDefaultCommonValues,
    modalFilters: fineryModalFilters,
    defaultModalValues: fineryDefaultModalValues,
    serviceLineFilterValues: fineryServiceLineFilterValues,
  },
};

export default function useStainManConfig(serviceLine: OrderServiceLines = OrderServiceLines.LAUNDRY) {
  return config[serviceLine];
}
