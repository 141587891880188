import React from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import UserItemNotes from '../../../components/userItemNotes/UserItemNotes';
import { IRequestCardProps } from './rightCard';
import FineryInstructions from '../fineryInstructions';

const FineryRightCard: React.FC<IRequestCardProps> = ({
  itemReportDetail,
}) => {
  return (
    <Wrapper>
      <NotesWrapper>
        <UserItemNotes
          title="Please Add Photo of the Item"
          onClose={() => {
          }}
          itemCode={itemReportDetail.itemCode}
          orderId={itemReportDetail.orderId}
          orderAlphaId={itemReportDetail.orderAlphaId}
          isDialog={false}
          imageSize="4rem"
        />
      </NotesWrapper>
      <SpecialInstructionsWrapper>
        <FineryInstructions notes={itemReportDetail.customerNotes} images={itemReportDetail.customerAttachmentsList}
                            itemReportDetail={itemReportDetail} />
      </SpecialInstructionsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  overflow-y: auto;
`;

const NotesWrapper = styled(Box)`
  padding: 1rem;
  margin-bottom: 1rem;
`;
const SpecialInstructionsWrapper = styled(Box)`
  border-top: 1px solid #7d7c97;
  padding: 1rem;
`;
export default FineryRightCard;
