import React, { useMemo, useEffect, useState, useRef } from 'react';
import { useForm, Controller, useWatch, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Badge, Button, Grid, Divider, Chip, Box } from '@material-ui/core';
import styled from 'styled-components';
import FilterListIcon from '@material-ui/icons/FilterList';
import lodash from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment';
import useUrlState from '@ahooksjs/use-url-state';

import { Toaster } from 'components/toaster';
import ModalFilters from './modalFilters';
import { AutoCompleteFilter, FilterBox, FilterDefaultValue, FilterOptions, SelectFilter, isDate } from 'components/filters';
import { MainKeyboard } from 'components/mainKeyboard';
import { MainKeyboardRefProps } from 'components/mainKeyboard/mainKeyboard';
import useHandleFocusOut from 'hooks/useHandleFocusOut';

import { ReactComponent as DelayDaysIcon } from 'assets/images/svgs/delay-days.svg';

interface Inputs {
  [x: string]: string;
}

export interface InputObj extends Partial<Inputs> {}

export interface InputState<InputNames> {
  input: InputObj;
  keyboardOpen: boolean;
  inputName: InputNames;
}

export type InputNameTypes = string;

interface TableFiltersProps {
  /** Order of array reflects order of UI elements */
  modalFilters: FilterOptions[];
  /** Order of array reflects order of UI elements */
  commonFilters?: FilterOptions[];
  handleSubmitFilters: (filters: { [key in string]?: FilterDefaultValue }) => void;
  defaultModalValues: { [key in string]?: FilterDefaultValue };
  defaultCommonValues?: { [key in string]?: FilterDefaultValue };
  wrapperDivId: string;
  resetOnUnmount?: boolean;
  usesUrlState?: boolean;
  childrenComponents?: { topLeft?: React.ReactNode };
  formSetValueHandler?: (
    setValue: UseFormSetValue<{
      [x: string]: FilterDefaultValue | undefined;
    }>
  ) => void;
  formWatchHandler?: (
    watch: () => UseFormWatch<{
      [x: string]: FilterDefaultValue | undefined;
    }>
  ) => void;
}

type WatchValues = {
  [x: string]:
    | string
    | string[]
    | number[]
    | {
        start?: Date | undefined;
        end?: Date | undefined;
      }
    | {
        label?: string | undefined;
        value?: string | undefined;
      }[]
    | undefined;
};

const renderAutoCompleteActiveFilters = (input: unknown, filter: AutoCompleteFilter) => {
  if (filter.isMultipleSelection && Array.isArray(input)) {
    const selectedFiters = input as { label: string; value: string }[];
    return !filter.creatable
      ? selectedFiters.map(({ label }) => <ChipDisplay key={label} label={label} />)
      : selectedFiters.map(({ value }) => <ChipDisplay key={value} label={value} />);
  } else {
    const autoCompleteInput = filter.filterOptions.find(({ value }) => value === (input as { label: string; value: string }).value);
    return <TextDispay>{lodash.get(autoCompleteInput, 'displayIcon', autoCompleteInput?.label)}</TextDispay>;
  }
};

const renderSelectActiveFilters = (input: unknown, filter: SelectFilter) => {
  if (filter.isMultipleSelection && Array.isArray(input)) {
    return filter.filterOptions
      .filter(({ value }) => (input as string[]).some((inputValue) => value === inputValue))
      .map(({ label }) => <ChipDisplay key={label} label={label} />);
  } else {
    const selectInput = filter.filterOptions.find(({ value }) => value === (input as string));
    return <TextDispay>{lodash.get(selectInput, 'displayIcon', selectInput?.label)}</TextDispay>;
  }
};

const displayFilters = (modalFiltersInputs: WatchValues, modalFilters: FilterOptions[]) =>
  Object.entries(modalFiltersInputs).map(([key, input]) => {
    const filter = modalFilters.find((f) => f.filterKey === key);
    const title = lodash.startCase(key.replaceAll(/Filter|List/gi, ''));
    let elementsToDisplay: { element: JSX.Element; shouldDisplay: boolean } = { element: <></>, shouldDisplay: false };
    if (!lodash.isEmpty(input)) {
      switch (filter?.type) {
        case 'autoComplete':
          elementsToDisplay.shouldDisplay = true;
          elementsToDisplay.element = (
            <Grid item key={key}>
              <TextDispay>{title}</TextDispay>
              {renderAutoCompleteActiveFilters(input, filter)}
            </Grid>
          );
          break;
        case 'select':
          const selectInput = filter.filterOptions.find(({ value }) => value === (input as string));

          elementsToDisplay.shouldDisplay =
            filter.isMultipleSelection && Array.isArray(input) ? true : !selectInput?.hasOwnProperty('displayIcon') || !!selectInput?.displayIcon;

          elementsToDisplay.element = (
            <Grid item key={key}>
              {!filter.hideDisplayTitle && <TextDispay>{title}</TextDispay>}
              {renderSelectActiveFilters(input, filter)}
            </Grid>
          );
          break;

        case 'date':
          if (isDate(input)) {
            const start = moment(input.start).format('dd DD MMM');
            const end = moment(input.end).format('dd DD MMM');
            const today = moment().format('dd DD MMM');
            if (!(start === end && start === today)) {
              elementsToDisplay.shouldDisplay = true;
              elementsToDisplay.element = (
                <Grid key={key} item style={{ display: 'flex' }} alignItems="center">
                  {filter.title} <DelayDaysIcon style={{ alignSelf: 'start' }} width="2.4rem" height="2.4rem" />
                  {start === end ? start : `${start} / ${end}`}
                </Grid>
              );
            }
          }
          break;
        case 'radio':
          const radioInput = filter.filterOptions.find(({ value }) => value === (input as string));
          const toDisplay = lodash.get(radioInput, 'displayIcon', radioInput?.label);
          elementsToDisplay.shouldDisplay = !!toDisplay;

          elementsToDisplay.element = (
            <Grid key={key} item>
              {toDisplay}
            </Grid>
          );
          break;

        default:
          break;
      }
    }
    return elementsToDisplay;
  });

const getSelectedFiltersCount = (modalFiltersInputs: WatchValues, modalFilters: FilterOptions[]) => {
  let count = 0;
  Object.entries(modalFiltersInputs).forEach(([key, input]) => {
    const filter = modalFilters.find((f) => f.filterKey === key);

    switch (filter?.type) {
      case 'autoComplete':
      case 'select':
        filter.isMultipleSelection ? (count += Array.isArray(input) ? input?.length : 0) : count++;
        break;
      case 'date':
        if (isDate(input)) {
          const start = moment(input.start).format('dd DD MMM');
          const end = moment(input.end).format('dd DD MMM');
          const today = moment().format('dd DD MMM');
          !(start === end && start === today) && count++;
        }
        break;
      case 'radio':
        !!input && count++;
        break;

      default:
        break;
    }
  });
  return count;
};

const isFiltersChanged = (defaultValues: WatchValues, inputs: WatchValues) => {
  return lodash.isEqual(defaultValues, inputs);
};

export const parseFilters = (
  filters: string
): {
  [x: string]: FilterDefaultValue | undefined;
} => {
  const parsedFilters: {
    [x: string]: FilterDefaultValue | undefined;
  } = JSON.parse(filters);
  return {
    ...parsedFilters,
    dateFilter: {
      start: new Date(isDate(parsedFilters.dateFilter) ? parsedFilters.dateFilter?.start || '' : ''),
      end: new Date(isDate(parsedFilters.dateFilter) ? parsedFilters.dateFilter?.end || '' : ''),
    },
    dropoffDateFilter: {
      start: new Date(isDate(parsedFilters.dropoffDateFilter) ? parsedFilters.dropoffDateFilter?.start || '' : ''),
      end: new Date(isDate(parsedFilters.dropoffDateFilter) ? parsedFilters.dropoffDateFilter?.end || '' : ''),
    },
    pickupDateFilter: {
      start: new Date(isDate(parsedFilters.pickupDateFilter) ? parsedFilters.pickupDateFilter?.start || '' : ''),
      end: new Date(isDate(parsedFilters.pickupDateFilter) ? parsedFilters.pickupDateFilter?.end || '' : ''),
    },
  };
};

export const stringifyFilters = (filters: { [x: string]: FilterDefaultValue | undefined }): { filters: string } => {
  return { filters: JSON.stringify(filters) };
};

const HeaderFilterBar: React.FC<React.PropsWithChildren<TableFiltersProps>> = ({
  modalFilters = [],
  commonFilters = [],
  children,
  childrenComponents,
  handleSubmitFilters,
  defaultCommonValues,
  defaultModalValues,
  wrapperDivId,
  resetOnUnmount = true,
  usesUrlState = false,
  formSetValueHandler,
  formWatchHandler,
}) => {
  // TODO: Refactor into context, to avoid passing up the chain
  const { watch, control, setValue, reset, getValues } = useForm({
    defaultValues: { ...defaultCommonValues, ...defaultModalValues },
    mode: 'onChange',
  });
  const inputs = useWatch({ control: control });

  const [filtersURLState] = useUrlState<{ filters: string }>();

  const [open, setOpen] = useState(false);
  const [prevInstanceOfFilters, setPrevInstanceOfFilters] = useState<{
    [x: string]: FilterDefaultValue | undefined;
  }>({});

  //On initial load, set the filters from URL state if we're using url state
  useEffect(() => {
    if (usesUrlState && filtersURLState?.filters) {
      const parsed = parseFilters(filtersURLState.filters);
      Object.keys(parsed).forEach((key) => {
        setValue(key, parsed[key]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Propagate the `setValue` to the parent component
  useEffect(() => {
    if (formSetValueHandler) {
      formSetValueHandler(() => setValue);
    }
  }, [setValue, formSetValueHandler]);

  // Propagate the `watch` to the parent component
  useEffect(() => {
    if (formWatchHandler) {
      formWatchHandler(() => watch);
    }
  }, [watch, formWatchHandler]);

  const modalFiltersInputs = useMemo<WatchValues>(
    () => modalFilters.reduce<WatchValues>((acc, filter) => ({ ...acc, [filter.filterKey]: inputs[filter.filterKey] }), {}),
    [inputs, modalFilters]
  );

  const selectedModalFiltersCount = useMemo(() => getSelectedFiltersCount(modalFiltersInputs, modalFilters), [modalFiltersInputs, modalFilters]);

  const displayModalFilters = useMemo(
    () => (selectedModalFiltersCount ? displayFilters(modalFiltersInputs, modalFilters) : undefined),
    [selectedModalFiltersCount, modalFiltersInputs, modalFilters]
  );

  const isClearDisabled = useMemo(
    () => isFiltersChanged({ ...defaultCommonValues, ...defaultModalValues }, inputs),
    [inputs, defaultCommonValues, defaultModalValues]
  );

  const filtersWithKeyboard = useMemo<InputObj>(
    () =>
      [...commonFilters, ...modalFilters].reduce<InputObj>((acc, filter) => {
        if (filter.type === 'autoComplete') {
          acc[filter.filterKey] = '';
        }
        return acc;
      }, {}),
    [modalFilters, commonFilters]
  );

  const [inputsState, setInputsState] = useState<InputState<InputNameTypes>>({
    input: {
      default: '',
      ...filtersWithKeyboard,
    },
    inputName: '',
    keyboardOpen: false,
  });
  const keyboardRef = useRef<MainKeyboardRefProps>(null);
  const keyboardWrapperRef = useRef<HTMLDivElement>(null);

  const keyboardFiltersRefs = useRef<Map<string, HTMLDivElement | null>>(new Map());

  //Reset on unmount
  React.useEffect(
    () => () => {
      if (resetOnUnmount) reset({ ...defaultModalValues, ...defaultCommonValues });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const handleOpen = () => {
    setOpen(true);
    //capture current Filters
    setPrevInstanceOfFilters(watch());
  };
  const handleClose = () => setOpen(false);
  const handleSubmitCallback = (data?: { [x: string]: FilterDefaultValue | undefined }) => {
    handleSubmitFilters(data ? data : watch());
    handleClose();
    onClearInput();
  };

  //TODO : Only supports setting a value of type string, needs refactoring to function properly for any value
  const handleAutoCompleteAndSelectDependents = (filter: FilterOptions, val?: FilterDefaultValue) => {
    if (filter.type === 'autoComplete' || filter.type === 'select') {
      if (filter.dependents && filter.dependents.length) {
        filter.dependents.forEach((deps) => {
          const { key, setValue: setNewValue, triggerValue } = deps;
          if (typeof setNewValue === 'string') {
            //On Multiple selection, we want to set the dependants value whenever any select occurs
            if (filter.isMultipleSelection || (typeof triggerValue === 'string' && val === triggerValue)) {
              setValue(key, setNewValue);
            }
          } else {
            setValue(key, setNewValue);
          }
        });
      }
    }
  };

  //TODO : Only supports setting a value of type string, needs refactoring to function properly for any value
  const handleRadioDependents = (filter: FilterOptions, val?: FilterDefaultValue) => {
    if (filter.type === 'radio') {
      if (filter.dependents && filter.dependents.length) {
        filter.dependents.forEach((deps) => {
          const { key, setValue: setNewValue, triggerValue } = deps;
          if (typeof setNewValue === 'string') {
            //On Multiple selection, we want to set the dependants value whenever any select occurs
            if (typeof triggerValue === 'string' && val === triggerValue) {
              setValue(key, setNewValue);
            }
          }
        });
      }
    }
  };

  //TODO : Only supports setting a value of type string[], needs refactoring to function properly for any value
  const handleDateDependents = (filter: FilterOptions, val: { start: Date; end: Date }) => {
    if (filter.type === 'date') {
      if (filter.dependents && filter.dependents.length) {
        filter.dependents.forEach((deps) => {
          const { key, type, setValue: setNewValue, triggerValue } = deps;
          const currentValues = getValues(key) as string[];
          if (triggerValue && triggerValue(val) && type === 'select' && Array.isArray(currentValues) && !currentValues.includes(setNewValue)) {
            setValue(key, [...currentValues, setNewValue]);
          }
        });
      }
    }
  };

  /**
   * @description
   * Close keyboard if clicked on outside of element
   */
  const handleClickOutside = (event: Event) => {
    event.stopPropagation();
    if (keyboardRef.current?.keyboardOpen) {
      let clickedOnFilter = false;
      const refs = keyboardFiltersRefs.current.values();
      let current = refs.next();
      while (!current.done) {
        if (current.value?.contains(event.target as Node)) {
          clickedOnFilter = true;
          break;
        }
        current = refs.next();
      }

      const clickedOnKeyboard = keyboardWrapperRef.current?.contains(event.target as Node);
      if (!clickedOnKeyboard && !clickedOnFilter) {
        onToggleKeyboard(!inputsState.keyboardOpen, '');
      }
    }
  };
  useHandleFocusOut(handleClickOutside, wrapperDivId, inputsState.keyboardOpen);
  useHandleFocusOut(handleClickOutside, 'modalFilters', inputsState.keyboardOpen);

  useEffect(() => {
    // reset Input Value
    if (inputsState.inputName && keyboardRef.current) {
      const value = inputsState.input[inputsState.inputName];
      if (value) {
        keyboardRef.current.setInput(value);
      } else {
        keyboardRef.current.clearInput();
      }
    }
  }, [inputsState.input, inputsState.inputName]);

  /**
   * @description
   * Sets the specified input as the active one and opens the keyboard
   * @param inputName
   */
  const setActiveInput = (inputName: InputNameTypes) => {
    setInputsState((prev) => ({ ...prev, inputName: inputName, keyboardOpen: true }));
  };

  /**
   * @description
   * Updates keyboard input-name/target to the changed input name
   * @param value
   */
  const handleInputChange = (value: string) => {
    const updatedInputObj = {
      ...inputsState.input,
      [inputsState.inputName]: value,
    };
    setInputsState((prev) => ({ ...prev, input: updatedInputObj }));
  };

  const onChangeAll = (inputObj: InputObj) => {
    setInputsState((prev) => ({ ...prev, input: { ...prev.input, ...inputObj } }));
  };

  /**
   * Clears input and keyboard input
   */
  const onClearInput = () => {
    keyboardRef.current?.clearInput();
    handleInputChange('');
  };

  const onToggleKeyboard = (value: boolean, filterKey: string) => {
    value && setActiveInput(filterKey);
    setInputsState((prev) => ({ ...prev, input: { ...prev.input, [prev.inputName]: '' }, keyboardOpen: value }));
    keyboardRef.current?.toggleKeyBoard(value);
  };

  const renderFilters = (filters: FilterOptions[], isCommon?: boolean) => {
    const commonControlProps = (filter: FilterOptions) => {
      return { control: control, key: `${filter.filterKey}-control`, name: filter.filterKey };
    };
    return filters.map((filter) => {
      switch (filter.type) {
        case 'autoComplete':
          const extraProps = filter.creatable && { modifyInputValue: (value: string) => value.toUpperCase() };
          const isOpen = inputsState.keyboardOpen && inputsState.inputName === filter.filterKey;
          return (
            <AutoCompleteGrid
              ref={(el: HTMLDivElement) => keyboardFiltersRefs.current?.set(filter.filterKey, el)}
              key={`${filter.filterKey}-grid`}
              open={isOpen}
              item
              xs={filter.xsValue || true}
            >
              <Controller
                {...commonControlProps(filter)}
                render={({ field }) => (
                  <FilterBox
                    onChangeHandler={(value) => {
                      if (!filter.creatable || !value.length || filter.rulePattern?.test(value[value.length - 1].value)) {
                        field.onChange(value);
                      } else {
                        return toast(<Toaster message="Invalid Order ID" type="bad" />);
                      }
                      field.onChange(value);
                      handleAutoCompleteAndSelectDependents(filter);
                      onClearInput();
                      isCommon && handleSubmitCallback();
                    }}
                    key={filter.title}
                    {...filter}
                    {...extraProps}
                    type="autoComplete"
                    field={{ ...field }}
                    inputValue={inputsState.input[filter.filterKey] ?? ''}
                    toggleKeyboard={(toggleValue) => onToggleKeyboard(toggleValue, filter.filterKey)}
                    toggleOptionsForKeyboard={isOpen}
                    handleInputChange={handleInputChange}
                    renderOptionAndLabel={(value) => (filter.filterKey === 'itemTypesListFilter' ? lodash.startCase(value) : value)}
                  />
                )}
              />
            </AutoCompleteGrid>
          );
        case 'radio':
          return (
            <FilterGrid key={`${filter.filterKey}-grid`} item xs={filter.xsValue || true}>
              <Controller
                {...commonControlProps(filter)}
                render={({ field }) => (
                  <FilterBox
                    {...filter}
                    type="radio"
                    onChangeHandler={(val) => {
                      handleRadioDependents(filter, val);
                      isCommon && handleSubmitCallback();
                    }}
                    key={filter.title}
                    field={field}
                  />
                )}
              />
            </FilterGrid>
          );
        case 'date':
          return (
            <FilterGrid key={`${filter.filterKey}-grid`} position="relative" item xs={filter.xsValue || true}>
              <Controller
                {...commonControlProps(filter)}
                render={({ field }) => (
                  <>
                    {(!isCommon || filter.showTitle) && <DateHeader>{filter.title}</DateHeader>}
                    <FilterBox
                      onDateSelect={(date) => {
                        handleDateDependents(filter, date);
                        isCommon && handleSubmitCallback();
                      }}
                      key={filter.title}
                      {...filter}
                      field={field}
                    />
                  </>
                )}
              />
            </FilterGrid>
          );
        case 'select':
          return (
            <FilterGrid key={`${filter.filterKey}-grid`} item xs={filter.xsValue || true}>
              <Controller
                {...commonControlProps(filter)}
                render={({ field }) => (
                  <FilterBox
                    {...filter}
                    type="select"
                    field={field}
                    onChangeHandler={(val) => {
                      handleAutoCompleteAndSelectDependents(filter, val);
                      isCommon && handleSubmitCallback();
                    }}
                    key={filter.title}
                  />
                )}
              />
            </FilterGrid>
          );
        default:
          return <></>;
      }
    });
  };

  return (
    <>
      <GridWrapper alignItems="center" item xs={12} wrap="wrap" container justifyContent="space-between">
        {childrenComponents?.topLeft}
        <Grid wrap="nowrap" container item xs={childrenComponents?.topLeft ? 7 : 9}>
          <Grid item container alignItems="center" justifyContent="space-evenly" wrap="nowrap" style={{ gap: '5px' }}>
            {renderFilters(commonFilters, true)}
          </Grid>
        </Grid>
        {modalFilters.length > 0 && (
          <SideOptions item xs={1} container justifyContent="center">
            <CustomizedBadge
              color="primary"
              filtered={Number(selectedModalFiltersCount !== 0)}
              badgeContent={selectedModalFiltersCount}
              showZero={false}
            >
              <FiltersButton size="large" startIcon={<FilterListIcon fontSize="large" color="primary" />} variant="outlined" onClick={handleOpen}>
                Filters
              </FiltersButton>
            </CustomizedBadge>
          </SideOptions>
        )}
        <SideOptions item xs={1}>
          <ClearButton
            variant="outlined"
            size="large"
            disabled={isClearDisabled}
            onClick={() => {
              reset({ ...defaultModalValues, ...defaultCommonValues });
              handleSubmitCallback();
            }}
          >
            Clear filters
          </ClearButton>
        </SideOptions>
        <SideOptions item xs={1}>
          {children}
        </SideOptions>
        {displayModalFilters && (
          <DisplayFiltersWrapper item xs={12} alignItems="center" spacing={2} container>
            {displayModalFilters.map((filter, index) =>
              filter.shouldDisplay ? (
                <DisplayFiltersItem item container key={index}>
                  {filter.element}
                  {filter.shouldDisplay && <CustomDivider orientation="vertical" flexItem />}
                </DisplayFiltersItem>
              ) : undefined
            )}
          </DisplayFiltersWrapper>
        )}
      </GridWrapper>
      <ModalFilters
        filters={renderFilters(modalFilters)}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmitCallback();
        }}
        open={open}
        handleClearFilters={() => reset({ ...watch(), ...defaultModalValues })}
        handleClose={() => {
          reset({ ...prevInstanceOfFilters });
          handleClose();
        }}
      />

      <div ref={keyboardWrapperRef}>
        <MainKeyboard
          onCloseKeyboard={() =>
            setInputsState((prev) => ({
              ...prev,
              input: { ...prev.input, [prev.inputName]: '' },
              keyboardOpen: false,
            }))
          }
          ref={keyboardRef}
          showKeyboard={keyboardRef.current?.keyboardOpen}
          customStyles={{ 'z-index': '2000' }}
          inputName={inputsState.inputName}
          keyboardId={'commonKeyboard'}
          onChangeAll={onChangeAll}
        />
      </div>
    </>
  );
};

export default HeaderFilterBar;

const CustomizedBadge = styled(Badge)<{ filtered?: number }>`
  .MuiBadge-badge {
    transform: scale(${({ filtered }) => (filtered ? 1.5 : 0)}) translate(50%, -50%);
  }
`;

const DisplayFiltersWrapper = styled(Grid)`
  margin-top: 1rem;
`;

const DisplayFiltersItem = styled(Grid)`
  width: initial;
`;
const GridWrapper = styled(Grid)`
  .mini-scanner {
    width: 1rem;
    overflow: hidden;
    padding: 0.4rem 1.5rem;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #3f44ac;
  }

  .MuiChip-deleteIconOutlinedColorPrimary {
    color: #3f44ac;
  }

  .MuiFormLabel-root {
    color: #111135;
  }
`;

const ClearButton = styled(Button)`
  font-size: 1.2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-transform: inherit;
  height: inherit;
  padding: 0.75rem 1rem;

  .MuiButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
  }
`;
const SideOptions = styled(Grid)`
  padding: 0 1rem;

  .MuiButton-outlined,
  .widget.border {
    border-radius: 2rem;
    border-color: #3f44ac;
    color: #3f44ac;
    text-transform: capitalize;
  }

  .MuiButton-outlined.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    border-color: rgba(0, 0, 0, 0.26);
  }

  .MuiButton-sizeLarge {
    font-size: 1rem;
    font-weight: normal;
  }
`;

const FilterGrid = styled(Grid)<{ position?: string }>`
  ${({ position }) => (position ? `position: ${position};` : '')};
`;

const AutoCompleteGrid = styled(Grid)<{ open?: boolean }>`
  .MuiAutocomplete-inputRoot {
    background-color: white;
    height: auto;
  }

  .MuiAutocomplete-clearIndicator.MuiAutocomplete-clearIndicatorDirty {
    visibility: inherit;
  }

  .MuiOutlinedInput-root {
    border-radius: 2rem;
  }

  .MuiAutocomplete-inputRoot.Mui-focused {
    height: auto;

    .MuiInputBase-input:focus {
      outline: 0;
      height: fit-content;
      padding: 9.5px 4px;
    }
  }

  .MuiInputLabel-filled {
    color: #111135;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #3f44ac;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #3f44ac;
  }

  ${({ open }) => (open ? ` .MuiAutocomplete-inputRoot { height : auto; }` : '')}
`;

const FiltersButton = styled(Button)`
  padding: 0.5rem 1rem;
`;

const CustomDivider = styled(Divider)`
  margin: 0 0.5rem;
  width: 0.2rem;
`;
const TextDispay = styled.p`
  margin-right: 0.5rem;
  display: inline;
`;

const ChipDisplay = styled(Chip)`
  color: #9b9b9b;
  background-color: #ececec;
  margin-right: 0.5rem;
`;
const DateHeader = styled(Box)`
  text-align: left;
  padding-bottom: 0.1rem;
  margin-left: 1.2rem;
  position: absolute;
  top: -20px;
`;
