import React, { useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import { useDebounce, usePrevious } from 'hooks';
import { fetchedFilters, IOptionsToFilterBy } from '../hooks/useGetOrdersWithStainedItemsList';
import { useGetOrdersWithStainedItemsList, useGetPdfLink, useStainManConfig } from '../hooks';
import Table from '../components/table/table';
import { FilterOptions, HeaderFilterBar } from 'components/filters';
import MultiScannerWidget from 'modules/stations/foldingStation/components/multiScanner/multiScannerWidget';

import PdfLinkQrDisplay from '../components/modals/pdfLinkQrDisplay';
import { OrderServiceLines } from 'utils/interfaces';

const addFilterOptionsFromAPI = (filter: FilterOptions, baseFilters: fetchedFilters) => {
  if (baseFilters.hasOwnProperty(filter.filterKey)) {
    if (filter.type === 'autoComplete' && filter.modifyLabelFromAPI) {
      return {
        ...filter,
        filterOptions: filter.modifyLabelFromAPI(baseFilters[filter.filterKey as keyof fetchedFilters]),
      };
    }
    return { ...filter, filterOptions: baseFilters[filter.filterKey as keyof fetchedFilters] };
  }
  return filter;
};

interface Props {
  serviceLine: OrderServiceLines;
}

const StainManOverview: React.FC<Props> = ({ serviceLine }) => {
  const serviceLineConfig = useStainManConfig(serviceLine);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedFilters, setSelectedFilters] = useState<IOptionsToFilterBy>({
    ...serviceLineConfig.defaultModalValues,
    ...serviceLineConfig.defaultCommonValues,
    ...serviceLineConfig.serviceLineFilterValues,
  });
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [pdfDialogOpen, setPdfDialogOpen] = useState(false);
  const prevPageNumber = usePrevious(currentPageNumber);
  const { debouncedValue: debouncedSearchQuery, isDebouncing } = useDebounce(selectedFilters, prevPageNumber !== currentPageNumber ? 0 : 3000);
  const { ordersItemsList, baseFilters, paginationMeta } = useGetOrdersWithStainedItemsList(
    debouncedSearchQuery,
    currentPageNumber,
    OrderServiceLines.LAUNDRY
  );
  const { handlePrintOrderPaper, isPinging, pdfLink, isPrinting } = useGetPdfLink(selectedFilters, currentPageNumber, paginationMeta);

  const [commonFiltersWithOptions, modalFiltersWithOptions] = useMemo(
    () => [
      serviceLineConfig.commonFilters.map((filter) => addFilterOptionsFromAPI(filter, baseFilters)),
      serviceLineConfig.modalFilters.map((filter) => addFilterOptionsFromAPI(filter, baseFilters)),
    ],
    [baseFilters, serviceLineConfig.commonFilters, serviceLineConfig.modalFilters]
  );

  useEffect(() => {
    setCurrentPageNumber(1);
  }, [selectedFilters]);

  //FIXME : Bad logic, should be refactored once we refactor this station to different pages instead of Modals
  const focusScanner = useMemo(() => location.pathname.endsWith('/table'), [location.pathname]);

  const onItemScan = (scannedValue: string) => {
    navigate(`report/${scannedValue}`, { replace: true });
  };

  return (
    <Grid container id={'stainManStationWrapper'}>
      {pdfDialogOpen && (
        <PdfLinkQrDisplay
          open={pdfDialogOpen}
          handleClose={() => {
            setPdfDialogOpen(false);
          }}
          handlePrintPdf={handlePrintOrderPaper}
          isPinging={isPinging}
          pdfLink={pdfLink}
          isPrinting={isPrinting}
        />
      )}
      {isDebouncing && (
        <CircleWrapper display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <FilterHeader>Filtering...</FilterHeader>
          <CircularProgress size="3rem" />
        </CircleWrapper>
      )}
      <Grid item xs={12}>
        <HeaderFilterBar
          defaultModalValues={serviceLineConfig.defaultModalValues}
          defaultCommonValues={serviceLineConfig.defaultCommonValues}
          handleSubmitFilters={(newFilters) => {
            setSelectedFilters({ ...newFilters, ...serviceLineConfig.serviceLineFilterValues });
          }}
          modalFilters={modalFiltersWithOptions}
          commonFilters={commonFiltersWithOptions}
          wrapperDivId="stainManStationWrapper"
          resetOnUnmount={false}
        >
          <MultiScannerWidget
            wrapperClassNames={'mini-scanner'}
            isVisible
            autoFocus={false}
            withOrderSearchButton
            isMiniScanner
            isWidget
            validateInput
            toastMessage="Invalid QR/RFID code"
            onItemScan={onItemScan}
            focusOnLoad={focusScanner}
          />
        </HeaderFilterBar>
      </Grid>
      <GridTable item xs={12}>
        <Table
          columns={serviceLineConfig.columns}
          paginationMeta={{ ...paginationMeta, page: currentPageNumber, itemsPerPage: paginationMeta.itemsPerPage }}
          onPageChange={(pageNumber) => setCurrentPageNumber(pageNumber)}
          tableData={ordersItemsList}
          handlePrintOrderPaper={() => setPdfDialogOpen(true)}
        />
      </GridTable>
    </Grid>
  );
};

export default StainManOverview;

const GridTable = styled(Grid)`
  height: 90%;
  margin-top: 0.8rem;

  .urgent-icon {
    padding-left: 0.5rem;
  }
`;
const CircleWrapper = styled(Box)`
  position: absolute;
  bottom: 45%;
  right: 45%;
  z-index: 1;
`;
const FilterHeader = styled(Typography)`
  font-size: 30px;
`;
