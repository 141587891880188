import { AxiosRequestConfig } from 'axios';
import Axious from './api';
import { IStainManFilters } from '../modules/stations/stainManStation/hooks/useGetOrdersWithStainedItemsList';
import { ItemIssueUpdateRequest } from '../modules/stations/stainManStation/hooks/useUpdateItemIssueStatus';
import { AddItemIssueRequestParams } from 'modules/stations/stainManStation/hooks/useSubmitNewIssue';

class StainManService {
  /**
   * Fetch the list of stained items on Stainman Station
   *
   * @return {Object} ordersItemsList
   */
  public async fetchStainedItems(filters: IStainManFilters) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/items/reported`,
      params: {
        ...filters,
      },
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async fetchItemReportDetails(itemIdentifier: string, orderAlphaId: string | undefined, isFirstScan: boolean) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/items/${itemIdentifier}/report`,
      params: {
        isFirstScan: isFirstScan,
        orderAlphaId: orderAlphaId,
      },
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async updateItemIssue(itemIssueUpdateRequest: ItemIssueUpdateRequest) {
    const { orderAlphaId, itemCode, issueId, ...restOfInputs } = itemIssueUpdateRequest;
    const requestOptions: AxiosRequestConfig = {
      method: 'PUT',
      url: `/orders/${orderAlphaId}/items/${itemCode}/issues/${issueId}`,
      data: restOfInputs,
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }

  /**
   * @description add new Unactionable issues (that are not stains/damages)
   *
   * @returns newIssue
   */
  public async addItemIssue(itemCode: string, itemIssueDetails: Omit<AddItemIssueRequestParams, 'itemCode'>) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/item/${itemCode}/add-issue`,
      data: {
        ...itemIssueDetails,
      },
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }

  /**
   * @description prints the current table items (100 max) into a PDF that's hosted on Slack & S3
   *
   * @returns s3PdfURL
   */
  public async printToPDF(filters: IStainManFilters) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/items/reported/print`,
      params: {
        ...filters,
      },
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }
}

const stainManService = new StainManService();

// export as singleton
export default stainManService;
