import { useMutation } from 'react-query';
import stationService from 'services/station.service';

interface CreateOptions {
  isRemoved: boolean;
  noteId: string;
}

const useSetNoteStatus = () => {
  return useMutation<string, Error, CreateOptions>(({ isRemoved, noteId }) => stationService.setNoteStatus(noteId, isRemoved));
};

export default useSetNoteStatus;
