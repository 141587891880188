import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Menu } from '@material-ui/core';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { HeaderRenderIcon } from './userActivityCount';
import { ActivityTypes } from 'utils/enums/typesEnum';

interface ActivityCountProps {
  renderIcons: HeaderRenderIcon[];
  activityType?: ActivityTypes;
}

const ActivityCount: React.FC<React.PropsWithChildren<ActivityCountProps>> = observer(({ renderIcons, children, activityType = ActivityTypes.BAG }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
          {renderIcons.map(({ src, count, width, height }, i) => (
            <ActivityCountIcon {...{ src, count, width, height, type: activityType }} key={i} />
          ))}
        </div>
      </IconButton>
      <StyledMenu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        onClose={handleClose}
      >
        {children}
      </StyledMenu>
    </>
  );
});

export default ActivityCount;

export const ActivityCountIcon: React.FC<{ count: number; src: string; width: string; height?: string; type?: ActivityTypes }> = ({
  count,
  type,
  src,
  width,
  height,
}) => {
  return (
    <Wrapper type={type}>
      <img src={src} alt="" width={width} height={height ? height : undefined} />
      <CountWrapper type={type}>{count}</CountWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ type?: ActivityTypes }>`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 50px;
  ${(props) => (props.type === 'SHOE' ? `margin-left: 1rem;` : '')}
`;

const CountWrapper = styled.div<{ type?: ActivityTypes }>`
  position: absolute;
  ${(props) => (props.type === 'SHOE' ? `color: #fff; top: 0rem; left: 0.3rem;` : `color: blue;`)};
`;

const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    border: #b6bcd1 3px solid;
    border-radius: 6px;
  }
`;
