import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Button, Grid } from '@material-ui/core';
import lodash from 'lodash';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const _itemsObjBuilder = (imagesList: string[], imagesPerPage: number) => {
  let itemsObj = {};

  for (let i = 0, index = 1, j = imagesList.length; i < j; i += imagesPerPage, index++) {
    itemsObj = { ...itemsObj, [`${index}`]: imagesList.slice(i, i + imagesPerPage) };
  }

  return itemsObj;
};

interface Props {
  imagesList: string[];
  width?: string;
  paginationWidth?: string;
  imagesPerRow?: number;
  imagesPerPage?: number;
  imagesDimentions?: { width: string; height: string };
  handleReachedLastPage?: () => void;
  unSetButtonPosition?: boolean;
}

const ImagesListCarousel: React.FC<Props> = ({
  imagesList = [],
  imagesPerPage = 3,
  imagesPerRow = 3,
  width,
  imagesDimentions = { height: '1rem', width: '1rem' },
  paginationWidth = '63%',
  handleReachedLastPage,
  unSetButtonPosition = false,
}) => {
  const [selectedPage, setSelectedPage] = React.useState(1);
  const carouselObj = React.useMemo<{ [key: number]: string[] }>(() => _itemsObjBuilder(imagesList, imagesPerPage), [imagesList, imagesPerPage]);

  const currentPageData = useMemo(
    () => (carouselObj[selectedPage] ? lodash.chunk(carouselObj[selectedPage], imagesPerRow) : [[]]),
    [carouselObj, selectedPage, imagesPerRow]
  );
  const nextItems = () => {
    const nextPage = selectedPage + 1;
    setSelectedPage(nextPage);
  };

  useEffect(() => {
    if (selectedPage === Object.keys(carouselObj).length) {
      handleReachedLastPage?.();
    }
  }, [selectedPage, handleReachedLastPage, carouselObj]);

  const previousItems = () => {
    const previousPage = selectedPage - 1;
    setSelectedPage(previousPage);
  };

  const [showNextBtn, showPrevBtn] = useMemo(
    () => [selectedPage <= Object.keys(carouselObj).length - 1, selectedPage > 1],
    [selectedPage, carouselObj]
  );

  return (
    <Wrapper width={width}>
      <ItemWrapper container spacing={5} direction="column">
        {currentPageData.map((imagesInRow, rowIndex) => (
          <Grid key={`images-in-row-wrapper-${rowIndex}`} item container justifyContent="space-around" xs={12}>
            {imagesInRow.map((imageSrc, imageIndex) => (
              <Grid key={`${imageSrc}-${imageIndex}`}>
                <ImgDisplay height={imagesDimentions.height} width={imagesDimentions.width} src={imageSrc} alt={`${imageIndex}`} />
              </Grid>
            ))}
          </Grid>
        ))}
      </ItemWrapper>
      <PaginationWrapper paginationWidth={paginationWidth} selectedPage={selectedPage} unSetPosition={unSetButtonPosition}>
        {showPrevBtn && (
          <NumberButton variant={'contained'} color={'primary'} size="small" onClick={previousItems}>
            <ChevronLeftIcon />
          </NumberButton>
        )}

        {showNextBtn && (
          <NumberButton variant={'contained'} color={'primary'} size="small" onClick={nextItems}>
            <ChevronRightIcon />
          </NumberButton>
        )}
      </PaginationWrapper>
    </Wrapper>
  );
};

export default ImagesListCarousel;
//add custom width
const Wrapper = styled.div<{ width?: string | undefined }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? `${width};` : `60%;`)}
  align-items: center;
  justify-content: center;
`;

const PaginationWrapper = styled.div<{ selectedPage?: number; paginationWidth?: string; unSetPosition?: boolean }>`
  display: flex;
  ${({ unSetPosition }) => (!unSetPosition ? `position: absolute;` : ``)}
  ${({ selectedPage }) => (selectedPage === 1 ? `justify-content: flex-end;` : `justify-content: space-between;`)}
  width: ${({ paginationWidth }) => paginationWidth};
`;

const ItemWrapper = styled(Grid)`
  height: fit-content;
  align-items: center;
  justify-content: center;
`;

const NumberButton = styled(Button)`
  padding: 0.5rem;
  margin: 0 0.5rem;
`;

const ImgDisplay = styled.img<{ height: string; width: string }>`
  ${({ width }) => `width : ${width};`}
  ${({ height }) => `height : ${height};`}
`;
