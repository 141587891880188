import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import styled from 'styled-components';

import { FoldingType } from 'utils/enums/stationEnums';

/** Icon Import */
import { ReactComponent as CardBoardIcon } from 'assets/images/preferenceIcons/cardboard.svg';
import { ReactComponent as NoCardboard } from 'assets/images/preferenceIcons/nocardboard.svg';
import { ReactComponent as CustomerSR } from 'assets/images/preferenceIcons/csr.svg';
import { ReactComponent as ReportIcon } from 'assets/images/preferenceIcons/staindamage.svg';
import { ReactComponent as FoldingIcon } from 'assets/images/preferenceIcons/folding.svg';
import { ReactComponent as NoFoldingIcon } from 'assets/images/preferenceIcons/nofolding.svg';

/** Background Colors enum */
enum BgColors {
  LIGHT = '#f7f4f0',
  MEDIUM = '#f6e5cf',
  HARD = '#e7a44c',
  stainRed = '#f5edef',
  green = '#e2f2e9',
  blue = '#ebecf9',
  midBlue = '#c9cce6',
  gray = '#f2f3f7',
  fullBlue = '#3e48a6',
}

/** Colors enum */
enum Colors {
  yellow = '#e5952c',
  red = '#ca3232',
  green = '#19a784',
  white = '#ffffff',
  blue = '#5861b2',
  gray = '#7c85a7',
  fullBlue = '#3e48a6',
}

/** Border Colors enum */
enum BorderColors {
  LIGHT = '#f4d9b2',
  MEDIUM = '#f3b7b',
  HARD = '#e39a3c',
  green = '#badfcb',
  red = '#ca3232',
  blue = '#5861b2',
  gray = '#b6bcd1',
  fullBlue = '#3e48a6',
}

/** Preference Styling Details */
interface PreferenceStylingDetails {
  bgColor: string;
  color: string;
  borderColor: string;
  prefTextId: string;
  icon?: JSX.Element;
}

/** Define props interface */
interface Props {
  instruction: string;
  isRectangular?: boolean;
  value: string | boolean;
  size: 'sm' | 'md' | 'lg';
}

/** Define default props */
const defaultProps = {
  instruction: 'default',
  value: false,
};

/** PreferenceBox */
const PreferenceBox: React.FC<Props> = ({ instruction = defaultProps.instruction, value = defaultProps.value, isRectangular = false, size }) => {
  // Initialise my prefDetails state
  const [prefDetails, setPrefDetails] = useState<PreferenceStylingDetails>({ bgColor: '', color: '', borderColor: '', prefTextId: '', icon: <></> });
  // Build the prefDetails according to the instruction and value
  useEffect(() => {
    switch (instruction) {
      case 'hasStain':
      case 'hasDamage':
        if (value)
          setPrefDetails({
            bgColor: BgColors.stainRed,
            color: Colors.red,
            borderColor: BorderColors.red,
            prefTextId: 'customerPreferences.hasReport',
            icon: <ReportIcon />,
          });
        break;

      case 'folding':
        if (value) {
          setPrefDetails({
            bgColor: BgColors.green,
            color: Colors.green,
            borderColor: BorderColors.green,
            prefTextId: 'customerPreferences.forFolding',
            icon: <FoldingIcon />,
          });
          break;
        }

        setPrefDetails({
          bgColor: BgColors.stainRed,
          color: Colors.red,
          borderColor: BorderColors.red,
          prefTextId: 'customerPreferences.notForFolding',
          icon: <NoFoldingIcon />,
        });
        break;

      case 'shirtCrease':
        if (value)
          setPrefDetails({
            bgColor: BgColors.blue,
            color: Colors.blue,
            borderColor: BorderColors.blue,
            prefTextId: `customerPreferences.withShirtCrease`,
          });
        break;

      case 'pantsCrease':
        if (value)
          setPrefDetails({
            bgColor: BgColors.blue,
            color: Colors.blue,
            borderColor: BorderColors.blue,
            prefTextId: `customerPreferences.withPantsCrease`,
          });
        break;
      case 'shortsCrease':
        if (value)
          setPrefDetails({
            bgColor: BgColors.blue,
            color: Colors.blue,
            borderColor: BorderColors.blue,
            prefTextId: `customerPreferences.withShortsCrease`,
          });
        break;

      case 'gathraCrease':
        if (value !== '')
          if (value === 'gathra_no_crease' || value === 'gathra_no_preference') {
            setPrefDetails({
              bgColor: BgColors.stainRed,
              color: Colors.red,
              borderColor: BorderColors.red,
              prefTextId: `customerPreferences.with${value}`,
            });
            break;
          }
        setPrefDetails({
          bgColor: BgColors.blue,
          color: Colors.blue,
          borderColor: BorderColors.blue,
          prefTextId: `customerPreferences.with${value}`,
        });
        break;

      case 'kanduraCrease':
        if (value !== '') {
          if (value === 'kandura_full') {
            setPrefDetails({
              bgColor: BgColors.fullBlue,
              color: Colors.white,
              borderColor: BorderColors.fullBlue,
              prefTextId: `customerPreferences.with${value}`,
            });
            break;
          }

          setPrefDetails({
            bgColor: BgColors.midBlue,
            color: Colors.fullBlue,
            borderColor: BorderColors.fullBlue,
            prefTextId: `customerPreferences.with${value}`,
          });
          break;
        }
        break;

      case 'withoutCrease':
        if (value)
          setPrefDetails({
            bgColor: BgColors.stainRed,
            color: Colors.red,
            borderColor: BorderColors.red,
            prefTextId: `customerPreferences.withNoCrease`,
          });
        break;

      case 'starch':
        if (value !== 'NONE') {
          setPrefDetails({
            bgColor: BgColors[value as keyof typeof BgColors],
            color: value === 'HARD' ? Colors.white : Colors.yellow,
            borderColor: BorderColors[value as keyof typeof BorderColors],
            prefTextId: `customerPreferences.Starch${value}`,
          });
          break;
        }

        setPrefDetails({
          bgColor: BgColors.stainRed,
          color: Colors.red,
          borderColor: BorderColors.red,
          prefTextId: 'customerPreferences.StarchNONE',
        });
        break;

      case 'hasCSR':
        if (value) {
          setPrefDetails({
            bgColor: BgColors.midBlue,
            color: Colors.fullBlue,
            borderColor: BorderColors.fullBlue,
            prefTextId: `customerPreferences.hasCSR`,
            icon: <CustomerSR />,
          });
        }
        break;

      case 'hasCardboard':
        if (value) {
          setPrefDetails({
            bgColor: BgColors.green,
            color: Colors.green,
            borderColor: BorderColors.green,
            prefTextId: 'customerPreferences.hasCardboard',
            icon: <CardBoardIcon />,
          });
          break;
        }

        setPrefDetails({
          bgColor: BgColors.stainRed,
          color: Colors.red,
          borderColor: BorderColors.red,
          prefTextId: 'customerPreferences.noCardboard',
          icon: <NoCardboard />,
        });
        break;

      case 'foldingType':
        if (value && value !== FoldingType.transit) {
          setPrefDetails({
            bgColor: BgColors.gray,
            color: Colors.gray,
            borderColor: BorderColors.gray,
            prefTextId: `customerPreferences.${value}`,
          });
        }
        break;
    }
  }, [instruction, value]);

  const { bgColor, color, borderColor, prefTextId, icon } = prefDetails;

  return (
    <>
      {bgColor !== '' && color !== '' && prefTextId !== '' && (
        <Wrapper bgColor={bgColor} borderColor={borderColor} color={color} size={size} hasIcon={!!icon}>
          {icon && <IconWrapper>{icon}</IconWrapper>}
          <FormattedMessage id={prefTextId || 'customerPreferences.default'} defaultMessage={`customerPreferences.default`} />
        </Wrapper>
      )}
    </>
  );
};

export default PreferenceBox;
interface WrapperProps {
  size: 'sm' | 'md' | 'lg';
  bgColor: string;
  borderColor: string;
  color: string;
  hasIcon: boolean;
}
const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;

  justify-content: center;
  position: relative;
  padding: 0.5rem;
  min-width: 10%;
  font-size: 1.5rem;
  font-weight: 300;
  border: 1px solid;
  margin: 0.6rem;
  border-radius: 2rem;
  border-color: ${(props) => props.borderColor};
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  ${(props) => (props.size === 'sm' ? `max-width: fit-content; ${props.hasIcon ? 'padding-left: 3.5rem;' : ''}` : '')}
`;
const IconWrapper = styled.div`
  position: absolute;
  left: -4%;
`;
