import { useQuery } from 'react-query';
import { StationService } from 'services';
import { ItemTypeMeasurementsConfig, ItemTypes, MeasurementConfig } from 'utils/interfaces';


type KeyedMeasurements = { [key in MeasurementConfig['key']]: MeasurementConfig }

export type QueryResponse = ItemTypeMeasurementsConfig & { keyedMeasurements: KeyedMeasurements }
const useGetItemTypeMeasurementsConfig = (itemType?: ItemTypes) => {
    return useQuery<QueryResponse, Error>(
        ['item-type-measurements', { itemType }],
        () => StationService.getMeasurementsForItemType(itemType),
        {
            select: (data) => ({
                ...data,
                keyedMeasurements: data?.measurements.reduce<KeyedMeasurements>((acc, measurement) => {
                    acc[measurement.key] = measurement;
                    return acc;
                }, {})
            }),
            refetchOnMount: false,
            refetchOnWindowFocus: false,
        }
    );

};

export default useGetItemTypeMeasurementsConfig;
