import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { Typography, Box, Grid, Button } from '@material-ui/core';
import { IGroup, IItemGroup, IOrderItem } from 'utils/interfaces';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { BrandDisplay } from 'components/brandDisplay';
import { ColorMapper } from 'components/colorMapper';
import NoImage from 'assets/images/pngs/noImage.png';
import { RoundButton } from './marryingModal';

interface Props {
  group: IGroup;
  index: number;
  itemGroups: IItemGroup;
  handelModifyGroup: (groupId: string) => void;
  handelRemoveGroup: (groupId: string) => void;
  currentItem: IOrderItem;
}

const GroupRow: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ group, index, itemGroups, handelModifyGroup, handelRemoveGroup, currentItem }) => {
    const [showScrollButton, setShowScrollButton] = React.useState(false);
    const scrollableRef = useRef<HTMLDivElement>(null);

    const scrollToEnd = () => {
      if (scrollableRef.current) {
        const scrollableElement = scrollableRef.current;
        scrollableElement.scrollLeft = scrollableElement.scrollWidth;
      }
    };

    const handleScroll = () => {
      if (scrollableRef.current) {
        const scrollableElement = scrollableRef.current;
        setShowScrollButton(scrollableElement.scrollLeft < scrollableElement.scrollWidth / 12);
      }
    };

    useEffect(() => {
      if (scrollableRef.current) {
        const scrollableElement = scrollableRef.current;
        setShowScrollButton(scrollableElement.scrollWidth > scrollableElement.clientWidth);
      }
    }, [itemGroups]);

    return (
      <GroupGrid container xs={'auto'} direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={'auto'}>
          <Typography variant={'h5'}>{'Group ' + index}</Typography>
        </Grid>
        <DisplayGroupGrid
          container
          xs={10}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          ref={scrollableRef}
          onScroll={handleScroll}
        >
          {group.itemCodesList.map((groupItem, i) => {
            const item = itemGroups.allItems.find((item) => item.itemCode === groupItem);
            const { code } = currentItem;
            if (item && code === item?.itemCode) {
              item.type = currentItem.type;
              item.brand = currentItem.brand;
              item.colorsList = currentItem.colorsList;
              item.frontImage = currentItem.frontImage;
            }
            return (
              <GroupItemBox
                item
                container
                xs={'auto'}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-end"
                key={`${i}-${item?.itemCode}-group-item-box`}
              >
                <Grid item xs={'auto'}>
                  <GroupImageHolderBox>
                    <img src={item?.frontImage || NoImage} width="120px" height="160px" alt="Washmen" />
                  </GroupImageHolderBox>
                </Grid>
                <Grid item xs={'auto'} justifyContent="flex-start" alignItems="center">
                  <Grid item xs={'auto'} style={{ marginLeft: '1rem' }}>
                    {item?.brand && <BrandDisplay isTextHidden alignItems={''} brand={item!.brand} />}
                  </Grid>
                  {item?.colorsList && (
                    <Grid item xs={'auto'}>
                      <ColorMapper colorsList={item?.colorsList} dimension="3rem" textHidden />
                    </Grid>
                  )}
                </Grid>
              </GroupItemBox>
            );
          })}

          {showScrollButton && <ScrollToEndButton color="default" size="large" variant="contained" onClick={scrollToEnd}></ScrollToEndButton>}
        </DisplayGroupGrid>
        <ButtonsGrid item xs={1} container justifyContent="space-evenly" alignContent="space-around">
          <RoundButton color="primary" size="large" variant="contained" onClick={() => handelModifyGroup(group.groupId)}>
            <EditIcon fontSize="large" />
          </RoundButton>

          <RoundButton color="secondary" size="large" variant="contained" onClick={() => handelRemoveGroup(group.groupId)}>
            <DeleteIcon fontSize="large" />
          </RoundButton>
        </ButtonsGrid>
      </GroupGrid>
    );
  }
);

export default GroupRow;

export const GroupImageHolderBox = styled(Box)`
  border: 1px solid #3c3c3c;
  border-radius: 3px;
  width: 7.5rem;
  height: 10rem;
  padding: 10px;
  background: #ffffff;
  position: relative;
`;

export const GroupItemBox = styled(Grid)`
  min-width: 22rem;
  padding-top: 1rem;
`;

export const DisplayGroupGrid = styled(Grid)`
  padding: 5px;
  display: grid;
  align-items: center;
  grid-column-gap: 1rem;
  grid-auto-flow: column;
  grid-template-columns: unset;
  grid-template-rows: unset;
  overflow: scroll;
`;

export const GroupGrid = styled(Grid)`
  border-bottom: 0.1rem solid #b6bcd1;
  padding: 0rem 3rem 0.2rem 1rem;
`;

export const ScrollToEndButton = styled(Button)`
  position: absolute;
  right: 12.3rem;
  border-radius: 0;
  bottom: 0.3rem;
  padding: 15px;
  margin-top: 6px;
  height: 13.4rem;
  min-width: 40px !important;
  width: 2.5rem;

  background: linear-gradient(to right, transparent 20%, #b6bcd1 100%);
  transition: left 0.3s ease-in-out;
  box-shadow: 0 0 20px #eee;
`;

export const ButtonsGrid = styled(Grid)`
  border-left: 0.1rem solid #b6bcd1;
  height: 13rem;
`;
