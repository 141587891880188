import { useMutation } from "react-query"
import stationService from "services/station.service";

interface MutationOptions {
    itemCode: string;
    orderId: string;
    approvalReason: string;
}
const useRequestApprovalSendToCp = () => {
    return useMutation({
        mutationFn: ({ approvalReason, itemCode, orderId }: MutationOptions) => stationService.requestApprovalSendToCp(itemCode, orderId, approvalReason)
    })
}
export default useRequestApprovalSendToCp