import React from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { PhotoCatcher } from 'modules/stations/components/photoCatcher';
import AddStainDamageComponent from './addStainDamageDetails';
import ReportOverView from './reportOverView';
import { DetailsList } from 'utils/interfaces';
import { ComponentProps, useGetSelectedItem } from 'modules/stations/components/SortingStepper/SortingStepper';
import { Grid } from '@material-ui/core';

interface Props extends ComponentProps {
  autoSave?: boolean;
  handleOnFinish?: () => void;
  skipPhoto?: boolean;
  routeOutside?: string;
}

const StainDamageComponent: React.FC<Props> = ({ autoSave = false, handleOnFinish, skipPhoto = true, onSelect, routeOutside }) => {
  const navigate = useNavigate();
  const locationState = useLocation().state as 'damage' | 'stain';

  const { selectedItem: currentItem } = useGetSelectedItem();
  // go to add stain/damage details with info
  function handleConfirmImage(image: string) {
    navigate(`addDetails`, {
      state: {
        type: locationState,
        image: image,
      },
    });
  }

  const handleAddStainSubmit = (form: DetailsList) => {
    if (!form.part) {
      delete form.part;
    }
    onSelect(
      {
        ...currentItem,
        reportDetailsList: [...(currentItem.reportDetailsList || []), form],
      },
      { disableRouting: true }
    );
  };

  const handleEditStain = (form: DetailsList, index: string = '') => {
    const intIndex = parseInt(index);
    onSelect(
      {
        ...currentItem,
        reportDetailsList: currentItem.reportDetailsList!.map((report, index) => {
          if (index === intIndex) {
            return form;
          }
          return report;
        }),
      },
      { disableRouting: true }
    );
  };

  const onFinish = () => {
    handleOnFinish?.();
    goOut();
  };

  const goOut = () => navigate(routeOutside ?? '../');

  return (
    <Routes>
      <Route path={`overView`} element={<ReportOverView onSelect={onSelect} handleOnFinish={onFinish} autoSave={autoSave} />} />
      <Route path={`addDetails`} element={<AddStainDamageComponent onSubmit={handleAddStainSubmit} />} />
      <Route path={`editDetails/:index`} element={<AddStainDamageComponent onSubmit={handleEditStain} />} />
      <Route
        path={'/'}
        element={
          <Grid container style={{ overflow: 'scroll', flex: '1' }}>
            <PhotoCatcher
              title="Please take a photo of the stain/damage"
              onConfirmImage={handleConfirmImage}
              enableUploadImage
              isReport={true}
              showSkipButton={skipPhoto}
              handleSkipButton={goOut}
            />
          </Grid>
        }
      />
      <Route path="*" element={<Navigate to={''} />} />
    </Routes>
  );
};

export default StainDamageComponent;
