import React from 'react';
import { ControllerRenderProps } from 'react-hook-form';

import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import styled from 'styled-components';

interface Props {
  title: string;
  filterOptions: { label: string; value: string }[];
  filterKey: string;
  field?: ControllerRenderProps;
  defaultValue: string;
  onChangeHandler?: (val: string) => void;
}
const RadioComponent: React.FC<Props> = ({ defaultValue, filterKey, filterOptions, field, title, onChangeHandler = () => {} }) => {
  return (
    <StyledFormControl container fullWidth alignItems="center" justifyContent="space-between" key={filterKey}>
      <Grid item xs={4}>
        <FormLabel id={`radio-buttons-group-label-${filterKey}`}>{title}</FormLabel>
      </Grid>
      <RadioGrid item xs={8}>
        <RadioGroup
          row
          {...field}
          onChange={(ev) => {
            field?.onChange(ev);
            onChangeHandler(ev.target.value as string);
          }}
          aria-labelledby={`radio-buttons-group-label-${filterKey}`}
          defaultValue={defaultValue}
        >
          {filterOptions.map(({ value, label }) => {
            return <FormControlLabel value={value} key={label} control={<Radio />} label={label} />;
          })}
        </RadioGroup>
      </RadioGrid>
    </StyledFormControl>
  );
};

export default RadioComponent;

const RadioGrid = styled(Grid)`
  .MuiRadio-colorSecondary.Mui-checked {
    color: #3f44ac;
  }
  .MuiFormGroup-row {
    justify-content: space-between;
  }
`;

const StyledFormControl = styled(({ ...rest }) => <FormControl component={Grid} {...rest} />)`
  flex-direction: row;
`;
