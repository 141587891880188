import { useMutation } from 'react-query';
import stationService from 'services/station.service';
import { ItemTypes } from 'utils/interfaces';

interface CreateOptions {
  orderAlphaId: string;
  itemCode: string;
  type: ItemTypes;
  image: string;
  orderId: string;
  extraItemCode: string;
}

const useAddExtraItem = () => {
  return useMutation<string, Error, CreateOptions>(({ orderAlphaId, itemCode, extraItemCode, type, image, orderId }) =>
    stationService.addExtraItem({ orderAlphaId, itemCode, type, image, orderId, extraItemCode })
  );
};

export default useAddExtraItem;
