import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';

import { Typography, Grid } from '@material-ui/core';
import { ExtraItem, IItemGroup, IOrderItem } from 'utils/interfaces';
import DeleteIcon from '@material-ui/icons/Delete';

import { BrandDisplay } from 'components/brandDisplay';
import { ColorMapper } from 'components/colorMapper';
import NoImage from 'assets/images/pngs/noImage.png';
import { RoundButton } from './marryingModal';
import { ButtonsGrid, DisplayGroupGrid, GroupGrid, GroupImageHolderBox, GroupItemBox, ScrollToEndButton } from './groupRow';

interface Props {
  extraItemsList: ExtraItem[];
  item: IOrderItem;
  index: number;
  itemGroups: IItemGroup;
  handelRemoveGroup: (orderAlphaId: string, itemCode: string, extraItemCodesList: string[]) => void;
  orderAlphaId: string;
}

const GroupRowExtraItems: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ extraItemsList, item, index, itemGroups, handelRemoveGroup, orderAlphaId }) => {
    const [showScrollButton, setShowScrollButton] = React.useState(false);
    const scrollableRef = useRef<HTMLDivElement>(null);

    const scrollToEnd = () => {
      if (scrollableRef.current) {
        const scrollableElement = scrollableRef.current;
        scrollableElement.scrollLeft = scrollableElement.scrollWidth;
      }
    };

    const handleScroll = () => {
      if (scrollableRef.current) {
        const scrollableElement = scrollableRef.current;
        setShowScrollButton(scrollableElement.scrollLeft < scrollableElement.scrollWidth / 12);
      }
    };

    useEffect(() => {
      if (scrollableRef.current) {
        const scrollableElement = scrollableRef.current;
        setShowScrollButton(scrollableElement.scrollWidth > scrollableElement.clientWidth);
      }
    }, [itemGroups]);

    return (
      <GroupGrid container xs={'auto'} direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={'auto'}>
          <Typography variant={'h5'}>{'Group ' + index}</Typography>
        </Grid>
        <DisplayGroupGrid
          container
          xs={10}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          ref={scrollableRef}
          onScroll={handleScroll}
        >
          {[item, ...extraItemsList].map((extraItem, i) => {
            return (
              <GroupItemBox
                item
                container
                xs={'auto'}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-end"
                key={`${i}-${extraItem?.code}-group-item-box`}
              >
                <Grid item xs={'auto'}>
                  <GroupImageHolderBox>
                    <img src={extraItem?.frontImage || NoImage} width="120px" height="160px" alt="Washmen" />
                  </GroupImageHolderBox>
                </Grid>
                {extraItem?.brand && (
                  <Grid item xs={'auto'} justifyContent="flex-start" alignItems="center">
                    <Grid item xs={'auto'} style={{ marginLeft: '1rem' }}>
                      {extraItem?.brand && <BrandDisplay isTextHidden alignItems={''} brand={extraItem!.brand} />}
                    </Grid>
                    {extraItem?.colorsList && (
                      <Grid item xs={'auto'}>
                        <ColorMapper colorsList={extraItem?.colorsList} dimension="3rem" textHidden />
                      </Grid>
                    )}
                  </Grid>
                )}
              </GroupItemBox>
            );
          })}

          {showScrollButton && <ScrollToEndButton color="default" size="large" variant="contained" onClick={scrollToEnd}></ScrollToEndButton>}
        </DisplayGroupGrid>
        <ButtonsGrid item xs={1} container justifyContent="space-evenly" alignContent="space-around">
          <RoundButton
            color="secondary"
            size="large"
            variant="contained"
            onClick={() =>
              handelRemoveGroup(
                orderAlphaId,
                item?.code || item?.itemCode || '',
                extraItemsList.map((item) => item.code)
              )
            }
          >
            <DeleteIcon fontSize="large" />
          </RoundButton>
        </ButtonsGrid>
      </GroupGrid>
    );
  }
);

export default GroupRowExtraItems;
