import React, { useEffect, useMemo, useState } from 'react';

import { Box, ButtonBase } from '@material-ui/core';
import ItemReportHeader from '../header/itemReportHeader';
import { AnswerDelaysProvider, ItemJourneyContext, ItemJourneyProvider } from 'modules/stations/pendingItemsStation/context';
import { Route, Routes } from 'react-router-dom';

import ItemActivityJourneyOverview from './itemActivityJourneyOverview';
import styled from 'styled-components';
import { IItemReportDetail } from 'utils/interfaces/stainManStationInterfaces';
import { LoaderComponent } from 'components/loaderComponent';
import { ItemIssue, OrderServiceLines } from 'utils/interfaces';
import useGetItemActivities from '../../../pendingItemsStation/hooks/useGetItemActivities';
import RejectedInfoModal from './rejectedInfoModal';
import ShoeMiddleCard from '../cards/shoeMiddleCard';
import ShoeRightCard from '../cards/shoeRightCard';
import ShoeLeftCard from '../cards/shoeLeftCard';
import { StainDamageComponent } from 'modules/stations/sorterStation/components/dialogs';
import { ReactComponent as BlackCloseSVG } from 'assets/images/svgs/close-black.svg';
import LeftCard from '../cards/leftCard';
import MiddleCard from '../cards/middleCard';
import RightCard from '../cards/rightCard';
import FineryLeftCard from '../cards/fineryLeftCard';
import FineryMiddleCard from '../cards/fineryMiddleCard';
import FineryRightCard from '../cards/fineryRightCard';

interface Props {
  openStainsDialog: (type: 'stain' | 'damage') => Promise<void>;
  itemReportDetail: IItemReportDetail;
  toggle: () => void;
  handleRefetchItemReports: () => void;
}

type ColumnsConfig = {
  [k in OrderServiceLines]: {
    leftColumn: JSX.Element;
    middleColumn: JSX.Element;
    rightColumn: JSX.Element;
  };
};

const hasStainOrDamage = (itemIssues: ItemIssue[], type: 'STAINED' | 'DAMAGED') => itemIssues.some((issue) => RegExp(type, 'i').test(issue.reason));

const ReportsModal: React.FC<Props> = ({ openStainsDialog, itemReportDetail, toggle, handleRefetchItemReports }) => {
  const orderServiceLine = itemReportDetail.orderServiceLine || OrderServiceLines.LAUNDRY;

  const hasStain = useMemo(() => hasStainOrDamage(itemReportDetail.itemIssues, 'STAINED'), [itemReportDetail.itemIssues]);
  const hasDamage = useMemo(() => hasStainOrDamage(itemReportDetail.itemIssues, 'DAMAGED'), [itemReportDetail.itemIssues]);
  const { itemActivities } = useGetItemActivities(itemReportDetail.itemCode, itemReportDetail.orderAlphaId);
  const [openRejectedModal, setOpenRejectedModal] = useState<boolean>(false);
  useEffect(() => setOpenRejectedModal(itemReportDetail.approvalStatus === 'REJECTED'), [itemReportDetail.approvalStatus]);

  const columns: ColumnsConfig = {
    laundry: {
      leftColumn: <LeftCard itemReportDetail={itemReportDetail} isItemRejected={itemReportDetail.approvalStatus === 'REJECTED'} />,
      middleColumn: (
        <MiddleCard
          handleOnSubmitNewIssue={handleRefetchItemReports}
          handleOnUpdateItemIssueStatus={handleRefetchItemReports}
          openStainsDialog={openStainsDialog}
          itemReportDetail={itemReportDetail}
          isItemRejected={itemReportDetail.approvalStatus === 'REJECTED'}
        />
      ),
      rightColumn: <RightCard itemReportDetail={itemReportDetail} />,
    },

    shoes: {
      leftColumn: <ShoeLeftCard itemReportDetail={itemReportDetail} isItemRejected={itemReportDetail.approvalStatus === 'REJECTED'} />,
      middleColumn: (
        <ShoeMiddleCard
          handleOnSubmitNewIssue={handleRefetchItemReports}
          handleOnUpdateItemIssueStatus={handleRefetchItemReports}
          openStainsDialog={openStainsDialog}
          itemReportDetail={itemReportDetail}
          isItemRejected={itemReportDetail.approvalStatus === 'REJECTED'}
        />
      ),
      rightColumn: <ShoeRightCard itemReportDetail={itemReportDetail} />,
    },
    finery: {
      leftColumn: <FineryLeftCard itemReportDetail={itemReportDetail} isItemRejected={itemReportDetail.approvalStatus === 'REJECTED'} />,
      middleColumn: (
        <FineryMiddleCard
          handleOnSubmitNewIssue={handleRefetchItemReports}
          handleOnUpdateItemIssueStatus={handleRefetchItemReports}
          openStainsDialog={openStainsDialog}
          itemReportDetail={itemReportDetail}
          isItemRejected={itemReportDetail.approvalStatus === 'REJECTED'}
        />
      ),
      rightColumn: <FineryRightCard itemReportDetail={itemReportDetail} />,
    },
  };
  return (
    <Routes>
      <Route
        path={`stain-damage/*`}
        element={
          <>
            <CloseButton onClick={() => {
            }}>
              <BlackCloseSVG width="3rem" height="3rem" />
            </CloseButton>
            <StainDamageComponent skipPhoto={false} autoSave />
          </>
        }
      />
      <Route
        path={'/'}
        element={
          <AnswerDelaysProvider refreshDataOnUpdate={false} validateManagerFirst queryKey="itemReportDetail">
            <Box style={{ overflow: 'hidden' }}>
              <ItemReportHeader itemReportDetail={itemReportDetail} toggle={toggle} />
              <LoaderComponent />
              <Wrapper justifyContent={'center'}>
                <ItemJourneyProvider>
                  <ItemJourneyContext.Consumer>
                    {({ open }) =>
                      open && <ItemActivityJourneyOverview hasDamage={hasDamage} hasStain={hasStain} activities={itemActivities} open={open} />
                    }
                  </ItemJourneyContext.Consumer>
                  <Box display="flex" flexDirection="row" bgcolor="#e8e8ef">
                    <Box flex={4} marginRight={1} bgcolor="#ffffff">
                      {columns[orderServiceLine].leftColumn}
                    </Box>
                    <Box flex={8}>
                      {columns[orderServiceLine].middleColumn}
                    </Box>
                    <SectionWrapper flex={4} marginLeft={1} bgcolor="#ffffff">
                      {columns[orderServiceLine].rightColumn}
                    </SectionWrapper>
                  </Box>
                </ItemJourneyProvider>
                <RejectedInfoModal open={openRejectedModal} toggle={() => setOpenRejectedModal(false)} onPressCancel={toggle} />
              </Wrapper>
            </Box>
          </AnswerDelaysProvider>
        }
      />
    </Routes>
  );
};

export default ReportsModal;
const Wrapper = styled(Box)`
  padding: 1.5rem 1rem;
  height: 100%;
  background-color: #e8e8ef;
`;

const SectionWrapper = styled(Box)`
  border: 1px solid #7d7c97;
`;
const CloseButton = styled(ButtonBase)`
  align-self: end;
  padding: 2rem;
`;
