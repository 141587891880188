import React, { useCallback } from 'react';
import SelectBrandDialog from './selectBrandDialog';
import { PhotoCatcher } from '../..';
import { Brand } from 'utils/interfaces';
import { observer } from 'mobx-react-lite';
import { useStationStore } from 'stores';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

interface Props {}

const SelectBrandComponent: React.FC<Props> = observer(() => {
  const { updateCurrentItemDetails, currentItem } = useStationStore();
  const navigate = useNavigate();
  const locationState = useLocation().state as { brand: Brand; isCareLabel?: boolean } | null;
  const setBrandAndConfirm = useCallback(
    (image: string, brand: Brand) => {
      updateCurrentItemDetails({
        ...currentItem,
        careLabelImageUrl: image,
        brand: brand,
      });

      if (currentItem.colorsList && currentItem.colorsList.length > 0) {
        navigate('../', { replace: true });
      } else {
        navigate('../(item/color)', { replace: true });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentItem]
  );

  return (
    <Routes>
      <Route
        path={`careLabel`}
        element={
          <PhotoCatcher
            title="Please take photo of care label"
            onConfirmImage={(image) => locationState?.brand && setBrandAndConfirm(image, locationState?.brand)}
            isCareLabel={locationState?.isCareLabel}
            //Removed for now, pending change after new sorter flow is ready
            // cancelBtn
            // cancelBtnText="no label"
            // onCancelButtonClick={() => setBrandAndConfirm('', brand)}
          />
        }
      />
      <Route path={'/'} element={<SelectBrandDialog />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
});

export default SelectBrandComponent;
