import React from 'react';
import { CustomDialog } from '../dialogs';
import { Typography, Box, Grid, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
interface Props {
  onClose: () => void;
  image: string;
  open: boolean;
  title?: string;
  subTitle?: string;
  imageClass?: string;
  modalSize?: 'sm' | 'md' | 'lg' | 'xl';
  dataCy?: string;
  wrapperClass?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imgHeight: {
      height: '25vh',
    },
    titleWidth: {
      width: '70%',
    },
  })
);

const ConfirmDialog: React.FC<React.PropsWithChildren<Props>> = ({
  onClose,
  image,
  open,
  children,
  title,
  subTitle,
  imageClass,
  modalSize = 'md',
  dataCy = '',
  wrapperClass,
}) => {
  const CloseModal = () => {
    onClose();
  };
  const classes = useStyles();

  return (
    <>
      <CustomDialog open={open} onClose={CloseModal} size={modalSize} dataCy={dataCy}>
        <Grid className={wrapperClass} justify="space-between" container alignItems="center" direction="column">
          <Grid item xs={12}>
            <Box padding="2rem">
              <img src={image} className={imageClass} alt="warning" />
            </Box>
          </Grid>

          {title && title !== '' && (
            <Grid item xs={12} className={classes.titleWidth}>
              <Box padding={subTitle && title ? '1rem' : '3rem'}>
                <Typography variant="h3" display="initial" align="center">
                  {title}
                </Typography>
              </Box>
            </Grid>
          )}

          {subTitle && subTitle !== '' && (
            <Grid item>
              <Box padding="1rem">
                <Typography variant="h4">{subTitle}</Typography>
              </Box>
            </Grid>
          )}

          <Grid container direction="row" justify="center">
            {children}
          </Grid>
        </Grid>
      </CustomDialog>
    </>
  );
};
export default ConfirmDialog;
