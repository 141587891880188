import { useEffect } from 'react';
/**
 * Hook that alerts clicks outside of the passed ref
 */
export default function useOutsideAlerter(cb: (event: Event) => void, wrapperId?: string, condition?: boolean) {
  useEffect(() => {
    if (condition) {
      // Bind the event listener
      const wrapper = wrapperId ? (document.getElementById(wrapperId) as HTMLElement) : document;
      wrapper.addEventListener('mousedown', cb);
      return () => {
        // Unbind the event listener on clean up
        wrapper.removeEventListener('mousedown', cb);
      };
    } else {
      document.removeEventListener('mousedown', cb);
    }
  }, [cb, wrapperId, condition]);
}
