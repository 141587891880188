import React, { useEffect, useState } from 'react';
import { Box, Button, ButtonBase, DialogActions, DialogContent, Grow, Modal } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { BounceComponent, LoaderComponent } from 'components/loaderComponent';
import QRCode from 'qrcode.react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Toaster } from 'components/toaster';

import { ReactComponent as PrinterIcon } from 'assets/images/svgs/printer.svg';

interface Props {
  isPinging: boolean;
  pdfLink?: string;
  open: boolean;
  handleClose: () => void;
  handlePrintPdf: () => void;
  isPrinting: boolean;
}
const PdfLinkQrDisplay: React.FC<Props> = ({ isPinging, pdfLink, open, handleClose, handlePrintPdf, isPrinting }) => {
  const [qrModalOpen, setQrModalOpen] = useState(false);

  return (
    <Modal open={open} keepMounted={false}>
      <ModalBox>
        <GeneratePdfComponent
          show={!qrModalOpen}
          handleClose={handleClose}
          handlePrintPdf={handlePrintPdf}
          setQrModalOpen={() => setQrModalOpen(true)}
        />
        <CustomLoader show={(isPinging || isPrinting) && qrModalOpen} />
        <PdfQRComponent show={!(isPinging || isPrinting) && qrModalOpen} handleClose={handleClose} qrCode={pdfLink || ''} />
      </ModalBox>
    </Modal>
  );
};

const GeneratePdfComponent: React.FC<{
  handlePrintPdf: () => void;
  setQrModalOpen: (val: boolean) => void;
  handleClose: () => void;
  show: boolean;
}> = ({ handleClose, handlePrintPdf, setQrModalOpen, show }) => {
  return (
    <Grow in={show} unmountOnExit timeout={{ appear: 0, enter: 1500, exit: 200 }}>
      <Box>
        <LoaderComponent />
        <DialogHeader>
          Are you sure you want to send data to Slack?
          <CloseDialogButton onClick={() => handleClose()}>
            <Close fontSize="large" />
          </CloseDialogButton>
        </DialogHeader>

        <StyledDialogContent>
          <PrinterIcon width={'25rem'} height="20rem" />
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledDialogButtons
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              handlePrintPdf();
              setQrModalOpen(true);
            }}
          >
            Generate PDF
          </StyledDialogButtons>
        </StyledDialogActions>
      </Box>
    </Grow>
  );
};

const CustomLoader: React.FC<{ show: boolean }> = ({ show }) => {
  return (
    <Grow in={show} unmountOnExit timeout={{ appear: 400, enter: 1500, exit: 0 }}>
      <Box>
        <StyledDialogContent>
          <BounceComponent />
        </StyledDialogContent>
      </Box>
    </Grow>
  );
};

const PdfQRComponent: React.FC<{ qrCode: string; handleClose: () => void; show: boolean }> = ({ qrCode, handleClose, show }) => {
  useEffect(() => {
    if (show) {
      toast(<Toaster message="PDF sent to Slack" type="success" />);
    }
  }, [show]);
  return (
    <Grow in={show} unmountOnExit timeout={{ appear: 400, enter: 1500, exit: 200 }}>
      <Box>
        {' '}
        <DialogHeader>
          Data sent to slack, you can now scan QR code
          <CloseDialogButton onClick={() => handleClose()}>
            <Close fontSize="large" />
          </CloseDialogButton>
        </DialogHeader>
        <StyledDialogContent>
          <QrWrapper>
            <QRCode value={qrCode || ''} renderAs="svg" includeMargin size={300} />
          </QrWrapper>
        </StyledDialogContent>
      </Box>
    </Grow>
  );
};

const QrWrapper = styled.div`
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 0.3rem;
  border: 1px solid #b6bcd1;
  border-radius: 5px;
`;

export default PdfLinkQrDisplay;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  justify-content: center;
`;
const StyledDialogActions = styled(DialogActions)`
  justify-content: center;
`;

const DialogHeader = styled.h1`
  text-align: center;
  color: #111135;
  font-weight: normal;
  font-size: 2.5rem;
  position: relative;
`;
const CloseDialogButton = styled(ButtonBase)`
  position: absolute;
  right: 1rem;
  bottom: 1.5rem;
`;

const StyledDialogButtons = styled(Button)`
  width: 25rem;
  height: 7rem;
`;
const ModalBox = styled(Box)`
  background-color: white;
  position: absolute;
  border-radius: 0.7rem;
  top: 50%;
  left: 50%;
  width: 60rem;
  height: max-content;
  transform: translate(-50%, -50%);
  overflow: scroll;

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #3f44ac;
  }

  .MuiChip-deleteIconOutlinedColorPrimary {
    color: #3f44ac;
  }

  .MuiFormLabel-root {
    color: #111135;
  }
`;
