import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Button, Theme } from '@material-ui/core';
import { useStationStore } from 'stores';
import { observer } from 'mobx-react-lite';
import { PhotoCatcher } from '../';
import { WashmenIcon } from 'components/washmenIcon';
import { useNavigate } from 'react-router-dom';
import useVerifyStation from '../../hooks/useVerifyStation';
import { StationTypes } from 'utils/enums/stationEnums';

interface Props {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stainIcon: {
      marginRight: '1rem',
      '& path': {
        fill: '#ca3232',
      },
      width: '2.5vw',
      height: '5vh',
    },
    damageIcon: {
      marginRight: '1rem',
      '& g': {
        fill: 'white',
        stroke: '#ca3232',
      },
      width: '2.5vw',
      height: '5vh',
    },
  })
);
const SelectPhotoComponent: React.FC<Props> = observer(() => {
  const navigate = useNavigate();

  const isWfSorter = useVerifyStation(StationTypes.WFSorter);
  const { currentItem, updateCurrentItemDetails, linkedItem } = useStationStore();
  // Save the currentImage being taken
  const [currentImage, setCurrentImage] = useState('');
  function handleConfirmImage(image: string) {
    // update current item in store
    updateCurrentItemDetails({
      ...currentItem,
      frontImage: image,
    });

    // exit modal route
    navigate('../');
  }

  // Save the current Image to the state
  const handleImageState = (image: string) => {
    setCurrentImage(image);
  };
  const classes = useStyles();

  function addStainDamageFlow(type: 'damage' | 'stain') {
    if (currentImage !== '') {
      handleConfirmImage(currentImage);
    }

    navigate('../(item/stainDamage)', { state: type });
  }

  const forceStain = linkedItem && linkedItem.stainsArray && linkedItem.stainsArray.length > 0;

  return (
    <PhotoCatcher imageSrc={currentItem.frontImage} onConfirmImage={handleConfirmImage} onPhotoTaken={handleImageState} hideNextButton={forceStain}>
      {!isWfSorter && [
        <Button
          key={'addDmg'}
          color="secondary"
          variant="outlined"
          size="large"
          onClick={() => addStainDamageFlow('damage')}
          style={{ margin: '0 auto' }}
        >
          <WashmenIcon name="damageIcon" svg iconClassName={classes.damageIcon} isButton={false} />
          add damage
        </Button>,
        <Button
          key={'addStain'}
          color="secondary"
          variant="outlined"
          size="large"
          onClick={() => addStainDamageFlow('stain')}
          style={{ margin: '0 auto' }}
        >
          <WashmenIcon name="stainIcon" svg iconClassName={classes.stainIcon} isButton={false} />
          add stain
        </Button>,
      ]}
    </PhotoCatcher>
  );
});

export default SelectPhotoComponent;
