import React from 'react';
import styled from 'styled-components';
import { Box, ButtonBase, Grid } from '@material-ui/core';

import { IItemReportDetail } from 'utils/interfaces/stainManStationInterfaces';
import { capitalizeString } from 'utils/helpers/string';
import { formatCurrentDate } from 'utils/helpers/date';
import { ShoeAddonApprovals } from 'modules/stations/components/icons';
import { StyledChip } from 'modules/stations/components/StyledComponents';

import { ReactComponent as BlackCloseSVG } from 'assets/images/svgs/close-black.svg';
import { ReactComponent as FacilityIcon } from 'assets/images/svgs/facility-icon.svg';
import { ReactComponent as ShoeWashIcon } from 'assets/images/svgs/shoe-washing.svg';

interface ReportHeaderProps {
  itemReportDetail: IItemReportDetail;
  toggle: () => void;
  smallFont?: boolean;
}

const ItemReportHeader: React.FC<ReportHeaderProps> = ({ itemReportDetail, toggle, smallFont = false }) => {
  const approvalStatus =
    itemReportDetail.approvalStatus && itemReportDetail.approvalOrigin
      ? `${capitalizeString(itemReportDetail.approvalStatus)} By ${capitalizeString(itemReportDetail.approvalOrigin)}`
      : '?';

  return (
    <Wrapper display="flex" flexDirection="row" flex={1}>
      <Wrapper2 display="flex" flexDirection="column" flex={40}>
        <Box display="flex" flex={2} alignItems="center" flexDirection="row" flexWrap="wrap" gridColumnGap="2rem">
          <Box flex={smallFont ? 2 : 1}>
            <Header smallFont={smallFont}>
              Order ID: <FontNormal>{itemReportDetail.orderAlphaId}</FontNormal>
            </Header>
          </Box>
          <Box flex={2} textAlign="justify">
            <Header smallFont={smallFont}>
              Facility visit: <FontNormal>{formatCurrentDate(itemReportDetail.createdAt, 'YYYY MMM DD')}</FontNormal>
            </Header>
          </Box>
          <Box flex={2}>
            <Header smallFont={smallFont}>
              Drop off date: <FontNormal>{formatCurrentDate(itemReportDetail.dropoffDate, 'YYYY MMM DD')} </FontNormal>
            </Header>
          </Box>
          <Box flex={2}>
            <Header smallFont={smallFont}>
              Station Scans: <FontNormal>{itemReportDetail.numberOfScans} times</FontNormal>{' '}
            </Header>
          </Box>
          {approvalStatus !== '?' && (
            <ApprovalStatusWrapper flex={2} display="flex" alignItems="center">
              <FacilityIcon width={'1.5rem'} height={'1.5rem'} className="facility-icon" />
              <Header smallFont={smallFont}>{` ${approvalStatus}`}</Header>
            </ApprovalStatusWrapper>
          )}
          <Box flex={2}>
            <Header smallFont={smallFont}>
              Customer ID: <FontNormal>{itemReportDetail.customerAlphaId}</FontNormal>
            </Header>
          </Box>
        </Box>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <Header smallFont={smallFont}>
              Customer ID: <FontNormal>{itemReportDetail.customerAlphaId}</FontNormal>
            </Header>
          </Grid>

          {!smallFont && itemReportDetail.preferences?.shoeAddOnApprovals?.assessBeforeProcessing === false && (
            <Grid item xs={2}>
              <Header smallFont={smallFont}>
                <StyledChip
                  avatar={
                    <ShoeWashIcon
                      style={{
                        width: '1rem',
                        height: '1rem',
                      }}
                    />
                  }
                  fontSize="0.8rem"
                  width="8.75rem"
                  height="2rem"
                  borderRadius="2rem"
                  label="Basic Cleaning"
                  variant="outlined"
                />
              </Header>
            </Grid>
          )}
          {!smallFont && (
            <Grid item xs={4}>
              <ShoeAddonApprovals approvals={itemReportDetail.preferences?.shoeAddOnApprovals} />
            </Grid>
          )}
        </Grid>
      </Wrapper2>
      <Box flex={1} marginTop={2} justifyContent="flex-start">
        <ButtonBase
          onClick={() => {
            toggle();
          }}
        >
          <BlackCloseSVG width="3rem" height="3rem" />
        </ButtonBase>
      </Box>
    </Wrapper>
  );
};

export default ItemReportHeader;
const Wrapper2 = styled(Box)`
  gap: 1rem;
`;

const Wrapper = styled(Box)`
  padding: 0.75rem 1rem;
  border: 1px solid #7d7c97;

  //For ShoeAddonApprovals Component
  .shoe-addon-approvals-box {
    font-size: 1rem;
    height: 1.25rem;
  }
`;

const Header = styled.p<{ smallFont: boolean }>`
  margin: 6px 0;
  font-family: Ubuntu;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--secondary-darkblue);
  flex: 7;
  width: 100%;
  white-space: nowrap;
  ${(props) => (!props.smallFont ? 'font-size: 25px;' : 'font-size: 15px; white-space: nowrap;')}
`;

const FontNormal = styled.span`
  font-weight: normal;
`;

const ApprovalStatusWrapper = styled(Box)`
  gap: 0.5rem;
`;
