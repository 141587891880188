import { AxiosRequestConfig } from 'axios';
import Axious from './api';

import { GetAvailableDropoffBagResponse, ListDropoffBagsForOrderResponse, ListFoldingPackagesResponse } from 'modules/stations/foldingStation/queries';
import { CloseDropoffBagParams, AssignPackageToDropoffBagParams } from 'modules/stations/foldingStation/mutations';

class FoldingService {
    public async getAvailableDropoffBag(orderAlphaId?: string) {
        const requestOptions: AxiosRequestConfig = {
            method: 'GET',
            url: `/orders/${orderAlphaId}/dropoff-bags`,
        };
        const response = await Axious.request<GetAvailableDropoffBagResponse>(requestOptions);
        return response.data;
    }
    public async listDropoffBagsForOrder(orderAlphaId?: string) {
        const requestOptions: AxiosRequestConfig = {
            method: 'GET',
            url: `/orders/${orderAlphaId}/dropoff-bags/packages`,
        };
        const response = await Axious.request<ListDropoffBagsForOrderResponse>(requestOptions);
        return response.data;
    }

    public async listFoldingPackages(orderAlphaId?: string) {
        const requestOptions: AxiosRequestConfig = {
            method: 'GET',
            url: `/orders/${orderAlphaId}/packages/list-folding`,
        };
        const response = await Axious.request<ListFoldingPackagesResponse>(requestOptions);
        return response.data;
    }

    public async closeDropoffBag(params: CloseDropoffBagParams) {
        const requestOptions: AxiosRequestConfig = {
            method: 'POST',
            url: `/orders/dropoff-bags/${params.bagCode}/mark-as-closed`,
        };
        const response = await Axious.request(requestOptions);
        return response.data;
    }
    public async assignPackageToDropoffBag(params: AssignPackageToDropoffBagParams) {
        const requestOptions: AxiosRequestConfig = {
            method: 'PUT',
            url: `/orders/dropoff-bags/${params.bagCode}/packages/${params.packageId}`,
        };
        const response = await Axious.request(requestOptions);
        return response.data;
    }

    public async unassignPackageToDropoffBag(params: AssignPackageToDropoffBagParams) {
        const requestOptions: AxiosRequestConfig = {
            method: 'DELETE',
            url: `/orders/dropoff-bags/packages/${params.packageId}`,
            data: {
                bagCode: params.bagCode
            }
        };
        const response = await Axious.request(requestOptions);
        return response.data;
    }

    public async markAsFoldingProcessingDelayed(orderId: string, itemCode: string) {
        const requestOptions: AxiosRequestConfig = {
            method: 'POST',
            url: `/orders/${orderId}/items/${itemCode}/mark-as-folding-processing-delayed`,
        };
        const response = await Axious.request(requestOptions);
        return response.data;
    }

}

const foldingService = new FoldingService();

export default foldingService;
