import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Typography, Grid, Button } from '@material-ui/core';
import { CustomDialog } from 'components/dialogs';

interface Props {
  onClose: () => any;
  onContinue: (allItemsRemove: boolean) => any;
  open: boolean;
  allItemsRemove?: boolean;
}

const RemoveConfirmationModal: React.FC<Props> = observer(({ onClose, onContinue, open, allItemsRemove = false }) => {
  return (
    <CustomDialog open={open} onClose={onClose} size="md">
      <RootGrid container direction="column">
        <GridContainer container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h4">Are you sure want to remove item(s) from group?</Typography>
          </Grid>
        </GridContainer>
        <GridContainer container direction="row" justifyContent="space-evenly">
          <Grid item xs="auto">
            <Button color="secondary" variant="contained" size="large" onClick={onClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={() => {
                onContinue(allItemsRemove);
              }}
            >
              Continue
            </Button>
          </Grid>
        </GridContainer>
      </RootGrid>
    </CustomDialog>
  );
});

const RootGrid = styled(Grid)`
  padding: 1rem;
`;

const GridContainer = styled(Grid)`
  padding: 4rem 4rem 1.5rem 4rem;
`;

export default RemoveConfirmationModal;
