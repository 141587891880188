import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import SwipeableViews from 'react-swipeable-views';
import MobileStepper from '@material-ui/core/MobileStepper';
import { Box, Grid } from '@material-ui/core';
import _ from 'lodash';

const VIEW_PER_STEP = 4;

interface Props<ObjectType> {
  items: ObjectType[];
  renderItem: (item: ObjectType, key: number) => JSX.Element;
  renderItemsWrapper?: (children: JSX.Element[], key: React.Key) => JSX.Element;
  viewPerStep?: number;
}

const defaultItemsWrapper = (children: JSX.Element[], key: React.Key) => {
  return (
    <Grid key={key} container item xs={8} justifyContent={'center'} style={{ maxWidth: 'max-content', minHeight: '16rem' }}>
      {children}
    </Grid>
  );
};

const CustomSwipeableView = <ObjectType,>({
  items,
  renderItem,
  viewPerStep = VIEW_PER_STEP,
  renderItemsWrapper = defaultItemsWrapper,
}: Props<ObjectType>) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const sectionArray = useMemo(() => _.chunk(items, viewPerStep), [items, viewPerStep]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const imagesToDisplay = useCallback(() => {
    return (
      /* //MIGRATION:: react-swipeable-views is still using the old version of react, we need to think of migrating to a better library
      @ts-ignore  */
      <SwipeableViews enableMouseEvents onChangeIndex={handleStepChange}>
        {sectionArray.map((objectsArray, index) => renderItemsWrapper(objectsArray.map(renderItem), index))}
      </SwipeableViews>
    );
  }, [sectionArray, renderItem, renderItemsWrapper]);

  return (
    <Box justifyContent={'center'}>
      {imagesToDisplay()}
      <StepperWrapper>
        <StyledStepper
          variant="dots"
          steps={Math.ceil(items.length / viewPerStep)}
          position="static"
          activeStep={activeStep}
          nextButton={<div onClick={handleNext} />}
          backButton={<div onClick={handleBack} />}
        />
      </StepperWrapper>
    </Box>
  );
};

const StyledStepper = styled(MobileStepper)`
  background-color: white;
  flex-grow: 1;
`;
const StepperWrapper = styled(Box)`
  text-align: -webkit-center;
`;
export default CustomSwipeableView;
