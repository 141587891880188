import { ItemTypes } from '../interfaces';

/** Categories Types */
export interface ITypeObj {
  value: ItemTypes;
  label: string;
  iconName: string;
}

/** Main Object Type */
export interface ITypeCategories {
  [key: string]: { [key: string]: ITypeObj };
}

export const ITEM_TYPE_CATEGORIES = ['top', 'bottom', 'undergarment', 'linen', 'formal', 'ethnic', 'outerwear', 'other'];

/** Return the TypeObject based on the program value property */
export const getTypeByValue = (programKey?: string) => {
  let typeObj: ITypeObj = {} as ITypeObj;

  let typeCategory: string = '';

  Object.keys(typeMapper).map((category: string) => {
    return Object.keys(typeMapper[category]).map((program: string) => {
      if (typeMapper[category][program].value === programKey) {
        typeCategory = category;
        return (typeObj = typeMapper[category][program]);
      }
      return false;
    });
  });

  return { typeObj, typeCategory };
};

// Return the TypeObject based on the program Key property
export const typeKeyObjHelper = (programLabel: string) => {
  let programObj: ITypeObj = {} as ITypeObj;

  Object.keys(typeMapper).some((category: string) => {
    if (typeMapper[category][programLabel]) {
      programObj = typeMapper[category][programLabel];

      return true;
    }
    return false;
  });
  return programObj;
};

const filterCategories = (category: { [key: string]: ITypeObj }, exclude: string[]) => {
  return Object.keys(category).reduce<{ [key: string]: ITypeObj }>((acc, curr) => {
    if (!exclude.includes(curr)) {
      acc[curr] = category[curr];
    }
    return acc;
  }, {});
};

export const verifyTypeInMapper = (type: ItemTypes, typeCategory: ITypeCategories) => {
  return Object.keys(typeCategory).some((category) => {
    return Object.keys(typeCategory[category]).some((program) => {
      return typeCategory[category][program].value === type;
    });
  });
};

const categoriesToValues = (categories: ITypeCategories) => Object.values(categories).reduce((acc, curr) => ({ ...acc, ...curr }), {});

export const REMOVED_LAUNDRY_ITEM_TYPES: ItemTypes[] = ['DRESS_LONG', 'DRESS_SHORT', 'PULLOVER', 'SWEATER'];

export const COMMON_ITEM_TYPES: any = {
  shirt: {
    value: 'SHIRT',
    label: 'Shirt',
    iconName: 'shirt',
  },
  tShirt: {
    value: 'T_SHIRT',
    label: 'T-Shirt',
    iconName: 'tShirt',
  },
  pants: {
    value: 'PANTS',
    label: 'Pants',
    iconName: 'pants',
  },
  blouse: {
    value: 'BLOUSE',
    label: 'Blouse',
    iconName: 'blouse',
  },
  pillowCase: {
    value: 'PILLOW_CASE',
    label: 'Pillow Case',
    iconName: 'pillowCase',
  },
  jacket: {
    value: 'JACKET',
    label: 'Jacket',
    iconName: 'jacket',
  },
  bedsheet: {
    value: 'BEDSHEET',
    label: 'Bedsheet',
    iconName: 'bedsheet',
  },
  suitJacket: {
    value: 'SUIT_JACKET',
    label: 'Suit Jacket',
    iconName: 'suitJacket',
  },
  shorts: {
    value: 'SHORTS',
    label: 'Shorts',
    iconName: 'shorts',
  },
  duvet: {
    value: 'DUVET',
    label: 'Duvet',
    iconName: 'duvet',
  },
};

const COMMON_SERVICE_LINE_TYPES: ITypeCategories = {
  accessories: {
    accessory: {
      value: 'ACCESSORY',
      label: 'Accessories',
      iconName: 'accessory',
    },
    looseItem: {
      value: 'LOOSE_ITEM',
      label: 'Loose Item',
      iconName: 'looseItem',
    },
    reusableBag: {
      value: 'REUSABLE_BAG',
      label: 'Reusable Bag',
      iconName: 'reusableBag',
    },
  },
};

export const HAND_BAG_TYPE_MAPPER: ITypeCategories = {
  handBags: {
    clutchPurse: {
      value: 'CLUTCH_PURSE',
      label: 'Clutch/Purse',
      iconName: 'clutchPurse',
    },
    wallets: {
      value: 'WALLETS',
      label: 'Wallets',
      iconName: 'wallets',
    },
    bagsStandardSize: {
      value: 'BAGS_STANDARD_SIZE',
      label: 'Bags Standard size',
      iconName: 'bagsStandardSize',
    },
    bagsOversized: {
      value: 'BAGS_OVERSIZED',
      label: 'Bag Oversized',
      iconName: 'bagsOversized',
    },
    mensBriefcase: {
      value: 'MENS_BRIEFCASE',
      label: 'Mens Breifcase',
      iconName: 'mensBriefcase',
    },
    valiseSuitcase: {
      value: 'VALISE_SUITCASE',
      label: 'Valise Suitcase',
      iconName: 'valiseSuitcase',
    },
    backpacks: {
      value: 'BACKPACKS',
      label: 'Backpacks',
      iconName: 'backpacks',
    },
    belts: {
      value: 'BELTS',
      label: 'Belts',
      iconName: 'belts',
    },
    shoeLaces: {
      value: 'SHOE_LACES',
      label: 'Shoe Laces',
      iconName: 'shoeLaces',
    },
  },
};

export const SHOE_EXCLUSIVE_TYPE_MAPPER: ITypeCategories = {
  sportsSneakers: {
    sportsSneakersSynthenticsTextile: {
      value: 'SPORTS_SNEAKERS_SYTHENTICS_TEXTILE',
      label: 'Sports Sneakers Sythentics / Textile',
      iconName: 'sportsSneakersSynthenticsTextile',
    },
    sportsSneakersSuedeNubuck: {
      value: 'SPORTS_SNEAKERS_SUEDE_NUBUCK',
      label: 'Sports Sneakers Suede / Nubuck',
      iconName: 'sportsSneakersSuedeNubuck',
    },
    sportsSneakersLeather: {
      value: 'SPORTS_SNEAKERS_LEATHER',
      label: 'Sports Sneakers Leather',
      iconName: 'sportsSneakersLeather',
    },
    sportsSneakersMix: {
      value: 'SPORTS_SNEAKERS_MIX',
      label: 'Sports Sneakers Mix',
      iconName: 'sportsSneakersMix',
    },
  },
  designerSneakers: {
    designerSneakersSythenticsTextile: {
      value: 'DESIGNER_SNEAKERS_SYTHENTICS_TEXTILE',
      label: 'Designer Sneakers Sythentics / Textile',
      iconName: 'designerSneakersSythenticsTextile',
    },
    designerSneakersSuede: {
      value: 'DESIGNER_SNEAKERS_SUEDE',
      label: 'Designer Sneakers Suede / Nubuck',
      iconName: 'designerSneakersSuede',
    },
    designerSneakersLeather: {
      value: 'DESIGNER_SNEAKERS_LEATHER',
      label: 'Designer Sneakers Leather',
      iconName: 'designerSneakersLeather',
    },
    designerSneakersMix: {
      value: 'DESIGNER_SNEAKERS_MIX',
      label: 'Designer Sneakers Mix',
      iconName: 'designerSneakersMix',
    },
  },

  formalShoe: {
    mensFormalShoeLeather: {
      value: 'MENS_FORMAL_SHOE_LEATHER',
      label: `Men's Formal Leather Shoe`,
      iconName: 'mensFormalShoeLeather',
    },
    mensFormalShoesMixedMaterial: {
      value: 'MENS_FORMAL_SHOES_MIXED_MATERIAL',
      label: `Men's Formal Mix Material Shoe`,
      iconName: 'mensFormalShoesMixedMaterial',
    },

    mensDesignerFormalLeather: {
      value: 'MENS_DESIGNER_FORMAL_LEATHER',
      label: `Men's Designer Formal Leather Shoe`,
      iconName: 'mensDesignerFormalLeather',
    },

    mensDesignerFormalMixedMaterial: {
      value: 'MENS_DESIGNER_FORMAL_MIXED_MATERIAL',
      label: `Men's Designer Formal Mix Material Shoe`,
      iconName: 'mensDesignerFormalMixedMaterial',
    },

    womensFormalShoe: {
      value: 'WOMENS_FORMAL_SHOE',
      label: `Women's Formal Shoe`,
      iconName: 'womensFormalShoe',
    },

    womenDesignerFormal: {
      value: 'WOMEN_DESIGNER_FORMAL',
      label: `Women's Designer Formal Shoe`,
      iconName: 'womenDesignerFormal',
    },
  },
  boots: {
    bootsSythenticsTextile: {
      value: 'BOOTS_SYTHENTICS_TEXTILE',
      label: 'Boot Sythentics / Textile',
      iconName: 'bootsSythenticsTextile',
    },
    bootsSuede: {
      value: 'BOOTS_SUEDE',
      label: 'Boot Suede / Nubuck',
      iconName: 'bootsSuede',
    },
    bootsLeather: {
      value: 'BOOTS_LEATHER',
      label: 'Boot Leather',
      iconName: 'bootsLeather',
    },
    bootsMix: {
      value: 'BOOTS_MIX',
      label: 'Boot Mix',
      iconName: 'bootsMix',
    },
    bootsHighBoots: {
      value: 'BOOTS_HIGH_BOOTS',
      label: 'High Boots All',
      iconName: 'bootsHighBoots',
    },
    bootsThighHighBoots: {
      value: 'BOOTS_THIGH_HIGH_BOOTS',
      label: 'Thigh High Boots All',
      iconName: 'bootsThighHighBoots',
    },
    bootsAnkle: {
      value: 'BOOTS_ANKLE',
      label: 'Ankle Boots',
      iconName: 'bootsAnkle',
    },
  },
  flipFlop: {
    flipFlopSythenticsTextile: {
      value: 'FLIP_FLOP_SYTHENTICS_TEXTILE',
      label: 'Flip Flop Sythentics / Textile',
      iconName: 'flipFlopSythenticsTextile',
    },
    flipFlopSuede: {
      value: 'FLIP_FLOP_SUEDE',
      label: 'Flip Flop Suede / Nubuck',
      iconName: 'flipFlopSuede',
    },
    leatherFlipFlop: {
      value: 'FLIP_FLOP_LEATHER',
      label: 'Flip Flop Leather',
      iconName: 'leatherFlipFlop',
    },
    flipFlopMix: {
      value: 'FLIP_FLOP_MIX',
      label: 'Flip Flop Mix',
      iconName: 'flipFlopMix',
    },
  },
  sandal: {
    sandalSynthenticsTextile: {
      value: 'SANDAL_SYTHENTICS_TEXTILE',
      label: 'Sandal Sythentics / Textile',
      iconName: 'sandalSynthenticsTextile',
    },
    suedeNubuckSandal: {
      value: 'SANDAL_SUEDE',
      label: 'Sandal Suede / Nubuck',
      iconName: 'suedeNubuckSandal',
    },
    sandalLeather: {
      value: 'SANDAL_LEATHER',
      label: 'Sandal Leather',
      iconName: 'sandalLeather',
    },
    sandalMix: {
      value: 'SANDAL_MIX',
      label: 'Sandal Mix',
      iconName: 'sandalMix',
    },
    designerSandalsFlipflops: {
      value: 'DESIGNER_SANDALS_FLIPFLOPS',
      label: 'Designer Sandals / FlipFlops',
      iconName: 'designerSandalsFlipflops',
    },
  },
  espardrilles: {
    espardrillesSythenticsTextile: {
      value: 'ESPARDRILLES_SYTHENTICS_TEXTILE',
      label: 'Espardrilles Sythentics / Textile',
      iconName: 'espardrillesSythenticsTextile',
    },
    espardrillesSuede: {
      value: 'ESPARDRILLES_SUEDE',
      label: 'Espardrilles Suede / Nubuck',
      iconName: 'espardrillesSuede',
    },
    espardrillesLeather: {
      value: 'ESPARDRILLES_LEATHER',
      label: 'Espardrilles Leather',
      iconName: 'espardrillesLeather',
    },
    espardrillesMix: {
      value: 'ESPARDRILLES_MIX',
      label: 'Espardrilles Mix',
      iconName: 'espardrillesMix',
    },
    designerEspardrilles: {
      value: 'DESIGNER_ESPARDRILLES',
      label: 'Designer Espardrilles',
      iconName: 'designerEspardrilles',
    },
  },
  childrensShoes: {
    sportsSneakersChildren: {
      value: 'SPORTS_SNEAKERS_CHILDREN',
      label: 'Children Sports Sneakers',
      iconName: 'sportsSneakersChildren',
    },
  },
};

export const SHOE_GENERAL_TYPE_MAPPER: ITypeCategories = {
  ...SHOE_EXCLUSIVE_TYPE_MAPPER,
  ...HAND_BAG_TYPE_MAPPER,
  ...COMMON_SERVICE_LINE_TYPES,
};

export const LAUNDRY_EXCLUSIVE_TYPE_MAPPER: ITypeCategories = {
  top: {
    tShirt: {
      value: 'T_SHIRT',
      label: 'T-Shirt',
      iconName: 'tShirt',
    },
    shirt: {
      value: 'SHIRT',
      label: 'Shirt',
      iconName: 'shirt',
    },
    blouse: {
      value: 'BLOUSE',
      label: 'Blouse',
      iconName: 'blouse',
    },
    undershirt: {
      value: 'UNDERSHIRT',
      label: 'Undershirt',
      iconName: 'undershirt',
    },
  },
  outerwear: {
    coat: {
      value: 'COAT',
      label: 'Coat',
      iconName: 'coat',
    },
    overcoat: {
      value: 'OVERCOAT',
      label: 'Overcoat',
      iconName: 'overcoat',
    },
    jacket: {
      value: 'JACKET',
      label: 'Jacket',
      iconName: 'jacket',
    },
    winterJacket: {
      value: 'WINTER_JACKET',
      label: 'Winter Jacket',
      iconName: 'winterJacket',
    },
    winterOvercoat: {
      value: 'WINTER_OVERCOAT',
      label: 'Winter Overcoat (Below Knee)',
      iconName: 'winterOvercoat',
    },
  },
  bottom: {
    shorts: {
      value: 'SHORTS',
      label: 'Shorts',
      iconName: 'shorts',
    },
    pants: {
      value: 'PANTS',
      label: 'Pants',
      iconName: 'pants',
    },
    skirt: {
      value: 'SKIRT',
      label: 'Skirt',
      iconName: 'skirt',
    },
    jeans: {
      value: 'JEANS',
      label: 'Jeans',
      iconName: 'jeans',
    },
    pyjamaPants: {
      value: 'PYJAMA_PANTS',
      label: 'Pyjama Pants',
      iconName: 'pyjamaPants',
    },
  },
  ethnic: {
    kandura: {
      value: 'KANDURA',
      label: 'Kandura',
      iconName: 'kandura',
    },
    abaya: {
      value: 'ABAYA',
      label: 'Abaya',
      iconName: 'abaya',
    },
    gathra: {
      value: 'GATHRA',
      label: 'Gathra',
      iconName: 'gathra',
    },
    kurta: {
      value: 'KURTA_MENS',
      label: 'Kurta',
      iconName: 'kurta',
    },
    sari: {
      value: 'SARI',
      label: 'Sari',
      iconName: 'sari',
    },
    sherwani: {
      value: 'SHERWANI',
      label: 'Sherwani',
      iconName: 'sherwani',
    },
    sheila: {
      value: 'SHEILA',
      label: 'Sheila',
      iconName: 'sheila',
    },
    jalabiya: {
      value: 'JALABIYA',
      label: 'Jalabiya',
      iconName: 'jalabiya',
    },
    delicateAbaya: {
      value: 'DELICATE_ABAYA',
      label: 'Delicate Abaya',
      iconName: 'delicateAbaya',
    },
  },
  linen: {
    bedsheet: {
      value: 'BEDSHEET',
      label: 'Bedsheet',
      iconName: 'bedsheet',
    },
    pillowCase: {
      value: 'PILLOW_CASE',
      label: 'Pillow Case',
      iconName: 'pillowCase',
    },
    bathrobe: {
      value: 'BATHROBE',
      label: 'Bathrobe',
      iconName: 'bathrobe',
    },
    towel: {
      value: 'TOWEL',
      label: 'Towel',
      iconName: 'towel',
    },
    comforterCover: {
      value: 'COMFORTER_COVER',
      label: 'Comforter Cover',
      iconName: 'comforterCover',
    },
    tablemat: {
      value: 'TABLE_MAT',
      label: 'Table Mat',
      iconName: 'tablemat',
    },
    tableCloth: {
      value: 'TABLE_CLOTH',
      label: 'Table Cloth',
      iconName: 'tableCloth',
    },
    doormat: {
      value: 'DOORMAT',
      label: 'Floor Mat',
      iconName: 'doormat',
    },
    pillow: {
      value: 'PILLOW',
      label: 'Pillow',
      iconName: 'pillow',
    },
    blanketSmall: {
      value: 'BLANKET',
      label: 'Blanket',
      iconName: 'blanketSmall',
    },
    blanketLarge: {
      value: 'BLANKET_LARGE',
      label: 'Blanket Large',
      iconName: 'blanketLarge',
    },
    bedCover: {
      value: 'BEDCOVER',
      label: 'Bed Cover',
      iconName: 'bedCover',
    },
  },
  undergarment: {
    underwear: {
      value: 'UNDERWEAR',
      label: 'Man Underwear',
      iconName: 'underwear',
    },
    socks: {
      value: 'SOCKS',
      label: 'Socks',
      iconName: 'socks',
    },
    bra: {
      value: 'BRA',
      label: 'Bra',
      iconName: 'bra',
    },
  },
  formal: {
    dressBasic: {
      value: 'DRESS_BASIC',
      label: 'Dress Basic',
      iconName: 'dressBasic',
    },
    dressComplex: {
      value: 'DRESS_COMPLEX',
      label: 'Dress Complex',
      iconName: 'dressComplex',
    },
    dressEvening: {
      value: 'DRESS_EVENING',
      label: 'Dress Evening',
      iconName: 'dressEvening',
    },
    suitPants: {
      value: 'SUIT_PANTS',
      label: 'Suits Pant',
      iconName: 'suitPants',
    },
    suitJacket: {
      value: 'SUIT_JACKET',
      label: 'Suit Jacket',
      iconName: 'suitJacket',
    },
    scarf: {
      value: 'SCARF',
      label: 'Scarf',
      iconName: 'scarf',
    },
    tie: {
      value: 'TIE',
      label: 'Tie',
      iconName: 'tie',
    },
    handkerchief: {
      value: 'HANDKERCHIEF',
      label: 'Handkerchief',
      iconName: 'handkerchief',
    },
    vest: {
      value: 'VEST',
      label: 'Vest',
      iconName: 'vest',
    },
    sportSweaterHoodie: {
      value: 'SPORT_SWEATER_HOODIE',
      label: 'Sport Sweater Hoodie',
      iconName: 'sportSweaterHoodie',
    },
    sweaterHoodie: {
      value: 'SWEATER_HOODIE',
      label: 'Sweater Hoodie',
      iconName: 'sweaterHoodie',
    },
    thickSweaterHoodie: {
      value: 'THICK_SWEATER_HOODIE',
      label: 'Thick Sweater Hoodie',
      iconName: 'thickSweaterHoodie',
    },
  },
  other: {
    cap: {
      value: 'CAP',
      label: 'Cap',
      iconName: 'cap',
    },
    jumpsuit: {
      value: 'JUMPSUIT',
      label: 'Jumpsuit',
      iconName: 'jumpsuit',
    },
    belt: {
      value: 'BELT',
      label: 'Belt',
      iconName: 'belt',
    },
    bathingSuit: {
      value: 'BATHING_SUIT',
      label: 'Bathing Suit',
      iconName: 'bathingSuit',
    },
    curtain: {
      value: 'CURTAIN_SQM',
      label: 'Curtain',
      iconName: 'curtain',
    },
    ...COMMON_SERVICE_LINE_TYPES.accessories,
    sofaCoverSmall: {
      value: 'SOFA_COVER_REGULAR',
      label: 'Sofa Cover Small',
      iconName: 'sofaCoverSmall',
    },
    sofaCoverLarge: {
      value: 'SOFA_COVER_LARGE',
      label: 'Sofa Cover Large',
      iconName: 'sofaCoverLarge',
    },
    cushionSmall: {
      value: 'CUSHION_COVER_SMALL',
      label: 'Cushion Small',
      iconName: 'cushionSmall',
    },
    cushionLarge: {
      value: 'CUSHION_COVER_LARGE',
      label: 'Cushion Cover Large',
      iconName: 'cushionLarge',
    },
    carpet: {
      value: 'CARPET_REGULAR_PERSQM',
      label: 'Carpet',
      iconName: 'carpet',
    },
    carpetWool: {
      value: 'CARPET_WOOL',
      label: 'Carpet Wool',
      iconName: 'carpetWool',
    },
    duffelBag: {
      value: 'DUFFEL_BAG',
      label: 'Duffel Bag',
      iconName: 'duffelBag',
    },
    backpack: {
      value: 'BACKPACK',
      label: 'Backpack',
      iconName: 'backpack',
    },
    duvet: {
      value: 'DUVET',
      label: 'Duvet',
      iconName: 'duvet',
    },
    comforter: {
      value: 'COMFORTER',
      label: 'Comforter',
      iconName: 'comforter',
    },
    glove: {
      value: 'GLOVE',
      label: 'Glove',
      iconName: 'glove',
    },
    faceMask: {
      value: 'FACE_MASK',
      label: 'Face Mask',
      iconName: 'faceMask',
    },
    apron: {
      value: 'APRON',
      label: 'Apron',
      iconName: 'apron',
    },
    smallStuffedToy: {
      value: 'STUFFED_TOY_SMALL',
      label: 'Small Stuffed Toy',
      iconName: 'smallStuffedToy',
    },
    mediumStuffedToy: {
      value: 'STUFFED_TOY_MEDIUM',
      label: 'Medium Stuffed Toy',
      iconName: 'mediumStuffedToy',
    },
    largeStuffedToy: {
      value: 'STUFFED_TOY_LARGE',
      label: 'Large Stuffed Toy',
      iconName: 'largeStuffedToy',
    },
    veil: {
      value: 'VEIL',
      label: 'Veil',
      iconName: 'veil',
    },
  },
};

/**
 * Keys marked as `common_category` refer to having the same category name
 * as another mapper, but we can't have it exactly the same name/key, as that would require a lot of backwards compability changes
 */
export const FINERY_EXCLUSIVE_TYPE_MAPPER: ITypeCategories = {
  bridal: {
    bridalDress: {
      value: 'BRIDAL_DRESS',
      label: 'Bridal Dress',
      iconName: 'bridalDress',
    },
  },
  leather: {
    leatherJacket: {
      value: 'LEATHER_JACKET',
      label: 'Leather Jacket',
      iconName: 'leatherJacket',
    },
  },
  formal_common_category: {
    giletJacket: {
      value: 'GILET_JACKET',
      label: 'Gilet Jacket',
      iconName: 'giletJacket',
    },
    casualDress: {
      value: 'CASUAL_DRESS',
      label: 'Casual Dress',
      iconName: 'casualDress',
    },
    formalDress: {
      value: 'FORMAL_DRESS',
      label: 'Formal Dress',
      iconName: 'formalDress',
    },

    eveningGown: {
      value: 'EVENING_GOWN',
      label: 'Evening Gown',
      iconName: 'eveningGown',
    },

    waistCoat: {
      value: 'WAIST_COAT',
      label: 'Waist Coat',
      iconName: 'waistCoat',
    },

    tuxedoPants: {
      value: 'TUXEDO_PANTS',
      label: 'Tuxedo Pants',
      iconName: 'tuxedoPants',
    },

    tuxedoJacket: {
      value: 'TUXEDO_JACKET',
      label: 'Tuxedo Jacket',
      iconName: 'tuxedoJacket',
    },
  },
  top_common_category: {
    sleevelesUndershirt: {
      value: 'SLEEVELES_UNDERSHIRT',
      label: 'Sleeveles Undershirt',
      iconName: 'sleevelesUndershirt',
    },
    pyjamaTop: {
      value: 'PYJAMA_TOP',
      label: 'Pyjama Top',
      iconName: 'pyjamaTop',
    },
    pullover: {
      value: 'PULLOVER',
      label: 'Pullover',
      iconName: 'pullover',
    },
    sweater: {
      value: 'SWEATER',
      label: 'Sweater',
      iconName: 'sweater',
    },
  },
  outerwear_common_category: {
    winterPants: {
      value: 'WINTER_PANTS',
      label: 'Winter Pants',
      iconName: 'winterPants',
    },
    winterCoat: {
      value: 'WINTER_COAT',
      label: 'Winter Coat',
      iconName: 'winterCoat',
    },
  },
  other_common_category: {
    sofaCover: {
      value: 'SOFA_COVER',
      label: 'Sofa Cover',
      iconName: 'sofaCover',
    },
    cushionCover: {
      value: 'CUSHION_COVER',
      label: 'Cushion Cover',
      iconName: 'cushionCover',
    },
    cushion: {
      value: 'CUSHION',
      label: 'Cushion',
      iconName: 'cushion',
    },
    swimmingSuit: {
      value: 'SWIMMING_SUIT',
      label: 'Swimming Suit',
      iconName: 'swimmingSuit',
    },
    shoes: {
      value: 'SHOES',
      label: 'Shoes',
      iconName: 'shoes',
    },
    bags: {
      value: 'BAGS',
      label: 'Bags',
      iconName: 'bags',
    },
  },

  linen_common_category: {
    comforter: {
      value: 'COMFORTER',
      label: 'Comforter',
      iconName: 'comforter',
    },
  },
};

const EXCLUDED_LAUNDRY_TYPES_FROM_FINERY = [
  'undershirt',
  'vest',
  'longDress',
  'delicateAbaya',
  'winterOvercoat',
  'belt',
  'sofaCoverSmall',
  'sofaCoverLarge',
  'faceMask',
  'apron',
  'comforter',
  'smallStuffedToy',
  'mediumStuffedToy',
  'largeStuffedToy',
  'cushionSmall',
  'cushionLarge',
  'carpet',
  'carpetWool',
];

/** Contains finery types and types from other serviceLines, but customized to fit finery logic */
export const FINERY_GENERAL_TYPE_MAPPER: ITypeCategories = {
  top: {
    ...filterCategories(LAUNDRY_EXCLUSIVE_TYPE_MAPPER.top, EXCLUDED_LAUNDRY_TYPES_FROM_FINERY),
    ...FINERY_EXCLUSIVE_TYPE_MAPPER.top_common_category,
  },
  outerwear: {
    ...filterCategories(LAUNDRY_EXCLUSIVE_TYPE_MAPPER.outerwear, EXCLUDED_LAUNDRY_TYPES_FROM_FINERY),
    ...FINERY_EXCLUSIVE_TYPE_MAPPER.outerwear_common_category,
  },
  formal: {
    ...filterCategories(LAUNDRY_EXCLUSIVE_TYPE_MAPPER.formal, EXCLUDED_LAUNDRY_TYPES_FROM_FINERY),
    ...FINERY_EXCLUSIVE_TYPE_MAPPER.formal_common_category,
  },
  other: {
    ...filterCategories(LAUNDRY_EXCLUSIVE_TYPE_MAPPER.other, EXCLUDED_LAUNDRY_TYPES_FROM_FINERY),
    ...FINERY_EXCLUSIVE_TYPE_MAPPER.other_common_category,
  },
  bottom: filterCategories(LAUNDRY_EXCLUSIVE_TYPE_MAPPER.bottom, EXCLUDED_LAUNDRY_TYPES_FROM_FINERY),
  ethnic: filterCategories(LAUNDRY_EXCLUSIVE_TYPE_MAPPER.ethnic, EXCLUDED_LAUNDRY_TYPES_FROM_FINERY),
  linen: {
    ...filterCategories(LAUNDRY_EXCLUSIVE_TYPE_MAPPER.linen, EXCLUDED_LAUNDRY_TYPES_FROM_FINERY),
    ...FINERY_EXCLUSIVE_TYPE_MAPPER.linen_common_category,
  },
  undergarment: filterCategories(LAUNDRY_EXCLUSIVE_TYPE_MAPPER.undergarment, EXCLUDED_LAUNDRY_TYPES_FROM_FINERY),
  bridal: {
    ...FINERY_EXCLUSIVE_TYPE_MAPPER.bridal,
  },
  leather: {
    ...FINERY_EXCLUSIVE_TYPE_MAPPER.leather,
  },
  shoes: categoriesToValues(SHOE_EXCLUSIVE_TYPE_MAPPER),
  bags: categoriesToValues(HAND_BAG_TYPE_MAPPER),
};

export const EXTRA_ITEMS_TYPE_MAPPER: ITypeCategories = {
  extra_items: {
    hangers: {
      value: 'HANGERS',
      label: 'Hangers',
      iconName: 'hangers',
    },
    suitCover: {
      value: 'SUIT_COVER',
      label: 'Suit Cover',
      iconName: 'suitCover',
    },
    shoeTree: {
      value: 'SHOE_TREE',
      label: 'Shoe Tree',
      iconName: 'shoeTree',
    },
    shoeBox: {
      value: 'SHOE_BOX',
      label: 'Shoe Box',
      iconName: 'shoeBox',
    },
    shoeBag: {
      value: 'SHOE_BAG',
      label: 'Shoe Bag',
      iconName: 'shoeBag',
    },
    laundryBag: {
      value: 'LAUNDRY_TRANSPORT_BAG',
      label: 'Laundry Bag',
      iconName: 'laundryBag',
    },
  },
};

export const typeMapper: ITypeCategories = {
  ...LAUNDRY_EXCLUSIVE_TYPE_MAPPER,
  ...SHOE_EXCLUSIVE_TYPE_MAPPER,
  ...FINERY_EXCLUSIVE_TYPE_MAPPER,
  ...HAND_BAG_TYPE_MAPPER,
  ...EXTRA_ITEMS_TYPE_MAPPER,
};
