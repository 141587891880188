import React from 'react';
import { Box, Button } from '@material-ui/core';
import styled from 'styled-components';
import { IUserNote } from 'utils/interfaces';
import AddNoteModal from './components/addNoteModal';
import { CustomDialog } from 'components/dialogs';
import Notes from './components/notesComponent';
import { useNotesModal } from 'modules/stations/stainManStation/hooks/useNotesModal';

interface IProps {
  itemCode: string;
  orderAlphaId: string;
  onClose: () => void;
  isDialog?: boolean;
  userNotes: IUserNote[];
  onAddNote: (description: string, photo: string) => void;
  onDeleteNote: (noteId?: string) => void;
  imageSize?: string;
  title?: string;
}

const UserNotesOverview: React.FC<IProps> = ({
  itemCode,
  orderAlphaId,
  userNotes,
  onAddNote,
  onDeleteNote,
  onClose,
  isDialog = true,
  imageSize,
  title,
}) => {
  const { openNotesModal, closeNotesModal } = useNotesModal('add-notes');

  const goBack = () => closeNotesModal();

  return (
    <>
      {isDialog ? (
        <CustomDialog size="lg" dataCy="userNote.component" open>
          <InnerModal>
            <Notes onClickAddNote={openNotesModal} userNotes={userNotes} onDelete={onDeleteNote} imageSize={imageSize} />
            <ConfirmButton variant="contained" size="large" color="primary" onClick={onClose}>
              Confirm
            </ConfirmButton>
          </InnerModal>
        </CustomDialog>
      ) : (
        <Notes onClickAddNote={openNotesModal} userNotes={userNotes} onDelete={onDeleteNote} isDialog={isDialog} imageSize={imageSize} />
      )}
      <AddNoteModal title={title} modalKey="add-notes" orderAlphaId={orderAlphaId} itemCode={itemCode} onClose={goBack} onNoteTaken={onAddNote} />
    </>
  );
};
const InnerModal = styled(Box)`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const ConfirmButton = styled(Button)`
  margin-top: 1rem;
  width: 16rem;
  align-self: center;
`;
export default UserNotesOverview;
