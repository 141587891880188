import { create } from 'zustand';
import createSelectors from 'utils/helpers/create-selectors';
import { ScanType } from 'services/scanner.service';


export interface Scanner {
  disabled?: boolean;
  type?: ScanType;
  focus?: boolean;
}

interface ScannerStore {
  activeScanners: Map<string, Scanner>;
  focusedScanners: Set<string>;
  addScanner: (scannerId: string, options: Scanner) => void;
  removeScanner: (scannerId: string) => void;
}

const useScannerStoreBase = create<ScannerStore>((set) => ({
  activeScanners: new Map(),
  focusedScanners: new Set(),
  addScanner: (scannerId: string, options: Scanner) => {
    const { type, disabled = false, focus } = options;
    const scanner: Scanner = {
      disabled,
      type,
    };
    set((state) => {
      const activeScanners = new Map(state.activeScanners);
      const focusedScanners = new Set(state.focusedScanners);
      activeScanners.set(scannerId, scanner);
      if (focus) {
        focusedScanners.add(scannerId);
      }
      return { activeScanners, focusedScanners };
    });
  },
  removeScanner: (scannerId: string) => {
    set((state) => {
      const activeScanners = new Map(state.activeScanners);
      const focusedScanners = new Set(state.focusedScanners);
      activeScanners.delete(scannerId);
      focusedScanners.delete(scannerId);
      return { activeScanners, focusedScanners };
    });
  },
}));

export const useScannerStore = createSelectors(useScannerStoreBase);

export const useCurrentScanner = (scannerId: string) => useScannerStore((state) => state.activeScanners.get(scannerId));

// a selector that returns true if a scanner is focused except for the scanner itself
export const useDisableScanner = (scannerId: string) =>
  useScannerStore((state) => {
    const scanner = state.activeScanners.get(scannerId);
    if (!scanner) {
      return false;
    }
    if (scanner.disabled) {
      return true;
    }
    return state.focusedScanners.size > 0 && !state.focusedScanners.has(scannerId);
  });


export const useHasActiveScanner = () =>
  useScannerStore((state) =>
    state.activeScanners.size > 0
  )
