import { useMutation } from 'react-query';
import { ItemIssue } from 'utils/interfaces';
import { StainManService } from 'services';

export interface AddItemIssueRequestParams {
  orderAlphaId: string;
  itemCode: string;
  issueReason: string;
}

const useSubmitNewIssue = (handleOnSuccess: () => void) => {
  return useMutation<{ itemIssue: ItemIssue }, Error, AddItemIssueRequestParams>(
    (options) => {
      const { itemCode, ...rest } = options;
      return StainManService.addItemIssue(itemCode, rest);
    },
    {
      onSuccess: handleOnSuccess,
    }
  );
};
export default useSubmitNewIssue;
