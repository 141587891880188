import { useMutation } from 'react-query';
import stationService from 'services/station.service';
import { IOrderItem } from 'utils/interfaces';

interface UpdateOptions {
  item: IOrderItem;
  managerId?: string;
}
const useUpdateItemRequest = () => {
  return useMutation<IOrderItem, Error, UpdateOptions>(({ item, managerId = '' }) => stationService.updateItem(item, managerId));
};

export default useUpdateItemRequest;
