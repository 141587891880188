import { ITypeMapper, typeMapperHelper } from 'utils/helpers/items';
import { capitalizeString } from 'utils/helpers/string';
import { IOrderItem, Preferences } from 'utils/interfaces';

// Package Detail Interface
export interface IZplStickerObject {
  orderAlphaId: string;
  fullAddress: string;
  pickupDate: string;
  dropOffDate: string;
  addressBldg: string;
  packageSerialId: string;
  itemsList: IOrderItem[];
}

export interface IFoldedBagLabel {
  orderAlphaId: string;
  customerAlphaId: string;
  geofence: string;
  pickupDate: string;
  dropoffDate: string;
  numberOfFoldingItems: number;
  packageCount: string;
}

export interface ISortedBagLabel {
  orderAlphaId: string;
  pickupDate: string;
  itemsCount: number;
  itemNumber: number;
  bagNumber: number;
  customerName: string;
  orderPreferences?: Preferences;
  brandLabel: string;
  size: string;
  colors: string;
  itemCode: string;
  trayCode: number;
  dropoffDate: string;
  isVip: boolean;
}

export interface IDropoffBagLabel {
  orderAlphaId: string;
  dropoffBagCode: string;
  packageCount: number;
  clusterName: string;
  dropoffDate: string;
  dropoffBagCount: string;
  isFoldingDelayed: boolean;
  itemsCount: number;
}

// Garment Position mapping
let garmentPositions = new Map();

garmentPositions.set(0, '420');
garmentPositions.set(1, '450');
garmentPositions.set(2, '480');
garmentPositions.set(3, '510');

const zplStickerMap = {
  orderAlphaId: (value: string) => {
    return `\n^FX Order Alpha ID\n^CF0,40\n^FO30,50^FD${value}^FS`;
  },
  addressBldg: (value: string) => {
    return `\n^FX Address Details\n^CF0,20\n ^FO30,90^FD${value}^FS`;
  },
  fullAddress: (value: string) => {
    return `^FO30, 110^FD${value}^FS\n`;
  },
  geofence: (value: string) => {
    return `\n^FX^FX Geofences ID\n^FO30,140^FD${value}^FS`;
  },
  geofenceSequence: (value: string) => {
    return `\n^FX Geofence Sequence\n^FO30,170^FD${value}^FS`;
  },
  pickupDate: (value: string) => {
    return `^FX PU/DO Informations\n^CF0,20\n^FO30, 215^FDPU: ${value}^FS\n`;
  },
  dropOffDate: (value: string) => {
    return `^FO200, 215^FDDO: ${value}^FS\n\n^FX Divider\n^FO30,240^GB700,1,3^FS\n`;
  }
};

const foldingPackageSerialId = (value: string) => {
  return `^FX QR Codes\n^FO30,250\n^BQN,2,4\n^FDQA,${value}^FS\n^FO150,250\n^BQN,2,4\n^FDQA,${value}^FS\n^FO270,250\n^BQN,2,4\n^FDQA,${value}^FS\n`;
}

const bannedPackageSerialId = (value: string) => {
  return `^FX CODE39 Barcode\n^FO10,50\n^B3N,N,100,Y,N\n^FO50,260^BC^FD${value}^FS\n^FO30,400^GB700,1,3^FS\n`;
}

const bannedPackageItems = (value: IOrderItem[]) => {
  let itemsDisplay = '';

  // eslint-disable-next-line array-callback-return
  value.map((garment, i) => {
    const coord = garmentPositions.get(i);
    itemsDisplay = `${itemsDisplay} \n^FX${garment.code}\n^FO30,${coord}^FD${garment.code}^FS\n^FO230,${coord}^FD${garment.type}^FS\n`;
  });

  return itemsDisplay;
};

const foldingPackageItems = (value: IOrderItem[], packageSerialId: string) => {
  const itemTypes: ITypeMapper = typeMapperHelper(value);
  let itemsString = '';

  Object.keys(itemTypes).forEach((key: string) => {
    itemsString = `${itemsString}${capitalizeString(key)} (x${itemTypes[key].length}), `;
  });

  return `^FX\n
  ^FO30,380^FDPackage ID:^FS\n
  ^FO170,380^FD${packageSerialId}^FS\n
  ^FO30,420^FDItem Count: ^FS\n
  ^FO170,420^FD${value.length}^FS\n

  ^FX
  ^FO30,460^FD${itemsString}^FS`;
};

class Parser {
  private _zplBody: string[] = [];

  get zplInstance(): string {
    return ['^XA', '^LH0,0', ...this.zplBody, '^XZ'].join('\n');
  }
  set zplBody(value: string[]) {
    this._zplBody = [...this.zplBody, ...value];
  }
  get zplBody() {
    return this._zplBody;
  }

  /**
   * @param comment
   * @description adds a comment string to the zpl
   */
  addComment(comment: string) {
    this.zplBody = [`^FX ${comment}`];
  }

  addZplLabel(label: string) {
    this.zplBody = [label];
  }

  generateZplSticker(data: IZplStickerObject, isFolding: boolean = false) {
    Object.entries(data).forEach(([key, value]) => {
      if (zplStickerMap[key as 'orderAlphaId']) {
        const result = zplStickerMap[key as 'orderAlphaId'](value);
        return (this.zplBody = [result]);
      }

      // Slot reserved for itemsList display
      if (key === 'itemsList') {
        const itemsDisplay = isFolding ? foldingPackageItems(value, data.packageSerialId) : bannedPackageItems(value);
        return (this.zplBody = [itemsDisplay]);
      }

      // Slot reserved for packageSerialId
      if (key === 'packageSerialId') {
        const itemsDisplay = isFolding ? foldingPackageSerialId(data.packageSerialId) : bannedPackageSerialId(value);
        return (this.zplBody = [itemsDisplay]);
      }
    });
    return this.zplInstance;
  }

  generateZPLBagSticker(bagCode: string) {
    const generateQRCode = `^FO70,100^BY4,2.0,60^BQN,1,10^FDMM,a${bagCode}^FS`;
    const QrCodeDisplay = `^CF0,30^FO100,450^FD${bagCode}^FS`;
    this.zplBody = [generateQRCode, QrCodeDisplay];

    return this.zplInstance;
  }

  generateFoldedBagLabel(foldedBagSticker: IFoldedBagLabel) {
    const { orderAlphaId, customerAlphaId, geofence, pickupDate, dropoffDate, numberOfFoldingItems, packageCount } = foldedBagSticker;

    const orderId = `^CF0,50\n^FO50,50^FDOrder: ${orderAlphaId}^FS`;
    const customerId = `^CF0,50\n^FO50,150^FDCX ID:  ${customerAlphaId}^FS`;

    const addressType = `^CF0,20\n^FO50,220^FD${geofence}^FS`;

    const pickup = `^CF0,20\n^FO50,300^FD${pickupDate}^FS`;
    const dropoff = `^CF0,20\n^FO50,350^FD${dropoffDate}^FS`;

    const numberOfFoldingItem = `^CF0,20\n^FO50,400^FD# of folding items: ${numberOfFoldingItems}^FS`;
    const packagingCount = `^CF0,20\n^FO50,500^FDPackage: ${packageCount}^FS`;

    this.zplBody = [orderId, customerId, addressType, pickup, dropoff, numberOfFoldingItem, packagingCount];
    return this.zplInstance;
  }

  generateSortedBagLabel(sortedBagSticker: ISortedBagLabel) {
    const {
      orderAlphaId,
      pickupDate,
      bagNumber,
      itemsCount,
      itemNumber,
      brandLabel,
      colors,
      customerName,
      itemCode,
      orderPreferences,
      size,
      trayCode,
      dropoffDate,
      isVip,
    } = sortedBagSticker;

    const toPrint = `
      ^FX Top section with order & bag info
          ^FO25,25^GB360,100,3^FS
          ^FO200,25^GB3,100,3^FS
          ^CF0,45
          ^FO50,60^FD${orderAlphaId}^FS
          ^CF0,45
          ^FO230,60^FDBag^FS
          ^FO310,60^FD${bagNumber}^FS
          ^FO20,130^GB370,3,3^FS


      ^FX Second section with customer info
          ^CF0,20
          ^FO30,140^FD${customerName}^FS
          ${isVip ? '^FO250,140^FDVIP^FS' : ''}
          ^FO30,175^FDPickup Date:^FS
          ^FO150,175^FD${pickupDate}^FS
          ^FO30,205^FDDrop off Date:^FS
          ^FO150,205^FD${dropoffDate}^FS
          ^FO30,235^FDCall Back Required: ^FS
          ^FO200,235^FD${orderPreferences?.isCallCustomerBeforeProcessing ? 'Yes' : 'No'}^FS
          ^FO30,290^FDAuto Approved^FS
          ^FO30,315^FDLight Restoration: ^FS
          ^FO180,315^FD${orderPreferences?.shoeAddOnApprovals?.restoration ? 'Yes' : 'No'}^FS
          ^FO30,335^FDStain Protection: ^FS
          ^FO170,335^FD${orderPreferences?.shoeAddOnApprovals?.stainProtection ? 'Yes' : 'No'}^FS
          ^FO30,355^FDIcing: ^FS
          ^FO80,355^FD${orderPreferences?.shoeAddOnApprovals?.soleIcing ? 'Yes' : 'No'}^FS
          
          ^CFA,15
          ^FO280,205
          ^BQN,2,4
          ^FDQA, ${itemCode}^FS
          ^FO20,390^GB370,3,3^FS


      ^FX Third section with shoe info & shoe QR code.
          ^CF0,20
          ^FO20,400^FD${brandLabel} ^FS
          ^FO120,400^FD${size} ^FS
          ^FO185,400^FD${colors} ^FS
          ^FO30,340
          ^BQN,2,4


      ^FX 4th section with shoe & tray number
          ^FO25,440^GB360,110,3^FS
          ^FO200,440^GB3,110,3^FS
          ^FO20,430^GB370,3,3^FS

          ^CF0,45
          ^FO50,455^FDShoe #^FS
          ^FO230,455^FDTray #^FS
          ^CF0,40
          ^FO50,505^FD${itemNumber}/${itemsCount}^FS
          ^FO260,505^FD${trayCode}^FS
    `;

    this.zplBody = [toPrint];
    return this.zplInstance;
  }

  generateDropoffBagLabel(dropoffBagSticker: IDropoffBagLabel) {
    const { clusterName, dropoffBagCode, dropoffDate, orderAlphaId, packageCount, dropoffBagCount, isFoldingDelayed, itemsCount } = dropoffBagSticker
    const toPrint = `
        ^XA

          ^FX Title
          ^CF0,45
          ^FO100,160^FD${orderAlphaId}^FS
          ^CF0,30^FS
        
          ^FX Body
          ^CF0,25
          ^FO60,235^FDDropoff Date:^FS
          ^FO210,235^FD${dropoffDate}^FS
          ^FO60,265^FDCluster:^FS
          ^FO150,265^FD${clusterName}^FS
          ^FO60,300^FDItem Count:^FS
          ^FO190,300^FD${itemsCount}^FS
          ^FO60,335^FDPackage Count:^FS
          ^FO220,335^FD #${packageCount}^FS
          ^FO60,370^FDDO Bag Count:^FS
          ^FO220,370^FD${dropoffBagCount}^FS
          ^FO60,405^FDDropoff Bag ID:^FS
          ^FO220,405^FD${dropoffBagCode}^FS
          
          ${isFoldingDelayed ? '^CF0,15 ^FO230,130^FDPROCESSING DELAYED^FS' : ''}
          ^FX QR Codes
          ^FO30,30
          ^BQN,2,4
          ^FDQA,${dropoffBagCode}^FS
          ^FO290,30
          ^BQN,2,4
          ^FDQA,${dropoffBagCode}^FS
          ^FO30,440
          ^BQN,2,4
          ^FDQA,${dropoffBagCode}^FS
          ^FO290,440
          ^BQN,2,4
          ^FDQA,${dropoffBagCode}^FS

        ^XZ
    `


    this.zplBody = [toPrint];
    return this.zplInstance;
  }

  clearZplInstance = () => {
    this._zplBody = [];
  };
}

const parser = new Parser();

export default parser;
