import React, { useState, useEffect } from 'react';
import { Box, Theme, Typography, ButtonBase, Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { uniqBy } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { BrandDisplay } from 'components/brandDisplay';
import { WashmenIcon } from 'components/washmenIcon';
import { ColorMapper } from 'components/colorMapper';
import { itemTypeIconMap } from 'utils/maps';
import { getTypeByValue } from 'utils/maps/typeMapper';
import { IOrderItem } from 'utils/interfaces';
import { isItemLinked } from 'utils/helpers/items';
import useSaItems from '../hooks/useSaItems';
import useGetBag from 'modules/stations/shoeSorterStation/queries/useGetBag';

import NoItems from 'assets/images/pngs/noStain.png';
import { ReactComponent as LinkedIcon } from 'assets/images/svgs/specialAttentionSVG/saLinked.svg';
import { ShoeTray } from 'modules/stations/components/shoeTray';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemsList: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      padding: '0 ',
    },
    itemNotSorted: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '.5rem ',
      border: '5px solid transparent',
    },
    itemSorted: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0.2rem',
      border: '5px solid transparent',
    },
    itemSelected: {
      border: '5px solid #3e48a6',
    },
    iconsBox: {
      display: 'flex',
      flex: '2',
      justifyContent: 'center',
      alignItems: 'baseline',
    },
    noItemScanned: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    itemTypeStyle: {
      maxHeight: '3.5rem',
      maxWidth: '4rem',
    },
    qrIconStyle: {
      [theme.breakpoints.down('md')]: {
        width: '2rem !important',
      },
      [theme.breakpoints.only('lg')]: {
        width: '2.5rem !important',
      },
      [theme.breakpoints.up('xl')]: {
        width: '3.5rem !important',
      },
    },
    rfidIconStyle: {
      paddingBottom: '.5rem',
      [theme.breakpoints.down('md')]: {
        width: '2.5rem !important',
      },
      [theme.breakpoints.only('lg')]: {
        width: '3rem !important',
      },
      [theme.breakpoints.up('xl')]: {
        width: '4rem !important',
      },
    },
    emptyIconStyle: {
      [theme.breakpoints.down('md')]: {
        width: '7rem',
      },
      [theme.breakpoints.only('lg')]: {
        width: '10rem',
      },
      [theme.breakpoints.up('xl')]: {
        width: '13rem',
      },
    },
    emptyLabelStyle: {
      [theme.breakpoints.down('md')]: {
        fontSize: '18px',
      },
      [theme.breakpoints.only('lg')]: {
        fontSize: '28px',
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '40px',
      },
    },
  })
);

// component to render if item is not sorted
export const NonSortedItem: React.FC<{ item: IOrderItem; index: number }> = ({ item, index }) => {
  const classes = useStyles();

  const { data: bag } = useGetBag();
  const { specialAttentionItemsList } = useSaItems(item, { bag });
  const isLinked = isItemLinked(specialAttentionItemsList, item.code);
  return (
    <>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <Grid item xs={5}>
          <Typography variant="h4">Item {index + 1}</Typography>
        </Grid>
        <Grid container item xs={5} direction="row" justifyContent="space-around" alignItems="center">
          <WashmenIcon name="qrCode" iconClassName={classes.qrIconStyle} active={!!item.code} />
          <WashmenIcon name="rfid" iconClassName={classes.rfidIconStyle} active={!!item.rfid} />
          {item.tray?.trayCode && (
            <Grid item xs={3}>
              <ShoeTray trayCode={item.tray.trayCode} />
            </Grid>
          )}
        </Grid>
        {isLinked && (
          <Grid container item xs={2} justifyContent="flex-end">
            <LinkedIcon className={classes.qrIconStyle} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

// component to render if item is sorted
export const SortedItem: React.FC<{ item: IOrderItem }> = ({ item }) => {
  const classes = useStyles();
  // Get the type Obj
  const { typeObj } = getTypeByValue(item.type);
  const { data: bag } = useGetBag();
  //Item flow reads SA items from the item, while the bag flow reads it from bag, but this component is used on both
  const { specialAttentionItemsList } = useSaItems(item, {
    bag: {
      ...bag,
      order: { ...bag?.order, specialAttentionItemsArray: bag.order.specialAttentionItemsArray ?? item.specialAttentionItemsArray ?? [] },
    },
  });
  const isLinked = isItemLinked(specialAttentionItemsList, item.code);
  return (
    <Grid wrap="wrap" container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={2}>
        <img src={itemTypeIconMap[typeObj.value!]} alt="" className={classes.itemTypeStyle} />
      </Grid>
      <Grid item xs={2}>
        <BrandDisplay brand={item.brand!} isHorizontal isTextHidden />
      </Grid>
      <Grid container item xs={4} justifyContent="flex-start">
        <ColorMapper colorsList={item.colorsList!} textHidden />
      </Grid>

      {item.tray?.trayCode && (
        <Grid item xs={2}>
          <ShoeTray trayCode={item.tray.trayCode} />
        </Grid>
      )}
      {isLinked && (
        <Grid container item xs={2} justifyContent="flex-end">
          <LinkedIcon className={classes.qrIconStyle} />
        </Grid>
      )}
    </Grid>
  );
};

interface Props {
  currentItem: IOrderItem;
}

const ItemsList: React.FC<Props> = ({ currentItem }) => {
  const { items } = useGetBag();
  const navigate = useNavigate();

  const getSortedItems = (orderItemList: IOrderItem[]) => {
    return (orderItemList || []).slice().sort((a, b) => {
      if (a.isSorted === true && b.isSorted === false) {
        return 1;
      }
      if (b.isSorted === true && a.isSorted === false) {
        return -1;
      }
      if (!a.isSorted && b.isSorted) {
        return -1;
      }
      return 0;
    });
  };

  const sortedItems = getSortedItems(items);
  const nonSortedItems = sortedItems.filter((item) => !item.isSorted);

  const [itemRef, setItemRef] = useState<HTMLDivElement>({} as HTMLDivElement);
  const classes = useStyles();

  const onItemSelect = (item: IOrderItem) => {
    let selectedItemUrl = '';
    if (item.code && item.rfid) {
      selectedItemUrl = `item/${item.code}/${item.rfid}`;
    } else if (item.code) {
      selectedItemUrl = `item/${item.code}`;
    }
    navigate(selectedItemUrl, { replace: true });
  };

  useEffect(() => {
    if (itemRef && itemRef.childNodes) {
      itemRef.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentItem, itemRef]);

  return (
    <Box className={classes.itemsList} flexGrow={1} height="0px">
      <Box flex="1" className={classes.noItemScanned} style={{ display: sortedItems.length === 0 ? 'flex' : 'none' }}>
        <img src={NoItems} alt="No Items" className={classes.emptyIconStyle} />
        <Box alignItems="center" marginTop="2rem">
          <Typography variant="h5" className={classes.emptyLabelStyle}>
            No scanned Items
          </Typography>
        </Box>
      </Box>
      {uniqBy(sortedItems, 'code').map((item) => {
        const actualIndex = nonSortedItems.findIndex((i) => i.code === item.code);
        return (
          <ButtonBase
            component="div"
            className={classNames({
              [classes.itemSorted]: item.isSorted,
              [classes.itemNotSorted]: !item.isSorted,
              [classes.itemSelected]: item.code === currentItem.code,
            })}
            key={item.code}
            ref={(e: HTMLDivElement) => (item.code === currentItem.code ? setItemRef(e) : '')}
            disabled={sortedItems.findIndex((item) => item.isSorted === false) > -1 && !!currentItem.code}
            onClick={() => onItemSelect(item)}
          >
            {item.isSorted ? <SortedItem item={item} /> : <NonSortedItem index={actualIndex} item={item} />}
          </ButtonBase>
        );
      })}
    </Box>
  );
};
export default ItemsList;
