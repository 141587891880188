import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Typography, IconButton, Dialog } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

import { useStationStore } from 'stores';
import styled from 'styled-components';
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      textAlign: 'center',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose?: () => void;
}

interface ICustomDialog {
  open: boolean;
  size?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  title?: string;
  classes?: any;
  disableAutoFocus?: boolean;
  onClose?: (event?: object, reason?: string) => void;
  dataCy?: string;
  incrementedNumber?: number;
  custom_size?: { width: string; height: string };
  titleStyles?: string;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const CustomDialog: React.FC<React.PropsWithChildren<ICustomDialog>> = ({
  children,
  open,
  onClose,
  size = 'md',
  title = '',
  classes = {},
  disableAutoFocus = false,
  dataCy = '',
  custom_size,
  titleStyles = '',
}) => {
  const { focusInput$ } = useStationStore();
  const handleClose = (event?: object, reason?: string) => {
    if (onClose) {
      onClose(event, reason);
      focusInput$.set(true);
    }
  };

  return (
    <div>
      <StyledDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={size}
        classes={classes}
        disableRestoreFocus={true}
        keepMounted={false}
        disableAutoFocus={disableAutoFocus}
        disableEnforceFocus={disableAutoFocus}
        data-cy={dataCy}
        custom_size={custom_size}
        title_styles={titleStyles}
      >
        {title && (
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {title}
          </DialogTitle>
        )}
        {children}
      </StyledDialog>
    </div>
  );
};

export default CustomDialog;

const StyledDialog = styled(Dialog)<{ custom_size?: { width: string; height: string }; title_styles?: string }>`
  ${({ custom_size }) =>
    custom_size &&
    `  .MuiDialog-paperFullWidth {
  width: ${custom_size.width};
  height: ${custom_size.height};
}`}
  .MuiDialogTitle-root {
    ${({ title_styles }) => title_styles}
  }
`;
