import { AxiosRequestConfig } from 'axios';
import { QueryFilters } from 'modules/stations/pendingItemsStation/hooks/useGetOrdersWithItemsList';
import Axious from './api';

export interface DelayOptions {
  orderId: string;
  itemsCodesList: string[];
  delayDays: number;
  delayReason: string;
  isDelayed: boolean;
  packageId?: string;
  isItemsLastActivityNotPackagedFilter?: boolean;
  type?: 'Pending' | 'Damaged'
}

class PendingService {
  /**
   * Fetch the list of pending items on Dispatch Pending Station
   *
   * @return {Object} ordersItemsList
   */
  public async fetchPendingItems(filters?: QueryFilters) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/items/pending`,
      params: { ...filters },
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }

  /**
   * Mark the item as Delay Answered
   *
   * @return {Object} ordersItemsList
   */
  public async markItemAsDelayAnswered({

    orderId,
    itemsCodesList,
    delayDays,
    delayReason,
    isDelayed,
    packageId,
    isItemsLastActivityNotPackagedFilter, type
  }: DelayOptions) {
    const d = new Date();
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/orders/${orderId}/items/is-delayed/${isDelayed}`,
      data: {
        reportedDelayTimestamp: d.getTime(),
        itemsCodesList,
        delayDays,
        delayReason,
        packageId,
        isItemsLastActivityNotPackagedFilter,
        delayType: type
      },
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }
  /**
   *
   * Get items by item/package scan
   *
   * @return {Object} itemsList
   */
  public async scanItemsOnPending(itemIdentifier?: string, packageId?: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/items/pending-station/scan`,
      params: {
        itemIdentifier,
        packageId,
      },
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }

  /**
   *
   * Get item activities
   *
   * @return {Array} itemActivities
   */
  public async getItemActivities(itemCode: string, orderAlphaId: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/item/${itemCode}/${orderAlphaId}/journey-activities`,
      params: {},
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }
}

const pendingService = new PendingService();

// export as singleton
export default pendingService;
