import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { Box, Grid } from '@material-ui/core';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import NoImage from 'assets/images/pngs/noImage.png';
import { IOrderItem, OrderServiceLines } from 'utils/interfaces';

interface Props {
  groupItemsList: { itemCode: string; frontImage: string }[];
  removeItemFromGroup: (item: { itemCode: string; frontImage: string }) => void;
  handleRemoveNewGroup: () => void;
  currentItem: IOrderItem;
  serviceLine?: OrderServiceLines;
}

const RightPanelNewGroup: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ groupItemsList, removeItemFromGroup, handleRemoveNewGroup, currentItem, serviceLine = OrderServiceLines.LAUNDRY }) => {
    return (
      <Grid container direction="row" alignItems="center">
        <Grid xs={'auto'}>
          <CustomBox display={'flex'}>
            {groupItemsList.map((item, i) => {
              const { code } = currentItem;
              if (code === item.itemCode) {
                item.frontImage =
                  (serviceLine === OrderServiceLines.SHOES
                    ? currentItem.itemImagesMap?.top
                    : currentItem.frontImage || currentItem.itemImagesMap?.front) || '';
              }
              return (
                <Grid item xs={'auto'} key={`${i}-${item.itemCode}-item-holder`}>
                  <ImageHolderBox>
                    <ItemRemoveButton fontSize="large" color="error" onClick={() => removeItemFromGroup(item)} style={{ alignSelf: 'flex-start' }} />
                    <GroupItemImage src={item.frontImage || NoImage} width="300px" height="300px" alt="Washmen" />
                  </ImageHolderBox>
                </Grid>
              );
            })}
            {groupItemsList.length < 3 &&
              Array.from({ length: 3 - groupItemsList.length }).map((index) => (
                <Grid item xs={'auto'} key={`${index}-empty-holder`}>
                  <ImageHolderBox></ImageHolderBox>
                </Grid>
              ))}
          </CustomBox>
        </Grid>
        <IconGrid xs={'auto'}>
          <RemoveCircleOutlineIcon
            fontSize="large"
            color="error"
            onClick={() => groupItemsList.length && handleRemoveNewGroup()}
            style={{ alignSelf: 'flex-start' }}
          />
        </IconGrid>
      </Grid>
    );
  }
);

export default RightPanelNewGroup;

const ImageHolderBox = styled(Box)`
  border: 2px solid #3e48a6;
  border-style: dashed;
  border-radius: 6px;
  width: 15rem;
  height: 12.6rem;
  padding: 10px;
  background: #ffffff;
  position: relative;
`;

const GroupItemImage = styled.img`
  max-width: 240px;
  max-height: 202px;
`;

const ItemRemoveButton = styled(RemoveCircleOutlineIcon)`
  position: absolute;
  margin-left: 12.8rem;
`;

const CustomBox = styled(Box)`
  border: 0.2rem solid #3e48a6;
  border-style: dashed;
  border-radius: 6px;
  background: #00b8ad14;
  padding: 5px;
  overflow: scroll;

  height: 34rem;
  max-width: 52rem;
  gap: 1rem;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 1rem;
`;

const IconGrid = styled(Grid)`
  align-self: baseline;
`;
