import React from 'react';
import styled from 'styled-components';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Box, Button, Tab, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { IUserNote } from 'utils/interfaces';
import NotesList from './notesList';

import { ReactComponent as ItemIcon } from 'assets/images/svgs/photo-notes.svg';

interface IProps {
  userNotes: IUserNote[];
  onDelete: (noteId: string | undefined) => void;
  isDialog?: boolean;
  onClickAddNote: () => void;
  imageSize?: string;
}

type TabValues = 'comments' | 'deleted';

const Notes: React.FC<IProps> = ({ userNotes, onDelete, isDialog = true, onClickAddNote, imageSize }) => {
  const [tabValue, setTabValue] = React.useState<TabValues>('comments');

  const handleChange = (event: React.ChangeEvent<{}>, value: TabValues) => setTabValue(value);

  return (
    <Box>
      <CustomBox flexDirection='column' width='%100'>
        <CustomBox flex={3} sx={{ alignSelf: isDialog ? 'center' : undefined }}
                   style={{ gap: '1rem', alignItems: 'center' }}>
          <ItemIcon width='4.25rem' height='4.25rem' />
          <Text>Photos & Notes</Text>
        </CustomBox>
        <CustomBox flex={1} onClick={onClickAddNote} alignSelf='end' marginBottom='1rem'>
          <Button variant='text' color='primary' startIcon={<AddIcon />}>
            ADD
          </Button>
        </CustomBox>
      </CustomBox>
      <TabBox flex={5} flexDirection='column'>
        <TabContext value={tabValue}>
          <Box>
            <TabList onChange={handleChange} indicatorColor='primary' textColor='primary' variant='fullWidth'>
              <Tab label='Comments' value='comments' />
              <Tab label='Deleted' value='deleted' />
            </TabList>
          </Box>
          <Scrollable>
            <TabPanel value='comments'>
              <NotesList notesList={userNotes.filter((note) => !note.isRemoved)} onDelete={onDelete}
                         imageSize={imageSize} />
            </TabPanel>
            <TabPanel value='deleted'>
              <NotesList imageSize={imageSize} notesList={userNotes.filter((note) => note.isRemoved)} />
            </TabPanel>
          </Scrollable>
        </TabContext>
      </TabBox>
    </Box>
  );
};

const TabBox = styled(Box)`
  display: flex;
  border: solid 2px #b6bcd1;

  .MuiTabPanel-root {
    padding: 0;
  }

  .MuiListItemText-root {
    margin-left: 1rem;
  }
`;
const CustomBox = styled(Box)`
  display: flex;
`;
const Text = styled(Typography)`
  font-size: 2.5rem;
  font-weight: lighter;
  text-align: center;
`;
const Scrollable = styled.div`
  height: 20rem;
  overflow-y: auto;
`;

export default Notes;
