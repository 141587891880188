import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide, { SlideProps } from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { makeStyles, Theme, createStyles, Box } from '@material-ui/core';
import { useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import lodash from 'lodash';

import {
  SelectBrandComponent,
  SelectTypeComponent,
  SelectColorComponent,
  SelectProgramComponent,
  SelectPhotoComponent,
  StainDamageComponent,
} from './dialogs';

import { useStationStore } from 'stores';
import { SaDialog } from 'modules/stations/components/saDialog';
import { WashmenIcon } from 'components/washmenIcon';
import { InstructionPopover } from './instructionPopover';
import { Toaster } from 'components/toaster';

/** Import Icon's SVG */
import { ReactComponent as TypeIcon } from 'assets/images/svgs/sorterModal/type-black.svg';
import { ReactComponent as BrandIcon } from 'assets/images/svgs/sorterModal/brand-black.svg';
import { ReactComponent as ColorIcon } from 'assets/images/svgs/sorterModal/colour-black.svg';
import { ReactComponent as PhotoIcon } from 'assets/images/svgs/sorterModal/photo-black.svg';
import { ReactComponent as StainIcon } from 'assets/images/svgs/sorterModal/staindamage-black.svg';
import { ReactComponent as ProgramIcon } from 'assets/images/svgs/sorterModal/program-black.svg';
import AlertIcon from 'assets/images/pngs/alert.png';
import { SaDialogTypes } from 'utils/enums/stationEnums';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      overflow: 'hidden',
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    header: {
      display: 'flex',
      borderBottom: 'solid 2px #b6bcd1',
      padding: '2rem',
      alignItems: 'center',
    },

    icon: {
      width: '40px',
      height: '40px',
      marginRight: '20px',
    },
    vipLogo: {
      height: 'auto',
      width: '4vw',
    },
    titleIcon: {
      width: '2rem',
      paddingRight: '1rem',
    },
  })
);

interface IHeaderIconMapper {
  [key: string]: JSX.Element;
}

const headerIconMapper: IHeaderIconMapper = {
  type: <TypeIcon />,
  brand: <BrandIcon />,
  color: <ColorIcon />,
  programs: <ProgramIcon />,
  photo: <PhotoIcon />,
  stainDamage: <StainIcon />,
};

export interface routeProps extends Record<string, string | undefined> {
  bagCode: string;
  dialogType: 'type' | 'brand' | 'color' | 'programs' | 'photo' | 'stainDamage' | 'scanner';
  qrCode: string;
  rfid: string;
}

interface Props {
  onClose: () => void;
  open: boolean;
  autoSave?: boolean;
  noManagerRequired?: boolean;
}

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...(props as SlideProps)} />;
});

const SorterDialog: React.FC<Props> = observer(({ autoSave = false, noManagerRequired = false, onClose, open }) => {
  const params = useParams<routeProps>();
  const location = useLocation();
  const classes = useStyles({});
  const {
    currentItem,
    bag,
    hasSpecialAttentionItems,
    linkedItem,
    specialItemsInBag,
    specialAttentionItemsList,
    linkSpecialAttentionItemToBag,
    unlinkSpecialAttentionItemFromBag,
    unlinkItemToSpecialAttentionItem,
    linkItemToSpecialAttentionItem,
  } = useStationStore();

  const [isSpecialAttentionModalOpen, setIsSpecialAttentionModalOpen] = useState(false);
  // Manipulate SVG with the isActive Flag
  let IconElement: JSX.Element = <></>;
  if (params.dialogType) {
    IconElement = headerIconMapper[params.dialogType];
    IconElement = React.cloneElement(IconElement, {
      className: classes.icon,
    });
  }

  const onCloseHandler = () => {
    if (params.dialogType === 'photo' && linkedItem && linkedItem.stainsArray && linkedItem.stainsArray.length > 0) {
      return toast(<Toaster message="Please add a stain" type="error" />);
    }

    onClose();
  };

  const closeOnStainDialog = location.pathname.split('/').slice(-1).pop() !== 'overView';

  const hideCloseIcon =
    (params.dialogType === 'photo' || closeOnStainDialog) && linkedItem && linkedItem.stainsArray && linkedItem.stainsArray.length > 0;

  return (
    <div>
      <Dialog
        classes={{ paper: classes.dialog }}
        fullScreen
        open={open}
        onClose={onCloseHandler}
        TransitionComponent={Transition}
        disableRestoreFocus={true}
        scroll="paper"
      >
        <Box className={classes.header}>
          {IconElement}
          <Box>
            <Typography variant="h4">{lodash.capitalize(params.dialogType)}</Typography>
          </Box>

          <Box flexGrow={1} justifyContent="flex-end" display="flex">
            {hasSpecialAttentionItems && (
              <Box display="flex" alignItems="center" justifyContent="center" onClick={() => setIsSpecialAttentionModalOpen(true)}>
                <img src={AlertIcon} alt="warning" className={classes.titleIcon} />
                <Typography variant="h6" display="initial" align="center" style={{ color: '#ca3232' }}>
                  Customer Special Request
                </Typography>
              </Box>
            )}
            {bag.isCustomerVip ? <WashmenIcon name="vipIcon" svg iconClassName={classes.vipLogo} isButton={false} /> : ''}
            {currentItem.isReclean && (
              <Box display="flex">
                <InstructionPopover isCustomerComplains />
              </Box>
            )}
            <Box display="flex">
              <InstructionPopover />
            </Box>
            {!hideCloseIcon && (
              <IconButton edge="start" color="inherit" onClick={onCloseHandler} aria-label="close">
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        </Box>

        {renderDialog(autoSave, noManagerRequired, params.dialogType)}
      </Dialog>

      {/* Special Attention Dialog */}
      <SaDialog
        isOpen={isSpecialAttentionModalOpen}
        onClose={() => setIsSpecialAttentionModalOpen(false)}
        type={SaDialogTypes.sorterViewOnly}
        hasSpecialAttentionItems={hasSpecialAttentionItems}
        bag={bag}
        linkedItem={linkedItem}
        specialItemsInBag={specialItemsInBag}
        specialAttentionItemsList={specialAttentionItemsList}
        linkSpecialAttentionItemToBag={linkSpecialAttentionItemToBag}
        unlinkSpecialAttentionItemFromBag={unlinkSpecialAttentionItemFromBag}
        unlinkItemToSpecialAttentionItem={unlinkItemToSpecialAttentionItem}
        linkItemToSpecialAttentionItem={linkItemToSpecialAttentionItem}
      />
    </div>
  );
});

export default SorterDialog;

const renderDialog = (autoSave: boolean, noManagerRequired: boolean, dialogType?: routeProps['dialogType']) => {
  switch (dialogType) {
    case 'type': {
      return <SelectTypeComponent />;
    }
    case 'brand': {
      return <SelectBrandComponent />;
    }
    case 'color': {
      return <SelectColorComponent />;
    }
    case 'stainDamage': {
      return <StainDamageComponent autoSave={autoSave} />;
    }
    case 'programs': {
      return <SelectProgramComponent autoSave={autoSave} noManagerRequired={noManagerRequired} />;
    }
    case 'photo': {
      return <SelectPhotoComponent />;
    }
  }
};
