import { FilterDefaultValue, FilterOptions } from '../../../../../components/filters';
import { ReactComponent as Vip } from 'assets/images/svgs/vip.svg';
import { ReactComponent as DelicateIcon } from 'assets/images/svgs/delicateitem-b.svg';
import React from 'react';

const laundryModalFilters: FilterOptions[] = [
  {
    filterKey: 'orderAlphaIdsListFilter',
    title: 'Order ID',
    type: 'autoComplete',
    isMultipleSelection: true,
    creatable: true,
    rulePattern: /^([a-zA-Z]{2}|[a-zA-Z]{3})[0-9]{3}$/,
    filterOptions: [],
    defaultValue: [],
    dependents: [{ key: 'isPackagedFilter', setValue: '' }],
    xsValue: 3,
  },
  {
    filterKey: 'itemTypesListFilter',
    title: 'Item Type',
    type: 'select',
    isMultipleSelection: true,
    filterOptions: [],
    defaultValue: [],
    xsValue: 3,
  },

  {
    filterKey: 'orderStatusListFilter',
    title: 'Order Status',
    type: 'select',
    isMultipleSelection: true,
    filterOptions: [
      { label: 'Complete', value: 'ORDER_COMPLETE' },
      { label: 'Pending', value: 'ORDER_PENDING' },
      { label: 'Dropoff Pending', value: 'ORDER_DROPOFF_PENDING' },
    ],
    defaultValue: ['ORDER_PENDING', 'ORDER_DROPOFF_PENDING'],
    xsValue: 3,
  },
  {
    filterKey: 'isVipFilter',
    title: 'VIP',
    type: 'select',
    hideDisplayTitle: true,
    xsValue: 3,
    filterOptions: [
      { label: 'All', value: '' },
      { label: 'Yes', value: 'true', displayIcon: <Vip width="3.125rem" height="3.125rem" /> },
      { label: 'No', value: 'false', displayIcon: false },
    ],
    defaultValue: '',
  },
  {
    filterKey: 'isDelicateFilter',
    title: 'Delicate',
    type: 'select',
    hideDisplayTitle: true,
    defaultValue: '',
    xsValue: 3,

    filterOptions: [
      { label: 'All', value: '' },
      { label: 'Yes', value: 'true', displayIcon: <DelicateIcon width="3.125rem" height="3.125rem" /> },
      { label: 'No', value: 'false', displayIcon: false },
    ],
  },
];

const laundryDefaultModalValues = (() => {
  const obj: { [key in string]?: FilterDefaultValue } = {};
  laundryModalFilters.forEach((filter) => {
    obj[filter.filterKey] = filter.defaultValue;
  });
  return obj;
})();

export { laundryModalFilters, laundryDefaultModalValues };
