import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { CustomDialog } from 'components/dialogs';
import styled from 'styled-components';
import { ReactComponent as GreenCheckMark } from 'assets/images/checkgreen.svg';
interface Props {
  onClose: () => void;
  open: boolean;
  dropoffDate: string;
  orderAlphaId: string;
  customerAlphaId: string;
}

const OrderCompleteDialog: React.FC<Props> = ({ onClose, open, dropoffDate, orderAlphaId, customerAlphaId }) => {
  return (
    <CustomDialog open={open} onClose={onClose}>
      <Wrapper container direction="row" justifyContent="center">
        <Grid container direction="column" item xs={6} wrap="nowrap">
          <Grid item xs>
            <Title variant="h4">
              Order Id: <b>{orderAlphaId}</b>
            </Title>
          </Grid>
          <Grid item xs>
            <Title variant="h4">
              Customer Id: <b>{customerAlphaId}</b>
            </Title>
          </Grid>
        </Grid>
        <Grid alignItems={'flex-end'} container direction="column" item xs={6} wrap="nowrap">
          <Grid item xs>
            <Title variant="h4">
              Drop off day: <b>{moment(dropoffDate).format('dddd, MMM D')}</b>
            </Title>
          </Grid>
        </Grid>
        <Wrapper spacing={2} container xs={12} item direction="column" justifyContent="center" alignItems="center" alignContent="center">
          <Grid spacing={2} direction="column" container item xs={6}>
            <Grid item xs>
              <Title variant="h3" align={'center'} custom_color={'#19a784'}>
                Order completed
              </Title>
            </Grid>
            <IconWrapper item xs>
              <GreenCheckMark width={'13.4rem'} height={'13.4rem'} />
            </IconWrapper>
          </Grid>
          <Grid item xs>
            <Button onClick={onClose} variant="contained" size="large" color="primary">
              Done
            </Button>
          </Grid>
        </Wrapper>
      </Wrapper>
    </CustomDialog>
  );
};

export default OrderCompleteDialog;

const Title = styled(Typography)<{ custom_color?: string }>`
  font-weight: 300;
  ${({ custom_color }) => custom_color && `color:${custom_color};`}
`;
const Wrapper = styled(Grid)`
  padding: 1.5rem;
`;
const IconWrapper = styled(Grid)`
  align-self: center;
`;
