import { useIsFetching, useIsMutating } from 'react-query';

const useIsRQLoading = () => {
  const isLoading = !!useIsFetching({ predicate: (query) => query.state.status === 'loading' });
  const isBackgroundLoading = !!useIsFetching();

  const isMutating = !!useIsMutating();

  return { isLoading, isMutating, isBackgroundLoading };
};

export default useIsRQLoading;
