import React, { Suspense, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useStationStore } from 'stores';
import { OrderCompletedDialog } from 'components/dialogs';
import { StationTypes } from 'utils/enums/stationEnums';
import { IOrderItem, OrderServiceLines } from 'utils/interfaces';
import FineryStainManStation from './stainManStation/fineryStainmanStation';

// lazy import stations
const SorterStation = React.lazy(() => import('./sorterStation/sorterStation'));
const FoldingStation = React.lazy(() => import('./foldingStation/foldingStation'));
const TaggerStation = React.lazy(() => import('./taggerStation/taggerStation'));
const PressingStation = React.lazy(() => import('./pressingStation/pressingStation'));
const PackagingStation = React.lazy(() => import('./packagingStation/packagingStation'));
const WasherStation = React.lazy(() => import('./washerStation/washerStation'));
const ScannerStation = React.lazy(() => import('./scannerStation/scannerStation'));
const GrouperStation = React.lazy(() => import('./groupingStation/groupingStation'));
const RackingStation = React.lazy(() => import('./rackingStation/rackingStation'));
const PendingItemsStation = React.lazy(() => import('./pendingItemsStation/pendingItemsStation'));
const LaundryStainManStation = React.lazy(() => import('./stainManStation/laundryStainManStation'));
const ShoeStainManStation = React.lazy(() => import('./stainManStation/shoeStainManStation'));
const ShoeSorterStation = React.lazy(() => import('./shoeSorterStation/shoeSorterStation'));
const ShoeBeforeAfterStation = React.lazy(() => import('./shoeBeforeAfterStation/shoeBeforeAfterStation'));
const ShoesPendingItemsStation = React.lazy(() => import('./shoesPendingItemsStation/shoesPendingItemsStation'));
const ShoeSorterAssessmentStation = React.lazy(() => import('./shoesSorterAssessmentStation/shoesSorterAssessmentStation'));
const DispatchStationCP = React.lazy(() => import('./dispatch/dispatchStationCP'));
const DispatchStationWF = React.lazy(() => import('./dispatch/dispatchStationWF'));
const DispatchStationShoes = React.lazy(() => import('./dispatch/dispatchStationShoes'));
const FinerySorterStation = React.lazy(() => import('./finerySorterStation/finerySorterStation'));
const FineryReceivingStation = React.lazy(() => import('./receivingStation/receivingStationFinery'));
const ShoesReceivingStation = React.lazy(() => import('./receivingStation/receivingStationShoes'));
const WfReceivingStation = React.lazy(() => import('./receivingStation/receivingStationWF'));
const ReceivingStation = React.lazy(() => import('./receivingStation/receivingStation'));

//  ========== ADD STATION ROUTES HERE ==============

const Stations: React.FC = observer(() => {
  const { stationType, currentStation, setOrderItem } = useStationStore();
  let [showOrderCompleteDialog, setShowOrderCompleteDialog] = useState(true);
  useEffect(() => {
    if (currentStation === 'scanner') {
      setShowOrderCompleteDialog(false);
    }
  }, [currentStation]);

  const onOrderCompleteDialogCloseHandler = () => {
    if (currentStation === 'washer') {
      setOrderItem({} as IOrderItem);
    }
  };

  return (
    <>
      <Suspense fallback={<div>Loading station...</div>}>
        <Routes>
          <Route path={`${StationTypes.Folding}/*`} element={<FoldingStation />} />
          <Route path={`${StationTypes.FoldingLinen}/*`} element={<FoldingStation />} />
          <Route path={`${StationTypes.Tagger}/*`} element={<TaggerStation />} />
          <Route path={`${StationTypes.WfTagger}/*`} element={<TaggerStation />} />
          <Route path={`${StationTypes.ShirtPressing}/*`} element={<PressingStation />} />
          <Route path={`${StationTypes.MultiPressing}/*`} element={<PressingStation />} />
          <Route path={`${StationTypes.PantPressing}/*`} element={<PressingStation />} />
          <Route path={`${StationTypes.UtilityPressing}/*`} element={<PressingStation />} />
          <Route path={`${StationTypes.FineryPressing}/*`} element={<PressingStation />} />
          <Route path={`${StationTypes.FineryTailorPost}/*`} element={<PressingStation />} />
          <Route path={`${StationTypes.FineryTailorPre}/*`} element={<PressingStation />} />
          <Route path={`${StationTypes.FinerySpotter}/*`} element={<WasherStation serviceLine={OrderServiceLines.FINERY} />} />
          <Route path={`${StationTypes.FineryCleaning}/*`} element={<PressingStation />} />
          <Route path={`${StationTypes.FineryDrying}/*`} element={<PressingStation />} />
          <Route path={`${StationTypes.CollarAndCuffPressing}/*`} element={<PressingStation />} />
          <Route path={`${StationTypes.PantsTopper}/*`} element={<PressingStation />} />
          <Route path={`${StationTypes.FlatIroner}/*`} element={<PressingStation />} />
          <Route path={`${StationTypes.Sorter}/*`} element={<SorterStation />} />
          <Route path={`${StationTypes.WFSorter}/*`} element={<SorterStation />} />
          <Route path={`${StationTypes.ShoesSorter}/*`} element={<ShoeSorterStation />} />
          <Route path={`${StationTypes.FinerySorter}/*`} element={<FinerySorterStation />} />
          <Route path={`${StationTypes.ShoesBeforeAfter}/*`} element={<ShoeBeforeAfterStation />} />
          <Route path={`${StationTypes.Scanner}/*`} element={<ScannerStation />} />
          <Route path={`${StationTypes.BannedPackaging}/*`} element={<PackagingStation />} />
          <Route path={`${StationTypes.FineryBannedPackaging}/*`} element={<PackagingStation />} />
          <Route path={`${StationTypes.Washer}/*`} element={<WasherStation />} />
          <Route path={`${StationTypes.HandWash}/*`} element={<WasherStation />} />
          <Route path={`${StationTypes.Grouping}/*`} element={<GrouperStation />} />
          <Route path={`${StationTypes.FineryGrouping}/*`} element={<GrouperStation />} />
          <Route path={`${StationTypes.Racking}/*`} element={<RackingStation />} />
          <Route path={`${StationTypes.PendingItems}/*`} element={<PendingItemsStation />} />
          <Route path={`${StationTypes.QualityControl}/*`} element={<LaundryStainManStation />} />
          <Route path={`${StationTypes.Spotter}/*`} element={<WasherStation />} />
          <Route path={`${StationTypes.PreSpotter}/*`} element={<WasherStation />} />
          <Route path={`${StationTypes.PostSpotter}/*`} element={<WasherStation />} />
          <Route path={`${StationTypes.WfWasher}/*`} element={<WasherStation />} />
          <Route path={`${StationTypes.Tailor}/*`} element={<WasherStation />} />
          <Route path={`${StationTypes.StainMan}/*`} element={<LaundryStainManStation />} />
          <Route path={`${StationTypes.ShoesTagger}/*`} element={<TaggerStation />} />
          <Route path={`${StationTypes.FineryTagger}/*`} element={<TaggerStation />} />
          <Route path={`${StationTypes.ShoesFolding}/*`} element={<FoldingStation />} />
          <Route path={`${StationTypes.FineryFolding}/*`} element={<FoldingStation />} />
          <Route path={`${StationTypes.ShoesPendingItems}/*`} element={<ShoesPendingItemsStation />} />
          <Route path={`${StationTypes.ShoesStainMan}/*`} element={<ShoeStainManStation />} />
          <Route path={`${StationTypes.ShoesSorterAssessment}/*`} element={<ShoeSorterAssessmentStation />} />
          <Route path={`${StationTypes.CPDispatch}/*`} element={<DispatchStationCP />} />
          <Route path={`${StationTypes.WFDispatch}/*`} element={<DispatchStationWF />} />
          <Route path={`${StationTypes.ShoesDispatch}/*`} element={<DispatchStationShoes />} />
          <Route path={`${StationTypes.FineryStainMan}/*`} element={<FineryStainManStation />} />
          <Route path={`${StationTypes.FineryReceiving}/*`} element={<FineryReceivingStation />} />
          <Route path={`${StationTypes.ShoesReceiving}/*`} element={<ShoesReceivingStation />} />
          <Route path={`${StationTypes.WfReceiving}/*`} element={<WfReceivingStation />} />
          <Route path={`${StationTypes.Receiving}/*`} element={<ReceivingStation />} />

          <Route path="*" element={<Navigate to={`${stationType}`} />} />
        </Routes>
      </Suspense>
      {showOrderCompleteDialog && <OrderCompletedDialog onClose={onOrderCompleteDialogCloseHandler} />}
    </>
  );
});

export default Stations;
