import React from 'react';
import { CustomDialog } from '../../../../components/dialogs';
import { Button, Grid, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import { ReactComponent as LinkIcon } from 'assets/images/svgs/link-icon.svg';
import { IOrderItem } from '../../../../utils/interfaces';

interface Props {
  currentItem: IOrderItem;
  items: IOrderItem[];
  onClose: () => void;
  open: boolean;
}

export const ItemsMatchedModal: React.FC<Props> = ({ currentItem, items, open, onClose }) => {
  return (
    <CustomDialog open={open}>
      <HeaderBox flex={1} justifyContent='center' alignItems='center'>
        <HeaderText variant='h4'>Items Grouped Together</HeaderText>
      </HeaderBox>
      <Wrapper container>
        <Grid container direction={'row'} alignItems={'center'} justifyContent={'center'}>
          <Grid container item direction={'row'} alignItems={'center'} justifyContent={'center'}>
            {items.map((item,index) => (
              <div key={index}>
                <LinkItemImage src={item?.frontImage || currentItem?.frontImage} />
                {index < items.length-1 &&<StyledLinkIcon />}
              </div>
            ))}
          </Grid>
        </Grid>
        <Grid container justifyContent={'center'} alignItems={'center'}>
          <ConfirmButton variant={'contained'} onClick={onClose}>
            Done
          </ConfirmButton>
        </Grid>
      </Wrapper>
    </CustomDialog>
  );
};

const Wrapper = styled(Grid)`
  padding: 2rem;
`;
const LinkItemImage = styled.img`
  height: 12rem;
  width: 8rem;
`;
const StyledLinkIcon = styled(LinkIcon)`
  align-items: center;
  margin: 1rem;
`;
const ConfirmButton = styled(Button)`
  margin-top: 3rem;
  width: 12rem;
  height: 4rem;
  align-self: center;
  background-color: #3e48a6;
  margin-right: 1rem;
  color: white;
`;
const HeaderText = styled(Typography)`
  margin: 1.2rem;
`;
const HeaderBox = styled(Box)`
  display: flex;
`;
