import React from 'react';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { ReactComponent as SwapWarning } from '../../assets/images/svgs/special-attention.svg';
import styled from 'styled-components';

export const formattedDate = (dropoffDate: string, dropoffTime: string, isSwapped?: boolean, color?: string, colorByDate: boolean = true) => {
  const today = moment();
  const date = moment(dropoffDate);
  const isBeforeToday = date.isBefore(today, 'day');
  const formattedDate = date.isSame(today, 'day') ? 'Today' : date.format('ddd D');
  const formattedTime = moment(dropoffTime, 'hmma').format('h:mm a');
  return (
    <Grid container direction='row' alignItems='center' alignContent='center'>
      <Grid item xs={9}>
        <ColoredDropoffDate fontWeight={isBeforeToday ? 'bold' : ''}
                            color={color ? color : colorByDate ? isBeforeToday ? '#db0404' : '' : ''}>
          {formattedDate}
          {formattedDate !== 'Today' && (
            <>
              <br />
              {formattedTime}
            </>
          )}
        </ColoredDropoffDate>
      </Grid>
      <Grid item xs={1}>
        {isSwapped && <SwapWarning width={'2rem'} height={'2rem'} />}
      </Grid>
    </Grid>
  );
};
const ColoredDropoffDate = styled.span<{ color?: string; fontWeight?: string }>`
  ${({ color }) => (color ? `color : ${color};` : '')}
  ${({ fontWeight }) => (fontWeight ? `font-weight : ${fontWeight};` : '')}
`;
