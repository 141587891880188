import { ImageDetail } from 'components/imageStepper/ImageStepper';
import { shoeIconNameMap } from 'modules/stations/shoeSorterStation/components/imagesCustomStepper/customStepperIcons';
import { IItemReportDetail } from 'utils/interfaces/stainManStationInterfaces';
import { OrderServiceLines } from '../interfaces';
import { fineryIconNameMap } from '../../modules/stations/finerySorterStation/components/imagesCustomStepper/customStepperIcons';

export const mapItemImagesMapWithIcons = (itemReportDetail: IItemReportDetail) => {
  let imagesArray: ImageDetail[] = [];
  if (itemReportDetail.itemImagesMap) {
    Object.entries(itemReportDetail.itemImagesMap).forEach(([key, value]) => {
      imagesArray.push({ image: value, logo: iconConfig[itemReportDetail.orderServiceLine][key] });
    });
  }
  return imagesArray;
};
const iconConfig: {
  [key in OrderServiceLines]: { [key: string]: JSX.Element }
} = {
  shoes: shoeIconNameMap,
  finery: fineryIconNameMap,
  laundry: shoeIconNameMap,
}

