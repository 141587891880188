import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Grid, Theme, Typography } from '@material-ui/core';
import { remove, isEmpty } from 'lodash';

import CustomDialog from 'components/dialogs/customDialog';
import { SaDialogHeader, SaDialogItemsDisplay, SaDialogFooter } from '.';
import { SaDialogTypes } from 'utils/enums/stationEnums';
import { IBag, SaItem } from 'utils/interfaces';
import styled from 'styled-components';
import { SAHandlerOptions } from 'modules/stations/finerySorterStation/hooks/useSaItems';

export enum linkActions {
  linkSuccessful = 'linkSuccessful',
  unlinkSuccessful = 'unlinkSuccessful',
  redirectToStain = 'redirectToStain',
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '1rem',
    },
    padding: {
      padding: '1rem',
    },
  }),
);

interface Props extends Omit<SAHandlerOptions, 'isAllSaItemsLinked' | 'cleanUp'> {
  isOpen: boolean;
  type: SaDialogTypes;
  openWithItem?: SaItem;
  hideStainReport?: boolean;
  bag: IBag;
  onItemLink?: (action: linkActions) => void;
  onContinue?: () => void;
  onClose?: () => void;
}

const SaDialog: React.FC<Props> = observer(
  ({
    isOpen,
    type,
    openWithItem,
    hideStainReport = false,
    bag,
    linkedItem,
    specialAttentionItemsList,
    specialItemsInBag,
    hasSpecialAttentionItems,
    linkItemToSpecialAttentionItem,
    linkSpecialAttentionItemToBag,
    unlinkItemToSpecialAttentionItem,
    unlinkSpecialAttentionItemFromBag,
    onClose,
    onItemLink,
    onContinue = () => ({}),
  }) => {
    // Import Style classes
    const classes = useStyles();

    // Declare States
    const [activeItem, setActiveItem] = useState<SaItem>({} as SaItem);
    const [thumbnailItems, setThumbnailItems] = useState<SaItem[]>([]);

    const init = useCallback(() => {
      switch (type) {
        case SaDialogTypes.linkToBag:
          return specialAttentionItemsList;
        case SaDialogTypes.viewOnly:
          return specialAttentionItemsList;
        case SaDialogTypes.sorterViewOnly:
          return specialAttentionItemsList;
        case SaDialogTypes.linkToItem:
          return specialItemsInBag;
        default:
          return [];
      }
    }, [specialAttentionItemsList, specialItemsInBag, type]);

    const saItemsList = useMemo(init, [init]);

    // Set the active Item state
    useEffect(() => {
      // If the modal needs to start with a given Item
      if (openWithItem && !isEmpty(openWithItem)) {
        setActiveItem(openWithItem);
        return;
      }

      // If Item linked
      if (linkedItem) {
        setActiveItem(linkedItem);
        return;
      }

      // Select the first element of the array
      if (saItemsList && saItemsList.length > 0) {
        const itemUnassigned = saItemsList.find((item: SaItem) => !item.itemCode && !item.bagCode && !item.notInBag);

        if (itemUnassigned) {
          return setActiveItem(itemUnassigned);
        }

        const itemNotInBag = saItemsList.find((item: SaItem) => !item.itemCode && !item.bagCode);

        if (itemNotInBag) {
          return setActiveItem(itemNotInBag);
        }

        setActiveItem(saItemsList[0]);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saItemsList, openWithItem, isOpen]);

    // Compute the Thumbnail Array Items based on the active Item
    useEffect(() => {
      if (saItemsList && saItemsList.length > 0 && activeItem) {
        const remaingItems = remove([...saItemsList], (item: SaItem) => item.id !== activeItem.id);
        setThumbnailItems(remaingItems);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeItem]);

    /**
     * Set the select thumbnail item to active
     *
     * @param item - set to active
     */
    const onItemSelection = (item: SaItem) => {
      setActiveItem(item);
    };

    const navigateToNextItem = () => {
      const nextValue: SaItem | undefined = generateNext();

      if (isEmpty(nextValue)) {
        if (onClose) onClose();
        return;
      }
      if (nextValue) setActiveItem(nextValue);
    };

    const generateNext = () => {
      return saItemsList.find((item: SaItem) => !(item.bagCode || item.notInBag || item.itemCode) && item.id !== activeItem.id);
    };

    const withStatus = type !== SaDialogTypes.unlinkItem && type !== SaDialogTypes.viewOnly;

    return (
      <CustomDialog open={isOpen} size="lg">
        <Grid container direction="column" alignItems="center" classes={{ container: classes.root }}>
          {/* Header */}
          <SaDialogHeader onClose={onClose} />

          {/* Dialog Body */}
          <BoxWrapper justifyContent="center" container item xs={12} wrap="nowrap">
            {bag?.order.customerNotes && (
              <NotesWrapper container item>
                <Grid item xs={12}>
                  <InfoTitle variant="h4">General notes</InfoTitle>
                </Grid>
                <Grid item xs={12}>
                  <Notes variant="body2">{bag.order.customerNotes}</Notes>
                </Grid>
              </NotesWrapper>
            )}
            {hasSpecialAttentionItems && (
              <SaWrapper container item direction="column" justifyContent="center" alignItems="center">
                {/* Item's Display */}
                <SaDialogItemsDisplay
                  activeItem={activeItem}
                  thumbnailItems={thumbnailItems}
                  selectItem={onItemSelection}
                  hideStainReport={hideStainReport}
                  withStatus={withStatus}
                />

                {/* Dialog Footer */}
                <SaDialogFooter
                  type={type}
                  activeItem={activeItem}
                  onItemTag={navigateToNextItem}
                  onItemLink={onItemLink}
                  onContinue={onContinue}
                  linkedItem={linkedItem}
                  bag={bag}
                  linkItemToSpecialAttentionItem={linkItemToSpecialAttentionItem}
                  linkSpecialAttentionItemToBag={linkSpecialAttentionItemToBag}
                  unlinkSpecialAttentionItemFromBag={unlinkSpecialAttentionItemFromBag}
                  unlinkItemToSpecialAttentionItem={unlinkItemToSpecialAttentionItem}
                />
              </SaWrapper>
            )}
          </BoxWrapper>
        </Grid>
      </CustomDialog>
    );
  },
);

export default SaDialog;

const InfoTitle = styled(Typography)`
  font-size: 2rem;
  color: #7d7c97;
`;

const Notes = styled(Typography)`
  font-size: 1.2rem;
`;
const BoxWrapper = styled(Grid)`
  gap: 1rem;
`;

const SaWrapper = styled(Grid)`
  gap: 1.3rem;
  background: #fffefc;
  border: 2px solid #fbf2e5;
  border-radius: 10px;
`;

const NotesWrapper = styled(Grid)`
  gap: 1.3rem;
  background: #fffefc;
  border: 2px solid #fbf2e5;
  border-radius: 10px;
  padding: 2rem;
  flex-shrink: 2;
  flex-basis: 70%;
`;
