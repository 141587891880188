import { useMutation } from 'react-query';
import stationService from 'services/station.service';

interface CreateOptions {
  itemCode: string;
  orderAlphaId: string;
  orderId: string;
  photo: string;
  description: string;
}

const useAddNote = () => {
  return useMutation<string, Error, CreateOptions>(({ description, itemCode, orderAlphaId, orderId, photo }) =>
    stationService.addUserNotes(itemCode, orderAlphaId, orderId, photo, description)
  );
};

export default useAddNote;
