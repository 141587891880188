import { useQuery } from 'react-query';
import { StationService } from 'services';
import { IBrandsResponse } from 'utils/interfaces';


const useGetBrands = (customerId: string, type: string, latestBrandSelected?: IBrandsResponse) => {
    const { data: topBrands } =
        useQuery<IBrandsResponse[], Error>(
            ['top-brands', { customerId, type, latestBrandSelected }],
            () => StationService.fetchCustomerTopBrands(customerId, type),
            {
                initialData: [],
                select: (topBrands) => {
                    let fixedTopBrands: IBrandsResponse[] = [];
                    if (typeof topBrands === 'string') fixedTopBrands = JSON.parse(topBrands) || []
                    const brands = latestBrandSelected !== undefined ? [latestBrandSelected, ...fixedTopBrands] : [...fixedTopBrands];
                    const topBrandsToReturn = brands.filter((brand, index) => {
                        return brands.findIndex((b) => b.name === brand.name) === index;
                    })
                    return topBrandsToReturn
                },
            }
        );
    const { data: allBrands } = useQuery<IBrandsResponse[], Error>(['all-brands'], () => StationService.fetchAllBrands(), {
        initialData: [],
    });

    return { allBrands: allBrands || [], topBrands: topBrands || [] };
};

export default useGetBrands;
