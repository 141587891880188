import { useQuery } from 'react-query';
import { StainManService } from 'services';
import { IItemReportDetail } from 'utils/interfaces/stainManStationInterfaces';
import { DetailsList, OrderServiceLines } from 'utils/interfaces';

export interface QueryResponse {
  itemReportDetail: IItemReportDetail;
}

export const initialReport: IItemReportDetail = {
  itemImagesMap: { top: '', bottom: '', backFront: '', inside: '', outside: '' },
  orderServiceLine: OrderServiceLines.LAUNDRY,
  customerAttachmentsList: [],
  customerNotes: '',
  orderId: '',
  customerAlphaId: '',
  dropoffDate: '',
  orderAlphaId: '',
  approvalOrigin: '',
  approvalStatus: '',
  brandDetails: { Delicate: false, label: '', logo: '', luxury: false, name: '' },
  createdAt: '',
  customerName: '',
  specialAttentionItem: {
    cleaningInstruction: '',
    itemCode: '',
    stainsArray: [],
    imageUrl: '',
    imageName: '',
    id: '',
    bagCode: '',
    brandName: '',
    tagsArray: [],
  },
  careLabelPhoto: '',
  itemIssues: [],
  orderStatus: '0',
  delayDetails: {
    delayDays: 0,
    delayReason: '',
    isDelayAnswered: false,
    isDelayed: false,
    reportedDelayTimestamp: '',
  },
  colorsList: [],
  stainDetails: [],
  facilityVisits: 0,
  itemBrand: '',
  itemCode: '',
  frontImage: '',
  numberOfScans: 0,
  type: "SOCKS",
  addOnServicesList: [],
  bagCode: '',
  cleanType: '',
  code: '',
  customerId: "",
  id: "",
  isBanned: false,
  isHung: false,
  isRecurrent: false,
  isRejected: false,
  rfid: ''
};

export const QUERY_KEY = 'itemReportDetail';

const useGetItemReportDetail = (itemIdentifier: string, isFirstScan: boolean, enabled: boolean, orderAlphaId?: string) => {
  return useQuery<QueryResponse, Error>(
    [QUERY_KEY, itemIdentifier, { orderAlphaId }],
    () => StainManService.fetchItemReportDetails(itemIdentifier, orderAlphaId, isFirstScan),
    {
      enabled: enabled,
      initialData: {
        itemReportDetail: initialReport,
      },
      select: ({ itemReportDetail }) => ({
        itemReportDetail: { ...itemReportDetail, reportDetailsList: itemReportDetail.itemIssues as DetailsList[], code: itemReportDetail.itemCode },
      }),
    }
  );
};
export default useGetItemReportDetail;
