import Axious from './api';
import { AxiosRequestConfig } from 'axios';

class AuthenticationService {
  /**
   *
   * @param username The employee RFID
   * @param password The Manager RFID
   * @param stationNumber Station Id that the employee is trying to login to
   */
  public async login(username: string, password: string, stationNumber: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: '/auth/login',
      data: {
        id: username,
        password: password,
        stationNumber: stationNumber,
      },
    };

    try {
      const response = await Axious.request(requestOptions);
      if (response.status === 200) {
        const { authToken, user } = response.data;
        localStorage.setItem('authToken', authToken);
        localStorage.setItem('userRfid', username);
        localStorage.setItem('userId', user.id);
        localStorage.setItem('userName', user.name);
        localStorage.setItem('userPermissions', JSON.stringify(user.permissionsList || []));
        localStorage.setItem('role', user.role || '');
      }
      return response;
    } catch (error) {
      throw error;
    }
  }

  /**
   * @description logs the user out by clearing the local storage
   */
  public logout() {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userRfid');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
  }

  async validateManagerId(managerId: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/managers/${managerId}/validate`,
    };
    const response = await Axious.request(requestOptions);
    return response;
  }
}

const authService = new AuthenticationService();

// export as singleton
export default authService;
