import { createContext, useContext } from 'react';
//TODO: Remove when migrating react-scripts and mobx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { observable, action, computed } from 'mobx';

interface IActivity {
  type: string;
  timestamp: number;
}

export enum EActionType {
  scanBag = 'SCAN_BAG',
  scanItem = 'SCAN_ITEM',
  scanShoeItem = 'SCAN_SHOE_ITEM',
  bagScanSuccessful = 'BAG_SCAN_SUCCESSFUL',
  bagScanUnsuccessful = 'BAG_SCAN_UNSUCCESSFUL',
}

export interface IStats {
  thisHour: number;
  oneHourAgo: number;
  twoHourAgo: number;
}

// Display Intervals
const ACTIVITY_INTERVAL = [60, 120, 180];
// Activity (TTL)
const ACTIVITY_TIMEOUT = ACTIVITY_INTERVAL[2];

class ActivityStore {
  @observable private activityLog: IActivity[];

  constructor() {
    //Fetch from local storage
    this.activityLog = JSON.parse(localStorage.getItem('activityLog') || '[]');
  }

  @computed
  get activityStats() {
    const reducedObj = this.activityLog.reduce(
      (acc: IStats, activity: IActivity) => {
        const minutes = _getTimeDifferenceInMinutes(activity.timestamp);

        if (minutes < ACTIVITY_INTERVAL[0]) {
          return {
            ...acc,
            thisHour: ++acc.thisHour,
          };
        } else if (minutes >= ACTIVITY_INTERVAL[0] && minutes < ACTIVITY_INTERVAL[1]) {
          return {
            ...acc,
            oneHourAgo: ++acc.oneHourAgo,
          };
        } else if (minutes >= ACTIVITY_INTERVAL[1] && minutes < ACTIVITY_INTERVAL[2]) {
          return {
            ...acc,
            twoHourAgo: ++acc.twoHourAgo,
          };
        } else if (minutes > ACTIVITY_TIMEOUT) {
          console.log(`Should be removed! Time since: ${minutes}`);
        }
        return acc;
      },
      {
        thisHour: 0,
        oneHourAgo: 0,
        twoHourAgo: 0,
      }
    );

    return reducedObj;
  }
  @computed
  get scannedBagsActivity() {
    const successfulBagScans: IActivity[] = [];
    const unsuccessfulBagScans: IActivity[] = [];

    this.activityLog.forEach((activity: IActivity) => {
      if (activity.type === EActionType.bagScanSuccessful) {
        successfulBagScans.push(activity);
      } else if (activity.type === EActionType.bagScanUnsuccessful) {
        unsuccessfulBagScans.push(activity);
      }
    });
    return { successfulBagScans, unsuccessfulBagScans };
  }
  @computed
  get cleanActivityList() {
    return this.activityLog;
  }

  /**
   * Setup a new Activity Log (In case the user logs in to a new station)
   *
   * @param value - Activity Array
   */
  @action
  setActivityLog(value: IActivity[]) {
    // Reset the activity log
    this.activityLog.length = 0;
    // Reset LS value
    localStorage.removeItem('activityLog');

    // Compute updated Array
    const cleanedArray = _cleanActivityArray(value);
    // Update activity log in store
    this.activityLog = [...cleanedArray];
    // Persist new array in LS
    localStorage.setItem('activityLog', JSON.stringify(cleanedArray));
  }

  /**
   *
   * @param actionType
   */
  @action.bound
  logActivity(actionType: EActionType) {
    // Create the new entry
    const newEntry = { type: actionType, timestamp: Date.now() };
    // Get the updated Array
    const cleanedArray = _cleanActivityArray([...this.activityLog, newEntry]);
    // Set the new array in store
    this.activityLog = cleanedArray;
    // Update LS
    localStorage.removeItem('activityLog');
    localStorage.setItem('activityLog', JSON.stringify(cleanedArray));
  }

  /**
   * Reset acitity Log array
   */
  @action.bound
  activityCleanup() {
    // Reset Array
    this.activityLog.length = 0;
    // Update LS
    localStorage.removeItem('activityLog');
  }
}

export const activityState = new ActivityStore();
const ActivityStateContext = createContext(activityState);

export const useActivityState = () => useContext(ActivityStateContext);

// Get the difference in minutes btween the timestamp and now
const _getTimeDifferenceInMinutes = (timestampA: number) => {
  const dateA = new Date(timestampA);
  const today = new Date();

  const difference = (today.getTime() - dateA.getTime()) / 1000;

  return Math.abs(Math.round(difference / 60));
};

const _cleanActivityArray = (activityArray: IActivity[]) => {
  return activityArray.filter((activity: IActivity) => _getTimeDifferenceInMinutes(activity.timestamp) <= ACTIVITY_TIMEOUT);
};
