import onScan from 'onscan.js';

export enum ScanType {
  ItemQrCode = 'itemQrCode',
  PackageQrCode = 'packageQrCode',
  TrayQrCode = 'trayQrCode',
  ItemRfid = 'itemRfid',
  QrCode = 'qrCode',
  DropoffBagCode = 'dropoffBagCode',
}

type ScanPatterns = Record<ScanType, RegExp>;

const AcceptableKeys = ['-'];
class ScannerService {
  private scanPatterns: ScanPatterns;

  constructor() {
    /**
     * @description The scan patterns are used to identify the type of scan.
     * The order of the keys is important.
     * Make sure the patterns are in order from most specific to
     * least specific.(If there are conflicting patterns, the first one will be used)
     *
     * When adding patterns, make sure they are 4 > letters, or modify the below `minLength` config
     */
    this.scanPatterns = {
      itemQrCode: /^\d{12}$/, // 12 digits
      packageQrCode: /^\d{10}$|^PKG-/, // 10 digits or starts with PKG-
      trayQrCode: /^\d{1,4}$/, // 1-4 digits
      itemRfid: /^[A-Za-z0-9]{24}$/, // 24 characters
      dropoffBagCode: /-\d+$/, // Anything followed by a dash and a number
      qrCode: /^.+$/, // Anything
    };
  }

  public initScanner(): void {
    if (onScan.isAttachedTo(document)) return;

    onScan.attachTo(document, {
      reactToPaste: true,
      keyCodeMapper: function (oEvent) {
        if (AcceptableKeys.includes(oEvent.key)) return oEvent.key;
        // Fall back to the default decoder in all other cases
        return onScan.decodeKeyEvent(oEvent);
      },
      minLength: 4,
    });
  }

  public identifyScanType<UserPreferredType extends ScanType = ScanType>(scannedCode: string): UserPreferredType | null {
    for (const [type, pattern] of Object.entries(this.scanPatterns)) {
      if (pattern.test(scannedCode)) {
        return type as UserPreferredType;
      }
    }
    return null;
  }

  /** For testing purposes */
  public simulateScan(scannedCode: string): void {
    onScan.simulate(document, scannedCode);
  }

  public detachScanner(): void {
    if (!onScan.isAttachedTo(document)) return;

    onScan.detachFrom(document);
  }
}

export default new ScannerService();
