import React from 'react';
import { observer } from 'mobx-react-lite';
import { Grid } from '@material-ui/core';

import { ExtraItem, IOrderItem, OrderServiceLines } from 'utils/interfaces';
import { ScanType } from 'utils/enums/stationEnums';
import { EXTRA_ITEMS_TYPE_MAPPER } from 'utils/maps/typeMapper';
import { SelectTypeComponent } from 'modules/stations/components/selectType';
import { ScannerWidget } from 'components/scanner';
import PhotoCatcher from '../photoCatcher';
import ShirtQrCodeImage from 'assets/images/pngs/Qr.png';
import { routeProps as sorterProps } from '../sorterDialog';

interface Props {
  handleAddExtraItem: (item: ExtraItem) => void;
  serviceLine?: OrderServiceLines;
  currentItemCode?: string;
}

function generateRandom12DigitNumber() {
  const min = 100000000000; // Minimum 12-digit number
  const max = 999999999999; // Maximum 12-digit number
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  return randomNumber.toString();
}

const AddExtraItems: React.FC<React.PropsWithChildren<Props>> = observer(({ handleAddExtraItem, serviceLine, currentItemCode }) => {
  const [item, setItem] = React.useState<ExtraItem>({
    code: serviceLine === OrderServiceLines.SHOES ? generateRandom12DigitNumber() : '',
    type: 'OTHER',
    frontImage: '',
  });

  const [dialogType, setDialogType] = React.useState<sorterProps['dialogType']>(serviceLine === OrderServiceLines.SHOES ? 'type' : 'scanner');

  const handleQrCode = (value: string, type: ScanType) => {
    setItem({ ...item, code: value });
    setDialogType('type');
  };

  const handleTypeSelect = (updatedItem: IOrderItem) => {
    if (updatedItem.type) {
      setItem({ ...item, type: updatedItem.type });
      setDialogType('photo');
    }
  };

  const handleConfirmImage = (image: string) => {
    setItem({ ...item, frontImage: image });
    handleAddExtraItem({ ...item, frontImage: image });
  };

  const renderDialog = (dialogType: sorterProps['dialogType']) => {
    switch (dialogType) {
      case 'scanner': {
        return (
          <ScannerWidget
            title="main.scanItemQrCode"
            onScanComplete={handleQrCode}
            images={{ imageUrl: ShirtQrCodeImage, type: ScanType.QRCODE }}
            validateInput
            qrCodeType="item"
            border={false}
          />
        );
      }
      case 'type': {
        return (
          <SelectTypeComponent
            onSelect={handleTypeSelect}
            customType={{
              labelPrefix: 'station.sorter.itemType.categories.',
              typesConfig: EXTRA_ITEMS_TYPE_MAPPER,
              title: 'Item type',
            }}
          />
        );
      }
      case 'photo': {
        return (
          <PhotoCatcher
            onConfirmImage={handleConfirmImage}
            height="45rem"
            title="Please take a photo of the item"
            isLoader={false}
            currentItemCode={currentItemCode}
          />
        );
      }
    }
  };

  return (
    <Grid container xs={'auto'} direction="row" justifyContent="space-between" alignItems="center">
      {renderDialog(dialogType)}
    </Grid>
  );
});

export default AddExtraItems;
