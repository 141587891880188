import React, { PropsWithChildren, useContext } from 'react';
import _ from 'lodash';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { IObservableValue } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Grid, makeStyles, Theme, createStyles, Button } from '@material-ui/core';

import WidgetBox from 'components/widgetBox/widgetBox';
import SorterDialog from 'modules/stations/components/SortingStepper/sorterDialog';
import { SorterSteps } from 'utils/enums/stationEnums';
import { useSorterStore } from 'stores';
import { IBag, IOrderItem, OrderServiceLines } from 'utils/interfaces';
import { SelectBrandComponent } from 'modules/stations/shoeSorterStation/components/dialogs/selectBrand';
import { SelectColorComponent } from 'modules/stations/components/selectColor';
import { SelectTypeComponent } from 'modules/stations/components/selectType';
import { MarryingButtonGrid } from 'modules/stations/sorterStation/components/stepsState';
import { ItemsMatchedModal } from 'modules/stations/components/itemGroups/ItemsMatchedModal';
import { MatchRecommendedModal } from 'modules/stations/components/itemGroups/MatchRecommendedModal';

import { ReactComponent as MatchButton } from 'assets/images/svgs/match-button.svg';
import { ReactComponent as MatchedIcon } from 'assets/images/svgs/linked-icon.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: '1',
    },
    iconMargin: {
      marginLeft: '2rem',
    },
    buttonStyle: {
      width: '100%',
      padding: '1.5rem 0 !important',
    },
    floatingImage: {
      [theme.breakpoints.down('md')]: {
        maxHeight: '80px',
      },
      [theme.breakpoints.up('md')]: {
        maxHeight: '100px',
      },
    },
  })
);

export interface CustomStepperProps<K extends ItemKey> {
  initialStep?: number;
  disabledSteps?: number[];
  schema: Schema<K>[];
  onStepClick?: (name: string) => void;
}
const sorterDefaultComponents = {
  type: (props: ComponentProps) => <SelectTypeComponent {...props} />,
  brand: (props: ComponentProps) => <SelectBrandComponent {...props} />,
  color: (props: ComponentProps) => <SelectColorComponent {...props} />,
};
type PredefinedComponents = keyof typeof sorterDefaultComponents;

export interface Schema<K extends ItemKey> {
  label: string;
  type: string;
  itemKey: K;
  renderContent?: (attr: IOrderItem[K]) => JSX.Element;
}

export type ItemKey = keyof IOrderItem;

export type Components = Record<string, React.FC<ComponentProps>>;

export interface ComponentProps {
  onSelect: (updatedItem: IOrderItem, options?: { urlState?: any; disableRouting?: boolean; forceRoute?: boolean; skipStep?: boolean }) => void;
  currentItem?: IOrderItem;
}

interface SortingSteppperSchema<ComponentsObj extends Readonly<Components>, K extends ItemKey> {
  components: ComponentsObj;
  schema: Array<Schema<K> & { type: keyof ComponentsObj | PredefinedComponents }>;
}

export const createConfig = <ComponentsObj extends Readonly<Components>, K extends ItemKey>(
  props: SortingSteppperSchema<ComponentsObj, K>
): SortingSteppperSchema<ComponentsObj, K> => ({ ...props, components: { ...sorterDefaultComponents, ...props.components } });

interface SortingStepperProps<ComponentsObj extends Readonly<Components>, K extends ItemKey> extends SortingSteppperSchema<ComponentsObj, K> {
  autoSave?: boolean;
  disabledSteps?: number[];
  setCurrentItem: (item: IOrderItem) => void;
  currentItem: IOrderItem;
  noManagerRequired?: boolean;
  HeaderComponents?: React.FC<{ style?: React.CSSProperties }>;
  addionalRoutes?: JSX.Element[];
  bag: IBag;
  serviceLine: OrderServiceLines;
  CustomStepper: <K extends keyof IOrderItem>(props: React.PropsWithChildren<CustomStepperProps<K>>) => JSX.Element;
  handleShowMarryingModel?: () => void;
  marryingRecommendations?: {
    handleMatchModel: (flag: boolean) => void;
    handleMatchedModel: (flag: boolean) => void;
    linkItems: () => void;
    itemGroup?: IOrderItem[];
    recommendedItems: (IOrderItem | undefined)[];
    isMatchedModalOpen: boolean;
    isMatchModalOpen: boolean;
  };
}

const SortingStepper = <ComponentsObj extends Readonly<Components>, K extends ItemKey>({
  currentItem,
  autoSave = false,
  disabledSteps,
  setCurrentItem,
  children,
  noManagerRequired = false,
  components,
  schema,
  HeaderComponents,
  addionalRoutes,
  bag,
  CustomStepper,
  serviceLine = OrderServiceLines.SHOES,
  handleShowMarryingModel,
  marryingRecommendations,
}: PropsWithChildren<SortingStepperProps<ComponentsObj, K>>) => {
  const classes = useStyles();
  // Import Context
  const { focusInput$ } = useSorterStore();
  const navigate = useNavigate();

  const onStepClick = (step: string) => {
    if (step === SorterSteps.stainDamage) {
      if (currentItem.reportDetailsList && currentItem.reportDetailsList.length > 0) return navigate(`(item/${step})/overView`);
    }

    navigate(`(item/${step})`);
  };

  return (
    <SelectedItemContext.Provider value={{ selectedItem: currentItem }}>
      <Routes>
        <Route
          path={'/'}
          element={
            <FocusWrapper focusInput$={focusInput$} setFocus={true}>
              <WidgetBox>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                  <MarryingButtonGrid xs={'auto'}>
                    <Button color="primary" size="large" variant="text" className={classes.buttonStyle} onClick={handleShowMarryingModel}>
                      <span>{'Marrying Items'}</span>
                    </Button>
                  </MarryingButtonGrid>
                  {marryingRecommendations && (
                    <>
                      {marryingRecommendations.itemGroup && !_.isEmpty(marryingRecommendations.itemGroup) ? (
                        <Grid xs={'auto'}>
                          <MatchedIcon onClick={() => marryingRecommendations.handleMatchedModel(true)} />
                          <ItemsMatchedModal
                            currentItem={currentItem}
                            items={marryingRecommendations.itemGroup}
                            onClose={() => marryingRecommendations.handleMatchedModel(false)}
                            open={marryingRecommendations.isMatchedModalOpen}
                          />
                        </Grid>
                      ) : (
                        !_.isEmpty(marryingRecommendations.recommendedItems) && (
                          <Grid xs={'auto'}>
                            <MatchRecommendedModal
                              open={marryingRecommendations.isMatchModalOpen}
                              currentItem={currentItem}
                              items={marryingRecommendations.recommendedItems}
                              onClose={() => marryingRecommendations.handleMatchModel(false)}
                              onMatch={marryingRecommendations.linkItems}
                            />
                            <MatchButton onClick={() => marryingRecommendations.handleMatchModel(true)} />
                          </Grid>
                        )
                      )}
                    </>
                  )}
                </Grid>
                <Grid container direction="column" justifyContent="space-between" alignItems="center" classes={{ container: classes.root }}>
                  <CustomStepper schema={schema} onStepClick={onStepClick} disabledSteps={disabledSteps}>
                    {HeaderComponents && <HeaderComponents style={{ flexBasis: '10%', marginBottom: '1.5rem' }} />}
                  </CustomStepper>

                  {children}
                </Grid>
              </WidgetBox>
              <Outlet />
            </FocusWrapper>
          }
        >
          <Route
            path={`(item/:stepType)/*`}
            element={
              <FocusWrapper focusInput$={focusInput$} setFocus={false}>
                <SorterDialog
                  serviceLine={serviceLine}
                  schema={schema}
                  onClose={() => navigate('', { replace: true })}
                  components={components}
                  open={true}
                  autoSave={autoSave}
                  noManagerRequired={noManagerRequired}
                  updateCurrentItemDetails={setCurrentItem}
                  HeaderComponents={HeaderComponents}
                  bag={bag}
                />
              </FocusWrapper>
            }
          />

          {addionalRoutes}
        </Route>
      </Routes>
    </SelectedItemContext.Provider>
  );
};
export default SortingStepper;

export type SelectedItemContextType = {
  selectedItem: IOrderItem;
};
export const SelectedItemContext = React.createContext<SelectedItemContextType>({} as SelectedItemContextType);

export const useGetSelectedItem = () => useContext(SelectedItemContext);

const FocusWrapper: React.FC<React.PropsWithChildren<{ setFocus: boolean; focusInput$: IObservableValue<boolean> }>> = observer(
  ({ children, focusInput$, setFocus }) => {
    focusInput$.set(setFocus);
    return <>{children}</>;
  }
);
