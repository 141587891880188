import React, { useRef } from 'react';

import './styles.scss';
import { SaItem } from 'utils/interfaces';
import { SpecialAttentionImage } from 'components/specialAttentionImageGallery';
import { Box, ButtonBase, makeStyles, Typography } from '@material-ui/core';

import styled from 'styled-components';
import NoImage from 'assets/images/pngs/noImage.png';

interface Props {
  imageUrl?: string;
  index?: number | string;
  linkedItem?: SaItem;
  text?: string;
  onImgLoad?: () => void;
  itemCode?: string;
  customTextNode?: React.ReactNode;
  imageWidth?: string;
  customComponents?: JSX.Element;
}

const ImagePopup: React.FC<Props> = ({
  imageUrl,
  index,
  linkedItem,
  text,
  onImgLoad = () => {},
  itemCode = '',
  customTextNode,
  imageWidth,
  customComponents,
}) => {
  const tag = linkedItem ? linkedItem.id : index;
  return (
    <>
      {linkedItem ? (
        <a className="lightbox" href={`#${tag}`}>
          <SpecialAttentionImage item={linkedItem || {}} withStatus={false} />
        </a>
      ) : (
        <StyledLink itemCode={itemCode} className="lightbox" href={`#${tag}`}>
          {itemCode}
          <ImageWithTextLayer
            customComponents={customComponents}
            imageWidth={imageWidth}
            imageUrl={imageUrl || ''}
            onImgLoad={onImgLoad}
            itemCode={itemCode}
          >
            {(text || customTextNode) && (
              <StyledTypography variant="body1" className="light-typo">
                {text}
                {customTextNode}
              </StyledTypography>
            )}
          </ImageWithTextLayer>
        </StyledLink>
      )}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="#">
        <div className="lightbox-target" id={`${tag}`}>
          <img className="img-responsive" src={linkedItem ? linkedItem.imageUrl : imageUrl} alt="" data-cy="lightbox.image" />
        </div>
      </a>
    </>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
  },
  imgStyle: {
    position: 'relative',
  },
  textShade: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    verticalAlign: 'top',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
}));

interface ImageWTLProps {
  imageUrl: string;
  imageClassName?: string;
  onImgLoad: () => void;
  itemCode?: string;
  imageWidth?: string;
  customComponents?: JSX.Element;
}

const ImageWithTextLayer: React.FC<React.PropsWithChildren<ImageWTLProps>> = ({
  customComponents,
  imageUrl,
  imageClassName = '',
  children,
  onImgLoad,
  itemCode = '',
  imageWidth,
}) => {
  const classes = useStyles();

  const imgRef = useRef<HTMLImageElement>(null);
  return (
    <StyledBox item_code={itemCode} className={classes.container} display="flex" justifyContent="center" alignItems="center">
      {/* Ripple Effect */}
      <StyledButton focusRipple item_code={itemCode}>
        <StyledImg
          src={imageUrl || ''}
          image_width={imageWidth}
          alt="Report"
          className={`${classes.imgStyle} ${imageClassName}`}
          ref={imgRef}
          item_code={itemCode}
          onError={() => (imgRef.current!.src = NoImage)}
          onLoad={onImgLoad}
        />
        {customComponents}
        <Box className={classes.textShade}>{children}</Box>
      </StyledButton>
    </StyledBox>
  );
};

export default ImagePopup;

const StyledLink = styled.a<{ itemCode?: string }>`
  ${({ itemCode }) =>
    itemCode
      ? `
    text-decoration: none;
    color: #3e48a6;
      `
      : ``}
`;

const StyledImg = styled.img<{ item_code?: string; image_width?: string }>`
  width: 100%;
  max-width: ${({ image_width }) => (image_width ? `${image_width}` : '20rem')};
  visibility: ${({ item_code }) => (item_code ? 'hidden' : 'visible')};
`;

const StyledButton = styled(ButtonBase)<{ item_code?: string }>`
  ${({ item_code }) => (item_code ? `height: 2rem; width: 15rem; top: -15px;` : ``)};
`;

const StyledBox = styled(Box)<{ item_code?: string }>`
  ${({ item_code }) => (item_code ? `height: 0;` : ``)};
`;

const StyledTypography = styled(Typography)`
  color: #fff;
  font-size: 1.5rem;
`;
