import { useQuery } from 'react-query';

import { PendingService } from 'services';
import { IPendingItem } from 'utils/interfaces';

interface IDelayedItem extends IPendingItem {
  isUrgent: boolean;
}
const useGetItemsByScan = (itemIdentifier?: string, packageId?: string) => {
  const { data, isSuccess, isError, refetch } = useQuery<{ itemsList: IDelayedItem[] }, Error>(
    ['scannedItems', { itemIdentifier, packageId }],
    () => PendingService.scanItemsOnPending(itemIdentifier, packageId),
    { enabled: Boolean(itemIdentifier || packageId), refetchOnWindowFocus: false, cacheTime: 0 }
  );

  return { isSuccess, isError, itemsList: data?.itemsList ?? [], refetch };
};

export default useGetItemsByScan;
