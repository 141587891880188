import { useMutation } from 'react-query';
import stationService from 'services/station.service';

interface CreateOptions {
  orderAlphaId: string;
  itemCodesList: string[];
  groupId?: string;
  mergedGroupIds?: string[];
}

const useAddGroup = () => {
  return useMutation<string, Error, CreateOptions>(({ orderAlphaId, itemCodesList, groupId, mergedGroupIds }) =>
    stationService.upsertGroup(orderAlphaId, itemCodesList, groupId, mergedGroupIds)
  );
};

export default useAddGroup;
