import React from 'react';
import styled from 'styled-components';
import { Grid, Icon } from '@material-ui/core';

import { CustomSwipeableView } from 'components/customSwipeableView';

import NoImage from 'assets/images/pngs/noImage.png';

export interface ImageDetail {
  image: string;
  logo: JSX.Element;
}

interface ImageStepperProps {
  images: ImageDetail[];
  imageWidth?: number;
}

const ImageStepper: React.FC<ImageStepperProps> = ({ images, imageWidth }) => {
  return (
    <CustomSwipeableView
      items={images}
      renderItem={(image, index) => (
        <ImageWrapper key={image.image} item xs={5}>
          <StyledImg image_width={imageWidth} src={image.image || NoImage} />
          {icons(image.logo)}
        </ImageWrapper>
      )}
    />
  );
};

const icons = (icon: JSX.Element) => {
  return <StyledIcon>{icon}</StyledIcon>;
};

const StyledIcon = styled(Icon)<{ top?: string; right?: string }>`
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;

  svg {
    width: 3rem;
    height: 3rem;
  }
`;

const StyledImg = styled.img<{ item_code?: string; image_width?: number }>`
  width: 100%;
  max-width: ${({ image_width }) => (image_width ? `${image_width}rem` : '30rem')};
  visibility: ${({ item_code }) => (item_code ? 'hidden' : 'visible')};
`;

const ImageWrapper = styled(Grid)`
  position: relative;
  margin: 0.5rem;
`;

export default ImageStepper;
