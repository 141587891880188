import { useMutation } from 'react-query';
import stationService from 'services/station.service';

interface CreateOptions {
  orderAlphaId: string;
  groupId: string;
}

const useRemoveGroup = () => {
  return useMutation<string, Error, CreateOptions>(({ groupId }) => stationService.removeItemGroups(groupId));
};

export default useRemoveGroup;
