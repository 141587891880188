export const DelayReasonMapper: { [key: string]: string } = {
  DRYING: 'Item needs more drying up time',
  STAIN: 'Item had a stain and required extra care',
  TAILORING: 'Item required tailoring',
  DAMAGE: 'Item had a pre existing damage and required extra care',
  DELICATE: 'Item was delicate and required extra care',
  RISK: 'Item had a risk of being damaged and required extra care',
  TRANSFER: 'Item was transferred from Wash & Fold to Clean & Press and required additional processing time',
  PRESSING_WASHING: 'The item required additional pressing/washing after quality control before being dispatched',
  APPROVAL_REQUESTED: 'Item was part of the permission request and required extra time',
  OTHER: 'Other',
};

export const DelayReasonsMap: { [key: string]: string[] } = {
  laundry: [
    'Bag misplacement at sorting',
    'Production Halt',
    'Item had a stain and required more time',
    'Item damaged in processing and required more time',
    'Item needs more drying up time',
    'Re-sorted',
    'No QR code',
    'Tailor work required more time',
    'Not ready during driver collection time',
    'Wrong Sorting',
  ],
  approval: ['Clean&Press - Approval Item (Late approval)', 'Clean&Press - Approval item (Left in sorting)'],
  claims: ['Item Damaged', 'Item not found'],
  dispatch: ['Item ready - Misplaced'],
  logistics: ['Item ready not picked up by the driver', 'Item ready task unassigned', 'Returned by the driver'],
  serviceTransfer: [
    'Carpet',
    'Clean&Press to Finery Transfer',
    'Clean&Press to ShoeCare Transfer',
    'Clean&Press to Wash&Fold Transfer',
    'Finery to Clean&Press Transfer',
    'Wash&Fold to Clean&Press Transfer',
  ],
  techIssue: ['Tech Issue'],
};

export const OtherReasonsMapper: { [key: string]: string } = {
  FORGOTTEN_ITEM: 'Driver forgot item',
  MISSING_ITEM: 'Item not found',
};
