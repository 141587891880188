import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';

/** Interfaces */
import { Brand } from 'utils/interfaces/stationInterfaces';

/** Images */
import noBrand from 'assets/images/pngs/noLogo.png';

/** Material UI */
import { Box, Typography } from '@material-ui/core';

/** Define props interface */
interface Props {
  brand: Brand;
  isHorizontal?: boolean;
  withBorders?: boolean;
  logoWidth?: string;
  logoHeight?: string;
  isTextHidden?: boolean;
  textFont?: 'small' | 'medium' | 'large';
  alignItems?: string;
}

/** Define Default Props */
const defaultProps = {
  brand: {
    name: 'brand-default-name',
    label: 'Brand Default',
    logo: 'brand-default-logo',
  },
  isHorizontal: false,
  withBorders: true,
  isTextHidden: false,
  textFont: 'medium',
  alignItems: 'center',
};

/** Image Row Dimensions */
const rowDimensions = {
  width: '30px',
  height: '30px',
};

/** Image Column Dimensions */
const columnDimensions = {
  width: '40px',
  height: '40px',
};

/** BrandDisplay FC */
const BrandDisplay: React.FC<Props> = ({
  brand = defaultProps.brand,
  isHorizontal = defaultProps.isHorizontal,
  withBorders = defaultProps.withBorders,
  isTextHidden = defaultProps.isTextHidden,
  textFont = defaultProps.textFont,
  alignItems = defaultProps.alignItems,

  logoWidth,
  logoHeight,
}) => {
  const { label, logo } = brand;
  const [brandLogo, setBrandLogo] = useState(noBrand);
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (logo !== 'NO_LOGO' && logo !== undefined) {
      setBrandLogo(`${process.env.REACT_APP_BRAND_API_HOST}/${logo}`);
    }
  }, [logo]);

  return (
    <Box display="flex" flexDirection={isHorizontal ? 'row' : 'column'} alignItems={alignItems} justifyContent="center">
      <img
        src={logo !== 'NO_LOGO' && logo !== undefined ? brandLogo : noBrand}
        alt="Brand logo"
        className="image-radius"
        style={{
          width: logoWidth ? logoWidth : isHorizontal ? rowDimensions.width : columnDimensions.width,
          height: logoHeight ? logoHeight : isHorizontal ? rowDimensions.height : columnDimensions.height,
          border: withBorders ? (logo !== 'NO_LOGO' && logo !== undefined ? '2px solid #b6bcd1' : undefined) : undefined,
          padding: withBorders ? '5px' : undefined,
        }}
        ref={imgRef}
        onError={() => (imgRef.current!.src = noBrand)}
      />
      {!isTextHidden && (
        <Typography
          variant="h6"
          className={`${isHorizontal ? 'light-typo text-margin-left' : 'light-typo text-margin-top'} ${textFont}-brand-name align-text`}
        >
          {label}
        </Typography>
      )}
    </Box>
  );
};

export default BrandDisplay;
