import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as ShoeItemIcon } from 'assets/images/svgs/shoe-item.svg';
import SizeIcon from 'assets/images/pngs/size-tag.png';
import { Box, IconButton, Grid, Typography, Button } from '@material-ui/core';
import { ItemDetails, useAnswerDelays, useItemJourney } from '../../../pendingItemsStation/context';
import QrDisplay from '../../../foldingStation/components/qrDisplay';
import { itemTypeIconMap } from 'utils/maps';
import { BrandDisplay } from 'components/brandDisplay';
import { ColorMapper } from 'components/colorMapper';
import AnswerDelayComponent from 'modules/stations/pendingItemsStation/components/answerDelays/answerDelayComponent';
import { DelayInfo, ItemTypes } from 'utils/interfaces';
import ImagesDialog from '../modals/imagesDialog';
import ImageStepper from 'components/imageStepper/ImageStepper';
import { IDetailsCardProps } from './leftCard';

import { ReactComponent as ShoeItemJourneyIcon } from 'assets/images/svgs/shoe-item-journey.svg';
import { mapItemImagesMapWithIcons } from 'utils/helpers/maps';

const ShoeLeftCard: React.FC<IDetailsCardProps> = ({
  itemReportDetail,
  isItemRejected,
  adjustHeight = false,
  itemIcon,
  itemJourneyIcon,
  iconTitle = 'Item Details',
  size,
  showColorNextLine = false,
  border = true,
}) => {
  const { toggle, updateItemId, updateOrderAlphaId } = useItemJourney();
  const { handleItemDelay, answerNoDelay } = useAnswerDelays();
  const [openImagesDialog, setOpenImagesDialog] = useState<boolean>(false);
  const toggleItemJourney = () => {
    updateOrderAlphaId(itemReportDetail.orderAlphaId);
    updateItemId(itemReportDetail.itemCode);
    toggle(true);
  };

  const { orderItemDetails, delayDetails } = useMemo<{
    orderItemDetails: ItemDetails;
    delayDetails: DelayInfo;
  }>(() => {
    return {
      orderItemDetails: {
        itemId: itemReportDetail.itemCode,
        orderAlphaId: itemReportDetail.orderAlphaId,
        customerAlphaId: itemReportDetail.customerAlphaId,
        dropoffDate: itemReportDetail.dropoffDate,
        itemType: itemReportDetail.type as ItemTypes | undefined,
        orderId: itemReportDetail.orderId,
      },
      delayDetails: itemReportDetail.delayDetails,
    };
  }, [itemReportDetail]);

  const [resetDelay, setResetDelay] = useState(false);
  const toggleResetDelay = (value: boolean) => setResetDelay(value);

  const handleDelayAnswer = (isDelayed: boolean) => {
    if (orderItemDetails) {
      if (isDelayed) {
        handleItemDelay?.({ ...orderItemDetails });
      } else {
        answerNoDelay?.({ ...orderItemDetails });
      }
      toggleResetDelay(false);
    }
  };

  return (
    <Wrapper adjustHight={adjustHeight} border={border}>
      <Box
        {...(adjustHeight ? { paddingBottom: '1rem' } : { paddingY: '1rem' })}
        display="flex"
        flexWrap="nowrap"
        justifyContent="space-between"
        alignItems={'center'}
        flexDirection="row"
      >
        <Box display="flex">
          {!itemIcon ? <ShoeItemIcon width="4.5rem" height="4.5rem" /> : itemIcon}
          <Header>{iconTitle}</Header>
        </Box>
        <Box>
          <IconButton onClick={toggleItemJourney}>
            {itemJourneyIcon ? itemJourneyIcon : <ShoeItemJourneyIcon width="4rem" height="4rem" />}
          </IconButton>
        </Box>
      </Box>
      <DetailsWrapper display={'flex'} flexDirection={'column'}>
        {itemReportDetail.itemImagesMap && (
          <Box display="flex" flexDirection="column">
            <Box display="flex" flex={9}>
              <ImageStepper images={mapItemImagesMapWithIcons(itemReportDetail)} />
            </Box>
            <Box display="flex" flex={1} justifyContent="center">
              <Button onClick={() => setOpenImagesDialog(true)}>View all images</Button>
            </Box>
          </Box>
        )}
        <Box alignItems={'center'}>
          <ItemStylesWrapper container item alignItems="center" wrap="nowrap" justifyContent={'center'} adjustHight={adjustHeight}>
            <QrDisplay withMargin={false} qrCode={itemReportDetail.itemCode} />
            {itemReportDetail.type && <ItemType src={itemTypeIconMap[itemReportDetail.type]} alt="item type" />}
            {itemReportDetail.brandDetails && (
              <BrandDisplay textFont="small" brand={itemReportDetail.brandDetails} logoHeight="3.5rem" logoWidth="3.5rem" />
            )}
            {!showColorNextLine && <ColorMapper colorsList={itemReportDetail.colorsList ?? []} withShadows textHidden dimension="3rem" />}
            {size && (
              <ShoeSize container item>
                <ShoeSizeImage src={SizeIcon} alt="item type" />
                <ShoeSizeText>{size}</ShoeSizeText>{' '}
              </ShoeSize>
            )}
          </ItemStylesWrapper>
          {showColorNextLine && <ColorMapper colorsList={itemReportDetail.colorsList ?? []} withShadows textHidden dimension="3rem" />}
        </Box>
        <DelayWrapper paddingTop={adjustHeight ? 3 : 10}>
          {!isItemRejected && (
            <AnswerDelayComponent
              handleDelayAnswer={handleDelayAnswer}
              resetDelay={resetDelay}
              toggleResetDelay={toggleResetDelay}
              answerDelaysWidth={'40%'}
              twoButtonOption={false}
              delayDetails={delayDetails}
              delayButtonText="Add Delay"
            />
          )}
        </DelayWrapper>
      </DetailsWrapper>

      <ImagesDialog open={openImagesDialog} onClose={() => setOpenImagesDialog(false)} imageList={mapItemImagesMapWithIcons(itemReportDetail)} />
    </Wrapper>
  );
};

export default ShoeLeftCard;

const DelayWrapper = styled(Box)`
  font-size: 2.5rem;

  svg {
    width: 2.5rem;
    height: 2.5rem;
  }
`;
const ItemStylesWrapper = styled(Grid)<{ adjustHight: boolean }>`
  height: fit-content;
  ${(props) => (props.adjustHight ? 'gap: 1rem;' : '')}
`;

const Wrapper = styled.div<{ adjustHight: boolean; border: boolean }>`
  ${(props) => (!props.adjustHight ? 'padding: 0px 1rem;' : 'padding: 0rem 1rem 1rem 1rem;')}
  ${(props) => (props.border ? 'border: 1px solid #7d7c97;' : '')}
  ${(props) => (!props.adjustHight ? 'height: 100%;' : '')}
  overflow-y: auto;
`;

const ItemType = styled.img`
  height: 6rem;
`;

const ShoeSize = styled(Grid)``;

const ShoeSizeImage = styled.img`
  width: 2.5rem;
  height: 2.5rem;
`;

const ShoeSizeText = styled(Typography)`
  font-size: 1.5rem;
  letter-spacing: normal;
  text-align: center;
  color: #111135;
`;

const Header = styled.p`
  font-family: Ubuntu, serif;
  font-size: 25px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #111135;
  padding-left: 0.75rem;
`;
const DetailsWrapper = styled(Box)`
  align-items: center;
  height: 85%;
  justify-content: space-around;
`;
