import { Grid, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { CustomDialog } from 'components/dialogs';
import { PhotoCatcher, StyledButton } from 'modules/stations/components/photoCatcher';
import React from 'react';
import styled from 'styled-components';

interface Props {
  onClose: () => void;
  onPhotoTaken: (imageBlob: string) => void;
  onSkipPhoto: () => void;
  onConfirmImage: (imageBlob: string) => void;
  photoAvailable: boolean;
  itemCode?: string;
  title?: string;
}
const AddImageDialog: React.FC<Props> = ({
  photoAvailable,
  onClose,
  onPhotoTaken,
  onSkipPhoto,
  onConfirmImage,
  itemCode,
  title = 'Please add photo of item',
}) => {
  return (
    <CustomDialog onClose={onClose} open={true} size="xl" dataCy="userNote.component" custom_size={{ width: '100%', height: '85%' }}>
      <DialogHeader container justifyContent="flex-end" item xs={12}>
        <IconButton onClick={onClose}>
          <StyledCloseIcon />
        </IconButton>
      </DialogHeader>
      <PhotoCatcher
        showChildrenInTakePhoto
        enableUploadImage
        title={title}
        onPhotoTaken={onPhotoTaken}
        onConfirmImage={onConfirmImage}
        itemCode={itemCode}
      >
        {photoAvailable ? (
          <StyledButton color="primary" variant="outlined" size="large" onClick={onClose} style={{ margin: '0rem 3rem' }}>
            cancel
          </StyledButton>
        ) : (
          <StyledButton color="secondary" variant="outlined" size="large" onClick={onSkipPhoto} style={{ margin: '0rem 0.5rem' }}>
            Skip
          </StyledButton>
        )}
      </PhotoCatcher>
    </CustomDialog>
  );
};

export default AddImageDialog;

const DialogHeader = styled(Grid)`
  flex: 0;
`;

const StyledCloseIcon = styled(Close)`
  width: 5rem;
  height: 5rem;
  color: black;
`;
