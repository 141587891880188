import { useMutation } from 'react-query';
import { StationService } from 'services';

interface Options {
  rfid: string;
}
const useRejectItem = () => {
  return useMutation<any, Error, Options>(({ rfid }) => StationService.rejectItem(rfid));
};
export default useRejectItem;
