import React, { useState, useContext } from 'react';

export type ItemJourneyContextType = {
  itemId: string;
  open: boolean;
  orderAlphaId: string;
  updateItemId: (value: string) => void;
  toggle: (value: boolean) => void;
  updateOrderAlphaId: (value: string) => void;
};
export const ItemJourneyContext = React.createContext<ItemJourneyContextType>({} as ItemJourneyContextType);

export const ItemJourneyProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [itemId, setItemId] = useState('');
  const [orderAlphaId, setOrderAlphaId] = useState('');
  const [open, setOpen] = useState(false);

  const updateItemId = (value: string) => setItemId(value);
  const updateOrderAlphaId = (value: string) => setOrderAlphaId(value);

  const toggle = (value: boolean) => {
    setOpen(value);
  };

  return (
    <ItemJourneyContext.Provider value={{ toggle, orderAlphaId, open, itemId, updateItemId, updateOrderAlphaId }}>
      {children}
    </ItemJourneyContext.Provider>
  );
};
export const useItemJourney = () => useContext(ItemJourneyContext);
