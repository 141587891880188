import React from 'react';
import './styles.scss';

/** Interfaces */
import { ColorsList } from 'utils/interfaces';

/** Material-UI */
import { Box } from '@material-ui/core';

interface Props {
  colorsList: ColorsList[];
  isVertical?: boolean;
  withShadows?: boolean;
  textHidden?: boolean;
  dimension?: string;
  withoutLeftMargin?: boolean;
  boxColor?: string;
  colorMargin?: string;
}

const defaultProps = {
  isVertical: false,
  withoutLeftMargin: false,
  withShadow: false,
  textHidden: false,
  dimension: '30px',
  boxColor: '',
};

const ColorMapper: React.FC<Props> = ({
  colorsList,
  dimension = defaultProps.dimension,
  isVertical = defaultProps.isVertical,
  withShadows = defaultProps.withShadow,
  textHidden = defaultProps.textHidden,
  withoutLeftMargin = defaultProps.withoutLeftMargin,
  boxColor = defaultProps.boxColor,
  colorMargin = '0.5rem',
}) => {
  return (
    <Box
      color={boxColor}
      display="flex"
      flexDirection={isVertical ? 'column' : 'row'}
      alignItems="center"
      justifyContent="center"
      justifyItems="center"
    >
      {colorsList &&
        colorsList.map((color, i) => {
          return (
            <Box
              margin={withoutLeftMargin ? '0.5rem 0.5rem 0.5rem 0' : colorMargin}
              display="flex"
              flexDirection="column"
              alignContent="center"
              alignItems="center"
              justifyContent="center"
              width={'20%'}
              key={`${color.hex}-${i}`}
            >
              <div
                key={`${i}-color-mapper`}
                className="color-rounded-div"
                data-testid={i + '-colorDisplay-colorMapper'}
                style={{
                  width: dimension,
                  height: dimension,
                  backgroundColor: color.hex,
                  borderColor: color.hex === '#FFFFFF' ? '#b6bcd1' : undefined,
                  boxShadow: withShadows ? '0px 5px 16px -2px rgba(0,0,0,0.5)' : undefined,
                }}
              />
              {textHidden ? '' : <div data-testid={i + '-colorName-colorMapper'}>{color.name}</div>}
            </Box>
          );
        })}
    </Box>
  );
};

export default ColorMapper;
