import { useMutation, useQueryClient } from 'react-query';
import { StationService } from 'services';
import { ItemMeasurements, ItemTypes } from 'utils/interfaces';
import { IItemReportDetail } from 'utils/interfaces/stainManStationInterfaces';
import { QueryResponse } from './useHandleGetItemReportDetail';

interface Options {
  itemCode: string;
  orderAlphaId: string;
  afterMeasurementsList: ItemMeasurements[];
  itemType: ItemTypes;
}

const useGetItemTypeMeasurementsConfig = () => {
  const queryClient = useQueryClient();

  return useMutation<{}, Error, Options>(({
      itemCode,
      itemType,
      orderAlphaId,
      afterMeasurementsList,
    }) => StationService.setAfterMeasurements(itemCode, orderAlphaId, afterMeasurementsList, itemType),
    {
      onMutate: (variables) => {
        const itemReportDetailQuery = queryClient.getQueryCache().find<IItemReportDetail>(['itemReportDetail'], {
          exact: false,
          active: true,
        });
        const queryKey = itemReportDetailQuery?.options.queryKey as [string, string];

        queryClient.setQueryData<Partial<QueryResponse>>(queryKey, (oldReportDetail) => {
          if (!(oldReportDetail && oldReportDetail.itemReportDetail)) {
            return {};
          }
          return { itemReportDetail: { ...oldReportDetail.itemReportDetail, afterMeasurementsList: [...variables.afterMeasurementsList] } };
        });
      },
    },
  );

};

export default useGetItemTypeMeasurementsConfig;
