import { useMutation } from 'react-query';
import stationService from 'services/station.service';

interface CreateOptions {
  orderAlphaId: string;
  itemCode: string;
  extraItemCodesList: string[];
}

const useRemoveExtraItems = () => {
  return useMutation<string, Error, CreateOptions>(({ orderAlphaId, itemCode, extraItemCodesList }) =>
    stationService.removeExtraItems(orderAlphaId, itemCode, extraItemCodesList)
  );
};

export default useRemoveExtraItems;
