import {
  IOrderItem,
  Brand,
  Bucket,
  ColorsList,
  Programs,
  Preferences,
  DetailsList,
  ItemTypes,
  ShoeAngleImages,
} from '../interfaces/stationInterfaces';

const shoeAngleDefaultValue = {
  top: '',
  backFront: '',
  bottom: '',
  inside: '',
  outside: '',
};

// TODO: new IOrderItem constructor takes props
export default class OrderItem implements IOrderItem {
  id: string = '';
  orderId: string = '';
  orderAlphaId: string = '';
  customerAlphaId: string = '';
  customerId: string = '';
  customerName: string = '';
  bagCode: string = '';
  code: string = '';
  brand?: Brand;
  type?: ItemTypes;
  facilityVisits: number = 0;
  frontImage?: string;
  colorsList?: ColorsList[];
  programs?: Programs;
  preferences?: Preferences;
  stainDetails?: DetailsList[];
  isHung: boolean = false;
  isBanned: boolean = false;
  isSorted: boolean = false;
  isRecurrent: boolean = false;
  cleanType: string = '';
  isRejected: boolean = false;
  bucket?: Bucket;
  rfid: string = '';
  isPackaged?: boolean = false;
  packageCode?: string;
  dropoffDate?: string = '';
  dropoffTime?: string = '';
  utcDropoffDate?: string = '';
  utcDropoffTime?: string = '';
  customerNotes: string = '';
  customerAttachmentsList: string[] = [];
  itemImagesMap?: ShoeAngleImages = shoeAngleDefaultValue;
  itemCode?: string = '';

  constructor(qrCode = '') {
    this.code = qrCode;
    this.itemCode = qrCode;
  }
}
