import React from 'react';
import classNames from 'classnames';

/** Import Enums */
import { ImageSteps } from 'utils/enums/stationEnums';

/** Material UI */
import { makeStyles } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';

/** Import SVG Icons */
import { ReactComponent as ShoeTopIcon } from 'assets/images/svgs/stepperIcons/svg/shoe-top.svg';
import { ReactComponent as ShoeBackFrontIcon } from 'assets/images/svgs/stepperIcons/svg/shoe-back-front.svg';
import { ReactComponent as ShoeBottomIcon } from 'assets/images/svgs/stepperIcons/svg/shoe-bottom.svg';
import { ReactComponent as ShoeInsideIcon } from 'assets/images/svgs/stepperIcons/svg/shoe-inside.svg';
import { ReactComponent as ShoeOutsideIcon } from 'assets/images/svgs/stepperIcons/svg/shoe-outside.svg';
import { ReactComponent as CheckIcon } from 'assets/images/svgs/stepperIcons/svg/check-border.svg';

interface Props {
  name: ImageSteps;
  active?: boolean;
  completed?: boolean;
  disabled?: boolean;
  error?: boolean;
  isDelicate?: boolean;
  onToggleClick?: (name: ImageSteps) => any;
  miniIcon?: boolean;
}

const defaultProps = {
  active: false,
  completed: false,
  disabled: false,
  error: false,
  isDelicate: false,
  onToggleClick: (name: ImageSteps) => console.log('No click handler'),
  miniIcon: false,
};

// FIX ME: Convert to styledComponents
const useStepButtonStyle = makeStyles((theme) => ({
  button: {
    display: 'flex',
    borderRadius: '50%',
  },
  activeStep: {
    width: '7.5rem',
    height: '7.5rem',
  },
  miniIcon: {
    width: '4.5rem',
    height: '4.5rem',
  },
  miniCheckIcon: {
    width: '2.1rem !important',
  },
  checkIcon: {
    position: 'absolute',
    [theme.breakpoints.down('md')]: {
      top: '-25px',
      right: '-5px',
      width: '20px',
    },
    [theme.breakpoints.only('lg')]: {
      top: '-25px',
      right: '-5px',
      width: '40px',
    },
    [theme.breakpoints.up('xl')]: {
      top: '-20px',
      right: '-5px',
      width: '40px',
    },
  },
}));

/** Image Mapper */
export const shoeIconNameMap: { [key: string]: JSX.Element } = {
  top: <ShoeTopIcon width="6rem" height="6rem" />,
  backFront: <ShoeBackFrontIcon width="6rem" height="6rem" />,
  bottom: <ShoeBottomIcon width="6rem" height="6rem" />,
  inside: <ShoeInsideIcon width="6rem" height="6rem" />,
  outside: <ShoeOutsideIcon width="6rem" height="6rem" />,
};

const CustomStepperIcon: React.FC<Props> = ({
  name,
  completed = defaultProps.completed,
  disabled = defaultProps.disabled,
  error = defaultProps.error,
  active,
  onToggleClick = defaultProps.onToggleClick,
  miniIcon = defaultProps.miniIcon,
}) => {
  //Import Styles
  const classes = useStepButtonStyle();
  // Handle onClick action
  const toggleHandler = () => {
    onToggleClick(name);
  };

  let elementWithClassName: JSX.Element = shoeIconNameMap[name];

  // Assign styling
  elementWithClassName = React.cloneElement(elementWithClassName, {
    className: classNames({
      'disabled-stepper': disabled,
      [classes.activeStep]: active && !error && !disabled,
      [classes.miniIcon]: !!miniIcon,
    }),
  });

  // Manipulate the Check SVG
  let checkElementWithClass: JSX.Element = <CheckIcon />;
  checkElementWithClass = React.cloneElement(checkElementWithClass, {
    className: classNames(classes.checkIcon, { 'active-check-mark': !error, [classes.miniCheckIcon]: !!miniIcon }),
  });

  return (
    <ButtonBase className={classes.button} onClick={toggleHandler} disabled={disabled} focusRipple>
      {elementWithClassName}
      {completed && checkElementWithClass}
    </ButtonBase>
  );
};

export default CustomStepperIcon;
