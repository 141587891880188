import React, { useMemo } from 'react';
import moment from 'moment';
import { DateRange, RangeKeyDict } from 'react-date-range';

import DateWrapper, { formatDateToDisplay, IDateComponentProps } from './dateWrapper';

const DateRangeSelector: React.FC<React.PropsWithChildren<IDateComponentProps>> = ({
  field: { onChange, value, ...field } = {},
  selectedDates,
  title,
  onDateSelect = () => {},
  onFocus = () => {},
  minDate,
  maxDate,
}) => {
  const handleDateSelect = (data: RangeKeyDict) => {
    const ranges = { ...data };
    const start = moment(ranges.selection.startDate).startOf('D').toDate();
    const end = moment(ranges.selection.endDate).endOf('D').toDate();
    if (start && end) {
      onChange && onChange({ start, end });
      onDateSelect({ start, end });
    }
  };

  const startDate = useMemo(() => (value ? value.start : selectedDates?.start), [selectedDates, value]);
  const endDate = useMemo(() => (value ? value.end : selectedDates?.end), [selectedDates, value]);

  const formattedDates = useMemo(() => formatDateToDisplay(startDate, endDate), [startDate, endDate]);
  return (
    <DateWrapper title={formattedDates ? formattedDates : title} onFocus={onFocus}>
      {/* //MIGRATION:: react-date-range is still using the old version of react, we need to think of migrating to a better library
      @ts-ignore  */}
      <DateRange
        onChange={handleDateSelect}
        {...field}
        minDate={minDate}
        maxDate={maxDate}
        ranges={[
          {
            startDate: startDate,
            endDate: endDate,
            key: 'selection',
          },
        ]}
      />
    </DateWrapper>
  );
};

export default DateRangeSelector;
