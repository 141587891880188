import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { ItemActions, ItemIssue } from 'utils/interfaces';
import { issueObj } from '../components/modals/itemIssueModalOverview';
import { ReactComponent as StillWet } from 'assets/images/svgs/stainmanIcons/damp-wet.svg';
import {
  ReactComponent as DamageAfterCleaning,
} from 'assets/images/svgs/stainmanIcons/damage-after-cleaning.svg';
import { ReactComponent as SmellsBad } from 'assets/images/svgs/stainmanIcons/smells-bad.svg';
import { ReactComponent as ItemRisk } from 'assets/images/svgs/itemTypes/item-risk.svg';
import { GridRowData } from '@mui/x-data-grid';
import { IssuesActionStatus } from '../enums/stainManEnums';
import React from 'react';
import { issueMapper } from '../components/cards/middleCard';

const NotActioned = styled.p`
  color: #e51c23;
`;

const Actioned = styled.p`
  color: #3e48a6;
`;

const Attempted = styled.p`
  color: #19a784;
`;

const RejectedItem = styled.p`
  color: #f5a623;
`;

const IconContainer = styled(Grid)`
  padding: 0.1rem;

  svg {
    width: 2rem;
    height: 2rem;
  }

  .stroke-and-fill > g,
  .stroke-and-fill > path {
    fill: #3e48a6;
    stroke: #3e48a6;
  }

  .damage-after-cleaning > g path {
    stroke: blue;
  }

  .damage-after-cleaning > g rect {
    fill: red;
  }

  .item-at-risk path:nth-child(1) {
    fill: #3e48a6;
  }

  .item-at-risk path:nth-child(2) {
    stroke: #ffc905;
  }

  .item-at-risk path:nth-child(n + 3) {
    fill: #ffc905;
  }
`;

const getCellClass = (customClass?: string) => customClass ? `cellClass ${customClass}` : 'cellClass';

const getReportedType = (isStained: boolean, isDamaged: boolean) => {
  if (isStained && isDamaged) {
    return 'Both';
  } else if (isStained) {
    return 'Stained';
  } else if (isDamaged) {
    return 'Damaged';
  } else {
    return 'none';
  }
};

const excludedIcons: (ItemActions | '')[] = [ItemActions.CLEANED, ItemActions.FIXED];

const issuesIconMapper: { [key in ItemActions | '']?: issueObj & { customColors?: boolean } } = {
  ...issueMapper,
  '': undefined,
  STILL_WET: {
    icon: <StillWet className='stroke-and-fill' />,
    customColors: true,
  },
  DAMAGE_AFTER_CLEANING: {
    icon: <DamageAfterCleaning className='damage-after-cleaning' />,
    customColors: true,
  },
  SMELLS_BAD: {
    icon: <SmellsBad className='stroke-and-fill' />,
    customColors: true,
  },
  ITEM_AT_RISK: {
    icon: <ItemRisk className='item-at-risk' />,
    customColors: true,
  },
};

function getAction(row: GridRowData) {
  // As GridRowData is of Type Record with 'any' as values
  // i didn't destucture here as to provied types for the values used

  const isStained: boolean = row.isStained;
  const isDamaged: boolean = row.isDamaged;
  const isRejected: boolean = row.isRejected;
  const stainsActionStatus: number = row.stainsActionStatus;

  if (isRejected) return <RejectedItem>Item Rejected</RejectedItem>;
  if (isDamaged && !isStained) return <p>Damage Only</p>;
  if (!(isDamaged || isStained)) return <p>No Issues</p>;

  switch (stainsActionStatus) {
    case IssuesActionStatus.NOT_ACTIONED:
      return <NotActioned>Not Actioned</NotActioned>;
    case IssuesActionStatus.ACTION_ATTEMPTED:
      return <Attempted>Action Attempted</Attempted>;

    case IssuesActionStatus.ACTIONED:
      return <Actioned>Actioned</Actioned>;

    case IssuesActionStatus.NO_ISSUES:
    default:
      return <p>No Issues</p>;
  }
}

const actionComponent = (row: GridRowData) => {
  const issues: ItemIssue[] = row.itemIssues || [];

  const uniqueIssues = new Set(
    issues
      .filter((issue) => !issue.isActionable || (issue.action && !excludedIcons.includes(issue.action)))
      .filter((issue) => issue.reason === 'STAINED')
      .map((issue) => (!issue.isActionable ? (issue.reason as keyof typeof ItemActions) : issue.action)),
  );

  const uniqueIssuesArrayOfThrees = Array.from(uniqueIssues);
  return (
    <Grid container justifyContent='space-between' alignItems='center'>
      <Grid item xs={5}>
        {getAction(row)}
      </Grid>

      <Grid container justifyContent='center' alignItems='center' item wrap='wrap' xs={5}>
        {uniqueIssuesArrayOfThrees.map(
          (issue) =>
            issuesIconMapper[issue] && (
              <IconContainer key={issue} container item xs={4}>
                {issuesIconMapper[issue]?.icon}
              </IconContainer>
            ),
        )}
      </Grid>
    </Grid>
  );
};

export {
  getCellClass,
  getReportedType,
  actionComponent,
};
