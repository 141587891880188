import React, { ChangeEvent } from 'react';
import TextField from '@material-ui/core/TextField';
import {
  Autocomplete,
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  createFilterOptions,
  FilterOptionsState,
} from '@material-ui/lab';
import { ControllerRenderProps } from 'react-hook-form';
import { Checkbox, Chip, Grid } from '@material-ui/core';

import styled from 'styled-components';

type FilterOptions = { label: string; value: string; inputValue?: string };

const filter = createFilterOptions<{ label: string; value: string; inputValue?: string }>();

interface Props {
  toggleKeyboard?: (value: boolean) => void;
  handleInputChange?: (value: string) => void;
  inputValue?: string;
  toggleOptionsForKeyboard?: boolean;
  title: string;
  isMultipleSelection?: boolean;
  filterOptions: { label: string; value: string }[];
  filterKey: string;
  field?: ControllerRenderProps;
  defaultValue: { label: string; value: string }[];
  onChangeHandler?: (value: { label: string; value: string }[]) => void;
  creatable?: boolean;
  modifyInputValue?: (value: string) => string;
  renderOptionAndLabel?: (value: string) => string;
  limitTags?: number;
}

/**
 * @description Highly customizable Select fiterBox that is Searchable and Creatable (Can create new values)
 */
const AutocompleteBox: React.FC<Props> = ({
  filterOptions,
  filterKey,
  toggleKeyboard,
  handleInputChange,
  inputValue = '',
  toggleOptionsForKeyboard,
  title,
  isMultipleSelection = true,
  field,
  defaultValue,
  limitTags,
  onChangeHandler = (value) => {},
  creatable,
  modifyInputValue,
  renderOptionAndLabel = (v) => v,
}) => {
  //Used for creatable autocomplete filterBox
  const filterCreatableOptions = (options: FilterOptions[], params: FilterOptionsState<FilterOptions>) => {
    const filtered = filter(options, params);
    // Suggest the creation of a new value
    if (creatable && params.hasOwnProperty('inputValue') && params.inputValue !== '') {
      const modifiedInputValue = modifyInputValue ? modifyInputValue(params.inputValue) : params.inputValue;
      filtered.push({
        value: modifiedInputValue,
        label: `Add "${modifiedInputValue}"`,
        inputValue: modifiedInputValue,
      });
    }
    return filtered;
  };

  const renderOption = (option: FilterOptions, state: AutocompleteRenderOptionState) => (
    <React.Fragment>
      <CustomCheckBox color="primary" checked={state.selected} />
      {renderOptionAndLabel(option.label)}
    </React.Fragment>
  );

  const getOptionLabel = (option: FilterOptions) => {
    // Display option created dynamically
    if (creatable && option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return renderOptionAndLabel(option.label);
  };

  //interface AutocompleteGetTagProps is not properly implemented in the lab component, a fix was added for it in the Stable release
  // In MUI > v5.
  const renderTags = (values: FilterOptions[], getTagProps: AutocompleteGetTagProps) => (
    <TagsWrapper container alignItems="center">
      {values.map((option, index) => (
        <CustomChip {...getTagProps({ index })} color="primary" variant="outlined" key={index} label={option.inputValue ?? option.label} />
      ))}
    </TagsWrapper>
  );

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      size="medium"
      variant="outlined"
      fullWidth
      label={title}
      inputProps={{
        ...params.inputProps,
        style: field?.value.length && !toggleOptionsForKeyboard ? { padding: 0 } : {},
        onKeyDown: (e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
          }
        },
      }}
      onChange={(event: ChangeEvent<HTMLInputElement>) => handleInputChange && handleInputChange(event.target.value)}
    />
  );
  return (
    <Autocomplete<FilterOptions, boolean, boolean | undefined, boolean | undefined>
      {...field}
      onChange={(ev, value) => {
        const valuesArray = value as FilterOptions[];
        //We're handling single selection the same way as multiple selections, as it would introduce many code changes to handle both cases, on a full-stack level
        onChangeHandler((isMultipleSelection ? valuesArray : valuesArray.length ? [valuesArray[valuesArray.length - 1]] : []) || []);
      }}
      filterOptions={filterCreatableOptions}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      key={filterKey}
      limitTags={limitTags}
      getOptionSelected={(option, value) => option.value === value.value}
      multiple={true}
      freeSolo={creatable}
      defaultValue={defaultValue}
      id={filterKey}
      options={filterOptions}
      onMouseDownCapture={() => toggleKeyboard && toggleKeyboard(!toggleOptionsForKeyboard)}
      open={toggleOptionsForKeyboard}
      disableCloseOnSelect
      inputValue={inputValue}
      fullWidth
      ChipProps={{}}
      renderTags={renderTags}
      onClose={() => !toggleOptionsForKeyboard && handleInputChange && handleInputChange('')}
      renderInput={renderInput}
    />
  );
};
export default AutocompleteBox;
const CustomCheckBox = styled(Checkbox)`
  margin-right: 8px;
`;

const TagsWrapper = styled(Grid)`
  padding-right: 1.5rem;
`;
const CustomChip = styled(Chip)`
  .MuiChip-deleteIcon {
    width: 2rem;
    height: 2rem;
    margin: 0;
  }
`;
