import { useMutation } from 'react-query';
import stationService from 'services/station.service';

interface CreateOptions {
  trayCode: number;
  itemCode: string;
  orderAlphaId: string;
}

const useLinkTrayToItem = () => {
  return useMutation<unknown, Error, CreateOptions>(
    ({ itemCode, orderAlphaId, trayCode }) => stationService.linkTrayToItem(itemCode, orderAlphaId, trayCode),
    {}
  );
};

export default useLinkTrayToItem;
