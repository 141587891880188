/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Grid, Typography, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';

import { useStationStore } from 'stores';
import { HeaderDialog } from 'components/dialogs';
import { itemTypeIconMap } from 'utils/maps';
import { IOrderItem } from 'utils/interfaces';
import { getTypeByValue } from 'utils/maps/typeMapper';

import alert from 'assets/images/pngs/alert.png';

const DIFFERENT_ORDER_DIALOG_TITLE = 'Item belongs to an order that is in status: Order Completed';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    garmentImgHeight: {
      height: '20rem',
    },
    alertImgHeight: {
      height: '10rem',
    },
    borderBottom: {
      borderBottom: '.1rem solid #b6bcd1',
      padding: '1rem 3rem .2rem 1rem',
    },
    padding: {
      padding: '1rem',
    },
    garmentImagePadding: {
      paddingBottom: '1rem',
    },
    alertImage: {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: '1rem',
    },
    alertOrderInfo: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginLeft: '.1rem',
    },
    alertTitle: {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: '1rem',
    },
  })
);

interface Props {
  onClose: () => void;
}

const OrderCompletedDialog: React.FC<Props> = observer(({ onClose }) => {
  const classes = useStyles();

  const { currentStation, orderAlreadyCompletedDialog, setOrderAlreadyCompletedDialog } = useStationStore();

  const { typeObj } = orderAlreadyCompletedDialog && getTypeByValue(orderAlreadyCompletedDialog.type);
  const typeIcons = typeObj && itemTypeIconMap[typeObj.value!];

  useEffect(() => {
    onCloseHandler();
  }, [currentStation]);

  const onCloseHandler = () => {
    setOrderAlreadyCompletedDialog({} as IOrderItem);
    onClose();
  };

  return (
    <HeaderDialog
      item={orderAlreadyCompletedDialog}
      open={Boolean(Object.keys(orderAlreadyCompletedDialog).length > 0)}
      onClose={onCloseHandler}
      typeIcons={typeIcons}
    >
      <Grid container direction="row" style={{ padding: '1rem' }} justify="center">
        <Grid container direction="column" item xs={10} wrap="nowrap" style={{ textAlign: 'center' }}>
          <Grid item className={classes.alertImage}>
            <img src={alert} className={classes.alertImgHeight} alt="frontImage" />
          </Grid>
          <Grid container direction="column" className={classes.padding} alignItems="center">
            <Grid item className={classes.alertTitle}>
              <Typography variant="h3">{DIFFERENT_ORDER_DIALOG_TITLE}</Typography>
            </Grid>
            <Grid item xs={'auto'} className={classes.alertOrderInfo}>
              <Typography variant="h4">Order Id. {orderAlreadyCompletedDialog!.orderAlphaId}</Typography>
            </Grid>
            <Grid item xs={'auto'} className={classes.alertOrderInfo}>
              <Typography variant="h4">Customer Id. {orderAlreadyCompletedDialog!.customerAlphaId}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item justify="center" alignContent="center" className={classes.padding}>
          <Grid item>
            <Button onClick={onCloseHandler} variant="contained" size="large" color="primary">
              I Understand
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </HeaderDialog>
  );
});

export default OrderCompletedDialog;
