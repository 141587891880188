import React from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';

import { Preferences } from 'utils/interfaces';

import { ReactComponent as Checkmark } from 'assets/images/svgs/checkmark.svg';

const buildDisplayString = (shoeAddonApprovals: Preferences['shoeAddOnApprovals']) => {
  const displayChunks: string[] = [];
  if (shoeAddonApprovals) {
    const { restoration, soleIcing, stainProtection } = shoeAddonApprovals;
    restoration && displayChunks.push('light restoration');
    stainProtection && displayChunks.push('stain protection');
    soleIcing && displayChunks.push('icing');
  }
  return displayChunks.join(', ');
};

interface Props {
  approvals: Preferences['shoeAddOnApprovals'];
}
const ShoeAddonApprovals: React.FC<Props> = ({ approvals }) => {
  return approvals?.restoration || approvals?.soleIcing || approvals?.stainProtection ? (
    <ApprovalsBox className="shoe-addon-approvals-box" display={'flex'} alignItems={'center'} padding={'1rem'}>
      <Checkmark />
      <span>Add on: {buildDisplayString(approvals)}</span>
    </ApprovalsBox>
  ) : (
    <></>
  );
};

export default ShoeAddonApprovals;

const ApprovalsBox = styled(Box)`
  background-color: #d3f4ec;
  border-radius: 2.5rem;
  font-size: 1.75rem;
  color: #00b8ab;
  max-width: fit-content;
  gap: 1rem;
`;
