import { useMutation } from 'react-query';
import { StationService } from 'services';

interface UnlinkItemToSaItemOptions {
    itemRfid: string;
    specialAttentionItemId: string;
}
const useUnlinkItemToSaItem = () => {
    return useMutation<void, Error, UnlinkItemToSaItemOptions>(({ itemRfid, specialAttentionItemId }) =>
        StationService.unlinkItemToSpecialAttentionItem(itemRfid, specialAttentionItemId)
    );
};
export default useUnlinkItemToSaItem;
