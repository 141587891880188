import { Button, Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { green } from 'assets/colors';

// Reference: https://material-ui.com/components/buttons/#customized-buttons

export const GreenButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green['700'],
    },
  },
}))(Button);
