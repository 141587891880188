

import { useMutation } from 'react-query';
import { StationService } from 'services';
import { IOrderItem } from 'utils/interfaces';


interface MutationVariables {
    rfidCode: string;
}

export const useGetItemByRfid = () => {
    return useMutation<IOrderItem, Error, MutationVariables>(({ rfidCode }) =>
        StationService.getOrderItemByRfidCode(rfidCode)
    );
};
