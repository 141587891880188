import { useQuery } from 'react-query';
import { StationService } from 'services';
import { IStationsList } from 'utils/interfaces';

export const STATIONS_LIST_QUERY_KEY = 'stations-list';

const useGetStationsList = () => {
  const stationsListQuery = useQuery<IStationsList, Error>([STATIONS_LIST_QUERY_KEY], () => StationService.fetchStationsList(), {
    refetchOnWindowFocus: false,
  });

  return {
    ...stationsListQuery,
    stationSections: stationsListQuery.data?.stationSections,
    stationNumbers: stationsListQuery.data?.stationNumbers,
  };
};

export default useGetStationsList;
