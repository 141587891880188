import React from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DateRangeIcon from '@material-ui/icons/DateRange';
import { Menu, Button } from '@material-ui/core';
import styled from 'styled-components';
import { ControllerRenderProps } from 'react-hook-form';
import moment from 'moment';

export interface IDateComponentProps {
  selectedDates?: { start: Date; end?: Date };
  title: string;
  onFocus?: () => void;
  onDateSelect?: (date: { start: Date; end: Date }) => void;
  minDate?: Date;
  maxDate?: Date;
  field?: ControllerRenderProps;
}
export const formatDateToDisplay = (start: Date, end: Date) => {
  const formattedStartDate = moment(start).format('dd DD MMM');
  const formattedEndDate = moment(end).format('dd DD MMM');
  return formattedStartDate !== formattedEndDate ? formattedStartDate.concat(` / ${formattedEndDate}`) : formattedStartDate;
};
export interface IDateWrapper {
  title: string;
  onFocus: () => void;
}
const DateWrapper: React.FC<React.PropsWithChildren<IDateWrapper>> = ({ title, children, onFocus }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    onFocus();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CustomButton
        startIcon={
          <IconWrapper>
            <DateRangeIcon />
          </IconWrapper>
        }
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <span> {title}</span>
      </CustomButton>
      <StyledMenu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={!!anchorEl}
        onClose={handleClose}
      >
        {children}
      </StyledMenu>
    </>
  );
};

export default DateWrapper;

const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    border: #b6bcd1 3px solid;
    border-radius: 6px;
  }
`;
const IconWrapper = styled.div`
  width: 3rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #b6bcd1;
  background-color: #f8f9fb;
  border-radius: 1.3rem 0 0 1.3rem;
`;

const CustomButton = styled(Button)`
  text-transform: inherit;
  background-color: white;
  border: 1px solid #b6bcd1;
  height: 3.4rem;
  width: -webkit-fill-available;
  color: #111135;
  font-size: 1rem;
  font-weight: 400;
  justify-content: end;
  padding: 0.3rem;
  border-radius: 2rem;

  .MuiButton-startIcon {
    height: 3.125rem;
    width: 3.75rem;
  }
  .MuiButton-label {
    height: 3.2rem;
    justify-content: start;
  }
`;
