import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import lodash from 'lodash';

import { DelayStatus, IPendingItem, IOrderWithItems, ItemTypes } from 'utils/interfaces';
import { ValidateManagerDialog } from 'components/dialogs';
import DelayDialog from '../components/dialogs/delayDialog';
import OrderCompleteDialog from '../components/dialogs/orderCompleteDialog';
import { Toaster } from 'components/toaster';
import { useItemDelayQuery } from '../mutations';
import { useGetSelectedItem } from '../hooks';
import { typeKeyObjHelper } from 'utils/maps/typeMapper';
import { DelayOptions } from 'services/pending.service';

export interface ItemDetails {
  itemId: string;
  orderAlphaId: string;
  dropoffDate?: string;
  orderId?: string;
  packagedItemsCodes?: string[];
  packageId?: string;
  isUrgent?: boolean;
  itemType?: ItemTypes;
  customerAlphaId?: string;
}
const twoDayDelayItemTypes: ItemTypes[] = ['COMFORTER', 'DUVET', 'PILLOW', 'SOFA_COVER_REGULAR', 'SOFA_COVER_LARGE', 'CURTAIN_SQM'];

export type AnswerDelaysContextType = {
  handleItemDelay: (itemDetails: ItemDetails, autoRefetchData?: boolean) => void;
  answerNoDelay: (itemDetails: ItemDetails, autoRefetchData?: boolean) => void;
  currentItem?: IPendingItem;
  currentOrder?: IOrderWithItems;
  invalidateTableData: () => Promise<void>;
};

export const AnswerDelaysContext = React.createContext<Partial<AnswerDelaysContextType>>({});

/** @description Handles delays logic and UI needed for answering delays */
export const AnswerDelaysProvider: React.FC<
  React.PropsWithChildren<{
    validateManagerFirst?: boolean;
    queryKey: string;
    refreshDataOnUpdate?: boolean;
  }>
> = ({ children, validateManagerFirst = false, queryKey, refreshDataOnUpdate = true }) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useItemDelayQuery(refreshDataOnUpdate);
  // const [booleanStates,setBoolStates] = React.useReducer()
  const [autoRefetch, setAutoRefetch] = useState(true);
  const [currentDetails, setCurrentDetails] = useState<ItemDetails>({
    itemId: '',
    orderAlphaId: '',
    packageId: '',
  });
  const [updatedOrderStatus, setUpdatedOrderStatus] = useState<DelayStatus>(0);
  const { currentItem, currentOrder } = useGetSelectedItem(currentDetails.itemId, currentDetails.orderAlphaId, queryKey);

  const [isDelayDialogOpen, setIsDelayDialogOpen] = useState(false);
  const [isManagerDialogOpen, setIsManagerDialogOpen] = useState(false);
  const [delayDays, setDelayDays] = useState(1);
  const [isManagerValidated, setIsManagerValidated] = useState(false);
  const [orderCompleteDialogOpen, setOrderCompleteDialogOpen] = useState(false);

  /** @description Clear cached data */
  const clearData = () => {
    setAutoRefetch(true);
    setOrderCompleteDialogOpen(false);
    setIsManagerValidated(false);
    setIsManagerDialogOpen(false);
    setCurrentDetails({ itemId: '', orderAlphaId: '', packageId: '' });
    setIsDelayDialogOpen(false);
    setDelayDays(1);
  };

  const incrementDelayDays = () => setDelayDays((prev) => prev + 1);
  const decrementDelayDays = () => setDelayDays((prev) => prev - 1);

  /** @description Answer delay by Yes (is delayed)*/
  const handleDelaySubmit = async (reportedReason: string, type: 'Pending' | 'Damaged') => {
    await submitAnsweredDelay(true, currentDetails, reportedReason, type);
    setIsDelayDialogOpen(false);
    return true;
  };
  /**
   * @description
   * Submits delay through mutation,
   * ----------------------------------------
   * else it refetches data
   * @param isDelayed
   * @param orderItem
   *
   * @param reportedReason
   * @param type
   */
  const submitAnsweredDelay = async (isDelayed: boolean, orderItem: ItemDetails, reportedReason?: string, type?: DelayOptions['type']) => {
    const returnedData = await mutateAsync({
      itemsCodesList: lodash.isEmpty(orderItem?.packagedItemsCodes)
        ? [orderItem?.itemId || currentItem?.code || '']
        : [...(orderItem?.packagedItemsCodes || [])],
      delayDays,
      delayReason: reportedReason || '',
      isDelayed,
      orderId: orderItem?.orderId || currentOrder?.orderId || '',
      packageId: currentDetails.packageId,
      type,
    });

    if (isDelayed) {
      await handleRefetchData(updatedOrderStatus);
      toast(<Toaster message="Delay added successfully" type="success" />);
      setUpdatedOrderStatus(returnedData.order.delayStatus);
    } else {
      await handleRefetchData(returnedData.order.delayStatus);
      toast(<Toaster message="No delay submitted" type="success" />);
    }
  };

  /**
   * @description
   * Refetch table data & Clear cache if needed
   *
   * @param clear
   */
  const invalidateQuery = async (clear = true) => {
    // toggleResetValues(delayDetails);
    await queryClient.invalidateQueries(queryKey, { exact: false, refetchActive: true, refetchInactive: false });
    clear && clearData();
  };
  //TODO: refactor this logic to be in parent component to avoid state leaking

  /**
   * @description
   * Displays Order complete dialog if order fits crtiera
   * ---------------------------------
   * else it fetches new data and clears the cache
   * @param delayStatus
   */
  const handleRefetchData = async (delayStatus: number) => {
    if ([DelayStatus.IS_DELAYED, DelayStatus.NOT_DELAYED].includes(delayStatus)) {
      setOrderCompleteDialogOpen(true);
    } else {
      autoRefetch && (await invalidateQuery());
      clearData();
    }
  };

  /**
   * @description
   * User hit Delay button Yes, so we start the delay flow by setting the required data for this item
   * ---------------------------
   * We update default delay days to 2 only if order is urgent and the item type matches one from twoDayDelayItemTypes array
   * @param itemDetails
   * @param autoRefetchData
   */
  const handleItemDelay = async (itemDetails: ItemDetails, autoRefetchData = true) => {
    setAutoRefetch(autoRefetchData);
    setCurrentDetails(itemDetails);

    if (itemDetails.isUrgent && twoDayDelayItemTypes.includes(typeKeyObjHelper(itemDetails.itemType || '').value)) {
      setDelayDays((prev) => (prev === 1 ? 2 : 1));
    }
    if (validateManagerFirst) {
      setIsManagerDialogOpen(true);
    } else {
      setIsDelayDialogOpen(true);
    }
  };

  /**
   * @description
   * User hit Delay button No, so we submit delay for this item immediatly, no further flow is required

   * @param itemDetails
   * @param autoRefetchData
   */
  const answerNoDelay = async (itemDetails: ItemDetails, autoRefetchData = true) => {
    setAutoRefetch(autoRefetchData);
    setCurrentDetails(itemDetails);
    submitAnsweredDelay(false, itemDetails);
  };
  /**
   * @description
   * Increment delay days on successful scan
   * @param isValid
   */
  const handleManagerScan = (isValid: boolean) => {
    setIsManagerValidated(isValid);
    if (isValid) {
      if (validateManagerFirst) setIsDelayDialogOpen(true);
      else incrementDelayDays();

      setIsManagerDialogOpen(false);
    }
  };

  const decrementDelayCount = () => {
    if (!isManagerValidated) {
      setIsManagerDialogOpen(true);
    } else {
      decrementDelayDays();
    }
  };

  const incrementDelayCount = () => {
    if (!isManagerValidated) {
      setIsManagerDialogOpen(true);
    } else {
      incrementDelayDays();
    }
  };

  return (
    <AnswerDelaysContext.Provider
      value={{
        handleItemDelay,
        answerNoDelay,
        invalidateTableData: invalidateQuery,
        currentItem,
        currentOrder,
      }}
    >
      {children}
      <DelayDialog
        incrementCount={incrementDelayCount}
        decrementCount={decrementDelayCount}
        open={isDelayDialogOpen}
        onClose={(clear) => {
          setIsDelayDialogOpen(false);
          clear && clearData();
        }}
        incrementedNumber={delayDays}
        isDecrementDisabled={delayDays === 1 ? true : false}
        onSubmit={handleDelaySubmit}
        isSubmitButtonDisabled={isLoading}
      />
      <OrderCompleteDialog
        dropoffDate={currentOrder?.dropoffDate ?? currentDetails.dropoffDate ?? ''}
        orderAlphaId={currentOrder?.orderAlphaId ?? currentDetails.orderAlphaId ?? ''}
        customerAlphaId={currentOrder?.customerAlphaId ?? currentDetails.customerAlphaId ?? ''}
        onClose={async () => {
          setOrderCompleteDialogOpen(false);
          autoRefetch && (await invalidateQuery());
          clearData();
        }}
        open={orderCompleteDialogOpen}
      />
      <ValidateManagerDialog
        onClose={() => setIsManagerDialogOpen(false)}
        open={isManagerDialogOpen}
        onManagerScan={(isValid) => handleManagerScan(isValid)}
      />
    </AnswerDelaysContext.Provider>
  );
};

export const useAnswerDelays = () => useContext(AnswerDelaysContext);
