import { AxiosRequestConfig } from 'axios';
import {
  QueryResponse as OrdersPickupListResponse,
  QueryFilters as OrdersPickupListFilters,
} from 'modules/stations/receivingStation/queries/useGetOrdersPickupList';

import {
  QueryResponse as OrdersDropoffListResponse,
  QueryFilters as OrdersDropoffListFilters,
} from 'modules/stations/receivingStation/queries/useGetOrdersDropoffList';
import Axious from './api';

class ReceivingService {
  public async getPickupOrders(filters: OrdersPickupListFilters) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `orders/bags/list-receiving`,
      params: filters,
    };
    const response = await Axious.request<OrdersPickupListResponse>(requestOptions);
    return response.data;
  }

  public async getDropoffOrders(filters: OrdersDropoffListFilters) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `orders/packages/list-receiving`,
      params: filters,
    };
    const response = await Axious.request<OrdersDropoffListResponse>(requestOptions);
    return response.data;
  }
}

const receivingService = new ReceivingService();

export default receivingService;
