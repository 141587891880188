import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { StainManService } from 'services';
import { IStainManFilters, PaginationMeta } from './useGetOrdersWithStainedItemsList';
import { useQueryFilters } from 'hooks';

const PDF_ITEMS_PER_PAGE = 100;
export interface QueryResponse {
  s3FilePath: string;
}

interface IPrintTableItems extends IStainManFilters {
  offset: number;
}

const usePrintTableItems = (filters: IStainManFilters, pageNumber: number, enabled: boolean, paginationMeta: PaginationMeta) => {
  const { hasNextPage, itemsPerPage: originalItemsPerPage } = paginationMeta;
  const updatedPageNumber = useMemo(() => (!hasNextPage && pageNumber > 1 ? pageNumber - 1 : pageNumber), [pageNumber, hasNextPage]);
  const queryFilters = useQueryFilters<IPrintTableItems>(
    { pageNumber: updatedPageNumber, offset: (updatedPageNumber - 1) * originalItemsPerPage },
    filters
  );

  const { data, isSuccess, isError, isFetching, isIdle } = useQuery<QueryResponse, Error>(
    ['printStainedItemsList', updatedPageNumber, { ...queryFilters }],
    () =>
      StainManService.printToPDF({
        ...queryFilters,
        itemsPerPage: PDF_ITEMS_PER_PAGE,
      }),
    {
      initialData: { s3FilePath: '' },
      refetchOnWindowFocus: false,
      enabled: enabled,
    }
  );
  return {
    pdfLink: data?.s3FilePath || '',
    isSuccess,
    isError,
    isFetching,
    isIdle,
  };
};
export default usePrintTableItems;
