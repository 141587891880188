import React, { useState } from 'react';
import { Grid, Button, Theme, Typography, Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { GreenButton } from 'components/buttons';
import { Toaster } from 'components/toaster';
import { ConfirmDialog } from 'components/confirmDialog';
import { LoaderComponent } from 'components/loaderComponent';
import { ImagePopup } from 'components/imagePopup';
import { IOrderItem, OrderServiceLines } from 'utils/interfaces';
import { useUpdateItemRequest, useRejectItem, useInformAndProceed, useSendItemToApproval } from 'modules/stations/shoeSorterStation/mutations';
import useGetBag from 'modules/stations/shoeSorterStation/queries/useGetBag';
import { ComponentProps, useGetSelectedItem } from 'modules/stations/components/SortingStepper/SortingStepper';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import alert from 'assets/images/pngs/alert.png';
import { TypeValueMapper } from 'utils/maps/typeValueMapper';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flex: '1',
    border: 'solid 2px #b6bcd1',
    margin: theme.spacing(3),
    width: 'auto',
    borderRadius: '8px',
    minHeight: 0,
    flexWrap: 'nowrap',
    position: 'relative',
  }, tableHeader: {
    backgroundColor: '#e8e9f4', padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  }, btnsContainer: {
    padding: theme.spacing(3), borderTop: 'solid 2px #b6bcd1',
  }, itemRow: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`, borderBottom: 'solid 2px #b6bcd1',
  }, itemsContainer: {
    overflow: 'auto', flexWrap: 'nowrap',
  }, mainWrapper: {
    minHeight: 0, flexWrap: 'nowrap', textAlign: 'center',
  }, controlBtns: {
    minWidth: '50px', minHeight: '50px', borderRadius: '5rem', padding: 0, margin: '0 .5rem',
  },
}));

interface Props extends ComponentProps {
  autoSave?: boolean;
  handleOnFinish?: () => void;
}

const ReportOverView: React.FC<Props> = ({
  autoSave = false, handleOnFinish = () => {
  }, onSelect,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { data: bag } = useGetBag();
  const { mutateAsync: updateItemRequest } = useUpdateItemRequest();
  const { mutateAsync: rejectItem } = useRejectItem();
  const { mutateAsync: sendItemToApproval } = useSendItemToApproval();
  const { mutateAsync: informAndProceed } = useInformAndProceed();
  const { selectedItem: currentItem } = useGetSelectedItem();

  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const isBtnDisabled = !(currentItem.reportDetailsList && currentItem.reportDetailsList.length > 0);
  const addStainDamage = () => {
    navigate('../');
  };

  const deleteReport = (index: number) => {
    const newItem = deleteReportFromItem(index);
    if (newItem) onSelect(newItem, { disableRouting: true });
    toggleDialog();
  };

  const deleteReportFromItem = (index: number) => {
    if (!currentItem.reportDetailsList) {
      return;
    }
    const newStainDetailsList = [...currentItem.reportDetailsList];
    newStainDetailsList.splice(index, 1);
    return {
      ...currentItem, reportDetailsList: newStainDetailsList,
    };
  };

  const toggleDialogItemIndex = (index: number) => {
    setIndex(index);
    setOpen(!open);
  };
  const toggleDialog = () => {
    setOpen(!open);
  };

  const editReport = (index: number) => {
    navigate(`../editDetails/${index}`);
  };

  const handleUpdateItem = async (item: IOrderItem) => {
    await updateItemRequest({ item }, {
      onSuccess: (data) => {
        onSelect({ ...data });
      },
    });
  };
  const rejectGarment = async () => {
    await handleUpdateItem(currentItem);
    await rejectItem({ rfid: currentItem.rfid }, {
      onSuccess: () => {
        toast(<Toaster message="Item Rejected" type="success" />);
        handleOnFinish();
      },
    });
  };

  const sendToApproval = async () => {
    await handleUpdateItem(currentItem);
    await sendItemToApproval({ rfid: currentItem.rfid }, {
      onSuccess: () => {
        toast(<Toaster message="Item Sent to Approval" type="success" />);
        handleOnFinish();
      },
    });
  };

  const onProceed = async () => {
    await informAndProceed({ rfid: currentItem.rfid }, {
      onSuccess: async () => {
        if (autoSave) {
          await handleUpdateItem(currentItem);
        }
        handleOnFinish();
      },
    });
  };
  const handleInformAndProceedDisable = () => {
    if (currentItem.orderServiceLine === OrderServiceLines.FINERY) {
      return false
    }
    return bag.order.preferences?.isCallCustomerBeforeProcessing
  }
  return (<Grid container direction="column" justifyContent="space-between" classes={{ container: classes.root }}>
    <LoaderComponent />
    <Grid classes={{ container: classes.mainWrapper }} container item direction="column">
      <Grid container item xs direction="row" justifyContent="space-between" classes={{ container: classes.tableHeader }}>
        <ConfirmDialog onClose={toggleDialog} image={alert} open={open} title={`Are you sure you want to delete this report ?`}>
          <Box padding="1rem">
            <Button onClick={toggleDialog} color="primary" variant="outlined" size="large">
              Cancel
            </Button>
          </Box>
          <Box padding="1rem">
            <Button variant="contained" color="primary" size="large" onClick={() => deleteReport(index)}>
              Confirm
            </Button>
          </Box>
        </ConfirmDialog>
        <Grid item xs>
          <Typography variant="h4">Image</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="h4">Report Type</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="h4">Type</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="h4">Part</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="h4">Location</Typography>
        </Grid>
        <Grid item xs></Grid>
      </Grid>
      <Grid classes={{ container: classes.itemsContainer }} container direction="column" alignItems="flex-start">
        {currentItem.reportDetailsList && currentItem.reportDetailsList.map((report, i) => (
          <Grid container item xs direction="row" justifyContent="space-between" key={i} classes={{ item: classes.itemRow }}>
            <Grid item xs>
              <ImagePopup imageUrl={report.imageUrl} index={i} />
            </Grid>
            <Grid item xs>
              <Typography variant="h4">{report.reason}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h4">{TypeValueMapper[report.type]}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h4">{report.part}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h4">{report.location}</Typography>
            </Grid>
            <Grid item xs>
              <Button color="primary" variant="contained" classes={{ root: classes.controlBtns }} onClick={() => editReport(i)}>
                <EditIcon />
              </Button>
              <Button color="secondary" variant="contained" classes={{ root: classes.controlBtns }} onClick={() => toggleDialogItemIndex(i)}>
                <DeleteIcon />
              </Button>
            </Grid>
          </Grid>))}
      </Grid>
    </Grid>
    <Grid container item alignItems="center" justifyContent="space-evenly" classes={{ container: classes.btnsContainer }}>
      <Button color="secondary" variant="outlined" size="large" onClick={addStainDamage}>
        Add Stain/Damage
      </Button>
      <Button color="secondary" variant="contained" size="large" onClick={rejectGarment} disabled={isBtnDisabled}>
        Reject Garment
      </Button>
      <Button color="primary" variant="contained" size="large" onClick={sendToApproval} disabled={isBtnDisabled}>
        Send to approval
      </Button>
      <GreenButton variant="contained" size="large" onClick={onProceed}
                   disabled={handleInformAndProceedDisable()}>
        inform and proceed
      </GreenButton>
    </Grid>
  </Grid>);
};

export default ReportOverView;
