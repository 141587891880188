import React, { useState } from 'react';
import { CustomDialog } from 'components/dialogs';
import { LoaderComponent } from 'components/loaderComponent';
import { Button, Box } from '@material-ui/core';
import LabelSelector, { Props as LabelSelectorProps } from 'modules/stations/sorterStation/components/labelSelector/LabelSelector';
import { ItemTypes } from 'utils/enums/typesEnum';
import lodash from 'lodash';
import styled from 'styled-components';

interface Props {
  openStainsDialog: (type: 'stain' | 'damage') => void;
  open: boolean;
  onClose: () => void;
  submitNewIssue: (issue: string) => void;
}

interface issueObj extends Partial<LabelSelectorProps> {
  reason: string;
  iconName: ItemTypes;
}

const iconDimensions: { height: string; width: string } | undefined = { width: '15rem', height: '15rem' };
const issueMapper: { [key: string]: issueObj } = {
  addDamage: {
    reason: 'damage',
    iconName: ItemTypes.addDamage,
    activeColor: '#3e48a6',
    isDashed: true,
    pathType: 'stroke',
    width: '15rem',
    labelFontSize: '1.3rem',
    iconDimensions: iconDimensions,
    // activeColor: ,
  },
  addStain: {
    reason: 'stain',
    iconName: ItemTypes.addStain,
    width: '15rem',
    labelFontSize: '1.3rem',
    iconDimensions: iconDimensions,
  },
  rewash: {
    reason: 'REWASH',
    iconName: ItemTypes.rewash,
    width: '15rem',
    labelFontSize: '1.3rem',
    iconDimensions: iconDimensions,
  },
  damageAfterCleaning: {
    reason: 'DAMAGE_AFTER_CLEANING',
    iconName: ItemTypes.damageAfterCleaning,
    customIconFills: { secondIconFill: '#ff2222' },
    activeClassName: 'active-two-color-icon ',
    pathType: 'stroke',
    width: '15rem',
    labelFontSize: '1.3rem',
    iconDimensions: iconDimensions,
  },
  smellsBad: {
    reason: 'SMELLS_BAD',
    iconName: ItemTypes.smellsBad,
    activeClassName: 'active-two-color-icon',
    customIconFills: { secondIconFill: 'red' },
    inactiveClassName: 'inactive-icon',
    width: '15rem',
    labelFontSize: '1.3rem',
    iconDimensions: iconDimensions,
  },
  stillWet: {
    reason: 'STILL_WET',
    iconName: ItemTypes.stillWet,
    activeClassName: 'active-two-color-icon',
    customIconFills: { secondIconFill: 'red' },
    inactiveClassName: 'inactive-icon',
    width: '15rem',
    labelFontSize: '1.3rem',
    iconDimensions: iconDimensions,
  },
};

/** @description check if the issue is currently selected */
const isSelected = (selectedIssue: issueObj | undefined, issueKey: string) => issueMapper[issueKey].reason === lodash.get(selectedIssue, 'reason');

/** @description Issues Modal where user can report new unactionable issues, or actionable (Stain/Damage) */
const ReportIssuesModal: React.FC<Props> = ({ openStainsDialog, open, onClose, submitNewIssue }) => {
  const [selectedIssue, setSelectedIssue] = useState<issueObj>();

  /** @description Reset issue selection OR set new issue */
  const handleIconClick = (key: string) => {
    if (isSelected(selectedIssue, key)) setSelectedIssue(undefined);
    else setSelectedIssue(issueMapper[key]);
  };

  /** @description Open stain/damage dialog OR Submit a non-actionable issue (Other than stains/damage) */
  const onSubmit = () => {
    if (!selectedIssue) return;
    if (selectedIssue.reason === 'stain' || selectedIssue.reason === 'damage') openStainsDialog(selectedIssue.reason);
    else submitNewIssue(selectedIssue.reason);

    handleOnClose();
  };

  const handleOnClose = () => {
    setSelectedIssue(undefined);
    onClose();
  };
  return (
    <CustomDialog titleStyles={titleStyles} size="xl" title="What do you want to report ?" open={open} onClose={handleOnClose}>
      <LoaderComponent />
      <Box padding="1rem 4rem" display="flex" alignItems="center" justifyContent="center">
        <Box display="flex" gridGap={'1rem'} justifyContent="space-between" width="100%">
          {Object.entries(issueMapper).map(([key, issue]) => (
            <LabelSelector
              key={key}
              {...issue}
              iconName={issue.iconName}
              isActive={isSelected(selectedIssue, key)}
              onToggleClick={() => handleIconClick(key)}
              dataCy={`${key}.reportIssue.button`}
            />
          ))}
        </Box>
      </Box>
      <Box display={'flex'} flex={'0.4'} justifyContent="center" alignItems={'center'} padding="2rem 0">
        <SubmitButton disabled={!selectedIssue} variant="contained" color="primary" onClick={onSubmit}>
          Submit
        </SubmitButton>
      </Box>
    </CustomDialog>
  );
};

export default ReportIssuesModal;

const titleStyles = `
  margin : 2rem 0;

  .MuiIconButton-root{
    svg { 
      width : 3rem;
      height : 3rem;
    }
  }
`;
const SubmitButton = styled(Button)`
  height: 5.2rem;
  width: 18rem;
  padding: 1rem 3rem;
  font-size: 1.5rem;
`;
