import { useMutation } from 'react-query';
import { StationService } from 'services';
import { IOrderItemResponse } from 'utils/interfaces';

interface AddNewOptions {
  itemCode: string;
  bagCode: string;
}
const useAddNewToBag = () => {
  return useMutation<IOrderItemResponse, Error, AddNewOptions>(({ bagCode, itemCode }) => StationService.addNewToBag(itemCode, bagCode));
};
export default useAddNewToBag;
