import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { Box, Button, Divider } from '@material-ui/core';
import { ReactComponent as StainIcon } from 'assets/images/svgs/stainAndDamage/shoe-stain.svg';
import { ReactComponent as RedSprayIcon } from 'assets/images/svgs/red-spray-icon.svg';
import { ReactComponent as NotFixedIcon } from 'assets/images/svgs/stainmanIcons/cant-be-fixed-red.svg';
import { ReactComponent as BlueCheckmark } from 'assets/images/svgs/blue-checkmark.svg';
import { OrderStatuses } from 'utils/enums/stationEnums';
import { capitalizeString } from 'utils/helpers/string';
import { ItemActions, ItemIssue } from 'utils/interfaces';
import ItemIssueModalOverview, { issueObj } from '../modals/itemIssueModalOverview';
import { Toaster } from 'components/toaster';
import { useSubmitNewIssue, useUpdateItemIssueStatus } from '../../hooks';
import { ItemIssueUpdateRequest } from '../../hooks/useUpdateItemIssueStatus';
import ReportIssuesModal from '../modals/reportIssuesModal';
import { ActionBar, IActionStainsCardProps } from './middleCard';
import AddNoteModal from 'components/userNotes/components/addNoteModal';
import { useAddNote } from '../../../components/userItemNotes/mutations';
import ShoeIssueModal from '../modals/shoeIssueModal';
import { useNotesModal } from '../../hooks/useNotesModal';

export const shoeIssueMapper: { [key in ItemActions | '']?: issueObj } = {
  CLEANED: {
    icon: <BlueCheckmark />,
    isActioned: true,
    isRemoved: true,
  },
  SEND_BACK_TO_THE_SPOTTER: {
    icon: <RedSprayIcon />,
    isNotCleaned: true,
  },
  CANT_CLEAN: {
    icon: <NotFixedIcon />,
    isActioned: true,
    isCantBeCleaned: true,
    isNotCleaned: true,
  },
  '': {
    icon: <></>,
  },
};

const ShoeMiddleCard: React.FC<IActionStainsCardProps> = ({
  itemReportDetail,
  openStainsDialog,
  handleOnSubmitNewIssue,
  handleOnUpdateItemIssueStatus,
  isItemRejected,
}) => {
  const queryClient = useQueryClient();
  const [openIssueModal, setOpenIssueModal] = useState<boolean>(false);
  const [openAddIssueModal, setOpenAddIssueModal] = useState(false);
  const [currentIssue, setCurrentIssue] = useState<ItemIssue>();
  const [selectedItemAction, setSelectedItemAction] = useState<ItemActions | ''>();
  const { openNotesModal, closeNotesModal } = useNotesModal('issue-modal');

  const approvalStatus =
    itemReportDetail.approvalStatus && itemReportDetail.approvalOrigin
      ? `${capitalizeString(itemReportDetail.approvalStatus)} By ${capitalizeString(itemReportDetail.approvalOrigin)}`
      : '?';

  const { mutateAsync: addNewIssue } = useSubmitNewIssue(handleOnSubmitNewIssue);

  const { mutateAsync: updateIssueStatus } = useUpdateItemIssueStatus(handleOnUpdateItemIssueStatus);
  const { mutateAsync: addUserNote } = useAddNote();

  async function updateItemIssue(itemAction: ItemActions | '', issueId?: string) {
    const itemIssueUpdateReq: ItemIssueUpdateRequest = {
      itemCode: itemReportDetail.itemCode,
      orderId: itemReportDetail.orderId,
      orderAlphaId: itemReportDetail.orderAlphaId,
      customerAlphaId: itemReportDetail.customerAlphaId,
      approvalStatus: approvalStatus,
      numberOfScans: itemReportDetail.numberOfScans,
      action: itemAction,
      issueId: issueId ? issueId : '',
      isActioned: shoeIssueMapper[itemAction]?.isActioned || false,
      isRemoved: shoeIssueMapper[itemAction]?.isRemoved || false,
      isNotCleaned: shoeIssueMapper[itemAction]?.isNotCleaned || false,
      isCantBeCleaned: shoeIssueMapper[itemAction]?.isCantBeCleaned || false,
    };
    await updateIssueStatus(itemIssueUpdateReq);
    if (itemAction === ItemActions.NOT_FIXED) {
      toast(<Toaster message={`Damage not fixed`} type="error" />);
      return;
    }
    if (shoeIssueMapper[itemAction]?.isNotCleaned) {
      toast(<Toaster message={`Stain not cleaned, ${_.lowerCase(itemIssueUpdateReq.action)}`} type="error" />);
    }
  }

  const submitNewIssue = async (issue: string) => {
    await addNewIssue({
      issueReason: issue,
      itemCode: itemReportDetail.itemCode,
      orderAlphaId: itemReportDetail.orderAlphaId,
    });
    toast(<Toaster message="New issue submitted" type="success" />);
  };

  const { stainedIssues, damagedIssues, isStainedAndDamaged } = useMemo(() => {
    const stainedIssues = itemReportDetail.itemIssues.filter((issue) => issue.reason === 'STAINED').sort((a, b) => a.action.localeCompare(b.action));
    const damagedIssues = itemReportDetail.itemIssues.filter((issue) => issue.reason === 'DAMAGED').sort((a, b) => a.action.localeCompare(b.action));
    const isStainedAndDamaged = stainedIssues.length !== 0 && damagedIssues.length !== 0;
    return { stainedIssues, damagedIssues, isStainedAndDamaged };
  }, [itemReportDetail.itemIssues]);

  function reportIssue(itemAction: ItemActions | '', detail: ItemIssue) {
    setCurrentIssue(detail);
    setSelectedItemAction(itemAction);
    if (itemAction) {
      openNotesModal();
    } else {
      updateItemIssue(itemAction, detail.issueId);
    }
  }

  function handleIssuesModal(detail: ItemIssue) {
    setCurrentIssue(detail);
    setOpenIssueModal(true);
  }

  function handleActionIssue(reason: ItemActions) {
    setSelectedItemAction(reason);
    if (reason) {
      openNotesModal();
    } else {
      updateItemIssue(reason, currentIssue?.issueId);
    }
  }

  const handleAddNote = async (description: string, photo: string) => {
    await addUserNote(
      {
        itemCode: itemReportDetail.itemCode,
        orderAlphaId: itemReportDetail.orderAlphaId,
        orderId: itemReportDetail.orderId,
        description,
        photo,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['item-notes'], { exact: false, active: true });
          closeNotesModal();
          updateItemIssue(selectedItemAction || '', currentIssue?.issueId);
        },
      }
    );
  };
  const goBack = () => closeNotesModal();

  return (
    <Wrapper>
      <AddNoteModal
        modalKey="issue-modal"
        itemCode={itemReportDetail.itemCode}
        orderAlphaId={itemReportDetail.orderAlphaId}
        onClose={goBack}
        onNoteTaken={handleAddNote}
        preDescription={_.capitalize(_.startCase(selectedItemAction)) + ', '}
        title="Please Add Photo of Shoe"
      />

      <Box display="flex" flex={1} flexDirection="row" alignItems="center" paddingY={'1rem'}>
        <Box display="flex" alignItems={'center'} flexDirection="row" flex={10}>
          <StainIcon width="4.5rem" height="4.5rem" />
          <Header>Action Issues</Header>
        </Box>
        <Box flex={3} paddingLeft={5}>
          <ReportButton
            disabled={itemReportDetail.orderStatus === OrderStatuses.ORDER_COMPLETE || !itemReportDetail.rfid || isItemRejected}
            variant="contained"
            onClick={() => setOpenAddIssueModal(true)}
            size="large"
            color="secondary"
          >
            Report Issue
          </ReportButton>
        </Box>
      </Box>
      <Scrollable>
        <ActionBar
          approvalStatus={approvalStatus}
          isItemRejected={isItemRejected}
          itemIssues={stainedIssues}
          openIssueModal={handleIssuesModal}
          reportIssue={reportIssue}
        />
        {isStainedAndDamaged && <SectionDivider />}
        <ActionBar
          approvalStatus={approvalStatus}
          isItemRejected={isItemRejected}
          itemIssues={damagedIssues}
          openIssueModal={handleIssuesModal}
          reportIssue={reportIssue}
        />
      </Scrollable>
      <ItemIssueModalOverview
        open={openIssueModal}
        actionItemIssue={(ItemAction) => {
          setSelectedItemAction(ItemAction);
          openNotesModal();
        }}
        toggle={() => setOpenIssueModal(false)}
      />
      <ReportIssuesModal
        submitNewIssue={submitNewIssue}
        openStainsDialog={openStainsDialog}
        onClose={() => setOpenAddIssueModal(false)}
        open={openAddIssueModal}
      />
      <ShoeIssueModal open={openIssueModal} actionItemIssue={handleActionIssue} toggle={() => setOpenIssueModal(false)} />
    </Wrapper>
  );
};

export default ShoeMiddleCard;
const Scrollable = styled.div`
  height: calc(100vh - 300px);
  overflow-y: auto;
`;
const Wrapper = styled(Box)`
  padding: 0 1rem;
  border: 1px solid #7d7c97;
  height: 100%;
`;
const SectionDivider = styled(Divider)`
  border-bottom-width: 10px;
  border-color: #b6bcd1;
  margin-bottom: 1rem;
  padding-bottom: 1.2rem;
`;
const ReportButton = styled(Button)`
  padding: 1.2rem;
`;
const Header = styled.p`
  font-family: Ubuntu, serif;
  font-size: 25px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #111135;
  padding-left: 0.75rem;
  margin: 0;
`;
