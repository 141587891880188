import React from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useAuthStore } from 'stores';
import SelectStation from './selectStation';
import { ScannerWidget } from 'components/scanner';
import { ScanType } from 'utils/enums/stationEnums';

import EmployeeCardImage from 'assets/images/pngs/employee-card.png';

const Login: React.FC = observer(() => {
  const navigate = useNavigate();
  const { isSystemValidated, isManagerLoggedIn, login, validateManager } = useAuthStore();

  const onManagerScanComplete = async (rfidCode: string) => {
    await validateManager(rfidCode);
    navigate(`manager/selectStation/section`);
  };

  const onEmployeeScanComplete = (rfidCode: string) => {
    login(rfidCode);
  };

  return (
    <Routes>
      <Route
        path={`manager`}
        element={
          isSystemValidated ? (
            <Navigate to={`../user`} />
          ) : (
            // TODO: clear stationtype and station Id before redicting
            <ScannerWidget
              title="login.scanRfidManager"
              onScanComplete={onManagerScanComplete}
              images={{
                imageUrl: EmployeeCardImage,
                type: ScanType.RFID,
              }}
            />
          )
        }
      />
      <Route
        path={`manager/selectStation/:type`}
        element={isSystemValidated === true ? <Navigate to={`user`} /> : isManagerLoggedIn === true ? <SelectStation /> : <Navigate to={`manager`} />}
      />
      <Route
        path={`user`}
        element={
          isSystemValidated ? (
            <ScannerWidget
              title="login.scanRfidEmployee"
              onScanComplete={onEmployeeScanComplete}
              images={{
                imageUrl: EmployeeCardImage,
                type: ScanType.RFID,
              }}
            />
          ) : (
            <Navigate to={`../manager`} />
          )
        }
      />
      <Route path="*" element={<Navigate to={`../manager`} />} />
    </Routes>
  );
});

export default Login;
