import { IItemReportDetail } from 'utils/interfaces/stainManStationInterfaces';
import React from 'react';
import { ReactComponent as CSRIcon } from 'assets/images/svgs/csr-icon.svg';
import styled from 'styled-components';
import { Box, Grid } from '@material-ui/core';
import { ImagePopup } from 'components/imagePopup';
import _ from 'lodash';

export interface IRequestCardProps {
  itemReportDetail: IItemReportDetail;
}

const displayString = _.flow(_.startCase, _.capitalize);
const displayArray = (array: string[]) => array.map(displayString).join(', ');

const RightCard: React.FC<IRequestCardProps> = ({ itemReportDetail: { specialAttentionItem: csr, frontImage } }) => {
  return (
    <Wrapper>
      <Box paddingTop={'1rem'} flexDirection="row" display="flex">
        <Box display={'flex'} justifyContent="center" alignItems={'center'}>
          <CSRIcon width="65px" height="65px" />
          <Header>Customer Special Request</Header>
        </Box>
      </Box>
      <Box display={'flex'} justifyContent="center" flexDirection="column">
        <ImageWrapper>
          <ImagePopup index={0.3} imageUrl={csr.imageUrl} imageWidth="22rem" />
        </ImageWrapper>
        <RequestText>
          {displayArray(csr.stainsArray || [])}
          <br />
          {displayString(csr.cleaningInstruction)}
          <br />
          {displayArray(csr.tagsArray || [])}
        </RequestText>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 0 1rem;
  border: 1px solid #7d7c97;
  height: 100%;
`;
const ImageWrapper = styled(Grid)`
  align-items: center;
  padding-top: 2rem;

  img {
    max-width: 30rem;
  }
`;

const RequestText = styled.div`
  height: 1rem;
  font-family: Ubuntu;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--secondary-darkblue);
  padding-top: 2rem;
  padding-left: 1rem;
  max-width: 30rem;
  align-self: center;
  width: 100%;
`;
const Header = styled.p`
  font-family: Ubuntu, serif;
  font-size: 25px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #111135;
  padding-left: 0.5rem;
`;

export default RightCard;
