import { useQuery } from 'react-query';
import { StationService } from '../../../../../services';
import { ColorsList, IGroup, IItemGroup, IOrderItem, OrderServiceLines } from '../../../../../utils/interfaces';
import _ from 'lodash';
import { useMemo } from 'react';

export interface ItemInfo {
  code: string;
  type: string;
  colorsList: ColorsList[];
  brand: string;
}

const QUERY_KEY = `recommendations`;

export const useGetRecommendation = (enabled: boolean, itemGroup: IItemGroup, item: IOrderItem, serviceLine: OrderServiceLines) => {
  const { itemInfoList } = useMemo(() => {
    const itemInfoList = itemGroup.items.map((item) => {
      return {
        code: item.itemCode || item.code,
        type: item.type || '',
        colorsList: item.colorsList || [],
        brand: item.brand?.name || '',
      };
    });
    const currentItem: IOrderItem | undefined = itemGroup.items.find((val) => val.itemCode === item.code);
    return { itemInfoList, currentItem };
  }, [item, itemGroup]);

  const recommendationQuery = useQuery<{ recommendations: string[]; existingGroup: IGroup | undefined }, Error>(
    [QUERY_KEY, itemInfoList, item],
    () =>
      StationService.getRecommendation(
        {
          code: item.code,
          type: item?.type || '',
          colorsList: item?.colorsList || [],
          brand: item?.brand?.name || '',
        },
        itemInfoList,
        itemGroup.groups,
        serviceLine
      ),
    {
      refetchOnWindowFocus: false,
      enabled: enabled,
    }
  );
  const { recommendedItems, existingGroup } = useMemo(() => {
    const recommendations = recommendationQuery.data?.recommendations;
    let recommendedItems: (IOrderItem | undefined)[] = [];
    if (!_.isEmpty(recommendations) && recommendations) {
      recommendedItems = recommendations.map((itemCode) => itemGroup.items.find((item) => item.itemCode === itemCode));
    }
    return { recommendedItems, existingGroup: recommendationQuery.data?.existingGroup };
  }, [itemGroup, recommendationQuery.data]);

  return { ...recommendationQuery, recommendedItems, existingGroup };
};
export default useGetRecommendation;
