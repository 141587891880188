import React, { useState } from 'react';
/** Import Store Context */
import { useStationStore } from 'stores';
/** Import Color Icon Selector Component */
import ColorIconSelector, { colorNames as ColorNames } from '../colorIconSelector/colorIconSelector';
import { colorMapper as ColorMapper } from '../colorIconSelector/colorIconSelector';
/** Import Interfaces */
import { ColorsList } from 'utils/interfaces/stationInterfaces';
/** Import Toaster  */
import { toast } from 'react-toastify';
import { Toaster } from 'components/toaster';
/** Imports Material UI */
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Button } from '@material-ui/core';
import { ValidateManagerDialog } from 'components/dialogs';
import { useNavigate } from 'react-router-dom';

const useColorDialogStyle = makeStyles((theme) => ({
  root: {
    width: '70%',
    padding: '30px 0',
  },
  colorWrapper: {
    width: '11.11%',
    alignItems: 'center',
    justifyItems: 'center',
  },
  nextContainer: {
    padding: '3rem 0',
    width: '100%',
  },
  submitButton: {
    [theme.breakpoints.up('md')]: {
      padding: '1.5rem 10rem',
    },
  },
}));

interface Props {}

const SelectColorDialog: React.FC<Props> = () => {
  // Import Styles
  const classes = useColorDialogStyle();

  const navigate = useNavigate();
  // Add Context
  const { currentItem, updateCurrentItemDetails } = useStationStore();
  const currentItemColors: ColorsList[] = currentItem.colorsList ? currentItem.colorsList : [];

  // Declare State
  const [selectedColors, setSelectedColors] = useState<ColorsList[]>(currentItemColors);
  const [toggleManagerFlag, setToggleManagerFlag] = useState(false);

  /**
   * This function handles the selection process
   *
   * @param currentColor - Color selected
   */
  const onColorSelect = (currentColor: ColorsList) => {
    // Check if the colors has already been selected
    const isSelected =
      selectedColors.filter((color: ColorsList) => {
        return color.hex === currentColor.hex;
      }).length > 0;

    // If he added 3 colors already
    if (selectedColors.length > 2 && !isSelected) {
      return toast(<Toaster message="Can't select more than 3 colors" type="error" />);
    }

    // Used to unselected colors
    if (isSelected) {
      const updatedColorList = selectedColors.filter((color: ColorsList) => {
        return color.hex !== currentColor.hex;
      });
      setSelectedColors(updatedColorList);
    }

    // Used to select colors (If < 3 colors selected)
    if (!isSelected)
      setSelectedColors((prevState) => {
        return [...prevState, currentColor];
      });
  };

  /**
   * Save item to the store and route to the next view
   */
  const onNextToggle = () => {
    // On recurrent item, ask for the manager permission
    if (currentItem && currentItem.isRecurrent) {
      setToggleManagerFlag(true);
      return;
    }
    // On new Item, update the colors
    updateCurrentItem();
  };

  const updateCurrentItem = () => {
    // Update the Current Item in store
    updateCurrentItemDetails({ ...currentItem, colorsList: selectedColors });
    // Route to the Programs Modal View
    if (currentItem.programs) {
      navigate(-1);
    } else {
      navigate('../(item/programs)');
    }
  };

  // Get the list of colors (Hex) currently selected
  const hexColors = selectedColors.map((color: ColorsList) => {
    return color.hex;
  });

  return (
    <>
      <Box display="flex" flexWrap="wrap" alignContent="center" justifyContent="center" alignSelf="center" className={classes.root}>
        {(Object.keys(ColorMapper) as ColorNames[]).map((color, i) => {
          return (
            <Box display="flex" className={classes.colorWrapper} key={`${i}-colorIcon-Mapper`}>
              <ColorIconSelector colorName={color} onToggleClick={onColorSelect} isActive={hexColors.includes(ColorMapper[color].hex)} />
            </Box>
          );
        })}
      </Box>
      <Box display="flex" alignContent="center" justifyContent="center" className={classes.nextContainer}>
        <Button
          color="primary"
          variant="contained"
          onClick={onNextToggle}
          size="large"
          disabled={selectedColors.length === 0}
          className={classes.submitButton}
        >
          Next
        </Button>
        <ValidateManagerDialog
          open={toggleManagerFlag}
          onManagerScan={updateCurrentItem}
          onClose={() => {
            setToggleManagerFlag(false);
          }}
        />
      </Box>
    </>
  );
};

export default SelectColorDialog;
