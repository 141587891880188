import React, { FormEvent } from 'react';
import { Box, Button, Grid, Modal, DialogContent, DialogActions, ButtonBase } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import styled from 'styled-components';

interface Props {
  filters: JSX.Element[];
  open: boolean;
  handleClearFilters: () => void;
  handleClose: () => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
}
const ModalFilters: React.FC<Props> = ({ filters, open, handleClearFilters, handleClose, onSubmit }) => {
  return (
    <Modal id="modalFilters" keepMounted open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <>
        <FiltersBox>
          <form onSubmit={onSubmit}>
            <DialogHeader>
              More Filters
              <CloseDialogButton onClick={() => handleClose()}>
                <Close fontSize="large" />
              </CloseDialogButton>
            </DialogHeader>

            <DialogContent>
              <FiltersWrapper item container justifyContent="space-between">
                {filters}
              </FiltersWrapper>
            </DialogContent>

            <br />
            <StyledDialogActions>
              <StyledDialogButtons variant="outlined" color="primary" size="large" onClick={() => handleClearFilters()}>
                Clear All
              </StyledDialogButtons>
              <StyledDialogButtons variant="contained" color="primary" size="large" type="submit">
                Confirm
              </StyledDialogButtons>
            </StyledDialogActions>
          </form>
        </FiltersBox>
      </>
    </Modal>
  );
};

export default ModalFilters;

const FiltersBox = styled(Box)`
  background-color: white;
  position: absolute;
  border-radius: 0.7rem;
  top: 50%;
  left: 50%;
  width: 60rem;
  height: max-content;
  transform: translate(-50%, -50%);
  overflow: scroll;

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #3f44ac;
  }

  .MuiChip-deleteIconOutlinedColorPrimary {
    color: #3f44ac;
  }

  .MuiFormLabel-root {
    color: #111135;
  }
`;

const CloseDialogButton = styled(ButtonBase)`
  position: absolute;
  right: 1rem;
  bottom: 1.5rem;
`;

const DialogHeader = styled.h1`
  text-align: center;
  color: #111135;
  font-weight: normal;
  font-size: 2.5rem;
  position: relative;
`;

const FiltersWrapper = styled(Grid)`
  row-gap: 2rem;
  column-gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const StyledDialogActions = styled(DialogActions)`
  justify-content: center;
`;
const StyledDialogButtons = styled(Button)`
  width: 17rem;
  height: 7rem;
`;
