import React, { useMemo } from 'react';
import { useUiState } from 'stores';
import { Box, CircularProgress } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { StationTypes } from 'utils/enums/stationEnums';
import { useIsRQLoading } from 'hooks';

const STATIONS_WITH_RQ = [
  StationTypes.ShoesSorter,
  StationTypes.FinerySorter,
  StationTypes.ShoesBeforeAfter,
  StationTypes.ShoesStainMan,
  StationTypes.ShoesSorterAssessment,
  StationTypes.CPDispatch,
  StationTypes.WFDispatch,
  StationTypes.ShoesDispatch,
];

const stationSupportsRQ = (pathname: string) => STATIONS_WITH_RQ.some((stationName) => pathname.includes(stationName));

const LoaderComponent: React.FC = observer(() => {
  const { isLoading: axiosIsLoading } = useUiState();
  const { isMutating, isLoading } = useIsRQLoading();
  const { pathname } = useLocation();

  const showLoader = useMemo(
    () => (stationSupportsRQ(pathname) ? isMutating || isLoading : axiosIsLoading),
    [axiosIsLoading, isMutating, isLoading, pathname]
  );
  return (
    <>
      {showLoader && (
        <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%" className="loading-icon">
          <CircularProgress />
        </Box>
      )}
    </>
  );
});

export default LoaderComponent;
