import React from 'react';

import { Grid, Paper, Typography, IconButton } from '@material-ui/core';
import { ReactComponent as SlackIcon } from 'assets/images/svgs/slack.svg';

const StainManTableFooter: React.FC<React.PropsWithChildren<{ totalNumberOfItems: number; handleOnPrint?: () => void }>> = ({
  totalNumberOfItems,
  children,
  handleOnPrint,
}) => {
  return (
    <>
      <Paper>
        <Grid container alignItems="center" justifyContent="space-between" style={{ padding: '0.6rem' }}>
          <Grid item container xs={4} wrap="nowrap" alignItems="center" justifyContent="flex-start">
            <Typography variant="h4"> Total : {totalNumberOfItems} </Typography>
            <IconButton onClick={handleOnPrint}>
              <SlackIcon />
            </IconButton>
          </Grid>
          <Grid container justifyContent="space-between" item xs={6}>
            <Grid item xs={10}>
              {children}
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default StainManTableFooter;
