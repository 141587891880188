import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Button, ButtonBase, createStyles, Typography } from '@material-ui/core';
import LabelSelector from '../../../sorterStation/components/labelSelector/LabelSelector';
import { ItemTypes } from 'utils/enums/typesEnum';
import { CustomDialog } from 'components/dialogs';
import { makeStyles } from '@material-ui/styles';
import { ReactComponent as BlackCloseSVG } from 'assets/images/svgs/close-black.svg';
import { ItemActions } from 'utils/interfaces';

interface IProps {
  open: boolean;
  actionItemIssue: (reason: ItemActions) => void;
  toggle: () => void;
}

export interface issueObj {
  icon: JSX.Element;
  isActioned?: boolean;
  isRemoved?: boolean;
  isNotCleaned?: boolean;
  isCantBeCleaned?: boolean;
}

const iconDimensions: { height: string; width: string } = { height: '11.25rem', width: '160px' };

export const useStyles = makeStyles(() =>
  createStyles({
    iconClass: {
      width: '60% !important',
    },
    buttonsBox: {
      display: 'flex',
      justifyContent: 'center',
      flex: '.4',
      alignItems: 'center',
      padding: '2rem 0',
    },
    buttons: {
      height: '5.2rem',
      width: '18rem',
      padding: '1rem 3rem',
      margin: '0 1rem',
    },
    iconTitle: {
      padding: '0.5rem 0',
    },
  })
);

const ItemIssueModalOverview: React.FC<IProps> = ({ open, actionItemIssue, toggle }) => {
  const classes = useStyles();
  const [openCannotBeCleanedModal, setOpenCannotBeCleanedModal] = useState<boolean>(false);
  const [currentAction, setCurrentAction] = useState<ItemActions | undefined>();

  const handleIconClick = (key: ItemActions) => {
    if (key === currentAction) {
      setCurrentAction(undefined);
      return;
    }
    setCurrentAction(key);
  };

  function reportIssue(itemAction: ItemActions | undefined) {
    if (itemAction) {
      if (itemAction === ItemActions.CANT_CLEAN) {
        setOpenCannotBeCleanedModal(true);
        return;
      }
      setOpenCannotBeCleanedModal(false);
      actionItemIssue(itemAction);
      setCurrentAction(undefined);
      toggle();
    }
  }

  return (
    <CustomDialog open={open} size="md" dataCy="reportIssueDialog.component">
      <Box display="flex" justifyContent="flex-end">
        <CustomButton onClick={() => toggle()}>
          <BlackCloseSVG width="3rem" height="3rem" />
        </CustomButton>
      </Box>
      <Box padding="1rem 4rem" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <Box display="flex" flexDirection="row">
          <JourneyHeader>
            <Typography variant="h4">Not Cleaned</Typography>
          </JourneyHeader>
        </Box>
        <Box display="flex" justifyContent="space-between" width="80%">
          <Box paddingTop="30px">
            <LabelSelector
              iconName={ItemTypes.spotter}
              isActive={currentAction === ItemActions.SEND_BACK_TO_THE_SPOTTER}
              onToggleClick={() => handleIconClick(ItemActions.SEND_BACK_TO_THE_SPOTTER)}
              labelFontSize="25px"
              iconDimensions={iconDimensions}
              dataCy="stain.reportIssue.button"
            />
          </Box>
          <Box paddingTop="30px" paddingRight="10px">
            <LabelSelector
              iconName={ItemTypes.rewash}
              labelFontSize="25px"
              iconDimensions={iconDimensions}
              isActive={currentAction === ItemActions.REWASH}
              onToggleClick={() => handleIconClick(ItemActions.REWASH)}
              dataCy="rewash.reportIssue.button"
            />
          </Box>
          <LabelSelector
            iconName={ItemTypes.cantClean}
            labelFontSize="25px"
            iconDimensions={iconDimensions}
            isActive={currentAction === ItemActions.CANT_CLEAN}
            activeClassName={'active-two-color-icon'}
            customIconFills={{ secondIconFill: 'red' }}
            inactiveClassName={'inactive-icon'}
            onToggleClick={() => handleIconClick(ItemActions.CANT_CLEAN)}
            dataCy="damage.reportIssue.button"
          />
        </Box>
      </Box>
      <JourneyHeader className={classes.buttonsBox}>
        <Box>
          <Button
            className={classes.buttons}
            color="primary"
            size="large"
            variant="contained"
            disabled={!currentAction}
            onClick={() => reportIssue(currentAction)}
            data-cy="pressing.reportIssue.continue.button"
          >
            Submit
          </Button>
        </Box>
      </JourneyHeader>
      <ItemCannotCleanedModal
        open={openCannotBeCleanedModal}
        actionItemIssue={(issueObj) => {
          handleIconClick(issueObj);
          reportIssue(issueObj);
        }}
        toggle={() => setOpenCannotBeCleanedModal(false)}
      />
    </CustomDialog>
  );
};

const ItemCannotCleanedModal: React.FC<IProps> = ({ open, actionItemIssue, toggle }) => {
  const classes = useStyles();
  const [currentAction, setCurrentAction] = useState<ItemActions>();
  const handleIconClick = (key: ItemActions) => {
    if (key === currentAction) {
      setCurrentAction(undefined);
      return;
    }
    setCurrentAction(key);
  };

  function reportIssue() {
    if (currentAction) {
      actionItemIssue(currentAction);
      toggle();
    }
  }

  return (
    <CustomDialog open={open} size="md" dataCy="reportIssueDialog.component">
      <Box display="flex" justifyContent="flex-end">
        <CustomButton
          onClick={() => {
            toggle();
          }}
        >
          <BlackCloseSVG width="3rem" height="3rem" />
        </CustomButton>
      </Box>
      <Box padding="1rem 4rem" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <ItemCannotCleanedJourneyHeader>
          <Typography variant="h4">Can't Be Cleaned</Typography>
        </ItemCannotCleanedJourneyHeader>
        <NotCleanedBox display="flex" justifyContent="space-between" width="80%">
          <LabelSelector
            iconName={ItemTypes.customer}
            isActive={currentAction === ItemActions.NEED_CUSTOMER_APPROVAL}
            onToggleClick={() => handleIconClick(ItemActions.NEED_CUSTOMER_APPROVAL)}
            labelFontSize="25px"
            iconDimensions={iconDimensions}
            dataCy="stain.reportIssue.button"
          />
          <LabelSelector
            iconName={ItemTypes.itemRisk}
            labelFontSize="25px"
            iconDimensions={iconDimensions}
            activeClassName={'item-at-risk-active'}
            isActive={currentAction === ItemActions.ITEM_AT_RISK}
            inactiveClassName="inactive-icon-stroke"
            onToggleClick={() => handleIconClick(ItemActions.ITEM_AT_RISK)}
            dataCy="damage.reportIssue.button"
          />
        </NotCleanedBox>
      </Box>
      <ItemCannotCleanedJourneyHeader className={classes.buttonsBox}>
        <Box paddingTop={2}>
          <Button
            className={classes.buttons}
            color="primary"
            size="large"
            variant="contained"
            disabled={!currentAction}
            onClick={() => reportIssue()}
            data-cy="pressing.reportIssue.continue.button"
          >
            Submit
          </Button>
        </Box>
      </ItemCannotCleanedJourneyHeader>
    </CustomDialog>
  );
};

const ItemCannotCleanedJourneyHeader = styled(Box)`
  padding: 1.3rem 2rem;
  padding-bottom: 5rem;
`;
const JourneyHeader = styled(Box)`
  padding: 1rem 2rem;
  padding-bottom: 4rem;
`;
const CustomButton = styled(ButtonBase)`
  top: 5px;
  right: 5px;
`;

const NotCleanedBox = styled(Box)`
  .item-at-risk-active path:nth-child(1) {
    fill: #3e48a6;
  }

  .item-at-risk-active path:nth-child(2) {
    stroke: #ffc905;
  }

  .item-at-risk-active path:nth-child(n + 3) {
    fill: #ffc905;
  }
`;

export default ItemIssueModalOverview;
