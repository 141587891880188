import Axios, { AxiosRequestConfig } from 'axios';
import Axious from './api';
import {
  IOrderBag,
  IOrderItemResponse,
  IBrandsResponse,
  DetailsList,
  IOrderItem,
  ShoeAngleImages,
  IOrderItemReportResponse,
  ShoeAngleImagesCleaned,
  ItemTypes,
  ItemMeasurements,
  OrderServiceLines,
  IGroup,
  ServiceType,
} from 'utils/interfaces/stationInterfaces';
import { FoldingType } from 'utils/enums/stationEnums';
import { EntityTypes } from 'services/mutations/useValidateEntityServiceLine';
import { QueryResponse as ItemTypeMeasurementsConfigResponse } from './queries/useGetItemTypeMeasurementsConfig';
import { ItemInfo } from '../modules/stations/components/itemGroups/queries/useGetRecommendation';
import { QueryResponse as PackageResponse } from 'modules/stations/receivingStation/queries/useGetPackage';

class StationService {
  /**
   * @param qrCode bag qr code
   * @returns Bag Details if found
   */
  public async getOrderBagByQrCode(qrCode: string, isLog: boolean = true) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/bags/${qrCode}`,
      params: { isLog },
    };

    const response = await Axious.request<IOrderBag>(requestOptions);
    return response.data.bag;
  }

  /**
   * @param qrCode bag qr code
   * @returns Bag Details if found
   */
  public async getBag(code: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/bags/${code}/get`,
    };

    const response = await Axious.request<IOrderBag>(requestOptions);
    return response.data.bag;
  }

  /**
   * Get Item by RFID
   * @param rfidCode - string
   * @returns {IOrderItemResponse} - Item Object
   */
  public async getOrderItemByRfidCode(rfidCode: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/items/rfid/${rfidCode}`,
    };

    const response = await Axious.request<IOrderItemResponse>(requestOptions);
    return response.data.item;
  }

  public async getOrderItemByQrCode(qrCode: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/items/${qrCode}`,
    };
    const response = await Axious.request<IOrderItemResponse>(requestOptions);
    return response.data.item;
  }

  public async getItemReportByQrCode(qrCode: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/items/${qrCode}/report`,
    };
    const response = await Axious.request<IOrderItemReportResponse>(requestOptions);
    return response.data.itemReportDetail;
  }

  public async updateBeforeImages(orderAlphaId: string, itemCode: string, images: ShoeAngleImages, orderServiceLine: OrderServiceLines) {
    const requestOptions: AxiosRequestConfig = {
      method: 'PUT',
      url: `/orders/${orderAlphaId}/items/${itemCode}/set-images`,
      data: {
        itemImagesMap: images,
        orderServiceLine,
      },
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async updateAfterImages(orderAlphaId: string, itemCode: string, images: ShoeAngleImagesCleaned) {
    const requestOptions: AxiosRequestConfig = {
      method: 'PUT',
      url: `/orders/${orderAlphaId}/items/${itemCode}/set-cleaned-images`,
      data: {
        itemImagesMap: images,
      },
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  /**
   * Call the add-new-to-bag API
   * @param itemCode - string
   * @param bagCode  - string
   */
  public async addNewToBag(itemCode: string, bagCode: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/v2/bags/${bagCode}/items/${itemCode}/new`,
    };

    const response = await Axious.request<IOrderItemResponse>(requestOptions);
    return response.data;
  }

  /**
   * Call the add-existing-to-bag API
   * @param itemCode - string
   * @param bagCode - string
   */
  public async addExistingToBag(itemCode: string, bagCode: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/v2/bags/${bagCode}/items/${itemCode}`,
    };

    const response = await Axious.request<IOrderItemResponse>(requestOptions);
    return response.data;
  }

  /**
   * Link garment to RFID
   * @param rfid - string
   * @param itemCode - string
   * @param orderId - string
   */
  public async linkRfidToItem(rfid: string, itemCode: string, orderId: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/v2/orders/${orderId}/items/${itemCode}/rfid`,
      data: {
        rfid: rfid,
      },
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }

  /**
   * Link garment to RFID
   * @param trayCode - string
   * @param itemCode - string
   * @param orderAlphaId - string
   */
  public async linkTrayToItem(itemCode: string, orderAlphaId: string, trayCode: number) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/orders/${orderAlphaId}/items/${itemCode}/tray`,
      data: {
        trayCode,
      },
    };
    const response = await Axious.request<unknown>(requestOptions);
    return response.data;
  }

  /**
   * @description resets the bag from all items
   * @param bagCode
   */
  public async resetBag(bagCode: string, managerId: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'DELETE',
      url: `/bags/${bagCode}`,
      headers: {
        'manager-password': managerId,
      },
    };
    return await Axious.request(requestOptions);
  }

  public async updateBagType(bagCode: string, bagType: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'PUT',
      url: `/bags/${bagCode}/type`,
      data: {
        bagType,
      },
    };

    return await Axious.request(requestOptions);
  }

  public async createBag(orderId: string, bagCode: string, bagType: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/v2/orders/${orderId}/bags/${bagCode}`,
      data: { bagType },
    };
    const response = await Axious.request<IOrderBag>(requestOptions);

    return response.data.bag;
  }

  public async fetchCustomerTopBrands(customerId: string, type: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      baseURL: process.env.REACT_APP_TOP_BRANDS_API,
      params: {
        customerId: customerId,
        type: type,
      },
    };
    const response = await Axios.request<IBrandsResponse[]>(requestOptions);

    if (response.data && typeof response.data === 'string') {
      response.data = JSON.parse(response.data);
    }

    // HOTFIX: remove brands with no name, luxury or delicate
    if (response.data && Array.isArray(response.data)) {
      response.data = response.data.filter((brand) => {
        return brand.name && typeof brand.name === 'string';
      });
    }
    return response.data;
  }

  public async fetchAllBrands() {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      baseURL: process.env.REACT_APP_BRAND_API_HOST,
      url: 'brands.json',
    };
    // TODO use Axious instance here instead (override baseURL)
    const response = await Axios.request<IBrandsResponse[]>(requestOptions);
    return response.data;
  }

  public async rejectItem(rfid: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/items/${rfid}/reject`,
    };
    const response = await Axious.request(requestOptions);
    return response;
  }

  public async sendItemToApproval(rfid: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/items/${rfid}/approvals`,
    };
    const response = await Axious.request(requestOptions);
    return response;
  }

  /**
   * Send a POST request to the inform and proceed endpoint
   *
   * @param {string} rfid
   *
   * @returns {Object} response
   */
  public async informAndProceed(rfid: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/v2/items/${rfid}/inform-and-proceed`,
    };
    const response = await Axious.request(requestOptions);
    return response;
  }

  /**
   * Reject a recurring item
   * @param itemCode - string
   */
  public async rejectScannedItem(itemCode: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/v2/items/${itemCode}/reject-scanned`,
    };
    const response = await Axious.request(requestOptions);
    return response;
  }

  /**
   * @description report a list of stains (stain - damage) to backend
   * @param itemCode
   * @param reason
   * @param list
   */
  public async reportStained(itemCode: string, reason: 'STAINED' | 'DAMAGED' | 'NOT_CLEAN' | 'NOT_PRESSED', list: DetailsList[]) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/items/${itemCode}/report/${reason}`,
      data: {
        reportDetailsList: list,
      },
    };
    const response = await Axious.request(requestOptions);
    return response;
  }

  public async fetchPreselectedPrograms(type: string, color: string[]) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/items/programs/suggested`,
      params: {
        typeList: new Array(type),
        colorsList: color,
      },
    };
    const response = await Axious.request(requestOptions);
    return response.data.programs;
  }

  public async markBagAsSorted(bagCode: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/v2/bags/${bagCode}/sort`,
    };
    const response = await Axious.request(requestOptions);
    return response;
  }

  public async markAsTagged(bagCode: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/v2/bags/${bagCode}/tag`,
    };
    const response = await Axious.request(requestOptions);
    return response;
  }

  /**
   * @description creates a new item in the backend
   * @param rfid
   * @param item
   */
  public async createNewItem(rfid: string, item: IOrderItem | {} = {}) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/v2/items/${rfid}`,
      data: { ...item },
      timeout: 120000,
    };
    const response = await Axious.request<IOrderItemResponse>(requestOptions);
    return response.data.item;
  }

  /**
   * @description update item's details wether new or recurrent
   */
  public async updateItem(item: IOrderItem, managerId?: string, isWasher?: boolean) {
    const requestOptions: AxiosRequestConfig = {
      method: 'PUT',
      url: `/v2/items/${isWasher ? `${item.rfid}/realtime` : item.rfid}`,
      // headers: {
      //   'manager-password': managerId,
      // },
      data: { ...item },
    };
    const response = await Axious.request<IOrderItemResponse>(requestOptions);
    return response.data.item;
  }

  public async markItemAsSorted(rfid: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/v2/items/${rfid}/sort`,
    };
    const response = await Axious.request(requestOptions);
    return response;
  }

  public async uploadImageToS3(itemCode: string, imageBlob: string, isReport = false) {
    const d = new Date();
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/images`,
      data: {
        photoName: `${itemCode}-${d.getTime()}-front`,
        garmentCode: itemCode,
        base64Image: imageBlob,
        reported: isReport,
      },
      timeout: 60000,
    };
    const response = await Axious.request<{ photoUrl: string }>(requestOptions);
    return response.data.photoUrl;
  }

  public async removeItemFromBag(bagCode: string, itemCode: string, managerId = '') {
    const requestOptions: AxiosRequestConfig = {
      method: 'DELETE',
      url: `/v2/bags/${bagCode}/items/${itemCode}`,
      headers: {
        'manager-password': managerId,
      },
    };
    const response = await Axious.request(requestOptions);
    return response;
  }

  public async markItemAsPressed(rfid: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/items/${rfid}/press`,
    };

    const response = await Axious.request(requestOptions);
    return response;
  }

  /**
   * Fetch the list of orders for the racking station
   *
   * @return {Object} response
   */
  public async fetchOrdersList() {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/orders/hungbanned`,
    };

    const response = await Axious.request(requestOptions);
    return response.data.ordersList;
  }

  /**
   * Fetch the item by RFID with remaining Racks
   *
   * @param {string} RFID
   *
   * @return {Object} response
   */
  public async getItemByRfidWithRemainingRacks(rfid: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/items/rfid/${rfid}`,
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }

  /**
   * Link Special Attention Item to Bag
   *
   * @param {string} bagCode
   * @param {string} specialAttentionItemId
   *
   */
  public async linkSpecialAttentionItemToBag(bagCode: string, specialAttentionItemId: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/v2/bags/${bagCode}/special-attention-items/${specialAttentionItemId}`,
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  /**
   * Unlink Special Attention Item to Bag
   *
   * @param {string} bagCode
   * @param {string} specialAttentionItemId
   *
   */
  public async unlinkSpecialAttentionItemFromBag(bagCode: string, specialAttentionItemId: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'DELETE',
      url: `/v2/bags/${bagCode}/special-attention-items/${specialAttentionItemId}`,
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  /**
   * Link item to special attention Item
   *
   * @param {string} itemRfid
   * @param {string} specialAttentionItemId
   *
   */
  public async linkItemToSpecialAttentionItem(itemRfid: string, specialAttentionItemId: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/v2/items/${itemRfid}/special-attention-items/${specialAttentionItemId}`,
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  /**
   * Link item to special attention Item
   *
   * @param {string} itemRfid
   * @param {string} specialAttentionItemId
   *
   */
  public async unlinkItemToSpecialAttentionItem(itemRfid: string, specialAttentionItemId: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'DELETE',
      url: `/v2/items/${itemRfid}/special-attention-items/${specialAttentionItemId}`,
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  /**
   * Validat RFID on the Pressing stations
   *
   * @param {string} itemRfid
   */
  public async validatRfid(itemCode: string, itemRfid: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/items/${itemRfid}/validate`,
      params: {
        itemCode,
      },
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  /**
   * TODO -R: Refactor name to fetchItemInBagWithType()
   * List folding item in a given bag
   * @param {string} bagCode
   */
  public async fetchTransitItems(bagCode: string, category: FoldingType | 'ALL') {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/bags/${bagCode}/packaging-type/${category}/items`,
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async DEV_fetchTransitItems(bagCode: string, category: FoldingType) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/bags/${bagCode}/packaging-type/${category}/items`,
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async fetchFoldingItem(identifier: string, isManual: boolean = false) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/folding-station/items/itemIdentifier/${identifier}`,
      params: {
        isManual,
      },
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async fetchMultiFoldingItems(packageId: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/lots/${packageId}/items`,
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async markItemAsScanned(rfid: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/items/${rfid}/scanned-on-folding`,
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async completeFoldingPackage(orderId: string, bagCode: string, type: FoldingType, itemsCodesList: string[]) {
    const packageType = type === FoldingType.mp || type === FoldingType.banned ? 'MULTIPLE' : type;
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/orders/${orderId}/packages/${packageType}`,
      data: {
        itemsCodesList,
        bagCode,
      },
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  /**
   * Unpackages items from a package and
   * deletes the links associated with that
   * package.
   * @param packageId
   */
  public async onFinishUnpackaging(packageId: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'DELETE',
      url: `/unpackage/${packageId}/items`,
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  /**
   *
   * @description Fetches all the items that belong to an Order
   * by either OrderAlphaId or ItemCode
   *
   * @param orderAlphaId
   * @param itemCode
   *
   * @returns orderSearchItemsList
   */
  public async fetchItemsByOrderAlphaId(orderAlphaId?: string, itemCode?: string, excludeWF?: boolean) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/items/orderAlphaId`,
      params: {
        orderAlphaId,
        itemCode,
        excludeWF,
      },
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async getPackageDetail(rfid: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/packages/items/${rfid}`,
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async rejectItemOnFolding(rfid: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/items/${rfid}/reject-folding`,
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async cancelItemOnFolding(rfid: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/items/${rfid}/cancel-folding`,
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  /**
   * @param entityId bag/package/qr/rfid code
   * @param entityType bag/package/qr/rfid
   * @returns Okay if valid, else throws error
   */
  public async validateEntityServiceLine(entityId: string, entityType?: EntityTypes) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/validation/service-lines/entity/${entityId}`,
      params: {
        entityType: entityType,
      },
    };

    const response = await Axious.request<string>(requestOptions);
    return response.data;
  }

  public async getUserNotes(itemCode: string, orderAlphaId: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/user-notes/list`,
      data: {
        itemCode,
        orderAlphaId,
      },
    };
    const response = await Axious.request(requestOptions);
    return response.data.userNotes;
  }

  public async setNoteStatus(noteId: string, isRemoved: boolean) {
    const requestOptions: AxiosRequestConfig = {
      method: 'PUT',
      url: `/user-notes/${noteId}/isRemoved/${isRemoved}`,
    };
    const response = await Axious.request<string>(requestOptions);

    return response.data;
  }

  public async addUserNotes(itemCode: string, orderAlphaId: string, orderId: string, photo: string, description: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/user-notes`,
      data: {
        itemCode,
        orderAlphaId,
        description,
        photo,
        orderId,
      },
    };
    const response = await Axious.request<string>(requestOptions);
    return response.data;
  }

  public async clearRacks(orderIdsList: string[]) {
    const requestOptions: AxiosRequestConfig = {
      method: 'PUT',
      url: `/orders/items/racks/clear-with-filters`,
      data: { orderIdsList },
    };
    const response = await Axious.request<string>(requestOptions);
    return response.data;
  }

  public async getMeasurementsForItemType(itemType?: ItemTypes) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/orders/items/measurements`,
      params: { itemType },
    };
    const response = await Axious.request<ItemTypeMeasurementsConfigResponse>(requestOptions);
    return response.data;
  }

  public async setAfterMeasurements(itemCode: string, orderAlphaId: string, afterMeasurementsList: ItemMeasurements[], itemType?: ItemTypes) {
    const requestOptions: AxiosRequestConfig = {
      method: 'PUT',
      url: `/orders/${orderAlphaId}/items/${itemCode}/set-after-measurements`,
      data: { itemType, measurementsList: afterMeasurementsList },
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async requestApprovalSendToCp(itemCode: string, orderId: string, approvalReason: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/orders/${orderId}/items/${itemCode}/approvals/${approvalReason}/wf-to-cp`,
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async fetchItemGroups(orderAlphaId: string, isFetchRecommendations: boolean = false, serviceLine: OrderServiceLines) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/orders/${orderAlphaId}/items/groups`,
      params: { isFetchRecommendations, orderServiceLine: serviceLine },
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async removeItemGroups(groupId: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'DELETE',
      url: `/orders/items/groups/${groupId}`,
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async removeExtraItems(orderAlphaId: string, itemCode: string, extraItemCodesList: string[]) {
    const requestOptions: AxiosRequestConfig = {
      method: 'DELETE',
      url: `orders/${orderAlphaId}/items/${itemCode}/extra`,
      data: {
        extraItemCodesList,
      },
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async upsertGroup(orderAlphaId: string, itemCodesList: string[], groupId?: string, mergedGroupIds?: string[]) {
    const requestOptions: AxiosRequestConfig = {
      method: 'PUT',
      url: `/orders/${orderAlphaId}/items/groups`,
      data: {
        orderAlphaId,
        itemCodesList,
        groupId,
        mergedGroupIds,
      },
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async addExtraItem({
    orderAlphaId,
    itemCode,
    type,
    image,
    orderId,
    extraItemCode,
  }: {
    orderAlphaId: string;
    itemCode: string;
    type: ItemTypes;
    image: string;
    orderId: string;
    extraItemCode: string;
  }) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/orders/${orderAlphaId}/items/${itemCode}/extra`,
      data: {
        code: extraItemCode,
        type,
        frontImage: image,
        orderId,
      },
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async getRecommendation(currentItemInfo: ItemInfo, itemInfoList: ItemInfo[], groupsList: IGroup[], serviceLine: OrderServiceLines) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/orders/items/groups/recommendations/list`,
      data: {
        currentItemInfo,
        itemInfoList,
        groupsList,
        orderServiceLine: serviceLine,
      },
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async saveBagStatus(statusCode: string, bagCode: string, serviceType?: ServiceType) {
    const requestOptions: AxiosRequestConfig = {
      method: 'PUT',
      url: `/bags/${bagCode}/status/${statusCode}`,
      data: { serviceType },
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async savePackageStatus(statusCode: string, packageId: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'PUT',
      url: `/packages/${packageId}/status/${statusCode}/receiving`,
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async getPackage(packageCode: string = '', isFetchItemsList?: boolean) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/packages/${packageCode}`,
      params: {
        isFetchItemsList,
      },
    };
    const response = await Axious.request<PackageResponse>(requestOptions);
    return response.data;
  }

  public async fetchStationsList() {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/stations/list`,
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }
}

const stationService = new StationService();

// export as singleton
export default stationService;
