import React from 'react';
import classNames from 'classnames';

/** Import Enums */
import { FineryImages } from 'utils/enums/stationEnums';

/** Material UI */
import { makeStyles } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';

/** Import SVG Icons */
import { ReactComponent as FrontIcon } from 'assets/images/svgs/stepperIcons/svg/front.svg';
import { ReactComponent as BackIcon } from 'assets/images/svgs/stepperIcons/svg/back.svg';
import { ReactComponent as CareLabelIcon } from 'assets/images/svgs/stepperIcons/svg/careLabel.svg';
import { ReactComponent as LeftSideIcon } from 'assets/images/svgs/stepperIcons/svg/leftSide.svg';
import { ReactComponent as RightSideIcon } from 'assets/images/svgs/stepperIcons/svg/rightSide.svg';
import { ReactComponent as ExtraIcon } from 'assets/images/svgs/stepperIcons/svg/emptyOval.svg';
import { ReactComponent as CheckIcon } from 'assets/images/svgs/stepperIcons/svg/check-border.svg';
import { FineryAngleImages } from 'utils/interfaces';

interface Props {
  name: FineryImages;
  active?: boolean;
  completed?: boolean;
  disabled?: boolean;
  error?: boolean;
  isDelicate?: boolean;
  onToggleClick?: (name: FineryImages) => any;
  miniIcon?: boolean;
}

const defaultProps = {
  active: false,
  completed: false,
  disabled: false,
  error: false,
  isDelicate: false,
  onToggleClick: (name: FineryImages) => console.log('No click handler'),
  miniIcon: false,
};

// FIX ME: Convert to styledComponents
const useStepButtonStyle = makeStyles((theme) => ({
  button: {
    display: 'flex',
    borderRadius: '50%',
  },
  activeStep: {
    width: '7.5rem',
    height: '7.5rem',
  },
  miniIcon: {
    width: '4.5rem',
    height: '4.5rem',
  },
  miniCheckIcon: {
    width: '2.1rem !important',
  },
  checkIcon: {
    position: 'absolute',
    [theme.breakpoints.down('md')]: {
      top: '-25px',
      right: '-5px',
      width: '20px',
    },
    [theme.breakpoints.only('lg')]: {
      top: '-25px',
      right: '-5px',
      width: '40px',
    },
    [theme.breakpoints.up('xl')]: {
      top: '-20px',
      right: '-5px',
      width: '40px',
    },
  },
}));

/** Image Mapper */
export const fineryIconNameMap: { [key in keyof Required<FineryAngleImages>]: JSX.Element } = {
  careLabel: <CareLabelIcon width="6rem" height="6rem" />,
  front: <FrontIcon width="6rem" height="6rem" />,
  back: <BackIcon width="6rem" height="6rem" />,
  leftSide: <LeftSideIcon width="6rem" height="6rem" />,
  rightSide: <RightSideIcon width="6rem" height="6rem" />,
  extra: <ExtraIcon width="6rem" height="6rem" />,
};

const CustomStepperIcon: React.FC<Props> = ({
  name,
  completed = defaultProps.completed,
  disabled = defaultProps.disabled,
  error = defaultProps.error,
  active,
  onToggleClick = defaultProps.onToggleClick,
  miniIcon = defaultProps.miniIcon,
}) => {
  //Import Styles
  const classes = useStepButtonStyle();
  // Handle onClick action
  const toggleHandler = () => {
    onToggleClick(name);
  };

  let elementWithClassName: JSX.Element = fineryIconNameMap[name];

  // Assign styling
  elementWithClassName = React.cloneElement(elementWithClassName, {
    className: classNames({
      'disabled-stepper': disabled,
      [classes.activeStep]: active && !error && !disabled,
      [classes.miniIcon]: !!miniIcon,
    }),
  });

  // Manipulate the Check SVG
  let checkElementWithClass: JSX.Element = <CheckIcon />;
  checkElementWithClass = React.cloneElement(checkElementWithClass, {
    className: classNames(classes.checkIcon, { 'active-check-mark': !error, [classes.miniCheckIcon]: !!miniIcon }),
  });

  return (
    <ButtonBase className={classes.button} onClick={toggleHandler} disabled={disabled} focusRipple>
      {elementWithClassName}
      {completed && checkElementWithClass}
    </ButtonBase>
  );
};

export default CustomStepperIcon;
