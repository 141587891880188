import React, { useState, useMemo } from 'react';

import AddDescriptionDialog from './addDescriptionDialog';
import { IUserNote } from 'utils/interfaces';
import AddImageDialog from './addImageDialog';
import useUrlState from '@ahooksjs/use-url-state';

interface IProps {
  itemCode: string;
  orderAlphaId: string;
  onNoteTaken: (photo: string, description: string) => void;
  onClose: () => void;
  modalKey?: string;
  preDescription?: string;
  title?: string;
}

const AddNoteModal: React.FC<IProps> = ({ itemCode, orderAlphaId, onNoteTaken, onClose, modalKey, preDescription, title }) => {
  const [urlState, setUrlState] = useUrlState({ imageNotes: false, descriptionNotes: false, noteModalKey: '' });

  const noteModalKey = urlState.noteModalKey;

  const shouldHideModal = useMemo(() => {
    if (modalKey && modalKey !== noteModalKey) {
      return true;
    }
    return false;
  }, [modalKey, noteModalKey]);

  const [note, setNote] = useState<IUserNote>({
    description: '',
    isRemoved: false,
    itemCode,
    orderAlphaId,
    photo: '',
  });

  const showAddDescription = () => {
    setUrlState({ imageNotes: false, descriptionNotes: true });
  };

  const handleSkipPhoto = () => showAddDescription();
  const handleConfirmImage = (image: string) => {
    handleSetPhoto(image);
    showAddDescription();
  };

  const handleSetPhoto = (image: string) => setNote({ ...note, photo: image });

  if (shouldHideModal) {
    return null;
  }

  if (urlState.imageNotes === 'true') {
    return (
      <AddImageDialog
        photoAvailable={!!note.photo}
        onClose={onClose}
        onPhotoTaken={handleSetPhoto}
        onSkipPhoto={handleSkipPhoto}
        onConfirmImage={handleConfirmImage}
        itemCode={itemCode}
        title={title}
      />
    );
  }

  if (urlState.descriptionNotes === 'true') {
    return (
      <AddDescriptionDialog
        onCancel={() => setUrlState({ imageNotes: true, descriptionNotes: false })}
        image={note.photo}
        onAddNote={onNoteTaken}
        preDescription={preDescription}
      />
    );
  }

  return null;
};

export default AddNoteModal;
