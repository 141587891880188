import React, { MouseEvent } from 'react';
import classNames from 'classnames';
import './style.scss';
import styled from 'styled-components';

import { FormattedMessage } from 'react-intl';
/** Imports Material UI */
import makeStyles from '@material-ui/core/styles/makeStyles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

/** Import Enums and Types */
import { ItemTypes } from 'utils/enums/typesEnum';

/** Import Icons  */
import { ReactComponent as ShortIcon } from 'assets/images/svgs/itemTypes/short-inactive.svg';
import { ReactComponent as SockIcon } from 'assets/images/svgs/itemTypes/sock-inactive.svg';
import { ReactComponent as TShirtIcon } from 'assets/images/svgs/itemTypes/t-shirt-inactive.svg';
import { ReactComponent as JeansIcon } from 'assets/images/svgs/itemTypes/jeans-inactive.svg';
import { ReactComponent as PantsIcon } from 'assets/images/svgs/itemTypes/pants-inactive.svg';
import { ReactComponent as DuvetIcon } from 'assets/images/svgs/itemTypes/duvet-inactive.svg';
import { ReactComponent as TieIcon } from 'assets/images/svgs/itemTypes/tie-inactive.svg';
import { ReactComponent as GathraIcon } from 'assets/images/svgs/itemTypes/gathra-inactive.svg';
import { ReactComponent as JacketIcon } from 'assets/images/svgs/itemTypes/jacket-inactive.svg';
import { ReactComponent as UnderShirtIcon } from 'assets/images/svgs/itemTypes/undershirt-inactive.svg';
import { ReactComponent as HandkerchiefIcon } from 'assets/images/svgs/itemTypes/handkerchief-inactive.svg';
import { ReactComponent as BraIcon } from 'assets/images/svgs/itemTypes/bra-inactive.svg';
import { ReactComponent as SkirtIcon } from 'assets/images/svgs/itemTypes/skirt-inactive.svg';
import { ReactComponent as SuitJacketIcon } from 'assets/images/svgs/itemTypes/suit-jacket-inactive.svg';
import { ReactComponent as BlouseIcon } from 'assets/images/svgs/itemTypes/blouse-inactive.svg';
import { ReactComponent as PillowIcon } from 'assets/images/svgs/itemTypes/pillow-inactive.svg';
import { ReactComponent as AbayaScarfIcon } from 'assets/images/svgs/itemTypes/abayascarf-inactive.svg';
import { ReactComponent as VestIcon } from 'assets/images/svgs/itemTypes/vest-inactive.svg';
import { ReactComponent as BedsSheetIcon } from 'assets/images/svgs/itemTypes/bedsheets-inactive.svg';
import { ReactComponent as ShortDressIcon } from 'assets/images/svgs/itemTypes/shortdress-inactive.svg';
import { ReactComponent as TowelIcon } from 'assets/images/svgs/itemTypes/towel-inactive.svg';
import { ReactComponent as BathRobeIcon } from 'assets/images/svgs/itemTypes/bathrobe-inactive.svg';
import { ReactComponent as ScarfIcon } from 'assets/images/svgs/itemTypes/scarf-inactive.svg';
import { ReactComponent as ShirtIcon } from 'assets/images/svgs/itemTypes/shirt-inactive.svg';
import { ReactComponent as BeltIcon } from 'assets/images/svgs/itemTypes/belt-inactive.svg';
import { ReactComponent as LeggingIcon } from 'assets/images/svgs/itemTypes/legging-inactive.svg';
import { ReactComponent as JumpingSuitIcon } from 'assets/images/svgs/itemTypes/jumpsuit-inactive.svg';
import { ReactComponent as TableClothIcon } from 'assets/images/svgs/itemTypes/table-cloth-inactive.svg';
import { ReactComponent as CarpetIcon } from 'assets/images/svgs/itemTypes/carpet-inactive.svg';
import { ReactComponent as UnderwearIcon } from 'assets/images/svgs/itemTypes/underwear-inactive.svg';
import { ReactComponent as PillowCaseIcon } from 'assets/images/svgs/itemTypes/pillowcase-inactive.svg';
import { ReactComponent as KanduraIcon } from 'assets/images/svgs/itemTypes/kandura-inactive.svg';
import { ReactComponent as LongDressIcon } from 'assets/images/svgs/itemTypes/longdress-inactive.svg';
import { ReactComponent as PulloverIcon } from 'assets/images/svgs/itemTypes/pullover-inactive.svg';
import { ReactComponent as SweaterIcon } from 'assets/images/svgs/itemTypes/sweater-inactive.svg';
import { ReactComponent as CoatIcon } from 'assets/images/svgs/itemTypes/coat-inactive.svg';
import { ReactComponent as AbayaIcon } from 'assets/images/svgs/itemTypes/abaya-inactive.svg';
import { ReactComponent as OvercoatIcon } from 'assets/images/svgs/itemTypes/overcoat-inactive.svg';
import { ReactComponent as ComforterIcon } from 'assets/images/svgs/itemTypes/comforter.svg';
import { ReactComponent as ComforterCoverIcon } from 'assets/images/svgs/itemTypes/mattresscover.svg';
import { ReactComponent as TableMatIcon } from 'assets/images/svgs/itemTypes/tablematt.svg';
import { ReactComponent as DoorMatIcon } from 'assets/images/svgs/itemTypes/doormat.svg';
import { ReactComponent as BlanketSmallIcon } from 'assets/images/svgs/itemTypes/blanket-small.svg';
import { ReactComponent as BlanketLargeIcon } from 'assets/images/svgs/itemTypes/blanket-large.svg';
import { ReactComponent as BedCoverIcon } from 'assets/images/svgs/itemTypes/bedcover.svg';
import { ReactComponent as SariIcon } from 'assets/images/svgs/itemTypes/sari.svg';
import { ReactComponent as SherwaniIcon } from 'assets/images/svgs/itemTypes/sherwani.svg';
import { ReactComponent as BathingSuitIcon } from 'assets/images/svgs/itemTypes/bathing-suit.svg';
import { ReactComponent as PyjamaPantsIcon } from 'assets/images/svgs/itemTypes/pyjama-pant.svg';
import { ReactComponent as CapIcon } from 'assets/images/svgs/itemTypes/cap.svg';
import { ReactComponent as KurtaIcon } from 'assets/images/svgs/itemTypes/kurta.svg';
import { ReactComponent as CurtainIcon } from 'assets/images/svgs/itemTypes/curtain.svg';
import { ReactComponent as SofaCoverSmallIcon } from 'assets/images/svgs/itemTypes/sofa-small.svg';
import { ReactComponent as SofaCoverLargeIcon } from 'assets/images/svgs/itemTypes/sofa-large.svg';
import { ReactComponent as CushionSmallIcon } from 'assets/images/svgs/itemTypes/chushionsmall.svg';
import { ReactComponent as CushionLargeIcon } from 'assets/images/svgs/itemTypes/cushion-large.svg';
import { ReactComponent as CushionCoverIcon } from 'assets/images/svgs/itemTypes/cushion-cover.svg';
import { ReactComponent as CarpetWoolIcon } from 'assets/images/svgs/itemTypes/carpet-wool.svg';
import { ReactComponent as DuffelBagIcon } from 'assets/images/svgs/itemTypes/duffel-bag.svg';
import { ReactComponent as BackpackIcon } from 'assets/images/svgs/itemTypes/backpack.svg';
import { ReactComponent as SuitPantsIcon } from 'assets/images/svgs/itemTypes/suit-pants.svg';
import { ReactComponent as SheilaIcon } from 'assets/images/svgs/itemTypes/sheila-inactive.svg';
import { ReactComponent as AccessoryIcon } from 'assets/images/svgs/itemTypes/accessory.svg';
import { ReactComponent as LooseItemIcon } from 'assets/images/svgs/itemTypes/looseitem.svg';
import { ReactComponent as DamageIcon } from 'assets/images/svgs/damage-inactive.svg';
import { ReactComponent as StainIcon } from 'assets/images/svgs/stain-inactive.svg';
import { ReactComponent as RewashIcon } from 'assets/images/svgs/rewash-inactive.svg';
import { ReactComponent as JalabiyaIcon } from 'assets/images/svgs/itemTypes/jalabiya.svg';
import { ReactComponent as DelicateAbayaIcon } from 'assets/images/svgs/itemTypes/delicate-abaya.svg';
import { ReactComponent as FaceMaskIcon } from 'assets/images/svgs/itemTypes/face-mask.svg';
import { ReactComponent as GloveIcon } from 'assets/images/svgs/itemTypes/gloves.svg';
import { ReactComponent as RePressIcon } from 'assets/images/svgs/iron.svg';
import { ReactComponent as SendBackSpotter } from 'assets/images/svgs/itemTypes/spotter-icon.svg';
import { ReactComponent as CantClean } from 'assets/images/svgs/itemTypes/cant-clean.svg';
import { ReactComponent as Customer } from 'assets/images/svgs/itemTypes/customer.svg';
import { ReactComponent as ItemRisk } from 'assets/images/svgs/itemTypes/item-risk.svg';
import { ReactComponent as DamageAfterCleaning } from 'assets/images/svgs/stainmanIcons/damage-after-cleaning.svg';
import { ReactComponent as SmellsBad } from 'assets/images/svgs/stainmanIcons/smells-bad.svg';
import { ReactComponent as StillWet } from 'assets/images/svgs/stainmanIcons/damp-wet.svg';
import { ReactComponent as ShoePair } from 'assets/images/svgs/shoeSorterIcons/pair.svg';
import { ReactComponent as ShoeSingle } from 'assets/images/svgs/shoeSorterIcons/single.svg';
import { ReactComponent as ShoeLeft } from 'assets/images/svgs/shoeSorterIcons/left.svg';
import { ReactComponent as ShoeRight } from 'assets/images/svgs/shoeSorterIcons/right.svg';
import { ReactComponent as LeatherSportsSneakers } from 'assets/images/svgs/shoeSorterIcons/types/leather/sports-sneakers.svg';
import { ReactComponent as LeatherDesignerSneakers } from 'assets/images/svgs/shoeSorterIcons/types/leather/designer-sneakers.svg';
import { ReactComponent as LeatherFormal } from 'assets/images/svgs/shoeSorterIcons/types/leather/formal.svg';
import { ReactComponent as LeatherBoot } from 'assets/images/svgs/shoeSorterIcons/types/leather/ankle-boots.svg';
import { ReactComponent as HighBoot } from 'assets/images/svgs/shoeSorterIcons/types/leather/high-boots.svg';
import { ReactComponent as ThighHighBoot } from 'assets/images/svgs/shoeSorterIcons/types/leather/thigh-high-boots.svg';
import { ReactComponent as LeatherFlipFlop } from 'assets/images/svgs/shoeSorterIcons/types/leather/flip-flops.svg';
import { ReactComponent as LeatherSandal } from 'assets/images/svgs/shoeSorterIcons/types/leather/sandals.svg';
import { ReactComponent as LeatherEspardrilles } from 'assets/images/svgs/shoeSorterIcons/types/leather/espardrilles.svg';
import { ReactComponent as MixSportsSneakers } from 'assets/images/svgs/shoeSorterIcons/types/mix/sports-sneakers.svg';
import { ReactComponent as MixDesignerSneakers } from 'assets/images/svgs/shoeSorterIcons/types/mix/designer-sneakers.svg';
import { ReactComponent as MixFormal } from 'assets/images/svgs/shoeSorterIcons/types/mix/formal.svg';
import { ReactComponent as MixBoot } from 'assets/images/svgs/shoeSorterIcons/types/mix/ankle-boots.svg';
import { ReactComponent as MixFlipFlop } from 'assets/images/svgs/shoeSorterIcons/types/mix/flip-flops.svg';
import { ReactComponent as MixSandal } from 'assets/images/svgs/shoeSorterIcons/types/mix/sandals.svg';
import { ReactComponent as MixEspardrilles } from 'assets/images/svgs/shoeSorterIcons/types/mix/espardrilles.svg';
import { ReactComponent as SuedeNubuckSportsSneakers } from 'assets/images/svgs/shoeSorterIcons/types/suedeNubuck/sports-sneakers.svg';
import { ReactComponent as SuedeNubuckDesignerSneakers } from 'assets/images/svgs/shoeSorterIcons/types/suedeNubuck/designer-sneakers.svg';
import { ReactComponent as SuedeNubuckFormal } from 'assets/images/svgs/shoeSorterIcons/types/suedeNubuck/formal.svg';
import { ReactComponent as SuedeNubuckBoot } from 'assets/images/svgs/shoeSorterIcons/types/suedeNubuck/ankle-boots.svg';
import { ReactComponent as SuedeNubuckFlipFlop } from 'assets/images/svgs/shoeSorterIcons/types/suedeNubuck/flip-flops.svg';
import { ReactComponent as SuedeNubuckSandal } from 'assets/images/svgs/shoeSorterIcons/types/suedeNubuck/sandals.svg';
import { ReactComponent as SuedeNubuckEspardrilles } from 'assets/images/svgs/shoeSorterIcons/types/suedeNubuck/espardrilles.svg';
import { ReactComponent as SythenticsTextileSportsSneakers } from 'assets/images/svgs/shoeSorterIcons/types/sythenticsTextile/sports-sneakers.svg';
import { ReactComponent as SythenticsTextileDesignerSneakers } from 'assets/images/svgs/shoeSorterIcons/types/sythenticsTextile/designer-sneakers.svg';
import { ReactComponent as SythenticsTextileFormal } from 'assets/images/svgs/shoeSorterIcons/types/sythenticsTextile/formal.svg';
import { ReactComponent as SythenticsTextileBoot } from 'assets/images/svgs/shoeSorterIcons/types/sythenticsTextile/ankle-boots.svg';
import { ReactComponent as SythenticsTextileFlipFlop } from 'assets/images/svgs/shoeSorterIcons/types/sythenticsTextile/flip-flops.svg';
import { ReactComponent as SythenticsTextileSandal } from 'assets/images/svgs/shoeSorterIcons/types/sythenticsTextile/sandals.svg';
import { ReactComponent as SythenticsTextileEspardrilles } from 'assets/images/svgs/shoeSorterIcons/types/sythenticsTextile/espardrilles.svg';
import { ReactComponent as ChildrensSportsSneakers } from 'assets/images/svgs/shoeSorterIcons/types/children-sneaker.svg';
import { ReactComponent as ChildrensFormal } from 'assets/images/svgs/shoeSorterIcons/types/children-formal.svg';
import { ReactComponent as ShoeStainIcon } from 'assets/images/svgs/sorterModal/shoe-stain-damage-black.svg';
import { ReactComponent as ShoeCantBeCleaned } from 'assets/images/svgs/stainAndDamage/shoe-cant-be-cleaned.svg';
import { ReactComponent as ClutchPurse } from 'assets/images/svgs/handBagIcons/clutch-purse.svg';
import { ReactComponent as Wallets } from 'assets/images/svgs/handBagIcons/wallet.svg';
import { ReactComponent as BagsStandardSize } from 'assets/images/svgs/handBagIcons/bag-standard-size.svg';
import { ReactComponent as BagsOversized } from 'assets/images/svgs/handBagIcons/bag-oversized.svg';
import { ReactComponent as MensBriefcase } from 'assets/images/svgs/handBagIcons/mens-breifcase.svg';
import { ReactComponent as ValiseSuitcase } from 'assets/images/svgs/handBagIcons/suitcase.svg';
import { ReactComponent as Backpacks } from 'assets/images/svgs/handBagIcons/backpack.svg';
import { ReactComponent as Belts } from 'assets/images/svgs/handBagIcons/belt.svg';
import { ReactComponent as WinterJacketIcon } from 'assets/images/svgs/itemTypes/winter-jacket.svg';
import { ReactComponent as WinterOvercoatIcon } from 'assets/images/svgs/itemTypes/winter-overcoat.svg';
import { ReactComponent as ShoesNotFound } from 'assets/images/svgs/packages/shoes-not-found.svg';
import { ReactComponent as ShoesNotReady } from 'assets/images/svgs/packages/shoes-not-ready.svg';
import { ReactComponent as LaundryNotFound } from 'assets/images/svgs/packages/laundry-not-found.svg';
import { ReactComponent as LaundryNotReady } from 'assets/images/svgs/packages/laundry-not-ready.svg';
import { ReactComponent as Apron } from 'assets/images/svgs/itemTypes/apron.svg';
import { ReactComponent as StuffedToySmall } from 'assets/images/svgs/itemTypes/stuffed-toy-small.svg';
import { ReactComponent as StuffedToyMedium } from 'assets/images/svgs/itemTypes/stuffed-toy-medium.svg';
import { ReactComponent as StuffedToyLarge } from 'assets/images/svgs/itemTypes/stuffed-toy-large.svg';
import { ReactComponent as BridalDress } from 'assets/images/svgs/itemTypes/bridal-dress.svg';
import { ReactComponent as LeatherJacket } from 'assets/images/svgs/itemTypes/leather-jacket.svg';
import { ReactComponent as FormalDress } from 'assets/images/svgs/itemTypes/formal-dress.svg';
import { ReactComponent as SleevlesUndershirt } from 'assets/images/svgs/itemTypes/sleeveless-undershirt.svg';
import { ReactComponent as GiletJacket } from 'assets/images/svgs/itemTypes/gilet-jacket.svg';
import { ReactComponent as CasualDress } from 'assets/images/svgs/itemTypes/casual-dress.svg';
import { ReactComponent as EveningGown } from 'assets/images/svgs/itemTypes/evening-gown.svg';
import { ReactComponent as WaistCoat } from 'assets/images/svgs/itemTypes/waist-coat.svg';
import { ReactComponent as SwimmingSuit } from 'assets/images/svgs/itemTypes/swimming-suit.svg';
import { ReactComponent as PyjamaTop } from 'assets/images/svgs/itemTypes/pyjama-top.svg';
import { ReactComponent as WinterPants } from 'assets/images/svgs/itemTypes/winter-pants.svg';
import { ReactComponent as WinterCoat } from 'assets/images/svgs/itemTypes/winter-coat.svg';
import { ReactComponent as TuxedoPants } from 'assets/images/svgs/itemTypes/tuxedo-pants.svg';
import { ReactComponent as TuxedoJacket } from 'assets/images/svgs/itemTypes/tuxedo-jacket.svg';
import { ReactComponent as Shoes } from 'assets/images/svgs/itemTypes/shoes.svg';
import { ReactComponent as Bags } from 'assets/images/svgs/itemTypes/bags.svg';
import { ReactComponent as Veil } from 'assets/images/svgs/itemTypes/veil.svg';
import { ReactComponent as ShoeLace } from 'assets/images/svgs/itemTypes/shoeLace.svg';
import { ReactComponent as DesignerFormalShoesSuede } from 'assets/images/svgs/itemTypes/designerFormalShoesSuede.svg';
import { ReactComponent as DressComplex } from 'assets/images/svgs/itemTypes/dress-complex.svg';
import { ReactComponent as DressEvening } from 'assets/images/svgs/itemTypes/dress-evening.svg';
import { ReactComponent as SportSweaterHoodie } from 'assets/images/svgs/itemTypes/sport-sweater-hoodie.svg';
import { ReactComponent as SweaterHoodie } from 'assets/images/svgs/itemTypes/sweater-hoodie.svg';
import { ReactComponent as ThickSweaterHoodie } from 'assets/images/svgs/itemTypes/thick-sweater-hoodie.svg';
import { ReactComponent as ShoeBag } from 'assets/images/svgs/extraItems/shoe-bag.svg';
import { ReactComponent as ShoeBox } from 'assets/images/svgs/extraItems/shoe-box.svg';
import { ReactComponent as ShoeTree } from 'assets/images/svgs/extraItems/shoe-tree.svg';
import { ReactComponent as Hangers } from 'assets/images/svgs/extraItems/hangers.svg';
import { ReactComponent as LaundryBag } from 'assets/images/svgs/extraItems/laundry-bag.svg';
import { ReactComponent as SuitCover } from 'assets/images/svgs/extraItems/suit-cover.svg';
import { ReactComponent as DesignerEspardrilles } from 'assets/images/svgs/shoeSorterIcons/designer-espardrilles.svg';
import { ReactComponent as DesignerSandalsFlipFlops } from 'assets/images/svgs/shoeSorterIcons/designer-sandals-flipflops.svg';
import { ReactComponent as BootsAnkle } from 'assets/images/svgs/shoeSorterIcons/boots-ankle.svg';
import { ReactComponent as DesignerFormalSythenticsTextile } from 'assets/images/svgs/shoeSorterIcons/designer-formal-sythentics-textile.svg';
import { ReactComponent as DesignerFormalLeather } from 'assets/images/svgs/shoeSorterIcons/designer-formal-leather.svg';
import { ReactComponent as DesignerFormalMix } from 'assets/images/svgs/shoeSorterIcons/designer-formal-mix.svg';
import { ReactComponent as ReusableBag } from 'assets/images/svgs/itemTypes/reusable-bag.svg';
import { ReactComponent as FormalHeels } from 'assets/images/svgs/shoeSorterIcons/formal-heels.svg';
import { ReactComponent as DesignerFormalHeels } from 'assets/images/svgs/shoeSorterIcons/designer-formal-heels.svg';

/** Button Selector Styles */
const useButtonSelectorStyles = (iconProps: { height: string; width: string }) =>
  makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    typo: {
      padding: '10px 0',
      justifyContent: 'center',
      fontWeight: 400,
    },
    icon: {
      padding: '10px 15px',
      width: iconProps.width,
      height: iconProps.height,
    },
    iconLabel: {
      padding: '10px 0',
    },
    labelBox: {
      width: '100%',
      border: '2px solid #b6bcd1',
      borderRadius: '5px',
      alignItems: 'center',
      justifyItems: 'center',
    },
    iconBox: {
      border: '2px solid #b6bcd1',
      borderRadius: '5px',
    },
  }));

/** Mapper's props */
interface Iicon {
  element: JSX.Element;
  label: string;
  activeElement?: JSX.Element;
}

/** Icon Component Mapper  */
const iconComponentMap: { [key: string]: Iicon } = {
  shorts: { element: <ShortIcon />, label: 'Shorts' },
  socks: { element: <SockIcon />, label: 'Socks' },
  tShirt: { element: <TShirtIcon />, label: 'T-Shirt' },
  jeans: { element: <JeansIcon />, label: 'Jeans' },
  pants: { element: <PantsIcon />, label: 'Pants' },
  duvet: { element: <DuvetIcon />, label: 'Duvet' },
  tie: { element: <TieIcon />, label: 'Tie' },
  gathra: { element: <GathraIcon />, label: 'Gathra' },
  jacket: { element: <JacketIcon />, label: 'Jacket' },
  undershirt: { element: <UnderShirtIcon />, label: 'Undershirt' },
  handkerchief: { element: <HandkerchiefIcon />, label: 'Handkerchief' },
  bra: { element: <BraIcon />, label: 'Bra' },
  skirt: { element: <SkirtIcon />, label: 'Skirt' },
  suitJacket: { element: <SuitJacketIcon />, label: 'Suit Jacket' },
  blouse: { element: <BlouseIcon />, label: 'Blouse' },
  looseItem: { element: <LooseItemIcon />, label: 'Loose Item' },
  accessory: { element: <AccessoryIcon />, label: 'Accessory' },
  pillow: { element: <PillowIcon />, label: 'Pillow' },
  abayascarf: { element: <AbayaScarfIcon />, label: 'Abaya Scarf' },
  vest: { element: <VestIcon />, label: 'Vest' },
  bedsheet: { element: <BedsSheetIcon />, label: 'Bed Sheet' },
  dressBasic: { element: <ShortDressIcon />, label: 'Dress Basic' },
  towel: { element: <TowelIcon />, label: 'Towel' },
  bathrobe: { element: <BathRobeIcon />, label: 'Bath Robe' },
  scarf: { element: <ScarfIcon />, label: 'Scarf' },
  shirt: { element: <ShirtIcon />, label: 'Shirt' },
  belt: { element: <BeltIcon />, label: 'Belt' },
  legging: { element: <LeggingIcon />, label: 'Legging' },
  jumpsuit: { element: <JumpingSuitIcon />, label: 'Jumpsuit' },
  tableCloth: { element: <TableClothIcon />, label: 'Table Cloth' },
  carpet: { element: <CarpetIcon />, label: 'Carpet' },
  underwear: { element: <UnderwearIcon />, label: 'Underwear' },
  pillowCase: { element: <PillowCaseIcon />, label: 'Pillowcase' },
  kandura: { element: <KanduraIcon />, label: 'Kandura' },
  longDress: { element: <LongDressIcon />, label: 'Long Dress' },
  pullover: { element: <PulloverIcon />, label: 'Pullover' },
  sweater: { element: <SweaterIcon />, label: 'Sweater' },
  coat: { element: <CoatIcon />, label: 'Coat' },
  abaya: { element: <AbayaIcon />, label: 'Abaya' },
  overcoat: { element: <OvercoatIcon />, label: 'Overcoat' },
  comforter: { element: <ComforterIcon />, label: 'Comforter' },
  comforterCover: { element: <ComforterCoverIcon />, label: 'Comforter Cover' },
  tablemat: { element: <TableMatIcon />, label: 'Table Mat' },
  doormat: { element: <DoorMatIcon />, label: 'Floor Mat' },
  blanketSmall: { element: <BlanketSmallIcon />, label: 'Blanket Small' },
  blanketLarge: { element: <BlanketLargeIcon />, label: 'Blanket Large' },
  bedCover: { element: <BedCoverIcon />, label: 'Bed Cover' },
  sari: { element: <SariIcon />, label: 'Sari' },
  sheila: { element: <SheilaIcon />, label: 'Sheila' },
  sherwani: { element: <SherwaniIcon />, label: 'Sherwani' },
  bathingSuit: { element: <BathingSuitIcon />, label: 'Bathing Suit' },
  suitPants: { element: <SuitPantsIcon />, label: 'Suits Pant' },
  pyjamaPants: { element: <PyjamaPantsIcon />, label: 'Pyjama Pants' },
  cap: { element: <CapIcon />, label: 'Cap' },
  kurta: { element: <KurtaIcon />, label: 'Kurta' },
  curtain: { element: <CurtainIcon />, label: 'Curtain' },
  sofaCoverSmall: { element: <SofaCoverSmallIcon />, label: 'Sofa Cover Small' },
  sofaCoverLarge: { element: <SofaCoverLargeIcon />, label: 'Sofa Cover large' },
  sofaCover: { element: <SofaCoverLargeIcon />, label: 'Sofa Cover' },
  cushionSmall: { element: <CushionSmallIcon />, label: 'Cushion Small' },
  cushionLarge: { element: <CushionLargeIcon />, label: 'Cushion Large' },
  cushionCover: { element: <CushionCoverIcon />, label: 'Cushion Cover' },
  cushion: { element: <CushionSmallIcon />, label: 'Cushion' },
  carpetWool: { element: <CarpetWoolIcon />, label: 'Carpet Wool' },
  duffelBag: { element: <DuffelBagIcon />, label: 'Duffel Bag' },
  backpack: { element: <BackpackIcon />, label: 'Backpack' },
  stain: { element: <StainIcon />, label: 'Stain' },
  damage: { element: <DamageIcon />, label: 'Damage' },
  rewash: { element: <RewashIcon />, label: 'Rewash' },
  jalabiya: { element: <JalabiyaIcon />, label: 'Jalabiya' },
  delicateAbaya: { element: <DelicateAbayaIcon />, label: 'Delicate Abaya' },
  faceMask: { element: <FaceMaskIcon />, label: 'Face Mask' },
  glove: { element: <GloveIcon />, label: 'Glove' },
  repress: { element: <RePressIcon />, label: 'Repress' },
  spotter: { element: <SendBackSpotter />, label: 'Send Back To Spotter' },
  cantClean: { element: <CantClean />, label: "Can't be cleaned" },
  customer: { element: <Customer />, label: 'Need Customer Approval' },
  itemRisk: { element: <ItemRisk />, label: 'Item At Risk' },
  damageAfterCleaning: { element: <DamageAfterCleaning />, label: 'Damage After Cleaning' },
  smellsBad: { element: <SmellsBad />, label: 'Smells Bad' },
  stillWet: { element: <StillWet />, label: 'Still Damp/Wet' },
  addStain: { element: <StainIcon />, label: 'Add Stain' },
  addDamage: { element: <DamageIcon />, label: 'Add Damage' },
  shoePair: { element: <ShoePair />, label: 'Pair' },
  shoeSingle: { element: <ShoeSingle />, label: 'Single' },
  shoeRight: { element: <ShoeRight />, label: 'Right' },
  shoeLeft: { element: <ShoeLeft />, label: 'Left' },
  sportsSneakersLeather: { element: <LeatherSportsSneakers />, label: 'Sports Sneakers Leather' },
  designerSneakersLeather: { element: <LeatherDesignerSneakers />, label: 'Designer Sneakers Leather' },
  formalShoeLeather: { element: <LeatherFormal />, label: 'Formal Leather' },
  bootsLeather: { element: <LeatherBoot />, label: 'Boot Leather' },
  leatherFlipFlop: { element: <LeatherFlipFlop />, label: 'Flip Flop Leather' },
  sandalLeather: { element: <LeatherSandal />, label: 'Sandal Leather' },
  espardrillesLeather: { element: <LeatherEspardrilles />, label: 'Espardrilles Leather' },
  sportsSneakersMix: { element: <MixSportsSneakers />, label: 'Sports Sneakers Mix' },
  designerSneakersMix: { element: <MixDesignerSneakers />, label: 'Designer Sneakers Mix' },
  formalShoeMix: { element: <MixFormal />, label: 'Formal Mix' },
  bootsMix: { element: <MixBoot />, label: 'Boot Mix' },
  flipFlopMix: { element: <MixFlipFlop />, label: 'Flip Flop Mix' },
  sandalMix: { element: <MixSandal />, label: 'Sandal Mix' },
  espardrillesMix: { element: <MixEspardrilles />, label: 'Espardrilles Mix' },
  sportsSneakersSuedeNubuck: { element: <SuedeNubuckSportsSneakers />, label: 'Sports Sneakers Suede / Nubuck' },
  designerSneakersSuede: { element: <SuedeNubuckDesignerSneakers />, label: 'Designer Sneakers Suede / Nubuck' },
  formalShoeSuede: { element: <SuedeNubuckFormal />, label: 'Formal Suede / Nubuck' },
  bootsSuede: { element: <SuedeNubuckBoot />, label: 'Boot Suede / Nubuck' },
  flipFlopSuede: { element: <SuedeNubuckFlipFlop />, label: 'Flip Flop Suede / Nubuck' },
  suedeNubuckSandal: { element: <SuedeNubuckSandal />, label: 'Sandal Suede / Nubuck' },
  espardrillesSuede: { element: <SuedeNubuckEspardrilles />, label: 'Espardrilles Suede / Nubuck' },
  sportsSneakersSynthenticsTextile: { element: <SythenticsTextileSportsSneakers />, label: 'Sports Sneakers Sythentics / Textile' },
  designerSneakersSythenticsTextile: { element: <SythenticsTextileDesignerSneakers />, label: 'Designer Sneakers Sythentics / Textile' },
  formalShoeSythenticsTextile: { element: <SythenticsTextileFormal />, label: 'Formal Sythentics / Textile' },
  bootsSythenticsTextile: { element: <SythenticsTextileBoot />, label: 'Boot Sythentics / Textile' },
  flipFlopSythenticsTextile: { element: <SythenticsTextileFlipFlop />, label: 'Flip Flop Sythentics / Textile' },
  sandalSynthenticsTextile: { element: <SythenticsTextileSandal />, label: 'Sandal Sythentics / Textile' },
  espardrillesSythenticsTextile: { element: <SythenticsTextileEspardrilles />, label: 'Espardrilles Sythentics / Textile' },
  sportsSneakersChildren: { element: <ChildrensSportsSneakers />, label: 'Children Sports Sneakers' },
  formalShoeChildren: { element: <ChildrensFormal />, label: 'Children Formal' },
  bootsHighBoots: { element: <HighBoot />, label: 'High Boots All' },
  bootsThighHighBoots: { element: <ThighHighBoot />, label: 'Thigh High Boots All' },
  shoeStain: { element: <ShoeStainIcon />, label: 'Stain' },
  shoeCantBeCleaned: { element: <ShoeCantBeCleaned />, label: "Can't Be Cleaned" },
  clutchPurse: { element: <ClutchPurse />, label: 'Clutch/Purse' },
  wallets: { element: <Wallets />, label: 'Wallets' },
  bagsStandardSize: { element: <BagsStandardSize />, label: 'Standard size' },
  bagsOversized: { element: <BagsOversized />, label: 'Oversized' },
  mensBriefcase: { element: <MensBriefcase />, label: 'Men’s briefcase' },
  valiseSuitcase: { element: <ValiseSuitcase />, label: 'Valise/suitcase' },
  backpacks: { element: <Backpacks />, label: 'Backpacks' },
  belts: { element: <Belts />, label: 'Belts' },
  winterJacket: { element: <WinterJacketIcon />, label: 'Winter Jacket' },
  winterOvercoat: { element: <WinterOvercoatIcon />, label: 'Winter Overcoat (Below Knee)' },
  laundryPackageNotReady: { element: <LaundryNotReady />, label: 'Items Not Ready' },
  laundryPackageNotFound: { element: <LaundryNotFound />, label: 'Items Not Found' },
  shoesPackageNotReady: { element: <ShoesNotReady />, label: 'Items Not Ready' },
  shoesPackageNotFound: { element: <ShoesNotFound />, label: 'Items Not Found' },
  apron: { element: <Apron />, label: 'Apron' },
  smallStuffedToy: { element: <StuffedToySmall />, label: 'Small Stuffed Toy' },
  mediumStuffedToy: { element: <StuffedToyMedium />, label: 'Medium Stuffed Toy' },
  largeStuffedToy: { element: <StuffedToyLarge />, label: 'Large Stuffed Toy' },
  bridalDress: { element: <BridalDress />, label: 'Bridal Dress' },
  leatherJacket: { element: <LeatherJacket />, label: 'Leather Jacket' },
  formalDress: { element: <FormalDress />, label: 'Formal Dress' },
  giletJacket: { element: <GiletJacket />, label: 'Gilet Jacket' },
  casualDress: { element: <CasualDress />, label: 'Casual Dress' },
  eveningGown: { element: <EveningGown />, label: 'Evening Gown' },
  waistCoat: { element: <WaistCoat />, label: 'Waist Coat' },
  tuxedoPants: { element: <TuxedoPants />, label: 'Tuxedo Pants' },
  tuxedoJacket: { element: <TuxedoJacket />, label: 'Tuxedo Jacket' },
  shoes: { element: <Shoes />, label: 'Shoes' },
  bags: { element: <Bags />, label: 'Bags' },
  sleevelesUndershirt: { element: <SleevlesUndershirt />, label: 'Sleeveles Undershirt' },
  pyjamaTop: { element: <PyjamaTop />, label: 'Pyjama Top' },
  winterPants: { element: <WinterPants />, label: 'Winter Pants' },
  winterCoat: { element: <WinterCoat />, label: 'Winter Coat' },
  swimmingSuit: { element: <SwimmingSuit />, label: 'Swimming Suit' },
  designerFormalShoesSuede: { element: <DesignerFormalShoesSuede />, label: 'Designer Formal Shoes Suede' },
  shoeLaces: { element: <ShoeLace />, label: 'Shoe Laces' },
  veil: { element: <Veil />, label: 'Veil' },
  dressComplex: { element: <DressComplex />, label: 'Dress Complex' },
  dressEvening: { element: <DressEvening />, label: 'Dress Evening' },
  sportSweaterHoodie: { element: <SportSweaterHoodie />, label: 'Sport Sweater Hoodie' },
  sweaterHoodie: { element: <SweaterHoodie />, label: 'Sweater Hoodie' },
  thickSweaterHoodie: { element: <ThickSweaterHoodie />, label: 'Thick Sweater Hoodie' },
  shoeBox: { element: <ShoeBox />, label: 'Shoe Box' },
  shoeBag: { element: <ShoeBag />, label: 'Shoe Bag' },
  shoeTree: { element: <ShoeTree />, label: 'Shoe Tree' },
  suitCover: { element: <SuitCover />, label: 'Suit Cover' },
  laundryBag: { element: <LaundryBag />, label: 'Laundry Bag' },
  hangers: { element: <Hangers />, label: 'Hangers' },
  designerEspardrilles: { element: <DesignerEspardrilles />, label: 'Designer Espardrilles' },
  designerSandalsFlipflops: { element: <DesignerSandalsFlipFlops />, label: 'Designer Sandals / FlipFlops' },
  bootsAnkle: { element: <BootsAnkle />, label: 'Ankle Boots' },
  designerFormalSythenticsTextile: { element: <DesignerFormalSythenticsTextile />, label: 'Designer formal Synthetic / Textile' },
  designerFormalLeather: { element: <DesignerFormalLeather />, label: 'Desinger formal leather (shoe polishing)' },
  designerFormalMix: { element: <DesignerFormalMix />, label: 'Designer Formal Mix' },
  reusableBag: { element: <ReusableBag />, label: 'Reusable Bag' },
  mensFormalShoeLeather: { element: <LeatherFormal />, label: `Men's Formal Leather Shoe` },
  mensFormalShoesMixedMaterial: { element: <MixFormal />, label: `Men's Formal Mix Material Shoe` },
  mensDesignerFormalLeather: { element: <DesignerFormalLeather />, label: `Men's Designer Formal Leather Shoe` },
  mensDesignerFormalMixedMaterial: { element: <DesignerFormalMix />, label: `Men's Designer Formal Mix Material Shoe` },
  womensFormalShoe: { element: <FormalHeels />, label: `Women's Formal Shoe` },
  womenDesignerFormal: { element: <DesignerFormalHeels />, label: `Women's Designer Formal Shoe` },
};

/** LabelSelector Props */
export interface Props {
  iconName?: ItemTypes;
  activeColor?: '#8B5C5E' | '#f3aa39' | '#19a784' | '#3e48a6';
  inactiveColor?: '#8B5C5E' | '#f3aa39' | '#19a784' | '#b6bcd1';
  isActive?: boolean;
  isFilled?: boolean;
  isDashed?: boolean;
  isRounded?: boolean;
  width?: string;
  children?: string;
  pathType?: 'fill' | 'stroke';
  activeClassName?: string;
  inactiveClassName?: string;
  onToggleClick?: ($event: MouseEvent) => void;
  dataCy?: string;
  iconDimensions?: { height: string; width: string };
  labelFontSize?: string;
  customIconFills?: { firstIconFill?: string; secondIconFill?: string };
}

/** LabelSelector Default Props */
const defaultProps = {
  isActive: false,
  isFilled: false,
  isDashed: false,
  isRounded: false,
  activeColor: '#3e48a6',
  pathType: 'fill',
  width: undefined,
  children: '',
  iconProps: { height: '80px', width: '100px' },
  onToggleClick: ($event: MouseEvent) => console.log('No click handler'),
  customIconFills: { firstIconFill: '', secondIconFill: '' },
};

/** LabelSelector FC
 *
 * Should be refactored to be one component that handles all labels for all serviceLines
 * For now, please update this component and the other one in this project
 * //TODO: Refactor to use 1 component instead of maintaining 2 but with different styles
 *
 */
const LabelSelector: React.FC<Props> = ({
  iconName,
  pathType = 'fill',
  children = defaultProps.children,
  onToggleClick = defaultProps.onToggleClick,
  activeColor = defaultProps.activeColor,
  isActive = defaultProps.isActive,
  isFilled = defaultProps.isFilled,
  isDashed = defaultProps.isDashed,
  isRounded = defaultProps.isRounded,
  width = defaultProps.width,
  iconDimensions = defaultProps.iconProps,
  activeClassName = '',
  inactiveClassName = '',
  dataCy = '',
  labelFontSize = '',
  customIconFills: { firstIconFill, secondIconFill } = defaultProps.customIconFills,
}) => {
  // Import Styles
  const classes = useButtonSelectorStyles(iconDimensions)();
  // Default Inactive Color (Gray)
  const _defaultInactiveColor = '#b6bcd1';

  // Manipulate SVG with the isActive Flag
  let elementWithClassName: JSX.Element = <></>;

  if (iconName) {
    elementWithClassName = iconComponentMap[iconName].element;
    elementWithClassName = React.cloneElement(elementWithClassName, {
      className: classNames(
        classes.icon,
        { 'active-icon': isActive && pathType !== 'stroke' },
        { 'active-stroke': isActive && pathType === 'stroke' },
        { [inactiveClassName]: !isActive && inactiveClassName },
        { [activeClassName]: isActive && activeClassName },
        isActive,
        !isActive
      ),
    });
  }

  return (
    <StyledButtonBase
      focusRipple
      path_type={pathType}
      second_icon_fill={secondIconFill}
      style={{
        borderRadius: isRounded ? 50 : 5,
        maxWidth: iconName ? '100%' : '100%',
        marginBottom: '20px',
        marginLeft: '10px',
        marginRight: '10px',
        width: width ? width : undefined,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyItems="center"
        alignItems="center"
        onClick={($event: MouseEvent) => onToggleClick($event)}
        className={classes.root}
        data-cy={dataCy}
      >
        {iconName ? (
          <>
            <Box
              style={{
                borderColor: isActive ? activeColor : isFilled ? activeColor : _defaultInactiveColor,
                backgroundColor: isActive ? (isFilled ? activeColor : '#fff') : '#fff',
              }}
              className={classes.iconBox}
            >
              {elementWithClassName}
            </Box>
            <IconLabel font_size={labelFontSize} className={classNames(classes.iconLabel, { 'active-label': isActive, 'inactive-label': !isActive })}>
              {iconComponentMap[iconName].label}
            </IconLabel>{' '}
          </>
        ) : (
          <div
            className={classes.labelBox}
            style={{
              color: isActive ? (isFilled ? '#fff' : activeColor) : isFilled ? activeColor : _defaultInactiveColor,
              borderColor: isActive ? activeColor : isFilled ? activeColor : _defaultInactiveColor,
              borderRadius: isRounded ? 50 : 5,
              borderStyle: isDashed ? 'dashed' : 'solid',
            }}
          >
            {children && (
              <Typography variant="h6" className={classes.typo}>
                <FormattedMessage id={children} defaultMessage={children} />
              </Typography>
            )}
          </div>
        )}
      </Box>
    </StyledButtonBase>
  );
};

export default LabelSelector;

const StyledButtonBase = styled(ButtonBase)<{ firstIconFill?: string; second_icon_fill?: string; path_type?: 'fill' | 'stroke' }>`
  .active-two-color-icon > g rect {
    fill: ${({ second_icon_fill }) => (second_icon_fill ? second_icon_fill : '#3e48a6')};
  }

  .active-two-color-icon > g {
    ${({ path_type, firstIconFill }) => (path_type ? `${path_type}:` : 'fill: ') + (firstIconFill ? firstIconFill : '#3e48a6')};
  }
`;

const IconLabel = styled.div<{ font_size?: string }>`
  ${({ font_size }) => (font_size ? `font-size  : ${font_size};` : '')}
`;
