import React, { useState, useRef, useEffect, useMemo, BaseSyntheticEvent } from 'react';
import { Grid, TextField, Box, FormControl, InputAdornment } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import styled from 'styled-components';
import startCase from 'lodash/startCase';
import capitalize from 'lodash/capitalize';

import { ItemMeasurements, MeasurementConfig } from 'utils/interfaces';
import { MainKeyboard } from 'components/mainKeyboard';
import { MainKeyboardRefProps } from 'components/mainKeyboard/mainKeyboard';
import { ComponentProps, useGetSelectedItem } from 'modules/stations/components/SortingStepper/SortingStepper';
import useGetItemTypeMeasurementsConfig from 'services/queries/useGetItemTypeMeasurementsConfig';
import { Controller, useForm } from 'react-hook-form';
import { UserItemNotes } from '..';

import { StyledButton } from 'modules/stations/components/photoCatcher';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '2rem',
    },
    paper: {
      padding: '10px 4px',
      display: 'flex',
      alignItems: 'stretch',
    },
    input: {
      marginLeft: 8,
      flex: 1,
    },
    keyBoard: {
      width: '100%',
      position: 'absolute',
      bottom: '0',
      left: '0',
      right: '0',
      zIndex: 999,
    },
  }),
);

interface Inputs {
  [x: string]: string;
}

export interface InputObj extends Partial<Inputs> {
}

export interface InputState<InputNames> {
  input: InputObj;
  keyboardOpen: boolean;
  inputName: InputNames;
}

export type InputNameTypes = string;

interface Props extends ComponentProps {
}

type FormData = { [key in string]: string | number };

export const getDefault = (measurementConfig: MeasurementConfig, measurementsList: ItemMeasurements[] = []) => {
  const measurementValue =
    measurementsList?.find((measurement) => measurement.key === measurementConfig.key)?.value ?? (measurementConfig.type === 'number' ? 0 : '');
  return measurementConfig.type === 'number' ? Number(measurementValue) || 0 : measurementValue;
};
const SelectMeasurementsDialog: React.FC<Props> = ({ onSelect }) => {
  // Import Style
  const classes = useStyles();
  const { selectedItem: currentItem } = useGetSelectedItem();
  const { data: measurementsConfig } = useGetItemTypeMeasurementsConfig(currentItem.type);
  const formMethods = useForm<FormData>();
  const keyboardRef = useRef<MainKeyboardRefProps>(null);
  const [openNotesModal, setOpenNotesModal] = useState(false);

  const keyboardInputKeys = useMemo<InputObj>(() => {
    const filterKeys: InputObj = {};
    measurementsConfig?.measurements.forEach(
      (measurement) =>
        (filterKeys[measurement.key] = String(getDefault(measurementsConfig.keyedMeasurements[measurement.key], currentItem.measurementsList) || '')),
    );
    return filterKeys;
  }, [currentItem.measurementsList, measurementsConfig]);

  const [inputsState, setInputsState] = useState<InputState<InputNameTypes>>({
    input: { default: '', ...keyboardInputKeys },
    inputName: '',
    keyboardOpen: false,
  });

  useEffect(() => {
    // reset Input Value
    if (inputsState.inputName && keyboardRef.current) {
      const value = inputsState.input[inputsState.inputName];
      if (value) {
        keyboardRef.current.setInput(value);
      } else {
        keyboardRef.current.clearInput();
      }
    }
  }, [inputsState.input, inputsState.inputName]);

  /**
   * @description
   * Sets the specified input as the active one and opens the keyboard
   * @param inputName
   */
  const setActiveInput = (inputName: InputNameTypes) => {
    setInputsState((prev) => ({ ...prev, inputName, keyboardOpen: true }));
  };

  /**
   * @description
   * Updates keyboard input-name/target to the changed input name
   * @param value
   */
  const handleInputChange = (value: string) => {
    let output: number | string = parseFloat(value);
    output = isNaN(output) ? '' : output;
    if (measurementsConfig?.keyedMeasurements?.[inputsState.inputName]?.type !== 'number') {
      output = value;
    }

    formMethods.setValue(inputsState.inputName, output);
    formMethods.trigger(inputsState.inputName);
    updateKeyboardInputs(String(output));
  };

  const updateKeyboardInputs = (value: string) => {
    setInputsState((prev) => ({ ...prev, input: { ...prev.input, [inputsState.inputName]: value } }));
  };

  /**
   * Clears input and keyboard input
   */
  const onClearInput = () => {
    keyboardRef.current?.clearInput();
    handleInputChange('');
  };

  const onToggleKeyboard = (value: boolean, measurementKey: string) => {
    value && setActiveInput(measurementKey);
    setInputsState((prev) => ({ ...prev, input: { ...prev.input, [prev.inputName]: prev.input[measurementKey] || '' }, keyboardOpen: value }));
    keyboardRef.current?.toggleKeyBoard(value);
  };

  const handleNextClick = (data: FormData, event?: BaseSyntheticEvent<object, any, any>) => {
    event && event.preventDefault();
    onClearInput();
    const measurementsList: ItemMeasurements[] = Object.entries(data).map(([key, value]) => ({
      key,
      value,
      letter: measurementsConfig?.keyedMeasurements[key].letter || '',
      unit: measurementsConfig?.keyedMeasurements[key].unit,
    }));

    onSelect({ ...currentItem, measurementsList });
  };

  const onFieldChange = (fieldOnChange: (...params: any[]) => void, type: MeasurementConfig['type']) => {
    return type === 'number'
      ? (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let output: number | '' = parseFloat(event.target.value);
        output = isNaN(output) ? '' : output;
        updateKeyboardInputs(String(output));
        fieldOnChange(output);
      }
      : (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        updateKeyboardInputs(event.target.value);
        fieldOnChange(event.target.value);
      };
  };

  return (
    <Grid container className={classes.root} spacing={5}>
      <Box className={classes.keyBoard}>
        <MainKeyboard
          customStyles={{ 'z-index': '2000' }}
          keyboardId="measurementsConfig"
          onChange={(input: string) => handleInputChange(input)}
          ref={keyboardRef}
        />
      </Box>
      <Grid item xs={4}>
        <MeasurementImg src={measurementsConfig?.imageUrl} alt="measurement-img" />
      </Grid>
      <Grid container spacing={1} direction="row" alignItems="center" item xs={8}>
        <Grid
          container
          alignItems="center"
          item
          xs={12}
          component={'form'}
          onSubmit={formMethods.handleSubmit(handleNextClick)}
          id="measurements-form"
          style={{ gap: '4rem' }}
        >
          {measurementsConfig?.measurements.map((measurement) => (
            <Grid item xs={3} key={measurement.key}>
              <Controller
                name={measurement.key}
                control={formMethods.control}
                rules={{ required: measurement.required, minLength: 1, min: measurement.type === 'number' ? measurement.min : 1 }}
                defaultValue={getDefault(measurement, currentItem?.measurementsList)}
                key={measurement.key}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={`${capitalize(measurement.letter)} - ${startCase(measurement.key)}`}
                    onFocus={() => onToggleKeyboard(true, measurement.key)}
                    variant="outlined"
                    fullWidth
                    className={classes.input}
                    placeholder={
                      measurement.type === 'number' ? `Add size in ${measurement?.unit || 'cm'}` : 'Please add some details for the measurements'
                    }
                    inputProps={{ 'aria-label': `Measurement input for ${measurement.key}` }}
                    type={measurement.type}
                    multiline={measurement.type === 'text'}
                    onChange={onFieldChange(field.onChange, measurement.type)}
                    key={measurement.key}
                    InputProps={{
                      endAdornment: measurement.unit && <InputAdornment position="end">{measurement.unit}</InputAdornment>,
                    }}
                  />
                )}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid item container xs={12} justifyContent="center" alignContent="center" style={{ gap: '1rem' }}>
        <Grid item>
          <FormControl>
            <StyledButton
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              disabled={!formMethods.formState.isValid}
              form="measurements-form"
            >
              Next
            </StyledButton>
          </FormControl>
        </Grid>

        <Grid item>
          <StyledButton onClick={() => setOpenNotesModal(true)} color="primary" size="large" variant="outlined">
            Add Note
          </StyledButton>
        </Grid>
      </Grid>

      {openNotesModal && (
        <UserItemNotes
          onClose={() => setOpenNotesModal(false)}
          itemCode={currentItem.code}
          orderId={currentItem.orderId}
          orderAlphaId={currentItem.orderAlphaId}
        />
      )}
    </Grid>
  );
};

export default SelectMeasurementsDialog;

const MeasurementImg = styled.img`
  width: 100%;
  height: 100%;
`;
