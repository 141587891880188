import { useMutation } from 'react-query';
import { StationService } from 'services';

export enum Entities {
  ITEM_CODE = 'ITEM_CODE',
  RFID = 'RFID',
  BAG = 'BAG',
  TRAY = 'TRAY',
  PACKAGE = 'PACKAGE',
}
export type EntityTypes = keyof typeof Entities;

interface MutationVariables {
  entityId: string;
  entityType?: EntityTypes;
}

export const useValidateEntityServiceLine = () => {
  return useMutation<string, Error, MutationVariables>(({ entityId, entityType }) => {
    return StationService.validateEntityServiceLine(entityId, entityType);
  });
};
