import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import { Toaster } from 'components/toaster';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { ButtonVarients } from 'utils/enums/stationEnums';

interface ImageUploaderProps {
  onImageUploaded: (imageDataUrl: string) => void;
  buttonVarient?: ButtonVarients;
  uploadImage?: boolean;
  showButton?: boolean;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  onImageUploaded,
  buttonVarient = ButtonVarients.outlined,
  uploadImage = false,
  showButton = true,
}) => {
  const hiddenFileInput = React.useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (uploadImage) {
      hiddenFileInput && hiddenFileInput.current?.click();
    }
  }, [uploadImage]);
  const onClickHandler = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    hiddenFileInput && hiddenFileInput.current?.click();
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const filesUploaded = event && event.target?.files;
    if (filesUploaded) onFileSelected(filesUploaded);
    event.target.value = '';
  };

  const onFileSelected = (image: FileList) => {
    const toSet = image ? image[0] : undefined;
    if (toSet) {
      // Encode the file using the FileReader API
      const reader = new FileReader();
      reader.readAsDataURL(toSet);
      reader.onloadend = () => {
        onImageUploaded(reader.result as string);
      };
    } else {
      toast(<Toaster message="Something wrong happened while uploading" type="bad" />);
    }
  };

  return (
    <>
      {showButton && (
        <StyledButton color="primary" variant={buttonVarient} size="large" onClick={(e: any) => onClickHandler(e)}>
          Upload photo
        </StyledButton>
      )}
      <input name="uploadImage" ref={hiddenFileInput} onChange={onChangeHandler} type="file" accept="image/*" style={{ display: 'none' }} />
    </>
  );
};

const StyledButton = styled(Button)`
  height: 6rem;
  width: 23rem;
`;

export default ImageUploader;
