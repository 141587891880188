import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, ButtonBase } from '@material-ui/core';

import { SaItem } from 'utils/interfaces';

import { ReactComponent as LinkedIcon } from 'assets/images/svgs/specialAttentionSVG/saLinked.svg';
import { ReactComponent as NotInBagIcon } from 'assets/images/svgs/specialAttentionSVG/saNotInBag.svg';
import { ReactComponent as InBagIcon } from 'assets/images/svgs/check-green.svg';

import NoImage from 'assets/images/pngs/noImage.png';

const useStyles = makeStyles((theme) => ({
  saItemImage: {
    maxWidth: '100%',
    display: 'block',
    [theme.breakpoints.down('md')]: {
      maxHeight: '5rem',
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: '10rem',
    },
  },
  saImage: {
    position: 'relative',
  },
  saBrandShade: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    verticalAlign: 'top',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  activeImageStatus: {
    position: 'absolute',
    width: '40px',
    height: '40px',
    top: '5px',
    right: '5px',
  },
  statusIcon: {
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      width: '15px',
      height: '15px',
      top: '2px',
      right: '2px',
    },
    [theme.breakpoints.up('md')]: {
      width: '25px',
      height: '25px',
      top: '5px',
      right: '5px',
    },
  },
  centeredStatusIcon: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '15px',
      height: '15px',
    },
    [theme.breakpoints.up('md')]: {
      width: '35px',
      height: '35px',
    },
  },
}));

interface Props {
  item: SaItem;
  withStatus?: boolean;
  isActiveItem?: boolean;
  isGalleryDisplay?: boolean;
  dialogClassName?: string;
  onClickHandler?: (item: SaItem) => void;
}

const SpecialAttentionImage: React.FC<Props> = ({
  item = {} as SaItem,
  withStatus = true,
  isActiveItem = false,
  isGalleryDisplay = false,
  dialogClassName = '',
  onClickHandler = () => ({}),
}) => {
  const classes = useStyles();

  const imgRef = useRef<HTMLImageElement>(null);

  return (
    <Box className={classes.saImage} display="flex" justifyContent="center" alignItems="center">
      {/* Ripple Effect */}
      <ButtonBase focusRipple>
        {/* Special Attention Image */}
        <img
          src={item.imageUrl || ''}
          alt="Customer Special Request Item - Images"
          className={`${classes.saItemImage} ${dialogClassName}`}
          onClick={() => onClickHandler(item)}
          ref={imgRef}
          onError={() => (imgRef.current!.src = NoImage)}
        />

        {/* Special Attention Item Brand */}
        <Box className={classes.saBrandShade} style={{ fontSize: isActiveItem ? '30px' : '18px' }}>
          {item.brandName}
        </Box>

        {/* Special Attention status Icon */}
        <Box data-testid={'Special-Attention-status-Icon'} style={{ position: isGalleryDisplay ? 'absolute' : 'static' }}>
          {withStatus &&
            (item.bagCode && item.itemCode ? (
              <LinkedIcon
                className={isActiveItem ? classes.activeImageStatus : isGalleryDisplay ? classes.centeredStatusIcon : classes.statusIcon}
                onClick={() => onClickHandler(item)}
              />
            ) : item.notInBag ? (
              <NotInBagIcon
                className={isActiveItem ? classes.activeImageStatus : isGalleryDisplay ? classes.centeredStatusIcon : classes.statusIcon}
                onClick={() => onClickHandler(item)}
              />
            ) : (
              <InBagIcon
                className={isActiveItem ? classes.activeImageStatus : isGalleryDisplay ? classes.centeredStatusIcon : classes.statusIcon}
                onClick={() => onClickHandler(item)}
              />
            ))}
        </Box>
      </ButtonBase>
    </Box>
  );
};

export default SpecialAttentionImage;
