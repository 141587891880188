import React from 'react';
import UserNotesOverview from 'components/userNotes/userNotesOverview';
import { useGetUserNotes } from 'modules/stations/components/userItemNotes/queries';
import { useAddNote, useSetNoteStatus } from 'modules/stations/components/userItemNotes/mutations';
import { useNotesModal } from '../../stainManStation/hooks/useNotesModal';

interface IProps {
  itemCode: string;
  orderAlphaId: string;
  orderId: string;
  onClose: () => void;
  isDialog?: boolean;
  imageSize?: string;
  title?: string;
}

const UserItemNotes: React.FC<IProps> = ({ itemCode, orderAlphaId, orderId, onClose, isDialog = true, imageSize, title }) => {
  const { userNotes, refetch } = useGetUserNotes(itemCode, orderAlphaId);
  const { mutateAsync: addUserNote } = useAddNote();
  const { mutateAsync: setUserNoteStatus } = useSetNoteStatus();
  const { closeNotesModal } = useNotesModal('add-note');

  const defaultOnSettled = async () => await refetch();
  const handleDelete = async (noteId?: string) => {
    if (noteId) {
      await setUserNoteStatus(
        {
          noteId,
          isRemoved: true,
        },
        {
          onSettled: defaultOnSettled,
        }
      );
    }
  };

  const handleAddNote = async (description: string, photo: string) => {
    await addUserNote(
      { itemCode, orderAlphaId, orderId, description, photo },
      {
        onSuccess: () => closeNotesModal(),
        onSettled: defaultOnSettled,
      }
    );
  };

  return (
    <UserNotesOverview
      orderAlphaId={orderAlphaId}
      itemCode={itemCode}
      onAddNote={handleAddNote}
      onDeleteNote={handleDelete}
      onClose={onClose}
      userNotes={userNotes}
      isDialog={isDialog}
      imageSize={imageSize}
      title={title}
    />
  );
};

export default UserItemNotes;
