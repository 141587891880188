import { useQuery } from 'react-query';
import { StationService } from 'services';
import { IItemGroup, OrderServiceLines } from 'utils/interfaces';

export const ITEM_GROUPS_QUERY_KEY = 'item-groups';

const useGetItemGroups = (
  orderAlphaId: string,
  itemsAddedToGroup: string[],
  isFetchRecommendations: boolean = false,
  serviceLine: OrderServiceLines,
  recommendedItems?: { itemCode: string }[],
  enabled?: boolean
) => {
  const itemGroupsQuery = useQuery<IItemGroup, Error>(
    [ITEM_GROUPS_QUERY_KEY, orderAlphaId, isFetchRecommendations],
    () => StationService.fetchItemGroups(orderAlphaId, isFetchRecommendations, serviceLine),
    {
      refetchOnWindowFocus: false,
      enabled: enabled,
      select: (data) => {
        const groupColors: Record<string, string> = {};
        const groupIds: Record<string, string> = {};

        const recommendations = [...data.recommendations, recommendedItems || []].map((recommendation: any, index) => ({
          itemCodesList: recommendation.map((item: any) => item.itemCode || item.code),
          recommendationId: index,
        }));

        /**
         * Here, we're generating a color based on the index using HSL color space.
         * @param index
         * @returns
         */
        function generateColor(index: number) {
          const hue = (index * 137.508) % 360; // 137.508 is an arbitrary constant
          return `hsl(${hue},70%,50%)`;
        }

        data.groups.forEach((group, index) => {
          group.itemCodesList.forEach((itemCode) => {
            groupColors[itemCode] = generateColor(index);
            groupIds[itemCode] = group.groupId;
          });
        });

        data.items.forEach((item) => {
          const borderColor = groupColors[item.itemCode!] || '0';
          item.borderColor = borderColor;
          item.groupId = groupIds[item.itemCode!] || '';
          item.recommendationId = undefined;
          if (serviceLine === OrderServiceLines.SHOES) {
            item.frontImage = item.itemImagesMap?.top;
          }
        });

        recommendations.forEach((recommendation, index) => {
          const foundItem = recommendation.itemCodesList.find((itemCode: any) => itemsAddedToGroup.includes(itemCode!));
          if (foundItem) {
            recommendation.itemCodesList.forEach((itemCode: any) => {
              const filteredItem = data.items.filter((item) => itemCode === item.itemCode!);
              if (filteredItem.length) {
                filteredItem[0]['recommendationId'] = index;
              }
            });
          }
        });

        const filteredItems = data.items.filter((item) => !itemsAddedToGroup.includes(item.itemCode!));

        filteredItems.sort((a, b) => {
          if (a.recommendationId !== undefined && b.recommendationId === undefined) {
            return -1;
          }
          if (a.recommendationId === undefined && b.recommendationId !== undefined) {
            return 1;
          }
          const groupIdComparison = a.groupId!.localeCompare(b.groupId!);
          return groupIdComparison;
        });

        return {
          ...data,
          items: filteredItems,
          allItems: data.items,
          groupIds,
        };
      },
    }
  );

  return { ...itemGroupsQuery, itemGroups: itemGroupsQuery.data || { items: [], groups: [] } };
};

export default useGetItemGroups;
