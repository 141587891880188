import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStationStore } from 'stores';
/** Import assets */
import SpecialInstructionIcon from 'assets/images/pngs/special-instruction.png';
/** Import Component*/
import CustomDialog from './customDialog';
import { CustomerPreferences } from 'components/customerPreferences';
/** Import Material-UI */
import { Typography, Box, Theme, Grid, Button } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { IBag } from 'utils/interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerTitle: {
      padding: '1rem',
      borderBottom: '1px solid #b6bcd1',
    },
    titleIcon: {
      width: '2.5rem',
      paddingRight: '1rem',
    },
    body: {
      padding: '2rem',
      minHeight: '250px',
    },
    root: {
      padding: '1rem',
    },
    containerPadding: {
      padding: '2rem 2rem 0 2rem',
    },
  })
);

interface Props {
  onClose: () => any;
  open: boolean;
  injectedBag?: IBag;
}

const SpecialInstructionModal: React.FC<Props> = observer(({ onClose, open, injectedBag }) => {
  /** Import Styles */
  const classes = useStyles();
  /** Import bag from store */
  const { bag: storeBag, focusInput$ } = useStationStore();

  const bag = injectedBag ?? storeBag;
  const { preferences } = bag;

  const handleClose = () => {
    onClose();
    focusInput$.set(true);
  };

  return (
    <CustomDialog open={open} onClose={handleClose} size={!bag.customerInstruction && !bag.driverInstruction ? 'sm' : 'md'}>
      <Grid container direction="column" alignItems="center" classes={{ container: classes.root }}>
        {/* Title and Icon */}
        <Grid container item direction="row" justify="center" alignItems="center" classes={{ item: classes.headerTitle }}>
          <Box>
            <img src={SpecialInstructionIcon} alt="warning" className={classes.titleIcon} />
          </Box>
          <Typography variant="h4" display="initial" align="center">
            Special Instructions
          </Typography>
        </Grid>
        {/* Display Instructions */}
        {(bag.customerInstruction || bag.driverInstruction) && (
          <Grid container direction="column" alignItems="flex-start" justify="center" classes={{ container: classes.containerPadding }}>
            <Grid item xs={12}>
              <ul>
                {bag.customerInstruction && (
                  <li>
                    <Typography variant="h5">{bag.customerInstruction}</Typography>
                  </li>
                )}
                {bag.driverInstruction && (
                  <li>
                    <Typography variant="h5">{bag.driverInstruction}</Typography>
                  </li>
                )}
              </ul>
            </Grid>
          </Grid>
        )}
        {/* Display Preferences */}
        <Grid container direction="column" alignItems="center" justify="center" classes={{ container: classes.body }}>
          <Grid item xs={12} style={{ width: '100%' }}>
            {preferences && <CustomerPreferences customerPref={preferences} displayRow={Boolean(bag.driverInstruction || bag.customerInstruction)} />}
          </Grid>
        </Grid>
        {/* Continue Button */}
        <Grid item xs={5}>
          <Button color="primary" variant="contained" size="large" onClick={handleClose}>
            Continue
          </Button>
        </Grid>
      </Grid>
    </CustomDialog>
  );
});

export default SpecialInstructionModal;
