import React from 'react';

import styled from 'styled-components';
import { Button, ButtonBase, Box } from '@material-ui/core';

import { DelayInfo } from 'utils/interfaces';

import { ReactComponent as DelayIcon } from 'assets/images/svgs/delay.svg';
import { ReactComponent as EditDelayIcon } from 'assets/images/svgs/edit-delay.svg';

interface IProps {
  delayDetails?: DelayInfo;
  itemsCount?: number;
  twoButtonOption?: boolean;
  onCloseModal?: () => void;
  updateDelayDetails?: (delayDetails: DelayInfo) => void;
  answerDelaysWidth?: string;
  handleDelayAnswer: (isDelayed: boolean) => void;
  toggleResetDelay: (resetDelayAnswer: boolean) => void;
  resetDelay: boolean;
  delayButtonText?: string;
  delayButtonSize?: 'small' | 'medium' | 'large';
}

const AnswerDelayComponent: React.FC<IProps> = ({
  delayDetails,
  itemsCount,
  twoButtonOption = true,
  handleDelayAnswer,
  answerDelaysWidth = '',
  toggleResetDelay,
  resetDelay,
  delayButtonText,
  delayButtonSize = 'large',
}) => {
  return (
    <div style={{ width: '100%' }}>
      {!resetDelay && delayDetails && delayDetails.isDelayed ? (
        <Box display="flex" width={'100%'}>
          <Box display="flex" alignItems="center">
            <DelayIcon width="2rem" height="2rem" color="#f5a623" />
            <DelayTime> + {delayDetails.delayDays} days</DelayTime>
          </Box>
          <ButtonBase>
            <EditDelayIcon width="1.5rem" height="1.5rem" onClick={() => toggleResetDelay(true)} />
          </ButtonBase>
        </Box>
      ) : !resetDelay && delayDetails && delayDetails.isDelayAnswered ? (
        <Box display="flex" alignItems="center" width={'100%'}>
          <NoDelay>No Delay</NoDelay>
          <ButtonBase>
            <EditDelayIcon width="1.5rem" height="1.5rem" onClick={() => toggleResetDelay(true)} />
          </ButtonBase>
        </Box>
      ) : twoButtonOption ? (
        <>
          <DelayButton
            width={answerDelaysWidth}
            custom_color="#f5a623"
            border_color="#f5a623"
            margin_right="0.3rem"
            onClick={() => handleDelayAnswer(true)}
            variant="outlined"
          >
            <span>Yes</span>
          </DelayButton>
          <DelayButton
            width={answerDelaysWidth}
            custom_color="#3e48a6"
            border_color="#3e48a6"
            variant="outlined"
            onClick={() => handleDelayAnswer(false)}
          >
            <span>No</span>
          </DelayButton>
        </>
      ) : (
        <>
          <DelayButton
            startIcon={<DelayIcon />}
            custom_color="#f5a623"
            border_color="#f5a623"
            onClick={() => handleDelayAnswer(true)}
            variant="outlined"
            size={delayButtonSize}
          >
            {!delayButtonText ? <span> Delay Item{(itemsCount && itemsCount > 1) ?? 's'}</span> : <span>{delayButtonText}</span>}
          </DelayButton>
        </>
      )}
    </div>
  );
};
export default AnswerDelayComponent;

const DelayButton = styled(Button)<{ custom_color: string; border_color: string; margin_right?: string; width?: string }>`
  ${({ custom_color }) => `color : ${custom_color};`}
  ${({ border_color }) => `border-color : ${border_color};`}
  ${({ margin_right }) => margin_right && `margin-right : ${margin_right};`}
  ${({ width }) => width && `width : ${width};`}
`;

const DelayTime = styled.span`
  color: #f5a623;
  padding-left: 0.2rem;
`;

const NoDelay = styled.span`
  color: #3e48a6;
`;
