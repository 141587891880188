export const TypeValueMapper: { [key: string]: string } = {
  CHAIN_DAMAGE: 'Chain Damage',
  COLOR_BLEEDING: 'Color Bleeding',
  FADING_COLOR: 'Fading Color',
  IRON_MARK: 'Iron Mark',
  LINT: 'Lint',
  LOOSE_THREADS: 'Loose Threads',
  MISSING_BEADS: 'Missing Beads',
  MISSING_BUTTON: 'Missing Button',
  PLEATS_REMOVED: 'Pleats Removed',
  PRINT_DAMAGE: 'Print Damage',
  PRINT_FADING: 'Print Fading',
  RIP: 'Rip',
  SMALL_HOLES: 'Small Holes',
  ZIPPER_DAMAGE: 'Zipper Damage',
  HARD_STAIN: 'Hard Stain',
  OIL_STAIN: 'Oil Stain',
  RUST_STAIN: 'Rust Stain',
  SWEAT_STAIN: 'Sweat Stain',
  FOOD_STAIN: 'Food Stain',
  DIRT_STAIN: 'Dirt Stain',
  BROKEN_BUTTONS: 'Broken Buttons',
  OTHER: 'Other',
};
