import { useMutation } from 'react-query';
import stationService from 'services/station.service';

interface MarkAsSortedOptions {
  rfid: string;
}
const useMarkItemAsSorted = () => {
  return useMutation<any, Error, MarkAsSortedOptions>(({ rfid }) => stationService.markItemAsSorted(rfid));
};

export default useMarkItemAsSorted;
