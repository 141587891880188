import { configure } from 'mobx';
import { AuthStore } from './authStore';
import { StationStore } from './stationStore';

configure({
  computedRequiresReaction: true,
  enforceActions: 'observed',
});

export const stores = {
  AuthStore,
  StationStore,
};

export { useAuthStore } from './authStore';
export { authStore as AuthStore } from './authStore';
export { useStationStore } from './stationStore';
export { stationStore } from './stationStore';
export { useUiState, uiState } from './uiState';
export { usePackagingStore } from './packagingStore';

export { useSorterStore } from './sorterStore';
export * from './scanner-store'