import React from 'react';
import { CustomDialog } from 'components/dialogs';
import { ImageDetail } from 'components/imageStepper/ImageStepper';
import { ImagePopup } from 'components/imagePopup';
import styled from 'styled-components';
import { Box, Button, Grid, Icon } from '@material-ui/core';

interface Props {
  open: boolean;
  onClose: () => void;
  imageList: ImageDetail[];
}

const ImagesDialog: React.FC<Props> = ({ imageList, open, onClose }) => {

  return (
    <CustomDialog titleStyles={titleStyles} size="xl" title="Images" open={open}
                  onClose={onClose}>
      <Grid direction="row" xs={12} container justifyContent={'center'} spacing={10}>
        {
          imageList.map((image, index) => (
            <Grid item xs={4} style={{ width: 'fit-content' }} key={index}>
              <ImagePopup
                imageUrl={image.image}
                index={index}
                imageWidth="28rem"
                customComponents={imageIcons(image.logo)}
              />
            </Grid>
          ))
        }
      </Grid>
      <Box display={'flex'} flex={'0.4'} justifyContent="center" alignItems={'center'} padding="2rem 0">
        <SubmitButton variant="contained" color="primary" onClick={onClose}>
          Confirm
        </SubmitButton>
      </Box>
    </CustomDialog>
  );
};
const imageIcons = (logo: JSX.Element) => {
  return (
    <>
      <StyledIcon>{logo}</StyledIcon>
    </>
  );
};
export default ImagesDialog;
const StyledIcon = styled(Icon)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;

  svg {
    width: 3rem;
    height: 3rem;
  }
`;
const titleStyles = `
  margin : 2rem 0;

  .MuiIconButton-root{
    svg { 
      width : 3rem;
      height : 3rem;
    }
  }
`;

const SubmitButton = styled(Button)`
  height: 5.2rem;
  width: 18rem;
  padding: 1rem 3rem;
  font-size: 1.5rem;
`;
