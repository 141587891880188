import React from 'react';

import { ReactComponent as ShoeTrayIcon } from 'assets/images/svgs/shoeSorterIcons/tray.svg';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';

interface Props {
  trayCode?: number;
}
const ShoeTray: React.FC<Props> = ({ trayCode }) => {
  return (
    <Grid container wrap="nowrap" direction="column" alignItems="center" justifyContent="center">
      <Grid item>
        <ShoeTrayIcon />
      </Grid>
      <TrayCode item>{trayCode}</TrayCode>
    </Grid>
  );
};

export default ShoeTray;

const TrayCode = styled(Grid)`
  word-break: break-word;
`;
