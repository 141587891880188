import { useMemo } from 'react';
import { useQueryClient } from 'react-query';

import { IOrderWithItems, IPendingItem } from 'utils/interfaces';
import { fetchedFilters } from './useGetOrdersWithItemsList';

export function useGetSelectedItem(itemId: string, orderAlphaId: string, queryKey: string) {
  const ordersItemsList = useQueryClient()
    .getQueryCache()
    .find<{ ordersItemsList: IOrderWithItems[]; baseFilters: fetchedFilters }>([queryKey], { exact: false, active: true })?.state
    .data?.ordersItemsList;

  const currentOrder = useMemo<IOrderWithItems | undefined>(
    () => ordersItemsList?.find((order) => order.orderAlphaId === orderAlphaId),
    [ordersItemsList, orderAlphaId]
  );

  const currentItem = useMemo<IPendingItem | undefined>(
    () => currentOrder && currentOrder.itemsList.find((item) => item.code === itemId),
    [itemId, currentOrder]
  );

  return { currentItem, currentOrder };
}
