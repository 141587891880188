import React, { PropsWithChildren, useMemo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide, { SlideProps } from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { makeStyles, Theme, createStyles, Box } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import lodash from 'lodash';
import styled from 'styled-components';

import { IBag, IOrderItem, OrderServiceLines } from 'utils/interfaces';
import { ComponentProps, Components, ItemKey, Schema, useGetSelectedItem } from 'modules/stations/components/SortingStepper/SortingStepper';
import { WashmenIcon } from 'components/washmenIcon';

import { ReactComponent as LaundryTypeIcon } from 'assets/images/svgs/stepperIcons/svg/type-border.svg';
import { ReactComponent as LaundryBrandIcon } from 'assets/images/svgs/stepperIcons/svg/brand-border.svg';
import { ReactComponent as LaundryColorIcon } from 'assets/images/svgs/stepperIcons/svg/color-border.svg';
import { ReactComponent as LaundryPhotoIcon } from 'assets/images/svgs/stepperIcons/svg/photo-border.svg';
import { ReactComponent as LaundryStainDamageIcon } from 'assets/images/svgs/stepperIcons/svg/stainDamage-border.svg';

/** Import Icon's SVG */
import { ReactComponent as ShoeBrandIcon } from 'assets/images/svgs/sorterModal/brand-black.svg';
import { ReactComponent as ShoeColorIcon } from 'assets/images/svgs/sorterModal/colour-black.svg';
import { ReactComponent as ShoePhotoIcon } from 'assets/images/svgs/sorterModal/photo-black.svg';
import { ReactComponent as ShoeSizeIcon } from 'assets/images/svgs/stepperIcons/svg/shoe-size.svg';
import { ReactComponent as ShoeSideIcon } from 'assets/images/svgs/stepperIcons/svg/shoe-side.svg';
import { ReactComponent as ShoeTypeIcon } from 'assets/images/svgs/stepperIcons/svg/shoe-type.svg';
import { ReactComponent as ShoeStainIcon } from 'assets/images/svgs/stepperIcons/svg/shoe-stain-damage.svg';

import { ReactComponent as FineryMeasurementsIcon } from 'assets/images/svgs/stepperIcons/svg/measurements.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      overflow: 'hidden',
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    header: {
      display: 'flex',
      borderBottom: 'solid 2px #b6bcd1',
      padding: '2rem',
      alignItems: 'center',
    },

    icon: {
      width: '40px',
      height: '40px',
      marginRight: '20px',
    },
    vipLogo: {
      height: 'auto',
      width: '4vw',
    },
    titleIcon: {
      width: '2rem',
      paddingRight: '1rem',
    },
  })
);

interface IHeaderIconMapper {
  [key: string]: JSX.Element;
}

const headerIcons: { [key in OrderServiceLines]: IHeaderIconMapper } = {
  [OrderServiceLines.FINERY]: {
    type: <LaundryTypeIcon />,
    brand: <LaundryBrandIcon />,
    color: <LaundryColorIcon />,
    measurements: <FineryMeasurementsIcon />,
    photo: <LaundryPhotoIcon />,
    stainDamage: <LaundryStainDamageIcon />,
  },
  [OrderServiceLines.LAUNDRY]: {
    //Not used ATM
  },
  [OrderServiceLines.SHOES]: {
    type: <ShoeTypeIcon />,
    brand: <ShoeBrandIcon />,
    color: <ShoeColorIcon />,
    photo: <ShoePhotoIcon />,
    stainDamage: <ShoeStainIcon />,
    side: <ShoeSideIcon />,
    size: <ShoeSizeIcon />,
  },
};
interface routeProps extends Record<string, string> {
  bagCode: string;
  stepType: string;
  qrCode: string;
  rfid: string;
}

interface Props<K extends ItemKey> {
  open: boolean;
  autoSave?: boolean;
  noManagerRequired?: boolean;
  components: Components;
  schema: Schema<K>[];
  HeaderComponents?: React.FC<{ style?: React.CSSProperties }>;
  bag: IBag;
  serviceLine: OrderServiceLines;
  onClose: () => void;
  updateCurrentItemDetails: (item: IOrderItem) => void;
}

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...(props as SlideProps)} />;
});

const getCurrentStepSchema = <K extends ItemKey>(stepType: routeProps['stepType'] | undefined, schema: Schema<K>[]) => {
  let keyIndex = 0;
  const attributeKey = schema.find(({ type }, index) => {
    if (type === stepType) keyIndex = index;
    return type === stepType;
  });

  return { keyIndex, attributeKey };
};

const SorterDialog = <K extends ItemKey>({
  open,
  components,
  schema,
  HeaderComponents,
  bag,
  serviceLine,
  onClose,
  updateCurrentItemDetails,
}: PropsWithChildren<Props<K>>) => {
  const params = useParams<routeProps>();

  const navigate = useNavigate();
  const classes = useStyles({});
  const { selectedItem: currentItem } = useGetSelectedItem();

  const currentStepSchema = useMemo(() => getCurrentStepSchema(params.stepType, schema), [params.stepType, schema]);

  // Manipulate SVG with the isActive Flag
  let IconElement: JSX.Element = <></>;
  if (params.stepType) {
    IconElement = headerIcons?.[serviceLine]?.[params.stepType] || <></>;
    IconElement = React.cloneElement(IconElement, {
      className: `${classes.icon} ${params.stepType}-class`,
    });
  }

  const onCloseHandler = () => onClose();

  const onSelect: ComponentProps['onSelect'] = (item, options = { disableRouting: false, forceRoute: false, skipStep: false }) => {
    updateCurrentItemDetails({ ...currentItem, ...item });

    const { attributeKey, keyIndex } = currentStepSchema;
    const next = schema[keyIndex + 1];

    // Skip routing if :  Step isn't even found in schema || We manually disabled routing || There is no next step to route to
    const { disableRouting, forceRoute, skipStep, urlState } = options;
    if (!attributeKey || disableRouting === true || !next) return;

    const currentPropertyForNextStep = currentItem[next.itemKey];
    if ((lodash.isEmpty(currentPropertyForNextStep) || forceRoute) && !skipStep) {
      routeTo(next.type, urlState);
    } else {
      navigate('../');
    }
  };

  /**
   * Route to the Brands Modal View
   */
  function routeTo(to: routeProps['stepType'], urlState?: any) {
    navigate(`../(item/${to})`, { state: urlState });
  }
  return (
    <div>
      <Dialog
        classes={{ paper: classes.dialog }}
        fullScreen
        open={open}
        onClose={onCloseHandler}
        TransitionComponent={Transition}
        disableRestoreFocus={true}
        scroll="paper"
      >
        <StyledHeaderBox className={classes.header}>
          {IconElement}
          <Box>
            <Typography variant="h4">{currentStepSchema.attributeKey?.label}</Typography>
          </Box>

          {HeaderComponents && <HeaderComponents style={{ flexBasis: '100%' }} />}
          <Box flexGrow={1} justifyContent="flex-end" display="flex">
            {bag.isCustomerVip ? <WashmenIcon name="vipIcon" svg iconClassName={classes.vipLogo} isButton={false} /> : ''}
            <IconButton edge="start" color="inherit" onClick={onCloseHandler} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Box>
        </StyledHeaderBox>
        {!!params.stepType && components[params.stepType]({ onSelect })}
      </Dialog>
    </div>
  );
};

export default SorterDialog;

export const StyledHeaderBox = styled(Box)`
  .side-class,
  .type-class,
  .size-class,
  .stainDamage-class {
    circle,
    g {
      stroke: black;
    }
    g,
    #Shape-Copy-3,
    #Combined-Shape {
      fill: black;
    }
  }
`;
