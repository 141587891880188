// Add Station Enums here

export enum StationTypes {
  Unselected = '',
  Tagger = 'tagger',
  Sorter = 'sorter',
  QA = 'QA',
  ShirtPressing = 'shirt-pressing',
  MultiPressing = 'multi-pressing',
  PantPressing = 'pant-pressing',
  UtilityPressing = 'utility-pressing',
  CollarAndCuffPressing = 'collar-cuff-pressing',
  PantsTopper = 'pants-topper',
  Spotting = 'Spotting',
  Washer = 'washer',
  FlatIroner = 'flat-ironer',
  HandWash = 'hand-wash',
  Scanner = 'scanner',
  Inspector = 'Inspector',
  BannedPackaging = 'banned-packaging',
  Folding = 'folding',
  FoldingLinen = 'folding-linen',
  Grouping = 'grouping',
  Racking = 'racking',
  PendingItems = 'pending-items',
  QualityControl = 'quality-control',
  Spotter = 'spotter',
  PreSpotter = 'pre-spotter',
  PostSpotter = 'post-spotter',
  Tailor = 'tailor',
  StainMan = 'stain-man',
  ShoesTagger = 'shoes-tagger',
  ShoesSorter = 'shoes-sorter',
  ShoesFolding = 'shoes-folding',
  ShoesBeforeAfter = 'shoes-before-after',
  ShoesPendingItems = 'shoes-pending-items',
  ShoesStainMan = 'shoes-stain-man',
  ShoesSorterAssessment = 'shoes-sorter-assessment',
  CPDispatch = 'cp-dispatch',
  WFDispatch = 'wf-dispatch',
  ShoesDispatch = 'shoes-dispatch',
  FineryTagger = 'finery-tagger',
  FinerySorter = 'finery-sorter',
  FineryGrouping = 'finery-grouping',
  FineryTailorPost = 'finery-tailor-post',
  FineryTailorPre = 'finery-tailor-pre',
  FinerySpotter = 'finery-spotter',
  FineryPressing = 'finery-pressing',
  FineryBannedPackaging = 'finery-banned-packaging',
  FineryFolding = 'finery-folding',
  FineryDrying = 'finery-drying',
  FineryCleaning = 'finery-cleaning',
  FineryStainMan = 'finery-stain-man',
  WFSorter = 'wf-sorter',
  WfTagger = 'wf-tagger',
  WfWasher = 'wf-washer',
  Receiving = 'receiving',
  WfReceiving = 'wf-receiving',
  FineryReceiving = 'finery-receiving',
  ShoesReceiving = 'shoes-receiving',
}

export enum Locales {
  English = 'en',
  Arabic = 'ar',
  Chinese = 'zh-Hans',
}

export enum ScanType {
  RFID = 'rfid',
  QRCODE = 'qrCode',
  BARCODE = 'barcode',
  MULTI = 'multi',
  ORDER = 'order',
  PACKAGE = 'package',
  TRAY = 'tray',
  DROPOFF_BAG = 'dropoffBag',
}

export enum BagTypes {
  CLEAN_PRESS = 'CP',
  WASH_FOLD = 'WF',
  PRESS = 'P',
  HOME_CARE = 'HC',
  SHOE_CLEANING = 'SC',
  FINERY = 'TF',
}

export enum SorterSteps {
  type = 'type',
  brand = 'brand',
  programs = 'programs',
  color = 'color',
  photo = 'photo',
  stainDamage = 'stainDamage',
  side = 'side',
  size = 'size',
  measurements = 'measurements',
}

//FIXME: Keep only one of these both, since both are now same.
export enum ImageSteps {
  top = 'top',
  bottom = 'bottom',
  backFront = 'backFront',
  inside = 'inside',
  outside = 'outside',
  customer = 'customer',
}

export enum FineryImages {
  careLabel = 'careLabel',
  front = 'front',
  back = 'back',
  leftSide = 'leftSide',
  rightSide = 'rightSide',
  extra = 'extra',
}

export enum ImageOptions {
  before = 'before',
  after = 'after',
}

export enum InputFieldTypes {
  tel = 'tel',
  text = 'text',
}

export enum ButtonVarients {
  contained = 'contained',
  outlined = 'outlined',
}

export enum SaDialogTypes {
  linkToItem = 'linkToItem',
  unlinkItem = 'unlinkItem',
  linkToBag = 'linkToBag',
  viewOnly = 'viewOnly',
  sorterViewOnly = 'sorterViewOnly',
  warning = 'warning',
}

export enum FoldingType {
  single = 'SINGLE',
  multiple = 'MULTIPLE',
  transit = 'TRANZIT',
  banned = 'BANNED',
  mp = 'MP',
}

export enum ItemPackagingStatus {
  pending = 'Pending',
  processingDelayed = 'Processing Delayed',
  folded = 'Folded',
  packaged = 'Packaged',
}

export enum OrderStatuses {
  ORDER_COMPLETE = '6',
  PICKUP_COMPLETED = '3',
  DROPOFF_ASSIGNED = '4',
  DROPOFF_IN_PROGRESS = '5',
}

export enum PackageStatuses {
  NOT_COLLECTED = 0,
  NOT_READY = 1,
  NOT_FOUND = 2,
  COLLECTED = 3,
  COLLECTED_BY_TRUCK = 3.1,
}

export enum UserPermissions {
  DELAY_PACKAGE_STATUS = 'DELAY_PACKAGE_STATUS',
}

export enum BAG_STATUSES {
  RECEIVED = 'RECEIVED',
  NOT_RECEIVED = 'NOT_RECEIVED',
  MISSING = 'MISSING',
  SCANNED = 'SCANNED',
  TAGGED = 'TAGGED',
  SORTED = 'SORTED',
}

export enum PACKAGE_RECEIVING_STATUSES {
  RECEIVED = 'RECEIVED',
  NOT_RECEIVED = 'NOT_RECEIVED',
  MISSING = 'MISSING',
}
