import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { BrandDisplay } from 'components/brandDisplay';
import { ColorMapper } from 'components/colorMapper';
import { Brand, ColorsList } from 'utils/interfaces';
import { itemTypeIconMap } from 'utils/maps';

import NoImage from 'assets/images/pngs/noImage.png';
import { GroupItem, RoundButton } from './marryingModal';

interface Props {
  type: string;
  brand: Brand;
  colorsList: ColorsList[];
  frontImage: string;
  itemCode: string;
  borderColor: string;
  groupId?: string;
  recommendationId?: number;
  addItemToGroup: ({ itemCode, frontImage, groupId }: GroupItem) => void;
}

const LeftPanelListItem: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ type, brand, colorsList, frontImage, itemCode, borderColor, addItemToGroup, groupId = '', recommendationId = -1 }) => {
    return (
      <CustomListItemGrid
        container
        xs={12}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        borderColor={borderColor}
        recommendationId={recommendationId}
      >
        <Grid item xs={'auto'}>
          <ItemTypes src={type ? itemTypeIconMap[type] : NoImage} alt="item type" />
        </Grid>
        <Grid item xs={'auto'}>
          <BrandDisplay isTextHidden brand={brand} />
        </Grid>
        <Grid item xs={4}>
          <ColorMapper colorsList={colorsList} dimension="3rem" textHidden />
        </Grid>
        <Grid item xs={'auto'}>
          <Photo src={frontImage ? frontImage : NoImage} width="120px" height="160px" alt="Washmen" />
        </Grid>
        <Grid item xs={1}>
          <RoundButton color="primary" size="large" variant="contained" onClick={() => addItemToGroup({ itemCode, frontImage, groupId })}>
            <AddIcon />
          </RoundButton>
        </Grid>
      </CustomListItemGrid>
    );
  }
);

export default LeftPanelListItem;

const CustomListItemGrid = styled(Grid)<{ borderColor: string; recommendationId: number }>`
  border: 0.1rem solid #b6bcd1;
  ${(props) => props.borderColor !== '0' && 'border-left: 10px solid ' + props.borderColor};
  padding: 1rem 3rem 0.2rem 1rem;
  border-radius: 6px;
  ${(props) => props.recommendationId !== -1 && 'background-color: #00b8ad14;'};
`;

const ItemTypes = styled.img`
  max-height: 80px;
  max-width: 80px;
`;

const Photo = styled.img`
  object-fit: 'cover';
  max-width: 70px;
  max-height: 90px;
`;
