import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { useStationStore } from 'stores';
import { Grid, Theme, InputBase, Box, ButtonBase, Typography, Button } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import stationService from 'services/station.service';
import { IBrandsResponse } from 'utils/interfaces';
import NoLogoIcon from 'assets/images/pngs/noLogo.png';
import { ConfirmDialog } from 'components/confirmDialog';
import CareLabelImage from 'assets/images/svgs/delicateitem-b.svg';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { MainKeyboard } from 'components/mainKeyboard';
import { MainKeyboardRefProps } from 'components/mainKeyboard/mainKeyboard';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2rem',
    },
    formContainer: {
      padding: '0rem 1rem',
    },
    paper: {
      padding: '10px 4px',
      display: 'flex',
      alignItems: 'stretch',
    },
    input: {
      marginLeft: 8,
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    brandsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    brandItemContainer: {
      display: 'flex',
      alignSelf: 'stretch',
      justifyContent: 'center',
    },
    brandButton: {
      padding: '1rem',
      border: '1px solid #b6bcd1',
      width: '100%',
      flexDirection: 'column',
    },
    keyBoard: {
      width: '100%',
      position: 'absolute',
      bottom: '0',
      left: '0',
      right: '0',
    },
    noLogoPadding: {
      paddingBottom: '0rem',
    },
    brandImageLogo: {
      maxHeight: '8rem',
      height: '2.5rem',
      width: 'auto',
      objectFit: 'contain',
    },
    btnContentContainer: {
      flex: '1',
    },
  })
);

const BrandItem: React.FC<{ brand: IBrandsResponse; onBrandSelect: (brand: IBrandsResponse) => void }> = ({ brand, onBrandSelect }) => {
  const classes = useStyles();
  const imgRef = useRef<HTMLImageElement>(null);
  return (
    <Grid container item className={classes.brandItemContainer} xs={2}>
      <ButtonBase focusRipple className={classes.brandButton} onClick={() => onBrandSelect(brand)}>
        {brand.logo && (brand.logo !== 'NO_LOGO' || brand.logo === undefined) ? (
          <Grid
            container
            item
            classes={{ item: classes.btnContentContainer }}
            alignItems="stretch"
            justify="space-between"
            direction="column"
            xs={12}
          >
            <img className={`${classes.brandImageLogo} img-responsive`} src={`${process.env.REACT_APP_BRAND_API_HOST}/${brand.logo}`} alt="" />
            <Typography variant="body2">{brand.name}</Typography>
          </Grid>
        ) : (
          <Box>
            <img width="128px" src={NoLogoIcon} alt="" className={classes.brandImageLogo} onError={() => (imgRef.current!.src = NoLogoIcon)} />
            <Typography variant="body2">{brand.name}</Typography>
          </Box>
        )}
      </ButtonBase>
    </Grid>
  );
};

const SelectBrandComponent: React.FC = observer(() => {
  // Import Style
  const classes = useStyles();

  const navigate = useNavigate();
  const keyboardRef = useRef<MainKeyboardRefProps>(null);
  const { currentItem, updateCurrentItemDetails, bag, latestBrandSelected, setLatestBrandSelected } = useStationStore();
  // Declare State
  const [input, setInput] = useState('');
  const [brands, setBrands] = useState<{
    allBrands: IBrandsResponse[];
    topBrands: IBrandsResponse[];
  }>({ allBrands: [], topBrands: [] });
  const [filteredBrands, setFilteredBrands] = useState<IBrandsResponse[]>([]);
  const [isDelicateDialogOpen, setIsDelicateDialogOpen] = useState(false);

  // fetch all brands and top brands on component mount
  useEffect(() => {
    const customerId = process.env.NODE_ENV === 'development' ? '000dfd04-755c-4c1e-b378-62c0edb2dc81' : bag.customerId || '';
    const fetchBrands = async () => {
      // fetch customer top brands first
      const topBrands = await stationService.fetchCustomerTopBrands(customerId, currentItem.type!);
      setBrands((prev) => {
        const brands = latestBrandSelected !== undefined ? [latestBrandSelected, ...topBrands] : [...topBrands];
        return {
          ...prev,
          topBrands: brands.filter((brand, index) => {
            return brands.findIndex((b) => b.name === brand.name) === index;
          }),
        };
      });

      // fetch all brands
      const allBrands = await stationService.fetchAllBrands();
      setBrands((prev) => {
        return {
          ...prev,
          allBrands: allBrands,
        };
      });
    };
    fetchBrands();
  }, [bag.customerId, latestBrandSelected, currentItem.type]);

  // Refresh the filteredBrands Array
  useEffect(() => {
    if (input !== '') {
      // Filter the brands using the input
      const filtering = brands.allBrands.filter((brand) => brand.name.toLowerCase().includes(input.toLowerCase())).slice(0, 10);
      // Create the custom brand with the name to match the input
      const customBrand: IBrandsResponse = {
        logo: 'NO_LOGO',
        name: input.toLowerCase().trim().replace(' ', '_'),
        active: false,
        sort_key: 10,
        luxury: false,
        Delicate: false,
      };

      // Set the filteredBrands with the custom Brand
      setFilteredBrands([...filtering, customBrand]);
    }
  }, [input, brands.allBrands]);

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInput(value);
    if (keyboardRef.current) {
      keyboardRef.current.setInput(value);
    }
  };

  const handleBrandClick = (brand: IBrandsResponse) => {
    setLatestBrandSelected(brand);
    updateCurrentItemDetails({
      ...currentItem,
      brand: {
        label: brand.name,
        logo: brand.logo || 'NO_LOGO',
        name: brand.name,
        luxury: brand.luxury,
        Delicate: brand.Delicate || false,
      },
    });

    if (brand.Delicate) {
      setIsDelicateDialogOpen(true);
    } else {
      // Handle Routing
      routeToNextPage();
    }
  };

  //Removed for now, pending change after new sorter flow is ready
  // const onNoLabeFound = () => {
  //   // Handle routing
  //   routeToNextPage();
  // };

  const onChange = (input: string) => {
    setInput(input);
  };

  const toggleCareLabelDialog = () => {
    setIsDelicateDialogOpen(false);
    // TODO: maybe only update item after adding a carelabel ??
    if (currentItem.careLabelImageUrl) {
      // redirect
      if (currentItem.colorsList && currentItem.colorsList.length > 0) {
        navigate('../', { replace: true });
      } else {
        navigate('../(item/brand)', { replace: true });
      }
    } else {
      navigate(`careLabel`, { replace: true, state: { brand: toJS(currentItem.brand), isCareLabel: true } });
    }
  };
  /**
   * Uncomment to add NO BRAND option
   *  const onNoBrandClick = () => {
    updateCurrentItemDetails({
      ...currentItem,
      brand: {
        label: 'No Brand',
        logo: 'NO_LOGO',
        name: 'none',
        luxury: false,
        Delicate: false,
      },
    });

    // Route to the next step
    routeToNextPage();
  };*/

  const routeToNextPage = () => {
    // Route back to the stepper If the garment is already sorted and contains a colorList
    if (currentItem.colorsList && currentItem.colorsList.length > 0) {
      navigate(-1);
    } else {
      navigate('../../(item/color)');
    }
  };

  return (
    <Grid container className={classes.root} spacing={5}>
      <Box className={classes.keyBoard}>
        <MainKeyboard onChange={(input: string) => onChange(input)} ref={keyboardRef} />
      </Box>
      <ConfirmDialog
        image={CareLabelImage}
        onClose={toggleCareLabelDialog}
        open={isDelicateDialogOpen}
        title="Delicate Item"
        subTitle="pay special care to the care label"
      >
        <Box padding="1rem">
          <Button onClick={toggleCareLabelDialog} color="primary" variant="contained" size="large">
            take photo of label
          </Button>
        </Box>
        {/*
         // Removed for now, pending change after new sorter flow is ready
        <Box padding="1rem">
          <Button onClick={onNoLabeFound} color="secondary" variant="contained" size="large">
            No Label
          </Button>
        </Box> */}
      </ConfirmDialog>
      <Grid item xs={4} className={classes.formContainer}>
        <form onSubmit={(event) => event.preventDefault()}>
          <Paper className={classes.paper}>
            <InputBase
              onFocus={() => {
                if (keyboardRef.current) keyboardRef.current.toggleKeyBoard(true);
              }}
              className={classes.input}
              placeholder="Search Brands"
              inputProps={{ 'aria-label': 'search brands' }}
              value={input}
              onChange={onChangeInput}
              autoFocus
            />
            <IconButton className={classes.iconButton} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </form>
        <Box margin="1rem 0rem" />
        {/*  Uncomment to add NO BRAND option
         <Button size="large" variant="contained" color="secondary" onClick={onNoBrandClick}>
          no brand
        </Button> */}
      </Grid>
      <Grid item xs={8}>
        <Grid container className={classes.brandsContainer} spacing={1} direction="row" alignItems="center">
          {input
            ? filteredBrands.map((brand) => <BrandItem onBrandSelect={handleBrandClick} key={brand.name} brand={brand} />)
            : brands.topBrands.map((brand) => <BrandItem onBrandSelect={handleBrandClick} key={brand.name} brand={brand} />)}
        </Grid>
      </Grid>
    </Grid>
  );
});

export default SelectBrandComponent;
