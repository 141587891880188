import { createTheme, responsiveFontSizes } from '@material-ui/core';
import { blue, red } from '../colors';

let theme = createTheme({
  typography: {
    fontFamily: ['Ubuntu', 'Roboto', 'sans-serif'].join(','),
    h1: {
      fontSize: '3rem',
      fontWeight: 700,
    },
  },
  palette: {
    primary: blue,
    secondary: red,
  },
  overrides: {
    MuiButton: {
      sizeLarge: {
        padding: '1.5rem 5rem',
        fontSize: '1.2rem',
      },
      outlinedPrimary: {
        color: blue[500],
        fontWeight:400,
        borderColor: blue[500],
        '&:hover': {
          backgroundColor: blue['700'],
          color: '#fff',
        },
      },
      containedSecondary: {
        color: '#fff',
        fontWeight: 400,
        backgroundColor: red[500],
        '&:hover': {
          backgroundColor: red['700'],
        },
      },
      outlinedSecondary: {
        color: red[500],
        fontWeight: 400,
        borderColor: red[500],
        '&:hover': {
          backgroundColor: red['700'],
          color: '#fff',
        },
      },
    },
    MuiFab: {
      secondary: {
        color: '#fff',
        fontWeight: 400,
        backgroundColor: red[500],
        '&:hover': {
          backgroundColor: red['700'],
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
