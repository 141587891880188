import React, { PropsWithChildren } from 'react';
import { ControllerRenderProps } from 'react-hook-form';

import DateRangeSelector from './date/dateRangeSelector';
import DateSelector from './date/dateSelector';

import AutocompleteBox from './autocompleteBox';
import RadioComponent from './radioComponent';
import SelectBox from './selectBox';
import { IDateComponentProps } from './date/dateWrapper';
import { GridSize } from '@material-ui/core';

type FilterTypes = 'date' | 'autoComplete' | 'radio' | 'select';
export type FilterDefaultValue = string | string[] | { start?: Date; end?: Date } | { label: string; value: string }[] | number[];
export type FilterValues = { [k: string]: FilterDefaultValue | undefined };

type isAutoCompleteFilter = { label: string; value: string }[];
type isDateFilter = { start?: Date; end?: Date };

/**
 * Type guard for Autocomplete filters, since the values coming from React-hook-form are Objects with Labels and value or Array of them
 */
export function isAutoComplete(filter: any): filter is isAutoCompleteFilter {
  return (
    (Array.isArray(filter) && filter.some((el: {}) => typeof el === 'object' && 'label' in el)) || (typeof filter === 'object' && 'label' in filter)
  );
}

export function isDate(filter: any): filter is isDateFilter {
  return typeof filter === 'object' && filter.hasOwnProperty('start') && filter.hasOwnProperty('end');
}

interface BaseFilter {
  type: FilterTypes;
  filterKey: string;
  title: string;
  /** Used to change other filters based on what was selected */
  dependents?: {
    key: string;
    setValue: FilterDefaultValue | number[];
    triggerValue?: string | ((value: FilterDefaultValue) => boolean);
    type?: FilterTypes;
  }[];
  /** We dont want to display certain filters so we pass in display Icon as false */
  filterOptions: { label: string; value: string; displayIcon?: JSX.Element | boolean }[] | { start?: Date; end?: Date };
  hideDisplayTitle?: boolean;
  /** Used for React hook Controller */
  field?: ControllerRenderProps;
  defaultValue?: FilterDefaultValue;
  xsValue?: GridSize;
}

export interface DateFilter extends Omit<BaseFilter, 'filterOptions' | 'dependents'>, IDateComponentProps {
  type: 'date';
  onDateSelect?: (date: { start: Date; end: Date }) => void;
  dependents?: { key: string; type?: FilterTypes; setValue: string; triggerValue?: (value: { start?: Date; end?: Date }) => boolean }[];
  multiple?: boolean;
  showTitle?: boolean;
}

export interface SelectFilter extends BaseFilter {
  type: 'select';
  filterOptions: { label: string; value: string; displayIcon?: JSX.Element | boolean }[];
  isMultipleSelection?: boolean;
  onChangeHandler?: (val: string | string[]) => void;
  defaultValue: string | string[];
  displayAllOptions?: boolean;
}

export interface RadioFilter extends BaseFilter {
  type: 'radio';
  filterOptions: { label: string; value: string; displayIcon?: JSX.Element | boolean }[];
  defaultValue: string;
  onChangeHandler?: (val: string) => void;
}

export interface AutoCompleteFilter extends BaseFilter {
  type: 'autoComplete';
  filterOptions: { label: string; value: string; displayIcon?: JSX.Element }[];
  defaultValue: { label: string; value: string }[];
  isMultipleSelection?: boolean;
  creatable?: boolean;
  inputValue?: string;
  toggleOptionsForKeyboard?: boolean;
  limitTags?: number;
  rulePattern?: RegExp;
  toggleKeyboard?: (value: boolean) => void;
  handleInputChange?: (value: string) => void;
  modifyInputValue?: (value: string) => string;
  onChangeHandler?: (value: { label: string; value: string }[]) => void;
  renderOptionAndLabel?: (value: string) => string;
  modifyLabelFromAPI?: (value: { label: string; value: string }[]) => { label: string; value: string }[];
}

export type FilterOptions = AutoCompleteFilter | RadioFilter | SelectFilter | DateFilter;

function FilterBox({ ...props }: PropsWithChildren<FilterOptions>) {
  const renderBox = () => {
    switch (props.type) {
      case 'autoComplete':
        return <AutocompleteBox key={props.filterKey} {...props} />;
      case 'date':
        return props.multiple ? <DateRangeSelector key={props.filterKey} {...props} /> : <DateSelector key={props.filterKey} {...props} />;
      case 'radio':
        return <RadioComponent key={props.filterKey} {...props} />;
      case 'select':
        return <SelectBox key={props.filterKey} {...props} />;
      default:
        return <></>;
    }
  };
  return renderBox();
}

export default FilterBox;
