import React from 'react';
import { FormattedMessage } from 'react-intl';

/** Import Material-UI Component */
import { makeStyles, createStyles } from '@material-ui/styles';
import { ButtonBase, Popover, Box, Typography } from '@material-ui/core';
// Import Icons
import SpecialInstructionIcon from 'assets/images/pngs/special-instruction.png';
import CustomerComplaintIcon from 'assets/images/pngs/customer-complaint.png';
import { useStationStore } from 'stores';
import { CustomerPreferences } from 'components/customerPreferences';
const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: '0 2rem',
    },
    buttonBase: {
      borderRadius: '1rem',
      padding: '5px 0',
      color: '#b6bcd1',
    },
    buttonIcon: {
      height: '2rem',
      padding: '0 1rem',
    },
    popoverDisplay: {
      padding: '1rem 0',
      minWidth: '15rem',
      display: 'flex',
    },
  })
);

interface Props {
  isCustomerComplains?: boolean;
}

const defaultProps = {
  isCustomerComplains: false,
};

const InstructionPopover: React.FC<Props> = ({ isCustomerComplains = defaultProps.isCustomerComplains }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const isPopoverActive = Boolean(anchorEl);

  const { currentItem } = useStationStore();

  function handleBagIconClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setAnchorEl(event.currentTarget);
  }

  function handleBagPopoverClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <Box display="flex" alignItems="center" className={classes.container}>
        <ButtonBase focusRipple className={classes.buttonBase} onClick={handleBagIconClick}>
          <img src={isCustomerComplains ? CustomerComplaintIcon : SpecialInstructionIcon} alt="Washmen Icon" className={classes.buttonIcon} />
          <Typography variant="h6" style={{ color: '#9393a9' }}>
            <FormattedMessage
              id={`station.sorter.header.${isCustomerComplains ? 'customerComplaint' : 'specialInstruction'}`}
              defaultMessage="Special Instruction"
            />
          </Typography>
        </ButtonBase>
        <Popover
          open={isPopoverActive}
          onClose={handleBagPopoverClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box
            display="flex"
            alignItems="start"
            width="100%"
            flexDirection="column"
            justifyContent={isCustomerComplains ? undefined : 'center'}
            className={classes.popoverDisplay}
          >
            {!isCustomerComplains ? (
              currentItem.preferences && (
                <>
                  {currentItem.customerInstruction && (
                    <Box display="flex" flexDirection="column" maxWidth="30rem" padding="0rem 1rem">
                      <Typography variant="h6">Customer's Instruction</Typography>
                      <Typography variant="body1">{currentItem.customerInstruction}</Typography>
                    </Box>
                  )}

                  <Typography variant="h6" style={{ paddingLeft: '1rem' }}>
                    Customer's Instruction
                  </Typography>
                  <Box display="flex" justifyContent="center" width="100%">
                    <CustomerPreferences customerPref={currentItem.preferences} reportsList={currentItem.reportDetailsList} />
                  </Box>
                </>
              )
            ) : (
              <ul>
                <li>
                  <Typography variant="h6">Not Cleaned</Typography>
                </li>
                <li>
                  <Typography variant="h6">Not Cleaned</Typography>
                </li>
              </ul>
            )}
          </Box>
        </Popover>
      </Box>
    </>
  );
};

export default InstructionPopover;
