import React, { useRef } from 'react';
import styled from 'styled-components';
import { Box, Button, Modal, TextField, Typography } from '@material-ui/core';

import { MainKeyboard } from 'components/mainKeyboard';
import { MainKeyboardRefProps } from 'components/mainKeyboard/mainKeyboard';
import { ImagePopup } from 'components/imagePopup';

interface IProps {
  image: string;
  onAddNote: (description: string, photo: string) => void;
  onCancel: () => void;
  preDescription?: string;
}

const AddDescriptionDialog: React.FC<IProps> = ({ image, onAddNote, onCancel, preDescription }) => {
  const [description, setDescription] = React.useState(preDescription || '');
  const keyboardRef = useRef<MainKeyboardRefProps>(null);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onAddNote(description, image);
  };

  const onKeyboardInputChange = (input: string) => {
    setDescription(input);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDescription(value);
    if (keyboardRef.current) {
      keyboardRef.current.setInput(value);
    }
  };

  return (
    <Modal open keepMounted>
      <>
        <InnerModal>
          <HeaderBox flex={1} justifyContent='center' alignItems='center'>
            <HeaderText variant='h3'>Add Comment</HeaderText>
          </HeaderBox>
          <form onSubmit={handleFormSubmit}>
            <CustomBox flex={8} flexDirection='column' justifyContent='center' marginTop='3rem'>
              <CustomBox justifyContent='center'>{image && <ImagePopup imageUrl={image} />}</CustomBox>
              <CustomBox>
                <CustomTextField
                  id='outlined-multiline-static'
                  multiline
                  onFocus={() => {
                    if (keyboardRef.current) keyboardRef.current.toggleKeyBoard(true);
                  }}
                  rows={7}
                  label='Add your note here.'
                  value={description}
                  onChange={handleChange}
                  fullWidth
                />
              </CustomBox>
              <Box display={'flex'} flex={'0.4'} justifyContent='center' alignItems={'center'} padding='3rem 0'>
                <SubmitButton onClick={onCancel} variant='outlined' color='primary'>
                  CANCEL
                </SubmitButton>
                <SubmitButton disabled={!description} variant='contained' color='primary' type='submit'>
                  ADD
                </SubmitButton>
              </Box>
            </CustomBox>
          </form>
        </InnerModal>
        <MainKeyboard onChange={(input: string) => onKeyboardInputChange(input)} ref={keyboardRef} />
      </>
    </Modal>
  );
};
const CustomBox = styled(Box)`
  display: flex;
`;
const HeaderBox = styled(Box)`
  display: flex;
  border-bottom: solid 2px #b6bcd1;
`;
const CustomTextField = styled(TextField)`
  padding: 0.75rem;
`;

const InnerModal = styled(Box)`
  padding: 1rem;

  background-color: white;
  position: absolute;
  border-radius: 0.7rem;
  top: 38%;
  left: 50%;
  width: 60rem;
  height: max-content;
  transform: translate(-50%, -50%);
  overflow: scroll;
`;
const HeaderText = styled(Typography)`
  margin: 1.2rem;
`;

const SubmitButton = styled(Button)`
  height: 5.6rem;
  width: 18.6rem;
  padding: 1rem 3rem;
  font-size: 1.5rem;
  margin-left: 2rem;
`;

export default AddDescriptionDialog;
