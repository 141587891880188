import React, { useState } from 'react';
import { Box, Button, ButtonBase, Typography } from '@material-ui/core';
import { ItemActions } from 'utils/interfaces';
import { CustomDialog } from 'components/dialogs';
import { ReactComponent as BlackCloseSVG } from 'assets/images/svgs/close-black.svg';
import { ItemTypes } from 'utils/enums/typesEnum';
import styled from 'styled-components';
import { useStyles } from './itemIssueModalOverview';
import LabelSelector from '../../../shoeSorterStation/components/labelSelector/LabelSelector';

const iconDimensions: { height: string; width: string } = { height: '11.25rem', width: '160px' };

interface IProps {
  open: boolean;
  actionItemIssue: (reason: ItemActions) => void;
  toggle: () => void;
}

const ShoeIssueModal: React.FC<IProps> = ({ open, actionItemIssue, toggle }) => {
  const classes = useStyles();
  const [currentAction, setCurrentAction] = useState<ItemActions>();
  const handleIconClick = (key: ItemActions) => {
    if (key === currentAction) {
      setCurrentAction(undefined);
      return;
    }
    setCurrentAction(key);
  };

  function reportIssue() {
    if (currentAction) {
      actionItemIssue(currentAction);
      toggle();
    }
  }

  return (
    <CustomDialog open={open} size="md" dataCy="reportIssueDialog.component">
      <Box display="flex" justifyContent="flex-end">
        <CustomButton
          onClick={() => {
            toggle();
          }}
        >
          <BlackCloseSVG width="3rem" height="3rem" />
        </CustomButton>
      </Box>
      <Box padding="1rem 4rem" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <ItemCannotCleanedJourneyHeader>
          <Typography variant="h4">Can't Be Cleaned</Typography>
        </ItemCannotCleanedJourneyHeader>
        <NotCleanedBox display="flex" justifyContent="space-between" width="80%">
          <LabelSelector
            iconName={ItemTypes.spotter}
            isActive={currentAction === ItemActions.SEND_BACK_TO_THE_SPOTTER}
            onToggleClick={() => handleIconClick(ItemActions.SEND_BACK_TO_THE_SPOTTER)}
            labelFontSize="25px"
            iconDimensions={iconDimensions}
            dataCy="stain.reportIssue.button"
          />
          <LabelSelector
            iconName={ItemTypes.shoeCantBeCleaned}
            labelFontSize="25px"
            iconDimensions={{ height: '11.25rem', width: '160px' }}
            isActive={currentAction === ItemActions.CANT_CLEAN}
            inactiveClassName="inactive-icon-stroke"
            activeClassName="active-icon-stroke"
            onToggleClick={() => handleIconClick(ItemActions.CANT_CLEAN)}
            dataCy="damage.reportIssue.button"
          />
        </NotCleanedBox>
      </Box>
      <ItemCannotCleanedJourneyHeader className={classes.buttonsBox}>
        <Box paddingTop={2}>
          <Button
            className={classes.buttons}
            color="primary"
            size="large"
            variant="contained"
            disabled={!currentAction}
            onClick={() => reportIssue()}
            data-cy="pressing.reportIssue.continue.button"
          >
            Submit
          </Button>
        </Box>
      </ItemCannotCleanedJourneyHeader>
    </CustomDialog>
  );
};
export default ShoeIssueModal;
const CustomButton = styled(ButtonBase)`
  top: 5px;
  right: 5px;
`;
const ItemCannotCleanedJourneyHeader = styled(Box)`
  padding: 1.3rem 2rem;
  padding-bottom: 5rem;
`;
const NotCleanedBox = styled(Box)`
  .inactive-icon-stroke path:nth-child(6) {
    fill: #ab1b1b;
  }

  .inactive-icon-stroke path:nth-child(4) {
    fill: #ab1b1b;
  }

  .inactive-icon-stroke path:nth-child(5) {
    fill: #ab1b1b;
  }

  .active-icon-stroke path:nth-child(6) {
    fill: #ab1b1b;
  }

  .active-icon-stroke path:nth-child(4) {
    fill: #ab1b1b;
  }

  .active-icon-stroke path:nth-child(3) {
    fill: #ffffff;
  }

  .active-icon-stroke path:nth-child(5) {
    fill: #ab1b1b;
  }

  .active-icon-stroke path:nth-child(8) {
    fill: #ffffff;
  }
`;
