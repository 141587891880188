import React, { useState, useEffect } from 'react';
import CustomDialog from '../dialogs/customDialog';
import { Typography, Grid, createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { ReactComponent as GoodJobIcon } from 'assets/images/svgs/goodjob.svg';
import { ReactComponent as AverageJobIcon } from 'assets/images/svgs/average-face.svg';
import { ReactComponent as SlowJobIcon } from 'assets/images/svgs/slow-face.svg';
import { observer } from 'mobx-react-lite';
import { useStationStore } from 'stores';
import moment from 'moment';

interface Props {
  onClose: () => void;
  open: boolean;
  considerItems?: boolean;
}

type stations = 'sorter' | 'tagger';

interface Station {
  station: stations;
}
type timeEvalType = 'good' | 'average' | 'slow';

interface ITimeEval {
  label: string;
  icon: JSX.Element;
}

const TimeMapper: { [key in timeEvalType]: ITimeEval } = {
  average: {
    icon: <AverageJobIcon />,
    label: 'You can do better!',
  },
  good: {
    icon: <GoodJobIcon />,
    label: 'Good Job!',
  },
  slow: {
    icon: <SlowJobIcon />,
    label: 'Too Slow!',
  },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(5),
      width: '63%',
    },
    text: {
      textAlign: 'center',
    },
    image: {
      margin: '3rem 0',
    },
    legendIcon: {
      height: '3rem',
      width: '3rem',
    },
    gridContainer: {
      padding: '.7rem .5rem',
    },
    good: {
      color: '#4DBB9F',
    },
    bad: {
      color: '#CB3636',
    },
    average: {
      color: '#F4AA39',
    },
  })
);

interface rating {
  good: string;
  average: string;
  bad: string;
}

const legendsMapper: { [key in stations]: rating } = {
  sorter: {
    good: 'under 50 sec ',
    average: '50 to 70 sec',
    bad: 'above 70 sec',
  },
  tagger: {
    good: 'Under 3 mins',
    average: '3 to 4 mins',
    bad: 'Above 4 mins ',
  },
};

const LegendsViewer: React.FC<Station> = ({ station }) => {
  const classes = useStyles();
  return (
    <>
      <Grid container direction="column" justify="flex-start" style={{ width: 'auto', padding: '0 0 0 .5rem' }}>
        <Grid container spacing={2} direction="row" classes={{ container: classes.gridContainer }}>
          <Grid item>
            <GoodJobIcon className={classes.legendIcon} />
          </Grid>
          <Grid item xs>
            <Typography color="secondary" classes={{ colorSecondary: classes.good }}>
              Good
            </Typography>
            <Typography>{legendsMapper[station].good}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} direction="row" classes={{ container: classes.gridContainer }}>
          <Grid item>
            <AverageJobIcon className={classes.legendIcon} />
          </Grid>
          <Grid item xs>
            <Typography color="secondary" classes={{ colorSecondary: classes.average }}>
              Average
            </Typography>
            <Typography>{legendsMapper[station].average}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} direction="row" classes={{ container: classes.gridContainer }}>
          <Grid item>
            <SlowJobIcon className={classes.legendIcon} />
          </Grid>
          <Grid item xs>
            <Typography color="secondary" classes={{ colorSecondary: classes.bad }}>
              Bad
            </Typography>
            <Typography>{legendsMapper[station].bad}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const ScanTimeDialog: React.FC<Props> = observer(({ open, onClose, considerItems = true }) => {
  const classes = useStyles();
  //FIXME : CLIENT STATE
  const { bagScanTimeStamp, items } = useStationStore();
  const [timeEval, setTimeEval] = useState<{ timeEval: timeEvalType; time: moment.Duration | undefined }>({
    time: undefined,
    timeEval: 'average',
  });

  useEffect(() => {
    if (!bagScanTimeStamp) {
      return;
    }
    const today = moment();
    const timeAtScan = moment(bagScanTimeStamp.toISOString());
    const duration = moment.duration(today.diff(timeAtScan));
    const durationAsSeconds = duration.asSeconds();
    let evaluationText: timeEvalType = 'average';

    if (!considerItems) {
      // calculate average per bag if considerItems is false
      // if less than 3 minutes => good
      if (durationAsSeconds < 180) {
        evaluationText = 'good';
      } else if (durationAsSeconds < 240) {
        // if less than 4 minutes => average
        evaluationText = 'average';
      } else {
        evaluationText = 'slow';
      }
    } else {
      // calculate average per item if considerItems is true
      if (items.length === 0) {
        return;
      }
      const averagePerItem = durationAsSeconds / items.length;
      if (averagePerItem < 50) {
        evaluationText = 'good';
      } else if (averagePerItem < 70) {
        evaluationText = 'average';
      } else {
        evaluationText = 'slow';
      }
    }
    setTimeEval(() => ({ timeEval: evaluationText, time: duration }));
  }, [bagScanTimeStamp, items.length, open, considerItems]);
  return (
    <CustomDialog onClose={onClose} open={open} size="md">
      <Grid container direction="row">
        <LegendsViewer station={considerItems ? 'sorter' : 'tagger'} />
        <Grid container direction="column" alignItems="center" justify="center" spacing={0} classes={{ container: classes.root }}>
          <Grid item xs="auto">
            <Typography variant="h4">{TimeMapper[timeEval.timeEval].label}</Typography>
          </Grid>
          <Grid item xs="auto" classes={{ item: classes.image }}>
            {TimeMapper[timeEval.timeEval].icon}
          </Grid>
          <Grid item xs="auto" classes={{ item: classes.text }}>
            <Typography variant="h4">
              {considerItems && (
                <>
                  {timeEval.time && <>{Math.trunc(timeEval.time.asSeconds() / items.length)} sec / Item</>}
                  <br />
                </>
              )}
              {timeEval.time && <>{`${Math.trunc(timeEval.time.asMinutes())}:${timeEval.time.seconds()}`} / Bag </>}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </CustomDialog>
  );
});

export default ScanTimeDialog;
