import { useMutation } from 'react-query';
import stationService from 'services/station.service';

interface RemoveOptions {
  bagCode: string;
  itemCode: string;
  managerId: string;
}
const useRemoveItemFromBag = () => {
  return useMutation<any, Error, RemoveOptions>(({ bagCode, itemCode, managerId }) => stationService.removeItemFromBag(bagCode, itemCode, managerId));
};

export default useRemoveItemFromBag;
