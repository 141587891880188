import React from 'react';

import { PhotoCatcher } from '../..';
import { FineryImages } from 'utils/enums/stationEnums';

import { FineryAngleImages } from 'utils/interfaces';
import { StyledButton } from 'modules/stations/components/photoCatcher';
import StainOrDamageDialog from 'modules/stations/shoeSorterStation/components/dialogs/stainOrDamageDialog';

const titles: { [key in keyof FineryAngleImages]: string } = {
  careLabel: 'Please take a photo of the care label',
  front: 'Please take a photo of the front',
  back: 'Please take a photo of the back',
  leftSide: 'Please take a photo of the inside front',
  rightSide: 'Please take a photo of the inside back',
  extra: 'Please take an extra photo',
};

interface props {
  imageSrc: string;
  activeStep: FineryImages;
  showSkipButton: boolean;
  onPhotoTaken: (imageBlob: string) => void;
  onNextClick: () => void;
  onSkip: (routeToStainDamage: boolean, routeTo?: 'stain' | 'damage') => void;
}

const PhotosSelector: React.FC<props> = ({ imageSrc, activeStep, showSkipButton, onPhotoTaken, onNextClick, onSkip }) => {
  const [showStainOrDamageModal, setShowStainOrDamageModal] = React.useState(false);

  const handleOnPhotoTaken = (image: string) => onPhotoTaken(image);

  const handleNextbtn = () => onNextClick();

  const handleSkipButton = () => {
    setShowStainOrDamageModal(true);
  };

  return (
    <>
      <StainOrDamageDialog open={showStainOrDamageModal} submit={onSkip} onClose={() => {}} />

      <PhotoCatcher
        title={titles[activeStep]}
        imageSrc={imageSrc.length ? imageSrc : ''}
        onConfirmImage={handleNextbtn}
        onPhotoTaken={handleOnPhotoTaken}
        showSkipButton={showSkipButton}
        handleSkipButton={handleSkipButton}
        enableUploadImage
        showChildrenInTakePhoto={true}
        onResetImage={() => handleOnPhotoTaken('')}
      >
        {activeStep === FineryImages.extra &&
          imageSrc && [
            <StyledButton key="damage-button" color="secondary" variant="outlined" size="large" onClick={() => onSkip(true, 'damage')}>
              add damage
            </StyledButton>,
            <StyledButton
              key="stain-button"
              color="secondary"
              variant="outlined"
              size="large"
              onClick={() => onSkip(true, 'stain')}
              style={{ margin: '0 2rem' }}
            >
              add stain
            </StyledButton>,
          ]}
      </PhotoCatcher>
    </>
  );
};

export default PhotosSelector;
