import { IActivity, IOrderItem } from './stationInterfaces';

export type DelayReasons = '' | 'DRYING' | 'OTHER' | 'TAILORING' | 'STAIN' | 'DAMAGE' | 'DELICATE' | 'RISK' | 'TRANSFER' | 'PRESSING_WASHING';

export interface IPendingItem extends IOrderItem {
  lastEvent: IActivity;
  createdAt?: string;
  delayDetails?: DelayInfo;
  isSwapped?: boolean;
}
export interface DelayInfo {
  isDelayed: boolean;
  delayDays: number;
  isDelayAnswered: boolean;
  delayReason: string;
  reportedDelayTimestamp?: string;
}

export interface IOrderWithItems {
  itemsList: IPendingItem[];
  orderId?: string;
  totalPendingItems: number;
  customerAlphaId: string;
  customerName?: string;
  customerEmail?: string;
  createdAt?: string;
  customerPhone?: string;
  pickupDate?: string;

  dropoffTime?: string;
  orderAlphaId: string;
  dropoffDriver: {
    name: string;
    id: string;
  };
  pickupDriver: {
    name: string;
    id: string;
  };
  geofence: {
    label: string;
    clusterName: string;
    geofenceId: string;
    clusterId: string;
  };
  dropoffAddress?: {
    geofence?: {
      id?: string;
    };
  };
  isUrgent: boolean;
  dropoffDate: string;
  delayStatus: DelayStatus;
  totalCPBags?: number;
  totalPBags?: number;
  hasSwappedItems?: boolean;
}
export enum DelayStatus {
  NOT_DETERMIND = 0,
  MIGHT_BE_DELAYED = 1,
  IS_DELAYED = 2,
  NOT_DELAYED = 3,
}
