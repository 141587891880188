import { useQuery } from 'react-query';

import { PendingService } from 'services';
import { IActivity } from 'utils/interfaces';

const useGetItemActivities = (itemCode: string, orderAlphaId: string) => {
  const { data, isSuccess, isError } = useQuery<{ itemActivities: IActivity[] }, Error>(['itemActivities', { itemCode, orderAlphaId }], () =>
    PendingService.getItemActivities(itemCode, orderAlphaId)
  );

  return { isSuccess, isError, itemActivities: data?.itemActivities ?? [] };
};

export default useGetItemActivities;
