import React from 'react';
import { CustomDialog } from '../../../../components/dialogs';
import styled from 'styled-components';
import { Button, Grid, Typography } from '@material-ui/core';
import { IOrderItem } from '../../../../utils/interfaces';
import { ReactComponent as LinkIcon } from 'assets/images/svgs/link-icon.svg';
import Box from '@material-ui/core/Box';
import { LoaderComponent } from 'components/loaderComponent';

interface Props {
  open: boolean;
  currentItem: IOrderItem;
  items: (IOrderItem | undefined)[];
  onClose: () => void;
  onMatch: () => void;
}

export const MatchRecommendedModal: React.FC<Props> = ({ open, items, currentItem, onClose, onMatch }) => {
  return (
    <CustomDialog open={open}>
      <LoaderComponent />
      <Wrapper container>
        <Grid container item direction={'row'} alignItems={'center'} justifyContent={'center'}>
          <Grid item alignContent={'center'}>
            <Box alignItems={'center'} justifyContent={'center'}>
              <CurrentItemImage src={currentItem.frontImage} />
              <StyledLinkIcon />
            </Box>
          </Grid>
          <Grid item>
            {items.map((item) => (
              <LinkItemImage key={item?.code} src={item?.frontImage} />
            ))}
          </Grid>
        </Grid>
        <HeaderBox flex={1} justifyContent="center" alignItems="center">
          <HeaderText variant="h5">Do you want to marry these items together?</HeaderText>
        </HeaderBox>
        <Grid container justifyContent={'center'} alignItems={'center'}>
          <CancelButton variant={'outlined'} onClick={onClose}>
            Cancel
          </CancelButton>
          <ConfirmButton variant={'contained'} onClick={onMatch}>
            yes
          </ConfirmButton>
        </Grid>
      </Wrapper>
    </CustomDialog>
  );
};
const ConfirmButton = styled(Button)`
  margin-top: 3rem;
  width: 12rem;
  height: 4rem;
  align-self: center;
  background-color: #3e48a6;
  margin-right: 1rem;
  color: white;
`;
const CancelButton = styled(Button)`
  margin-top: 3rem;
  width: 12rem;
  height: 4rem;
  align-self: center;
  background-color: white;
  margin-right: 1rem;
  color: #3e48a6;
  border-color: #3e48a6;
`;
const CurrentItemImage = styled.img`
  height: 14rem;
  width: 10rem;
`;
const Wrapper = styled(Grid)`
  padding: 2rem;
`;
const LinkItemImage = styled.img`
  height: 12rem;
  width: 8rem;
  margin: 0.5rem;
`;
const StyledLinkIcon = styled(LinkIcon)`
  align-items: center;
  margin: 1rem;
`;
const HeaderText = styled(Typography)`
  margin: 1.2rem;
`;
const HeaderBox = styled(Box)`
  display: flex;
`;
