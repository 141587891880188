import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';

import { ColorMapper } from 'components/colorMapper';
import { ImagePopup } from 'components/imagePopup';
import ItemTypeIconMap from 'utils/maps/itemTypeIconMap';
import { formatArrayToString, capitalizeString } from 'utils/helpers/string';
import { IOrderItem, SaItem } from 'utils/interfaces';

const useStyle = makeStyles((theme) => ({
  root: {
    padding: '15px 30px',
  },
  itemTypeStyle: {
    marginRight: '2rem',
    [theme.breakpoints.down('md')]: {
      maxHeight: '60px',
    },
    [theme.breakpoints.only('lg')]: {
      maxHeight: '70px',
    },
    [theme.breakpoints.up('xl')]: {
      maxHeight: '80px',
    },
  },
  label: {
    color: '#7d7c97',
  },
  section: {
    margin: '0 1rem',
  },
}));

interface Props {
  withItemInfo?: boolean;
  withoutBackground?: boolean;
  alignLeft?: boolean;
  currentItem: IOrderItem;
  linkedItem?: SaItem;
}

const ItemBanner: React.FC<React.PropsWithChildren<Props>> = ({
  withItemInfo = false,
  children,
  withoutBackground = false,
  alignLeft = false,
  currentItem,
  linkedItem,
}) => {
  const classes = useStyle();

  // Deconstruct type and colorsList
  const { type, colorsList } = currentItem;

  // Checking conditions
  const hasStainReport = linkedItem && linkedItem.stainsArray && linkedItem.stainsArray.length > 0;
  const hasTagsArray = linkedItem && linkedItem!.tagsArray && linkedItem!.tagsArray.length > 0;
  const hasCleaningInstruction = linkedItem && linkedItem.cleaningInstruction;

  // Construct sections grid xs value
  const stainSectionGrid = withItemInfo && hasTagsArray && hasCleaningInstruction ? 2 : alignLeft ? 10 : 3;
  const instructionSectionGrid = withItemInfo && hasStainReport && hasTagsArray ? 3 : alignLeft ? 10 : 4;
  const otherSectionGrid = withItemInfo && hasStainReport && hasCleaningInstruction ? 2 : alignLeft ? 10 : 3;

  return (
    <Grid
      container
      className={classes.root}
      alignItems="center"
      style={{ backgroundColor: withoutBackground ? '#fff' : '#f9fafb' }}
      direction={alignLeft ? 'row-reverse' : 'row'}
    >
      <Grid container item xs={10} direction={alignLeft ? 'column' : 'row'}>
        {/* Item's Information */}
        {currentItem && withItemInfo && (
          <>
            <Grid container item xs={3} justify="flex-start" direction="row">
              {type && <img src={ItemTypeIconMap[type]} alt="" className={classes.itemTypeStyle} />}
              {colorsList && <ColorMapper colorsList={colorsList} withShadows textHidden dimension="30px" />}
            </Grid>
          </>
        )}

        {/* Special Attention Instruction */}
        {linkedItem && (
          <>
            {/* Special Attention Stain Reported */}
            {hasStainReport && (
              <Grid container item xs={stainSectionGrid} direction="column" className={classes.section}>
                <Typography variant="h6" className={classes.label}>
                  Stain Reported:
                </Typography>
                <Typography variant="body1">{formatArrayToString(linkedItem.stainsArray || [])}</Typography>
              </Grid>
            )}

            {/* Special Attention Cleaning Instruction */}
            {hasCleaningInstruction && (
              <Grid container item xs={instructionSectionGrid} direction="column" className={classes.section}>
                <Typography variant="h6" className={classes.label}>
                  Cleaning Instruction:
                </Typography>

                <Typography variant="body1">{capitalizeString(linkedItem.cleaningInstruction || '')}</Typography>
              </Grid>
            )}

            {/* Special Attention Others */}
            {hasTagsArray && (
              <Grid container item xs={otherSectionGrid} direction="column" className={classes.section}>
                <Typography variant="h6" className={classes.label}>
                  Other:
                </Typography>

                <Typography variant="body1">{formatArrayToString(linkedItem.tagsArray || [])}</Typography>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <Grid container item xs={2} alignItems="center" justify="center">
        {withItemInfo ? children : <ImagePopup linkedItem={linkedItem || undefined} />}
      </Grid>
    </Grid>
  );
};

export default ItemBanner;
