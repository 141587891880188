import React from 'react';
import styled from 'styled-components';
import QRCode from 'qrcode.react';

import GreenCheck from 'assets/images/pngs/check.png';
import ErrorIcon from 'assets/images/pngs/cancel.png';
import PackageIcon from 'assets/images/pngs/package-print.png';
import PendingIcon from 'assets/images/pngs/pending.png';

interface Props {
  qrCode: string;
  status?: 'NONE' | 'PACKAGED' | 'APPROVED' | 'REJECTED' | 'PENDING';
  withStatus?: boolean;
  withMargin?: boolean;
}

const statusIconMapper = {
  PACKAGED: PackageIcon,
  APPROVED: GreenCheck,
  REJECTED: ErrorIcon,
  PENDING: PendingIcon,
};

const QrDisplay: React.FC<Props> = ({ qrCode, status, withStatus = false, withMargin = false }) => {
  return (
    <div style={{ position: 'relative' }}>
      <Wrapper margin={withMargin}>
        <Code>{qrCode}</Code>
        <QRCode value={qrCode || ''} renderAs="svg" includeMargin size={55} />
      </Wrapper>
      {withStatus && status && status !== 'NONE' && <Checked src={statusIconMapper[status]} alt="" />}
    </div>
  );
};

export default QrDisplay;

const Wrapper = styled.div<{ margin: boolean }>`
  max-width: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 0.3rem;
  border: 1px solid #b6bcd1;
  border-radius: 5px;

  min-height: 85px;
  max-height: 85px;

  ${(props) => (props.margin ? `margin: .5rem` : ``)}
`;

const Code = styled.div`
  word-wrap: break-word;
  width: 50px;
  font-size: 0.8rem;
  text-align: center;
`;

const Checked = styled.img`
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
`;
