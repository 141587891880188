import React from 'react';
import { Grid, Button, Typography, IconButton } from '@material-ui/core';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';

import CustomDialog from './customDialog';
import { UniversalItemNote } from 'utils/interfaces';

import SpecialInstructionIcon from 'assets/images/pngs/special-instruction.png';

interface Props {
  onClose: () => any;
  open: boolean;
  note: UniversalItemNote;
}

const UniversalItemNoteModal: React.FC<Props> = ({ onClose, open, note }) => {
  return (
    <CustomDialog custom_size={{ height: 'auto', width: '70%' }} open={open} size={false}>
      <Wrapper container direction="column" alignItems="center">
        <TitleWrapper container item direction="row" justifyContent="center" alignItems="center">
          <InstructionsImg src={SpecialInstructionIcon} alt="warning" />
          <Typography variant="h4" display="initial" align="center">
            Item instructions
          </Typography>
          <CloseIconWrapper onClick={onClose} aria-label="close">
            <StyledCloseIcon />
          </CloseIconWrapper>
        </TitleWrapper>
        <InstructionsWrapper container item direction="row" alignItems="center">
          <StyledNotes variant="h6" display="initial" align="left">
            {note.description}
          </StyledNotes>
        </InstructionsWrapper>
        <Grid item xs={5}>
          <DoneButton color="primary" variant="contained" size="large" onClick={onClose}>
            Done
          </DoneButton>
        </Grid>
      </Wrapper>
    </CustomDialog>
  );
};

export default UniversalItemNoteModal;
const Wrapper = styled(Grid)`
  padding: 1rem;
`;

const StyledNotes = styled(Typography)`
  margin: 1.5rem;
`;

const DoneButton = styled(Button)`
  margin-top: 2rem;
`;

const InstructionsWrapper = styled(Grid)`
  padding: 1rem;
  width: 70%;
`;

const TitleWrapper = styled(Grid)`
  padding: 1rem;
  position: relative;
`;

const InstructionsImg = styled.img`
  width: 2.5rem;
  padding-right: 1rem;
`;

const CloseIconWrapper = styled(IconButton)`
  position: absolute;
  right: 0;
`;
const StyledCloseIcon = styled(CloseIcon)`
  width: 2.5rem;
  height: 2.5rem;
`;
