import React, { useRef, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Typography, Button, Grid, Theme, Box, CircularProgress } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import stationService from 'services/station.service';
import { useStationStore } from 'stores';
import { observer } from 'mobx-react-lite';
import { WashmenIcon } from 'components/washmenIcon';
import { LoaderComponent } from 'components/loaderComponent';
import { Toaster } from 'components/toaster';

import CareLabelAnimation from 'assets/images/pngs/gif/carelabel-animation.gif';

interface Props {
  imageSrc?: string;
  title?: string;
  cancelBtn?: Boolean;
  cancelBtnText?: string;
  isCareLabel?: boolean;
  hideNextButton?: boolean;
  showSkipButton?: boolean;
  handleSkipButton?: () => void;
  onCancelButtonClick?: () => void;
  onPhotoTaken?: (imageBlob: string) => void;
  onConfirmImage?: (imageBlob: string) => void;
  onResetImage?: () => void;
  isReport?: boolean;
  uploadImageBtn?: boolean;
  height?: string;
  isLoader?: boolean;
  currentItemCode?: string;
}

const defaultProps = {
  title: 'Please take a front photo of garment',
  isCareLabel: false,
  onPhotoTaken: (image: string) => {},
  onConfirmImage: (image: string) => {},
  onResetImage: () => {},
  handleSkipButton: () => {},
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: '1',
    },
    photoBoxWrapper: {
      border: '1px solid #b6bcd1',
      padding: '1rem',
      width: 'auto',
    },
    cameraIcon: {
      marginRight: '2rem',
      '& path': {
        fill: 'white',
      },
      width: '2.5vw',
      height: 'auto',
    },
    careLabelAnimation: {
      maxHeight: '10rem',
      maxWidth: '6rem',
      margin: '0 2rem',
    },
  })
);

/**
 *
 * @description PhotoCatcher component that takes photos
 */
const PhotoCatcher: React.FC<React.PropsWithChildren<Props>> = observer(
  ({
    children,
    imageSrc = '',
    cancelBtn = false,
    cancelBtnText = 'cancel',
    onCancelButtonClick = () => {},
    isCareLabel = defaultProps.isCareLabel,
    onPhotoTaken = defaultProps.onPhotoTaken,
    onConfirmImage = defaultProps.onConfirmImage,
    onResetImage = defaultProps.onResetImage,
    handleSkipButton = defaultProps.handleSkipButton,
    title = defaultProps.title,
    hideNextButton = false,
    isReport = false,
    showSkipButton = false,
    uploadImageBtn = false,
    height = '',
    isLoader = true,
    currentItemCode = '',
  }) => {
    const { currentItem } = useStationStore();
    const videoElementRef = useRef<HTMLVideoElement>(null);
    const canvasElementRef = useRef<HTMLCanvasElement>(null);
    const [imgValue, setImgValue] = useState(imageSrc);
    const [showImgUploadLoader, setShowImgUploadLoader] = useState<boolean>(false);
    const [loaded, setLoaded] = useState(true);
    const classes = useStyles();

    useEffect(() => {
      if (imgValue) {
        return;
      }
      const video = videoElementRef.current!;
      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then(function (stream) {
            videoElementRef.current!.srcObject = stream;
            // Activate take photo button on load
            setLoaded(false);
          })
          .catch(function (error) {
            console.log('Something went wrong!');
          });
      }
      return () => {
        video.srcObject = null;
      };
    }, [imgValue, showImgUploadLoader]);

    async function onTakeScreenShot() {
      setShowImgUploadLoader(true);
      canvasElementRef.current!.width = videoElementRef.current!.videoWidth;
      canvasElementRef.current!.height = videoElementRef.current!.videoHeight;
      canvasElementRef.current!.getContext('2d')!.drawImage(videoElementRef.current!, 0, 0);
      // Other browsers will fall back to image/png
      const image = canvasElementRef.current!.toDataURL('image/jpeg');
      setImgValue(image);
      const url = await stationService.uploadImageToS3(currentItem.code || currentItemCode, image.split(',')[1], isReport);
      setImgValue(url);
      onPhotoTaken(url);
      setShowImgUploadLoader(false);
    }

    function retakeScreenShotHandler() {
      setImgValue('');
      onResetImage();
    }

    function handleConfirmImage() {
      if (showImgUploadLoader && imgValue.startsWith('https://s3')) {
        toast(<Toaster message="Wait for image to upload" type="error" />);
        return;
      }
      onConfirmImage(imgValue);
    }
    return (
      <RootGrid classes={{ container: classes.root }} height={height} container direction="column" justify="space-around" alignItems="center">
        {isLoader && <LoaderComponent />}
        {showImgUploadLoader && (
          <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%" className="loading-icon">
            <CircularProgress />
          </Box>
        )}
        {!imgValue ? (
          <>
            <Grid container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {isCareLabel && <img src={CareLabelAnimation} alt="careLabel" className={classes.careLabelAnimation} />}
              <Typography variant="h3">{title}</Typography>
              {isCareLabel && <img src={CareLabelAnimation} alt="careLabel" className={classes.careLabelAnimation} />}
            </Grid>
            <Grid item xs={4}>
              <Grid container item alignItems="center" justify="center">
                <video ref={videoElementRef} id="videoElement" autoPlay={true} style={{ width: '100%', maxHeight: '100%' }} />
                <canvas ref={canvasElementRef} style={{ display: 'none' }}></canvas>
              </Grid>
            </Grid>
            <Grid container alignItems="center" justify="space-around">
              {cancelBtn && (
                <>
                  <Button size="large" variant="contained" color="secondary" onClick={() => onCancelButtonClick()}>
                    {cancelBtnText}
                  </Button>
                  <Box margin="0 1rem" />
                </>
              )}

              {showSkipButton && (
                <Button color="secondary" variant="outlined" size="large" onClick={handleSkipButton}>
                  Skip
                </Button>
              )}
              {uploadImageBtn && (
                <>
                  <Button size="large" variant="outlined" color="primary" onClick={() => onCancelButtonClick()}>
                    upload image
                  </Button>
                  <Box margin="0 1rem" />
                </>
              )}
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={async () => {
                  await onTakeScreenShot();
                }}
                disabled={loaded}
              >
                <WashmenIcon svg name="cameraIcon" iconClassName={classes.cameraIcon} isButton={false} />
                Take Photo
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <Grid container item classes={{ item: classes.photoBoxWrapper }} justify="center" direction="column" xs={4}>
              <img className="img-responsive" src={imgValue} alt="" />
              <Button color="primary" size="large" variant="text" onClick={retakeScreenShotHandler}>
                Retake Photo
              </Button>
            </Grid>
            <Grid container item direction="row" alignItems="center" justify="center">
              {children}
              {!hideNextButton && (
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={handleConfirmImage}
                  style={{ margin: '0 auto' }}
                  disabled={showImgUploadLoader}
                >
                  next
                </Button>
              )}
            </Grid>
          </>
        )}
      </RootGrid>
    );
  }
);

const RootGrid = styled(Grid)<{ height: string }>`
  ${(props) => (props.height ? `height: ${props.height};` : '')}
`;

export default PhotoCatcher;
