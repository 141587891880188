import { IBag, IOrderItem, SaItem } from "utils/interfaces"
import { useLinkItemToSaItem, useLinkSaItemToBag, useUnlinkItemToSaItem, useUnlinkSaItemToBag } from "../mutations";
import { QueryObserverResult } from "react-query";
import { BagError } from "modules/stations/shoeSorterStation/queries/useGetBag";
import { useEffect, useMemo, useState } from "react";

import isEmpty from "lodash/isEmpty";

interface BagOptions {
  bag: IBag;
  refetchBag?: () => Promise<QueryObserverResult<IBag, BagError>>
}

export interface SAHandlerOptions {
  linkSpecialAttentionItemToBag: (saItemId: string) => Promise<void>;
  unlinkSpecialAttentionItemFromBag: (saItemId: string) => Promise<void>;
  unlinkItemToSpecialAttentionItem: () => Promise<void>;
  linkItemToSpecialAttentionItem: (saItemId: string) => Promise<void>;
  hasSpecialAttentionItems: boolean;
  specialAttentionItemsList: SaItem[];
  linkedItem?: SaItem;
  isAllSaItemsLinked: boolean;
  specialItemsInBag: SaItem[];
  cleanUp: () => void

}

const Sa_items_LS = 'Sa-items'

const initSaArray = (saItems: SaItem[]) => {
  const saItemsFromLS = JSON.parse(localStorage.getItem(Sa_items_LS) || '[]')
  return isEmpty(saItemsFromLS) ? saItems : saItemsFromLS
}
//TODO: Refactor component to utilize server state, currently it's using client side caching to avoid breaking changes
const useSaItems = (currentItem: IOrderItem, { bag, refetchBag }: BagOptions): SAHandlerOptions => {

  const { mutateAsync: linkItemToSaItem } = useLinkItemToSaItem();
  const { mutateAsync: unlinkItemToSaItem } = useUnlinkItemToSaItem();
  const { mutateAsync: linkSaItemToBag } = useLinkSaItemToBag();
  const { mutateAsync: unlinkSaItemToBag } = useUnlinkSaItemToBag();

  const [specialAttentionItemsArray, setSpecialAttentionItemsArray] = useState<SaItem[]>(() => initSaArray(bag?.order.specialAttentionItemsArray || []))
  const keyedSpecialAttentionItemsArray = useMemo<{ [key: string]: SaItem }>(() => specialAttentionItemsArray.reduce((acc, saItem) => ({
    ...acc,
    [saItem.id]: saItem,
  }), {}), [specialAttentionItemsArray])

  useEffect(() => {

    const newSaItems = (bag?.order.specialAttentionItemsArray || []).map((saItem) => ({
      ...saItem,
      notInBag: keyedSpecialAttentionItemsArray[saItem.id]?.notInBag,
    }))
    setSpecialAttentionItemsArray(newSaItems)
    localStorage.setItem(Sa_items_LS, JSON.stringify(newSaItems));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bag?.order.specialAttentionItemsArray])

  const handleSetSpecialAttentionItemsArray = (newSaItems: SaItem[]) => {
    setSpecialAttentionItemsArray(newSaItems)
    localStorage.setItem(Sa_items_LS, JSON.stringify(newSaItems))
  }

  const linkedItem = useMemo<SaItem | undefined>(() =>
      specialAttentionItemsArray?.find((saItem: SaItem) =>
        saItem.bagCode && saItem.itemCode === currentItem?.code,
      )
    , [specialAttentionItemsArray, currentItem?.code])

  const isAllSaItemsLinked = useMemo<boolean>(() =>
      !(specialAttentionItemsArray?.filter((saItem: SaItem) =>
        saItem.bagCode && saItem.bagCode === bag?.bagCode && !saItem.itemCode,
      ).length)
    , [bag?.bagCode, specialAttentionItemsArray])


  const specialItemsInBag = useMemo<SaItem[]>(() =>
      specialAttentionItemsArray?.filter((saItem: SaItem) =>
        saItem.bagCode && saItem.bagCode === bag?.bagCode && !saItem.itemCode,
      ) || []
    , [bag?.bagCode, specialAttentionItemsArray])


  const linkItemToSpecialAttentionItem = async (specialAttentionItemId: string) => {
    await linkItemToSaItem(
      { itemRfid: currentItem?.rfid || '', specialAttentionItemId },
      {
        onSuccess: () => handleSetSpecialAttentionItemsArray(linkSaItem(specialAttentionItemId)),
        onSettled: refetchBag,
      },
    )
  }

  const unlinkItemToSpecialAttentionItem = async () => {
    if (!linkedItem) return;
    await unlinkItemToSaItem(
      { itemRfid: currentItem?.rfid || '', specialAttentionItemId: linkedItem.id },
      {
        onSuccess: () => handleSetSpecialAttentionItemsArray(unlinkSaItem(linkedItem.id)),
        onSettled: refetchBag,
      },
    )
  }


  const linkSpecialAttentionItemToBag = async (specialAttentionItemId: string) => {
    await linkSaItemToBag(
      { bagCode: bag?.bagCode || '', specialAttentionItemId },
      {
        onSuccess: () => handleSetSpecialAttentionItemsArray(markAsInBag(specialAttentionItemId)),
        onSettled: refetchBag,
      },
    )
  }

  const unlinkSpecialAttentionItemFromBag = async (specialAttentionItemId: string) => {
    if (!specialAttentionItemsArray) return
    const itemIndex = specialAttentionItemsArray?.findIndex((item: SaItem) => item.id === specialAttentionItemId);
    if (!specialAttentionItemsArray?.[itemIndex].bagCode) {
      handleSetSpecialAttentionItemsArray(markAsNotInBag(specialAttentionItemId))
      return
    }

    await unlinkSaItemToBag(
      { bagCode: bag?.bagCode || '', specialAttentionItemId },
      { onSuccess: () => handleSetSpecialAttentionItemsArray(markAsNotInBag(specialAttentionItemId)) },
    )
  }


  const linkSaItem = (saItemId: string) =>
    specialAttentionItemsArray?.map((saItem: SaItem) =>
      saItem.id === saItemId ? { ...saItem, itemCode: currentItem?.code } : saItem,
    ) || []

  const unlinkSaItem = (saItemId: string) =>
    specialAttentionItemsArray?.map(({ itemCode, ...saItem }: SaItem) =>
      saItem.id === saItemId ? saItem : { ...saItem, itemCode },
    ) || []

  const markAsInBag = (saItemId: string) =>
    specialAttentionItemsArray?.map(({ notInBag, ...saItem }: SaItem) =>
      saItem.id === saItemId ? { ...saItem, bagCode: bag?.bagCode } : { ...saItem, notInBag },
    ) || []


  const markAsNotInBag = (saItemId: string) =>
    specialAttentionItemsArray?.map(({ bagCode, ...saItem }: SaItem) =>
      saItem.id === saItemId ? { ...saItem, notInBag: true } : { ...saItem, bagCode },
    ) || []


  const cleanUp = () => {
    localStorage.removeItem(Sa_items_LS)
  }

  return {
    hasSpecialAttentionItems: !!specialAttentionItemsArray?.length,
    specialAttentionItemsList: specialAttentionItemsArray || [],
    linkedItem, isAllSaItemsLinked, specialItemsInBag,
    linkSpecialAttentionItemToBag,
    unlinkSpecialAttentionItemFromBag,
    unlinkItemToSpecialAttentionItem,
    linkItemToSpecialAttentionItem,
    cleanUp,
  }

}

export default useSaItems;





