import moment from 'moment';
import React, { useMemo } from 'react';
import { Calendar, CalendarProps } from 'react-date-range';

import DateWrapper, { formatDateToDisplay, IDateComponentProps } from './dateWrapper';

const DateSelector: React.FC<React.PropsWithChildren<IDateComponentProps>> = ({
  selectedDates,
  onDateSelect = () => {},
  onFocus = () => {},
  minDate,
  maxDate,
  title,
  field: { onChange, value, ...field } = {},
}) => {
  const handleDateSelect: CalendarProps['onChange'] = (date) => {
    const start = moment(date).startOf('D').toDate();
    if (start) {
      onChange && onChange({ start, end: start });
      onDateSelect({ start, end: start });
    }
  };

  const startDate = useMemo<Date>(() => (value ? value.start : selectedDates?.start) || new Date(), [selectedDates, value]);
  const endDate = useMemo<Date>(() => (value ? value.end : selectedDates?.end) || new Date(), [selectedDates, value]);

  const formattedDates = useMemo(() => formatDateToDisplay(startDate, endDate), [startDate, endDate]);
  return (
    <DateWrapper title={formattedDates ? formattedDates : title} onFocus={onFocus}>
      {/* //MIGRATION:: react-date-range is still using the old version of react, we need to think of migrating to a better library
      @ts-ignore  */}
      <Calendar {...field} date={startDate} onChange={handleDateSelect} minDate={minDate} maxDate={maxDate} />
    </DateWrapper>
  );
};

export default DateSelector;
