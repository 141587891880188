import React from 'react';
import { BagTypes } from 'utils/enums/stationEnums';
import { OrderServiceLines } from 'utils/interfaces';

import { ReactComponent as GreenBag } from 'assets/images/svgs/bags/laundry-bag.svg';
import { ReactComponent as OrangeBag } from 'assets/images/svgs/bags/shoes-bag.svg';

export const ServiceLinesForBagsMap: { [key in OrderServiceLines]: BagTypes[] } = {
  laundry: [BagTypes.CLEAN_PRESS, BagTypes.HOME_CARE, BagTypes.PRESS, BagTypes.WASH_FOLD],
  shoes: [BagTypes.SHOE_CLEANING],
  finery: [BagTypes.FINERY],
};

export const ServiceLineIcons: { [key in OrderServiceLines]?: JSX.Element } = {
  laundry: <GreenBag />,
  shoes: <OrangeBag />,
};

export const InterchangeableServiceLines = [[OrderServiceLines.LAUNDRY, OrderServiceLines.FINERY]];
