import { createContext, useContext } from 'react';
//TODO: Remove when migrating react-scripts and mobx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { observable, action, computed } from 'mobx';

import { IOrderItem } from '../utils/interfaces/stationInterfaces';
import { OrderItem } from '../utils/classes';

export class SorterStore {
  //FIXME : Client State
  @observable item: IOrderItem = {} as IOrderItem;
  @observable items: IOrderItem[] = [];
  //FIXME : Client State for BAG SCAN ONLY - Please refer to me (Amjad) before working on this, we need to figure this out together
  @observable private _currentItem: IOrderItem = new OrderItem();
  @observable private _bagScanTimeStamp: Date | null = null;

  // Order Already Completed Dialog flag
  @observable orderAlreadyCompletedDialog: IOrderItem = {} as IOrderItem;

  focusInput$ = observable.box(true, { deep: false });

  @computed
  get bagScanTimeStamp() {
    return this._bagScanTimeStamp;
  }

  set bagScanTimeStamp(value: Date | null) {
    localStorage.setItem('bagScanTimeStamp', String(value));
    this._bagScanTimeStamp = value;
  }

  @computed.struct
  get currentItem() {
    return this._currentItem;
  }

  constructor() {
    const scanTime = localStorage.getItem('bagScanTimeStamp');
    if (scanTime) {
      this.bagScanTimeStamp = new Date(scanTime);
    }
  }

  //TODO : Client State
  @action.bound
  setCurrentItem(item: IOrderItem) {
    this._currentItem = { ...item };
  }

  @action.bound
  setOrderAlreadyCompletedDialog(item: IOrderItem) {
    this.orderAlreadyCompletedDialog = { ...item };
  }

  @action.bound
  setOrderItem(item: IOrderItem) {
    this.item = item;
  }

  @action.bound
  clearData = () => {
    this.items = [];
    this.setCurrentItem(new OrderItem());
    this.item = new OrderItem();
  };

  @action.bound
  setItems(items: IOrderItem[]) {
    this.items = items;
  }

  @action.bound
  setBagScanTimeStamp(value: Date | null) {
    this.bagScanTimeStamp = value;
  }

  // finish a bag and clear related data from store and local storage
  @action.bound
  handleFinishBag() {
    // TODO: clear LS for speaci attention ITems list
    this.bagScanTimeStamp = null;
    localStorage.removeItem('bagScanTimeStamp');
  }
}

const sorterStore = new SorterStore();
export { sorterStore };

const SorterStoreContext = createContext(sorterStore);
export const useSorterStore = () => useContext(SorterStoreContext);
