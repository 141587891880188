import React from 'react';
import classNames from 'classnames';
/** Import Enums */
import { SorterSteps } from 'utils/enums/stationEnums';
/** Material UI */
import { makeStyles } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';

/** Import SVG Icons */
import { ReactComponent as TypeIcon } from 'assets/images/svgs/stepperIcons/svg/type-border.svg';
import { ReactComponent as BrandIcon } from 'assets/images/svgs/stepperIcons/svg/brand-border.svg';
import { ReactComponent as ColorIcon } from 'assets/images/svgs/stepperIcons/svg/color-border.svg';
import { ReactComponent as ProgramsIcon } from 'assets/images/svgs/stepperIcons/svg/programs-border.svg';
import { ReactComponent as PhotoIcon } from 'assets/images/svgs/stepperIcons/svg/photo-border.svg';
import { ReactComponent as StainDamageIcon } from 'assets/images/svgs/stepperIcons/svg/stainDamage-border.svg';
import { ReactComponent as CheckIcon } from 'assets/images/svgs/stepperIcons/svg/check-border.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/svgs/stepperIcons/svg/error-border.svg';
import { ReactComponent as DelicateBrand } from 'assets/images/svgs/stepperIcons/svg/delicate-brand.svg';

interface Props {
  name: SorterSteps;
  active?: boolean;
  completed?: boolean;
  disabled?: boolean;
  error?: boolean;
  isDelicate?: boolean;
  onToggleClick?: (name: SorterSteps) => any;
}

const defaultProps = {
  active: false,
  completed: false,
  disabled: false,
  error: false,
  isDelicate: false,
  onToggleClick: (name: SorterSteps) => console.log('No click handler'),
};

const useStepButtonStyle = makeStyles((theme) => ({
  button: {
    display: 'flex',
    borderRadius: '50%',
  },
  imageSize: {
    [theme.breakpoints.down('md')]: {
      width: '50px',
      height: '50px',
    },
    [theme.breakpoints.only('lg')]: {
      width: '90px',
      height: '90px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '120px',
      height: '120px',
    },
  },
  checkIcon: {
    position: 'absolute',
    [theme.breakpoints.down('md')]: {
      top: '-25px',
      right: '-5px',
      width: '20px',
    },
    [theme.breakpoints.only('lg')]: {
      top: '-25px',
      right: '-5px',
      width: '40px',
    },
    [theme.breakpoints.up('xl')]: {
      top: '-20px',
      right: '-5px',
      width: '40px',
    },
  },
}));

/** Image Mapper */
const iconNameMap: { [key: string]: JSX.Element } = {
  type: <TypeIcon />,
  brand: <BrandIcon />,
  color: <ColorIcon />,
  programs: <ProgramsIcon />,
  photo: <PhotoIcon />,
  stainDamage: <StainDamageIcon />,
};

const CustomStepperIcon: React.FC<Props> = ({
  name,
  active = defaultProps.active,
  completed = defaultProps.completed,
  disabled = defaultProps.disabled,
  error = defaultProps.error,
  isDelicate = defaultProps.isDelicate,
  onToggleClick = defaultProps.onToggleClick,
}) => {
  //Import Styles
  const classes = useStepButtonStyle();
  // Handle onClick action
  const toggleHandler = () => {
    onToggleClick(name);
  };

  // Manipulate SVG with the isActive Flag
  let elementWithClassName: JSX.Element = <></>;
  // Handle Error Step
  elementWithClassName = error ? <ErrorIcon /> : iconNameMap[name];
  // Handle Delicate Brand Step
  if (name === 'brand' && isDelicate) {
    elementWithClassName = <DelicateBrand />;
  }
  // Assign styling
  elementWithClassName = React.cloneElement(elementWithClassName, {
    className: classNames(
      classes.imageSize,
      { 'active-stepper-icon': (active || completed) && name !== 'stainDamage' && !error && !isDelicate && !disabled },
      { 'stain-stepper-icon': (name === 'stainDamage' || !error) && !isDelicate && !disabled },
      { 'disabled-delicate-brand': isDelicate && disabled }
    ),
  });

  // Manipulate the Check SVG
  let checkElementWithClass: JSX.Element = <CheckIcon />;
  checkElementWithClass = React.cloneElement(checkElementWithClass, {
    className: classNames(classes.checkIcon, { 'active-check-mark': !error }, { 'active-check-mark-error': error }),
  });

  return (
    <ButtonBase className={classes.button} onClick={toggleHandler} disabled={disabled} focusRipple>
      {elementWithClassName}
      {completed && checkElementWithClass}
    </ButtonBase>
  );
};

export default CustomStepperIcon;
