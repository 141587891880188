import React from 'react';

import CheckIcon from 'assets/images/pngs/circular-check.png';

import { ColorsList } from 'utils/interfaces/stationInterfaces';
/** Imports Material UI */
import makeStyles from '@material-ui/core/styles/makeStyles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Box from '@material-ui/core/Box';

const useColorIconStyle = makeStyles((theme) => ({
  root: {
    position: 'relative',
    margin: '10px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '70px',
      height: '80px',
    },
    [theme.breakpoints.only('md')]: {
      maxWidth: '80px',
      height: '100px',
    },
    [theme.breakpoints.only('lg')]: {
      maxWidth: '100px',
      height: '120px',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '140px',
      height: '140px',
    },
  },
  buttonBase: {
    borderRadius: '50%',
  },
  colorCircle: {
    [theme.breakpoints.down('sm')]: {
      width: '60px',
      height: '60px',
    },
    [theme.breakpoints.only('md')]: {
      width: '80px',
      height: '80px',
    },
    [theme.breakpoints.only('lg')]: {
      width: '100px',
      height: '100px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '130px',
      height: '130px',
    },
    borderRadius: '50%',
    border: '1px solid #b6bcd1',
  },
  isActiveIcon: {
    [theme.breakpoints.down('sm')]: {
      width: '30px',
    },
    [theme.breakpoints.only('md')]: {
      width: '35px',
    },
    [theme.breakpoints.only('lg')]: {
      width: '40px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '50px',
    },
    position: 'absolute',
    top: 0,
    right: 0,
  },
}));

type IColor = {
  [key in colorNames]: ColorsList;
};

export const colorMapper: IColor = {
  // First Row
  black: { hex: '#000000', name: 'Black' },
  white: { hex: '#FFFFFF', name: 'White' },
  gray: { hex: '#7A7878', name: 'Gray' },
  silver: { hex: '#C0C0C0', name: 'Silver' },
  brown: { hex: '#795548', name: 'Brown' },
  beige: { hex: '#E1C699', name: 'Beige' },
  burgundy: { hex: '#800020', name: 'Burgundy' },
  red: { hex: '#E51C23', name: 'Red' },
  gold: { hex: '#CFB53B', name: 'Gold' },
  light_yellow: { hex: '#eee8aa', name: 'Light Yellow' },

  // Second Row
  navy: { hex: '#020265', name: 'Navy' },
  blue: { hex: '#03A9F4', name: 'Blue' },
  medium_blue: { hex: '#2246d8', name: 'Medium Blue' },
  cyan: { hex: '#00FFFF', name: 'Cyan' },
  light_blue: { hex: '#b0e0e6', name: 'Light Blue' },
  olive: { hex: '#5a7332', name: 'Olive' },
  green: { hex: '#3CB371', name: 'Green' },
  aqua: { hex: '#66cdbc', name: 'Aqua' },

  // Third Row
  light_green: { hex: '#98FB98', name: 'Light Green' },
  orange: { hex: '#FF5722', name: 'Orange' },
  salmon: { hex: '#ffa07a', name: 'Salmon' },
  yellow: { hex: '#FFE64B', name: 'Yellow' },
  pink: { hex: '#E91E63', name: 'Pink' },
  fuchsia: { hex: '#FF00FF', name: 'Fuchsia' },
  light_pink: { hex: '#f1b9bc', name: 'Light Pink' },
  purple: { hex: '#673AB7', name: 'Purple' },
  light_purple: { hex: '#ccb0e3', name: 'Light Purple' },
};

export type colorNames =
  | 'black'
  | 'white'
  | 'gray'
  | 'silver'
  | 'brown'
  | 'beige'
  | 'light_green'
  | 'salmon'
  | 'light_yellow'
  | 'gold'
  | 'burgundy'
  | 'red'
  | 'navy'
  | 'blue'
  | 'green'
  | 'olive'
  | 'medium_blue'
  | 'aqua'
  | 'pink'
  | 'orange'
  | 'cyan'
  | 'fuchsia'
  | 'yellow'
  | 'purple'
  | 'light_blue'
  | 'light_pink'
  | 'light_purple';

interface Props {
  colorName: colorNames;
  isActive?: boolean;
  onToggleClick?: (color: ColorsList) => void;
}

const defaultProps = {
  isActive: false,
  onToggleClick: (color: ColorsList) => console.log('No Click Handler'),
};

const ColorIconSelector: React.FC<Props> = ({ isActive = defaultProps.isActive, colorName, onToggleClick = defaultProps.onToggleClick }) => {
  // Import Styles
  const classes = useColorIconStyle();
  return (
    <Box display="flex" className={classes.root} alignItems="center">
      <ButtonBase focusRipple className={classes.buttonBase} onClick={() => onToggleClick(colorMapper[colorName])}>
        <Box display="flex" bgcolor={colorMapper[colorName].hex} className={classes.colorCircle} />
      </ButtonBase>
      {isActive ? <img src={CheckIcon} alt="Washmen Icon" className={classes.isActiveIcon} /> : null}
    </Box>
  );
};

export default ColorIconSelector;
