import { Chip } from '@material-ui/core';
import styled from 'styled-components';

const StyledChip = styled(Chip)<{ width?: string; height?: string; borderRadius?: string; margin?: string; fontSize?: string }>`
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
  border-radius: ${(props) => props.borderRadius || '5px'};
  margin: ${(props) => props.margin || '0'};
  font-size: ${(props) => props.fontSize || '14px'};
`;
export { StyledChip };
