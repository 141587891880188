import { IOrderItem, SaItem } from 'utils/interfaces';

export interface ITypeMapper {
  [key: string]: IOrderItem[];
}

/**
 * @description Creates an object with the type as key and itemsList as value
 *
 * @param {IOrderItem} itemsList
 *
 * @example typeMapperHelper(items)
 */
export const typeMapperHelper = (itemsList: IOrderItem[]): ITypeMapper => {
  // TODO: Fix
  // eslint-disable-next-line array-callback-return
  return itemsList.reduce((acc: any, currentValue: IOrderItem) => {
    const { type } = currentValue;
    if (type) {
      if (acc[type]) {
        // Sort the items according to the isPackaged flag (Packaged item first)
        return { ...acc, [type]: [...acc[type], currentValue].sort((itemA: IOrderItem) => (Boolean(itemA.isPackaged) ? -1 : 1)) };
      }

      return { ...acc, [type]: [currentValue] };
    }
  }, {});
};

export const getShoeSize = (
  shoeSize: IOrderItem['shoeSize'],
  options: { sizeLabel?: boolean; defaultMessage?: boolean } = { sizeLabel: true, defaultMessage: true }
) => {
  const { sizeLabel, defaultMessage } = options;
  if (shoeSize?.euSize) return `${shoeSize?.euSize}${sizeLabel ? ' EU' : ''}`;
  if (shoeSize?.ukSize) return `${shoeSize?.ukSize}${sizeLabel ? ' UK' : ''}`;
  if (shoeSize?.handbagSize) return `${shoeSize?.handbagSize}`;
  return defaultMessage ? 'No Size' : '';
};



export const isItemLinked = (saItemList: SaItem[], itemCode: string) => {
  if (!saItemList) return false;
  return !!saItemList.find((item: SaItem) => item.bagCode && item.itemCode === itemCode);
};