import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, ButtonBase, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import KeyIcon from '@material-ui/icons/VpnKey';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import moment from 'moment';
import styled from 'styled-components';

import { useAuthStore, useSorterStore, useStationStore } from 'stores';
import { useActivityState } from 'stores/activityStore';
import { useFoldingStore } from 'stores/foldingStore';
import BagTypeSelector from './bagTypeSelector';
import UserActivityCount from 'modules/stations/components/userActivityCount';
import BagActivityCount from 'modules/stations/components/bagActivityCount';
import { BagTypes, StationTypes } from 'utils/enums/stationEnums';
import { ValidateManagerDialog } from '../dialogs';

import WashmenLogo from 'assets/images/pngs/logo.png';
import { ReactComponent as ItemsInBagSVG } from 'assets/images/bagIcon/itemsInBag.svg';
import { toast } from 'react-toastify';
import { Toaster } from '../toaster';
import { useNavigate } from 'react-router-dom';
import { BackgroundLoaderComponent } from 'components/loaderComponent';

interface Props {
  itemLength: number;
  bagScanTime: Date | null;
}

const AverageTimerPerItem: React.FC<Props> = ({ itemLength, bagScanTime }) => {
  const [time, setTime] = useState<number>();
  useEffect(() => {
    if (!bagScanTime || itemLength === 0) {
      return;
    }
    const today = moment();
    const timeAtScan = moment(bagScanTime.toISOString());
    const duration = moment.duration(today.diff(timeAtScan));
    setTime(duration.asSeconds() / itemLength);
  }, [itemLength, bagScanTime]);
  return (
    <>
      {itemLength > 0 && time && (
        <Typography style={{ alignSelf: 'center', borderLeft: '1px solid white', marginLeft: '1.5rem', padding: '0 1rem' }}>
          {Math.trunc(time)} seconds / item
        </Typography>
      )}
    </>
  );
};

/** --------- Station configs --------- */
const StationsWithBagTypeSelector = [
  StationTypes.Tagger,
  StationTypes.Sorter,
  StationTypes.ShoesSorter,
  StationTypes.ShoesTagger,
  StationTypes.FinerySorter,
  StationTypes.FineryTagger,
];

const StationsWithBagTypeEdit = [StationTypes.Tagger, StationTypes.ShoesSorter, StationTypes.ShoesTagger, StationTypes.FineryTagger];

const StationsWithScanAverage = [StationTypes.Sorter, StationTypes.ShoesSorter, StationTypes.FinerySorter];

const StationsWithBagActivityCount = [StationTypes.Grouping, StationTypes.FineryGrouping];

const StationsWithUserActivityCount = [
  StationTypes.Tagger,
  StationTypes.FineryTagger,
  StationTypes.ShoesTagger,
  StationTypes.Sorter,
  StationTypes.ShirtPressing,
  StationTypes.MultiPressing,
  StationTypes.PantPressing,
  StationTypes.UtilityPressing,
  StationTypes.CollarAndCuffPressing,
  StationTypes.FineryPressing,
  StationTypes.FineryTailorPost,
  StationTypes.FineryTailorPre,
  StationTypes.FinerySpotter,
  StationTypes.FineryDrying,
  StationTypes.FineryCleaning,
  StationTypes.PantsTopper,
  StationTypes.ShoesSorter,
  StationTypes.ShoesBeforeAfter,
  StationTypes.FinerySorter,
];
/** --------- end config --------- */
const HeaderBar: React.FC = observer(() => {
  const { logout, isLoggedIn, resetSystem, userName } = useAuthStore();
  const { bag, updateBagType, stationType, bagScanTimeStamp, items, isValidBagForOrder, stationServiceLine } = useStationStore();
  const { items: newSorterStoreItems } = useSorterStore();
  const { itemsInBag, orderItem, setIsItemsListOpen } = useFoldingStore();

  const [date, setDate] = React.useState(new Date());
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { activityCleanup } = useActivityState();
  const navigate = useNavigate();

  function handleMenu(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleLogout() {
    logout();
    handleClose();
  }

  function handleClose() {
    setAnchorEl(null);
  }

  useEffect(() => {
    const dateInterval = setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => {
      clearInterval(dateInterval);
    };
  }, []);

  async function selectBagTypeHandler(bagType: BagTypes) {
    await updateBagType(bagType);
    const isValidServiceForBag = isValidBagForOrder(bagType);

    if (!isValidServiceForBag) {
      toast(<Toaster message={'Wrong service line for editing the bag'} type="error" />);
      navigate('../', { relative: 'path' });
    }
  }

  const reset = () => {
    resetSystem();
    activityCleanup();
  };

  function handleItemsInBagEvent() {
    setIsItemsListOpen();
  }

  /**
   * Show the items In Bag button if all of them are valid:
   * - On the folding station / Linen Folding
   * - Items In Bag is not empty
   * - Order Item contains an item (Folding Store)
   */
  const itemsInBagCondition = useMemo(
    () =>
      [StationTypes.Folding, StationTypes.FoldingLinen, StationTypes.ShoesFolding].includes(stationType) && itemsInBag.length > 0 && !!orderItem.code,
    [stationType, itemsInBag.length, orderItem.code]
  );

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Box display="flex" flexGrow={1}>
            <img width="160px" src={WashmenLogo} alt="washmen logo" />
            {StationsWithBagTypeSelector.includes(stationType) && (
              <BagTypeSelector
                stationServiceLine={stationServiceLine}
                bag={bag}
                onTypeSelect={selectBagTypeHandler}
                disabled={!StationsWithBagTypeEdit.includes(stationType)}
              />
            )}
            {StationsWithScanAverage.includes(stationType) && (
              <AverageTimerPerItem bagScanTime={bagScanTimeStamp} itemLength={items.length || newSorterStoreItems.length} />
            )}
            {itemsInBagCondition && (
              <ButtonBase focusRipple style={{ margin: '0 1rem', borderRadius: '50px' }} onClick={handleItemsInBagEvent}>
                <ItemInBagWrapper>
                  <ItemsInBagSVG />
                  <Box paddingLeft="1rem">Items In Bag</Box>
                </ItemInBagWrapper>
              </ButtonBase>
            )}
          </Box>
          {StationsWithUserActivityCount.includes(stationType) && <UserActivityCount />}
          {StationsWithBagActivityCount.includes(stationType) && <BagActivityCount />}
          <BackgroundLoaderComponent />
          {!isLoggedIn && (
            <div>
              <IconButton aria-label="log system out" aria-controls="menu-appbar" aria-haspopup="true" onClick={reset} color="inherit">
                <KeyIcon />
              </IconButton>
            </div>
          )}
          <div>
            {isLoggedIn ? (
              <Button aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
                <div> {userName}</div>
              </Button>
            ) : (
              <IconButton aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
                <AccountCircle />
              </IconButton>
            )}
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              {isLoggedIn && <MenuItem onClick={handleLogout}>Logout</MenuItem>}
              <MenuItem onClick={() => window.location.reload()}>Update</MenuItem>
              <MenuItem onClick={handleClose}>Help</MenuItem>
            </Menu>
          </div>
          <div> &nbsp;&nbsp; | &nbsp;&nbsp; {date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
        </Toolbar>
      </AppBar>
      <ValidateManagerDialog
        open={isModalOpen}
        autoValidate
        onManagerScan={() => {
          setIsModalOpen(false);
          resetSystem();
        }}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
});

export default HeaderBar;

const ItemInBagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
