import { useMutation } from 'react-query';
import stationService from 'services/station.service';
import { IOrderItem } from 'utils/interfaces';

interface CreateOptions {
  rfid: string;
  item?: {} | IOrderItem;
}

const useCreateNewItem = () => {
  return useMutation<IOrderItem, Error, CreateOptions>(({ rfid, item }) => stationService.createNewItem(rfid, item), {
    onSuccess: () => { },
    onError: () => { },
  });
};

export default useCreateNewItem;
