import React, { useEffect } from 'react';
import './style.scss';
import { FormattedMessage } from 'react-intl';
/** Import Enums */
import { SorterSteps } from 'utils/enums/stationEnums';
import { StepperKeys } from './contentMapper';
/** Custom Components */
import CustomStepperIcon from './customStepperIcons';
import ContentMapperComponent, { contentMapper } from './contentMapper';
/** Material UI */
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import StepLabel from '@material-ui/core/StepLabel';
import Step from '@material-ui/core/Step';
import Stepper from '@material-ui/core/Stepper';
import StepConnector from '@material-ui/core/StepConnector';

/** Import Store */
import { useStationStore } from 'stores';
import { observer } from 'mobx-react-lite';

/** Connector Styles */
const ConnectorStyle = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage: 'linear-gradient( 95deg,rgb(128,137,223) 0%,rgb(32,46,184) 50%,rgb(128,137,223) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage: 'linear-gradient( 95deg,rgb(128,137,223) 0%,rgb(32,46,184) 50%,rgb(18,30,148) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#ccc',
    borderRadius: 1,
  },
})(StepConnector);

/** Custom Stepper Style */
const useStepperStyle = makeStyles(theme => ({
  root: {
    width: '100%',
    [theme.breakpoints.only('lg')]: {
      marginTop: '20px',
    },
    [theme.breakpoints.up('xl')]: {
      marginTop: '50px',
    },
  },
  stepperStyle: {
    padding: '24px 24px 0 0',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  steps: {
    completed: {
      color: 'lightgreen',
    },
  },
  contentBox: {
    flexGrow: 1,
    marginTop: '40px',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

  label: {
    fontWeight: 'lighter',
    [theme.breakpoints.only('lg')]: {
      paddingTop: '20px',
      fontSize: '25px',
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: '50px',
      fontSize: '30px',
    },
  },
}));

const useStepLabelStyle = makeStyles({
  completed: {
    color: '#36479a !important',
  },
  error: {
    color: '#ca3232 !important',
  },
});

/** Custom Stepper *Icon* Styles */
const useStepIconStyles = makeStyles({
  root: {
    zIndex: 1,
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

/** Define StepIcons Props */
interface StepIconProps {
  icon: number;
  active: boolean;
  completed: boolean;
  error: boolean;
  disabled?: boolean;
  onClick: (name: SorterSteps) => any;
}
/** Step Icons  */
const ColorlibStepIcon: React.FC<StepIconProps> = ({ icon, active, completed, error, onClick, disabled = false }) => {
  // Import the Root Class
  const classes = useStepIconStyles();
  const { currentItem } = useStationStore();

  let isDisabled: boolean = active ? false : completed ? false : true;

  // Map the icon Number with the Icon Name
  const iconsName: any = {
    1: 'type',
    2: 'brand',
    3: 'color',
    4: 'programs',
    5: 'photo',
    6: 'stainDamage',
  };
  // If recurrent Item, disable the type and brand buttons
  if (currentItem && currentItem.isRecurrent) {
    if (icon === 1 || icon === 2) {
      isDisabled = true;
    }
    if (icon === 3 || icon === 4 || icon === 5 || icon === 6) {
      isDisabled = false;
    }
  }

  // Check if the brand is delicate
  let isDelicate: boolean = Boolean(currentItem) && currentItem.brand && currentItem.brand.Delicate && icon === 2 ? true : false;

  // Return the correct Icon State
  return (
    <div className={classes.root}>
      <CustomStepperIcon
        name={iconsName[icon]}
        active={active}
        disabled={disabled || isDisabled}
        completed={completed}
        error={error}
        isDelicate={isDelicate}
        onToggleClick={(name: SorterSteps) => onClick(name)}
      />
    </div>
  );
};

/** Get the Steps Lable */
const getSteps = () => {
  return [
    'station.sorter.stepper.label.type',
    'station.sorter.stepper.label.brand',
    'station.sorter.stepper.label.color',
    'station.sorter.stepper.label.programs',
    'station.sorter.stepper.label.photo',
    'station.sorter.stepper.label.stainDamage',
  ];
};

/** Define props interface */
interface Props {
  initialStep?: number;
  disabledSteps?: number[];
  onStepClick?: (name: SorterSteps) => void;
}

/** Define Default Props */
const defaultProps = {
  initialStep: 0,
  onStepClick: (name: SorterSteps) => console.log('No click Handler on Stepper Widget'),
};

const CustomStepper: React.FC<Props> = observer(
  ({ initialStep = defaultProps.initialStep, onStepClick = defaultProps.onStepClick, disabledSteps = [] }) => {
    const classes = useStepperStyle();
    const stepLabelClasses = useStepLabelStyle();
    const { currentItem } = useStationStore();

    const [activeStep, setActiveStep] = React.useState(initialStep);
    const steps = getSteps();

    useEffect(() => {
      setActiveStep(0);
      if (currentItem.type) {
        setActiveStep(1);
      }
      if (currentItem.brand) {
        setActiveStep(2);
      }
      if (currentItem.colorsList) {
        setActiveStep(3);
      }
      if (currentItem.programs) {
        setActiveStep(4);
      }
      if (currentItem.frontImage) {
        setActiveStep(5);
      }
      if (currentItem.reportDetailsList && currentItem.reportDetailsList.length > 0) {
        setActiveStep(6);
      }
    }, [currentItem]);

    return (
      <div className={classes.root}>
        <Stepper alternativeLabel activeStep={activeStep} connector={<ConnectorStyle />} className={classes.stepperStyle}>
          {steps.map((i18Key, index) => (
            <Step key={i18Key}>
              <StepLabel
                classes={{ completed: stepLabelClasses.completed, error: stepLabelClasses.error }}
                StepIconComponent={props => (
                  <ColorlibStepIcon {...props} onClick={(name: SorterSteps) => onStepClick(name)} disabled={disabledSteps.includes(index + 1)} />
                )}
              >
                <div className={classes.label}>
                  <FormattedMessage id={i18Key} defaultMessage={i18Key} />
                </div>
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box width="100%" className={classes.contentBox}>
          <Grid container spacing={1} alignItems="center">
            {Object.keys(contentMapper).map((stepLabel: string, i: number) => {
              return <ContentMapperComponent key={`${stepLabel}-${i}`} itemProperty={stepLabel as StepperKeys} />;
            })}
          </Grid>
        </Box>
      </div>
    );
  }
);

export default CustomStepper;
