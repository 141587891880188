import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { StationTypes } from 'utils/enums/stationEnums';
import WidgetBox from '../widgetBox/widgetBox';

export interface item {
  value: string;
  text: string;
  i18nKey: string;
}

interface Props {
  items: item[];
  title: JSX.Element;
  onSelect: (value: string | StationTypes) => void;
  type?: 'number' | 'type' | 'section';
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(4),
    },
    button: {
      margin: `${theme.spacing(3)}px ${theme.spacing(5)}px`,
      padding: `${theme.spacing(3)}px ${theme.spacing(10)}px`,
      minWidth: theme.spacing(42),
    },
    input: {
      display: 'none',
    },
    btnsContainer: {
      marginTop: theme.spacing(8),
    },
  })
);

export const SelectStationItemList: React.FC<Props> = ({ items = [], title, onSelect, type }) => {
  const classes = useStyles();

  return (
    <WidgetBox>
      <Box textAlign="center">
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
        <Box
          display="flex"
          mx="auto"
          width="75%"
          flexWrap="wrap"
          flexGrow={1}
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          className={classes.btnsContainer}
        >
          {items.map((item, index) => (
            <Button
              key={`${type}-${index}`}
              variant="contained"
              className={classes.button}
              onClick={(event) => onSelect(item.value)}
              data-cy={item.value}
            >
              <FormattedMessage id={item.i18nKey} defaultMessage={item.text} />
            </Button>
          ))}
        </Box>
      </Box>
    </WidgetBox>
  );
};
