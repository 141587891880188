import React from 'react';
import styled from 'styled-components';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { IUserNote, NotesOriginApps } from 'utils/interfaces';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import startCase from 'lodash/startCase';

interface IProps {
  notesList: IUserNote[];
  onDelete?: (noteId: string | undefined) => void;
  imageSize?: string;
}

const NotesList: React.FC<IProps> = ({ notesList, onDelete, imageSize }) => {
  return (
    <List>
      {notesList.map((note) => (
        <div key={note.id}>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              {note.photo ? <CustomAvatar alt={note.photo} src={note.photo} variant="square" image_size={imageSize} /> : <></>}
            </ListItemAvatar>
            <ListItemText
              primary={
                <Box display="flex" flexDirection="row" style={{ gap: '0.5rem' }} alignItems={'center'}>
                  <Typography>{note.createdBy}&nbsp;&nbsp;</Typography>
                  <DateText>{moment(note.createdAt).format('dd DD MMM')}</DateText>
                  {note.originApp && <Chip label={startCase(note.originApp?.toLowerCase())} />}
                </Box>
              }
              secondary={<React.Fragment>{note.description}</React.Fragment>}
            />
            {onDelete && note.id && note.originApp !== NotesOriginApps.OPS && (
              <ListItemSecondaryAction>
                <Button onClick={() => onDelete(note.id)}>
                  <DeleteIcon />
                </Button>
              </ListItemSecondaryAction>
            )}
          </ListItem>
          <CustomDivider variant="middle" />
        </div>
      ))}
    </List>
  );
};
const CustomDivider = styled(Divider)`
  height: 0.2rem;
`;
const DateText = styled(Typography)`
  font-weight: lighter;
`;
const CustomAvatar = styled(Avatar)<{ image_size?: string }>`
  height: ${({ image_size }) => (image_size ? image_size : '8rem')};
  width: ${({ image_size }) => (image_size ? image_size : '8rem')};
`;

export default NotesList;
