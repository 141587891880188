import { useMutation } from 'react-query';
import { StationService } from 'services';
import { IOrderItemResponse } from 'utils/interfaces';

interface AddExistingToBagOptions {
  itemCode: string;
  bagCode: string;
}
const useAddExistingToBag = () => {
  return useMutation<IOrderItemResponse, Error, AddExistingToBagOptions>(({ itemCode, bagCode }) =>
    StationService.addExistingToBag(itemCode, bagCode)
  );
};
export default useAddExistingToBag;
