import { useQuery } from 'react-query';
import { StationService } from 'services';
import { IBrandsResponse } from 'utils/interfaces';

//FIXME: Remove this Array once top10Brands for shoes are live
const topBrands: IBrandsResponse[] = [
  {
    active: false,
    logo: 'adidas_gryL9Er.png',
    sort_key: 3827,
    name: 'Adidas',
    luxury: true,
    Delicate: false,
  },
  {
    active: false,
    logo: 'nike_mc2b2zD.png',
    sort_key: 3995,
    name: 'Nike',
    luxury: true,
    Delicate: false,
  },
  {
    active: false,
    logo: 'converse_JAqqU3k.png',
    sort_key: 64,
    name: 'Converse',
    luxury: true,
    Delicate: false,
  },
  {
    active: false,
    logo: 'colehaan_wkeG2Th.png',
    sort_key: 4,
    name: 'Cole Haan',
    luxury: true,
    Delicate: false,
  },
  {
    active: false,
    logo: 'gucci_nIU7VpN.png',
    sort_key: 1619,
    name: 'Gucci',
    luxury: true,
    Delicate: true,
  },
  {
    active: false,
    logo: 'lacoste_clQIVAI.png',
    sort_key: 9156,
    name: 'Lacoste',
    luxury: true,
    Delicate: false,
  },
  {
    active: false,
    logo: 'nobrand_jT8R2mB.png',
    sort_key: 3600,
    name: 'no brand,',
    luxury: true,
    Delicate: false,
  },
  {
    active: false,
    logo: 'loropiana_HfmisKJ.png',
    sort_key: 11,
    name: 'Loro Piana',
    luxury: true,
    Delicate: true,
  },
  {
    active: false,
    logo: 'puma_E4g8caK.png',
    sort_key: 636,
    name: 'Puma',
    luxury: true,
    Delicate: false,
  },
  {
    active: false,
    logo: 'vansanten_NFsXWKg.png',
    sort_key: 4,
    name: 'Van Santen',
    luxury: true,
    Delicate: false,
  },
  {
    active: false,
    logo: 'airjordan_vbLoN9X.png',
    sort_key: 4,
    name: 'Air Jordan',
    luxury: true,
    Delicate: false,
  },
  {
    active: false,
    logo: 'yeezy_gryL9Er.png',
    sort_key: 4,
    name: 'Yeezy',
    luxury: true,
    Delicate: false,
  },
];

const useGetBrands = (customerId: string, type: string, latestBrandSelected?: IBrandsResponse) => {
  // const { data: topBrands } =
  useQuery<IBrandsResponse[], Error>(
    ['top-brands', { customerId, type, latestBrandSelected }],
    () => StationService.fetchCustomerTopBrands(customerId, type),
    {
      initialData: [],
      //FIXME: Renable this query once top10Brands for shoes are live
      enabled: false,
      onSuccess: (topBrands) => {
        const brands = latestBrandSelected !== undefined ? [latestBrandSelected, ...topBrands] : [...topBrands];
        return {
          topBrands: brands.filter((brand, index) => {
            return brands.findIndex((b) => b.name === brand.name) === index;
          }),
        };
      },
    }
  );
  const { data: allBrands } = useQuery<IBrandsResponse[], Error>(['all-brands'], () => StationService.fetchAllBrands(), {
    initialData: [],
  });

  return { allBrands: allBrands || [], topBrands: topBrands || [] };
};

export default useGetBrands;
