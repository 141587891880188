import React, { useMemo, useState } from 'react';
import { IItemReportDetail } from 'utils/interfaces/stainManStationInterfaces';
import styled from 'styled-components';
import { Box, Button, Divider, Grid, Icon } from '@material-ui/core';
import { ReactComponent as StainIcon } from 'assets/images/svgs/stainAndDamage/stain-icon.svg';
import { ReactComponent as StainInstructionIcon } from 'assets/images/svgs/stainAndDamage/instructions-stain.svg';
import { ReactComponent as DamageInstructionIcon } from 'assets/images/svgs/stainAndDamage/instructions-damage.svg';
import { ReactComponent as RedSprayIcon } from 'assets/images/svgs/red-spray-icon.svg';
import { ReactComponent as ItemAtRiskIcon } from 'assets/images/svgs/stainmanIcons/item-at-risk.svg';
import { ReactComponent as CustomerApprovalIcon } from 'assets/images/svgs/stainmanIcons/customer-approval-red.svg';
import { ReactComponent as NotFixedIcon } from 'assets/images/svgs/stainmanIcons/cant-be-fixed-red.svg';

import { ReactComponent as BlueCheckmark } from 'assets/images/svgs/blue-checkmark.svg';
import { ReactComponent as FacilityIcon } from 'assets/images/svgs/facility-icon.svg';
import { ReactComponent as BlueWasherIcon } from 'assets/images/svgs/washer-blue.svg';

import { OrderStatuses } from 'utils/enums/stationEnums';
import { capitalizeString } from 'utils/helpers/string';
import { ImagePopup } from 'components/imagePopup';
import { ItemActions, ItemIssue } from 'utils/interfaces';
import ItemIssueModalOverview, { issueObj } from '../modals/itemIssueModalOverview';
import _ from 'lodash';
import { Toaster } from 'components/toaster';
import { toast } from 'react-toastify';
import { useSubmitNewIssue, useUpdateItemIssueStatus } from '../../hooks';
import { ItemIssueUpdateRequest } from '../../hooks/useUpdateItemIssueStatus';
import ReportIssuesModal from '../modals/reportIssuesModal';
import MeasurementSection from '../modals/MeasurementSection';

export interface IActionStainsCardProps {
  itemReportDetail: IItemReportDetail;
  openStainsDialog: (type: 'stain' | 'damage') => void;
  handleOnSubmitNewIssue: () => void;
  handleOnUpdateItemIssueStatus: () => void;
  isItemRejected: boolean;
}

export const issueMapper: { [key in ItemActions | '']?: issueObj } = {
  CLEANED: {
    icon: <BlueCheckmark />,
    isActioned: true,
    isRemoved: true,
  },
  SEND_BACK_TO_THE_SPOTTER: {
    icon: <RedSprayIcon />,
    isNotCleaned: true,
  },
  REWASH: {
    icon: <BlueWasherIcon />,
    isNotCleaned: true,
  },
  CANT_CLEAN: {
    icon: <NotFixedIcon />,
    isNotCleaned: true,
  },
  NEED_CUSTOMER_APPROVAL: {
    icon: <CustomerApprovalIcon />,
    isActioned: true,
    isCantBeCleaned: true,
    isNotCleaned: true,
  },
  ITEM_AT_RISK: {
    icon: <ItemAtRiskIcon className="item-at-risk" />,
    isActioned: true,
    isCantBeCleaned: true,
    isNotCleaned: true,
  },
  FIXED: {
    icon: <BlueCheckmark />,
    isRemoved: true,
  },
  NOT_FIXED: {
    icon: <NotFixedIcon />,
  },
  '': {
    icon: <></>,
  },
};

const FineryMiddleCard: React.FC<IActionStainsCardProps> = ({
  itemReportDetail,
  openStainsDialog,
  handleOnSubmitNewIssue,
  handleOnUpdateItemIssueStatus,
  isItemRejected,
}) => {
  const [openIssueModal, setOpenIssueModal] = useState<boolean>(false);
  const [openAddIssueModal, setOpenAddIssueModal] = useState(false);
  const [currentIssue, setCurrentIssue] = useState<ItemIssue>();

  const approvalStatus =
    itemReportDetail.approvalStatus && itemReportDetail.approvalOrigin
      ? `${capitalizeString(itemReportDetail.approvalStatus)} By ${capitalizeString(itemReportDetail.approvalOrigin)}`
      : '?';
  const { mutateAsync: addNewIssue } = useSubmitNewIssue(handleOnSubmitNewIssue);

  const { mutateAsync } = useUpdateItemIssueStatus(handleOnUpdateItemIssueStatus);

  async function updateItemIssue(itemAction: ItemActions | '', issueId: string) {
    const itemIssueUpdateReq: ItemIssueUpdateRequest = {
      itemCode: itemReportDetail.itemCode,
      orderId: itemReportDetail.orderId,
      orderAlphaId: itemReportDetail.orderAlphaId,
      customerAlphaId: itemReportDetail.customerAlphaId,
      approvalStatus: approvalStatus,
      numberOfScans: itemReportDetail.numberOfScans,
      action: itemAction,
      issueId: issueId,
      isActioned: issueMapper[itemAction]?.isActioned || false,
      isRemoved: issueMapper[itemAction]?.isRemoved || false,
      isNotCleaned: issueMapper[itemAction]?.isNotCleaned || false,
      isCantBeCleaned: issueMapper[itemAction]?.isCantBeCleaned || false,
    };
    await mutateAsync(itemIssueUpdateReq);
    if (itemAction === ItemActions.NOT_FIXED) {
      toast(<Toaster message={`Damage not fixed`} type="error" />);
      return;
    }
    if (issueMapper[itemAction]?.isNotCleaned) {
      toast(<Toaster message={`Stain not cleaned, ${_.lowerCase(itemIssueUpdateReq.action)}`} type="error" />);
    }
  }

  const submitNewIssue = async (issue: string) => {
    await addNewIssue({
      issueReason: issue,
      itemCode: itemReportDetail.itemCode,
      orderAlphaId: itemReportDetail.orderAlphaId,
    });
    toast(<Toaster message="New issue submitted" type="success" />);
  };

  const { stainedIssues, damagedIssues, isStainedAndDamaged } = useMemo(() => {
    const stainedIssues = itemReportDetail.itemIssues.filter((issue) => issue.reason === 'STAINED').sort((a, b) => a.action.localeCompare(b.action));
    const damagedIssues = itemReportDetail.itemIssues.filter((issue) => issue.reason === 'DAMAGED').sort((a, b) => a.action.localeCompare(b.action));
    const isStainedAndDamaged = stainedIssues.length !== 0 && damagedIssues.length !== 0;
    return { stainedIssues, damagedIssues, isStainedAndDamaged };
  }, [itemReportDetail.itemIssues]);

  function reportIssue(itemAction: ItemActions | '', detail: ItemIssue) {
    updateItemIssue(itemAction, detail.issueId);
    setCurrentIssue(detail);
  }

  function openIssuesModal(detail: ItemIssue) {
    setCurrentIssue(detail);
    setOpenIssueModal(true);
  }

  return (
    <Scrollable>
      <Box bgcolor="#e8e8ef">
        <MeasurementWrapper>
          <MeasurementSection itemReportDetail={itemReportDetail} />
        </MeasurementWrapper>
        <Wrapper>
          <Box display="flex" flex={1} flexDirection="row" alignItems="center" paddingY={'1rem'}>
            <Box display="flex" alignItems={'center'} flexDirection="row" flex={10}>
              <StainIcon width="4.5rem" height="4.5rem" />
              <Header>Action Issues</Header>
            </Box>
            <Box flex={3} paddingLeft={5}>
              <ReportButton
                disabled={itemReportDetail.orderStatus === OrderStatuses.ORDER_COMPLETE || !itemReportDetail.rfid || isItemRejected}
                variant="contained"
                onClick={() => setOpenAddIssueModal(true)}
                size="large"
                color="secondary"
              >
                Report Issue
              </ReportButton>
            </Box>
          </Box>
          <ActionBar
            approvalStatus={approvalStatus}
            isItemRejected={isItemRejected}
            itemIssues={stainedIssues}
            openIssueModal={openIssuesModal}
            reportIssue={reportIssue}
          />
          {isStainedAndDamaged && <SectionDivider />}
          <ActionBar
            approvalStatus={approvalStatus}
            isItemRejected={isItemRejected}
            itemIssues={damagedIssues}
            openIssueModal={openIssuesModal}
            reportIssue={reportIssue}
          />
          <ItemIssueModalOverview
            open={openIssueModal}
            actionItemIssue={(ItemAction) => {
              updateItemIssue(ItemAction, currentIssue ? currentIssue.issueId : '');
            }}
            toggle={() => setOpenIssueModal(false)}
          />
          <ReportIssuesModal
            submitNewIssue={submitNewIssue}
            openStainsDialog={openStainsDialog}
            onClose={() => setOpenAddIssueModal(false)}
            open={openAddIssueModal}
          />
        </Wrapper>
      </Box>
    </Scrollable>


  );
};

export interface ActionBarProps {
  itemIssues: ItemIssue[];
  isItemRejected: boolean;
  approvalStatus: string;
  reportIssue: (itemAction: ItemActions | '', detail: ItemIssue) => void;
  openIssueModal: (detail: ItemIssue) => void;
}

export const ActionBar: React.FC<ActionBarProps> = ({ itemIssues, isItemRejected, approvalStatus, reportIssue, openIssueModal }) => {
  return (
    <>
      {itemIssues.map((detail, index) => (
        <ActionWrapper key={index}>
          <Box display="flex" flexDirection="row">
            <ActionViewWrapper flex={3}>
              <Grid container direction="column">
                <Grid item alignContent={'flex-start'} alignItems="flex-start" justifyContent={'flex-start'}>
                  <ImagesWrapper>
                    <ImagePopup
                      index={index}
                      imageWidth="26rem"
                      customComponents={!isItemRejected ? reportImageIcons(detail) : undefined}
                      customTextNode={reportImageText(detail)}
                      imageUrl={detail.imageUrl}
                    />
                  </ImagesWrapper>
                </Grid>
                <Grid item>
                  <FacilityApprovalWrapper isRejected={isItemRejected}>
                    <FacilityIcon width={'1.25rem'} height={'1.25rem'} className="facility-icon" />
                    {` ${approvalStatus}`}
                  </FacilityApprovalWrapper>
                </Grid>
              </Grid>
            </ActionViewWrapper>
            <Box flex={4} paddingLeft={5}>
              {!isItemRejected && (
                <ReportButtons
                  detail={detail}
                  reportIssue={(itemAction) => reportIssue(itemAction, detail)}
                  openModal={() => openIssueModal(detail)}
                />
              )}
            </Box>
          </Box>
          {index !== itemIssues.length - 1 && <CustomDivider />}
        </ActionWrapper>
      ))}
    </>
  );
};

export interface ReportButtonProps {
  detail: ItemIssue;
  reportIssue: (itemAction: ItemActions | '') => void;
  openModal: () => void;
}

export const ReportButtons: React.FC<ReportButtonProps> = ({ detail, reportIssue, openModal }) => {
  return (
    <>
      {detail.reason === 'STAINED' ? (
        <ButtonWrapper>
          <Box padding={1}>
            <ActionButton
              onClick={() => reportIssue(detail.action === ItemActions.CLEANED ? '' : ItemActions.CLEANED)}
              color="primary"
              fullWidth
              variant={detail.action === ItemActions.CLEANED ? 'contained' : 'outlined'}
            >
              Cleaned
            </ActionButton>
          </Box>
          <Box padding={1}>
            <ActionButton
              onClick={() => (issueMapper[detail.action]?.isNotCleaned ? reportIssue('') : openModal())}
              color="secondary"
              fullWidth
              variant={issueMapper[detail.action]?.isNotCleaned ? 'contained' : 'outlined'}
            >{`Not Cleaned${issueMapper[detail.action]?.isNotCleaned ? `, ${_.upperCase(detail.action)}` : ''}`}</ActionButton>
          </Box>
        </ButtonWrapper>
      ) : (
        <>
          <ButtonWrapper>
            <Box padding={1}>
              <ActionButton
                onClick={() => reportIssue(detail.action === ItemActions.FIXED ? '' : ItemActions.FIXED)}
                color="primary"
                fullWidth
                variant={detail.action === ItemActions.FIXED || '' ? 'contained' : 'outlined'}
              >
                Fixed
              </ActionButton>
            </Box>
            <Box padding={1}>
              <ActionButton
                onClick={() => reportIssue(detail.action === ItemActions.NOT_FIXED ? '' : ItemActions.NOT_FIXED)}
                color="secondary"
                fullWidth
                variant={detail.action === ItemActions.NOT_FIXED || '' ? 'contained' : 'outlined'}
              >
                Not Fixed
              </ActionButton>
            </Box>
          </ButtonWrapper>
        </>
      )}
    </>
  );
};

export const reportImageText = (detail: ItemIssue) => {
  return (
    <ImageText>
      {capitalizeString(detail.location)}, {capitalizeString(detail.type)}
    </ImageText>
  );
};

export const reportImageIcons = (detail: ItemIssue) => {
  return (
    <>
      {detail.reason === 'STAINED' ? <CustomizedStainIcon width="100px" /> : <CustomizedDamageIcon width="100px" />}
      <StyledIcon>{issueMapper[detail.action]?.icon}</StyledIcon>
    </>
  );
};

export default FineryMiddleCard;


const ActionButton = styled(Button)`
  border-radius: 20px;
  border-width: 2px;
  font-size: 18px;
`;
const ImagesWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: start;
  justify-content: start;
`;
const ActionViewWrapper = styled(Box)`
  padding-left: 1.5rem;
`;
const Scrollable = styled.div`
  height: calc(100vh - 120px);
  overflow-y: auto;
`;
const ImageText = styled.span`
  padding: 0.5rem;
  line-height: 1.5;
  float: left;
`;
const Wrapper = styled(Box)`
  padding: 0 1rem;
  border: 1px solid #7d7c97;
  height: 100%;
  background: #ffffff;

`;
const MeasurementWrapper = styled(Box)`
  padding: 0 1rem;
  border: 1px solid #7d7c97;
  margin-bottom: 8px;
  background: #ffffff;
`;

const CustomDivider = styled(Divider)`
  border-bottom-width: 10px;
  border-color: #b6bcd1;
  margin-bottom: 1rem;
`;
const SectionDivider = styled(Divider)`
  border-bottom-width: 10px;
  border-color: #b6bcd1;
  margin-bottom: 1rem;
  padding-bottom: 1.2rem;
`;
const ActionWrapper = styled(Grid)`
  box-sizing: content-box;
`;
const ReportButton = styled(Button)`
  padding: 1.2rem;
`;
const ButtonWrapper = styled(Box)`
  padding: 1rem;
`;
const CustomizedStainIcon = styled(StainInstructionIcon)`
  position: absolute;
  top: 0;
  left: 10px;
`;
const CustomizedDamageIcon = styled(DamageInstructionIcon)`
  position: absolute;
  top: 0;
  left: 10px;
`;
const Header = styled.p`
  font-family: Ubuntu, serif;
  font-size: 25px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #111135;
  padding-left: 0.75rem;
  margin: 0;
`;
const FacilityApprovalWrapper = styled.p<{ isRejected?: boolean }>`
  height: 1rem;
  font-family: Ubuntu, serif;
  font-size: 20px;
  font-weight: 250;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${({ isRejected }) => (isRejected ? '#800000' : '#111135')};
`;
const StyledIcon = styled(Icon)<{ top?: string; right?: string }>`
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;

  svg {
    width: 3rem;
    height: 3rem;
  }

  .item-at-risk path:nth-child(1) {
    fill: ghostwhite;
  }

  .item-at-risk path:nth-child(2) {
    stroke: #3e48a6;
  }

  .item-at-risk path:nth-child(3),
  .item-at-risk path:nth-child(4) {
    fill: #ffc905;
  }

  .item-at-risk path:nth-child(5) {
    stroke: #ffc905;
  }
`;
