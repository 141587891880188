import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ButtonGroup, ButtonBase, Button, Typography, Grid } from '@material-ui/core';
import styled from 'styled-components';

import WidgetBox from 'components/widgetBox/widgetBox';
import Scanner, { ImageIcon, ScannerRefProps } from 'components/scanner/scanner';
import { ScanType } from 'utils/enums/stationEnums';
import { useIsRQLoading } from 'hooks';

import ErrorOutline from '@material-ui/icons/ErrorOutline';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import MultiScanner from 'assets/images/multi-scanner.png';
import ScanAndRemove from 'assets/images/scan-remove-rfid.png';
import { ReactComponent as OrderSearchIcon } from 'assets/images/svgs/order-search.svg';

const isProd = process.env.NODE_ENV === 'production';

// const isProd = true;

interface Props {
  onItemScan: (value: string, type: ScanType) => void;
  onOrderSearchClick?: () => void;
  validateInput?: boolean;
  autoFocus?: boolean;
  isVisible?: boolean;
  withOrderSearchButton?: boolean;
  isMiniScanner?: boolean;
  noBorder?: boolean;
  isWidget?: boolean;
  removeMode?: boolean;
  wrapperClassNames?: string;
  children?: JSX.Element;
  toastMessage?: string;
  focusOnLoad?: boolean;
}

/**
 * @description If the module using this component uses RQ for API calls,
 *  you should use this component, else use the `MultiScannerWidget`
 *  which is still using the old `isLoading` handling logic
 */
const MultiScannerWidget: React.FC<Props> = ({
  autoFocus = true,
  isWidget = false,
  noBorder = false,
  removeMode = false,
  isVisible = false,
  wrapperClassNames,
  onItemScan,
  onOrderSearchClick,
  withOrderSearchButton = false,
  isMiniScanner = false,
  toastMessage,
  focusOnLoad,
}) => {
  const scannerRef = useRef<ScannerRefProps>(null);
  const [active, setActive] = useState(false);
  const [activeScanner, setActiveScanner] = useState<'QRCODE' | 'RFID'>('RFID');
  const { isLoading, isMutating, isBackgroundLoading } = useIsRQLoading();

  const scannerImage = useMemo<ImageIcon>(
    () => ({
      imageUrl: isWidget ? undefined : MultiScanner,
      type: isProd ? ScanType.MULTI : ScanType[activeScanner],
      toastMessage,
    }),
    [activeScanner, toastMessage, isWidget]
  );

  useEffect(() => {
    if (focusOnLoad) handleScannerClick();
  }, [focusOnLoad]);

  const handleScannerClick = () => {
    if (scannerRef.current) scannerRef.current.focusInput();
  };

  return (
    <WidgetWrapper classNames={wrapperClassNames ?? ''} onClick={handleScannerClick} border={!noBorder}>
      <Wrapper isWidget={isWidget} isMiniScanner={isMiniScanner}>
        {withOrderSearchButton && onOrderSearchClick ? (
          <Grid container direction="row" justify="space-between">
            <Grid item xs={1}>
              {active ? <CheckCircleOutline color="primary" /> : <ErrorOutline color="secondary" />}
            </Grid>

            <Grid item xs={1}>
              <ButtonBase onClick={onOrderSearchClick}>
                <OrderSearchIcon />
              </ButtonBase>
            </Grid>
          </Grid>
        ) : active ? (
          <CheckCircleOutline color="primary" />
        ) : (
          <ErrorOutline color="secondary" />
        )}
        {isWidget && (
          <WidgetContent>
            {!isMiniScanner && <Typography variant="body1">{removeMode ? 'Scan and remove any RFID or QR' : 'Scan QR Code or RFID tag'}</Typography>}
            {isProd && !removeMode && <img src={MultiScanner} width={isMiniScanner ? '35px' : '60px'} alt="multi-scanner" />}
            {removeMode && <img src={ScanAndRemove} width="200px" height="60px" alt="remove-tag" style={{ padding: '.5rem' }} />}
          </WidgetContent>
        )}
      </Wrapper>
      {!isProd && !isMiniScanner && (
        <ButtonGroup size="small" style={{ marginTop: '.5rem', justifyContent: 'center' }}>
          <Button color={activeScanner === 'QRCODE' ? 'primary' : 'secondary'} onClick={() => setActiveScanner('QRCODE')}>
            QR
          </Button>
          <Button color={activeScanner === 'RFID' ? 'primary' : 'secondary'} onClick={() => setActiveScanner('RFID')}>
            RFID
          </Button>
        </ButtonGroup>
      )}
      <ScannerWrapper isWidget={isWidget}>
        <Scanner
          title={isWidget ? undefined : 'Scan QR Code or RFID tag'}
          imageIcon={scannerImage}
          isLoading={(isLoading && !isBackgroundLoading) || isMutating}
          onFormSubmit={onItemScan}
          qrCodeType={'item'}
          onBlur={() => setActive(false)}
          onFocus={() => setActive(true)}
          ref={scannerRef}
          isVisible={isVisible}
          autoFocus={autoFocus}
          validateInput
        />
      </ScannerWrapper>
    </WidgetWrapper>
  );
};

export default MultiScannerWidget;

const WidgetWrapper = styled(WidgetBox)<{ border?: boolean; isMiniScanner?: boolean }>`
  ${({ border }) => (border ? 'border: 0 !important' : '')};
`;

const Wrapper = styled.div<{ isWidget: boolean; isMiniScanner?: boolean }>`
  ${({ isWidget }) =>
    isWidget
      ? `
      display: flex;
      align-items: flex-start;
      justify-content: center;
  `
      : ``};
  ${({ isMiniScanner }) =>
    isMiniScanner
      ? `
  .MuiSvgIcon-root {
    width: 0.8rem;
    height: 0.8rem;
  }`
      : ''}
`;

const WidgetContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-left: 0.2rem;
`;

const ScannerWrapper = styled.div<{ isWidget: boolean }>`
  ${({ isWidget }) =>
    isWidget
      ? `
    height: 0px;
     overflow: hidden;
`
      : ``};
`;
