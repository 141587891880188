
import { useMutation } from 'react-query';
import { StationService } from 'services';

interface UnlinkSaItemToBagOptions {
    bagCode: string;
    specialAttentionItemId: string;
}
const useUnlinkSaItemToBag = () => {
    return useMutation<void, Error, UnlinkSaItemToBagOptions>(({ bagCode, specialAttentionItemId }) =>
        StationService.unlinkSpecialAttentionItemFromBag(bagCode, specialAttentionItemId)
    );
};
export default useUnlinkSaItemToBag;
