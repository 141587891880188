import React, { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { StationService } from 'services';
import { Bag } from 'utils/classes';
import { IBag } from 'utils/interfaces';

import { BagTypes } from 'utils/enums/stationEnums';
import { EActionType, useActivityState } from 'stores/activityStore';
import { toast } from 'react-toastify';
import { Toaster } from 'components/toaster';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSorterStore, useStationStore } from 'stores';
export type QueryResponse = IBag;

export interface BagError extends Error {
  message: string;
}

export const QUERY_KEY = 'bag-with-order-and-items';

//Local Storage Keys
const BAGCODE_LS = 'bagCode-rq';
const BAG_LS = 'bag-rq';

interface QueryOptions {
  refetchOnMount?: boolean;
}
const useGetBag = ({ refetchOnMount }: QueryOptions = { refetchOnMount: false }) => {
  const queryClient = useQueryClient();
  const { logActivity } = useActivityState();
  const { handleFinishBag: clientStateFinishBag, setItems } = useSorterStore();
  //TODO : For later, we should stop reading/writing from this store
  //Setting Bag in stationStore to handle displaying bagCount in BagCountDisplay (BagCount.tsx) rendered in root.tsx
  const { setBagScanTimeStamp: setOldStoreBagScanTimStamp, setBag: setOldStoreBag, setItems: setOldStoreItems } = useStationStore();

  const navigate = useNavigate();

  const { pathname } = useLocation();
  const [bagCode, setBagCode] = useState(localStorage.getItem(BAGCODE_LS) || '');
  //Used to log activity in case user called handleBagScanned()
  const [manualScan, setManualScan] = useState(false);
  const queryEnabled = useMemo(() => !!bagCode, [bagCode]);
  const onGetBagSuccessfully = (bag: IBag) => {
    localStorage.setItem(BAG_LS, JSON.stringify(bag));
    localStorage.setItem(BAGCODE_LS, bagCode);
    setOldStoreBag(bag);
    setItems(bag.orderItemList || []);
    setOldStoreItems(bag.orderItemList || []);

    if (bagCode && (!pathname.includes(bagCode) || manualScan)) {
      if (bag && bag.type !== BagTypes.WASH_FOLD) {
        logActivity(EActionType.scanBag);
        setManualScan(false);
        return navigate(`bag/${bagCode}`);
      } else {
        toast(<Toaster message={`Can't process a ${BagTypes.WASH_FOLD} Bag !`} type="error" />);
        throw new Error(`Can't process a ${BagTypes.WASH_FOLD} Bag !`);
      }
    }
  };

  const handleFinishBag = () => {
    setBagCode('');
    //FIXME : Remove clientStateFinishBag Once setItems is refactored into client state
    clientStateFinishBag();
    clearLocalStorage();
  };

  const onGetBagFailed = (error: BagError | string) => {
    handleFinishBag();
    // Display the error
    if ((typeof error === 'string' || typeof error.message === 'string') && error === `Can't process a ${BagTypes.WASH_FOLD} Bag !`) {
      //@ts-ignore
      toast(<Toaster message={error.message ? error.message : error} type="error" />);
    }
  };
  const getBagQuery = useQuery<QueryResponse, BagError>([QUERY_KEY, bagCode], () => StationService.getOrderBagByQrCode(bagCode, manualScan), {
    initialData: () => {
      const cachedBag = localStorage.getItem(BAG_LS);
      if (queryEnabled && Boolean(cachedBag) && cachedBag !== null) {
        return JSON.parse(cachedBag);
      }
    },

    enabled: queryEnabled,
    //FIXME : This is handling info code 210.7 (Order complete)
    // The info code should be handled, and this ternary operator should be removed
    onSuccess: (bag) => (bag ? onGetBagSuccessfully(bag) : handleFinishBag()),
    onError: (e) => {
      queryClient.setQueryData([QUERY_KEY, bagCode], () => undefined);
      onGetBagFailed(e);
    },
    refetchOnWindowFocus: false,
    refetchOnMount,
    retry: false,
  });

  const handleBagScanned = (bagCode: string) => {
    setManualScan(true);
    localStorage.setItem(BAGCODE_LS, bagCode);
    setBagCode(bagCode);
    //set initial timer value
    const today = new Date();
    setOldStoreBagScanTimStamp(today);
  };

  const clearLocalStorage = () => {
    localStorage.removeItem(BAG_LS);
    localStorage.removeItem(BAGCODE_LS);
    setOldStoreBag(new Bag());
  };

  const refetchBag = async () => await getBagQuery.refetch({ active: true });

  const queryMutation = useMutation(refetchBag);

  return {
    ...getBagQuery,
    //TODO : Fix by returning data as is, and using proper conditions to check if undefined or not
    data: getBagQuery.data || (new Bag() as IBag),
    items: getBagQuery.data?.orderItemList ?? [],
    manualScan,
    handleBagScanned,
    handleFinishBag,
    refetchBag,
    hardRefetch: queryMutation.mutateAsync,
  };
};

export default useGetBag;
