import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import lodash from 'lodash';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';

import { ActivityEntityTypes, IActivity } from 'utils/interfaces';
import { capitalizeString } from 'utils/helpers/string';

interface IProps {
  activities?: IActivity[];
  showActivity?: boolean;
  showDetailedJourney?: boolean;
}

export const getActivityStationName = (itemActivity?: IActivity) =>
  (itemActivity?.stationId || '')
    .replace(RegExp(/-station-\d+$|-station$/, 'i'), '')
    .toLowerCase()
    .replaceAll('_', '-');

const ItemJourney: React.FC<IProps> = ({ activities = [] }) => {
  const sortedStations = React.useMemo(
    () =>
      lodash.sortBy(activities, 'createdAt').map((activity) => {
        const stationId = activity.stationId;
        return { ...activity, stationId: stationId };
      }, [] as IActivity[]),
    [activities],
  );

  return (
    <CustomStepper activeStep={sortedStations.length}>
      {sortedStations.map((activity, activityIndex) => {
        const createdAt = moment(activity.createdAt);
        return (
          <Station active key={`${activity.stationId} - ${activityIndex}`} completed>
            <StepperText coordinates='5.5rem' direction='bottom'>
              {capitalizeString(activity.activity)}
            </StepperText>
            <StepperText coordinates="3.5rem" direction="bottom">
              {(activity.activity === 'ITEM_ASSIGNED_PACKAGE' || activity.activity === 'ITEM_UNASSIGNED_PACKAGE')
                ? ((activity.entityType === ActivityEntityTypes.package) ? activity.itemCode : activity.packageCode)
                : capitalizeString(activity.stationId || '', '-')}
            </StepperText>

            <Oval />
            <StepperText coordinates="1.5rem" direction="top">
              {capitalizeString(activity.userName || '', ' ')}
            </StepperText>
            <StepperText coordinates="3rem" direction="top">
              {createdAt.format('YYYY MMM DD')}
            </StepperText>
            <StepperText coordinates="4.5rem" direction="top">
              {createdAt.format('hh:mm A')}
            </StepperText>
          </Station>
        );
      })}
    </CustomStepper>
  );
};

export default ItemJourney;
const Station = styled(Step)`
  display: flex;
  min-width: 6.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;
const CustomStepper = styled(Stepper)`
  min-height: 15rem;
  width: auto;

  .MuiStepper-root {
    padding: 0;
  }

  .MuiStepConnector-line {
    height: 0.5rem;
    border-color: #dfe3f1;
    min-width: 10rem;
    border-radius: 3.5px;
    background-color: #dfe3f1;
  }
`;
const StepperText = styled.span<{ direction: string; coordinates: string }>`
  position: absolute;
  font-size: 1.3rem;
  font-weight: normal;
  width: max-content;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #111135;
  ${({ direction, coordinates }) => {
    return `${direction} : ${coordinates};`;
  }}
`;

const Oval = styled.span`
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
  border: solid 4px #3e48a6;
  background-color: #fff;
`;
