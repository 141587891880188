import { useMutation, useQueryClient } from 'react-query';
import { ItemActions, ItemIssue } from 'utils/interfaces';
import { StainManService } from 'services';
import { IItemReportDetail } from 'utils/interfaces/stainManStationInterfaces';
import { QueryResponse } from './useHandleGetItemReportDetail';

export interface ItemIssueUpdateRequest {
  orderId: string;
  orderAlphaId: string;
  itemCode: string;
  issueId: string;
  approvalStatus: string;
  customerAlphaId: string;
  action: ItemActions | '';
  isActioned: boolean;
  numberOfScans: number;
  isRemoved: boolean;
  isNotCleaned: boolean;
  isCantBeCleaned: boolean;
}

const useUpdateItemIssueStatus = (handleOnSuccess: () => void) => {
  const queryClient = useQueryClient();

  return useMutation<{ itemIssue: ItemIssue }, Error, ItemIssueUpdateRequest>((options) => StainManService.updateItemIssue(options), {
    onSuccess: (data, variables) => {
      const { issueId, action } = variables;

      //Since the user can use multi scanner to scan by RFID, adding the itemCode as key here would not get the correct query
      const itemReportDetailQuery = queryClient.getQueryCache().find<IItemReportDetail>(['itemReportDetail'], {
        exact: false,
        active: true,
      });
      const queryKey = itemReportDetailQuery?.options.queryKey as [string, string];
      queryClient.setQueryData<Partial<QueryResponse>>(queryKey, (oldReportDetail) => {
        if (!(oldReportDetail && oldReportDetail.itemReportDetail)) {
          return {};
        }

        const issues =
          oldReportDetail.itemReportDetail.itemIssues?.map((issue) => {
            if (issue.issueId === issueId) {
              issue.action = action;
            }
            return issue;
          }) || [];
        return { itemReportDetail: { ...oldReportDetail.itemReportDetail, itemIssues: [...issues] } };
      });
      handleOnSuccess();
    },
  });
};
export default useUpdateItemIssueStatus;
