import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Dialog, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { Iicon } from 'modules/stations/sorterStation/components/labelSelector/LabelSelector';
import { ReactComponent as FineryBag } from 'assets/images/svgs/bags/finery_bag.svg';
import { ReactComponent as CleanPressBag } from 'assets/images/svgs/bags/laundry-bag.svg';
import { ReactComponent as ShoeCleaningBag } from 'assets/images/svgs/bags/shoes-bag.svg';
import { ReactComponent as HomeCareBag } from 'assets/images/svgs/bags/home_care_bag.svg';
import { ReactComponent as PressingBag } from 'assets/images/svgs/bags/pressing_bag.svg';
import { ReactComponent as WashFoldBag } from 'assets/images/svgs/bags/wash_fold_bag.svg';
import { ReactComponent as Alert } from 'assets/images/svgs/alert.svg';
import { ReactComponent as NoneBag } from 'assets/images/svgs/nonebag.svg';
import { ReactComponent as BagScanQrCode } from 'assets/images/svgs/bags/bag_qrcode.svg';

interface Props {
  open: boolean;
  iconName: string;
  title: string;
  subTitle: string;
  buttonText?: string;
  onClose: () => void;
  showSecondaryButton?: boolean;
  secondaryButtonText?: string;
  onSecondaryButtonClick?: () => void;
}

const ScanErrorDialog: React.FC<Props> = ({
  open,
  iconName,
  title,
  subTitle,
  buttonText = '',
  onClose,
  showSecondaryButton,
  secondaryButtonText,
  onSecondaryButtonClick,
}) => {
  const iconComponentMap: { [key: string]: Iicon } = {
    cleanPress: { element: <CleanPressBag />, label: 'Clean and Press Bag' },
    pressing: { element: <PressingBag />, label: 'Pressing Bag' },
    shoeCleaning: { element: <ShoeCleaningBag />, label: 'Shoe Cleaning Bag' },
    homeCare: { element: <HomeCareBag />, label: 'Home Care Bag' },
    washFold: { element: <WashFoldBag />, label: 'Wash and Fold Bag' },
    finery: { element: <FineryBag />, label: 'Finery Bag' },
    none: { element: <NoneBag />, label: 'None' },
    alert: { element: <Alert />, label: 'Alert' },
    bagScanQrCode: { element: <BagScanQrCode />, label: 'Alert' },
  };

  return (
    <Dialog open={open} maxWidth="md">
      <ContentWrapper container direction="column" justifyContent="center" alignItems="center">
        <Grid>{iconComponentMap[iconName].element}</Grid>
        <ModalTitle item container xs={12} justifyContent="center">
          <Typography variant="h3">{title}</Typography>
        </ModalTitle>
        <ModalSubTitle>
          <SubTitleWrapper variant="h4">{subTitle}</SubTitleWrapper>
        </ModalSubTitle>
        <ButtonWrapper container item xs={12} direction="row" justifyContent={secondaryButtonText ? 'space-between' : 'center'}>
          {showSecondaryButton && (
            <Button color="primary" variant="outlined" size="large" onClick={onSecondaryButtonClick}>
              {secondaryButtonText}
            </Button>
          )}
          {buttonText && (
            <Button color="primary" variant="contained" size="large" onClick={onClose}>
              {buttonText}
            </Button>
          )}
        </ButtonWrapper>
      </ContentWrapper>
    </Dialog>
  );
};

export default ScanErrorDialog;

const ContentWrapper = styled(Grid)`
  text-align: center;
  padding: 3rem;
  width: 50rem;
`;

const ModalTitle = styled(Grid)`
  padding: 2rem 0rem 0rem 0rem;
  justify-content: center;
`;

const ModalSubTitle = styled(Grid)`
  padding: 0.5rem 0rem 0rem 0rem;
  justify-content: center;
`;

const ButtonWrapper = styled(Grid)`
  padding: 2rem 0rem;
`;

const SubTitleWrapper = styled(Typography)`
  font-weight: 200;
`;
