import { useMutation } from 'react-query';
import { StationService } from 'services';

interface LinkItemToSaItemOptions {
    itemRfid: string;
    specialAttentionItemId: string;
}
const useLinkItemToSaItem = () => {
    return useMutation<void, Error, LinkItemToSaItemOptions>(({ itemRfid, specialAttentionItemId }) =>
        StationService.linkItemToSpecialAttentionItem(itemRfid, specialAttentionItemId)
    );
};
export default useLinkItemToSaItem;
