import React, { useState } from 'react';
import { Grid, Button, Box, Typography, IconButton } from '@material-ui/core';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';

import CustomDialog from './customDialog';
import ImagesListCarousel from 'modules/stations/components/carousel/imagesListCarousel';
import { Preferences } from 'utils/interfaces';
import { ShoeAddonApprovals } from 'modules/stations/components/icons';

import SpecialInstructionIcon from 'assets/images/pngs/special-instruction.png';
import { ReactComponent as CustomerRequestedCallBackIcon } from 'assets/images/svgs/customer-requested-callback.svg';

interface Props {
  onClose: () => any;
  open: boolean;
  preferences?: Preferences;
  customerAttachments?: string[];
  customerNotes?: string;
}

const IMAGES_PER_PAGE = 6;
const IMAGES_PER_ROW = 3;

const CustomerSpecialInstructionModal: React.FC<Props> = ({
  onClose,
  open,
  preferences: { isCallCustomerBeforeProcessing, shoeAddOnApprovals } = {
    isCallCustomerBeforeProcessing: false,
    shoeAddOnApprovals: {
      restoration: false,
      soleIcing: false,
      stainProtection: false,
    },
  },
  customerAttachments,
  customerNotes,
}) => {
  const handleClose = () => {
    onClose();
  };

  const [userSeenAllImages, setUserSeenAllImages] = useState(!customerAttachments?.length || IMAGES_PER_PAGE >= customerAttachments?.length);

  return (
    <CustomDialog custom_size={{ height: 'auto', width: '80%' }} open={open} size={false}>
      <Wrapper container direction="column" alignItems="center">
        <TitleWrapper container item direction="row" justify="center" alignItems="center">
          <InstructionsImg src={SpecialInstructionIcon} alt="warning" />
          <Typography variant="h4" display="initial" align="center">
            Customer Special Instructions
          </Typography>
          <CloseIconWrapper onClick={handleClose} disabled={!userSeenAllImages} aria-label="close">
            <StyledCloseIcon />
          </CloseIconWrapper>
        </TitleWrapper>
        <InstructionsWrapper container item direction="row" alignItems="center">
          <Grid alignItems="center" container item xs={12} wrap="nowrap">
            <Grid item xs={6}>
              {isCallCustomerBeforeProcessing && (
                <Box padding={'1rem'}>
                  <CustomerRequestedCallBackIcon />
                </Box>
              )}
            </Grid>
            <Grid item xs={6}>
              <ShoeAddonApprovals approvals={shoeAddOnApprovals} />
            </Grid>
          </Grid>
          <ImagesListCarousel
            paginationWidth="100%"
            width="100%"
            imagesPerPage={IMAGES_PER_PAGE}
            imagesPerRow={IMAGES_PER_ROW}
            imagesDimentions={{ width: '15.8rem', height: '12.125rem' }}
            imagesList={customerAttachments || []}
            handleReachedLastPage={() => {
              if (!userSeenAllImages) {
                setUserSeenAllImages(!userSeenAllImages);
              }
            }}
          />

          <StyledNotes variant="h6" display="initial" align="left">
            {customerNotes || ''}
          </StyledNotes>
        </InstructionsWrapper>
        <Grid item xs={5}>
          <DoneButton disabled={!userSeenAllImages} color="primary" variant="contained" size="large" onClick={handleClose}>
            Done
          </DoneButton>
        </Grid>
      </Wrapper>
    </CustomDialog>
  );
};

export default CustomerSpecialInstructionModal;
const Wrapper = styled(Grid)`
  padding: 1rem;
`;

const StyledNotes = styled(Typography)`
  margin: 1.5rem;
`;

const DoneButton = styled(Button)`
  margin-top: 2rem;
`;

const InstructionsWrapper = styled(Grid)`
  padding: 1rem;
  border-bottom: 1px solid #b6bcd1;
`;

const TitleWrapper = styled(Grid)`
  padding: 1rem;
  border-bottom: 1px solid #b6bcd1;
  position: relative;
`;

const InstructionsImg = styled.img`
  width: 2.5rem;
  padding-right: 1rem;
`;

const CloseIconWrapper = styled(IconButton)`
  position: absolute;
  right: 0;
`;
const StyledCloseIcon = styled(CloseIcon)`
  width: 2.5rem;
  height: 2.5rem;
`;
