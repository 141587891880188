import React, { useState } from 'react';
import { Button, Grid } from '@material-ui/core';

import { ComponentProps, useGetSelectedItem } from 'modules/stations/components/SortingStepper';
import { PhotoCatcher } from '../..';
import { Brand } from 'utils/interfaces';
import startCase from 'lodash/startCase';
import { MISSING_BRAND_CONFIG } from './selectBrandDialog';

import { ReactComponent as BackIcon } from 'assets/images/svgs/back-arrow.svg';
enum Steps {
  PREVIEW = 'preview',
  NECK_LABEL = 'neckLabel',
  CARE_LABEL = 'careLabel',
}

interface Props extends ComponentProps {
  onGoBack: () => void;
}

const MissingBrandDialog: React.FC<Props> = ({ onSelect, onGoBack }) => {
  const { selectedItem: currentItem } = useGetSelectedItem();
  const [photos, setPhotos] = useState<Pick<Brand, 'careLabel' | 'neckLabel'>>({
    careLabel: currentItem.brand?.careLabel,
    neckLabel: currentItem.brand?.neckLabel,
  });

  const [step, setStep] = useState<Steps>(photos.neckLabel ? (photos.careLabel ? Steps.PREVIEW : Steps.CARE_LABEL) : Steps.NECK_LABEL);

  const handleOnPhotoTaken = (image: string, selectedStep: Exclude<Steps, Steps.PREVIEW>) => {
    setPhotos((prev) => ({ ...prev, [selectedStep]: image }));
  };
  /** Go to next step */
  const handleNextbtn = () => setStep((prev) => (prev === Steps.NECK_LABEL ? Steps.CARE_LABEL : Steps.PREVIEW));

  /** Finished the flow */
  const handleOnSelect = () => onSelect({ ...currentItem, brand: { ...MISSING_BRAND_CONFIG, ...photos } });

  const resetStep = (resetTo: Exclude<Steps, Steps.PREVIEW>) => {
    setStep(resetTo);
    setPhotos((prev) => ({ ...prev, [resetTo]: '' }));
  };

  return (
    <>
      <Grid container justifyContent="flex-start" style={{ padding: '1rem' }}>
        <Button size="medium" color="primary" onClick={onGoBack}>
          <BackIcon width="25px" height="25px" />
          Select brand
        </Button>
      </Grid>

      {step !== Steps.PREVIEW ? (
        <Grid item style={{ flex: 'auto' }} xs={12} container>
          <PhotoCatcher
            key={step}
            title={photos[step] ? startCase(step) : `Please take photo of ${startCase(step)}`}
            imageSrc={photos[step]}
            onConfirmImage={handleNextbtn}
            onPhotoTaken={(image) => handleOnPhotoTaken(image, step)}
            enableUploadImage
            showTitleAtEnd
            showChildrenInTakePhoto
            onResetImage={() => resetStep(step)}
          />
        </Grid>
      ) : (
        <Grid item style={{ flex: 'auto' }} xs={12} container justifyContent="space-around" alignContent="space-around">
          <Grid item xs={12} container justifyContent="center" alignContent="center">
            <PhotoCatcher
              hideNextButton
              title={startCase(Steps.CARE_LABEL)}
              imageSrc={photos.careLabel}
              onResetImage={() => resetStep(Steps.CARE_LABEL)}
              showTitleAtEnd
              styles={{ imgGridSize: 12 }}
            />

            <PhotoCatcher
              hideNextButton
              showTitleAtEnd
              title={startCase(Steps.NECK_LABEL)}
              imageSrc={photos.neckLabel}
              onResetImage={() => resetStep(Steps.NECK_LABEL)}
              styles={{ imgGridSize: 12 }}
            />
          </Grid>
          <Grid item xs={12} container justifyContent="center" alignContent="center">
            <Button variant="contained" size="large" color="primary" onClick={handleOnSelect}>
              Confirm and proceed
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default MissingBrandDialog;
