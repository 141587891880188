import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import SelectBrandDialog from './selectBrandDialog';
import { ComponentProps } from 'modules/stations/components/SortingStepper/SortingStepper';

interface Props extends ComponentProps {}

//TODO : Can be made reusable by extracting BAG from SelectBrandDialog
const SelectBrandComponent: React.FC<Props> = ({ onSelect }) => {
  return (
    <Routes>
      <Route path={'/'} element={<SelectBrandDialog onSelect={onSelect} />} />
      <Route path="*" element={<Navigate to={''} />} />
    </Routes>
  );
};

export default SelectBrandComponent;
