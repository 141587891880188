import { ColorsList } from 'utils/interfaces';

/**
 * @description Formats array by running CapitalizeString on each
 * string inside it, and adds a comma after each string
 *
 * @param array
 *
 * @returns formattedString
 */
export const formatArrayToString = (array: string[]) => {
  return array
    .map((s: string) => {
      return capitalizeString(s);
    })
    .join(', ');
};

/**
 * @description Capitalizes the first char, and returns
 * the rest of the string in lower-case.
 * If the string contains words separated by `_` , we remove it
 * and capitalize the words around it.
 * If the optional property is available, split at that string instead.
 *
 * @param str
 *
 * @returns CapitalizedString
 */
export const capitalizeString = (str: string, splitAt?: string) => {
  if (!splitAt) splitAt = '_';
  return str
    .split(splitAt)
    .map((word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
};

/**
 *
 * @description Formats array to return a displayable string of colors
 * separated by commas.
 *
 * @param  colors
 *
 * @returns {string} formattedColors
 */
export const generatedFormattedColors = (colors: ColorsList[]) => {
  const colorsArray = colors.map((color) => color.name);
  if (colorsArray.length > 1) {
    return formatArrayToString(colorsArray);
  } else {
    return capitalizeString(colorsArray[0]);
  }
};
