import React, { useState } from 'react';
import styled from 'styled-components';
import CustomerSpecialInstructionModal from 'components/dialogs/customerSpecialInstructionModal';
import { Box, Button, Grid, Typography } from '@material-ui/core';

import { ReactComponent as InstructionsIcon } from 'assets/images/svgs/instructions.svg';
import ImageStepper from 'components/imageStepper/ImageStepper';

interface Props {
  notes?: string;
  images?: string[];
}

const Instructions: React.FC<Props> = ({ notes = '', images = [] }) => {
  const [specialInstructionsOpen, setSpecialInstructionsOpen] = useState<boolean>(false);

  return (
    <>
      <TitleSection>
        <InstructionsIcon width="4.25rem" height="4.25rem" />
        <StyledTitle>Customer Special Instructions</StyledTitle>
      </TitleSection>
      {images.length || notes ? (
        <Wrapper container justifyContent="center">
          <ImageStepper images={images.map((img) => ({ image: img, logo: <></> }))} />
          <WrapperNotes>
            <StyledNotes variant="h6" display="initial" align="left">
              {notes || ''}
            </StyledNotes>
          </WrapperNotes>
          {/* Should show if no specialInstructions images or notes? */}
          <StyledButton variant="text" size="large" color="primary" onClick={() => setSpecialInstructionsOpen(true)}>
            View All
          </StyledButton>
          <CustomerSpecialInstructionModal
            customerAttachments={images}
            customerNotes={notes}
            open={specialInstructionsOpen}
            onClose={() => setSpecialInstructionsOpen(false)}
          />
        </Wrapper>
      ) : (
        <Wrapper item container justifyContent="center">
          <StyledTitle>No Customer Special Instructions</StyledTitle>
        </Wrapper>
      )}
    </>
  );
};

const TitleSection = styled(Box)`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  align-items: center;
`;

const Wrapper = styled(Grid)`
  height: 19rem;
  align-items: center;
`;

const StyledTitle = styled(Typography)`
  font-size: 1.5rem;
  font-weight: lighter;
  text-align: center;
`;

const WrapperNotes = styled.div`
  height: 5rem;
  overflow-y: auto;
`;

const StyledNotes = styled(Typography)`
  margin: 1.5rem;
`;

const StyledButton = styled(Button)`
  padding: 0rem !important;
`;

export default Instructions;
