import { useEffect, useMemo, useState } from 'react';
import { IOrderItem, UniversalItemNote } from 'utils/interfaces';

const useUniversalItemNotesState = (stationId?: string, item?: IOrderItem) => {
  const [open, setOpen] = useState(false);

  const noteForStation = useMemo(() => getNoteForStation(stationId, item?.universalItemNotes), [stationId, item?.universalItemNotes]);

  useEffect(() => {
    if (noteForStation?.id) setOpen(true);
  }, [item?.itemCode, noteForStation?.id]);

  return { open, setOpen, noteForStation };
};

const getNoteForStation = (stationId?: string, notes?: UniversalItemNote[]) =>
  notes?.find((note) => note.station.some((noteStation) => stationId?.includes(noteStation || '')));

export default useUniversalItemNotesState;
