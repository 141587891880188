import { Grid } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const BounceComponent: React.FC = () => {
  return (
    <Wrapper container justifyContent="space-between" alignItems="flex-end">
      <Grid item className="ball" />
      <Grid item className="ball" />
      <Grid item className="ball" />
      <span>Loading</span>
    </Wrapper>
  );
};

export default BounceComponent;

const Wrapper = styled(Grid)`
  height: 10.675rem;
  width: 7.5rem;

  span {
    font-size: 1.5rem;
    width: 7rem;
    margin: auto;
  }
  span::after {
    animation: loader 1.5s normal ease infinite;
    content: '';
  }
  .ball {
    width: 1.565rem;
    height: 1.565rem;
    border-radius: 50%;
    background-color: black;
    animation: bounce 0.5s alternate infinite;
  }

  .ball:nth-child(2) {
    animation-delay: 0.16s;
  }
  .ball:nth-child(3) {
    animation-delay: 0.32s;
  }

  @keyframes bounce {
    from {
      transform: scaleX(1.25);
    }
    to {
      transform: translateY(-50px) scaleX(1);
    }
  }

  @keyframes loader {
    0% {
      content: '';
    }

    33% {
      content: ' .';
    }

    66% {
      content: ' ..';
    }

    100% {
      content: ' ...';
    }
  }
`;
